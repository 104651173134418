import { getToken } from "../configs/cookie";
import api from "../utility/Utils";

export const AccountServices = {
  uploadImageProfile,
  approvalChangeEmail,
  approvalChangeEmailOtp,
  againRequestChangeEmailOtp,
  changePasswordProfile,
  invalideToken,
  disableReasonsListGet,
  disableFreezeDelete,
  deleteAccount,
  userReasonToContactUs,
  notifyContactUs,
  getCompanyHeaderInfo,
};
function getCompanyHeaderInfo() {
  //şirket header bilgilerini getirir.
  return api
    .get("/company/main/header")
    .then((response) => {
      return Promise.resolve(response);
    })
    .catch((err) => {
      return Promise.reject(err);
    });
}
function invalideToken(arg) {
  //token kontrolü
  //süresi geçmiş token    "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJodHRwOi8vc2NoZW1hcy54bWxzb2FwLm9yZy93cy8yMDA1LzA1L2lkZW50aXR5L2NsYWltcy9uYW1lIjoiMTE4MDAwMDAwMDAiLCJVc2VySWQiOiJmZTIxM2VhYi03ZGJmLTRlNDgtOTYxNy0zMTRiNzVmNjRmYzgiLCJFbXBsb3llcklkIjoiMCIsIkNhbmRpZGF0ZUlkIjoiMjMyMyIsImp0aSI6IjkwNzE4MTM0LTVhNjUtNDIzMy1iN2ViLTlhODQ4ODMxMDBlYiIsImh0dHA6Ly9zY2hlbWFzLm1pY3Jvc29mdC5jb20vd3MvMjAwOC8wNi9pZGVudGl0eS9jbGFpbXMvcm9sZSI6IkNhbmRpZGF0ZSIsImV4cCI6MTcwOTI3MTc2MSwiaXNzIjoiaHR0cDovL2xvY2FsaG9zdDo1MDAwIiwiYXVkIjoiaHR0cDovL2xvY2FsaG9zdDo0MjAwIn0.en6PIQU9xpPd3i1fviqkUCtRvUNp12ZddvqjUfCMuZo", //
  var token = getToken();
  var sendData = {
    token: token == null || token == undefined ? null : token,
  };
  return api
    .post("/account/validatetoken", sendData)
    .then((response) => {
      return Promise.resolve(response);
    })
    .catch((err) => {
      return Promise.reject(err);
    });
}

function uploadImageProfile(arg) {
  //son talepler
  return api
    .post("employer/profile-image", arg)
    .then((response) => {
      console.log("response* * " + JSON.stringify(response));
      return Promise.resolve(response);
    })
    .catch((err) => {
      return Promise.reject(err);
    });
}

function approvalChangeEmail(arg) {
  //email değiştirme kısmı
  return api
    .post("/account/employer/changeemail", arg)
    .then((response) => {
      return Promise.resolve(response);
    })
    .catch((err) => {
      return Promise.reject(err);
    });
}
function approvalChangeEmailOtp(arg) {
  //email değiştirken kod onaylama
  return api
    .post("/account/employer/confirmchangedemail", arg)
    .then((response) => {
      return Promise.resolve(response);
    })
    .catch((err) => {
      return Promise.reject(err);
    });
}
function againRequestChangeEmailOtp(arg) {
  //email değiştirken kod gelmediğinde tekrardan isteme kısmı
  return api
    .post("/account/employer/resendupdateemailconfirmation", arg)
    .then((response) => {
      return Promise.resolve(response);
    })
    .catch((err) => {
      return Promise.reject(err);
    });
}
function changePasswordProfile(arg) {
  //şifre değiştir
  return api
    .post("/account/employer/changepassword", arg)
    .then((response) => {
      return Promise.resolve(response);
    })
    .catch((err) => {
      return Promise.reject(err);
    });
}

function disableReasonsListGet(id) {
  //hesap silme ve dondurma neden listesini verir
  return api
    .get("/common/disablereasons/" + id)
    .then((response) => {
      return Promise.resolve(response);
    })
    .catch((err) => {
      return Promise.reject(err);
    });
}

function userReasonToContactUs(accountTypeId) {
  //iletişim neden listesini verir
  return api
    .get("/common/reasonToContactUs/" + accountTypeId)
    .then((response) => {
      return Promise.resolve(response);
    })
    .catch((err) => {
      return Promise.reject(err);
    });
}

function notifyContactUs(arg) {
  //iletişim de gönderme işlemi yapılır
  return api
    .post("/notifyus/contactUs", arg)
    .then((response) => {
      return Promise.resolve(response);
    })
    .catch((err) => {
      return Promise.reject(err);
    });
}
function disableFreezeDelete(arg) {
  //hesap dondurur
  return api
    .post("/account/employer/disable", arg)
    .then((response) => {
      return Promise.resolve(response);
    })
    .catch((err) => {
      return Promise.reject(err);
    });
}

function deleteAccount(arg) {
  //hesap sil
  return api
    .post("/account/employer/delete", arg)
    .then((response) => {
      return Promise.resolve(response);
    })
    .catch((err) => {
      return Promise.reject(err);
    });
}
