import React, { useEffect } from "react";
import logo from "../../images/btnlogowhite.png";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { useState } from "react";
import { UserAction } from "../../action";
import classes from "../../css/bitoniscss/reset.module.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useForm, Controller } from "react-hook-form";
import {
  useHistory,
  useLocation,
} from "react-router-dom/cjs/react-router-dom.min";
import { useParams } from "react-router-dom";

import { setToken } from "../../configs/cookie";
import Select from "react-select";
import { customStyles, labelStyle } from "../customStyleInline";
import api from "../../utility/Utils";
import { RadioGroup } from "@material-ui/core";

const EmployerUnSubscriptionPage = (props) => {
  const params = new URLSearchParams(window.location.search);
  const value = params.get("value");
  const [selectUnSubscription, setSelectUnSubscription] = useState(null);
  var whyUnSubscriptionList = [
    { name: "Cok fazla E-Posta alıyorum", id: 1 },
    { name: "Gönderilen icerikler ilgimi çekmiyor.", id: 2 },
    { name: "Şirketim tarafından onaylanmadı", id: 3 },
  ];
  const[selectIndex,setSelectIndex]=useState(null)
  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm();
  var history = useHistory();
  function unSubscriptionSend(arg) {
    return api
      .post("/account/employer/unsubscription", arg)
      .then((response) => {
        console.log("response* * ** " + JSON.stringify(response));
        return Promise.resolve(response);
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  }
  function onSubmit() {
    if (
      selectUnSubscription === null ||
      selectUnSubscription === undefined ||
      selectUnSubscription === ""
    ) {
      toast.info("Lütfen Abonelikten Çıkma Nedeninizi Seçin", {
        autoClose: 2000,
        onClose: () => {
          setTimeout(() => {}, 100);
        },
      });
    } else {
      var sendData = {
        guid: value.toString(),
        unsubReason: selectUnSubscription,
      };
      unSubscriptionSend(sendData).then((unSubscriptionResponse) => {
        if (unSubscriptionResponse) {
          if (unSubscriptionResponse.succeeded === true) {
            toast.success(
              "Abonelikten Çıkma İşleminiz Başarılı Şekilde Gerçekleşti",
              {
                autoClose: 2000,
                onClose: () => {
                  setTimeout(() => {
                    history.push("/");
                  }, 100);
                },
              }
            );
          } else {
            toast.info(unSubscriptionResponse.error.message, {
              autoClose: 2000,
              onClose: () => {
                setTimeout(() => {}, 100);
              },
            });
          }
        } else {
          toast.info("Hata Oluştu", {
            autoClose: 2000,
            onClose: () => {
              setTimeout(() => {
                //  window.location.href = window.location.href;
              }, 100);
            },
          });
        }
      });
    }
  }



  return (
    <div>
      <ToastContainer
        position="top-right"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <div className="forgot-form-bx">
        <div className="container-fluid" >
          <div className="row">
            <div className="col-lg-6 col-md-7 box-skew d-flex">
              <div className="authincation-content" >
                <div className="mb-0">
                  <h2 className={classes.title}>E-Mail Abonelik</h2>
                </div>
                <label className="mb-2 ">
                  <p
                    style={{
                      fontSize: "14px",
                    }}
                  ></p>
                </label>
                {props.errorMessage && (
                  <div className="bg-red-300 text-red-900 border border-red-900 p-1 my-2">
                    {props.errorMessage}
                  </div>
                )}
                {props.successMessage && (
                  <div className="bg-green-300 text-green-900 border border-green-900 p-1 my-2">
                    {props.successMessage}
                  </div>
                )}
                <div className="form-group">
                  <label
                    className="col-sm col-form-label"
                    /* className="col-sm-3 col-form-label mt-3"*/
                    style={labelStyle}
                  >
                    Abonelikten Çıkma Sebebi
                    <label
                      style={{
                        color: "red",
                      }}
                    >
                      {" "}
                      *
                    </label>
                  </label>
               
                  {whyUnSubscriptionList.map((value,index) => {
                    return (
                      <div style={{}}>
                        <div
                          onClick={() => {
                            setSelectUnSubscription(value.id);
                          }}
                          style={
                            selectUnSubscription &&
                            selectUnSubscription === value.id
                              ? {
                                  display: "flex",
                                  border: "1px solid #F6F5F5",
                                  padding: "10px",
                                  borderRadius: "8px",
                                  height: "50px",
                                  margin: "10px",
                                  backgroundColor: "#F3F8FF",
                                }
                              : {
                                  display: "flex",
                                  border: "1px solid #F6F5F5",
                                  padding: "10px",
                                  borderRadius: "8px",
                                  height: "50px",
                                  margin: "10px",
                                }
                          }
                        >
                          <RadioButtonWithTitle
                            title={value.name}
                            onChange={() => {
                              setSelectUnSubscription(value.id);
                            }}
                            checked={
                              selectUnSubscription&&selectUnSubscription ===
                              value.id
                            }
                          />
                        </div>
                      </div>
                    );
                  })}
                  <div className="text-center">
                    <div className="col-sm-12 mt-2">
                      <button
                        type="button"
                        onClick={(val) => {
                          val.preventDefault();
                          onSubmit();
                        }}
                        className="btn btn-primary btn-block"
                      >
                        Gönder
                      </button>
                    </div>
                  </div>{" "}
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-5 d-flex box-skew1">
              <div className="inner-content align-self-center"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

function mapState(state) {
  let authentication = state.authentication;
  return { authentication };
}

export default connect(mapState)(EmployerUnSubscriptionPage);
function RadioButtonWithTitle({ title, checked, onChange }) {
  return (
    <div className="form-check">
      <input
        className="form-check-input"
        type="radio"
        checked={checked}
        onChange={onChange}
        style={{
          width: "20px",
          height: "20px",
        
        }}
      />
      <label
        className="form-check-label"
        style={{
          fontSize: "13px",
          color: "#001542",
          fontWeight: 450,
          margin:"5px"
        }}
      >
        {title}
      </label>
    </div>
  );
}
