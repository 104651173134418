import React, { useState } from "react";
import moment from "moment";
import { Box, Stack, Typography } from "@mui/material";
import { Col, Row } from "react-bootstrap";

function BlockItem({
  candidateNameSurname,
  positionName,
  status,
  candidateId,
  matchCreateDate,
}) {
  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  const getBackgroundColor = () => {
    if (isHovered) {
      return "#F5F5F5";
    }

    // Diğer durumlar için renkleri döndürün
    return "white";
  };
  return (
    <Box
      className="card-body"
      key={candidateId}
      style={{
        display: "flex",
        flexDirection: "row",
        alignContent: "space-between",
        border: isHovered == true ? "1px solid #D6E5FA" : "1px solid #F7F7F7",

        height: "60px",
        position: "relative",
        borderRadius: "10px",
        alignItems: "center",
        width: "100%",
        margin: "8px",
        justifyContent: "space-between",
        cursor: "pointer",
        backgroundColor: getBackgroundColor(), // Dinamik arka plan rengi
      }}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <Row
        sx={{
          display: "flex",
          flexDirection: "row",
        }}
      >
        <Box
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-start",
            alignContent: "center",
            alignItems: "center",
          }}
        >
          <span
            className="fa-stack fa-lg"
            style={{
              marginLeft: "10px",
            }}
          >
            <i class="fa-solid fa-user"></i>
          </span>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Typography
              sx={{
                fontSize: {
                  xs: "10px", // 0px ve üzeri ekran genişlikleri için
                  sm: "12px", // 600px ve üzeri ekran genişlikleri için
                  md: "12px", // 900px ve üzeri ekran genişlikleri için
                  lg: "14px", // 1200px ve üzeri ekran genişlikleri için
                  xl: "14px", // 1536px ve üzeri ekran genişlikleri için,
                },
                fontWeight: 600,
                color: {
                  xs: "#001542", // 0px ve üzeri ekran genişlikleri için
                  sm: "#001542", // 600px ve üzeri ekran genişlikleri için
                  md: "#001542", // 900px ve üzeri ekran genişlikleri için
                  lg: "#001542", // 1200px ve üzeri ekran genişlikleri için
                  xl: "#001542", // 1536px ve üzeri ekran genişlikleri için
                },
              }}
            >
              {candidateNameSurname}
            </Typography>
            <Typography
              sx={{
                fontSize: {
                  xs: "8px", // 0px ve üzeri ekran genişlikleri için
                  sm: "10px", // 600px ve üzeri ekran genişlikleri için
                  md: "10px", // 900px ve üzeri ekran genişlikleri için
                  lg: "12px", // 1200px ve üzeri ekran genişlikleri için
                  xl: "12px", // 1536px ve üzeri ekran genişlikleri için
                },
                fontWeight: "bold",
                color: {
                  xs: "grey", // 0px ve üzeri ekran genişlikleri için
                  sm: "grey", // 600px ve üzeri ekran genişlikleri için
                  md: "grey", // 900px ve üzeri ekran genişlikleri için
                  lg: "grey", // 1200px ve üzeri ekran genişlikleri için
                  xl: "grey", // 1536px ve üzeri ekran genişlikleri için
                },
              }}
            >
              Engelleme Tarihi:{" "}
              {moment(matchCreateDate && matchCreateDate).format("DD/MM/YYYY")}
            </Typography>
          </Box>
        </Box>
      </Row>
      <Box>
        <Typography
          sx={{
            fontSize: {
              xs: "9px", // 0px ve üzeri ekran genişlikleri için
              sm: "11px", // 600px ve üzeri ekran genişlikleri için
              md: "11px", // 900px ve üzeri ekran genişlikleri için
              lg: "13px", // 1200px ve üzeri ekran genişlikleri için
              xl: "12px", // 1536px ve üzeri ekran genişlikleri için,
            },
            textAlign: "center",
            fontWeight: 600,
            color: {
              xs: "#001542", // 0px ve üzeri ekran genişlikleri için
              sm: "#001542", // 600px ve üzeri ekran genişlikleri için
              md: "#001542", // 900px ve üzeri ekran genişlikleri için
              lg: "#001542", // 1200px ve üzeri ekran genişlikleri için
              xl: "#001542", // 1536px ve üzeri ekran genişlikleri için
            },
          }}
        >
          {positionName}
        </Typography>
      </Box>
      <Box>
        <Typography
          sx={{
            fontSize: {
              xs: "9px", // 0px ve üzeri ekran genişlikleri için
              sm: "12px", // 600px ve üzeri ekran genişlikleri için
              md: "12px", // 900px ve üzeri ekran genişlikleri için
              lg: "13px", // 1200px ve üzeri ekran genişlikleri için
              xl: "12px", // 1536px ve üzeri ekran genişlikleri için,
            },
            fontWeight: 600,
            color: {
              xs:"red", // 0px ve üzeri ekran genişlikleri için
              sm:"red", // 600px ve üzeri ekran genişlikleri için
              md:"red", // 900px ve üzeri ekran genişlikleri için
              lg:"red", // 1200px ve üzeri ekran genişlikleri için
              xl:"red" // 1536px ve üzeri ekran genişlikleri için
            },
          }}
        >
   
            <div>Engellendi</div>
       
        </Typography>
      </Box>
      <div
        style={
       {
                position: "absolute",
                bottom: 0,
                right: 0,
                width: 0,
                height: 0,
                borderLeft: "30px solid transparent",
                borderTop: "30px solid transparent",
                borderRight: "30px solid red",
                borderRadius: "0px 0px 8px",
              }
           
        }
      ></div>
    </Box>
  );
}

export default BlockItem;

