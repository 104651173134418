import Modal from "react-bootstrap/Modal";
import React, { useEffect, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import Select from "react-select";
import { connect, useDispatch } from "react-redux";
import { Button } from "react-bootstrap";
import api from "../../../../../../../utility/Utils";
import addEducation from "../../Function/educationmultiAdd";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import BitonisMultiDropdown from "../../../../../../widgets/BitonisMultiDropdown";
import BitonisSingleDropdown from "../../../../../../widgets/BitonisSingleDropdown";
import CustomDesign from "../../../../../../widgets/CustomDesign";
function EducationAddModal({
  show,
  handleClose,
  university,
  setUniversity,
  section,
  setSection,
  sectionList,
  setSectionList,
  departmentGroupId,
  setDepartmentGroupId,
  educationList,
  setEducationList,
  universityListWhite,
  facultyDepAndGroupIdsRef,
  facultyDepAndGroupIdsRefTemp,
}) {
  function onHide() {
    handleClose(false);
  }

  var props = {
    show,
    onHide,
    university,
    setUniversity,
    section,
    setSection,
    sectionList,
    setSectionList,
    departmentGroupId,
    setDepartmentGroupId,
    educationList,
    setEducationList,
    universityListWhite,
    facultyDepAndGroupIdsRef,
    facultyDepAndGroupIdsRefTemp,
  };

  return (
    <>
      <div>
        <MyVerticallyCenteredModal props={props} />
      </div>
    </>
  );
}

function MyVerticallyCenteredModal({ props }) {
  const {
    show,
    onHide,
    university,
    setUniversity,
    section,
    setSection,
    sectionList,
    setSectionList,
    departmentGroupId,
    setDepartmentGroupId,
    educationList,
    setEducationList,
    universityListWhite,
    facultyDepAndGroupIdsRef,
    facultyDepAndGroupIdsRefTemp,
  } = props;

  const {
    register,
    handleSubmit,
    control,
    setValue,watch,getValues,
    formState: { errors },
  } = useForm();

  function submitEducation(form) {
    addEducation(
      university,
      setUniversity,
      section,
      setSection,
      departmentGroupId,
      setDepartmentGroupId,
      setEducationList,educationList,
      setValue,
      facultyDepAndGroupIdsRef,
      facultyDepAndGroupIdsRefTemp
    );
    onHide();
  }
  function departmentListGetsNotFaculty() {
    //üniversite id ==233 ise bu api çağrılır.
    setSectionList([]);
    api
      .get("common/FacultyDepartmentGroupList")
      .then((response) => {
        //  alert("gelen bölümler " + JSON.stringify(response));
        setSectionList(response.data);
      })
      .catch((err) => {
        setSectionList([]);
      });
  }

  function facultyDepartmentListGets(universyId) {
    setSectionList([]);
    //üniversite id ==233 değil ise bu api çağrılır.
    let id = {
      universityId: universyId.toString(),
    };
    api
      .post("/common/UniversityFacultyDepartmentList", id)
      .then((response) => {
        //  alert("gelen bölümler " + JSON.stringify(response));
        setSectionList(response.data);
      })
      .catch((err) => {
        setSectionList([]);
      });
  }

  useEffect(() => {
    if (show) {
      setValue("universityId", "");
      setValue("facultyDepartmentId", "");
    }
  }, [show]);
  return (
    <Modal
      {...props}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      dialogClassName="full-screen-modal" // Tam ekran sınıfı ekleyin
    >
      <Modal.Header
        closeButton
        style={{
          borderBottom: "none",
        }}
      >
        <Modal.Title
          aria-labelledby="contained-modal-title-vcenter"
          style={{
            width: "100%",
            textAlign: "center",
            margin: "0 auto",
          }}
          centered
        >
          Yeni Eğitim Ekle
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="basic-form" style={{ marginTop: "2px" }}>
          <form onSubmit={handleSubmit(submitEducation)}>
            <CustomDesign
              label={"Üniversite"}
              required={true}
              isColumn={true}
              child={
                <BitonisSingleDropdown
                  items={
                    universityListWhite &&
                    universityListWhite.map(({ id, name }) => ({
                      value: id,
                      label: name,
                    }))
                  }
                  register={register}
                  watch={watch}
                  companyClassName={"universityId"}
                  control={control}
                  setValue={setValue}
                  errors={errors}
                  placeholder="Üniversite Seçin"
                  onValueChanged={(e) => {
                    console.log(e);
                    setValue("facultyDepartmentId", "");
                    setSection(null);
                    setDepartmentGroupId(null);
                    if (e) {
                      setUniversity(e);
                      if (e.value === 233 || e.value == 234) {
                        departmentListGetsNotFaculty();
                      } else {
                        facultyDepartmentListGets(e.value);
                      }
                    } else {
                      setUniversity(null);
                      setSection(null);
                      setDepartmentGroupId(null);
                      setValue("facultyDepartmentId", "");
                    }
                  }}
                  required={true}
                  getValues={getValues}
                  errorMessage={"Üniversite boş geçilemez"}
                />
              }
            />

            <CustomDesign
              label={"Bölüm"}
              required={true}
              isColumn={true}
              child={
                <BitonisMultiDropdown
                  items={
                    sectionList &&
                    sectionList.map(({ id, name, departmentGroupId }) => ({
                      value: id,
                      label: name,
                      departmentGroupId: departmentGroupId,
                    }))
                  }
                  register={register}
                  watch={watch}
                  companyClassName={"facultyDepartmentId"}
                  control={control}
                  setValue={setValue}
                  errors={errors}
                  errorMessage={"Bölüm boş geçilemez"}
                  placeholder="Bölüm Seçin"
                  getValues={getValues}
                  onValueChanged={(e) => {
                    console.log(e);
                    var facultyDepAndGroupIds = [];
                    facultyDepAndGroupIdsRefTemp.current =
                      facultyDepAndGroupIds;

                    if (e) {
                      const selectedValues = e.map((option) => option.value);
                      if (university.value == 233) {
                        if (selectedValues.includes(698)) {
                          const selectedOption = e.find(
                            (option) => option.value === 698
                          );

                          if (selectedOption) {
                            [selectedOption].map((val) => {
                              facultyDepAndGroupIds.push({
                                facultyDepartmentGroupId: val.value,
                                facultyDepartmentId: 23069,
                                facultyDepartmentName: val.label,
                              });
                            });
                          } else {
                          }
                        } else {
                          e.map((val) => {
                            facultyDepAndGroupIds.push({
                              facultyDepartmentGroupId: val.value,
                              facultyDepartmentId: 23069,
                              facultyDepartmentName: val.label,
                            });
                          });
                        }
                      } else if (university.value == 234) {
                        if (selectedValues.includes(698)) {
                          const selectedOption = e.find(
                            (option) => option.value === 698
                          );
                          if (selectedOption) {
                            [selectedOption].map((val) => {
                              facultyDepAndGroupIds.push({
                                facultyDepartmentGroupId: val.value,
                                facultyDepartmentId: 25091,
                                facultyDepartmentName: val.label,
                              });
                            });
                          } else {
                          }
                        } else {
                          e.map((val) => {
                            facultyDepAndGroupIds.push({
                              facultyDepartmentGroupId: val.value,
                              facultyDepartmentId: 25091,
                              facultyDepartmentName: val.label,
                            });
                          });
                        }
                      } else {
                        if (selectedValues.includes(23069)) {
                          const selectedOption = e.find(
                            (option) => option.value === 23069
                          );
                          if (selectedOption) {
                            [selectedOption].map((val) => {
                              facultyDepAndGroupIds.push({
                                facultyDepartmentGroupId: val.departmentGroupId,
                                facultyDepartmentId: val.value,
                                facultyDepartmentName: val.label,
                              });
                            });
                          } else {
                          }
                        } else {
                          e.map((val) => {
                            facultyDepAndGroupIds.push({
                              facultyDepartmentGroupId: val.departmentGroupId,
                              facultyDepartmentId: val.value,
                              facultyDepartmentName: val.label,
                            });
                          });
                        }
                      }
                      facultyDepAndGroupIdsRefTemp.current =
                        facultyDepAndGroupIds;
                    } else {
                      var facultyDepAndGroupIds = [];
                    }
                  }}
                  required={true}
                  selectedId={
                    (university && university.value == 233) ||
                    (university && university.value == 234)
                      ? 698
                      : 23069
                  }
                />
              }
            />

          
          </form>
        </div>
      </Modal.Body>
      <Modal.Footer
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          alignContent: "center",
          borderTop: "none",
        }}
      >
        <form onSubmit={handleSubmit(submitEducation)}>
          <Button
            as="input"
            type="submit"
            value="Kaydet"
            onClick={handleSubmit(submitEducation)}
            style={{
              borderRadius: "8px",
              fontSize: "15px",
              padding: "15px",
              width: "300px", // Genişliği istediğiniz değere ayarlayın

              cursor: "pointer",
              transition: "background-color 0.3s",
            }}
          />
        </form>
      </Modal.Footer>
    </Modal>
  );
}
const customStyles = {
  control: (provided) => ({
    ...provided,
    borderRadius: "6px",
    padding: "10px 10px 10px 0px",
    border: "1px solid rgb(226, 226, 226)",
    fontSize: "15px",
    color: "#001542",
  }),
  option: (provided) => ({
    ...provided,
    fontSize: "15px", // Font boyutunu 9px olarak ayarlayın
  }),

  placeholder: (provided) => ({
    ...provided,
    fontSize: "13px", // Placeholder yazı boyutu
    marginLeft: "2px",
  }),
};

const labelStyle = {
  fontSize: "15px",
  marginRight: "0px",
  paddingRight: "0px",
};

export default EducationAddModal;
