import React, { useState, useEffect, useMemo } from "react";
import { useParams } from "react-router-dom";
import { Link } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Select from "react-select";

import { useForm, Controller } from "react-hook-form";
import {
  useHistory,
  useLocation,
} from "react-router-dom/cjs/react-router-dom.min";
import { connect, useDispatch } from "react-redux";
import EditCompanyLocationModal from "./EditCompanyLocationModal";
import { DropListAction } from "../../../../../../../../action";
import CustomPreview from "../../../../../../../widgets/CustomPreview";
import EditIconWidget from "../../../../../../../widgets/EditIconWidget";
import { Box } from "@mui/material";
import PageInfoTitle from "../../../../../../../widgets/PageInfoTitle";
import PrevIconButton from "../../../../../../../widgets/buttons/PrevIconButton";
const CompanyLocation = ({
  onSubmit,
  handleSubmit,
  register,
  inputStyle,
  cardStyle,
  companyList,
  props,
  job,
  customStyles,
  control,
  setValue,
  errors,
  watch,
  isValid,
  labelStyle,
  getValues,
  isIntern,
}) => {
  const [showEdit, setShowEdit] = useState(false);
  function handleEditClose() {
    setShowEdit(false);
  }
  useMemo(() => {
    if (props.job.job.companyId) {
      props.dispatch(
        DropListAction.companyItemLocation(props.job.job.companyId)
      );
    }
  }, [props.job.job.companyId]);
  return (
    <>
      <div class="card" style={cardStyle}>
        <div
          class="card-header"
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
            borderBottom: "none",
            marginBottom: "0px",
            paddingBottom: "0px",
          }}
        >
        
          <PrevIconButton top={0} left={-5} />
          <PageInfoTitle title={"Görüşme Lokasyonu"} />
          <Box
            sx={{
              position: "absolute",
              top: {
                xs: 40,
                sm: 65,
                md: 65,
                lg: 65,
                xl: 65,
              },
              right: {
                xs: 0,
                sm: 20,
                md: 15,
                lg: 15,
                xl: 15,
              },
              display: "flex",
              alignItems: "center",
              paddingRight: "30px",
            }}
          >
            <EditIconWidget
              size={20}
              onTap={() => {
                if (companyList.companyList) {
                  setShowEdit(true);
                }
              }}
              showContainerEdit={false}
              showEditText={false}
            />
          </Box>
        </div>
        <div class="card-body">
          <CustomPreview
            name={
              isIntern && isIntern == true
                ? "Stajyer Aradığınız Firma"
                : "Eleman Aradığınız Firma"
            }
            value={job.job.companyName}
          />
          <CustomPreview
            name={
              isIntern && isIntern == true
                ? "Görüşme Lokasyonu"
                : "İş Görüşme Lokasyonu"
            }
            value={job.job.companyLocationName}
          />
        </div>
      </div>
      <EditCompanyLocationModal
        show={showEdit}
        handleClose={handleEditClose}
        isValid={isValid}
        approval={() => {
          setShowEdit(false);
        }}
        companyList={companyList.companyList}
        job={job.job}
        onSubmit={onSubmit}
        props={props}
        register={register}
        customStyles={customStyles}
        handleSubmit={handleSubmit}
        control={control}
        setValue={setValue}
        errors={errors}
        watch={watch}
        labelStyle={labelStyle}
        getValues={getValues}
        isIntern={isIntern}
      />
      <PrevIconButton top={15} left={10} />
    </>
  );
};

export default CompanyLocation;

/*


 <div
              style={{
                width: "30px",
                height: "30px",
                margin: "0px 5px 0px 0px",
                padding: "0px",
              }}
            >
              <div
                style={{
                  backgroundColor: "green",
                  borderRadius: "6px",
                  width: "30px",
                  height: "30px",
                  alignItems: "center",
                  display: "flex",
                  justifyContent: "center",
                  transition: "fontSize 0.2s",
                }}
                onMouseOver={(e) => {
                  e.currentTarget.style.width = "35px";
                  e.currentTarget.style.height = "35px";
                }}
                onMouseOut={(e) => {
                  e.currentTarget.style.width = "30px";
                  e.currentTarget.style.height = "30px";
                }}
                onClick={() => {
                  if (companyList.companyList) {
                    setShowEdit(true);
                  }
                }}
              >
                <i
                  class="fa-solid fa-pen-to-square"
                  style={{
                    transition: "fontSize 0.2s",
                    color: "white",
                  }}
                ></i>
              </div>
            </div>
            <div
              style={{
                color: "#001542",
                fontWeight: "550",
                marginLeft: "6px",
              }}
            >
              Düzenle
            </div>
          </div>
        </div>

        */
