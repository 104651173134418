import { ToastContainer, toast } from "react-toastify";

export default function addEducation(
  university,
  setUniversity,
  section,
  setSection,
  departmentGroupId,
  setDepartmentGroupId,
  setEducationList,educationList,
  setValue,
  facultyDepAndGroupIdsRef,
  facultyDepAndGroupIdsRefTemp
) {
  facultyDepAndGroupIdsRef.current = facultyDepAndGroupIdsRefTemp.current;
  if (university && facultyDepAndGroupIdsRef.current) {
    var getEduList = [...educationList];
     if (
       getEduList.some(
         (addedUniversity) => addedUniversity.universityId === university.value
       )
     ) {
         setUniversity(null);
         setSection(null);
         setDepartmentGroupId(null);
         setValue("universityId", "");
         setValue("facultyDepartmentId", "");
        toast.info("Bu Üniversiteyi Daha Önce Eklediğiniz", {
          autoClose: 3000,
          onClose: () => {
            setTimeout(() => {}, 100);
          },
        });
     } else {

       if (
         university &&
         facultyDepAndGroupIdsRefTemp.current &&
         facultyDepAndGroupIdsRefTemp.current.length
       ) {
         var map = {
           universityId: university.value,
           universityName: university.label,
           facultyDepAndGroupIds: facultyDepAndGroupIdsRef.current,
         };

         setEducationList((prev) => [...prev, map]);
         facultyDepAndGroupIdsRef.current = [];
         facultyDepAndGroupIdsRefTemp.current = [];
         setUniversity(null);
         setSection(null);
         setDepartmentGroupId(null);
         setValue("universityId", "");
         setValue("facultyDepartmentId", "");
         toast.info("Eğitim başarılı şekilde eklendi", {
           autoClose: 3000,
           onClose: () => {
             setTimeout(() => {}, 100);
           },
         });
       } else {
              facultyDepAndGroupIdsRef.current = [];
              facultyDepAndGroupIdsRefTemp.current = [];
              setUniversity(null);
              setSection(null);
              setDepartmentGroupId(null);
              setValue("universityId", "");
              setValue("facultyDepartmentId", "");
       }
       
     }
   
  }
}
