import React, { useEffect, useMemo, useState } from "react";
import EditPreferredLocationModal from "./EditPreferredLocationModal";
import { DropListAction } from "../../../../../../../../action";
import getMultiSemt from "../functions/preferredGetMultiSemt";
import AddPreferredLocationModal from "./AddPreferredLocationModal";
import { addPreferredLocation } from "../../../../../../../../redux/redux-toolkit/preferredListSlice";
import { useDispatch } from "react-redux";
import { DropListServices } from "../../../../../../../../services";
import CustomPreview from "../../../../../../../widgets/CustomPreview";
import { Box } from "@mui/material";
import EditIconWidget from "../../../../../../../widgets/EditIconWidget";
import PageInfoTitle from "../../../../../../../widgets/PageInfoTitle";
import CustomPreviewNoTitle from "../../../../../../../widgets/CustomPreviewNoTitle";
import CustomLocationNoTitle from "../../../../../../../widgets/CustomLocationNoTitle";

const PreferredLocationDetail = ({
  register,
  onSubmit,
  handleSubmit,
  inputStyle,
  cardStyle,
  job,
  props,
  customStyles,
  customStylesMulti,
  positionList,
  isValid,
  control,
  setValue,
  errors,
  watch,
  getValues,
  preferredLocationRef,
  isOnIntern,
}) => {
  const dispatch = useDispatch();
  const [lat, setLat] = useState("");
  const [lng, setLng] = useState("");
  const [semtNewList, setSemtNewList] = useState([]);
  useEffect(() => {
    preferredLocationRef.current = [];
    if (props.job.job.jobPreferredLocations) {
      const preferredLocations = props.job.job.jobPreferredLocations;

      const newPreferredLocationList = preferredLocations.map((location) => {
        if (
          location.districts === undefined ||
          location.districts == [] ||
          location.counties == [] ||
          location.counties == undefined
        ) {
          var adres = location.countryName + "/" + location.cityName;
          getLatLngApi(adres);
        } else {
          var adres = location.countryName + "/" + location.cityName;

          getLatLngApi(adres);
        }
        return {
          countryId: { label: location.countryName, value: location.countryId },
          cityId: { label: location.cityName, value: location.cityId },
          countyId:
            location.counties == []
              ? []
              : location.counties &&
                location.counties.map((item) => ({
                  label: item.name,
                  value: item.id,
                })),
          districts:
            location.districts == []
              ? []
              : location.districts.map((item) => ({
                  label: item.name,
                  value: item.id,
                })),
          lat: lat.toString() ?? "",
          lng: lng.toString() ?? "",
        };
      });

      preferredLocationRef.current = [
        ...preferredLocationRef.current,
        ...newPreferredLocationList,
      ];
    }
  }, [props.job.job.jobPreferredLocations]);
  async function getLatLngApi(adress) {
    DropListServices.handleGetLocation(adress).then(
      (loc) => {
        if(loc){
          var l = loc.lat;
          var lg = loc.lng;
          setLat(l);
          setLng(lg);
        }
       
      },
      (error) => {}
    );
  }
  const [showEdit, setShowEdit] = useState(null);
  function handleCloseEdit() {
    setShowEdit(false);
  }
  const [showAdd, setShowAdd] = useState(null);
  function handleCloseAdd() {
    setShowAdd(false);
  }
  const [indexItem, setIndexItem] = useState(null);
  const [locationItem, setLocationItem] = useState(null);

  return (
    <div>
      <>
        <div class="card" style={cardStyle}>
          {" "}
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-end",
              alignItems: "center",
              marginTop: "0px",
              paddingTop: "0px",
              marginBottom: "0px",
              marginRight: "10px",
              padding: "0px",
              cursor: "pointer",
            }}
            onClick={() => {
              setShowAdd(true);
            }}
          >
            <div
              style={{
                width: "30px",
                height: "30px",
                margin: "0px 5px 0px 0px",
                padding: "0px",
              }}
            >
              <div
                style={{
                  backgroundColor: "green",
                  borderRadius: "6px",
                  width: "30px",
                  height: "30px",
                  alignItems: "center",
                  display: "flex",
                  justifyContent: "center",
                  transition: "fontSize 0.2s",
                }}
                onMouseOver={(e) => {
                  e.currentTarget.style.width = "35px";
                  e.currentTarget.style.height = "35px";
                }}
                onMouseOut={(e) => {
                  e.currentTarget.style.width = "30px";
                  e.currentTarget.style.height = "30px";
                }}
              >
                {" "}
                <i
                  class="fa-solid fa-plus"
                  style={{
                    transition: "fontSize 0.2s",
                    color: "white",
                  }}
                ></i>
              </div>
            </div>
            <div
              style={{
                color: "#001542",
                fontWeight: "550",
                marginLeft: "6px",
              }}
            >
              Yeni Şehir/Bölge Ekle
            </div>
          </div>
          <div
            class="card-header"
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
              borderBottom: "none",
              marginBottom: "0px",
              paddingBottom: "0px",
            }}
          >
            {" "}
            {isOnIntern && isOnIntern === true ? (
              <PageInfoTitle
                title={"Stajyer Aradığınız Güzergahlar"}
                bottomPadding={"0px"}
              />
            ) : (
              <PageInfoTitle
                title={"Eleman Aradığınız Güzergahlar"}
                bottomPadding={"0px"}
              />
            )}
          </div>
          <div class="card-body">
            <div className="basic-form">
              <div className="form-group row">
                {preferredLocationRef.current &&
                  preferredLocationRef.current.map((location, index) => (
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        width: "100%",
                        justifyContent: "center",
                        alignItems: "center",
                        alignContent: "center",
                        justifyItems: "center",
                        position: "relative",
                        margin:"0px",
                        padding:"0px"
                      }}
                    >
                      <div
                        class="card-body"
                        style={{
                          marginTop: "0px",
                          paddingTop: "0px",
                        }}
                      >
                        <CustomLocationNoTitle
                          name={""}
                          value={
                            location.countryId.label +
                            "/" +
                            location.cityId.label
                          }
                        />
                      </div>
                      <Box
                        sx={{
                          position: "absolute",
                          top: {
                            xs: 12,
                            sm: 12,
                            md: 12,
                            lg: 12,
                            xl: 12,
                          },
                          right: {
                            xs: -25,
                            sm: -20,
                            md: -20,
                            lg: -20,
                            xl: -20,
                          },
                          marginLeft: "0px",
                          display: "flex",
                          alignItems: "center",
                          paddingRight: "20px",
                        }}
                      >
                        <EditIconWidget
                          size={17}
                          onTap={() => {
                            setLocationItem(location);
                            setIndexItem(index);
                            setTimeout(() => {
                              props.dispatch(
                                DropListAction.allCountyList(
                                  location.cityId.value
                                )
                              );
                              if (location.countyId && location.countyId) {
                                getMultiSemt(
                                  false,
                                  location.countyId && location.countyId,
                                  semtNewList,
                                  setSemtNewList
                                );
                              }
                            }, 300);
                            setShowEdit(true);
                          }}
                          showContainerEdit={false}
                          showEditText={false}
                        />
                      </Box>
                    </div>
                  ))}
              </div>
            </div>
          </div>
        </div>
        <EditPreferredLocationModal
          show={showEdit}
          handleClose={handleCloseEdit}
          job={job.job}
          onSubmit={onSubmit}
          props={props}
          customStyles={customStyles}
          inputStyle={inputStyle}
          customStylesMulti={customStylesMulti}
          isValid={isValid}
          register={register}
          handleSubmit={handleSubmit}
          control={control}
          setValue={setValue}
          errors={errors}
          watch={watch}
          semtNewList={semtNewList}
          setSemtNewList={setSemtNewList}
          indexItem={indexItem}
          setIndexItem={setIndexItem}
          location={locationItem}
          preferredLocationRef={preferredLocationRef}
          getValues={getValues}
          isOnIntern={isOnIntern}
        />
        <AddPreferredLocationModal
          show={showAdd}
          handleClose={handleCloseAdd}
          job={job.job}
          onSubmit={onSubmit}
          props={props}
          customStyles={customStyles}
          inputStyle={inputStyle}
          customStylesMulti={customStylesMulti}
          isValid={isValid}
          register={register}
          handleSubmit={handleSubmit}
          control={control}
          setValue={setValue}
          errors={errors}
          watch={watch}
          semtNewList={semtNewList}
          setSemtNewList={setSemtNewList}
          preferredLocationRef={preferredLocationRef}
          getValues={getValues}
          isOnIntern={isOnIntern}
        />
      </>
    </div>
  );
};

export default PreferredLocationDetail;
