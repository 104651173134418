import React from 'react'
import CustomPreview from '../../../../../../../../widgets/CustomPreview';
import CustomPreviewHobbies from '../../../../../../../../widgets/CustomPreview.Hobbies';

const WhiteHobbies = ({ whiteMatchDetail }) => {
	return (
    <div>
      <div
        class="card"
        style={{
          margin: "0px 5% 5px 5%",
          padding: "0px",
          border: "0.5px solid #F7F7F7",
          backgroundColor: "F7F7F7",
        }}
      >
        <div
          class="card-header"
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
            borderBottom: "none",
            marginBottom: "0px",
            paddingBottom: "0px",
          }}
        >
          <h5
            class="card-title"
            style={{
              fontSize: "17px",
            }}
          >
            Hobileri
          </h5>
        </div>
        <div
          class="card-body"
          style={{
            marginTop: "0px",
            paddingTop: "10px",
          }}
        >
          <CustomPreviewHobbies
            name={""}
            value={whiteMatchDetail && whiteMatchDetail.matchHobbies}
          />
        </div>
      </div>
    </div>
  );
};

export default WhiteHobbies