import React, { useEffect, useState } from "react";

import Modal from "react-bootstrap/Modal"; // Varsayılan olarak React Bootstrap Modal bileşeni kullanılıyor
import { Alert, Button } from "react-bootstrap";

function RouteIsEmptyModal({ show, handleClose }) {
  return (
    <>
      <div>
        <Modal
          className="fade bd-example-modal-lg"
          size="lg"
          show={show}
          onHide={handleClose}
          backdrop="static"
          keyboard={false}
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title>Uyarı</Modal.Title>
          </Modal.Header>
          <Modal.Body className="grid-example">
            <h6>Güzergah Boş Geçilemez</h6>
          </Modal.Body>
          <Modal.Footer>
            <div className="container">
         
                <Button
                  as="input"
                  type="button"
                  value="Tamam"
                  style={{
                    borderRadius: "8px",
                    width: "100%",
                  }}
                  onClick={handleClose}
                />
              </div>
         
          </Modal.Footer>
        </Modal>
      </div>
    </>
  );
}

export default RouteIsEmptyModal;
