import Modal from "react-bootstrap/Modal"; // Varsayılan olarak React Bootstrap Modal bileşeni kullanılıyor
import React, { useEffect, useMemo, useState } from "react";
import { Button } from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";
import Select from "react-select";
import { connect, useDispatch } from "react-redux";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { DropListAction, TaxInfoAction } from "../../../../../../../action";
import api from "../../../../../../../utility/Utils";
import { customStyles } from "../../../../../../customStyleInline";
import { DropListServices } from "../../../../../../../services";
import BitonisSingleDropdown from "../../../../../../widgets/BitonisSingleDropdown";
import CustomDesign from "../../../../../../widgets/CustomDesign";
import SelectSingleRoute from "../../../../../../widgets/components/common/SelectSingleRoute";
import CustomButton from "../../../../../../widgets/buttons/CustomButton";
import GoogleMapWidget from "../../../../../../widgets/map/GoogleMapWidget";
function EmployerGroupCompaniesAddLocation({
  show,
  handleClose,
  props,
  companyId,
  companyNameForLocation,
}) {
  const {
    register,
    handleSubmit,
    control,
    watch,
    reset,
    setValue,
    getValues,
    formState: { errors },
  } = useForm();
  const [lat, setLat] = useState("");
  const [lng, setLng] = useState("");
  //alert("company ** * " + JSON.stringify(company));
  useEffect(() => {
    if (!props.countryList.success) {
      props.dispatch(DropListAction.countryList());
    }

    props.dispatch(
      DropListAction.cityList({
        countryId: 1,
      })
    );

    if (!props.taxOffices.success) {
      props.dispatch(TaxInfoAction.taxOffices({}));
    }
  }, []);

  useEffect(() => {
    if (!props.sectorList.success) {
      props.dispatch(DropListAction.sectorList({}));
    }
  }, [props.sectorList.success]);

  const handleCity = (selected) => {
    props.dispatch(DropListAction.countyList(selected.value));
  };
  const handleDistry = (selected) => {
    props.dispatch(DropListAction.districtList(selected.value));
  };
  async function getLatLngApi(adress) {

    DropListServices.handleGetLocation(adress).then((location) => {
      // alert("lat " + location.lat + " " + "lng " + location.lng);
      if (location) {
        var latitute = location.lat;
        var longtitute = location.lng;
        setLat(latitute);
        setLng(longtitute);
        setLat(latitute);
        setLng(longtitute);
      } else {
      }
    });
  }
  function addLocationFetch(arg) {
    return api
      .post("/company/location/create", arg)
      .then((response) => {
        return Promise.resolve(response);
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  }

  var adress = watch("adress");
  var countryId = watch("countryId");
  var cityId = watch("cityId");
  var countyId = watch("countyId");
  var districtsId = watch("districts");



  function addLocation(formData) {
    //Bu işlem de bitti

    let sendData = {
      companyId: companyId,
      locationName: formData.locationName.toString(),
      countryId: parseInt(formData.countryId.value.toString()),
      cityId: parseInt(formData.cityId.value.toString()),
      countyId: parseInt(formData.countyId.value.toString()),
      districtId: parseInt(formData.districts.value.toString()),
      address: formData.adress.toString(),
      latitude: lat.toString(),
      longitude: lng.toString(),
      phoneNumber: "",
      sectorId: parseInt(formData.sector.value.toString()),
      isActive: true,
      isDeleted: false,
    };
    addLocationFetch(sendData).then(
      (addLocationGetRequest) => {
        handleClose(true);

        if (addLocationGetRequest) {
          if (addLocationGetRequest.succeeded === true) {
            toast.success("Lokasyon Başarılı Şekilde Eklendi", {
              autoClose: 2000,
              onClose: () => {
                setTimeout(() => {
                  window.location.href = window.location.href;
                }, 100);
              },
            });
          } else {
            toast.info(addLocationGetRequest.error.message, {
              autoClose: 2000,
              onClose: () => {
                setTimeout(() => {
                  window.location.href = window.location.href;
                }, 100);
              },
            });
          }
        }
      },
      (error) => {
        toast.info("Hata Oluştu", {
          autoClose: 2000,
          onClose: () => {
            setTimeout(() => {
              window.location.href = window.location.href;
            }, 100);
          },
        });
      }
    );
  }

  return (
    <Modal
      className="fade bd-example-modal-lg"
      size="lg"
      show={show}
      onHide={handleClose}
      backdrop="static"
      keyboard={false}
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header
        closeButton
        style={{
          borderBottom: "none",
          marginBottom: "0px",
          paddingBottom: "0px",
        }}
      >
        <Modal.Title
          aria-labelledby="contained-modal-title-vcenter"
          style={{
            width: "100%",
            textAlign: "center",
            margin: "0 auto",
          }}
        >
          <h4 style={{ color: "#001542", fontWeight: "600" }}>
            {" "}
            Lokasyon Ekle
          </h4>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body
        className="grid-example"
        style={{
          marginBottom: "0px",
          paddingBottom: "10px",
        }}
      >
        <div className="container mt-0">
          <form onSubmit={handleSubmit(addLocation)}>
            <div className="row">
              <CustomDesign
                label={"Sektör"}
                required={true}
                isColumn={true}
                columnClassName={"col-xs-6 col-md-6"}
                child={
                  <BitonisSingleDropdown
                    items={
                      props.sectorList.success &&
                      props.sectorList.sectorList.map(({ id, name }) => ({
                        value: id,
                        label: name,
                      }))
                    }
                    register={register}
                    watch={watch}
                    companyClassName={"sector"}
                    control={control}
                    setValue={setValue}
                    errors={errors}
                    placeholder="Sektör Seç"
                    onValueChanged={(e) => {
                      console.log(e);
                      if (e) {
                      }
                    }}
                    required={true}
                    getValues={getValues}
                    errorMessage={"Sektör Boş Olamaz "}
                  />
                }
              />
              <CustomDesign
                label={"Lokasyon Adı"}
                required={true}
                isColumn={true}
                columnClassName={"col-xs-6 col-md-6"}
                child={
                  <>
                    <input
                      type="text"
                      style={{
                        marginTop: "0px",
                      }}
                      className="form-control"
                      id="inputClass"
                      rules={{ required: true }}
                      name="locationName"
                      {...register("locationName", { required: true })}
                      placeholder="Lokasyon Adı"
                    ></input>
                    {errors.locationName && (
                      <div className="text-danger fs-12">
                        Lokasyon Adı Boş Olamaz
                      </div>
                    )}
                  </>
                }
              />
            </div>

            <SelectSingleRoute
              props={props}
              register={register}
              watch={watch}
              control={control}
              setValue={setValue}
              errors={errors}
              getValues={getValues}
              getLatLngApi={getLatLngApi}
              preview={false}
              employerCompanyName={companyNameForLocation}
            />
            <GoogleMapWidget
              initialLatLng={{
                lat: lat,
                lng: lng,
              }}
              onChangedPositioned={(position) => {
                setLat(position.lat);
                setLng(position.lng);
              }}
              heightMap={"60vh"}
            />
            <div className="row">
              <CustomDesign
                label={"Şirket Adresi"}
                required={true}
                isColumn={true}
                columnClassName={"col-xs-12 col-md-12"}
                child={
                  <>
                    {
                      <textarea
                        name="adress"
                        id="noter-text-area-active"
                        {...register("adress", {
                          required: true,
                        })}
                      ></textarea>
                    }
                    {errors.adress && (
                      <div className="text-danger fs-12">
                        {"Şirket Adresi Boş Olamaz "}
                      </div>
                    )}
                  </>
                }
              />
            </div>
          </form>
        </div>
      </Modal.Body>
      <Modal.Footer
        style={{
          border: "none",
          marginTop: "0px",
          paddingTop: "0px",
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          alignContent: "center",
          alignItems: "center",
        }}
      >
        <form onSubmit={handleSubmit(addLocation)}>
          <CustomButton
            title={"Kaydet"}
            width={{
              xs: "400px",
              sm: "400px",
              md: "400px",
              lg: "400px",
              xl: "400px",
            }}
          />
        </form>
      </Modal.Footer>
    </Modal>
  );
}

export default EmployerGroupCompaniesAddLocation;
