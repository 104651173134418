import React from "react";
import CustomPdfPreview from "../../../../../../../../widgets/CustomPdfPreview";
import DividerDotted from "../../../../../../../../widgets/DividerDotted";
import { DividerPdf } from "./DividerPdf";
import moment from "moment";

const EducationWhiteCv = ({ whiteMatchDetail }) => {
  return (
    <div
      className="card"
      style={{
        flex: "6",
        marginTop: "0px",
        display: "flex",
        flexDirection: "column",
        margin: "0px",
        padding: "0px",
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          marginBottom: "10px",
          padding: "0px",
        }}
      >
        <div
          style={{
            border: "2px solid grey",
            borderRadius: "8px",

            height: "40px",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignContent: "center",
          }}
        >
          <p
            style={{
              padding: "10px",
              margin: "0px",
              fontSize: "10px",
              color: "#001542",
              fontWeight: "500",
            }}
          >
            Eğitim Bilgileri
          </p>
        </div>
        <hr
          style={{
            color: "black",
            marginLeft: "0px",
            paddingLeft: "0px",
            border: "1px solid grey",
            flex: "6",
            margin: "0px",
            padding: "0px",
          }}
        />
        <div
          style={{
            width: "40px",
            height: "40px",
            borderRadius: "50%",
            border: "1.8px solid grey",
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <i
            class="fa-solid fa-user-graduate"
            style={{
              color: "orange",
              fontSize: "12px",
            }}
          ></i>
        </div>
      </div>
      <div
        style={{
          backgroundColor: "#f7f7f7",
          borderRadius: "8px",
          marginTop: "0px",
          marginBottom: "30px",
        }}
      >
        <div
          style={{
            margin: "10px",
          }}
        ></div>
        {whiteMatchDetail &&
          whiteMatchDetail.educationList.map((edu, index) => {
            if (
              edu.educationTypeId === 1 ||
              edu.educationTypeId === 2 ||
              edu.educationTypeId === 3
            ) {
              return (
                <div style={{}}>
                  <p
                    style={{
                      paddingLeft: "5px",
                      color: "#001542",
                      fontSize: "9px",
                      fontWeight: "500",
                      paddingLeft: "10px",
                      paddingTop: "0px",
                      width: "80px",
                    }}
                  >
                    {edu.educationName}
                  </p>
                  {index !== whiteMatchDetail.educationList.length - 1 && (
                    <hr style={{ borderTop: "1px dotted orange" }}></hr>
                  )}
                </div>
              );
            } else {
              return (
                <div>
                  {" "}
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <p
                      style={{
                        paddingLeft: "5px",
                        color: "#001542",
                        fontSize: "9px",
                        fontWeight: "500",
                        paddingLeft: "10px",
                        paddingTop: "5px",
                        width: "80px",
                      }}
                    >
                      {edu.educationName}
                    </p>{" "}
                    <div
                      style={{
                        width: "50px",
                      }}
                    ></div>
                    <div
                      style={{
                        paddingRight: "10px",
                      }}
                    ></div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                      }}
                    >
                      <p
                        style={{
                          color: "grey",
                          fontSize: "9px",
                          fontWeight: "normal",
                          margin: "0px",
                          padding: "0px",
                        }}
                      >
                        Üniversite
                      </p>
                      <p
                        style={{
                          margin: "0px",
                          fontSize: "9px",
                          color: "#001542",
                          fontWeight: "500",
                        }}
                      >
                        {edu.universityName}
                      </p>{" "}
                      <p
                        style={{
                          color: "grey",
                          fontSize: "9px",
                          fontWeight: "normal",
                          margin: "0px",
                          padding: "0px",
                        }}
                      >
                        Bölüm
                      </p>
                      <p
                        style={{
                          margin: "0px",
                          fontSize: "9px",
                          color: "#001542",
                          fontWeight: "500",
                        }}
                      >
                        {edu.sectionName}
                      </p>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "space-between",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                          }}
                        >
                          {" "}
                          <p
                            style={{
                              color: "grey",
                              fontSize: "9px",
                              fontWeight: "normal",
                              margin: "0px",
                              padding: "0px",
                            }}
                          >
                            Başlangıç Tarihi
                          </p>
                          <p
                            style={{
                              margin: "0px",
                              fontSize: "9px",
                              color: "#001542",
                              fontWeight: "500",
                            }}
                          >
                            {moment(
                              whiteMatchDetail &&
                                whiteMatchDetail.educationStartDate
                            ).format("MM.YYYY")}
                          </p>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            marginRight: "30px",
                          }}
                        ></div>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                          }}
                        >
                          <ShowStatusFinish
                            finishDate={
                              whiteMatchDetail && whiteMatchDetail.finishDate
                            }
                            present={
                              whiteMatchDetail && whiteMatchDetail.present
                            }
                            leavingSchool={
                              whiteMatchDetail && whiteMatchDetail.leavingSchool
                            }
                          />
                        </div>
                      </div>
                    </div>
                  </div>{" "}
                  {index !== whiteMatchDetail.educationList.length - 1 && (
                    <hr style={{ borderTop: "1px dotted orange" }}></hr>
                  )}
                </div>
              );
            }
          })}
        <div
          style={{
            paddingBottom: "20px",
          }}
        ></div>
      </div>
    </div>
  );
};
const ShowStatusFinish = ({ finishDate, present, leavingSchool }) => {
  if (present === true) {
    return (
      <p
        style={{
          margin: "0px",
          fontSize: "9px",
          color: "#001542",
          fontWeight: "500",
        }}
      >
        Devam Ediyorum
      </p>
    );
  } else if (leavingSchool === true) {
    return (
      <p
        style={{
          margin: "0px",
          fontSize: "9px",
          color: "#001542",
          fontWeight: "500",
        }}
      >
        Terk
      </p>
    );
  } else {
    return (
      <div>
        <p
          style={{
            color: "grey",
            fontSize: "9px",
            fontWeight: "normal",
            margin: "0px",
            padding: "0px",
          }}
        >
          Bitiş Tarihi
        </p>
        <p
          style={{
            margin: "0px",
            fontSize: "9px",
            color: "#001542",
            fontWeight: "500",
          }}
        >
          {moment(finishDate && finishDate).format("MM.YYYY")}
        </p>
      </div>
    );
  }
};
export default EducationWhiteCv;
