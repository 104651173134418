import React from "react";

const Evaluate = ({ onTap, matchData }) => {
  function checkMeetingDateValidity(meetingDate) {
    var meetingDateObj = new Date(meetingDate);
    var oneHourLater = new Date(meetingDateObj.getTime() + 60 * 60 * 1000); // Add one hour
    var thirtyDaysLater = new Date(
      meetingDateObj.getTime() + 30 * 24 * 60 * 60 * 1000
    ); // Add 30 days
    return oneHourLater < new Date() && thirtyDaysLater > new Date();
  }
  // Kullanım:
  var isValid = checkMeetingDateValidity(matchData&&matchData.meetingDate);
  console.log(isValid);
  return (
    <div>
      {isValid===true?(
        <div onClick={onTap}>
          <div
            style={{
              position: "absolute",
              right: "0px",
              bottom: "0px",
              fontSize: "9px",
              border: "1px solid orange",
              borderRadius: "3px",
              color: "#001542",
              display: "flex",
              flexDirection: "row",
              alignContent: "center",
              alignItems: "center",
              padding: "0px",
              cursor: "pointer",
            }}
          >
            <i
              class="fa-regular fa-star"
              style={{
                color: "orange",
                margin: "0px 1px 0px 1px",
              }}
            ></i>
            <p
              style={{
                padding: "1px 5px 1px 2px",
                margin: "0px",
              }}
            >
              Değerlendir
            </p>
          </div>
        </div>
      ):<div></div>}
    </div>
  );
};

export default Evaluate;
