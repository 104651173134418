import React, { useEffect, useState } from "react";

import Modal from "react-bootstrap/Modal"; // Varsayılan olarak React Bootstrap Modal bileşeni kullanılıyor
import { Alert, Button } from "react-bootstrap";
import PageInfoTitle from "../../../../../../../widgets/PageInfoTitle";

function FilterInternModal({
  show,
  handleClose,
  approval,
  demandFilterList,
  selectedStatus,
}) {
  return (
    <>
      <div>
        <MyVerticallyCenteredModal
          show={show}
          onHide={() => handleClose(false)}
          approval={approval}
          demandFilterList={demandFilterList}
          selectedStatus={selectedStatus}
        />
      </div>
    </>
  );
}
function MyVerticallyCenteredModal(props) {
  const { show, approval, demandFilterList, selectedStatus } = props;
  const [selected, setSelected] = useState([]);
  useEffect(() => {
    if (show) {
      setSelected([]);
      selectedStatus.map((value) => {
        setSelected((prev) => [...prev, value]);
      });
    }
  }, [show]);
  const toggleSelection = (item) => {
    if (selected.some((selectedItem) => selectedItem.id === item.id)) {
      setSelected((prev) =>
        prev.filter((selectedItem) => selectedItem.id !== item.id)
      );
    } else {
      setSelected([...selected, item]);
    }
  };
  return (
    <Modal
      {...props}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      dialogClassName="full-screen-modal"
    >
       <Modal.Header
        closeButton
        style={{ borderBottom: "none", justifyContent: "center" }}
      >
        <PageInfoTitle title={"Stajyer Filtrele"} />
      </Modal.Header>
      <Modal.Body
        style={{
          paddingBottom: "0px",
          marginBottom: "0px",
          paddingTop: "0px",
          marginTop: "0px",
        }}
      >
        <div>
          {demandFilterList.map((item, index) => {
            return (
              <div
                key={item.status}
                style={{
                  paddingLeft: 5,
                  color: "#001542",
                  border: "none",
                  margin: "0px 0px 10px 0px",
                  borderRadius: "8px",
                  width: "100%",
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  backgroundColor: "rgb(242, 243, 244)",
                  alignContent: "center",
                  alignItems: "center",
                }}
              >
                <div style={{ display: "flex", alignItems: "center" }}>
                  <input
                    type="checkbox"
                    style={{
                      marginLeft: "10px",
                    }}
                    checked={
                      selected &&
                      selected.some(
                        (selectedItem) => selectedItem.id === item.id
                      )
                    }
                    onChange={() => toggleSelection(item)}
                  />
                  <button
                    onClick={(event) => {
                      event.preventDefault();
                      toggleSelection(item);
                    }}
                    style={{
                      color: "#001542",
                      padding: "10px",
                      outline: "none",
                      border: "none",
                      margin: "10px 0px 10px 0px",
                      borderRadius: "8px",
                      width: "100%",
                      display: "flex",
                      justifyContent: "start",
                      backgroundColor: "transparent",
                      width: "400px",
                    }}
                  >
                    <span
                      style={{
                        fontSize: 14,
                        fontWeight: 600,
                        color: "#001542",
                        paddingLeft: "20px",
                      }}
                    >
                      {item.title}
                    </span>
                  </button>{" "}
                </div>
              </div>
            );
          })}
        </div>
      </Modal.Body>
      <Modal.Footer
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          alignContent: "center",
          borderTop: "none",   paddingTop: "0px",
          marginTop: "0px",
        }}
      >
        <Button
          as="input"
          type="button"
          value="Filtrele"
          onClick={() => {
            if (selected.length > 0) {
              approval(selected);
            } else {
              approval(null);
            }
          }}
          style={{
            borderRadius: "8px",
            fontSize: "15px",
            padding: "15px",
            width: "300px",
            cursor: "pointer",
            transition: "background-color 0.3s",
            backgroundColor: "#001542",
            border: "none",
          }}
        />
      </Modal.Footer>
    </Modal>
  );
}
export default FilterInternModal;
