import { Box, Typography } from "@mui/material";
import React from "react";

const EditIconWidget = ({
  onTap,
  size,
  color,
  showEditText,
  editTextDirection,
  textFontSize,
  showContainerEdit,
}) => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection:
          editTextDirection == undefined || editTextDirection == null
            ? "column"
            : editTextDirection,
        justifyContent: "center",
        alignContent: "center",
        alignItems: "center",
        cursor: "pointer",
        marginBottom: "0px",
        paddingBottom: "0px",
      }}
      onClick={onTap}
    >
      <Box
        style={
          showContainerEdit == undefined ||
          showContainerEdit == null ||
          showContainerEdit == false
            ? {}
            : {
                backgroundColor: "green",
                borderRadius: "6px",
                width: "30px",
                height: "30px",
                alignItems: "center",
                display: "flex",
                justifyContent: "center",
                transition: "fontSize 0.2s",
              }
        }
      >
        <i
          class="fa-solid fa-pencil"
          style={{
            fontSize: size,
            cursor: "pointer",
            color:
              color == undefined || color == null
                ? showContainerEdit == undefined ||
                  showContainerEdit == null ||
                  showContainerEdit == false
                  ? "#001542"
                  : "white"
                : color,
          }}
        ></i>
      </Box>
      <Typography
        sx={{
          display:
            showEditText == undefined ||
            showEditText == null ||
            showEditText == true
              ? ""
              : "none",
          fontSize:
            textFontSize == null || textFontSize == undefined
              ? {
                  xs: "9px",
                  sm: "9px",
                  md: "11px",
                  lg: "11px",
                  xl: "11px",
                }
              : textFontSize,
          padding:
            editTextDirection == undefined || editTextDirection == null
              ? "0px"
              : "5px",
          fontWeight: 600,
          color: "#001542",
          textAlign: "center",
        }}
      >
        Düzenle
      </Typography>
    </div>
  );
};

export default EditIconWidget;
