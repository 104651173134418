export default function DividerDotted() {
  return (
    <div>
      <hr
        style={{
          marginLeft: "10%",
          marginRight: "10%",
          marginTop: "0px",
          marginBottom: "0px",
          border: "none",
          borderTop: "1.2px dotted orange",
          color: " #fff",
          backgroundColor: " #fff",
          height: "1px",
        }}
      ></hr>
    </div>
  );
}
