import React, { useEffect, useState } from "react";
import useIsMobile from "../../../hooks/useIsMobile";
import { ProfileDetailServices } from "../../../../services";
import LoadingDotsAnimation from "../../../widgets/LoadingDotsAnimation";
import { Col, Row } from "react-bootstrap";
import CustomDesign from "../../../widgets/CustomDesign";
import CustomPreview from "../../../widgets/CustomPreview";
import DemandNotFound from "../Application/Employer/Demand/DemandAllShow/Common/DemandNotFound";
import CouponNotFound from "../Application/Employer/Demand/DemandAllShow/Common/CouponNotFound";
import { Link } from "react-router-dom/cjs/react-router-dom.min";

const DiscountCoupons = () => {
  //indirim kupon listeme sayfası
  const isMobile = useIsMobile();
  const [couponList, seCouponList] = useState(null);

  function getCouponList() {
    ProfileDetailServices.getCouponList().then(
      (couponResponse) => {
        if (couponResponse) {
          if (couponResponse && couponResponse.succeeded) {
            seCouponList([]);
            couponResponse.data.map((value) => {
              seCouponList((prev) => [...prev, value]);
            });
          } else {
          }
        }
      },
      (error) => {}
    );
  }

  useEffect(() => {
    getCouponList();
  }, []);

  return (
    <div
      style={{
        margin: isMobile ? "0px 20px 0px 20px" : "0px 70px 0px 70px",
      }}
      className="card"
    >
      <p
        style={{
          color: "#001542",
          fontWeight: "600",
          padding: "0px",
          margin: "0px",
          textAlign: "center",
          marginTop: "10px",
        }}
      >
        İndirim Kuponlarım
      </p>
      {couponList === null ? (
        <div >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
            }}
          >
            <LoadingDotsAnimation fontSize={40} />
          </div>
        </div>
      ) : (
        <div>
          {couponList && couponList.length > 0 ? (
            <p
              style={{
                margin: "20px",
                color: "#001542",
                fontSize: isMobile ? "12px" : "13px",
                fontWeight: 500,
              }}
            >
              <i
                class="fa-solid fa-bell"
                style={{
                  margin: "10px",
                  color: "#fdc303",
                  fontSize: isMobile ? "15px" : "20px",
                }}
              ></i>
              Size özel sunduğumuz indirim kuponlarıyla, satın alma işlemi
              sırasında kupon kodunu kullanarak özel indirimlerden
              faydalanabilirsiniz.
            </p>
          ) : (
            <div></div>
          )}

          <Row
            style={{
              margin: "10px",
              padding: "5px",
            }}
          >
            {couponList &&
              couponList.map((item, index) => (
                <DiscountItem
                  item={item}
                  index={index}
                  key={index}
                  length={couponList && couponList.length}
                />
              ))}
          </Row>

          {couponList && couponList.length > 0 ? (
            <div></div>
          ) : (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignContent: "center",
                alignItems: "center",
              }}
            >
              <CouponNotFound />
            </div>
          )}
        </div>
      )}
    </div>
  );
};

const DiscountItem = ({ item, index, length }) => {
  const isMobile = useIsMobile();
  const remainingDays = calculateRemainingDays(item.expiryDate);

  return (
    <Col
      xs={12}
      sm={length == 1 ? 12 : 6}
      md={length == 1 ? 12 : 6}
      lg={length == 1 ? 12 : 6}
      xl={length == 1 ? 12 : 6}
      style={{
        marginBottom: "10px",
        position: "relative",
      }}
    >
      <div
        style={{
          display: "flex",
          alignItems: "flex-start",
          backgroundColor: " #f7f7f7",
          borderRadius: "6px",
          justifyContent: "flex-start",
          border: "1px solid #f3f3f6",
          padding: "20px",
        }}
      >
        <div
          style={{
            border: "1.5px dotted orange",
            width: isMobile ? "80px" : "120px",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignContent: "center",
            alignItems: "center",
            backgroundColor: "white",
            fontSize: isMobile ? "10px" : "12px",
            padding: "10px",
            borderRadius: "6px",
            color: "#001542",
            fontWeight: 420,
          }}
        >
          {item.couponCode}
        </div>
        <div
          style={{
            position: "absolute",
            top: "5px",
            right: "7%",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignContent: "center",
                alignItems: "center",
              }}
            >
              <i
                class="fa-solid fa-bell"
                style={{
                  fontSize: isMobile ? "12px" : "13px",
                  color: "orange",
                }}
              ></i>
              <p
                style={{
                  margin: "0px",
                  padding: "0px",
                  marginLeft: "5px",
                  color: remainingDays === 0 ? "red" : "#001542",
                  fontSize: isMobile ? "10px" : "11px",
                  fontWeight: 410,
                }}
              >
                {remainingDays === 0
                  ? "Süresi Geçti"
                  : "Son " + remainingDays + " Gün"}
              </p>
            </div>
            <p
              style={{
                margin: "0px",
                padding: "0px",
                marginLeft: "5px",
                color: "#001542",
                fontSize: isMobile ? "10px" : "11px",
                fontWeight: 410,
                textAlign: "center",
                marginBottom: "5px",
              }}
            >
              {item.couponType == 2
                ? item.couponAmount + " TL"
                : "%" + item.couponAmount}
            </p>
            <Link
              to="/packets"
              style={{
                height: isMobile ? "25px" : "25px",
                backgroundColor: "#001542",
                display: "flex",
                justifyContent: "center",
                alignContent: "center",
                alignItems: "center",
                fontSize: isMobile ? "10px" : "11px",
                color: "white",
                borderRadius: "6px",
              }}
            >
              Paketler
            </Link>
          </div>
        </div>
      </div>
    </Col>
  );
};
function calculateRemainingDays(date) {
  const targetDate = new Date(date);
  let remainingDays;
  const now = new Date();
  const difference = targetDate.getTime() - now.getTime();

  if (difference > 0) {
    remainingDays = Math.ceil(difference / (1000 * 3600 * 24));
  } else {
    remainingDays = 0;
  }
  return remainingDays;
}

export default DiscountCoupons;
