import React, { useState } from "react";
import "../../../../../../../../../css/bitoniscss/matchıtem.css";
import ProgressCircle from "./ProgressCircle";
import ProgressMediumCircle from "./ProgressMediumCircle";
const NewMediumMatchItem = ({ matchData }) => {
  return (
    <div
      style={{
        position: "relative",
      }}
    >
      <div className="card-body cardItem" key={matchData.candidateId}>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignContent: "space-between",
            alignItems: "center",
            marginLeft: "0px",
          }}
        >
          <span className="fa-stack fa-lg">
            <i class="fa-solid fa-user"></i>
          </span>
          <div className="candidate-name">{matchData.candidateNameSurname}</div>
        </div>
        <div className="position-name">{matchData.positionName}</div>
        <div
          className={
            matchData && matchData.status == 0
              ? "new-matchMedium"
              : "new-matchMediumEvalute"
          }
        >
          {matchData && matchData.status == 0
            ? "Yeni Eşleşme"
            : "Değerlendirmeye Alındı"}
        </div>
        <div className="triangle-new-match-medium"></div>{" "}
      </div>
      {matchData.matchPoint === null || matchData.status === 12 ? (
        <div></div>
      ) : (
        <div
          style={{
            position: "absolute",
            right: -32,
            top: -28,
          }}
        >
          <ProgressMediumCircle
            value={matchData.matchPoint}
            matchData={matchData}
          />
        </div>
      )}
    </div>
  );
};

export default NewMediumMatchItem;
