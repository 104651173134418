import React, { useEffect, useMemo, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import Modal from "react-bootstrap/Modal"; // Varsayılan olarak React Bootstrap Modal bileşeni kullanılıyor
import Select from "react-select";
import { Alert, Button } from "react-bootstrap";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "../../../../../../../../../../css/bitoniscss/modalcss.css";
import { DemandsServices } from "../../../../../../../../../../services";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { useDispatch } from "react-redux";
import { setDemandBlockTab } from "../../../../../../../../../../redux/redux-toolkit/demandBlock";
import { Box } from "@mui/material";
import CustomButton from "../../../../../../../../../widgets/buttons/CustomButton";
import LoadingDotsAnimation from "../../../../../../../../../widgets/LoadingDotsAnimation";

const BlockCandidateModal = ({
  show,
  handleClose,
  onApproved,
  positionType,
  Detail,
  companyId,
  employerId,
}) => {
  const [whyBlockCandidateList, setWhyBlockCandidateList] = useState(null);
  const [selectedItem, setSelectedItem] = useState(null);
  const history = useHistory();
  const dispatch = useDispatch();
  const {
    register,
    handleSubmit,
    control,
    watch,
    reset,
    setValue,
    formState: { errors, isValid },
  } = useForm();

  function getDataWhyBlockCandidate() {
    setWhyBlockCandidateList(null);
    DemandsServices.getDataWhyBlockCandidate(positionType).then((value) => {
      setWhyBlockCandidateList([]);
      setWhyBlockCandidateList(value.data);
    });
  }
  useEffect(() => {
    setSelectedItem(null);
    if (show == true && whyBlockCandidateList == null) {
      getDataWhyBlockCandidate();
    }
  }, [show, whyBlockCandidateList]);

  function updateBlock() {
    var positionName = "";
    if (positionType === 1) {
      let data = Detail.matchedJobList;
      let positionNames = data.map((value) => value.jobPosition).join(",");

      // Eğer positionNames boşsa bu durumun kontrolünü yapabilirsiniz
      positionName = positionNames === "" ? "" : positionNames;
    } else {
    }
    var sendData = {
      candidateId: Detail.candidateId,
      companyId: companyId,
      employerId: employerId,
      positionName:
        positionType == 2 ? Detail.pozitionMatchDetailCandidate : positionName,
      labourerMatchId:
        positionType == 2 ? null : Detail.matchedJobList[0].matchId,
      employeeMatchId: positionType == 1 ? null : Detail.matchId,
      matchRestrictionStatus: 1, //engelle ,,2 engeli kaldır
      restrictionReasonId: selectedItem.id,
    };

    DemandsServices.employerBlockCandidateUpdate(sendData).then((response) => {
      if (response.succeeded == true) {
        if (positionType == 1) {
          dispatch(setDemandBlockTab(0));
        } else {
          dispatch(setDemandBlockTab(1));
        }
        handleClose();
        toast.success("Engelleme İşleminiz başarılı şekilde gerçekleşti", {
          autoClose: 2000,
          onClose: () => {
            setTimeout(() => {
              history.push("/DemandBlock");
            }, 100);
          },
        });
      } else {
        handleClose();
        toast.info(response.error.message, {
          autoClose: 2000,
          onClose: () => {
            setTimeout(() => { }, 100);
          },
        });
      }
    });
  }
  const toggleSelection = (item) => {
    if (selectedItem && selectedItem.id === item.id) {
      setSelectedItem(null);
    } else {
      setSelectedItem(item);
    }
  };
  return (
    <div>
      {show && whyBlockCandidateList === null ? (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
          }}
        >
          <LoadingDotsAnimation />
        </div>) :
        <div>
          <Modal
            show={show}
            onHide={handleClose}
            aria-labelledby="contained-modal-title-vcenter"
            centered
            dialogClassName="full-screen-modal"
          >
            <div className="">
              <Modal.Header closeButton style={{ borderBottom: "none" }}>
                <Modal.Title
                  aria-labelledby="contained-modal-title-vcenter"
                  style={{
                    width: "100%",
                    textAlign: "center",
                    margin: "0 auto",
                  }}
                >
                  Adayı Engelle
                </Modal.Title>
              </Modal.Header>
              <Modal.Body
                style={{
                  marginBottom: "0px",
                  paddingBottom: "0px",
                  marginTop: "0px",
                  paddingTop: "0px",
                }}
              >
                <div
                  style={{
                    marginTop: "0px",
                    paddingTop: "0px",
                  }}
                >
                  {
                    whyBlockCandidateList &&
                    whyBlockCandidateList.map((item, index) => (
                      <div key={index} style={{ paddingLeft: 5 }}>
                        <button
                          onClick={() => toggleSelection(item)}
                          style={{
                            color: "#001542",
                            padding: 20,
                            outline: "none",
                            border: "none",
                            margin: "5px",
                            borderRadius: "8px",
                            width: "100%",
                            display: "flex",
                            justifyContent: "start",
                          }}
                        >
                          <input
                            type="checkbox"
                            checked={selectedItem && selectedItem.id === item.id}
                            onChange={() => toggleSelection(item)}
                          />

                          <span
                            style={{
                              fontSize: 14,
                              fontWeight: 600,
                              color: "#001542;",
                              paddingLeft: "20px",
                            }}
                          >
                            {item.description}
                          </span>
                        </button>
                      </div>
                    ))
                  }
                </div>
              </Modal.Body>
              <Modal.Footer
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  alignContent: "center",
                  borderTop: "none",
                  marginTop: "0px",
                  paddingTop: "0px",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "center",
                    alignContent: "center",
                    borderTop: "none",
                    marginTop: "15px",
                    paddingTop: "0px",
                    marginLeft: "20px",
                  }}
                >
                  <CustomButton
                    onTap={(val) => {
                      if (selectedItem == null) {
                        handleClose();
                      } else {
                        updateBlock();
                      }
                    }}
                    title={"Engelle"}
                    backgroundColor={"red"}
                    border={"none"}
                    color={"white"}
                    hoverBackgroundColor={"rgb(231, 32, 32)"}
                    width={{
                      xs: "420px",
                      sm: "430px",
                      md: "430px",
                      lg: "430px",
                      xl: "430px",
                    }}
                  />
                </Box>
              </Modal.Footer>
            </div>
          </Modal>
        </div>}
    </div >
  );
};

export default BlockCandidateModal;
