import React, { useEffect, useState } from "react";
import moment from "moment";
import "./hovercss.css";
import useIsMobile from "../../../../../../../../hooks/useIsMobile";
import { Box, Typography } from "@mui/material";
import { Row } from "react-bootstrap";
const MeetingRejectedItem = ({ matchData, title }) => {
  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  const getBackgroundColor = () => {
    if (isHovered) {
      return "white";
    }

    // Diğer durumlar için renkleri döndürün
    return "white";
  };

  const getBoxShadow = () => {
    return isHovered
      ? "0px 7px 8px #dfdfdf;" // Hovered state shadow (elevation effect)
      : "0px"; // Default state shadow
  };




  return (
    <div>
      <Box
        className="card-body"
        key={matchData.candidateId}
        sx={{
          display: "flex",
          flexDirection: "row",
          alignContent: "space-between",
          border: isHovered == true ? "1px solid #D6E5FA" : "1px solid #F7F7F7",
          boxShadow: getBoxShadow(), 
          transition: "box-shadow 0.3s ease-in-out",
          height: "60px",
          position: "relative",
          borderRadius: "10px",
          alignItems: "center",
          width: "100%",
          margin: "8px",
          justifyContent: "space-between",
          cursor: "pointer",
          backgroundColor: getBackgroundColor(), // Dinamik arka plan rengi
        }}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        <Row
          sx={{
            display: "flex",
            flexDirection: "row",
          }}
        >
          <Box
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-start",
              alignContent: "center",
              alignItems: "center",
            }}
          >
            <span
              className="fa-stack fa-lg"
              style={{
                marginLeft: "10px",
              }}
            >
              <i class="fa-solid fa-user"></i>
            </span>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                fontSize: {
                  xs: "10px",
                  sm: "12px",
                  md: "12px",
                  lg: "14px",
                  xl: "14px",
                },
                width: {
                  xs: "120px",
                  sm: "200px",
                  md: "200px",
                  lg: "170px",
                  xl: "200px",
                },
                marginRight: {
                  xs: "-200px",
                },
              }}
            >
              <Typography
                sx={{
                  fontSize: {
                  xs: "10px",
                  sm: "12px",
                  md: "12px",
                  lg: "14px",
                  xl: "14px",
                },
                  fontWeight: 600,
                  color: {
                    xs: "#001542", // 0px ve üzeri ekran genişlikleri için
                    sm: "#001542", // 600px ve üzeri ekran genişlikleri için
                    md: "#001542", // 900px ve üzeri ekran genişlikleri için
                    lg: "#001542", // 1200px ve üzeri ekran genişlikleri için
                    xl: "#001542", // 1536px ve üzeri ekran genişlikleri için
                  },
                }}
              >
                {matchData.candidateNameSurname}
              </Typography>
            </Box>
          </Box>
        </Row>
        <Box
          sx={{
            width: {
              xs: "120px", // 0px ve üzeri ekran genişlikleri için
              sm: "120px", // 600px ve üzeri ekran genişlikleri için
              md: "150px", // 900px ve üzeri ekran genişlikleri için
              lg: "170px", // 1200px ve üzeri ekran genişlikleri için
              xl: "170px", // 1536px ve üzeri ekran genişlikleri için,
            },
            marginLeft: {
              xs: "100px", // 0px ve üzeri ekran genişlikleri için
              sm: "120px", // 600px ve üzeri ekran genişlikleri için
              md: "120px", // 900px ve üzeri ekran genişlikleri için
              lg: "120px", // 1200px ve üzeri ekran genişlikleri için
              xl: "120px", // 1536px ve üzeri ekran genişlikleri için,
            },
          }}
        >
          <Typography
            sx={{
              fontSize: {
                xs: "9px", // 0px ve üzeri ekran genişlikleri için
                sm: "11px", // 600px ve üzeri ekran genişlikleri için
                md: "11px", // 900px ve üzeri ekran genişlikleri için
                lg: "13px", // 1200px ve üzeri ekran genişlikleri için
                xl: "12px", // 1536px ve üzeri ekran genişlikleri için,
              },
              textAlign: "center",
              fontWeight: 600,
              color: {
                xs: "#001542", // 0px ve üzeri ekran genişlikleri için
                sm: "#001542", // 600px ve üzeri ekran genişlikleri için
                md: "#001542", // 900px ve üzeri ekran genişlikleri için
                lg: "#001542", // 1200px ve üzeri ekran genişlikleri için
                xl: "#001542", // 1536px ve üzeri ekran genişlikleri için
              },
            }}
          >
            {matchData.positionName}
          </Typography>
        </Box>
        <Box sx={{
          display:"flex",
          flexDirection:"row",
          justifyContent:"flex-end",
          alignContent:"flex-end",
          alignItems:"flex-end"
        }}>
          <Typography
            sx={{
              width: {
                xs: "120px", // 0px ve üzeri ekran genişlikleri için
                sm: "120px", // 600px ve üzeri ekran genişlikleri için
                md: "150px", // 900px ve üzeri ekran genişlikleri için
                lg: "250px", // 1200px ve üzeri ekran genişlikleri için
                xl: "250px", // 1536px ve üzeri ekran genişlikleri için,
              },
              fontSize: {
                xs: "9px", // 0px ve üzeri ekran genişlikleri için
                sm: "11px", // 600px ve üzeri ekran genişlikleri için
                md: "11px", // 900px ve üzeri ekran genişlikleri için
                lg: "11px", // 1200px ve üzeri ekran genişlikleri için
                xl: "11px", // 1536px ve üzeri ekran genişlikleri için,
              },
              fontWeight: 600,
              color: {
                xs: "red", // 0px ve üzeri ekran genişlikleri için
                sm: "red", // 600px ve üzeri ekran genişlikleri için
                md: "red",
                lg: "red",
                xl: "red",
              },
            }}
          >
            <div> {title}</div>
          </Typography>
        </Box>
        <div
          style={{
            position: "absolute",
            bottom: 0,
            right: 0,
            width: 0,
            height: 0,
            borderLeft: "30px solid transparent",
            borderTop: "30px solid transparent",
            borderRight: "30px solid red",
            borderRadius: "0px 0px 8px",
          }}
        ></div>
      </Box>
    </div>
  );
};

export default MeetingRejectedItem;


/*


 const isMobile = useIsMobile();
  const mobileStyles = {
    candidateName: {
      overflow: "hidden",
      whiteSpace: "normal",
      maxWidth: "90px",
      fontSize: "11px",
    },
    meetingRejectedMatch: {
      fontSize: "9px",
      marginRight: "0px",
      marginTop: "0px",
    },
  };

  return (
    <div
      style={{
        padding: "8px",
        border: "1px solid #f5f5f5",
        borderRadius: "8px",
        width: "100%",
        margin: "8px 0",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        transition: "box-shadow 0.3s ease",
        cursor: "pointer",
      }}
      className="card-item"
    >
      <div
        style={{
          display: "flex",
          alignItems: "center",
        }}
      >
        <span
          style={{ fontSize: "18px", marginRight: "8px", color: "#001542" ,marginLeft:"15px"}}
        >
          <i className="fa-solid fa-user"></i>
        </span>
        <div
          style={{
            flex: 1,
            fontSize: isMobile ? "9px" : "13px",
            fontWeight: 600,
            color: "#001542",
            ...(isMobile ? mobileStyles.candidateName : {}),
          }}
        >
          {matchData.candidateNameSurname}
        </div>
      </div>
      <div
        style={{
          flex: 1,
          fontSize: isMobile ? "10px" : "13px",
          textAlign: "center",
          fontWeight: 590,
          color: "#001542",
        }}
      >
        {matchData.positionName}
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          fontWeight: "bold",
          fontSize: isMobile ? "9px" : "11px",
          color: "red",
          ...(isMobile ? mobileStyles.meetingRejectedMatch : {}),
        }}
      >
        {title}
        <div
          style={{
            fontSize: isMobile ? "9px" : "11px",
            fontWeight: 600,
            color: "#001542",
            marginTop: "4px",
          }}
        >
          Görüşme Günü: {moment(matchData.meetingDate).format("DD/MM/YYYY")}
          <br />
          Saat: {moment(matchData.meetingDate).format("HH:mm")}
        </div>
      </div>
    </div>
  );


  */