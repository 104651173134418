import { DropListAction } from "../../../../../../../../action";
import { DropListServices } from "../../../../../../../../services";
import { toast } from "react-toastify";

export default function updateRequestDetailsToAPI(
  form,
  jobParentId,
  preferredLocationList,
  history,
  dispatch,
  checkProgram,
  checkCertificate,
  checkCompetence,
  checkCLicenseType,
  interviewContactValue,job
) {
  const jobId = jobParentId;
  const companyId = form.companyId.value;
  const companyLocationId = form.companyLocationId.value;
  const positionId = form.positionId.value;
  const workTypes = form.workTypes.map((val) => parseInt(val.value.toString()));
  var jobInterviewDays = [];
  if (form.jobInterviewDays.length > 0) {
    jobInterviewDays = form.jobInterviewDays.map((item) => item.value);
  } else {
    jobInterviewDays = [];
  }
  const educationTypes = parseInt(form.educationTypes.value.toString());
  const gender = parseInt(form.gender.value.toString());
  const militaryStatus =
    form.militaryStatus == "" ||
    form.militaryStatus == undefined ||
    form.militaryStatus == null
      ? null
      : parseInt(form.militaryStatus.value);
  const maritalStatus = parseInt(form.maritalStatus.value.toString());
  const ageRanges = form.ageRanges.map((val) => parseInt(val.value.toString()));
  const nationalityId = form.nationalityId.value;
  const smoking = parseInt(form.smokerApply.value);
  const drivingLicence = form.drivingLicence.value;
  var drivingLicenceTypes;

  if (
    form.drivingLicenceTypes === "" ||
    form.drivingLicenceTypes === undefined ||
    form.drivingLicenceTypes === null
  ) {
    drivingLicenceTypes = [];
  } else {
    drivingLicenceTypes = form.drivingLicenceTypes.map((val) =>
      parseInt(val.value.toString())
    );
  }

  const jobExperienceRanges = form.jobExperienceRanges.map((val) =>
    parseInt(val.value.toString())
  );
  var jobPrograms = [];
  if (form.jobPrograms == []) {
    jobPrograms = [];
  } else {
    jobPrograms = form.jobPrograms.map((val) => val.value);
  }
  var jobCertificates = [];
  if (form.jobCertificates == []) {
    jobCertificates = [];
  } else {
    jobCertificates = form.jobCertificates.map((val) => val.value);
  }
  var jobCompetences = [];
  if (form.jobCompetences == []) {
    jobCompetences = [];
  } else {
    jobCompetences = form.jobCompetences.map((val) => val.value);
  }
  var jobPreferredLocations = [];

  preferredLocationList.map((data) => {
    let element = {};
    element["countryId"] = data.countryId.value;
    element["cityId"] = data.cityId.value;
    element["counties"] =
      data.countyId == [] || data.countyId == undefined || data.countyId == null
        ? []
        : data.countyId.map(({ value }) => value);
    element["districts"] =
      data.districts == [] ||
      data.districts == undefined ||
      data.districts == null
        ? []
        : data.districts.map(({ value }) => value);
    if (data && data.lat !== undefined && data.lat !== null) {
      element["latitude"] = data.lat.toString();
    } else {
      element["latitude"] = "";
    }
    if (data && data.lng !== undefined && data.lng !== null) {
      element["longitude"] = data.lng.toString();
    } else {
      element["longitude"] = "";
    }

    jobPreferredLocations.push(element);
  });
  var disabilityId = parseInt(form.disabilityStatus.value.toString());
  var disabilityDegreeListIds = [];
  form.degreeOfDisability == null ||
  form.degreeOfDisability == [] ||
  form.degreeOfDisability === "" ||
  form.degreeOfDisability == undefined ||
  form.degreeOfDisability.toString() == "[]" ||
  form.degreeOfDisability.toString() == "null"
    ? null
    : form.degreeOfDisability.map((val) => {
        disabilityDegreeListIds.push(parseInt(val.value.toString()));
    });
  
   
  
  var sendDemandData = {
    jobId: jobId,
    companyId: companyId,
    companyLocationId: companyLocationId,
    positionId: positionId,
    workTypes: workTypes,
    jobInterviewDays: jobInterviewDays.length > 0 ? jobInterviewDays : null,
    educationTypes: [educationTypes],
    gender: gender,
    militaryStatus: gender == 0 || gender == 1 ? null : militaryStatus,
    maritalStatus: maritalStatus,
    ageRanges: ageRanges,
    nationalityId: nationalityId,
    smoking: smoking,
    drivingLicence: drivingLicence,
    drivingLicenceTypes: drivingLicenceTypes,
    jobPreferredLocations: jobPreferredLocations,
    jobExperienceRanges: jobExperienceRanges,
    jobPrograms: jobPrograms,
    jobCertificates: jobCertificates,
    jobCompetences: jobCompetences,
    isActive: true,
    visibilityStatus:
      jobInterviewDays.length > 0
        ? 1
        : interviewContactValue.toString() == "true" ||
          interviewContactValue == true
        ? 2
        : 1,
    disability: disabilityId,
    jobDegreeOfDisabilityIds: disabilityDegreeListIds,
    isAllCertificatesMust:
      jobCertificates.toString() == "[]" ? false : checkCertificate,
    isAllProgramsMust: jobPrograms.toString() == "[]" ? false : checkProgram,
    isAllCompetencesMust:
      jobCompetences.toString() == "[]" ? false : checkCompetence,
    isAllDrivingLicensesMust:
      drivingLicenceTypes.toString() == "[]" ? false : checkCLicenseType,
  };

  DropListServices.blueUpdateDemand(sendDemandData).then(
    (blueUpdateDemand) => {
      if (blueUpdateDemand.succeeded === true) {
        toast.success(blueUpdateDemand.result.message, {
          autoClose: 2000,
          onClose: () => {
            setTimeout(() => {
              window.location.href = window.location.href;
            }, 100);
          },
        });
      } else {
        toast.info(blueUpdateDemand.error.message, {
          autoClose: 2000,
          onClose: () => {
            setTimeout(() => {
              window.location.href = window.location.href;
            }, 100);
          },
        });
      }
    },
    (error) => {
  
    }
  );
}
