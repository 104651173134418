import React from "react";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const PrevIconButton = ({ left, right, top, bottom }) => {
  const history = useHistory();

  return (
    <div
      style={{
        position: "absolute",
        top: top,
        left: left,
        right: right,
        bottom: bottom,
      }}
      onClick={() => {
        history.goBack(); // Geri git fonksiyonu
      }}
    >
      <div
        style={{
          margin: "10px",
          width: "35px",
          height: "35px",
          backgroundColor: "#F5F5F5",
          borderRadius: "50%",
          display: "flex",
          justifyContent: "center",
          alignContent: "center",
          alignItems: "center",
          marginBottom: "0px",
          cursor: "pointer",
          marginTop: "0px",
        }}
      >
        <i
          class="fa-solid fa-arrow-left"
          style={{
            fontSize: "15px",
            color: "#3d4465",
          }}
        ></i>
      </div>
    </div>
  );
};

export default PrevIconButton;
