import { combineReducers } from "redux";
import { authentication } from "./AuthReducer";
import { job } from "./JobReducer";
import { taxCounty, taxOffices } from "./TaxInfoReducer";
import { user, users, own } from "./UserReducer";
import { labourer, labourerMatch, lastLabourer } from "./DashboardReducer";
import {
  recruitedList,
  recruitedListWhite,
  recruitedDetailWhite,
  recruitedDetail,
  unrecruitedList,
  unrecruitedListWhite,
} from "./RecruitedReducer";
import {
  demands,
  matchingDemands,
  matchingDemandsWhite,
} from "./DemandsReducer";
import getGroupCompany_slicer from "./redux-toolkit/getGroupCompany_slicer";

import {
  yearOfExperience,
  disabilityOfDegreeList,
  positionList,
  wayOfWorking,
  nationalityList,
  ageRangeList,
  driverLicenceType,
  programList,
  certificatesList,
  competencesList,
  countryList,
  cityList,
  countyList,
  districtList,
  companyList,
  companyLocation,
  planList,
  companyListMain,
  sectorList,
  departmentList,
  companyItemLocation,
  countyJobSeekerList,
} from "./DropListReducer";
import {
  yearOfExperienceWhite,
  positionListWhite,
  wayOfWorkingWhite,
  nationalityListWhite,
  ageRangeListWhite,
  driverLicenceTypeWhite,
  programListWhite,
  certificatesListWhite,
  competencesListWhite,
  countryListWhite,
  cityListWhite,
  countyListWhite,
  districtListWhite,
  companyListWhite,
  salaryListWhite,
  businessTravelListWhite,
  languageListWhite,
  languageLevelListWhite,
  universityListWhite,
  facultyListWhite,
  facultyDepartmentListWhite,
} from "./DropListWhiteReducer";
import {
  companyDetail,
  companyMainDetail,
  companyAddedGetRoutes,
  searchCompanyList,
} from "./ProfileDetailReducer";

import redux_slicer from "./redux-toolkit/redux_slicer";
import companyInfo from "./redux-toolkit/companyInfo";
import packetSlice from "./redux-toolkit/packetSlice";
import lastDemandCountSlice from "./redux-toolkit/lastDemandCountSlice";
import getGroupCompaniesLocations from "./redux-toolkit/getGroupCompaniesLocations";
import preferredListSlice from "./redux-toolkit/preferredListSlice";
import { matchBlue } from "./demandMatchReducer";
import filterMatch from "./redux-toolkit/filterMatch";
import selectedHireTab from "./redux-toolkit/selectedHireTab";
import selectedNotHireTab from "./redux-toolkit/selectedNotHireTab";
import notificationRedux from "./redux-toolkit/notificationRedux";
import matchDetailRedux from "./redux-toolkit/matchDetailRedux";
import demandBlock from "./redux-toolkit/demandBlock";
import demandVideoCvTab from "./redux-toolkit/demandVideoCvTab";
import demandMatchOfficeInterView from "./redux-toolkit/demandMatchOfficeInterView";
import demandConferencesMeet from "./redux-toolkit/demandConferencesMeet";
import sidebarIndex from "./redux-toolkit/sidebarIndex";
import companyMainData from "./redux-toolkit/companyMainData";
import filterAllShow from "./redux-toolkit/filterAllShow";
import { internJob } from "./InternJobReducer";

const rootReducer = combineReducers({
  authentication,
  own,
  user,
  users,
  labourerMatch,
  labourer,
  lastLabourer,
  demands,
  matchingDemands,
  matchingDemandsWhite,
  matchBlue,
  job,internJob,
  recruitedList,
  recruitedListWhite,
  recruitedDetail,
  recruitedDetailWhite,
  unrecruitedList,
  unrecruitedListWhite,
  positionList,
  yearOfExperience,
  wayOfWorking,
  nationalityList,
  ageRangeList,
  driverLicenceType,
  programList,
  certificatesList,
  competencesList,
  countryList,
  sectorList,
  departmentList,
  cityList,
  countyList,
  districtList,
  companyList,
  companyLocation,
  yearOfExperienceWhite,
  positionListWhite,
  wayOfWorkingWhite,
  nationalityListWhite,
  ageRangeListWhite,
  driverLicenceTypeWhite,
  programListWhite,
  certificatesListWhite,
  competencesListWhite,
  countryListWhite,
  cityListWhite,
  countyListWhite,
  districtListWhite,
  companyListWhite,
  salaryListWhite,
  businessTravelListWhite,
  languageListWhite,
  languageLevelListWhite,
  universityListWhite,
  facultyListWhite,
  facultyDepartmentListWhite,
  taxCounty,
  taxOffices,
  companyDetail,
  companyListMain,
  planList,
  companyMainDetail,
  companyItemLocation,
  companyAddedGetRoutes,
  data: redux_slicer,
  companyData: companyInfo,
  lastDemand: lastDemandCountSlice,
  selectPacket: packetSlice,
  getGroupCompanies: getGroupCompany_slicer,
  getGroupCompanyLocations: getGroupCompaniesLocations,
  preferredLocation: preferredListSlice,
  countyJobSeekerList,
  selectedTab: filterMatch,
  selectedHireTab: selectedHireTab,
  selectedNotHireTab: selectedNotHireTab,
  notification: notificationRedux,
  matchDetail: matchDetailRedux,
  demandBlockTab: demandBlock,
  searchCompanyList,
  disabilityOfDegreeList,
  demandVideoCvTab: demandVideoCvTab,
  demandMatchOfficeInterView: demandMatchOfficeInterView,
  demandConferencesMeet: demandConferencesMeet,
  demandConferencesMeet: demandConferencesMeet,
  navIndex: sidebarIndex,
  companyMainData: companyMainData,
  selectedAllTab: filterAllShow
});

export default rootReducer;
