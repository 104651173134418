import React, { useEffect, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import Select from "react-select";
import { connect, useDispatch } from "react-redux";
import {
  DashboardAction,
  DropListAction,
  DropListWhiteAction,
} from "../../../../../../../action";
import {
  customStyles,
  customStylesMulti,
  labelStyle,
} from "../../../../../../customStyleInline";
import BitonisMultiDropdown from "../../../../../../widgets/BitonisMultiDropdown";
import BitonisSingleDropdown from "../../../../../../widgets/BitonisSingleDropdown";
import FindInternSelectEducation from "../../../../../../widgets/educationWidgets/FindInternSelectEducation";
import CustomDesign from "../../../../../../widgets/CustomDesign";
const FindInternFeatures = (props) => {
  const {
    register,
    handleSubmit,
    control,
    watch,
    reset,
    setValue,
    errors,
    submitNewVersion,
    companyName,
    companyLocationName,
    getValues,
    sectionList,
    setSectionList,
  } = props;
  useEffect(() => {
    if (!props.nationalityList.success) {
      props.dispatch(
        DropListAction.nationalityList({
          positionType: 1,
          isActive: true,
        })
      );
    }
  }, []);

  const genderList = [
    //kontrol edildi
    { id: 0, name: "Farketmez" },
    { id: 1, name: "Kadın" },
    { id: 2, name: "Erkek" },
  ];

  return (
    <div>
      <div
        className="card"
        style={{
          boxShadow: "none",
        }}
      >
        <div className="basic-form">
          <form onSubmit={handleSubmit(submitNewVersion)}>
            <CustomDesign
              label={"Uyruk"}
              required={true}
              child={
                <BitonisSingleDropdown
                  items={
                    props.nationalityList.nationalityList &&
                    props.nationalityList.nationalityList.map(
                      ({ id, name }) => ({
                        value: id,
                        label: name,
                      })
                    )
                  }
                  register={register}
                  watch={watch}
                  companyClassName={"nationalityId"}
                  control={control}
                  setValue={setValue}
                  errors={errors}
                  placeholder="Uyruk Seçin"
                  onValueChanged={(e) => {
                    console.log(e);
                    if (e) {
                    }
                  }}
                  required={true}
                  getValues={getValues}
                  errorMessage={"Uyruk Boş geçilemez"}
                />
              }
            />

            <CustomDesign
              label={"Cinsiyet"}
              required={true}
              child={
                <BitonisSingleDropdown
                  items={genderList.map(({ id, name }) => ({
                    value: id,
                    label: name,
                  }))}
                  register={register}
                  watch={watch}
                  companyClassName={"gender"}
                  control={control}
                  setValue={setValue}
                  errors={errors}
                  placeholder="Cinsiyet Seçin"
                  onValueChanged={(e) => {
                    console.log(e);
                  }}
                  required={true}
                  getValues={getValues}
                  errorMessage={"Cinsiyet Boş geçilemez"}
                />
              }
            />

            <FindInternSelectEducation
              register={register}
              control={control}
              watch={watch}
              reset={reset}
              setValue={setValue}
              errors={errors}
              getValues={getValues}
              sectionList={sectionList}
              setSectionList={setSectionList}
              onMultiSection={true}
            />
          </form>
        </div>
      </div>
    </div>
  );
};

function mapState(state) {
  let authentication = state.authentication;
  let yearOfExperience = state.yearOfExperience;
  let positionList = state.positionList;
  let wayOfWorking = state.wayOfWorking;
  let nationalityList = state.nationalityList;
  let ageRangeList = state.ageRangeList;
  let driverLicenceType = state.driverLicenceType;
  // Beyaz Yaka
  let yearOfExperienceWhite = state.yearOfExperienceWhite;
  let positionListWhite = state.positionListWhite;
  let wayOfWorkingWhite = state.wayOfWorkingWhite;
  let nationalityListWhite = state.nationalityListWhite;
  let ageRangeListWhite = state.ageRangeListWhite;
  let driverLicenceTypeWhite = state.driverLicenceTypeWhite;
  let salaryListWhite = state.salaryListWhite;
  let businessTravelListWhite = state.businessTravelListWhite;
  return {
    authentication,
    driverLicenceType,
    yearOfExperience,
    positionList,
    wayOfWorking,
    nationalityList,
    ageRangeList,
    yearOfExperienceWhite,
    positionListWhite,
    wayOfWorkingWhite,
    nationalityListWhite,
    ageRangeListWhite,
    driverLicenceTypeWhite,
    salaryListWhite,
    businessTravelListWhite,
  };
}

export default connect(mapState)(FindInternFeatures);
