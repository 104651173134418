import React from "react";
import "../../css/bitoniscss/customPreviewMySkillTwopt.css";
import moment from "moment";

const CustomPreviewMySkillTwoptDate = ({ name, value }) => {
  return (
    <div className="custom-preview-container">
      <div className="row-parent-item">
        <div className="custom-preview-label">{name}</div>
        <div className="custom-preview-value">
          {moment(value).format("MM.YYYY")}
        </div>
      </div>
    </div>
  );
};

export default CustomPreviewMySkillTwoptDate;
