/// Menu
import MetisMenu from "metismenujs";
import React, { Component, useState } from "react";
/// Scroll
import PerfectScrollbar from "react-perfect-scrollbar";
/// Link
import { Link } from "react-router-dom";
import { connect, useDispatch } from "react-redux";
import requests from "../../../images/icons/requests.png";
import group_light from "../../../images/icons/group_light.png";
import user_add from "../../../images/icons/user_add.png";
import user_exits from "../../../images/icons/user_exits.png";
import home_icon from "../../../images/icons/home-icon.png";
import user_profile from "../../../images/icons/user_profile.png";
import document_requests from "../../../images/icons/document_requests.png";
import upgrade_icon from "../../../images/icons/upgrade_icon.png";
import exit_icon from "../../../images/icons/exit-icon.png";
import plus_solid from "../../../images/icons/plus-solid.png";
import "../../../css/bitoniscss/navbar/style.css";
import ExitModal from "./ExitModal";
import apiSecure from "../../../utility/Utils";
class MM extends Component {
  componentDidMount() {
    console.log("first");

    this.$el = this.el;
    this.mm = new MetisMenu(this.$el);
  }
  componentWillUnmount() {}
  render() {
    return (
      <div className="mm-wrapper">
        <ul className="metismenu" ref={(el) => (this.el = el)}>
          {this.props.children}
        </ul>
      </div>
    );
  }
}

class SideBar extends Component {
  state = {
    menuActive: "/",
    index: 0,
    approvedExit: false,
    remainderJobCount: null,
  };

  /// Open menu
  componentDidMount() {
    // sidebar open/close
    var btn = document.querySelector(".nav-control");
    var aaa = document.querySelector("#main-wrapper");
    function toggleFunc() {
      return aaa.classList.toggle("menu-toggle");
    }
    btn.addEventListener("click", toggleFunc);
    let path = window.location.pathname;
    path = path.split("/");
    path = path[path.length - 1];
    this.getCurrentPackets();
    if (path === "/") {
      this.changeIndex(-1);
    } else if (path === "allShow") {
      this.changeIndex(0);
    } else if (path == "matchShow") {
      this.changeIndex(1);
    } else if (path == "matchVideoRequest") {
      this.changeIndex(2);
    } else if (path == "matchInterviewMeets") {
      this.changeIndex(3);
    } else if (path == "matchVideoConferencesMeets") {
      this.changeIndex(4);
    } else if (path == "hire") {
      this.changeIndex(5);
    } else if (path == "notHire") {
      this.changeIndex(6);
    } else if (path == "DemandBlock") {
      this.changeIndex(7);
    } else if (path == "blueJobSeeker") {
      this.changeIndex(8);
    } else if (path == "whiteJobSeeker") {
      this.changeIndex(9);
    } else if (path == "Profile") {
      this.changeIndex(10);
    } else if (path == "companyRoutes") {
      this.changeIndex(11);
    } else if (path == "documents") {
      this.changeIndex(12);
    } else if (path == "changeEmail") {
      this.changeIndex(13);
    } else if (path == "changePassword") {
      this.changeIndex(14);
    }
  }

  getCurrentPackets() {
    apiSecure
      .get("payment/getCompanyPermission")
      .then((response) => {
        var data = response.data;
        this.setState({
          remainderJobCount: data.creditLeft,
        });
      })
      .catch((err) => {});
  }

  menuClick(click) {
    console.log(click);
    this.setState({ menuActive: click });
  }
  changeIndex(i) {
    this.setState({ index: i });
  }

  state = {
    loveEmoji: false,
  };
  render() {
    /// Path
    let path = window.location.pathname;
    path = path.split("/");
    path = path[path.length - 1];

    /// Active menu
    let dashBoard = [
        "",
        "search-job",
        "appication",
        "profile",
        "companies",
        "statistics",
      ],
      app = ["demands", "Profile"],
      email = ["email-compose", "email-inbox", "email-read"],
      shop = [
        "ecom-product-grid",
        "ecom-product-list",
        "ecom-product-list",
        "ecom-product-order",
        "ecom-checkout",
        "ecom-invoice",
        "ecom-customers",
        "ecom-product-detail",
      ],
      charts = [
        "chart-rechart",
        "chart-flot",
        "chart-chartjs",
        "chart-chartist",
        "chart-sparkline",
        "chart-apexchart",
      ],
      bootstrap = [
        "ui-accordion",
        "ui-badge",
        "ui-alert",
        "ui-button",
        "ui-modal",
        "ui-button-group",
        "ui-list-group",
        "ui-media-object",
        "ui-card",
        "ui-carousel",
        "ui-dropdown",
        "ui-popover",
        "ui-progressbar",
        "ui-tab",
        "ui-typography",
        "ui-pagination",
        "ui-grid",
      ],
      plugins = [
        "uc-select2",
        "uc-nestable",
        "uc-sweetalert",
        "uc-toastr",
        "uc-noui-slider",
        "map-jqvmap",
        //"post",
      ],
      redux = ["todo", "form-redux", "form-redux-wizard"],
      widget = ["widget-basic"],
      forms = [
        "form-element",
        "form-wizard",
        "form-editor-summernote",
        "form-pickers",
        "form-validation-jquery",
      ],
      table = [
        "table-bootstrap-basic",
        "table-datatable-basic",
        "table-sorting",
        "table-filtering",
      ],
      pages = [
        "page-register",
        "page-login",
        "page-lock-screen",
        "page-error-400",
        "page-error-403",
        "page-error-404",
        "page-error-500",
        "page-error-503",
      ],
      error = [
        "page-error-400",
        "page-error-403",
        "page-error-404",
        "page-error-500",
        "page-error-503",
      ];
    console.log(path);

    return (
      <>
        <div className="deznav">
          <PerfectScrollbar className="deznav-scroll">
            <MM className="metismenu" id="menu">
              <li
                className={
                  this.state.menuActive == null || this.state.menuActive == "/"
                    ? "mm-active"
                    : ""
                }
                onClick={() => {
                  this.menuClick("/");
                  this.setState({ menuActive: "/" });
                  this.changeIndex(-1);
                }}
              >
                <Link to="/" className="ai-icon">
                  <i
                    className="fa fa-house home-icon"
                    style={
                      this.state.menuActive == null ||
                      this.state.menuActive == "/"
                        ? {
                            fontSize: "30px",
                            color: "#FDC303",
                          }
                        : {
                            fontSize: "25px",
                            color: "white",
                          }
                    }
                  ></i>
                  <span className="nav-text">Anasayfa</span>
                </Link>
              </li>
              <li
                className={
                  this.state.menuActive == "demands" ? "mm-active" : ""
                }
                onClick={() => this.menuClick("demands")}
              >
                <Link className="has-arrow ai-icon" to="/demands/allShow">
                  <i
                    className="fa fa-list-check  requests-icon"
                    style={
                      this.state.menuActive == "demands"
                        ? {
                            fontSize: "25px",
                            color: "#FDC303",
                          }
                        : {
                            fontSize: "20px",
                            color: "#FDC303",
                          }
                    }
                  ></i>
                  <span className="nav-text">Talepler</span>
                </Link>
                <ul>
                  <li>
                    {" "}
                    <div
                      style={{
                        margin: "10px 0px 0px 0px",
                      }}
                    ></div>
                    <Link
                      id={"navTitleStyle"}
                      onClick={() => {
                        this.setState({ menuActive: "demands" });
                        this.changeIndex(0);
                      }}
                      to="/demands/allShow"
                    >
                      <i class="fa-solid fa-file" id="iconNavStyle"></i>
                      Taleplerimi Gör
                    </Link>
                  </li>

                  <li>
                    <Link
                      id={"navTitleStyle"}
                      onClick={() => {
                        this.setState({ menuActive: "demands" });
                        this.changeIndex(0);
                      }}
                      to="/demands/internAllShow"
                    >
                      <i class="fa-regular fa-user" id="iconNavStyle"></i>
                      Stajyer Taleplerimi Gör
                    </Link>
                  </li>
                </ul>
              </li>
              <li
                className={this.state.menuActive == "match" ? "mm-active" : ""}
                onClick={() => this.menuClick("match")}
              >
                <Link className="has-arrow ai-icon" to="/matchShow">
                  <i
                    className="fa fa-link  requests-icon"
                    style={
                      this.state.menuActive == "match"
                        ? {
                            fontSize: "25px",
                            color: "#FDC303",
                          }
                        : {
                            fontSize: "20px",
                            color: "white",
                          }
                    }
                  ></i>
                  <span className="nav-text">Talebimle Eşleşenler</span>
                </Link>
                <ul>
                  <li>
                    <Link
                      onClick={() => {
                        this.setState({ menuActive: "match" });
                        this.changeIndex(1);
                      }}
                      to="/matchShow"
                      id={"navTitleStyle"}
                    >
                      <i class="fa-solid fa-users" id="iconNavStyle"></i>
                      Taleplerim İle Eşleşenleri Gör
                    </Link>
                  </li>
                  <li>
                    <Link
                      onClick={() => {
                        this.setState({ menuActive: "match" });
                        this.changeIndex(2);
                      }}
                      to="/matchVideoRequest"
                      id="navTitleStyle"
                    >
                      <i
                        class="fa-regular fa-circle-play "
                        id="iconNavStyle"
                      ></i>
                      <p id="pId"> Video CV Talep Ettiğim Adaylar</p>
                    </Link>
                  </li>{" "}
                  <li>
                    <Link
                      id="navTitleStyle"
                      onClick={() => {
                        this.setState({ menuActive: "match" });
                        this.changeIndex(3);
                      }}
                      to="/matchInterviewMeets"
                    >
                      <i class="fa-solid fa-user-clock" id="iconNavStyle"></i>
                      <p id="pId"> İşyerinde Görüşme Planladığım Adaylar</p>
                    </Link>
                  </li>
                  <li>
                    <Link
                      onClick={() => {
                        this.setState({ menuActive: "match" });
                        this.changeIndex(4);
                      }}
                      to="/matchVideoConferencesMeets"
                      id={"navTitleStyle"}
                    >
                      <i class="fa-solid fa-video" id="iconNavStyle"></i>
                      <p id="pId"> Video Konferans Planladığım Adaylar</p>
                    </Link>
                  </li>{" "}
                  <li>
                    <Link
                      onClick={() => {
                        this.setState({ menuActive: "match" });
                        this.changeIndex(5);
                      }}
                      to="/hire"
                      id={"navTitleStyle"}
                    >
                      <i class="fa-solid fa-user-plus" id="iconNavStyle"></i>
                      <p id="pId"> İşe Alınanları Gör</p>
                    </Link>
                  </li>
                  <li>
                    <Link
                      onClick={() => {
                        this.setState({ menuActive: "match" });
                        this.changeIndex(6);
                      }}
                      to="/notHire"
                      id={"navTitleStyle"}
                    >
                      <i class="fa-solid fa-user-minus" id="iconNavStyle"></i>
                      <p id="pId"> İşe Alınmayanları Gör</p>
                    </Link>
                  </li>
                  <li>
                    <Link
                      onClick={() => {
                        this.setState({ menuActive: "match" });
                        this.changeIndex(7);
                        this.props.onClick();
                      }}
                      to="/demandBlock"
                      id={"navTitleStyle"}
                    >
                      <i class="fa-solid fa-user-slash" id="iconNavStyle"></i>
                      <p id="pId"> Engellediğim Adayları Gör</p>
                    </Link>
                  </li>
                </ul>
              </li>
              <li
                className={
                  this.state.menuActive == "findJobSeeker/blueJobSeeker"
                    ? "mm-active"
                    : ""
                }
                onClick={() => this.menuClick("findJobSeeker/blueJobSeeker")}
              >
                <Link className="has-arrow ai-icon" to="/demands">
                  <i
                    className="fa fa-plus-square  requests-icon"
                    style={
                      this.state.menuActive == "findJobSeeker/blueJobSeeker"
                        ? {
                            fontSize: "25px",
                            color: "#FDC303",
                          }
                        : {
                            fontSize: "20px",
                            color: "white",
                          }
                    }
                  ></i>
                  <span className="nav-text">Yeni Talep Oluştur</span>
                </Link>
                <ul>
                  <li>
                    <Link
                      id={"navTitleStyle"}
                      onClick={() => {
                        this.props.onClick3();
                        this.setState({
                          menuActive: "findJobSeeker/blueJobSeeker",
                        });
                        this.changeIndex(8);
                      }}
                      to={
                        this.state.remainderJobCount === null ||
                        this.state.remainderJobCount === undefined
                          ? ""
                          : this.state.remainderJobCount === 0
                          ? "/activepackets"
                          : "/findJobSeeker/blueJobSeeker"
                      }
                    >
                      <p id="pId"> Mavi Yaka</p>
                    </Link>
                  </li>
                  <li>
                    <Link
                      id={"navTitleStyle"}
                      className={`${
                        path === "page-error-403" ? "mm-active" : ""
                      }`}
                      to={
                        this.state.remainderJobCount === null ||
                        this.state.remainderJobCount === undefined
                          ? ""
                          : this.state.remainderJobCount === 0
                          ? "/activepackets"
                          : "/findJobSeeker/whiteJobSeeker"
                      }
                      onClick={() => {
                        this.setState({
                          menuActive: "findJobSeeker/whiteJobSeeker",
                        });
                        this.changeIndex(9);
                      }}
                    >
                      <p id="pId"> Beyaz Yaka</p>
                    </Link>
                  </li>
                  <li>
                    <Link
                      id={"navTitleStyle"}
                      className={`${
                        path === "page-error-403" ? "mm-active" : ""
                      }`}
                      to={
                        this.state.remainderJobCount === null ||
                        this.state.remainderJobCount === undefined
                          ? ""
                          : this.state.remainderJobCount === 0
                          ? "/activepackets"
                          : "/findJobSeeker/internJob"
                      }
                      onClick={() => {
                        this.setState({
                          menuActive: "findJobSeeker/internJob",
                        });
                        this.changeIndex(9);
                      }}
                    >
                      <p id="pId">Stajyer</p>
                    </Link>
                  </li>
                </ul>
              </li>
              <li
                className={
                  this.state.menuActive == "Profile" ? "mm-active" : ""
                }
                onClick={() => this.menuClick("Profile")}
              >
                <Link to="/profile" className="has-arrow ai-icon">
                  <i
                    className="fa fa-building user-profile-icon"
                    style={
                      this.state.menuActive == "Profile"
                        ? {
                            fontSize: "25px",
                            color: "#FDC303",
                          }
                        : {
                            fontSize: "20px",
                            color: "white",
                          }
                    }
                  ></i>
                  <span className="nav-text">Profil</span>
                </Link>
                <ul>
                  <li>
                    <Link
                      id={"navTitleStyle"}
                      onClick={() => {
                        this.props.onClick();
                        this.setState({
                          menuActive: "Profile",
                        });
                        this.changeIndex(10);
                      }}
                      to="/Profile"
                    >
                      {" "}
                      <i class="fa-regular fa-building" id="iconNavStyle"></i>
                      Şirket Bilgilerim
                    </Link>
                  </li>
                  <li>
                    <Link
                      id={"navTitleStyle"}
                      onClick={() => {
                        this.props.onClick();
                        this.setState({
                          menuActive: "Profile",
                        });
                        this.changeIndex(11);
                      }}
                      to="/companyRoutes"
                    >
                      <i class="fa-solid fa-route" id="iconNavStyle"></i>
                      <p id="pId"> Şirket Servis Güzergahları</p>
                    </Link>
                  </li>
                  <li>
                    <Link
                      id={"navTitleStyle"}
                      className={`${
                        path === "page-error-403" ? "mm-active" : ""
                      }`}
                      to="/documents"
                      onClick={() => {
                        this.props.onClick3();
                        this.setState({
                          menuActive: "Profile",
                        });
                        this.changeIndex(12);
                      }}
                    >
                      <i class="fa-solid fa-file-invoice" id="iconNavStyle"></i>
                      <p id="pId"> Şirket İşe Giriş Evrak Listesi</p>
                    </Link>
                  </li>
                </ul>
              </li>
              <li
                className={
                  this.state.menuActive == "packets" ? "mm-active" : ""
                }
                onClick={() => this.menuClick("packets")}
              >
                <Link to="/packets" className="ai-icon">
                  <i
                    className="fa fa-database upgrade_icon-icon"
                    style={
                      this.state.menuActive == "packets"
                        ? {
                            fontSize: "25px",
                            color: "#FDC303",
                          }
                        : {
                            fontSize: "20px",
                            color: "white",
                          }
                    }
                  ></i>
                  <span className="nav-text">Paket Bilgileri</span>
                </Link>
              </li>{" "}
              <li
                className={
                  this.state.menuActive == "discountCoupons" ? "mm-active" : ""
                }
                onClick={() => this.menuClick("discountCoupons")}
              >
                <Link to="/discountCoupons" className="ai-icon">
                  <i
                    class="fa-solid fa-ticket"
                    style={
                      this.state.menuActive == "discountCoupons"
                        ? {
                            fontSize: "25px",
                            color: "#FDC303",
                          }
                        : {
                            fontSize: "20px",
                            color: "white",
                          }
                    }
                  ></i>
                  <span className="nav-text">İndirim Kuponlarım</span>
                </Link>
              </li>
              <li
                className={
                  this.state.menuActive == "activepackets" ? "mm-active" : ""
                }
                onClick={() => this.menuClick("activepackets")}
              >
                <Link to="/activepackets" className="ai-icon">
                  <i
                    className="fa-solid fa-cart-plus"
                    style={
                      this.state.menuActive == "activepackets"
                        ? {
                            fontSize: "25px",
                            color: "#FDC303",
                          }
                        : {
                            fontSize: "20px",
                            color: "white",
                          }
                    }
                  ></i>
                  <span className="nav-text">Aktif Paketim</span>
                </Link>
              </li>
              <li
                className={
                  this.state.menuActive == "settings" ? "mm-active" : ""
                }
                onClick={() => this.menuClick("settings")}
              >
                <Link className="has-arrow ai-icon" to="/settings">
                  <i
                    className="fa-solid fa-gear  requests-icon"
                    style={
                      this.state.menuActive == "settings"
                        ? {
                            fontSize: "25px",
                            color: "#FDC303",
                          }
                        : {
                            fontSize: "20px",
                            color: "white",
                          }
                    }
                  ></i>
                  <span className="nav-text">Ayarlar</span>
                </Link>
                <ul>
                  <li>
                    <Link
                      id={"navTitleStyle"}
                      onClick={() => {
                        this.props.onClick();
                        this.setState({
                          menuActive: "Profile",
                        });
                        this.changeIndex(13);
                      }}
                      to="/changeEmail"
                    >
                      <i class="fa-solid fa-envelope" id="iconNavStyle"></i>
                      <p id="pId"> E-Posta Adresini Değiştir</p>
                    </Link>
                  </li>
                  <li>
                    <Link
                      id={"navTitleStyle"}
                      onClick={() => {
                        this.props.onClick();
                        this.setState({
                          menuActive: "Profile",
                        });
                        this.changeIndex(14);
                      }}
                      to="changePassword"
                    >
                      <i class="fa-solid fa-lock" id="iconNavStyle"></i>
                      <p id="pId"> Şifre Değiştir</p>
                    </Link>
                  </li>
                  <li>
                    <Link
                      id={"navTitleStyle"}
                      onClick={() => {
                        this.props.onClick();
                        this.setState({
                          menuActive: "Profile",
                        });
                        this.changeIndex(13);
                      }}
                      to="/freezeAccount"
                    >
                      <i
                        class="fa-solid fa-unlock-keyhole"
                        id="iconNavStyle"
                      ></i>
                      <p id="pId"> Hesabını Dondur </p>
                    </Link>
                  </li>
                  <li>
                    <Link
                      onClick={() => {
                        this.props.onClick();
                        this.setState({
                          menuActive: "Profile",
                        });
                        this.changeIndex(13);
                      }}
                      to="/deleteAccount"
                      id={"navTitleStyle"}
                    >
                      <i class="fa-solid fa-user-xmark" id="iconNavStyle"></i>
                      <p id="pId"> Hesabını Sil</p>
                    </Link>
                  </li>
                </ul>
              </li>
              <li className={`${widget.includes(path) ? "mm-active" : ""}`}>
                <Link
                  to="/"
                  className="ai-icon"
                  onClick={() => {
                    this.setState({ approvedExit: true });
                  }}
                >
                  <i
                    className="fa fa-arrow-right user-profile-icon"
                    style={
                      this.state.menuActive == "mm-active"
                        ? {
                            fontSize: "25px",
                            color: "#FDC303",
                          }
                        : {
                            fontSize: "20px",
                            color: "white",
                          }
                    }
                  ></i>
                  <span className="nav-text">Çıkış Yap</span>
                </Link>
              </li>
            </MM>
            <div className="copyright">
              <p>
                <strong>bitoniş</strong> <br />
                Copyright © 2022-{new Date().getFullYear()} bitoniş
              </p>
            </div>
          </PerfectScrollbar>
        </div>
        <ExitModal
          show={this.state.approvedExit}
          handleClose={() => {
            this.setState({ approvedExit: false });
          }}
          approval={(value) => {
            if (value === true) {
              this.props.logout();
            }
          }}
        />
      </>
    );
  }
}

function mapState(state) {
  let authentication = state.authentication;
  return { authentication };
}
export default connect(mapState)(SideBar);
