import { useCallback, useState } from "react";

const useReRender = () => {
  const [, setState] = useState(0);
  const reRender = useCallback(() => {
    setState(prev => prev + 1);
  }, []);
  return reRender;
};


export default useReRender;
