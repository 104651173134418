import React from "react";
import CustomPdfPreview from "../../../../../../../../widgets/CustomPdfPreview";
import DividerDotted from "../../../../../../../../widgets/DividerDotted";
import { DividerPdf } from "./DividerPdf";

const WantsToWorkCriterion = ({ whiteMatchDetail }) => {
  return (
    <div
      className="card"
      style={{
        flex: "6",
        marginTop: "7px",
        display: "flex",
        flexDirection: "column",
        margin: "0px",
        padding: "0px",
        paddingTop:"10px",
        marginTop: "0px",
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          marginBottom: "10px",
          padding: "0px",
        }}
      >
        <div
          style={{
            border: "2px solid grey",
            borderRadius: "8px",

            height: "40px",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignContent: "center",
          }}
        >
          <p
            style={{
              padding: "10px",
              margin: "0px",
              fontSize: "10px",
              color: "#001542",
              fontWeight: "500",
            }}
          >
            Çalışmak İstediği Kriterler
          </p>
        </div>
        <hr
          style={{
            color: "black",
            marginLeft: "0px",
            paddingLeft: "0px",
            border: "1px solid grey",
            flex: "6",
            margin: "0px",
            padding: "0px",
          }}
        />
        <div
          style={{
            width: "40px",
            height: "40px",
            borderRadius: "50%",
            border: "1.8px solid grey",
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
   
          <i
            class="fa-solid fa-handshake"
            style={{
              color: "orange",
              fontSize: "12px",
            }}
          ></i>
        </div>
      </div>
      <div
        style={{
          backgroundColor: "#f7f7f7",
          borderRadius: "8px",
          marginTop: "10px",
          height: "240px",
          marginBottom: "10px",
        }}
      >
        <div
          style={{
            margin: "10px",
          }}
        ></div>{" "}
        <CustomPdfPreview
          name={"Çalışma Şekli"}
          value={
            whiteMatchDetail &&
            whiteMatchDetail.workTypes &&
            whiteMatchDetail.workTypes.map((val) => val.workTypeName).join(", ")
          }
        />
        <DividerPdf />
        <CustomPdfPreview
          name={"Maaş"}
          value={whiteMatchDetail && whiteMatchDetail.salaryRange}
        />
        <DividerPdf />
        <CustomPdfPreview
          name={"Hafta Sonu Çalışma"}
          value={
            whiteMatchDetail &&
            whiteMatchDetail.weekendWork &&
            whiteMatchDetail.weekendWork.name
          }
        />
        <DividerPdf />
        <CustomPdfPreview
          name={"Sektör"}
          value={
            whiteMatchDetail &&
            whiteMatchDetail.sector &&
            whiteMatchDetail.sector.map((val) => val.sectorName).join(", ")
          }
        />
        <DividerPdf />
        <CustomPdfPreview
          name={"İş Seyahati Tercihi"}
          value={
            whiteMatchDetail &&
            whiteMatchDetail.businessTravel &&
            whiteMatchDetail.businessTravel
              .map((val) => val.businessTravelChoiceName)
              .join(", ")
          }
        />
      </div>
    </div>
  );
};

export default WantsToWorkCriterion;
