import React, { useEffect, useState } from "react";
import api from "../../../../../../utility/Utils.js";
import DetailEditRouteFindJobSeeker from "./DetailEditRouteFindJobSeeker.jsx";
import { connect, useDispatch } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import routesImages from "../../../../../../images/updateImage.svg";
import { Button } from "react-bootstrap";
import AddRoutesModalFindJobSeeker from "./AddRoutesModalFindJobSeeker.jsx";
import DeleteModal from "./DeleteModal.jsx";
import EditIconWidget from "../../../../../widgets/EditIconWidget.jsx";
import { Box } from "@mui/material";
import BitonisAvatarGlow from "../../../../../widgets/components/BitonisAvatarGlow.jsx";

const FindJobSeekerRoutes = (props) => {
  const {
    register,
    handleSubmit,
    control,
    watch,
    reset,
    setValue,
    errors,
    submitNewVersion,
    companyId,
    selectedRouteList,
    setSelectedRouteList,
    companyName,
    companyLocationName,
    getValues,
  } = props;

  const [getRouteList, setGetRouteList] = useState([]); //eklenmiş rotalar için state tanımladım

  const [showEdit, setShowEdit] = useState(null);
  const [showAdd, setShowAdd] = useState(null);
  const [showDelete, setShowDelete] = useState(null);
  const [updatePage, setUpdatePage] = useState(null);
  const [preferredLocationLineId, setPreferredLocationLineId] = useState(null);
  useEffect(() => {
    if (companyId) {
      if (updatePage == true || updatePage == null) {
        setUpdatePage(false);
        getCompanyPreferredLocation();
      } else {
      }
    }
  }, [companyId, updatePage]);

  function getCompanyPreferredLocation() {
    //companyPreferredLocationId
    setGetRouteList([]);

    api
      .get("/company/preferredlocationlist/" + companyId)
      .then((response) => {
        if (response.succeeded == true) {
          setGetRouteList([]);
          var data = response.data;
          if (data) {
            data.map((value) => setGetRouteList((prev) => [...prev, value]));
          } else {
          }
        } else {
          //  alert("response* ** * *  " + JSON.stringify(response));
          setGetRouteList([]);
        }
      })
      .catch((err) => {
        setGetRouteList([]);
      });
  }

  const toggleSelection = (item) => {
    if (
      selectedRouteList.some(
        (selectedItem) =>
          selectedItem.companyPreferredLocationId ===
          item.companyPreferredLocationId
      )
    ) {
      setSelectedRouteList((prev) =>
        prev.filter(
          (selectedItem) =>
            selectedItem.companyPreferredLocationId !==
            item.companyPreferredLocationId
        )
      );
    } else {
      setSelectedRouteList([...selectedRouteList, item]);
    }
  };

  const handleCloseEdit = () => {
    setShowEdit(false);
  };
  const handleCloseAdd = () => {
    setShowAdd(false);
  };
  const handleDeleteClose = () => {
    setShowDelete(false);
  };
  const [companyPreferredLocationId, setCompanyPreferredLocationId] =
    useState(null);
  const [currentIndex, setCurrentIndex] = useState(null);
  const [indexSelectGetRoutes, setIndexSelectGetRoutes] = useState(null);

  return (
    <>
      <div>
  { /*
        <BitonisAvatarGlow glowColor="red" waveDuration={3} startColor="red" endColor="red">
        <i class="fa-solid fa-exclamation"></i>
        </BitonisAvatarGlow>
        */}

        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-end",
            alignItems: "center",
            marginRight: "30px",
          }}
          onClick={() => {
            setShowAdd(true);
          }}
        >
          <div
            style={{
              width: "40px",
              height: "40px",
              margin: "0px 5px 0px 0px",
              padding: "0px", cursor: "pointer",
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignContent: "center",
              alignItems: "center"
            }}
          >
            <div
              style={{
                backgroundColor: "green",
                borderRadius: "6px",
                width: "30px",
                height: "30px",
                alignItems: "center",
                display: "flex",
                justifyContent: "center",
                transition: "fontSize 0.2s", cursor: "pointer"
              }}
              onMouseOver={(e) => {
                e.currentTarget.style.width = "35px";
                e.currentTarget.style.height = "35px";
              }}
              onMouseOut={(e) => {
                e.currentTarget.style.width = "30px";
                e.currentTarget.style.height = "30px";
              }}

            >
              {" "}
              <i
                class="fa-solid fa-plus"
                style={{
                  transition: "fontSize 0.2s",
                  color: "white",
                }}
              ></i>
            </div>
          </div>
          <div
            style={{
              color: "#001542",
              fontWeight: "550",
              cursor: "pointer"
            }}
          >
            {getRouteList.length <= 0 ? (
              <div>Güzergah Ekle</div>
            ) : (
              <div>Yeni Güzergah Ekle</div>
            )}
          </div>
        </div>
        <ToastContainer
          position="top-right"
          autoClose={2000}
          hideProgressBar={false}
          newestOnTop
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />

        {getRouteList.length <= 0 ? (
          <div>
            <div
              class=""
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                alignContent: "center",
                justifyContent: "center",
                marginTop: "10px",
              }}
            >
              <img
                src={routesImages}
                style={{
                  height: "70px",
                  textAlign: "center",
                }}
              />
              <p
                style={{
                  color: "#001542",
                  marginTop: "10px",
                  marginBottom: "0px",
                  fontSize: "15px",
                  fontWeight: 500,
                  marginBottom: "50px",
                }}
              >
                Eklemiş olduğunuz güzergah bulunamadı
              </p>
            </div>
          </div>
        ) : (
          <div className="card">
            <div className="card-body">
              <div className="basic-form">
                <form onSubmit={handleSubmit(submitNewVersion)}>
                  <div
                    style={{
                      marginBottom: "0px",
                    }}
                  >
                    {getRouteList &&
                      getRouteList.map((item, index) => (
                        <div
                          key={index}
                          onClick={(event) => {
                            event.preventDefault();
                            toggleSelection(item);
                          }}
                          style={{
                            paddingLeft: 5,
                            color: "#001542",
                            border: "none",
                            margin: "0px 0px 10px 0px",
                            borderRadius: "8px",
                            width: "100%",
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-between",
                            backgroundColor: "rgb(242, 243, 244)",
                            alignContent: "center",
                            alignItems: "center",
                            cursor: "pointer",
                          }}
                        >
                          <div
                            style={{ display: "flex", alignItems: "center" }}
                          >
                            <input
                              type="checkbox"
                              style={{
                                marginLeft: "10px",
                                cursor: "pointer",
                              }}
                              checked={selectedRouteList.some(
                                (selectedItem) =>
                                  selectedItem.companyPreferredLocationId ===
                                  item.companyPreferredLocationId
                              )}
                              onChange={() => toggleSelection(item)}
                            />
                            <button
                              onClick={(event) => { }}
                              style={{
                                color: "#001542",
                                padding: "10px",
                                outline: "none",
                                border: "none",
                                margin: "10px 0px 10px 0px",
                                borderRadius: "8px",
                                width: "100%",
                                display: "flex",
                                justifyContent: "start",
                                backgroundColor: "transparent", // Buton rengi olmaması için
                              }}
                            >
                              <span
                                style={{
                                  fontSize: 14,
                                  fontWeight: 600,
                                  color: "#001542",
                                  paddingLeft: "20px",
                                }}
                              >
                                {item.name}
                              </span>
                            </button>{" "}
                          </div>

                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              justifyContent: "flex-end",
                              marginRight: "0px",
                            }}
                          >
                            <Box
                              sx={{
                                position: "relative",

                                display: "flex",
                                alignItems: "center",
                                paddingRight: "20px",
                              }}
                            >
                              <EditIconWidget
                                size={18}
                                onTap={(e) => {
                                  e.stopPropagation();

                                  setCompanyPreferredLocationId(
                                    item.companyPreferredLocationId
                                  );
                                  setPreferredLocationLineId(
                                    item.preferredLocationLineId
                                  );
                                  setIndexSelectGetRoutes(index);
                                  const currentIndexItem =
                                    selectedRouteList.findIndex(
                                      (k) =>
                                        k.companyPreferredLocationId ===
                                        item.companyPreferredLocationId
                                    );

                                  setCurrentIndex(currentIndexItem);
                                  setShowEdit(true);
                                }}
                                showContainerEdit={false}
                                showEditText={false}
                              />
                            </Box>
                          </div>
                        </div>
                      ))}
                  </div>
                </form>
              </div>
            </div>{" "}
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                margin: "0px",
              }}
            ></div>
          </div>
        )}
        <DetailEditRouteFindJobSeeker
          show={showEdit}
          getRouteList={getRouteList}
          handleClose={handleCloseEdit}
          indexSelectGetRoutes={indexSelectGetRoutes}
          companyPreferredLocationId={companyPreferredLocationId}
          preferredLocationLineId={preferredLocationLineId}
          selectedRouteList={selectedRouteList}
          setSelectedRouteList={setSelectedRouteList}
          currentIndex={currentIndex}
          setGetRouteList={setGetRouteList}
          props={props}
          companyId={companyId}
          backState={(value) => {
            //  alert(value);
            if (value === true) {
              setUpdatePage(true);
            } else {
              setUpdatePage(false);
            }
          }}
        />
        <AddRoutesModalFindJobSeeker
          show={showAdd}
          handleClose={handleCloseAdd}
          props={props}
          companyId={companyId}
          backState={(value) => {
            //  alert(value);
            if (value === true) {
              setUpdatePage(true);
            } else {
              setUpdatePage(false);
            }
          }}
        />
      </div>
      <DeleteModal
        show={showDelete}
        handleClose={handleDeleteClose}
        approval={() => {
          setShowDelete(false);
        }}
      />
    </>
  );
};
function mapState(state) {
  let authentication = state.authentication;
  let companyRoutes = state.companyAddedGetRoutes.companyRoutes;
  let countryList = state.countryList;
  let cityList = state.cityList;
  let countyList = state.countyList;
  let countyJobSeekerList = state.countyJobSeekerList;
  let districtList = state.districtList;
  return {
    authentication,
    companyRoutes,
    countryList,
    cityList,
    countyList,
    districtList,
    countyJobSeekerList,
  };
}

export default connect(mapState)(FindJobSeekerRoutes);
