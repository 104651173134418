import { useSelector } from "react-redux";
import React, { Fragment, useEffect, useMemo, useState } from "react";
import { connect, useDispatch } from "react-redux";
import "react-toastify/dist/ReactToastify.css";
import "react-toastify/dist/ReactToastify.css";
import { Button, Col, Modal, Row } from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";
import Select from "react-select";
import { ToastContainer, toast } from "react-toastify";
import {
  useHistory,
  useLocation,
} from "react-router-dom/cjs/react-router-dom.min";
import { getGroupCompanies } from "../../../../../../../redux/redux-toolkit/getGroupCompany_slicer";
import api from "../../../../../../../utility/Utils";
import EmployerGroupCompanyDetail from "./EmployerGroupCompanyDetail";
import EmployerAddGroupCompanies from "./EmployerAddGroupCompanies";
import { RowContainer } from "../RowContainer";
import { DropListAction } from "../../../../../../../action";
import useIsMobile from "../../../../../../hooks/useIsMobile";
import LoadingDotsAnimation from "../../../../../../widgets/LoadingDotsAnimation";
import DeleteCompaniesModal from "./DeleteCompaniesModal";
import EditIconWidget from "../../../../../../widgets/EditIconWidget";
import companyImage from "../../../../../../../images/avatar/company.png";
import { Box } from "@mui/material";
import PrevIconButton from "../../../../../../widgets/buttons/PrevIconButton";

const EmployerGroupCompanies = (props) => {
  const [showAdd, setShowAdd] = useState(false);
  const [showEdit, setShowEdit] = useState(false);
  const isMobile = useIsMobile();
  const dispatch = useDispatch();
  const history = useHistory();
  const [companyId, setCompanyId] = useState(null);
  const [groupCompany, setGroupCompany] = useState(null);
  const [company, setCompany] = useState(null);

  function fetchCompany() {
    //Şirket grup şirketleri
    return api
      .get("/company/baseGroupWithCount")
      .then((response) => {
        return Promise.resolve(response);
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  }

  useEffect(() => {
    getCompanies();
  }, []);

  function getCompanies() {
    fetchCompany().then(
      (getResponse) => {
        console.log("groupCompany ** * " + JSON.stringify(getResponse));
        if (getResponse) {
          if (getResponse.succeeded) {
            setGroupCompany([]);
            if (getResponse.data && getResponse.data.length > 0) {
              setGroupCompany(getResponse.data);
            } else {
            }
          }
        }
      },
      (error) => { }
    );
  }
  useEffect(() => {
    if (!props.countryList.success) {
      props.dispatch(DropListAction.countryList());
    }
    if (!props.cityList.success) {
      props.dispatch(
        DropListAction.cityList({
          countryId: 1,
        })
      );
    }
  }, []);

  const handleCloseEdit = () => {
    setShowEdit(false);
  };
  const handleCloseAdd = () => {
    setShowAdd(false);
  };
  function deleteCompanies(arg) {
    //grup şirketi sil
    // alert("arg* * * " + JSON.stringify(arg));
    let sendData = { companyId: parseInt(arg.toString()) };
    return api
      .post("/company/delete", sendData)
      .then((response) => {
        return Promise.resolve(response);
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  }
  function deleteCompany(id) {
    deleteCompanies(id).then(
      (deleteResponse) => {
        if (deleteResponse.succeeded === true) {
          toast.success("Grup Şirketi başarılı şekilde silindi", {
            autoClose: 2000,
            onClose: () => {
              setTimeout(() => {
                window.location.href = window.location.href;
              }, 100);
            },
          });
        } else {
          toast.info(deleteResponse.error.message, {
            autoClose: 2000,
            onClose: () => {
              setTimeout(() => {
                window.location.href = window.location.href;
              }, 100);
            },
          });
        }
      },
      (error) => {
        toast.info("Hata Oluştu", {
          autoClose: 2000,
          onClose: () => {
            setTimeout(() => {
              window.location.href = window.location.href;
            }, 100);
          },
        });
      }
    );
  }

  return (
    <div>
      <ToastContainer
        position="top-right"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />

      {groupCompany === null ? (
        <div >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
            }}
          >
            <LoadingDotsAnimation  />
          </div>
        </div>
      ) : (
        <div className="">
          <div
            style={{
              marginLeft: "25px",
              marginBottom: "0px",
              paddingBottom: "0px",
              display: "flex",
              flexDirection: "row",
            }}
          ></div>

          <form>
            <div
              className="card"
              style={{
                height: groupCompany && groupCompany.length <= 4 ? "500px" : "",
                position: "relative",
              }}
            >
              <PrevIconButton top={10} left={5} />
              {groupCompany && groupCompany.length > 0 && (
                <p
                  style={{
                    color: "#001542",
                    fontWeight: "600",
                    padding: "0px",
                    margin: "0px",
                    textAlign: "center",
                    marginTop: "10px",
                    fontSize: isMobile ? "15px" : "18px",
                  }}
                >
                  Grup Şirketlerim
                </p>
              )}

              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  alignContent: "flex-end",
                  alignItems: "flex-end",
                  flexDirection: "row",
                  marginTop: isMobile ? "30px" : "10px",
                  textAlign: "center",
                  position: "absolute",
                  right: "10px",
                  top: "0px",
                  marginRight: "25px",
                  fontSize: isMobile ? "9px" : "13px",
                }}
              >
                <div
                  style={{
                    width: isMobile ? "25px" : "30px",
                    height: isMobile ? "25px" : "30px",
                    marginTop: "10px",
                    padding: "0px",
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                    alignContent: "center",
                    alignItems: "center",
                    cursor: "pointer",
                  }}
                >
                  <div
                    style={{
                      backgroundColor: "green",
                      borderRadius: "6px",
                      width: isMobile ? "20px" : "30px",
                      height: isMobile ? "20px" : "30px",
                      alignItems: "center",
                      display: "flex",
                      justifyContent: "center",
                      transition: "fontSize 0.2s",
                    }}
                    onMouseOver={(e) => {
                      e.currentTarget.style.width = "35px";
                      e.currentTarget.style.height = "35px";
                    }}
                    onMouseOut={(e) => {
                      e.currentTarget.style.width = "30px";
                      e.currentTarget.style.height = "30px";
                    }}
                    onClick={() => {
                      setShowAdd(true);
                    }}
                  >
                    <i
                      class="fa-solid fa-plus"
                      style={{
                        transition: "fontSize 0.2s",
                        color: "white",
                      }}
                    ></i>
                  </div>
                </div>

                <div
                  style={{
                    color: "#001542",
                    fontWeight: "550",
                    marginLeft: "6px",
                    cursor: "pointer",
                    marginTop: "0px",
                    fontSize: isMobile ? "11px" : "12px",
                    marginBottom: "5px",
                  }}
                  onClick={() => {
                    setShowAdd(true);
                  }}
                >
                  Grup Şirketi Ekle
                </div>
              </div>

              {groupCompany && groupCompany.length <= 0 && (
                <div>
                  <div
                    style={{
                      marginTop: isMobile ? "40%" : "20%",
                    }}
                  >
                    <div
                      class=""
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        alignContent: "center",
                        justifyContent: "center",
                        marginTop: "10px",
                      }}
                    >

                      <Box
                        component="img"
                        sx={{
                          width: {
                            xs: "60px",
                            sm: "100px",
                            md: "100px",
                            lg: "100px",
                            xl: "100px",
                          },
                          height: {
                            xs: "60px",
                            sm: "100px",
                            md: "100px",
                            lg: "100px",
                            xl: "100px",
                          }
                        }}
                        alt=""
                        src={companyImage}
                      />

                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <p
                          style={{
                            color: "#001542",
                            marginTop: "10px",
                            marginBottom: "0px",
                            fontSize: "16px",
                            fontWeight: 600,
                            marginBottom: "0px",
                          }}
                        >
                          Grup Şirketi Bulunamadı
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              )}

              <Row
                style={{
                  margin: "10px",
                  padding: "5px",
                  marginTop: "7%",
                }}
              >
                {groupCompany &&
                  groupCompany.map((item, index) => (
                    <AddedCompanyItem
                      item={item}
                      index={index}
                      key={index}
                      length={
                        groupCompany && groupCompany && groupCompany.length
                      }
                      deleteCompany={deleteCompany}
                      setShowEdit={setShowEdit}
                      setCompanyId={setCompanyId}
                      setCompany={setCompany}
                    />
                  ))}
              </Row>
              <EmployerAddGroupCompanies
        show={showAdd}
        handleClose={handleCloseAdd}
        props={props}
      />
      <EmployerGroupCompanyDetail
        companyId={company}
        show={showEdit}
        handleClose={handleCloseEdit}
        id={companyId}
        props={props}
      />
            </div>
          </form>
        </div>
      )}
    
    </div>
  );
};

const AddedCompanyItem = ({
  item,
  index,
  length,
  deleteCompany,
  setShowEdit,
  setCompanyId,
  setCompany,
}) => {
  const isMobile = useIsMobile();
  const history = useHistory();
  const [showDeleteCompanies, setShowDeleteCompanies] = useState(null);
  function handleDeleteClose() {
    setShowDeleteCompanies(false);
  }
  return (
    <>
      <Col
        xs={12}
        sm={length == 1 ? 12 : 6}
        md={length == 1 ? 12 : 6}
        lg={length == 1 ? 12 : 6}
        xl={length == 1 ? 12 : 6}
        style={{
          marginBottom: "10px",
          cursor: "pointer",
        }}
        onClick={() => { }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "flex-start",
            backgroundColor: " #f7f7f7",
            borderRadius: "6px",
            justifyContent: "flex-start",
            border: "1px solid #f3f3f6",
            padding: "25px 0px 30px 15px",
            height: isMobile ? "85px" : "100px",
            position: "relative",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
            }}
          >
            <p
              style={{
                color: "#001542",
                fontWeight: "bold",
                fontSize: isMobile ? "11px" : "13px",
              }}
            >
              Firma Adı :
            </p>
            <p
              style={{
                color: "#001542",
                fontWeight: 500,
                fontSize: isMobile ? "11px" : "13px",
                marginLeft: "5px",
                width: isMobile ? "170px" : length == 1 ? "600px" : "170px",
                overflow: "hidden",
              }}
            >
              {item.name}
            </p>
          </div>

          <div
            style={{
              position: "absolute",
              top: isMobile ? "5px" : "10px",
              right: "2%",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
              }}
            >
              <div
                class=""
                style={{
                  fontSize: "20px",
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  alignContent: "center",
                  alignItems: "center"
                }}
              >

                <EditIconWidget
                  size={15}
                  onTap={() => {
                    setCompanyId(item.id);
                    setCompany(item);
                    if (item) {
                      setShowEdit(true);
                    }
                  }}
                  showEditText={false}


                />

                <i
                  class="fas fa-trash-o"
                  style={{
                    fontSize: isMobile ? "15px" : "17px",
                    marginRight: isMobile ? "5px" : "0px",
                    color: "red",
                    paddingLeft: "12px"
                  }}
                  onClick={() => {
                    setShowDeleteCompanies(true);
                  }}
                ></i>
              </div>

              <p
                style={{
                  margin: "0px",
                  padding: "0px",
                  marginLeft: "5px",
                  color: "#001542",
                  fontSize: isMobile ? "10px" : "11px",
                  fontWeight: 410,
                  textAlign: "center",
                  marginBottom: "5px",
                }}
              ></p>
            </div>
          </div>
          <div
            style={{
              position: "absolute",
              bottom: "0px",
              right: "5px",
              bottom: "5px",
            }}
          >
            <Button
              onClick={() => {
                const dataMap = new Map();
                dataMap.set("companyId", item.id);
                history.push({
                  pathname: "/companyLocations",
                  state: { dataMap },
                });
              }}
              style={{
                height: isMobile ? "30px" : "30px",
                backgroundColor: "#001542",
                display: "flex",
                justifyContent: "center",
                alignContent: "center",
                alignItems: "center",
                fontSize: isMobile ? "9px" : "11px",
                color: "white",
                borderRadius: "0px",
              }}
            >
              Lokasyonlarım
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  alignContent: "center",
                  alignItems: "center",
                  marginLeft: "5px",
                }}
              >
                <i
                  class="fa-solid fa-route"
                  style={{
                    fontSize: isMobile ? "13px" : "14px",
                    color: "orange",
                  }}
                ></i>
                <p
                  style={{
                    margin: "0px",
                    padding: "0px",
                    marginLeft: "5px",
                    color: "white",
                    fontSize: isMobile ? "10px" : "14px",
                    fontWeight: 410,
                  }}
                >
                  {item.count}
                </p>
              </div>
            </Button>
          </div>
        </div>
      </Col>
      <DeleteCompaniesModal
        show={showDeleteCompanies}
        handleClose={handleDeleteClose}
        approval={() => {
          setShowDeleteCompanies(false);
          deleteCompany(item.id);
        }}
      />
    </>
  );
};
function mapState(state) {
  let authentication = state.authentication;
  let companyDetail = state.companyDetail;
  let countryList = state.countryList;
  let cityList = state.cityList;
  let countyList = state.countyList;
  let districtList = state.districtList;
  let companyMainDetail = state.companyMainDetail;
  let sectorList = state.sectorList;
  let taxOffices = state.taxOffices;

  return {
    authentication,
    companyDetail,
    countryList,
    cityList,
    countyList,
    districtList,
    companyMainDetail,
    sectorList,
    taxOffices,
  };
}

export default connect(mapState)(EmployerGroupCompanies);
