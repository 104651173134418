import React, { useEffect, useRef, useState } from "react";

import Modal from "react-bootstrap/Modal"; // Varsayılan olarak React Bootstrap Modal bileşeni kullanılıyor
import { Alert, Button } from "react-bootstrap";
import { usePinInput } from "react-pin-input-hook";
import "../../../../../../../css/bitoniscss/demand/inputPın.css";
import CountdownTimerOtp from "../CountdownTimerOtp";
import EmployerToBitonisContact from "../../../../../../pages/EmployerToBitonisContact";

function ProfileOtpModal({
  show,
  handleClose,
  approval,
  time,
  handleTimeChange,
  requestAgainEmailOtpCode,
  again,
  showContact,
  setShowContact,
  handleContactClose,
}) {
  return (
    <>
      <div>
        <MyVerticallyCenteredModal
          show={show}
          onHide={() => handleClose(false)}
          approval={approval}
          time={time}
          handleTimeChange={handleTimeChange}
          requestAgainEmailOtpCode={requestAgainEmailOtpCode}
          again={again}
          showContact={showContact}
          setShowContact={setShowContact}
          handleContactClose={handleContactClose}
        />
      </div>
    </>
  );
}
function MyVerticallyCenteredModal(props) {
  const {
    show,
    approval,
    time,
    handleTimeChange,
    requestAgainEmailOtpCode,
    again,
    onHide,
    showContact,
    setShowContact,
    handleContactClose,
  } = props;
  const [values, setValues] = useState(["", "", "", "", "", ""]);
  const [error, setError] = useState(false);
  var [againTimer, setAgainTimer] = useState(false);
  const actionRef = useRef();

  const { fields } = usePinInput({
    values,
    onChange: setValues,
    error,
    actionRef,
    onComplete: (value) => {
      console.log(value);
    },
  });
  useEffect(() => {
    if (show) {
    }
  }, [show]);
  const onSubmit = (val) => {
    val.preventDefault();
    if (values.includes("")) {
      setError(true);
      actionRef.current?.focus();
    } else {
      setError(false);

      approval(values.join(""));
    }
  };
 
  return (
    <Modal
      {...props}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      dialogClassName="full-screen-modal"
    >
      <Modal.Header
        closeButton
        style={{ borderBottom: "none", justifyContent: "center" }}
      >
        <Modal.Title aria-labelledby="contained-modal-title-vcenter" centered>
          <h3 style={{ color: "#001542", fontSize: "20px" }}></h3>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body
        style={{
          margin: "0px",
          padding: "0px",
        }}
      >
        <div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignContent: "center",
              alignItems: "center",
            }}
          ></div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              alignContent: "center",

              color: "#001542",
              marginTop: "5px",
              textAlign: "center",
            }}
          >
            <h3>Kod için E-Postanızı kontrol ediniz</h3>
            <p
              style={{
                color: "#001542",
                fontWeight: "400",
                margin: "0px 5px 5px 5px",
                fontSize: "14px",
              }}
            >
              Lütfen e-posta adresinize <strong></strong>
              gönderilen doğrulama kodunu girin.
            </p>
            <div
              className="pin-input"
              style={{
                margin: "0px",
                padding: "0px",
              }}
            >
              {fields.map((fieldProps, index) => (
                <input
                  key={fieldProps}
                  className="pin-input__field pinInput"
                  {...fieldProps}
                />
              ))}
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "flex-start",
                  alignContent: "flex-start",
                  alignItems: "flex-start",
                }}
              >
                {error && (
                  <div
                    style={{
                      fontSize: "16px",
                      color: "red",
                      fontWeight: "500",
                      margin: "0px",
                      padding: "0px",
                    }}
                  >
                    Boş Geçilemez
                  </div>
                )}
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "end",
                  alignContent: "end",
                  alignItems: "end",
                }}
              >
                <CountdownTimerOtp
                  initialMinute={time / 60}
                  onTimeChange={handleTimeChange}
                  again={again}
                />
              </div>
            </div>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignContent: "center",
            alignItems: "center",
            cursor: "pointer",
          }}
          onClick={requestAgainEmailOtpCode}
        >
          <div
            style={{
              backgroundColor: "#001542",
              width: "30px",
              height: "30px",
              borderRadius: "8px",
              display: "flex",
              justifyContent: "center",
              alignContent: "center",
              alignItems: "center",
            }}
          >
            <i
              class="fa-solid fa-rotate-right"
              style={{
                fontSize: "15px",
                color: "white",
              }}
            ></i>
          </div>
          <p
            style={{
              color: "#001542",
              fontWeight: "400",
              fontSize: "11px",
              marginTop: "5px",
            }}
          >
            Kod Size Ulaşmadı mı ?{" "}
            <strong
              style={{
                color: "#001542",
                fontSize: "15px",
              }}
            >
              Yeniden Gönder
            </strong>
          </p>
        </div>
        <div
          onClick={() => {
            onHide()
            setShowContact(true);
          }}
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignContent: "center",
            alignItems: "center",
          }}
        >
          <div
            style={{
              marginTop: "0px",
              marginBottom: "0px",
              borderRadius: "6px",
              width: "45%",
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignContent: "center",
              alignItems: "center",
              cursor: "pointer",
            }}
          >
            <p
              className="mb-0"
              style={{
                padding: "5px",
                fontSize: "12px",
                fontWeight: "550px",
                textDecoration: "underline",
              }}
            >
              Bizimle iletişime Geç
            </p>
          </div>
        </div>
       
      </Modal.Body>
      <Modal.Footer
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          alignContent: "center",
          borderTop: "none",
        }}
      >
        <Button
          as="input"
          type="button"
          value="Doğrula"
          onClick={onSubmit}
          style={{
            borderRadius: "8px",
            fontSize: "15px",
            padding: "15px",
            width: "300px",
            cursor: "pointer",
            transition: "background-color 0.3s",
            backgroundColor: "#001542",
            border: "none",
          }}
        />
      </Modal.Footer>
    </Modal>
  );
}

export default ProfileOtpModal;
