export const UserConstants = {
    REGISTER_REQUEST: 'USERS_REGISTER_REQUEST',
    REGISTER_SUCCESS: 'USERS_REGISTER_SUCCESS',
    REGISTER_FAILURE: 'USERS_REGISTER_FAILURE',

    LOGIN_REQUEST: 'USERS_LOGIN_REQUEST',
    LOGIN_SUCCESS: 'USERS_LOGIN_SUCCESS',
    USER_SUCCESS: 'USERS_USER_SUCCESS',
    LOGIN_FAILURE: 'USERS_LOGIN_FAILURE',

    LOST_REQUEST: 'USERS_LOST_REQUEST',
    LOST_SUCCESS: 'USERS_LOST_SUCCESS',
    LOST_FAILURE: 'USERS_LOST_FAILURE',

    RESET_REQUEST: 'USERS_RESET_REQUEST',
    RESET_SUCCESS: 'USERS_RESET_SUCCESS',
    RESET_FAILURE: 'USERS_RESET_FAILURE',

    LOGOUT: 'USERS_LOGOUT',

    GET_REQUEST: 'USERS_GET_REQUEST',
    GET_SUCCESS: 'USERS_GET_SUCCESS',
    OWN_SUCCESS: 'USERS_OWN_SUCCESS',
    GET_FAILURE: 'USERS_GET_FAILURE',

    GET_ALL_REQUEST: 'USERS_GET_ALL_REQUEST',
    GET_ALL_SUCCESS: 'USERS_GET_ALL_SUCCESS',
    GET_ALL_FAILURE: 'USERS_GET_ALL_FAILURE',

    DELETE_REQUEST: 'USERS_DELETE_REQUEST',
    DELETE_SUCCESS: 'USERS_DELETE_SUCCESS',
    DELETE_FAILURE: 'USERS_DELETE_FAILURE'
};
