import React, { useEffect, useState } from "react";
import {
  useHistory,
  useLocation,
} from "react-router-dom/cjs/react-router-dom.min";
import { Form, InputGroup, Dropdown, Button } from "react-bootstrap";
import { Card, Tab, Nav } from "react-bootstrap";
import { DemandsServices } from "../../../../../../../../services";
import PaginationPage from "../../../../../../../widgets/PaginationPage";
import MatchNotFound from "../../MatchShow/BlueMatchShow/MatchNotFound";
import NotHireItem from "../../NotHire/NotHireItem";
import api from "../../../../../../../../utility/Utils";
import ShowEmployerJobSeekerDocuments from "../../Hire/Common/ShowEmployerJobSeekerDocuments";
import HireItem from "../../Hire/HireItem";
import PopupLongMenu from "../../../../../../../widgets/PopupLongMenu";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import LoadingDotsAnimation from "../../../../../../../widgets/LoadingDotsAnimation";
import CurrentInternMatchFilter from "./modal/CurrentInternMatchFilter";
import InternTabItem from "../../MatchDetail/Intern/InternTabItem";
const SeeInternCurrentDemandMatchShow = () => {
  const [search, setSearch] = useState("");
  const [hireItem, setHireItem] = useState(null);
  const [candidateId, setCandidateId] = useState(null);
  const [getDocument, setGetDocument] = useState(false);

  const [show, setShow] = useState(null);
  function handleClose() {
    setShow(false);
  }
  const history = useHistory();
  const location = useLocation(); // useLocation hook'u ile location alınır
  const { dataMap } = location.state;
  const jobId = dataMap.get("jobId");
  const positionName = dataMap.get("positionName");
  var matchSelectedList = [
    { title: "Staja  Alındı", status: 2 },
    { title: "Staja Alınmadı", status: 3 },
    { title: "Yeni Eşleşme", status: 0 },
  ];

  const [selectedFilter, setSelectedFilter] = useState([
    { title: "Staja  Alındı", status: 2 },
    { title: "Staja Alınmadı", status: 3 },
    { title: "Yeni Eşleşme", status: 0 },
  ]);

  const [currentDemandMatchAllShowData, setCurrentDemandMatchAllShowData] =
    useState(null);

  useEffect(() => {
    if (jobId) {
      getCurrentDemandMatchAllShow();
    }
  }, [jobId, selectedFilter]);
  function getCurrentDemandMatchAllShow() {
    DemandsServices.internCurrentDemandToGetDataMatchShow(
      selectedFilter,
      jobId
    ).then(
      (demandMatch) => {
        if (demandMatch && demandMatch.succeeded === true) {
          setCurrentDemandMatchAllShowData([]);
          setCurrentDemandMatchAllShowData(demandMatch.data.pagedData);
        }
      },
      (error) => {}
    );
  }

  function searchHandleCandidate(value) {
    setSearch(value);
  }

  const [page, setPage] = useState(1);
  const pageSize = 10; // Her sayfada gösterilecek eleman sayısı

  const paginatedList =
    currentDemandMatchAllShowData && page === null
      ? currentDemandMatchAllShowData
      : currentDemandMatchAllShowData &&
        currentDemandMatchAllShowData.slice(
          (page - 1) * pageSize,
          page * pageSize
        );
  const [openDialogSelectItem, setOpenDialogSelectItem] = useState(null);

  return (
    <div id="demandShow">
      <ToastContainer
        position="top-right"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />

      <div className="header-center">
        <div
          className=""
          style={{
            textTransform: "capitalize",
            color: "#3d4465",
            fontSize: "17px",
            marginBottom: "0px",
            paddingBottom: "10px",
            fontWeight: "600",
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignContent: "center",
            alignItems: "center",
          }}
        >
          <strong
            style={{
              marginRight: "5px",
            }}
          >
            {" "}
            {positionName}{" "}
          </strong>{" "}
          Talebinizle Eşleşen Adaylar
        </div>
      </div>
      <div className="row">
        <div className="col-md-12">
          <Card>
            {" "}
            <div
              style={{
                margin: "10px",
                width: "35px",
                height: "35px",
                backgroundColor: "#F5F5F5",
                borderRadius: "50%",
                display: "flex",
                justifyContent: "center",
                alignContent: "center",
                alignItems: "center",
                marginBottom: "0px",     cursor:"pointer"
              }}
            >
              <i
                class="fa-solid fa-arrow-left"
                style={{
                  fontSize: "15px",
                  color: "#3d4465",
                }}
                onClick={() => {
                  history.goBack(); // Geri git fonksiyonu
                }}
              ></i>
            </div>
            <Card.Body>
              <div>
                <div className="">
                  <InputGroup className="mb-3" style={{}}>
                    <Form.Control
                      style={{
                        borderRadius: "20px",
                        backgroundColor: "#F5F5F5",
                        marginLeft: "10px",
                      }}
                      type="search"
                      placeholder="Bölüme göre ara"
                      value={search}
                      onChange={(e) => searchHandleCandidate(e.target.value)}
                    />

                    <Button
                      variant="outline"
                      onClick={() => {
                        setShow(true);
                      }}
                      style={{
                        borderRadius: "8px",
                        border: "1px solid #F5F5F5",
                        marginLeft: "30px",
                        backgroundColor: "white",
                      }}
                    >
                      <i
                        class="fa-solid fa-arrow-down-wide-short"
                        style={{
                          color: "#fdc303",
                          fontSize: "20px",
                        }}
                      ></i>
                      <a
                        style={{
                          color: "#001542",
                          fontWeight: "600px",
                          margin: "5px",
                        }}
                      >
                        Filtrele
                      </a>
                    </Button>
                  </InputGroup>
                </div>
              </div>
              <Tab.Container defaultActiveKey={"demandCurrentDemandMatchShow"}>
                <Tab.Content
                  style={{
                    marginTop: "0px",
                    paddingTop: "0px",
                  }}
                >
                  {currentDemandMatchAllShowData === null ? (
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "center",
                      }}
                    >
                      <LoadingDotsAnimation />
                    </div>
                  ) : currentDemandMatchAllShowData &&
                    paginatedList &&
                    paginatedList.length > 0 ? (
                    paginatedList && (
                      <div
                        style={{
                          marginBottom: "10%",
                          marginTop: "0px",
                          paddingTop: "0px",
                        }}
                      >
                        {paginatedList.map((matchData, index) => {
                          return paginatedList[index].departmentName
                            .toLowerCase()
                            .includes(search.toLowerCase()) ? (
                            <div
                              style={{
                                position: "relative",
                              }}
                            >
                              <div
                                onClick={() => {
                                  const dataMap = new Map();
                                  dataMap.set(
                                    "candidateId",
                                    matchData.candidateId
                                  );
                                  dataMap.set("jobId", jobId);
                                  dataMap.set(
                                    "positionName",
                                    matchData.departmentName
                                  );

                                  dataMap.set("matchId", matchData.matchId);
                                  dataMap.set("status", 2);
                                  history.push({
                                    pathname: "/matchShow/internMatchDetail",
                                    state: { dataMap },
                                  });
                                }}
                              >
                                <InternTabItem
                                  key={matchData.candidateId}
                                  candidateNameSurname={
                                    matchData.candidateNameSurname
                                  }
                                  positionName={matchData.departmentName}
                                  status={matchData.status}
                                  candidateId={matchData.candidateId}
                                  matchCreateDate={matchData.internMatchDate}
                                  internEducationTypeName={
                                    matchData.internEducationTypeName
                                  }
                                />
                              </div>
                            </div>
                          ) : (
                            <div></div>
                          );
                        })}
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                          }}
                        >
                          {currentDemandMatchAllShowData &&
                            currentDemandMatchAllShowData.length > 0 &&
                            currentDemandMatchAllShowData.length > pageSize && (
                              <PaginationPage
                                items={currentDemandMatchAllShowData}
                                page={page}
                                pageSize={pageSize}
                                onChange={(value) => {
                                  if (value) {
                                    setPage(value);
                                  }
                                }}
                                onTapAllShowButton={() => {
                                  setPage(null);
                                }}
                              />
                            )}
                        </div>
                      </div>
                    )
                  ) : (
                    <MatchNotFound title={""} newMatch={true} />
                  )}
                </Tab.Content>
              </Tab.Container>
            </Card.Body>
          </Card>
        </div>
      </div>

      <CurrentInternMatchFilter
        show={show}
        handleClose={handleClose}
        approval={(value) => {
          if (value === null) {
            setSelectedFilter([
              { title: "Staja  Alındı", status: 2 },
              { title: "Staja Alınmadı", status: 3 },
              { title: "Yeni Eşleşme", status: 0 },
            ]);
          } else {
            setSelectedFilter(value);
          }
          handleClose();
        }}
        matchFilterList={matchSelectedList}
        selectedStatus={selectedFilter}
      />
    </div>
  );
};

export default SeeInternCurrentDemandMatchShow;
