import api from "../utility/Utils";

export const DashboardServices = {
  device,
  create,
  labourerOwn,
  devicename,
  loaddevice,
  lastLabourer,
  labourer,
  labourerMatch,
  jobDetail,
  payment,
  getNotification,
  changeStatusNotification,
  employerActiveDemandsCount,
  getRemainderJobCount,
  lastDemands,
  getNotificationUnReadCount,
  getHeaderCompanyMain,
  jobInternDetail,
};
function getNotification(pageNumber, didRead) {
  var sendData = {
    pageNumber: pageNumber,
    pageSize: 12,
    userId: null,
    didRead: didRead,
  };
  return api
    .post("/common/notification/getlist", sendData)
    .then((response) => {
      return Promise.resolve(response);
    })
    .catch((err) => {
      return Promise.reject(err);
    });
}
function getNotificationUnReadCount() {
  return api
    .get("/common/notification/countByUser")
    .then((response) => {
      return Promise.resolve(response);
    })
    .catch((err) => {
      return Promise.reject(err);
    });
}
function getHeaderCompanyMain() {
  return api
    .get("/company/main/header")
    .then((response) => {
      return Promise.resolve(response);
    })
    .catch((err) => {
      return Promise.reject(err);
    });
}
function getRemainderJobCount() {
  //kalan talep hakkını almak için kullandım
  return api
    .get("/payment/getCompanyPermission")
    .then((response) => {
      return Promise.resolve(response);
    })
    .catch((err) => {
      return Promise.reject(err);
    });
}
function lastDemands(arg) {
  //son talepler
  return api
    .post("demand/list", arg)
    .then((response) => {
      return Promise.resolve(response);
    })
    .catch((err) => {
      return Promise.reject(err);
    });
}

function employerActiveDemandsCount() {
  return api
    .get("/job/count?isActive=true")
    .then((response) => {
      return Promise.resolve(response);
    })
    .catch((err) => {
      return Promise.reject(err);
    });
}
function changeStatusNotification(eventNotificationId) {
  return api
    .get("/common/notification/changestatus/" + eventNotificationId)
    .then((response) => {
      return Promise.resolve(response);
    })
    .catch((err) => {
      return Promise.reject(err);
    });
}

function labourerOwn(arg) {
  return api
    .get("post/own", { params: arg })
    .then((response) => {
      return Promise.resolve(response);
    })
    .catch((err) => {
      return Promise.reject(err);
    });
}

function device(arg) {
  return api
    .get("post/" + arg.type + "/" + arg.value)
    .then((response) => {
      return Promise.resolve(response);
    })
    .catch((err) => {
      return Promise.reject(err);
    });
}

function create(arg) {
  return api
    .post("post/add", arg)
    .then((response) => {
      return Promise.resolve(response);
    })
    .catch((err) => {
      return Promise.reject(err);
    });
}

function devicename(arg) {
  return api
    .post("setdevicename", arg)
    .then((response) => {
      return Promise.resolve(response);
    })
    .catch((err) => {
      return Promise.reject(err);
    });
}
function lastLabourer(arg) {
  // Anasayfa son taleplerim Beyaz Yaka
  return api
    .post("/demand/list", arg)
    .then((response) => {
      return Promise.resolve(response.data);
    })
    .catch((err) => {
      return Promise.reject(err);
    });
}

function labourer(arg) {
  return api
    .post("/demand/list", arg)
    .then((response) => {
      return Promise.resolve(response.data);
    })
    .catch((err) => {
      return Promise.reject(err);
    });
}

function jobDetail(arg) {
  return api
    .get("/job/" + arg)
    .then((response) => {
      return Promise.resolve(response.data);
    })
    .catch((err) => {
      return Promise.reject(err);
    });
}
function jobInternDetail(arg) {
  return api
    .get("/intern/job/" + arg)
    .then((response) => {
      return Promise.resolve(response.data);
    })
    .catch((err) => {
      return Promise.reject(err);
    });
}
function labourerMatch(arg) {
  return api
    .post("/demand/list", arg)
    .then((response) => {
      // //console.log("laborerMatch", response)
      return Promise.resolve(response.data);
    })
    .catch((err) => {
      return Promise.reject(err);
    });
}

function loaddevice(arg) {
  return api
    .post("loaddevice", arg)
    .then((response) => {
      return Promise.resolve(response);
    })
    .catch((err) => {
      return Promise.reject(err);
    });
}
function payment(arg) {
  return api
    .post("/payment/iyzicopayment3D", arg)
    .then((response) => {
      //  debugger;
      return Promise.resolve(response);
    })
    .catch((err) => {
      return Promise.reject(err);
    });
}
