import React, { useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import Select from "react-select";
import { useForm, Controller } from "react-hook-form";
import {
  DropListAction,
  DropListWhiteAction,
} from "../../../../../../../action";
import {
  customStylesMulti,
  labelStyle,
} from "../../../../../../customStyleInline";
import Alert from "@mui/material/Alert";
import ProgramsCertificatesCompetences from "../../../ProgramsCertificatesCompetences/ProgramsCertificatesCompetences";
import AbilitiesRequiredWidget from "../../Common/AbilitiesRequiredWidget";

const FindBlueAbilities = (props) => {
  const {
    register,
    handleSubmit,
    control,
    watch,
    reset,
    setValue,
    errors,
    submitNewVersion,
    companyName,
    companyLocationName,
    getValues,
  } = props;

  return (
    <div>
      <div
        className="card"
        style={{
          boxShadow: "none",
          margin: "0px",
          padding: "0px",
        }}
      >
        <div className="basic-form">
          <form onSubmit={handleSubmit(submitNewVersion)}>
            <ProgramsCertificatesCompetences
              programList={props.programList.programList}
              certificateList={props.certificatesList.certificatesList}
              CompetenceList={props.competencesList.competencesList}
              control={control}
              register={register}
              watch={watch}
              setValue={setValue}
              errors={errors}
              getValues={getValues}
              checkCertificate={props.checkCertificate}
              checkCompetence={props.checkCompetence}
              checkProgram={props.checkProgram}
              setCheckCertificate={props.setCheckCertificate}
              setCheckCompetence={props.setCheckCompetence}
              setCheckProgram={props.setCheckProgram}
              preview={false}
            />
          </form>
        </div>
      </div>
    </div>
  );
};
function mapState(state) {
  let authentication = state.authentication;
  let labourer = state.labourer;
  let labourerMatch = state.labourerMatch;
  let demands = state.demands;
  let job = state.job;
  let yearOfExperience = state.yearOfExperience;
  let positionList = state.positionList;
  let wayOfWorking = state.wayOfWorking;
  let nationalityList = state.nationalityList;
  let ageRangeList = state.ageRangeList;
  let driverLicenceType = state.driverLicenceType;
  let programList = state.programList;
  let certificatesList = state.certificatesList;
  let competencesList = state.competencesList;
  let countryList = state.countryList;
  let cityList = state.cityList;
  let countyList = state.countyList;
  let districtList = state.districtList;
  let companyList = state.companyList;
  let companyLocation = state.companyLocation;

  let companyItemLocation = state.companyItemLocation;

  //alert("companyItemLocation * * * * * "+JSON.stringify(companyItemLocation))
  // Beyaz Yaka
  let yearOfExperienceWhite = state.yearOfExperienceWhite;
  let positionListWhite = state.positionListWhite;
  let wayOfWorkingWhite = state.wayOfWorkingWhite;
  let nationalityListWhite = state.nationalityListWhite;
  let ageRangeListWhite = state.ageRangeListWhite;
  let driverLicenceTypeWhite = state.driverLicenceTypeWhite;
  let programListWhite = state.programListWhite;
  let certificatesListWhite = state.certificatesListWhite;
  let competencesListWhite = state.competencesListWhite;
  let countryListWhite = state.countryListWhite;

  let cityListWhite = state.cityListWhite;
  let countyListWhite = state.countyListWhite;
  let districtListWhite = state.districtListWhite;
  let companyListWhite = state.companyListWhite;
  let salaryListWhite = state.salaryListWhite;
  let businessTravelListWhite = state.businessTravelListWhite;
  let languageListWhite = state.languageListWhite;
  let languageLevelListWhite = state.languageLevelListWhite;
  let universityListWhite = state.universityListWhite;
  let facultyListWhite = state.facultyListWhite;
  let facultyDepartmentListWhite = state.facultyDepartmentListWhite;

  return {
    authentication,
    labourer,
    labourerMatch,
    demands,
    job,
    yearOfExperience,
    positionList,
    wayOfWorking,
    nationalityList,
    ageRangeList,
    driverLicenceType,
    programList,
    certificatesList,
    competencesList,
    countryList,
    cityList,
    countyList,
    districtList,
    companyList,
    companyLocation,
    yearOfExperienceWhite,
    positionListWhite,
    wayOfWorkingWhite,
    nationalityListWhite,
    ageRangeListWhite,
    driverLicenceTypeWhite,
    programListWhite,
    certificatesListWhite,
    competencesListWhite,
    countryListWhite,
    cityListWhite,
    countyListWhite,
    districtListWhite,
    companyListWhite,
    salaryListWhite,
    businessTravelListWhite,
    languageListWhite,
    languageLevelListWhite,
    universityListWhite,
    facultyListWhite,
    facultyDepartmentListWhite,
    companyItemLocation,
  };
}
export default connect(mapState)(FindBlueAbilities);
