import React from "react";
import "../../../../../../../css/bitoniscss/notificationItem.css";
import moment from "moment";

const NotificationItem = ({ notification, isRead,onTap ,key }) => {
  return (
    <div onClick={onTap} key={key}>
      <div className="notification-item">
        <div className="leading">
          <i
            className="fa-regular fa-bell bell"
            style={
              isRead == false
                ? {
                    color: "orange",
                  }
                : {
                    color: "green",
                  }
            }
          ></i>
          <div className="title-subtitle">
            <p className="n-item-title">{notification.title}</p>
            <p className="n-message">{notification.message}</p>
          </div>
        </div>
        <div className="n-date-container">
          <p
            className="n-date"
            style={
              isRead == false
                ? {
                    color: "orange",
                  }
                : {
                    color: "green",
                  }
            }
          >
            {moment(
              notification && notification.createDate && notification.createDate
            ).format("DD/MM/YYYY HH:mm:ss")}
          </p>
        </div>
      </div>
    </div>
  );
};

export default NotificationItem;
