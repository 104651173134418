//volkan beylerin yazdığı ; taleplerim ,talepler detay,eşleşme sayfası  kodlarını sevmediğimden,çok fazla hata olduğundan ve geliştirilebilir olmadığından sildim

import React, { useState, useEffect } from "react";
import { Switch, Route } from "react-router-dom";
import "./index.css";
import Nav from "./layouts/nav";
import Footer from "./layouts/Footer";
import Home from "./components/Dashboard/Home/Home";
import Packets from "./components/Dashboard/packages/Packets";
import ScrollToTop from "./layouts/ScrollToTop";
import BuyPackets from "./components/Dashboard/packages/BuyPackets";
import ActivePackets from "./layouts/nav/ActivePackets";
import DenemeSayfasi from "./components/Dashboard/Application/DenemeSayfasi";
import "../css/bitoniscss/index.css";
import FindJobBlueSeeker from "./components/Dashboard/Application/FindJobSeeker/BlueJobSeeker/FindJobBlueSeeker";
import FindJobWhiteSeeker from "./components/Dashboard/Application/FindJobSeeker/WhiteJobSeeker/FindJobWhiteSeeker";
import DemandMatchShow from "./components/Dashboard/Application/Employer/Demand/MatchShow/DemandMatchShow";
import DemandAllShow from "./components/Dashboard/Application/Employer/Demand/DemandAllShow/DemandAllShow";
import DemandHire from "./components/Dashboard/Application/Employer/Demand/Hire/DemandHire";
import DemandNotHire from "./components/Dashboard/Application/Employer/Demand/NotHire/DemandNotHire";
import BlueDemandPreview from "./components/Dashboard/Application/Employer/Demand/DemandDetail/BlueDemandDetail/BlueDemandPreview";
import WhiteDemandPreview from "./components/Dashboard/Application/Employer/Demand/DemandDetail/WhiteDemandDetail/WhiteDemandPreview";
import NotificationPage from "./components/Dashboard/Application/Employer/Notifications/NotificationPage";
import BlueCandidateCv from "./components/Dashboard/Application/Employer/Demand/Cv/BlueCandidateCv/BlueCandidateCv";
import WhiteCandidateCv from "./components/Dashboard/Application/Employer/Demand/Cv/WhiteCandidateCv/WhiteCandidateCv";
import JoInVideoConferencePage from "./JoInVideoConferencePage.jsx";
import EmployerGroupCompanies from "./components/Dashboard/Application/Employer/Profil/employerGroupCompany/EmployerGroupCompanies";
import EmployerGroupLocations from "./components/Dashboard/Application/Employer/Profil/employerLocations/EmployerGroupLocations";
import RequestEmployerJobSeekerDocuments from "./components/Dashboard/Application/Employer/Profil/documents/RequestEmployerJobSeekerDocuments";
import CompanyServiceRoutePage from "./components/Dashboard/Application/Employer/Profil/routesServices/CompanyServiceRoutePage";
import CompanyWidthAddedRoutes from "./components/Dashboard/Application/Employer/Profil/routesServices/CompanyWidthAddedRoutes";
import Profile from "./components/Dashboard/Application/Employer/Profil/Profile";
import ChangeEmailPage from "./components/Dashboard/Application/Employer/Settings/ChangeEmailPage";
import ChangePasswordPage from "./components/Dashboard/Application/Employer/Settings/ChangePasswordPage";
import DemandBlock from "./components/Dashboard/Application/Employer/Demand/DemandBlock/DemandBlock.jsx";
import TestWidget from "./components/Dashboard/Home/TestWidget.jsx";
import SeeBlueCurrentDemandMatchShow from "./components/Dashboard/Application/Employer/Demand/DemandAllShow/CurrentDemandMatchShow/SeeBlueCurrentDemandMatchShow.jsx";
import SeeWhiteCurrentDemandMatchShow from "./components/Dashboard/Application/Employer/Demand/DemandAllShow/CurrentDemandMatchShow/SeeWhiteCurrentDemandMatchShow.jsx";
import EmployerDemandVideoCv from "./components/Dashboard/Application/Employer/Demand/MatchVideoCv/EmployerDemandVideoCv.jsx";
import EmployerDemandMatchOfficeInterView from "./components/Dashboard/Application/Employer/Demand/MatchOfficeInterView/EmployerDemandMatchOfficeInterView.jsx";
import EmployerDemandConferencesMeet from "./components/Dashboard/Application/Employer/Demand/Meetinglist/EmployerDemandConferencesMeet.jsx";
import FreezeAccount from "./components/Dashboard/Application/Employer/Settings/FreezeAccount.jsx";
import DeleteAccount from "./components/Dashboard/Application/Employer/Settings/DeleteAccount.jsx";
import EmployerUnSubscriptionPage from "./pages/EmployerUnSubscriptionPage.jsx";
import EmployerUnSubscriptionPageOnLoginIn from "./pages/EmployerUnSubscriptionPageOnLoginIn.jsx";
import SeeWhiteCurrentDemandMediumMatchShow from "./components/Dashboard/Application/Employer/Demand/DemandAllShow/CurrentDemandMatchShow/SeeWhiteCurrentDemandMediumMatchShow.jsx";
import WhiteMatchMediumDetail from "./components/Dashboard/Application/Employer/Demand/MatchDetail/whiteMatchDetail/WhiteMatchMediumDetail.jsx";
import FindJobIntern from "./components/Dashboard/Application/FindJobSeeker/InternJob/FindJobIntern.jsx";
import DemandInternAllShow from "./components/Dashboard/Application/Employer/Demand/DemandAllShow/DemandInternAllShow.jsx";
import InternDemandPreview from "./components/Dashboard/Application/Employer/Demand/DemandDetail/InternDemandDetail/InternDemandPreview.jsx";
import SeeInternCurrentDemandMatchShow from "./components/Dashboard/Application/Employer/Demand/DemandAllShow/CurrentDemandMatchShow/SeeInternCurrentDemandMatchShow.jsx";
import InternMatchDetail from "./components/Dashboard/Application/Employer/Demand/MatchDetail/Intern/InternMatchDetail.jsx";
import DiscountCoupons from "./components/Dashboard/packages/DiscountCoupons.jsx";
import URLWatcher from "./URLWatcher.jsx";
import ResponsiveBox from "./hooks/ResponsiveBox.jsx";
import BlueMatchDetail from "./components/Dashboard/Application/Employer/Demand/MatchDetail/blueMatchDetail/BlueMatchDetail.jsx";
import WhiteMatchDetail from "./components/Dashboard/Application/Employer/Demand/MatchDetail/whiteMatchDetail/WhiteMatchDetail.jsx";

const Markup = () => {
  let path = window.location.pathname;
  path = path.split("/");
  path = path[path.length - 1];
  let pagePath = path.split("-").includes("page");
  const [activeEvent, setActiveEvent] = useState(!path);

  const routes = [
    { url: "dashboard", component: Home },
    { url: "packets", component: Packets },
    { url: "findJobSeeker/blueJobSeeker", component: FindJobBlueSeeker },
    { url: "findJobSeeker/whiteJobSeeker", component: FindJobWhiteSeeker },
    { url: "findJobSeeker/internJob", component: FindJobIntern },
    { url: "profile", component: Profile },
    { url: "companyRoutes", component: CompanyWidthAddedRoutes },
    { url: "companyServiceRoutePage", component: CompanyServiceRoutePage },
    { url: "documents", component: RequestEmployerJobSeekerDocuments },
    { url: "companyLocations", component: EmployerGroupLocations },
    { url: "/", component: Home },
    // { url: "packetsBuy/:id", component: BuyPackets },
    { url: "packetsBuy", component: BuyPackets },
    { url: "activepackets", component: ActivePackets },
    { url: "discountCoupons", component: DiscountCoupons },
    { url: "deneme", component: DenemeSayfasi },
    { url: "demands/BlueDemandPreview/", component: BlueDemandPreview },
    { url: "demands/WhiteDemandPreview/", component: WhiteDemandPreview },
    { url: "demands/InternDemandPreview/", component: InternDemandPreview },
    { url: "matchShow", component: DemandMatchShow },
    { url: "matchShow/blueMatchDetail/", component: BlueMatchDetail },
    { url: "matchShow/whiteMatchDetail/", component: WhiteMatchDetail },
    { url: "matchShow/internMatchDetail/", component: InternMatchDetail },
    {
      url: "matchShow/whiteMatchMediumDetail/",
      component: WhiteMatchMediumDetail,
    },
    { url: "demands/allShow", component: DemandAllShow },
    { url: "demands/internAllShow", component: DemandInternAllShow },
    { url: "matchVideoRequest", component: EmployerDemandVideoCv },
    {
      url: "matchInterviewMeets",
      component: EmployerDemandMatchOfficeInterView,
    },
   
    {
      url: "demands/blueCurrentDemandMatchShow",
      component: SeeBlueCurrentDemandMatchShow,
    },
    {
      url: "demands/whiteCurrentDemandMatchShow",
      component: SeeWhiteCurrentDemandMatchShow,
    },
    {
      url: "demands/whiteCurrentDemandMediumMatchShow",
      component: SeeWhiteCurrentDemandMediumMatchShow,
    },
    {
      url: "demands/internCurrentDemandMatchShow",
      component: SeeInternCurrentDemandMatchShow,
    },
    {
      url: "matchVideoConferencesMeets",
      component: EmployerDemandConferencesMeet,
    },

    { url: "hire/", component: DemandHire },
    { url: "notHire/", component: DemandNotHire },
    { url: "demandBlock/", component: DemandBlock },
    { url: "notification/", component: NotificationPage },
    { url: "blueCvDetail/", component: BlueCandidateCv },
    { url: "whiteCvDetail/", component: WhiteCandidateCv },
    { url: "changeEmail/", component: ChangeEmailPage },
    { url: "changePassword/", component: ChangePasswordPage },
    { url: "freezeAccount/", component: FreezeAccount },
    { url: "deleteAccount/", component: DeleteAccount },
    { url: "videoConference/", component: JoInVideoConferencePage },
    { url: "profile/groupCompanies/", component: EmployerGroupCompanies },
    { url: "responsiveBox/", component: ResponsiveBox },
    { url: "test/", component: TestWidget },
    {
      url: "unsubscription/",
      component: EmployerUnSubscriptionPageOnLoginIn,
    },
  ];

  return (
    <>
      <div
        id={`${!pagePath ? "main-wrapper" : ""}`}
        className={`${!pagePath ? "show" : "mh100vh"}`}
      >
        {!pagePath && (
          <Nav
            onClick={() => setActiveEvent(!activeEvent)}
            activeEvent={activeEvent}
            onClick2={() => setActiveEvent(false)}
            onClick3={() => setActiveEvent(true)}
            logout={() => setActiveEvent(true)}
          />
        )}
        <div
          className={` ${!path && activeEvent ? "rightside-event" : ""} ${
            !pagePath ? "content-body" : ""
          }`}
        >
          <div
            className={`${!pagePath ? "container-fluid" : ""}`}
            style={{ minHeight: window.screen.height - 60 }}
          >
            <Switch>
              {routes.map((data, i) => (
                <Route
                  key={i}
                  exact
                  path={`/${data.url}`}
                  component={data.component}
                />
              ))}
            </Switch>
          </div>
        </div>
        {!pagePath && <Footer />}
      </div>
      <ScrollToTop />
    </>
  );
};

export default Markup;
