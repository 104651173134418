import React from "react";
import CustomPreviewNoTitle from "../../../../../../../widgets/CustomPreviewNoTitle";

const InternWantToLocation = ({
  cardStyle,
  labelStyle,
  inputStyle,
  internMatchDetail,
}) => {
  return (
    <div>
      <div class="" style={cardStyle}>
        <div
          class="card-header"
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
            borderBottom: "none",
            marginBottom: "0px",
            paddingBottom: "0px",
            marginTop: "0px",
          }}
        >
          <h5 class="card-title">Staj Aradığım Lokasyonlar</h5>
        </div>
        <div class="card-body">
          {internMatchDetail &&
					  internMatchDetail.matchCandidateLocations.map((location) => {
              return (
                <CustomPreviewNoTitle
                  name={""}
                  value={location.countryName + "/" + location.cityName}
                />
              );
            })}
        </div>
      </div>
    </div>
  );
};

export default InternWantToLocation;
