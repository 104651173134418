import React, { useEffect, useRef, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";
import Select from "react-select";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import api from "../../../../../../../utility/Utils";
import { DropListServices } from "../../../../../../../services";
import { customStyles } from "../../../../../../customStyleInline";
import { TaxInfoAction } from "../../../../../../../action";
import apiSecure from "../../../../../../../utility/Utils";
import BitonisSingleDropdown from "../../../../../../widgets/BitonisSingleDropdown";
import CustomDesign from "../../../../../../widgets/CustomDesign";
import { API_URL } from "../../../../../../../services/ApiUrlServices";
import profileImg from "../../../../../../../images/avatar/company.png";
import axios from "axios";
import { getToken } from "../../../../../../../configs/cookie";
import useReRender from "../../../../../../hooks/useReRender";
import CustomButton from "../../../../../../widgets/buttons/CustomButton";
import { Box } from "@mui/material";
import LoadingDotsAnimation from "../../../../../../widgets/LoadingDotsAnimation";

const EmployerGroupCompanyDetail = ({
  show,
  handleClose,
  id,
  company,
  props,
}) => {
  const [companyDetail, setCompanyDetail] = useState(null);
  const [sectorList, setSectorList] = useState([]);

  const {
    register,
    handleSubmit,
    control,
    watch,
    reset,
    setValue,
    getValues,
    formState: { errors },
  } = useForm();
  function fetchCompanyDetail(id) {
    //Şirket grup şirketleri detayı
    return api
      .get("/company/group/" + id)
      .then((response) => {
        return Promise.resolve(response);
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  }
  const [image, setImage] = useState(null);
  const [phoneNumber, setPhoneNumber] = useState(null);
  const [companyId, setCompanyId] = useState(null);
  const [loading, setLoading] = useState(true);
  const [selectFile, setSelectFile] = useState(null);
  const [file, setFile] = useState(null);
  const [notFount, setNotFount] = useState(false);
  useEffect(() => {
    if (show) {
      initFetchShowModal();
    }
  }, [show]);

  function initFetchShowModal() {
    setLoading(true);
    setNotFount(false);
    setImage(null);
    setValue("taxCity", "");
    setValue("brandCompanyName", "");
    setValue("sector", "");
    setValue("taxOfficeId", "");
    setValue("companyName", "");
    setSectorList([]);
    setCompanyDetail(null)
    fetchCompanyDetail(id).then(
      (getResponse) => {
        if (getResponse) {
          if (getResponse.succeeded == true) {
            var getResponseData = getResponse.data && getResponse.data;

            if (getResponseData) {
              setCompanyDetail(getResponseData);
              setValue("brandCompanyName", getResponseData.alias);
              setValue("companyName", getResponseData.name);
              var id = getResponseData.id;
              setCompanyId(id);
              setPhoneNumber(getResponseData.phoneNumber);
              var profileImageUrl = getResponseData.profileImageUrl;
              const url = `${API_URL}/images/employer/profile/${id}/${profileImageUrl}`;
              setLoading(false);

              if (
                profileImageUrl === undefined ||
                profileImageUrl === null ||
                profileImageUrl === ""
              ) {
                setNotFount(true);

                setImage(profileImg);
              } else {
                setNotFount(false);
                setImage(url);
              }

              setValue("taxNumber", getResponseData.taxNumber);
              setValue("taxCity", {
                label: getResponseData.taxOffice.cityName,
                value: getResponseData.taxOffice.cityId,
                code: getResponseData.taxOffice.cityCode,
              });
              setValue("sector", {
                label: getResponseData.sectors.name,
                value: getResponseData.sectors.id,
              });
              setValue("taxOfficeId", {
                label: getResponseData.taxOffice.name,
                value: getResponseData.taxOffice.id,
              });
              props.dispatch(
                TaxInfoAction.taxOffices(getResponseData.taxOffice.cityCode)
              );

              DropListServices.sectorList({}).then(
                (sectorListt) => {
                  if (sectorListt) {
                    //  alert("sectorListt* * " + JSON.stringify(sectorListt));
                    sectorListt.map((value) => {
                      setSectorList((prev) => [...prev, value]);
                    });
                  }
                },
                (error) => { }
              );
            } else {
              setCompanyDetail([]);
            }
          } else {
            setCompanyDetail(null)
            handleClose();
          }
        }
      },
      (error) => {
        setCompanyDetail(null)
        handleClose();
      }
    );
  }

  const handleTaxCounty = (e) => {
    const selectedValue = e;

    const selectedCity = props.cityList.cityList.find(
      (item) => item.code.toString() === selectedValue.code.toString()
    );
    const cityCode = selectedCity ? selectedCity.code : null;
    // setCityId(cityCode);
    props.dispatch(TaxInfoAction.taxOffices(cityCode));
  };
  function editGroupCompanyDetail(formData) {
    const sendData = {
      id: companyId,
      name: formData.companyName,
      alias:
        formData.brandCompanyName == "" ||
          formData.brandCompanyName == undefined ||
          formData.brandCompanyName == null
          ? formData.companyName
          : formData.brandCompanyName,
      taxNumber: formData.taxNumber.toString(),
      taxOfficeId: formData.taxOfficeId.value,
      sectorId: formData.sector.value,
      phoneNumber: phoneNumber,
    };
    updateCompany(sendData).then(
      (updateCompanyData) => {
        if (updateCompanyData.succeeded === true) {
          handleClose(false);
          toast.success("Güncelleme başarılı şekilde gerçekleşti", {
            autoClose: 2000,
            onClose: () => {
              setTimeout(() => {
                window.location.href = window.location.href;
              }, 100);
            },
          });
        } else {
          toast.info(updateCompanyData.error.message, {
            autoClose: 2000,
            onClose: () => {
              setTimeout(() => {
                window.location.href = window.location.href;
              }, 100);
            },
          });
        }
      },
      (error) => {
        toast.info("Hata Oluştu", {
          autoClose: 2000,
          onClose: () => {
            setTimeout(() => {
              window.location.href = window.location.href;
            }, 100);
          },
        });
      }
    );
  }
  function updateCompany(arg) {
    //grup şirketi güncelle
    return api
      .put("/company/group", arg)
      .then((response) => {
        return Promise.resolve(response);
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  }

  const handleLoad = () => {
    setLoading(false);
  };

  const handleFileChange = async (e) => {
    setSelectFile(e);
    if (e.target.files) {
      setLoading(true);
      setFile(e.target.files[0]);
      if (e.target.files[0]) {
        const formData = new FormData();
        formData.append("file", e.target.files[0]);
        formData.append("companyId", companyId);
        var token = getToken();
        try {
          const result = await fetch(API_URL + "/employer/profile-image", {
            method: "POST",
            body: formData,
            headers: { Authorization: `Bearer ${token}` },
          });

          const data = await result.json();
          setLoading(false);

          if (data.succeeded === true) {
            toast.success(data.result.message);
            setFile(null);
            var profileImageUrl = data.data;
            var id = companyId;
            const url = `${API_URL}/images/employer/profile/${id}/${profileImageUrl}`;

            setImage(url);
            setLoading(false);
            setNotFount(false);
          } else {
            toast.success(data.error.message);
          }

          console.log(data);
        } catch (error) {
          console.error(error);
        }
      }
      setLoading(false);
    }
  };

  const handleUpload = async () => {
    // We will fill this out later
  };
  const reRender = useReRender();
  return (
    <div>
      {companyDetail === null && show == true ?
        <Box sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          alignContent: "center",
          alignItems: "center"

        }} >
          <LoadingDotsAnimation />
        </Box> : <div>
          <Modal
            className="fade bd-example-modal-lg"
            size="lg"
            show={show}
            onHide={handleClose}
            backdrop="static"
            keyboard={false}
            aria-labelledby="contained-modal-title-vcenter"
            centered
          >
            <Modal.Header
              closeButton
              style={{
                borderBottom: "none",
                justifyContent: "center",
                marginBottom: "0px",
                paddingBottom: "0px",
              }}
            >
              <Modal.Title
                aria-labelledby="contained-modal-title-vcenter"
                centered
              >
                <h4 style={{ color: "#001542", fontWeight: "600" }}>
                  {" "}
                  Grup Şirketi Bilgilerimi Güncelle
                </h4>
              </Modal.Title>
            </Modal.Header>
            <Modal.Body
              className="grid-example"
              style={{
                marginTop: "0px",
                paddingTop: "0px",
                marginBottom: "5px",
                paddingBottom: "0px"
              }}
            >
              <div className="container">
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                    alignContent: "center",
                  }}
                >
                  <div
                    style={{
                      position: "relative",
                      width: "150px",
                      marginBottom: "0px",
                    }}
                  >
                    <div
                      style={{
                        border: "5px solid #001542",
                        borderRadius: "50%",
                        width: "100px",
                        height: "100px",
                        display: "flex",
                        justifyContent: "center",
                        alignContent: "center",
                        flexDirection: "column",
                        position: "relative",
                      }}
                    >
                      {loading && (
                        <i
                          class="fa-solid fa-spinner"
                          style={{
                            position: "absolute",

                            left: "30%",
                            transform: "translate(-50%, -50%)",
                            fontSize: "24px",
                            animation: "spin 1s linear infinite",
                            display: "flex",
                            justifyContent: "center",
                            alignContent: "center",
                            flexDirection: "column",
                          }}
                        ></i>
                      )}
                      <img
                        src={notFount ? profileImg : image}
                        onLoad={handleLoad}
                        alt="Display"
                        onError={(val) => {
                          setNotFount(true);
                        }}
                        style={{
                          display: loading ? "none" : "block",
                          width: notFount ? "60px" : "100%",
                          height: notFount ? "60px" : "100%",
                          borderRadius: "50%",
                          padding: "1px",
                          margin: notFount ? "10px" : "0px",
                        }}
                      />
                    </div>
                    <label
                      htmlFor="file"
                      style={{
                        cursor: "pointer",
                        position: "absolute",
                        top: "50%",
                        right: "30%",
                      }}
                    >
                      {" "}
                      <div
                        style={{
                          width: "30px",
                          height: "30px",
                          borderRadius: "50%",
                          backgroundColor: "white",
                          border: "1.5px solid #001542",
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "center",
                          alignContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <i
                          class="fa-solid fa-pencil"
                          style={{
                            color: "#001542",
                          }}
                        ></i>
                      </div>
                      <input
                        input
                        id="file"
                        type="file"
                        onChange={handleFileChange}
                        style={{
                          display: "none",
                        }}
                      />
                    </label>
                  </div>
                </div>
                <form
                  onSubmit={handleSubmit(editGroupCompanyDetail)}
                  style={{
                    marginTop: "0px",
                  }}
                >
                  <div className="row">
                    <CustomDesign
                      label={"Vergi No"}
                      required={true}
                      isColumn={true}
                      columnClassName={"col-xs-6 col-md-6"}
                      child={
                        <input
                          type="text"
                          name="taxNumber"
                          style={{
                            borderRadius: "8px",
                            height: "55px",
                            backgroundColor: "#fcfcfc",
                            border: "1px solid #dcdcdd",
                            margin: "0px",
                            fontWeight: 500,
                            fontSize: "14px",
                          }}
                          id="inputClassActive"
                          maxLength={11}
                          disabled={true}
                          {...register("taxNumber", { required: true })}
                          className="form-control"
                        />
                      }
                    />
                    <CustomDesign
                      label={"Firma Adı"}
                      required={true}
                      isColumn={true}
                      columnClassName={"col-xs-6 col-md-6"}
                      child={
                        <input
                          type="text"
                          style={{
                            borderRadius: "8px",
                            height: "55px",
                            backgroundColor: "#fcfcfc",
                            border: "1px solid #dcdcdd",
                            margin: "0px",
                            fontWeight: 500,
                            fontSize: "14px",
                          }}
                          className="form-control"
                          id="inputClassActive"
                          name="companyName"
                          {...register("companyName", { required: false })}
                          placeholder="Firma Adı"
                        ></input>
                      }
                    />
                    <CustomDesign
                      label={"Firma Marka Adı"}
                      required={true}
                      isColumn={true}
                      columnClassName={"col-xs-6 col-md-6"}
                      child={
                        <input
                          type="text"
                          style={{
                            borderRadius: "8px",
                            height: "55px",
                            backgroundColor: "#fcfcfc",
                            border: "1px solid #dcdcdd",
                            margin: "0px",
                            fontWeight: 500,
                            fontSize: "14px",
                          }}
                          className="form-control"
                          id="inputClassActive"
                          name="brandCompanyName"
                          {...register("brandCompanyName", { required: false })}
                          placeholder="Firma Marka Adı"
                        ></input>
                      }
                    />

                    <CustomDesign
                      label={"Sektör"}
                      required={true}
                      isColumn={true}
                      columnClassName={"col-xs-6 col-md-6"}
                      child={
                        <BitonisSingleDropdown
                          items={
                            sectorList &&
                            sectorList.map(({ id, name }) => ({
                              value: id,
                              label: name,
                            }))
                          }
                          register={register}
                          watch={watch}
                          companyClassName={"sector"}
                          control={control}
                          setValue={setValue}
                          errors={errors}
                          placeholder="Sektör Seç"
                          onValueChanged={(e) => {
                            console.log(e);
                            if (e) {
                            }
                          }}
                          required={true}
                          getValues={getValues}
                          errorMessage={"Sektör Boş Geçilemez"}
                        />
                      }
                    />
                    <CustomDesign
                      label={"Vergi Dairesi İli"}
                      required={true}
                      isColumn={true}
                      columnClassName={"col-xs-6 col-md-6"}
                      child={
                        <BitonisSingleDropdown
                          items={
                            props.cityList.cityList &&
                            props.cityList.cityList.map(({ id, name, code }) => ({
                              value: id,
                              label: name,
                              code: code,
                            }))
                          }
                          register={register}
                          watch={watch}
                          companyClassName={"taxCity"}
                          control={control}
                          setValue={setValue}
                          errors={errors}
                          placeholder="Vergi Dairesi İli Seç"
                          onValueChanged={(e) => {
                            console.log(e);
                            if (e) {
                              setValue("taxOfficeId", "");
                              handleTaxCounty(e);
                            }
                          }}
                          required={true}
                          getValues={getValues}
                          errorMessage={"Vergi Dairesi İli Boş Olamaz"}
                        />
                      }
                    />

                    <CustomDesign
                      label={"Vergi Dairesi"}
                      required={true}
                      isColumn={true}
                      columnClassName={"col-xs-6 col-md-6"}
                      child={
                        <BitonisSingleDropdown
                          items={
                            props.taxOffices.taxOffices &&
                            props.taxOffices.taxOffices.pagedData.map(
                              ({ id, name }) => ({
                                value: id,
                                label: name,
                              })
                            )
                          }
                          register={register}
                          watch={watch}
                          companyClassName={"taxOfficeId"}
                          control={control}
                          setValue={setValue}
                          errors={errors}
                          placeholder="Vergi Dairesi Seç"
                          onValueChanged={(e) => {
                            console.log(e);
                            if (e) {
                            }
                          }}
                          required={true}
                          getValues={getValues}
                          errorMessage={"Vergi Dairesi Boş Olamaz"}
                        />
                      }
                    />
                  </div>
                </form>
              </div>
            </Modal.Body>{" "}
            <Modal.Footer
              style={{
                display: "flex",
                justifyContent: "center",
                borderTop: "none",
              }}
            >
              <form
                onSubmit={handleSubmit(editGroupCompanyDetail)}
                style={{
                  marginTop: "0px",
                  paddingTop: "0px",
                }}
              >
                <CustomButton
                  title={"Kaydet"}
                  width={{
                    xs: "380px",
                    sm: "380px",
                    md: "400px",
                    lg: "400px",
                    xl: "400px",
                  }}
                />
              </form>
            </Modal.Footer>
          </Modal>
        </div>}
    </div>
  );
};

export default EmployerGroupCompanyDetail;
