import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  packetDetail: null,
};

const packetSlice = createSlice({
  name: "selectPacket",
  initialState,
  reducers: {
    setSelectPacketData: (state, action) => {
      state.packetDetail = action.payload;
    },
  },
});

export const { setSelectPacketData } = packetSlice.actions;
export default packetSlice.reducer;
