export const GivenStarTypeEnum = {
  None: "None",
  FirstProfile: "FirstProfile",
  ByEmployerAfterVideoConf: "ByEmployerAfterVideoConf",
  ByEmployerAfterInterview: "ByEmployerAfterInterview",
  ByEmployerAfterVideoCV: "ByEmployerAfterVideoCV",
  ApproveMeeting: "ApproveMeeting",
  UploadVideoCV: "UploadVideoCV",
};
export var gamificationGivenStarReasonList = [
  {"type": "None", "id": 0},
  {"type": "FirstProfile", "id": 1},
  {"type": "ByEmployerAfterVideoConf", "id": 2},
  {"type": "ByEmployerAfterInterview", "id": 3},
  {"type": "ByEmployerAfterVideoCV", "id": 4},
  {"type": "ApproveMeeting", "id": 5},
  {"type": "UploadVideoCV", "id": 6},
];
