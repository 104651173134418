import {
  YearOfExperienceWhiteContants,
  PositionListWhiteContants,
  WayOfWorkingWhiteContants,
  NationalityListWhiteContants,
  AgeRangeWhiteContants,
  DriverLicenseTypeWhiteContants,
  ProgramListWhiteContants,
  CertificatesListWhiteContants,
  CompetencesListWhiteContants,
  CountryListWhiteContants,
  CityListWhiteContants,
  CountyListWhiteContants,
  DistrictListWhiteContants,
  CompanyListWhiteConstants,
  SalaryListWhiteContants,
  BusinessTravelListWhiteContants,
  LanguageListWhiteContants,
  LanguageLevelListWhiteContants,
  UniversityListWhiteContants,
  FacultyListWhiteContants,
  FacultyDepartmantListWhiteContants,
  JobConstants,
} from "../constants";
import { DemandsServices, DropListWhiteServices } from "../services";
import { toast } from "react-toastify";

export const DropListWhiteAction = {
  positionListWhite,
  yearOfExperienceWhite,
  wayOfWorkingWhite,
  nationalityListWhite,
  ageRangeListWhite,
  driverLicenceTypeWhite,
  programListWhite,
  certificatesListWhite,
  competencesListWhite,
  countryListWhite,
  cityListWhite,
  countyListWhite,
  districtListWhite,
  companyListWhite,
  salaryListWhite,
  businessTravelListWhite,
  languageListWhite,
  languageLevelListWhite,
  universityListWhite,
  facultyListWhite,
  facultyDepartmentListWhite,
  whiteNewDemand,
  whiteUpdateDemand,
};

// Beyaz Yaka Bilgileri

function companyListWhite(arg) {
  //Şirket Bilgileri
  return (dispatch) => {
    dispatch(request());
    DropListWhiteServices.companyListWhite(arg).then(
      (companyListWhite) => {
        dispatch(success(companyListWhite));
      },
      (error) => {
        dispatch(failure(error));
      }
    );
  };

  function request() {
    return {
      type: CompanyListWhiteConstants.GET_ALL_REQUEST,
    };
  }

  function success(companyListWhite) {
    return {
      type: CompanyListWhiteConstants.GET_ALL_SUCCESS,
      companyListWhite,
    };
  }

  function failure(error) {
    return {
      type: CompanyListWhiteConstants.GET_ALL_FAILURE,
      error,
    };
  }
}
function positionListWhite(arg) {
  // Pozisyon listesi
  return (dispatch) => {
    dispatch(request());
    DropListWhiteServices.positionListWhite(arg).then(
      (positionListWhite) => {
        dispatch(success(positionListWhite));
      },
      (error) => {
        dispatch(failure(error));
      }
    );
  };

  function request() {
    return { type: PositionListWhiteContants.GET_ALL_REQUEST };
  }

  function success(positionListWhite) {
    return {
      type: PositionListWhiteContants.GET_ALL_SUCCESS,
      positionListWhite,
    };
  }

  function failure(error) {
    return { type: PositionListWhiteContants.GET_ALL_FAILURE, error };
  }
}
function yearOfExperienceWhite(arg) {
  // Tecrübe Yılı listesi
  return (dispatch) => {
    dispatch(request());
    DropListWhiteServices.yearOfExperienceWhite(arg).then(
      (yearOfExperienceWhite) => {
        dispatch(success(yearOfExperienceWhite));
      },
      (error) => {
        dispatch(failure(error));
      }
    );
  };

  function request() {
    return { type: YearOfExperienceWhiteContants.GET_ALL_REQUEST };
  }

  function success(yearOfExperienceWhite) {
    return {
      type: YearOfExperienceWhiteContants.GET_ALL_SUCCESS,
      yearOfExperienceWhite,
    };
  }

  function failure(error) {
    return { type: YearOfExperienceWhiteContants.GET_ALL_FAILURE, error };
  }
}
function wayOfWorkingWhite(arg) {
  // Çalışma Şekli
  return (dispatch) => {
    dispatch(request());
    DropListWhiteServices.wayOfWorkingWhite(arg).then(
      (wayOfWorkingWhite) => {
        dispatch(success(wayOfWorkingWhite));
      },
      (error) => {
        dispatch(failure(error));
      }
    );
  };

  function request() {
    return { type: WayOfWorkingWhiteContants.GET_ALL_REQUEST };
  }

  function success(wayOfWorkingWhite) {
    return {
      type: WayOfWorkingWhiteContants.GET_ALL_SUCCESS,
      wayOfWorkingWhite,
    };
  }

  function failure(error) {
    return { type: WayOfWorkingWhiteContants.GET_ALL_FAILURE, error };
  }
}
function nationalityListWhite(arg) {
  // Uyruk
  return (dispatch) => {
    dispatch(request());
    DropListWhiteServices.nationalityListWhite(arg).then(
      (nationalityListWhite) => {
        dispatch(success(nationalityListWhite));
      },
      (error) => {
        dispatch(failure(error));
      }
    );
  };

  function request() {
    return { type: NationalityListWhiteContants.GET_ALL_REQUEST };
  }

  function success(nationalityListWhite) {
    return {
      type: NationalityListWhiteContants.GET_ALL_SUCCESS,
      nationalityListWhite,
    };
  }

  function failure(error) {
    return { type: NationalityListWhiteContants.GET_ALL_FAILURE, error };
  }
}
function ageRangeListWhite(arg) {
  // Yaş Aralığı Listesi
  return (dispatch) => {
    dispatch(request());
    DropListWhiteServices.ageRangeListWhite(arg).then(
      (ageRangeListWhite) => {
        dispatch(success(ageRangeListWhite));
      },
      (error) => {
        dispatch(failure(error));
      }
    );
  };

  function request() {
    return { type: AgeRangeWhiteContants.GET_ALL_REQUEST };
  }

  function success(ageRangeListWhite) {
    return { type: AgeRangeWhiteContants.GET_ALL_SUCCESS, ageRangeListWhite };
  }

  function failure(error) {
    return { type: AgeRangeWhiteContants.GET_ALL_FAILURE, error };
  }
}
function driverLicenceTypeWhite(arg) {
  // Sürücü Belgesi Türü
  return (dispatch) => {
    dispatch(request());
    DropListWhiteServices.driverLicenceTypeWhite(arg).then(
      (driverLicenceTypeWhite) => {
        dispatch(success(driverLicenceTypeWhite));
      },
      (error) => {
        dispatch(failure(error));
      }
    );
  };

  function request() {
    return { type: DriverLicenseTypeWhiteContants.GET_ALL_REQUEST };
  }

  function success(driverLicenceTypeWhite) {
    return {
      type: DriverLicenseTypeWhiteContants.GET_ALL_SUCCESS,
      driverLicenceTypeWhite,
    };
  }

  function failure(error) {
    return { type: DriverLicenseTypeWhiteContants.GET_ALL_FAILURE, error };
  }
}
function programListWhite(arg) {
  // Program Listesi
  return (dispatch) => {
    dispatch(request());
    DropListWhiteServices.programListWhite(arg).then(
      (programListWhite) => {
        dispatch(success(programListWhite));
      },
      (error) => {
        dispatch(failure(error));
      }
    );
  };

  function request() {
    return { type: ProgramListWhiteContants.GET_ALL_REQUEST };
  }

  function success(programListWhite) {
    return { type: ProgramListWhiteContants.GET_ALL_SUCCESS, programListWhite };
  }

  function failure(error) {
    return { type: ProgramListWhiteContants.GET_ALL_FAILURE, error };
  }
}
function certificatesListWhite(arg) {
  // Sertifika Listesi
  return (dispatch) => {
    dispatch(request());
    DropListWhiteServices.certificatesListWhite(arg).then(
      (certificatesListWhite) => {
        dispatch(success(certificatesListWhite));
      },
      (error) => {
        dispatch(failure(error));
      }
    );
  };

  function request() {
    return { type: CertificatesListWhiteContants.GET_ALL_REQUEST };
  }

  function success(certificatesListWhite) {
    return {
      type: CertificatesListWhiteContants.GET_ALL_SUCCESS,
      certificatesListWhite,
    };
  }

  function failure(error) {
    return { type: CertificatesListWhiteContants.GET_ALL_FAILURE, error };
  }
}
function competencesListWhite(arg) {
  // Yetkinlikler Listesi
  return (dispatch) => {
    dispatch(request());
    DropListWhiteServices.competencesListWhite(arg).then(
      (competencesListWhite) => {
        dispatch(success(competencesListWhite));
      },
      (error) => {
        dispatch(failure(error));
      }
    );
  };

  function request() {
    return { type: CompetencesListWhiteContants.GET_ALL_REQUEST };
  }

  function success(competencesListWhite) {
    return {
      type: CompetencesListWhiteContants.GET_ALL_SUCCESS,
      competencesListWhite,
    };
  }

  function failure(error) {
    return { type: CompetencesListWhiteContants.GET_ALL_FAILURE, error };
  }
}
function countryListWhite(arg) {
  // Ülke Listesi
  return (dispatch) => {
    dispatch(request());
    DropListWhiteServices.countryListWhite(arg).then(
      (countryListWhite) => {
        dispatch(success(countryListWhite));
      },
      (error) => {
        dispatch(failure(error));
      }
    );
  };

  function request() {
    return { type: CountryListWhiteContants.GET_ALL_REQUEST };
  }

  function success(countryListWhite) {
    return { type: CountryListWhiteContants.GET_ALL_SUCCESS, countryListWhite };
  }

  function failure(error) {
    return { type: CountryListWhiteContants.GET_ALL_FAILURE, error };
  }
}
function cityListWhite(arg) {
  // Ülke Listesi
  return (dispatch) => {
    dispatch(request());
    DropListWhiteServices.cityListWhite(arg).then(
      (cityListWhite) => {
        dispatch(success(cityListWhite));
      },
      (error) => {
        dispatch(failure(error));
      }
    );
  };

  function request() {
    return { type: CityListWhiteContants.GET_ALL_REQUEST };
  }

  function success(cityListWhite) {
    return { type: CityListWhiteContants.GET_ALL_SUCCESS, cityListWhite };
  }

  function failure(error) {
    return { type: CityListWhiteContants.GET_ALL_FAILURE, error };
  }
}
function countyListWhite(arg) {
  // İlçe Listesi
  return (dispatch) => {
    dispatch(request());
    DropListWhiteServices.countyListWhite(arg).then(
      (countyListWhite) => {
        dispatch(success(countyListWhite));
      },
      (error) => {
        dispatch(failure(error));
      }
    );
  };

  function request() {
    return { type: CountyListWhiteContants.GET_ALL_REQUEST };
  }

  function success(countyListWhite) {
    return { type: CountyListWhiteContants.GET_ALL_SUCCESS, countyListWhite };
  }

  function failure(error) {
    return { type: CountyListWhiteContants.GET_ALL_FAILURE, error };
  }
}
function districtListWhite(arg) {
  // Semt Listesi
  return (dispatch) => {
    dispatch(request());
    DropListWhiteServices.districtListWhite(arg).then(
      (districtListWhite) => {
        dispatch(success(districtListWhite));
      },
      (error) => {
        dispatch(failure(error));
      }
    );
  };

  function request() {
    return { type: DistrictListWhiteContants.GET_ALL_REQUEST };
  }

  function success(districtListWhite) {
    return {
      type: DistrictListWhiteContants.GET_ALL_SUCCESS,
      districtListWhite,
    };
  }

  function failure(error) {
    return { type: DistrictListWhiteContants.GET_ALL_FAILURE, error };
  }
}
function salaryListWhite(arg) {
  // Maaş Listesi
  return (dispatch) => {
    dispatch(request());
    DropListWhiteServices.salaryListWhite(arg).then(
      (salaryListWhite) => {
        dispatch(success(salaryListWhite));
      },
      (error) => {
        dispatch(failure(error));
      }
    );
  };

  function request() {
    return { type: SalaryListWhiteContants.GET_ALL_REQUEST };
  }

  function success(salaryListWhite) {
    return { type: SalaryListWhiteContants.GET_ALL_SUCCESS, salaryListWhite };
  }

  function failure(error) {
    return { type: SalaryListWhiteContants.GET_ALL_FAILURE, error };
  }
}
function businessTravelListWhite(arg) {
  // İş Seyehat Listesi
  return (dispatch) => {
    dispatch(request());
    DropListWhiteServices.businessTravelListWhite(arg).then(
      (businessTravelListWhite) => {
        dispatch(success(businessTravelListWhite));
      },
      (error) => {
        dispatch(failure(error));
      }
    );
  };

  function request() {
    return { type: BusinessTravelListWhiteContants.GET_ALL_REQUEST };
  }

  function success(businessTravelListWhite) {
    return {
      type: BusinessTravelListWhiteContants.GET_ALL_SUCCESS,
      businessTravelListWhite,
    };
  }

  function failure(error) {
    return { type: BusinessTravelListWhiteContants.GET_ALL_FAILURE, error };
  }
}
function languageListWhite(arg) {
  // Yabancı Dil Listesi
  return (dispatch) => {
    dispatch(request());
    DropListWhiteServices.languageListWhite(arg).then(
      (languageListWhite) => {
        dispatch(success(languageListWhite));
      },
      (error) => {
        dispatch(failure(error));
      }
    );
  };

  function request() {
    return { type: LanguageListWhiteContants.GET_ALL_REQUEST };
  }

  function success(languageListWhite) {
    return {
      type: LanguageListWhiteContants.GET_ALL_SUCCESS,
      languageListWhite,
    };
  }

  function failure(error) {
    return { type: LanguageListWhiteContants.GET_ALL_FAILURE, error };
  }
}
function languageLevelListWhite(arg) {
  // Yabancı Dil Listesi
  return (dispatch) => {
    dispatch(request());
    DropListWhiteServices.languageLevelListWhite(arg).then(
      (languageLevelListWhite) => {
        dispatch(success(languageLevelListWhite));
      },
      (error) => {
        dispatch(failure(error));
      }
    );
  };

  function request() {
    return { type: LanguageLevelListWhiteContants.GET_ALL_REQUEST };
  }

  function success(languageLevelListWhite) {
    return {
      type: LanguageLevelListWhiteContants.GET_ALL_SUCCESS,
      languageLevelListWhite,
    };
  }

  function failure(error) {
    return { type: LanguageLevelListWhiteContants.GET_ALL_FAILURE, error };
  }
}
function universityListWhite(arg) {
  // Üniversite Listesi
  return (dispatch) => {
    dispatch(request());
    DropListWhiteServices.universityListWhite(arg).then(
      (universityListWhite) => {
        dispatch(success(universityListWhite));
      },
      (error) => {
        dispatch(failure(error));
      }
    );
  };

  function request() {
    return { type: UniversityListWhiteContants.GET_ALL_REQUEST };
  }

  function success(universityListWhite) {
    return {
      type: UniversityListWhiteContants.GET_ALL_SUCCESS,
      universityListWhite,
    };
  }

  function failure(error) {
    return { type: UniversityListWhiteContants.GET_ALL_FAILURE, error };
  }
}
function facultyListWhite(arg) {
  // Fakülte Listesi
  return (dispatch) => {
    dispatch(request());
    DropListWhiteServices.facultyListWhite(arg).then(
      (facultyListWhite) => {
        dispatch(success(facultyListWhite));
      },
      (error) => {
        dispatch(failure(error));
      }
    );
  };

  function request() {
    return { type: FacultyListWhiteContants.GET_ALL_REQUEST };
  }

  function success(facultyListWhite) {
    return { type: FacultyListWhiteContants.GET_ALL_SUCCESS, facultyListWhite };
  }

  function failure(error) {
    return { type: FacultyListWhiteContants.GET_ALL_FAILURE, error };
  }
}
function facultyDepartmentListWhite(arg) {
  // Bölüm Listesi
  return (dispatch) => {
    dispatch(request());
    DropListWhiteServices.facultyDepartmentListWhite(arg).then(
      (facultyDepartmentListWhite) => {
        dispatch(success(facultyDepartmentListWhite));
      },
      (error) => {
        dispatch(failure(error));
      }
    );
  };

  function request() {
    return { type: FacultyDepartmantListWhiteContants.GET_ALL_REQUEST };
  }

  function success(facultyDepartmentListWhite) {
    return {
      type: FacultyDepartmantListWhiteContants.GET_ALL_SUCCESS,
      facultyDepartmentListWhite,
    };
  }

  function failure(error) {
    return { type: FacultyDepartmantListWhiteContants.GET_ALL_FAILURE, error };
  }
}
function whiteNewDemand(arg, history, secondsRef) {
  // Beyaz Yaka Yeni Talep Post
  return (dispatch) => {
    dispatch(request());
    DropListWhiteServices.whiteNewDemand(arg).then(
      (whiteNewDemand) => {
        //  alert("whiteNewDemand** * * " + JSON.stringify(whiteNewDemand));
        if (whiteNewDemand.succeeded == false) {
          toast.error(whiteNewDemand.error.message, {
            autoClose: 2000,
            onClose: () => {
              //   history.push("/demands");
              setTimeout(() => {
                window.location.href = window.location.href;
              }, 100);
            },
          });
        } else {
          var sendData = {
            actionTypeId: 5,
            duration: secondsRef.current,
            actionRelatedId: null,
          };

          DemandsServices.currentUserActionPageLog(sendData).then(
            (pageLogRes) => {
              if (pageLogRes && pageLogRes.succeeded === true) {
              }
            },
            (error) => {}
          );
          toast.info("Beyaz Yaka Talebiniz Başarıyla Gerçekleşti", {
            autoClose: 2000,
            onClose: () => {
              history.push("/demands/allShow");
              setTimeout(() => {
                window.location.href = window.location.href;
              }, 100);
            },
          });
        }
      },
      (error) => {
        dispatch(failure(error));
      }
    );
  };

  function request() {
    return { type: JobConstants.GET_ALL_REQUEST };
  }

  function success(whiteNewDemand) {
    //console.log("whiteNewDemand", whiteNewDemand)
    return { type: JobConstants.GET_ALL_SUCCESS, whiteNewDemand };
  }

  function failure(error) {
    return { type: JobConstants.GET_ALL_FAILURE, error };
  }
}
function whiteUpdateDemand(arg) {
  // Beyaz Yaka Talep Güncelleme Post
  return (dispatch) => {
    dispatch(request());
    DropListWhiteServices.whiteUpdateDemand(arg).then(
      (whiteUpdateDemand) => {
        if (whiteUpdateDemand.succeeded === true) {
          toast.success(whiteUpdateDemand.result.message, {
            autoClose: 1000,
            onClose: () => {
              setTimeout(() => {
                window.location.href = window.location.href;
              }, 100);
            },
          });
        } else {
          toast.info(whiteUpdateDemand.error.message, {
            autoClose: 1000,
            onClose: () => {
              setTimeout(() => {
                window.location.href = window.location.href;
              }, 100);
            },
          });
        }
      },
      (error) => {
        dispatch(failure(error));
      }
    );
  };

  function request() {
    return { type: JobConstants.GET_ALL_REQUEST };
  }

  function success(whiteUpdateDemand) {
    return { type: JobConstants.GET_ALL_SUCCESS, whiteUpdateDemand };
  }

  function failure(error) {
    return { type: JobConstants.GET_ALL_FAILURE, error };
  }
}
function clearCompanyList() {
  return (dispatch) => {
    return dispatch({ type: CompanyListConstants.GET_CLEAR });
  };
}
