import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import CustomPreview from "../../../../../../../../widgets/CustomPreview";
import AnotherComponent from "../../../../../../../../widgets/AnotherComponent";
import DividerDotted from "../../../../../../../../widgets/DividerDotted";

const WorkExperiences = ({
  cardStyle,
  labelStyle,
  inputStyle,
  whiteMatchDetail,
}) => {
  return (
    <div
      style={{
        marginTop: "5px",
      }}
    >
      {whiteMatchDetail &&
      whiteMatchDetail.experiencesList &&
      whiteMatchDetail.experiencesList.length > 0 ? (
        <div
          className="card"
          style={{
            margin: "px 5% 0px 5%",
            padding: "0px",
            border: "0.5px solid #F7F7F7",
            backgroundColor: "F7F7F7",
          }}
        >
          <div
            className="card-header"
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
              borderBottom: "none",
              marginBottom: "0px",
              paddingBottom: "0px",
            }}
          >
            <h5
              className="card-title"
              style={{
                fontSize: "17px",
              }}
            >
              İş Deneyimleri
            </h5>
          </div>
          <div
            className="card-body"
            style={{
              marginTop: "0px",
              paddingTop: "10px",
            }}
          >
            {whiteMatchDetail &&
              whiteMatchDetail.experiencesList &&
              whiteMatchDetail.experiencesList.map((experience, index) => {
                {
                  return (
                    <div>
                      <CustomPreview
                        name={"Firma Adı"}
                        value={experience && experience.companyName}
                      />{" "}
                      <CustomPreview
                        name={"Pozisyon"}
                        value={experience && experience.positionName}
                      />
                      <div className="row">
                        <div className="col-md-12">
                          <AnotherComponent
                            startDate={experience && experience.startDate}
                            finishDate={experience && experience.finishDate}
                            present={experience && experience.present}
                            leavingSchool={""}
                          />
                        </div>
                      </div>
                      <CustomPreview
                        name={"Firma Sektörü"}
                        value={experience && experience.sectorName}
                      />{" "}
                      <CustomPreview
                        name={"Departman"}
                        value={experience && experience.departmentName}
                      />{" "}
                      <CustomPreview
                        name={"Çalışma Şekli"}
                        value={experience && experience.workTypeName}
                      />{" "}
                      {index !==
                        whiteMatchDetail.experiencesList.length - 1 && (
                        <hr style={{ borderTop: "1px dotted lightgrey" }}></hr>
                      )}
                    </div>
                  );
                }
              })}
          </div>
        </div>
      ) : (
        <div></div>
      )}
    </div>
  );
};

export default WorkExperiences;
