import React, { useEffect, useState } from "react";
import { DashboardServices } from "../../../../../../../services/DashboardServices";
import NotificationItem from "../Common/NotificationItem";
import LoadingDotsAnimation from "../../../../../../widgets/LoadingDotsAnimation";
import noNotificationFound from "../../../../../../../images/svg/NotificationNotFound.svg";
import NotificationNotFound from "../Common/NotificationNotFound";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { setCurrentTab } from "../../../../../../../redux/redux-toolkit/filterMatch";
import { useDispatch, useSelector } from "react-redux";
import Pagination from "@mui/material/Pagination";
import { setCurrentDemandVideoCvTab } from "../../../../../../../redux/redux-toolkit/demandVideoCvTab";
import { setDemandMatchOfficeInterViewTab } from "../../../../../../../redux/redux-toolkit/demandMatchOfficeInterView";
import { setDemandConferencesMeetTab } from "../../../../../../../redux/redux-toolkit/demandConferencesMeet";
import { setCurrentTabHire } from "../../../../../../../redux/redux-toolkit/selectedHireTab";
import { setCurrentTabNotHire } from "../../../../../../../redux/redux-toolkit/selectedNotHireTab";
import { setCurrentAllTab } from "../../../../../../../redux/redux-toolkit/filterAllShow";

const ReadNotification = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const [pageNumber, setPageNumber] = useState(1);
  const [ReadNotificationList, setReadNotificationList] = useState(null);
  const [paginationSize, setPaginationSize] = useState(5);

  useEffect(() => {
    getNotification();
  }, []);
  const handleChange = (event, value) => {
    setPageNumber(value);
  };
  useEffect(() => {
    getNotification();
  }, [pageNumber]);
  function getNotification() {
    setReadNotificationList(null);
    DashboardServices.getNotification(pageNumber, true).then((notification) => {
      if (notification && notification.succeeded === true) {
        var getData = notification.data.pagedData;
        if (getData) {
          setReadNotificationList([]);

          getData.map((val) => {
            setReadNotificationList((prev) => [...prev, val]);
          });
        } else {
          setReadNotificationList(null);
        }
      }
    });
  }

  const { notificationCount } = useSelector((state) => state.notification);
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  // Diğer state değişkenlerini de tanımlayın

  useEffect(() => {
    const handleScroll = () => {
      const { scrollTop, scrollHeight, clientHeight } =
        document.documentElement;
      if (!isLoadingMore && scrollTop + clientHeight >= scrollHeight) {
        setIsLoadingMore(true);
      } else {
        setIsLoadingMore(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [isLoadingMore]);

  useEffect(() => {
    if (isLoadingMore) {
      // Sayfa sonuna ulaşıldığında yeni verileri getir
      if (
        notificationCount === null ||
        notificationCount === 0 ||
        notificationCount === "" ||
        notificationCount === undefined
      ) {
        setPageNumber((prevPageSize) => prevPageSize + 1);
      }
      setIsLoadingMore(false);
    }
  }, [isLoadingMore, pageNumber, notificationCount]);

  if (ReadNotificationList === null) {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
        }}
      >
        <LoadingDotsAnimation />
      </div>
    );
  }
  return (
    <div
      style={{
        marginLeft: "10px",
        marginRight: "10px",
      }}
    >
      {ReadNotificationList && ReadNotificationList.length > 0 ? (
        ReadNotificationList.map((notification, index) => (
          <NotificationItem
            key={notification.eventNotificationId}
            notification={notification}
            isRead={false}
            onTap={() => {
              openDetailPage(notification, index);
            }}
          />
        ))
      ) : (
        <NotificationNotFound />
      )}
      <div
        className="card"
        style={{
          marginTop: "10px",
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          justifyItems: "center",
          alignContent: "center",
          alignItems: "center",
          marginLeft: "10px",
          marginRight: "10px",
          marginTop: "50px",
        }}
      >
        <Pagination
          count={paginationSize}
          variant="outlined"
          color="primary"
          page={pageNumber}
          onChange={handleChange}
        />
      </div>
    </div>
  );
  function openDetailPage(notification, index) {
    var status = notification.notificationType;
    var candidateId = notification.candidateId;
    var matchId = notification.matchId;
    if (status === 11) {
      dispatch(setDemandConferencesMeetTab(1));
      history.push("/matchVideoConferencesMeets");
    } else if (status === 19) {
      dispatch(setDemandConferencesMeetTab(0));
      history.push("/matchVideoConferencesMeets");
    } else if (status === 11) {
      dispatch(setDemandConferencesMeetTab(1));
      history.push("/matchVideoConferencesMeets");
    } else if (status === 15) {
      dispatch(setDemandConferencesMeetTab(0));
      history.push("/matchVideoConferencesMeets");
    } else if (status === 20) {
      dispatch(setDemandConferencesMeetTab(0));
      history.push("/matchVideoConferencesMeets");
    } else if (status === 22) {
      dispatch(setDemandConferencesMeetTab(1));
      history.push("/matchVideoConferencesMeets");
    } else if (status === 12) {
      dispatch(setDemandMatchOfficeInterViewTab(1));
      history.push("/matchInterviewMeets");
    } else if (status === 16) {
      dispatch(setDemandMatchOfficeInterViewTab(0));
      history.push("/matchInterviewMeets");
    } else if (status === 3) {
      dispatch(setDemandMatchOfficeInterViewTab(0));
      history.push("/matchInterviewMeets");
    } else if (status === 21) {
      dispatch(setDemandMatchOfficeInterViewTab(0));
      history.push("/matchInterviewMeets");
    } else if (status === 4) {
      dispatch(setCurrentDemandVideoCvTab(1));
      history.push("/matchVideoRequest");
    } else if (status === 23 || status === 24) {
      //demand all show

      if (notification.positionTypeId === 1) {
        dispatch(setCurrentAllTab(0));
        history.push({
          pathname: "/demands/allShow",
        });
      } else {
        dispatch(setCurrentAllTab(1));
        history.push({
          pathname: "/demands/allShow",
        });
      }
    } else if (status === 1) {
      if (notification.positionTypeId === 1) {
        dispatch(setCurrentTab(0));
        history.push({
          pathname: "/matchShow",
        });
      } else if (notification.positionTypeId==2) {
        dispatch(setCurrentTab(1));
        history.push({
          pathname: "/matchShow",
        });
      } else if (notification.positionTypeId == 3) {
           history.push({
             pathname: "/demands/internAllShow",
           });
      }
    }
  }
};

export default ReadNotification;
