import React from "react";

const Footer = () => {
  return (
    <div className="footer">
      <div className="copyright">
        <p>
          <a href="http://bitonis.com/" target="_blank"  rel="noreferrer">
          bitonis.com
          </a>{" "}
          Copyright © 2022-{new Date().getFullYear()} bitoniş
        </p>
      </div>
    </div>
  );
};

export default Footer;
