import React, { useEffect, useState } from "react";

import Modal from "react-bootstrap/Modal"; // Varsayılan olarak React Bootstrap Modal bileşeni kullanılıyor
import { Alert, Button } from "react-bootstrap";

function DeleteCompaniesModal({ show, handleClose, approval }) {
  return (
    <>
      <div>
        <MyVerticallyCenteredModal
          show={show}
          onHide={() => handleClose(false)}
          approval={approval}
        />
      </div>
    </>
  );
}
function MyVerticallyCenteredModal(props) {
  const { approval } = props;

  return (
    <Modal
      {...props}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      dialogClassName="full-screen-modal" // Tam ekran sınıfı ekleyin
    >
      <Modal.Header closeButton style={{ borderBottom: "none" }}>
        <Modal.Title aria-labelledby="contained-modal-title-vcenter" centered>
          <h3
            style={{
              marginTop: "30px",
              marginBottom: "0px",
              color: "#001542",
              width: "720px",
              marginLeft: "150px",
            }}
          >
            Grup Şirketi Sil
          </h3>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p
          style={{
            textAlign: "center",
            marginTop: "0px",
            color: "grey",
            fontWeight: "400",
          }}
        >
          <h8>Grup Şirketini silmek istediğinizden emin misiniz? </h8>
        </p>
      </Modal.Body>
      <Modal.Footer
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          alignContent: "center",
          borderTop: "none",
        }}
      >
        <Button
          as="input"
          type="button"
          value="Sil"
          onClick={approval}
          style={{
            borderRadius: "8px",
            fontSize: "15px",
            padding: "15px",
            width: "300px",
            cursor: "pointer",
            transition: "background-color 0.3s",
            backgroundColor: "#FF3D00",
            border: "none",
          }}
        />
      </Modal.Footer>
    </Modal>
  );
}
export default DeleteCompaniesModal;
