import React, { useRef } from "react";
import { useReactToPrint } from "react-to-print";
import WhiteCandidateCvDetail from "./children/WhiteCandidateCvDetail";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";

const WhiteCandidateCv = ({ whiteMatchDetail, pdfRef2 }) => {
  return (
    <>
      <div
        ref={pdfRef2}
        style={{
          width: "100%",
          padding: "10px",
          margin: "0px",
          backgroundColor: "white",
          borderRadius: "8px",
        }}
        className="card"
      >
        <WhiteCandidateCvDetail whiteMatchDetail={whiteMatchDetail} />
      </div>
    </>
  );
};

export default WhiteCandidateCv;
