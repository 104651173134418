import React from "react";

import "../../../../../../../../css/bitoniscss/demand/bluecvdetail.css";
import { useSelector } from "react-redux";
import InternInfoBody from "./children/InfoBody";
import InternNotesCv from "./children/NotesCv";
import InterInfoCv from "./children/InternInfoCv";
import CvWantLocation from "../BlueCandidateCv/children/CvWantLocation";
import InternCvWantLocation from "./children/InternCvWantLocation";
const InternCandidateCvDetail = ({ internMatchDetail }) => {
  console.log("internMatchDetail* * " + JSON.stringify(internMatchDetail));
  var body1;

    body1 = (
      <div
        style={{
          display: "flex",
          flexDirection: "row",
        }}
      >
        <InternInfoBody internMatchDetail={internMatchDetail} />
        <div
          style={{
            margin: "10px",
          }}
        ></div>
        <InternNotesCv />
      </div>
    );

  return (
    <div className="card">
      <div
        style={{
          width: "100%",

          padding: "10px",
          margin: "0px",
          backgroundColor: "white",
          borderRadius: "8px",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
          }}
        >
          <InterInfoCv internMatchDetail={internMatchDetail} />
          <InternCvWantLocation internMatchDetail={internMatchDetail} />
        </div>
        {body1}
      </div>
    </div>
  );
};

export default InternCandidateCvDetail;
