import React, { useEffect, useState } from "react";
import {
  useHistory,
  useLocation,
} from "react-router-dom/cjs/react-router-dom.min";
import { Form, InputGroup, Dropdown, Button } from "react-bootstrap";
import { Card, Tab, Nav } from "react-bootstrap";
import { DemandsServices } from "../../../../../../../../services";
import PaginationPage from "../../../../../../../widgets/PaginationPage";
import MatchNotFound from "../../MatchShow/BlueMatchShow/MatchNotFound";
import ItemByStatus from "../../MatchShow/WhiteMatchShow/widgets/ItemByStatus";
import PopupLongMenu from "../../../../../../../widgets/PopupLongMenu";
import ShowEmployerJobSeekerDocuments from "../../Hire/Common/ShowEmployerJobSeekerDocuments";
import api from "../../../../../../../../utility/Utils";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import LoadingDotsAnimation from "../../../../../../../widgets/LoadingDotsAnimation";
import useCurrentUserActionPageLog from "../../../../../../../hooks/useCurrentUserActionPageLog";
import PrevIconButton from "../../../../../../../widgets/buttons/PrevIconButton";
const SeeWhiteCurrentDemandMatchShow = () => {
    useCurrentUserActionPageLog(10, null);
  const [search, setSearch] = useState("");
  const [getDocument, setGetDocument] = useState(false);

  const [show, setShow] = useState(null);
  function handleClose() {
    setShow(false);
  }
  const history = useHistory();
  const location = useLocation(); // useLocation hook'u ile location alınır
  const { dataMap } = location.state;
  const jobId = dataMap.get("jobId");
  const positionName = dataMap.get("positionName");
  var matchSelectedList = [
    { title: "Yeni Eşleşme", status: 0 },
    { title: "İşyerinde Görüşme Günü Onay Bekliyor", status: 2 },
    { title: "İşyerinde Görüşme Günü Onaylandı", status: 3 },
    { title: "Ek Video CV Bekleniyor", status: 4 },
    { title: "Sistem Reddetti", status: 20 },
    { title: "Video Konf. Görüşme Günü Onay Bekliyor", status: 8 },
    { title: "Video Konf. Görüşme Günü Onaylandı", status: 9 },
    { title: "Gelen Görüşme Günü Onaylandı", status: 10 },
    {
      title: "İş Arayan Ek Video CV'sini Gönderdi",
      status: 12,
    },
    { title: "İle Alındı", status: 6 },
    { title: "İle Alınmadı", status: 7 },
    { title: "Görüşme Onay Bekliyor", status: 15 },
    { title: "Görüşme Onaylandı", status: 16 },
    { title: "Sistem Reddetti", status: 21 },
  ];

  const [currentDemandMatchAllShowData, setCurrentDemandMatchAllShowData] =
    useState(null);
  const [hireItem, setHireItem] = useState(null);
  const [candidateId, setCandidateId] = useState(null);
  const [openDialogSelectItem, setOpenDialogSelectItem] = useState(null);
  const [addedDocument, setAddedDocument] = useState(null);
  useEffect(() => {
    if (getDocument === false) {
      getEmployerDocument();
    }
  }, [getDocument]);
  function getEmployerDocument() {
    setGetDocument([]);
    api
      .get("common/hiringDocuments")
      .then((response) => {
        if (response.succeeded == true) {
          response.data.map((value) => {
            setGetDocument((prev) => [...prev, value]);
          });
        } else {
          setGetDocument(false);
        }
      })
      .catch((err) => {
        setGetDocument(false);
      });
  }
  useEffect(() => {
    if (openDialogSelectItem) {
      addedDocumentApi();
    }
  }, [openDialogSelectItem]);
  function addedDocumentApi() {
    setAddedDocument(null);

    api
      .get("employer/employmentdocs/" + candidateId)
      .then((response) => {
        setAddedDocument([]);
        if (response.succeeded == true) {
          if (response.data.length > 0) {
            setAddedDocument([]);
            response.data.map((value) => {
              setAddedDocument((prev) => [...prev, value]);
            });
            setShow(true);
          } else {
            setAddedDocument([]);
            api
              .get("employer/hirechecklist/2")
              .then((response) => {
                if (response.succeeded == true) {
                  if (response.data.length > 0) {
                    setAddedDocument([]);
                    response.data.map((value) => {
                      setAddedDocument((prev) => [...prev, value]);
                    });
                    setShow(true);
                  } else {
                    setAddedDocument(null);
                    setShow(true);
                  }
                } else {
                  setAddedDocument(null);
                  setShow(true);
                }
              })
              .catch((err) => {
                setAddedDocument(false);
                setShow(true);
              });
          }
        } else {
          setAddedDocument(null);
          setShow(true);
        }
      })
      .catch((err) => {
        setAddedDocument(false);
        setShow(true);
      });
  }
  useEffect(() => {
    if (jobId) {
      getCurrentDemandMatchAllShow();
    }
  }, [jobId]);
  function getCurrentDemandMatchAllShow() {
   
    DemandsServices.whiteCurrentDemandToGetaLLMatchShow(
      matchSelectedList,
      jobId
    ).then(
      (demandMatch) => {
       
        if (demandMatch && demandMatch.succeeded === true) {
           setCurrentDemandMatchAllShowData([]);
          setCurrentDemandMatchAllShowData(demandMatch.data.pagedData);
        }
      },
      (error) => {}
    );
  }

  function searchHandleCandidate(value) {
    setSearch(value);
  }

  const [page, setPage] = useState(1);
  const pageSize = 10; // Her sayfada gösterilecek eleman sayısı

  const paginatedList =
    currentDemandMatchAllShowData && page === null
      ? currentDemandMatchAllShowData
      : currentDemandMatchAllShowData &&
        currentDemandMatchAllShowData.slice(
          (page - 1) * pageSize,
          page * pageSize
        );

  return (
    <div id="demandShow">
      <ToastContainer
        position="top-right"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <div className="header-left">
        <div
          className="dashboard_bar"
          style={{
            textTransform: "capitalize",
            color: "#3d4465",
            fontSize: "25px",
            marginBottom: "10px",
            padding: "0px",
            fontSize: "20px",
            fontWeight: "550",
            margin: "10px",
          }}
        >
          <strong
            style={{
              fontSize: "20px",
            }}
          >
            {positionName}{" "}
          </strong>{" "}
          Pozisyonu Talebinizle Eşleşen Adaylar
        </div>
      </div>

      <div className="row">
        <div className="col-md-12">
          <Card>
            {" "}
            <div
              style={{
                margin: "10px",
                paddingBottom: "20px",
              }}
            >
              <PrevIconButton top={10} left={5} />
            </div>
            <Card.Header
              style={{
                borderBottom: "none",
                marginBottom: "0px",
                paddingBottom: "0px",
                display: "flex",
                flexDirection: "column",
                justifyContent: "flex-start",
                alignContent: "flex-start",
                alignItems: "flex-start",
              }}
            >
              <Card.Title
                style={{
                  fontSize: "14px",
                  fontWeight: "550",
                  margin: "10px",
                }}
              ></Card.Title>
              <InputGroup
                className="mb-0"
                style={{
                  marginBottom: "0px",
                  paddingBottom: "0px",
                }}
              >
                <Form.Control
                  style={{
                    borderRadius: "25px",
                    backgroundColor: "#F5F5F5",
                    marginLeft: "10px",
                  }}
                  type="search"
                  placeholder="İsme göre ara"
                  value={search}
                  onChange={(e) => searchHandleCandidate(e.target.value)}
                />
              </InputGroup>
            </Card.Header>
            <Card.Body>
              <Tab.Container defaultActiveKey={"demandCurrentDemandMatchShow"}>
                <Tab.Content
                  style={{
                    marginTop: "0px",
                    paddingTop: "0px",
                  }}
                >
                  {currentDemandMatchAllShowData === null ? (
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "center",
                      }}
                    >
                      <LoadingDotsAnimation />
                    </div>
                  ) : currentDemandMatchAllShowData &&
                    paginatedList &&
                    paginatedList.length > 0 ? (
                    paginatedList && (
                      <div
                        style={{
                          marginBottom: "10%",
                          marginTop: "0px",
                          paddingTop: "0px",
                        }}
                      >
                        {paginatedList.map((matchData, index) => {
                          return paginatedList[index].candidateNameSurname
                            .toLowerCase()
                            .includes(search.toLowerCase()) ? (
                            <div
                              style={{
                                position: "relative",
                              }}
                            >
                              <div
                                onClick={() => {
                                  const dataMap = new Map();
                                  dataMap.set(
                                    "candidateId",
                                    matchData.candidateId
                                  );
                                  dataMap.set("matchId", matchData.matchId);
                                  dataMap.set("status", matchData.status);
                                  dataMap.set("cameWithoutMatchingPage", true);
                                  history.push({
                                    pathname: "/matchShow/whiteMatchDetail",
                                    state: { dataMap },
                                  });
                                }}
                              >
                                {
                                  <ItemByStatus
                                    matchData={matchData}
                                    key={matchData}
                                    comeCurrentDemand={true}
                                  />
                                }
                              </div>
                              {matchData.status === 6 ? (
                                <div>
                                  <div
                                    onClick={() => {}}
                                    style={{
                                      position: "absolute",
                                      top: 0,
                                      right: 20,
                                      width: 0,
                                      height: 0,
                                      cursor: "pointer",
                                    }}
                                  >
                                    <div>
                                      <PopupLongMenu
                                        options={[
                                          {
                                            title:
                                              "İşe Giriş Evraklarını gönder",
                                            id: 0,
                                          },
                                        ]}
                                        onChanged={(value) => {
                                          var dateTime = new Date(); //burdaki amaç candidateId ye bakarsam eğer aynı itemda dialog açıldığında use effect yenilenmiyor, bu yüzden dialog içinde eski veriler kalıyor.
                                          setOpenDialogSelectItem(dateTime);
                                          if (value) {
                                            setCandidateId(null);
                                            setCandidateId(
                                              matchData.candidateId
                                            );

                                            if (
                                              value.onTapStatus == true &&
                                              value.id === 0
                                            ) {
                                              setHireItem(matchData);
                                            }
                                          }
                                        }}
                                        icon={
                                          <i
                                            className="fa-solid fa-ellipsis-vertical menu-icon-demand"
                                            style={{
                                              color: "orange",
                                            }}
                                          ></i>
                                        }
                                        iconItem={
                                          <i
                                            class="fa-solid fa-file-invoice"
                                            style={{
                                              color: "orange",
                                            }}
                                          ></i>
                                        }
                                      />
                                    </div>
                                  </div>
                                </div>
                              ) : (
                                <div></div>
                              )}
                            </div>
                          ) : (
                            <div></div>
                          );
                        })}
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                          }}
                        >
                          {currentDemandMatchAllShowData &&
                            currentDemandMatchAllShowData.length > 0 &&
                            currentDemandMatchAllShowData.length > pageSize && (
                              <PaginationPage
                                items={currentDemandMatchAllShowData}
                                page={page}
                                pageSize={pageSize}
                                onChange={(value) => {
                                  if (value) {
                                    setPage(value);
                                  }
                                }}
                                onTapAllShowButton={() => {
                                  setPage(null);
                                }}
                              />
                            )}
                        </div>
                      </div>
                    )
                  ) : (
                    <MatchNotFound title={""} newMatch={true} />
                  )}
                </Tab.Content>
              </Tab.Container>
            </Card.Body>
          </Card>
        </div>
      </div>
      <ShowEmployerJobSeekerDocuments
        hireItem={hireItem}
        positionType={2}
        show={show}
        handleClose={handleClose}
        getDocument={getDocument}
        addedDocument={addedDocument}
        approval={(value) => {
          if (value) {
            if (value === true) {
              handleClose();
            }
          }
        }}
      />
    </div>
  );
};

export default SeeWhiteCurrentDemandMatchShow;
