import React, { useEffect, useState } from "react";
import { Card, Tab, Nav } from "react-bootstrap";

import { useDispatch, useSelector } from "react-redux";
import {
  setCurrentTabHire,
  setFilterBlueHire,
} from "../../../../../../../redux/redux-toolkit/selectedHireTab";
import ShowHireBlue from "./BlueHire/ShowHireBlue";
import ShowWhiteHire from "./WhiteHire/ShowWhiteHire";
import api from "../../../../../../../utility/Utils";
import BitonisTabBar from "../../../../../../widgets/BitonisTabBar/BitonisTabBar";
import PageInfoTitle from "../../../../../../widgets/PageInfoTitle";
import PrevIconButton from "../../../../../../widgets/buttons/PrevIconButton";

const DemandHire = () => {
  const dispatch = useDispatch();
  const [getDocument, setGetDocument] = useState(false);

  const { activeCurrentTab, statusHireBlue } = useSelector(
    (state) => state.selectedHireTab
  );
  const selectedChangeTab = (select) => {
    if (select == 0) {
    } else {
    }
    dispatch(setCurrentTabHire(select));
  };

  useEffect(() => {
    if (getDocument === false) {
      getEmployerDocument();
    }
  }, [getDocument]);
  function getEmployerDocument() {
    setGetDocument([]);
    api
      .get("common/hiringDocuments")
      .then((response) => {
        if (response.succeeded == true) {
          response.data.map((value) => {
            setGetDocument((prev) => [...prev, value]);
          });
        } else {
          setGetDocument(false);
        }
      })
      .catch((err) => {
        setGetDocument(false);
      });
  }
  return (
    <div id="matchShow">
     
      <PageInfoTitle title={"İşe Alınanlar"} />

      <div className="row">
        <div className="col-md-12">
          <Card>
            <Card.Body>
              <BitonisTabBar
                tabLeftTitle={"Mavi Yaka"}
                tabRightTitle={"Beyaz Yaka"}
                tabLeftBody={<ShowHireBlue getDocument={getDocument} />}
                tabRightBody={<ShowWhiteHire getDocument={getDocument} />}
                showBorder={false}
                activeTab={activeCurrentTab}
                tabStyle={{
                  fontSize: "0.8rem",
                }}
                onChangeTab={(tab) => {}}
              />
            </Card.Body>
          </Card>
        </div>
      </div>
    </div>
  );
};

export default DemandHire;
