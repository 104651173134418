import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { DropListAction } from "../../../../../../../action";
import { DropListServices } from "../../../../../../../services";
import Modal from "react-bootstrap/Modal"; // Varsayılan olarak React Bootstrap Modal bileşeni kullanılıyor
import Select from "react-select";
import { Alert, Button } from "react-bootstrap";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import api from "../../../../../../../utility/Utils";
import { customStyles } from "../../../../../../customStyleInline";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import BitonisMultiDropdown from "../../../../../../widgets/BitonisMultiDropdown";
import BitonisSingleDropdown from "../../../../../../widgets/BitonisSingleDropdown";
import CustomDesign from "../../../../../../widgets/CustomDesign";
import PageInfoTitle from "../../../../../../widgets/PageInfoTitle";
import { Box } from "@mui/material";
import CustomButton from "../../../../../../widgets/buttons/CustomButton";
import LoadingDotsAnimation from "../../../../../../widgets/LoadingDotsAnimation";
import SelectMultiRoute from "../../../../../../widgets/components/common/SelectMultiRoute";

//İlgili Güzergahı güncelleme
function CompanyServisRoutePageEdit({
  show,
  handleClose,
  props,
  companyPreferredLocationId,
  name,
  companyId,
  preferredLocationLineId,
  addedRoutes,
}) {
  const {
    register,
    handleSubmit,
    control,
    watch,
    reset,
    setValue,
    getValues,
    formState: { errors },
  } = useForm();
  const history = useHistory();
  const [lat, setLat] = useState("");
  const [lng, setLng] = useState("");
  const [selectCompanyList, setSelectCompanyList] = useState([]);
  const [semtNewList, setSemtNewList] = useState([]);
  const [showCountiesAndDistricts, setShowCountiesAndDistricts] =
    useState(true);
  const [deleteRouteModel, setDeleteRouteModel] = useState(null);
  const [servicesRouteData, setServicesRouteData] = useState(null);

  const [cityList, setCityList] = useState([]);
  useEffect(() => {
    if (show) {
      setShowCountiesAndDistricts(true);
    } else {
    }
  }, [show]);
  function getRoutesDetail(id) {
    setServicesRouteData(null);
    api
      .get("company/preferredlocation/" + id)
      .then((response) => {
        if (response.succeeded == true) {
          var data = response.data;
          setServicesRouteData(data);
          //  alert("dta * *  " + JSON.stringify(data));
          setValue("servisRouteName", data.name);
          setValue("countryId", {
            value: data.details[0].countryId,
            label: data.details[0].countryName,
          });
          DropListServices.allCityList({
            countryId: data.details[0].countryId,
          }).then(
            (cityData) => {
              setCityList([]);
              if (cityData && cityData.length > 0) {
                cityData.map((value) =>
                  setCityList((prev) => [...prev, value])
                );
              }
            },
            (error) => {}
          );
          setValue("cityId", {
            value: data.details[0].cityId,
            label: data.details[0].cityName,
          });

          handleCity({
            value: data.details[0].cityId,
          });
          if (data.details[0].cityId === 83) {
            setShowCountiesAndDistricts(false);
          }
          var countiesList = [];
          var districtList = [];
          data.details[0].counties &&
            data.details[0].counties.map((value) => {
              countiesList.push({
                label: value.name,
                value: value.id,
              });
            });
          if (countiesList) {
            handleCounty(countiesList);

            setValue("countyId", countiesList);
          }
          data.details[0].districts &&
            data.details[0].districts.map((value) => {
              districtList.push({
                label: value.name,
                value: value.id,
              });
            });
          if (districtList) {
            setValue("districts", districtList);
            if (
              data.details[0].countryName &&
              data.details[0].cityName &&
              data.details[0].counties[0] &&
              data.details[0].districts[0]
            ) {
              var adres =
                data.details[0].countryName +
                "/" +
                data.details[0].cityName +
                "/" +
                data.details[0].counties[0]["name"] +
                data.details[0].districts[0]["name"];
              getLatLngApi(adres);
            }
          }
          var deleteModel = {
            servisRouteName: data.name,
            countryId: {
              value: data.details[0].countryId,
              label: data.details[0].countryName,
            },
            cityId: {
              value: data.details[0].cityId,
              label: data.details[0].cityName,
            },
            countyId: countiesList,
            districts: districtList,
          };
          setDeleteRouteModel(deleteModel);
        } else {
          setServicesRouteData(null);
          handleClose();
          toast.info("Güzergah Detayı açılırken hata oluştu", {
            autoClose: 2000,
            onClose: () => {
              setTimeout(() => {}, 100);
            },
          });
        }
      })
      .catch((err) => {
        setServicesRouteData(null);
        handleClose();
        toast.info("Güzergah Detayı açılırken hata oluştu", {
          autoClose: 2000,
          onClose: () => {
            setTimeout(() => {}, 100);
          },
        });
      });
  }
  useEffect(() => {
    if (companyPreferredLocationId) {
      getRoutesDetail(companyPreferredLocationId);
    }
  }, [companyPreferredLocationId]);
  useEffect(() => {
    if (show) {
      if (!props.countryList.success) {
        props.dispatch(DropListAction.countryList());
      }
      if (!props.cityList.success) {
        props.dispatch(
          DropListAction.allCityList({
            countryId: 1,
          })
        );
      }
    }
  }, [show]);
  const handleCity = (selected) => {
    setValue("countyId", "");
    setValue("districts", "");
    if (selected.value.toString() == "83") {
      setShowCountiesAndDistricts(false);
    } else {
      setShowCountiesAndDistricts(true);
    }
    props.dispatch(DropListAction.allCountyList(selected.value));
  };

  async function getLatLngApi(adress) {
    DropListServices.handleGetLocation(adress).then((location) => {
      // alert("lat " + location.lat + " " + "lng " + location.lng);
      var latitute = location.lat;
      var longtitute = location.lng;

      setLat(latitute);
      setLng(longtitute);
    });
  }

  const handleCounty = (selected) => {
    setSemtNewList([]);
    if (selected) {
      semtFGetApi(selected);
    }
  };
  function semtFGetApi(selected) {
    for (var i = 0; i < selected.length; i++) {
      DropListServices.districtList(selected[i].value).then(
        (districtList) => {
          if (
            districtList == null ||
            districtList == undefined ||
            districtList == []
          ) {
          } else {
            var semtListPre = [];
            semtListPre = districtList;

            // alert("semtListPre * * * " + JSON.stringify(semtListPre));

            for (var fetSemt in semtListPre) {
              // Aynı ID'ye sahip semt zaten listeye eklenmişse, eklemeyi atla
              if (semtNewList.some((semt) => semt.id == fetSemt.value)) {
                // print("aynı id ye sahip var* * ** * * ");
                continue;
              }
              // Aynı ID'ye sahip  listeye eklenmemişse, ekle
              setSemtNewList((prevList) => [...prevList, semtListPre[fetSemt]]);
            }
          }
        },
        (error) => {
          setSemtNewList([]);
        }
      );
    }
  }

  function editRoutes(form) {
    var formData = {
      companyPreferredLocationId: companyPreferredLocationId,
      companyPreferredLocationLineId: preferredLocationLineId,
      name: form["servisRouteName"],
      companyId: parseInt(companyId.toString()),
      companyPrefLocationReq: {
        countryId: form["countryId"]["value"],
        cityId: form["cityId"]["value"],
        counties:
          form["countyId"] == "" || form["countyId"] == []
            ? []
            : form["countyId"].map((count) =>
                parseInt(count["value"].toString())
              ),
        districts:
          form["districts"] == "" || form["districts"] == []
            ? []
            : form["districts"].map((count) =>
                parseInt(count["value"].toString())
              ),
        latitude: lat.toString(),
        longitude: lng.toString(),
      },
    };
    editCompaniesRoute(formData);
  }
  async function editCompaniesRoute(routeData) {
    setSelectCompanyList([]);
    api
      .put("company/preferredlocation", routeData)
      .then((response) => {
        console.log("response* * * " + JSON.stringify(response));
        if (response.succeeded == true) {
          handleClose(true);
          toast.success(response.result.message, {
            autoClose: 2000,
            onClose: () => {
              setTimeout(() => {
                window.location.href = window.location.href;
              }, 100);
            },
          });
        } else {
          handleClose(true);
          toast.info(response.error.message, {
            autoClose: 2000,
            onClose: () => {
              setTimeout(() => {
                window.location.href = window.location.href;
              }, 100);
            },
          });
        }
      })
      .catch((err) => {
        console.log("Hata Oluştu" + JSON.stringify(err));
        handleClose(true);
        toast.info("Hata Oluştu", {
          autoClose: 2000,
          onClose: () => {
            setTimeout(() => {
              window.location.href = window.location.href;
            }, 100);
          },
        });
      });
  }

  function deleteRoutes() {
    var sendData = {
      companyPreferredLocationId: companyPreferredLocationId,
      companyPreferredLocationLineId: preferredLocationLineId,
      name: deleteRouteModel["servisRouteName"],
      companyId: parseInt(companyId.toString()),
      companyPrefLocationReq: {
        countryId: deleteRouteModel["countryId"]["value"],
        cityId: deleteRouteModel["cityId"]["value"],
        counties:
          deleteRouteModel["countyId"] == "" ||
          deleteRouteModel["countyId"] == []
            ? []
            : deleteRouteModel["countyId"].map((count) =>
                parseInt(count["value"].toString())
              ),
        districts:
          deleteRouteModel["districts"] == "" ||
          deleteRouteModel["districts"] == []
            ? []
            : deleteRouteModel["districts"].map((count) =>
                parseInt(count["value"].toString())
              ),
        latitude: lat.toString(),
        longitude: lng.toString(),
      },
    };

    api
      .delete("company/preferredlocation", { data: sendData })
      .then((response) => {
        if (response.succeeded == true) {
          handleClose();
          toast.success(response.result.message, {
            autoClose: 2000,
            onClose: () => {
              setTimeout(() => {
                if (addedRoutes.length === 1) {
                  history.push("/companyRoutes");
                } else {
                  window.location.href = window.location.href;
                }
              }, 100);
            },
          });
        } else {
          console.log("response err* * * " + JSON.stringify(response.error));
          toast.info(response.error.message, {
            autoClose: 2000,
            onClose: () => {
              setTimeout(() => {}, 100);
            },
          });
        }
      })
      .catch((err) => {
        console.log("hata oluştu  **  " + JSON.stringify(err));
        toast.info("Hata Oluştu", {
          autoClose: 2000,
          onClose: () => {
            setTimeout(() => {
              //  window.location.href = window.location.href;
            }, 100);
          },
        });
      });
  }
  return (
    <>
      {servicesRouteData === null && show == true ? (
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignContent: "center",
            alignItems: "center",
          }}
        >
          <LoadingDotsAnimation />
        </Box>
      ) : (
        <div>
          <Modal
            className="fade bd-example-modal-lg"
            size="lg"
            show={show}
            onHide={handleClose}
            backdrop="static"
            keyboard={false}
            aria-labelledby="contained-modal-title-vcenter"
            centered
          >
            <Modal.Header
              closeButton
              style={{
                borderBottom: "none",
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignContent: "center",
                alignItems: "center",
                marginBottom: "0px",
                paddingBottom: "0px",
              }}
            >
              <PageInfoTitle title={name} />
            </Modal.Header>
            <Modal.Body
              style={{
                marginTop: "0px",
                paddingTop: "0px",
                marginBottom: "0px",
                paddingBottom: "20px",
              }}
            >
              <form onSubmit={handleSubmit(editRoutes)}>
                <div className="row">
                  <CustomDesign
                    label={"Servis Güzergah Başlığı"}
                    required={true}
                    isColumn={true}
                    columnClassName={"col-xs-12 col-md-12"}
                    child={
                      <>
                        {" "}
                        <input
                          type="text"
                          className="form-control"
                          id="inputClass"
                          rules={{ required: true }}
                          name="servisRouteName"
                          {...register("servisRouteName", { required: true })}
                          placeholder="Servis Güzergah Adı"
                        ></input>
                        {errors.servisRouteName && (
                          <div className="text-danger fs-12">
                            Bu alan Boş Olamaz
                          </div>
                        )}
                      </>
                    }
                  />
                </div>
                <SelectMultiRoute
                  props={props}
                  register={register}
                  watch={watch}
                  control={control}
                  setValue={setValue}
                  errors={errors}
                  getValues={getValues}
                  getLatLngApi={getLatLngApi}
                  preview={true}
                  setSemtNewList={setSemtNewList}
                  semtNewList={semtNewList}
                  handleCity={handleCity}
                  handleCounty={handleCounty}
                  showCountiesAndDistricts={showCountiesAndDistricts}
                  setShowCountiesAndDistricts={setShowCountiesAndDistricts}
                  cityList={cityList}
                  setCityList={setCityList}
                />
              </form>
            </Modal.Body>
            <Modal.Footer
              style={{
                borderTop: "none",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                alignContent: "center",
                borderTop: "none",
                marginTop: "0px",
                paddingTop: "0px",
              }}
            >
              <div className="container">
                <div className="row">
                  <div className="col-xs-6 col-md-6">
                    <Box
                      sx={{
                        margin: "0px",
                        padding: {
                          xs: "5px",
                          sm: "5px",
                          md: "5px",
                          lg: "5px",
                          xl: "5px",
                        },
                      }}
                    >
                      <CustomButton
                        onTap={deleteRoutes}
                        title={"Sil"}
                        width={{
                          xs: "400px",
                          sm: "100%",
                          md: "100%",
                          lg: "350px",
                          xl: "350px",
                        }}
                        backgroundColor={"red"}
                        border={"none"}
                        hoverBackgroundColor={"rgb(231, 32, 32)"}
                      />
                    </Box>
                  </div>

                  <div className="col-xs-6 col-md-6">
                    <form onSubmit={handleSubmit(editRoutes)}>
                      <Box
                        sx={{
                          margin: "0px",
                          padding: {
                            xs: "5px",
                            sm: "5px",
                            md: "5px",
                            lg: "5px",
                            xl: "5px",
                          },
                        }}
                      >
                        <CustomButton
                          title={"Kaydet"}
                          width={{
                            xs: "400px",
                            sm: "100%",
                            md: "100%",
                            lg: "350px",
                            xl: "350px",
                          }}
                        />
                      </Box>
                    </form>
                  </div>
                </div>
              </div>
            </Modal.Footer>
          </Modal>
        </div>
      )}
    </>
  );
}

export default CompanyServisRoutePageEdit;

/*

 <div className="row">
                  <CustomDesign
                    label={"Ülke"}
                    required={true}
                    isColumn={true}
                    columnClassName={"col-xs-6 col-md-6"}
                    child={
                      props.countryList.countryList && (
                        <BitonisSingleDropdown
                          items={
                            props.countryList.countryList &&
                            props.countryList.countryList.map(({ id, name }) => ({
                              value: id,
                              label: name,
                            }))
                          }
                          register={register}
                          watch={watch}
                          companyClassName={"countryId"}
                          control={control}
                          setValue={setValue}
                          errors={errors}
                          placeholder="Ülke Seç"
                          onValueChanged={(e) => {
                            console.log(e);
                            if (e) {
                              props.dispatch(
                                DropListAction.allCityList({
                                  countryId: 1,
                                })
                              );
                              setValue("cityId", "");
                              setValue("countyId", "");
                              setValue("districts", "");
                            }
                          }}
                          required={true}
                          getValues={getValues}
                          errorMessage={"Ülke Boş Olamaz"}
                        />
                      )
                    }
                  />

                  <CustomDesign
                    label={"İl/Şehir"}
                    required={true}
                    isColumn={true}
                    columnClassName={"col-xs-6 col-md-6"}
                    child={
                      cityList && (
                        <BitonisSingleDropdown
                          items={
                            cityList &&
                            cityList.map(({ id, name }) => ({
                              value: id,
                              label: name,
                            }))
                          }
                          register={register}
                          watch={watch}
                          companyClassName={"cityId"}
                          control={control}
                          setValue={setValue}
                          errors={errors}
                          placeholder="İl/Şehir Seç"
                          onValueChanged={(e) => {
                            console.log(e);
                            setValue("countyId", "");
                            setValue("districts", "");
                            if (e) {
                              handleCity(e);
                              var country = watch("countryId");
                              if (country && e) {
                                var adres = country.label + "/" + e.label;
                                getLatLngApi(adres);
                              }
                            }
                          }}
                          required={true}
                          getValues={getValues}
                          errorMessage={"İl/Şehir Boş Olamaz"}
                        />
                      )
                    }
                  />
                </div>

                <div className="row">
                  {showCountiesAndDistricts &&
                    props.countyList.countyList &&
                    props.countyList.countyList.length > 0 && (
                      <CustomDesign
                        label={"İlçe"}
                        required={true}
                        isColumn={true}
                        columnClassName={"col-xs-6 col-md-6"}
                        child={
                          <BitonisMultiDropdown
                            items={
                              props.countyList.countyList &&
                              props.countyList.countyList.map(({ id, name }) => ({
                                value: id,
                                label: name,
                              }))
                            }
                            register={register}
                            watch={watch}
                            companyClassName={"countyId"}
                            control={control}
                            setValue={setValue}
                            errors={errors}
                            errorMessage={"İlçe Boş Olamaz "}
                            placeholder="İlçe Seç"
                            onValueChanged={(e) => {
                              if (e) {
                                const selectedValues = e.map(
                                  (option) => option.value
                                );
                                if (selectedValues.includes(990)) {
                                  setValue("districts", "");
                                  setSemtNewList([]);
                                  const selectedOption = e.find(
                                    (option) => option.value === 990
                                  );
                                  if (selectedOption) {
                                  } else {
                                  }
                                } else {
                                  handleCounty(e);
                                  setValue("districts", "");
                                }
                              } else {
                                setValue("districts", "");
                                setSemtNewList([]);
                              }
                            }}
                            required={true}
                            selectedId={990}
                            getValues={getValues}
                          />
                        }
                      />
                    )}

                  {showCountiesAndDistricts &&
                    semtNewList &&
                    semtNewList.length > 0 && (
                      <CustomDesign
                        label={"Semt/Mahalle"}
                        required={true}
                        isColumn={true}
                        columnClassName={"col-xs-6 col-md-6"}
                        child={
                          <BitonisMultiDropdown
                            items={
                              semtNewList &&
                              semtNewList.map(({ id, name }) => ({
                                value: id,
                                label: name,
                              }))
                            }
                            register={register}
                            watch={watch}
                            companyClassName={"districts"}
                            control={control}
                            setValue={setValue}
                            errors={errors}
                            errorMessage={""}
                            placeholder="Semt/Mahalle Seç"
                            onValueChanged={(e) => {
                              var country = watch("countryId");
                              var city = watch("cityId");
                              var county = watch("countyId");
                              if (country && city && county && e) {
                                var adres =
                                  country.label +
                                  "/" +
                                  city.label +
                                  "/" +
                                  county.label +
                                  e.label;
                                getLatLngApi(adres);
                              }
                            }}
                            getValues={getValues}
                          />
                        }
                      />
                    )}
                </div>

                */
