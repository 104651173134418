import React, { useEffect, useMemo, useState } from "react";
import { Controller, useForm } from "react-hook-form";

import Modal from "react-bootstrap/Modal"; // Varsayılan olarak React Bootstrap Modal bileşeni kullanılıyor
import Select from "react-select";
import { Alert, Button } from "react-bootstrap";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { DropListAction } from "../../../../../../../../../action";
import "../../../../../../../../../css/bitoniscss/modalcss.css";
import { labelStyle } from "../../../../../../../../customStyleInline";
import AbilitiesRequiredWidget from "../../../../../FindJobSeeker/Common/AbilitiesRequiredWidget";
import LicenseTypeRequired from "../../../../../FindJobSeeker/Common/LicenseTypeRequired";
import LicenseTypeRequiredUpdate from "../../../../../FindJobSeeker/Common/LicenseTypeRequiredUpdate";
import BitonisMultiDropdown from "../../../../../../../../widgets/BitonisMultiDropdown";
import ProgramsCertificatesCompetences from "../../../../../ProgramsCertificatesCompetences/ProgramsCertificatesCompetences";
import BitonisSingleDropdown from "../../../../../../../../widgets/BitonisSingleDropdown";
import FindInternSelectEducation from "../../../../../../../../widgets/educationWidgets/FindInternSelectEducation";
import { DemandsServices } from "../../../../../../../../../services";
import CustomDesign from "../../../../../../../../widgets/CustomDesign";
import CustomButton from "../../../../../../../../widgets/buttons/CustomButton";
function EditInternDemandFeatureModal({
  show,
  handleClose,
  job,
  onSubmit,
  props,
  register,
  handleSubmit,
  control,
  setValue,
  errors,
  watch,
  isValid,
  getValues,
  reset,
}) {
  const genderList = [
    { id: 0, name: "Farketmez" },
    { id: 1, name: "Kadın" },
    { id: 2, name: "Erkek" },
  ];
  const [sectionList, setSectionList] = useState([]);
  function internHighSchoolDepartmentList() {
    setSectionList([]);
    //stajyer için lise bölüm listesini verir
    DemandsServices.internHighSchoolDepartmentList().then((response) => {
      if (response && response.succeeded === true) {
        response.data.map((val) => {
          setSectionList((prev) => [...prev, val]);
        });
      }
    });
  }

  function internFacultyDepartmentGroupList() {
    setSectionList([]);
    //stajyer riçin fakültelerin bölüm listesini verir.
    DemandsServices.internFacultyDepartmentGroupList().then((response) => {
      if (response && response.succeeded === true) {
        response.data.map((val) => {
          setSectionList((prev) => [...prev, val]);
        });
      }
    });
  }
  useEffect(() => {
    if (show) {
      if (
        job &&
        job.internJobEducation.internEducationType &&
        job.internJobEducation.internEducationType === 3
      ) {
        //lise
        internHighSchoolDepartmentList();
      } else {
        //lise üstü
        internFacultyDepartmentGroupList();
      }
    }
  }, [show]);
  return (
    <>
      <div>
        <Modal
          show={show}
          onHide={handleClose}
          keyboard={false}
          aria-labelledby="contained-modal-title-vcenter"
          centered
          style={{
            backgroundColor: "rgba(0, 0, 0, 0.2)",
            left: "auto",
          }}
          backdrop="static"
          size="lg"
        >
          <div>
            <Modal.Header closeButton style={{ borderBottom: "none" }}>
              <Modal.Title
                aria-labelledby="contained-modal-title-vcenter"
                style={{
                  width: "100%",
                  textAlign: "center",
                  margin: "0 auto",
                }}
              >
                Aradığım Özellikler
              </Modal.Title>
            </Modal.Header>
            <Modal.Body
              style={{
                marginTop: "0px",
                paddingTop: "0px",
              }}
            >
              <form
                onSubmit={(e) => {
                  e.preventDefault();
                  handleSubmit(onSubmit)();
                  if (isValid) {
                    handleClose(); // Close the modal after form submission
                  }
                }}
              >
                <CustomDesign
                  label={"Uyruk"}
                  required={true}
                  child={
                    <BitonisSingleDropdown
                      items={
                        props.nationalityList.nationalityList &&
                        props.nationalityList.nationalityList.map(
                          ({ id, name }) => ({
                            value: id,
                            label: name,
                          })
                        )
                      }
                      register={register}
                      watch={watch}
                      companyClassName={"nationalityId"}
                      control={control}
                      setValue={setValue}
                      errors={errors}
                      placeholder="Uyruk Seçin"
                      onValueChanged={(e) => {
                        console.log(e);
                        if (e) {
                        }
                      }}
                      required={true}
                      getValues={getValues}
                      errorMessage={"Uyruk Boş geçilemez"}
                    />
                  }
                />
                <CustomDesign
                  label={"Cinsiyet"}
                  required={true}
                  child={
                    <BitonisSingleDropdown
                      items={genderList.map(({ id, name }) => ({
                        value: id,
                        label: name,
                      }))}
                      register={register}
                      watch={watch}
                      companyClassName={"gender"}
                      control={control}
                      setValue={setValue}
                      errors={errors}
                      placeholder="Cinsiyet Seçin"
                      onValueChanged={(e) => {
                        console.log(e);
                      }}
                      required={true}
                      getValues={getValues}
                      errorMessage={"Cinsiyet Boş geçilemez"}
                    />
                  }
                />

                <FindInternSelectEducation
                  register={register}
                  control={control}
                  watch={watch}
                  reset={reset}
                  setValue={setValue}
                  errors={errors}
                  getValues={getValues}
                  sectionList={sectionList}
                  setSectionList={setSectionList}
                />
              </form>
            </Modal.Body>
            <Modal.Footer
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                alignContent: "center",
                borderTop: "none",
                margin: "0px",
                paddingTop: "0px",
              }}
            >
              <form
                onSubmit={(e) => {
                  e.preventDefault();
                  handleSubmit(onSubmit)();
                  if (isValid) {
                    handleClose();
                  }
                }}
              >
                 <CustomButton title={"Kaydet"} width={{
                    xs: "350px",
                    sm: "350px",
                    md: "350px",
                    lg: "350px",
                    xl: "350px",
                  }}/>
              </form>
            </Modal.Footer>
          </div>
        </Modal>
      </div>
    </>
  );
}

export default EditInternDemandFeatureModal;
