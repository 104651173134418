// dataSlice.js
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
	preferredLocationListRedux: [],
};

const preferredListSlice = createSlice({
  name: "preferredLocation",
  initialState,
  reducers: {
	  addPreferredLocation: (state, action) => {
		
		  state.preferredLocationListRedux = action.payload;
	
		
    },
  },
});

export const { addPreferredLocation } = preferredListSlice.actions;
export default preferredListSlice.reducer;
