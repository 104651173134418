// src/features/dataSlice.js

import { createSlice } from "@reduxjs/toolkit";
import api from "../../utility/Utils";
function groupCompanyLocations(id) {
  //Şirket grup şirketleri
  return api
    .get("/company/location/list/" + id.toString())
    .then((response) => {
      return Promise.resolve(response.data);
    })
    .catch((err) => {
      return Promise.reject(err);
    });
}
export const getGroupCompanyLocationsFetch = (id) => async (dispatch) => {
  try {
    dispatch(fetchGetCompaniesLocationDataStart());
    groupCompanyLocations(id).then(
      (groupCompany) => {
      
        dispatch(fetchGetCompaniesLocationDataSuccess(groupCompany));
      },
      (error) => {
        dispatch(fetchGetCompaniesLocationDataFailure());
      }
    );
  } catch (error) {
    dispatch(fetchGetCompaniesLocationDataFailure());
  }
};
const getGroupCompaniesLocations = createSlice({
  name: "getGroupCompanyLocations",
  initialState: {
    isLoadingCompanyLocations: false,
    successLocations: false,
    getGroupCompanyLocationsData: null,
  },
  reducers: {
    fetchGetCompaniesLocationDataStart(state) {
      state.isLoadingLocations = true;
      state.successLocations = false;
    },
    fetchGetCompaniesLocationDataSuccess(state, action) {
      state.isLoadingCompanyLocations = false;
      state.successLocations = true;
      state.getGroupCompanyLocationsData = action.payload;
     
    },
    fetchGetCompaniesLocationDataFailure(state) {
      state.isLoadingCompanyLocations = false;
      state.successLocations = false;
      state.getGroupCompanyLocationsData = null;
    },
  },
});

export const {
  fetchGetCompaniesLocationDataStart,
  fetchGetCompaniesLocationDataSuccess,
  fetchGetCompaniesLocationDataFailure,
} = getGroupCompaniesLocations.actions;

export default getGroupCompaniesLocations.reducer;
