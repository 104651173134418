import React, { useState } from "react";
import { Card, Tab, Nav } from "react-bootstrap";

import { useDispatch, useSelector } from "react-redux";
import {
  setCurrentTabHire,
  setCurrentTabNotHire,
  setFilterBlueHire,
} from "../../../../../../../redux/redux-toolkit/selectedNotHireTab";
import ShowNotHireWhite from "./NotWhiteHire/ShowNotHireWhite";
import ShowNotHireBlue from "./NotBlueHire/ShowNotHireBlue";
import BitonisTabBar from "../../../../../../widgets/BitonisTabBar/BitonisTabBar";
import PageInfoTitle from "../../../../../../widgets/PageInfoTitle";
import PrevIconButton from "../../../../../../widgets/buttons/PrevIconButton";

const DemandNotHire = () => {
  const dispatch = useDispatch();
  const { activeCurrentTab } = useSelector(
    (state) => state.selectedNotHireTab
  );
  const selectedChangeTab = (select) => {
    if (select == 0) {
    } else {
    }
    dispatch(setCurrentTabNotHire(select));
  };

  return (
    <div id="matchShow">
  
      <PageInfoTitle title={"İşe Alınmayanlar"} />

      <div className="row">
        <div className="col-md-12">
          <Card>
            <Card.Body>
              <BitonisTabBar
                tabLeftTitle={"Mavi Yaka"}
                tabRightTitle={"Beyaz Yaka"}
                tabLeftBody={<ShowNotHireBlue />}
                tabRightBody={<ShowNotHireWhite />}
                showBorder={false}
                activeTab={activeCurrentTab}
                tabStyle={{
                  fontSize: "0.8rem",
                }}
                onChangeTab={(tab) => {}}
              />
            </Card.Body>
          </Card>
        </div>
      </div>
    </div>
  );
};

export default DemandNotHire;
