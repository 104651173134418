import * as React from "react";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Box from "@mui/material/Box";
import { useState } from "react";
import { Button } from "react-bootstrap";
import { StepConnector } from "@mui/material";
import { makeStyles, withStyles } from "@mui/styles";
const DottedStepConnector = withStyles((theme) => ({
  line: {
    borderColor: "orange", // Noktalı çizginin rengini isteğinize göre değiştirin
    borderTopWidth: 4,
    borderStyle: "dotted",
    borderBottomWidth: 0,
    marginLeft: 8, // Yuvarlak noktalar ile çizgi arasındaki boşluğu ayarlayın
    marginRight: 8,
  },
}))(StepConnector);
const EasySteps = ({
  stepsHeader,
  children,
  currentStep,
  changePrev,

}) => {
  return (
    <div>
      <div className="card">
        <div
          className="card-header"
          style={{
            borderBottom: "none",
          }}
        >
          {" "}
          <i
            class="fa-solid fa-angle-left"
            style={{
              fontSize: "25px",
              marginTop: "0px",
            }}
            onClick={changePrev}
          ></i>
          <Stepper 
            connector={<DottedStepConnector />}
            activeStep={currentStep}
            alternativeLabel
            style={{
              width: "100%",
              marginTop: "35px",
            }}
          >
            {stepsHeader.map((label, index) => (
              <Step key={label.title}>
                <StepLabel
                  icon={
                    <div
                      style={
                        currentStep === index
                          ? {
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              width: "40px",
                              height: "40px",
                              border: "2.5px dotted #fdc303",
                              borderRadius: "50%",
                              marginBottom: "0px",
                              borderColor: "orange",
                              position: "relative",
                              bottom: "5px",
                            }
                          : currentStep > index
                          ? {
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              width: "40px",
                              height: "40px",
                              backgroundColor: "orange",
                              color: "white",
                              borderRadius: "50%",
                              marginBottom: "0px",
                              position: "relative",
                              bottom: "5px",
                            }
                          : {
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              width: "40px",
                              height: "40px",
                              border: "2px dotted #001542",
                              borderRadius: "50%",
                              marginBottom: "0px",
                              position: "relative",
                              bottom: "5px",
                            }
                      }
                    >
                      {label.icon}
                    </div>
                  }
                >
                  {label.title}
                </StepLabel>
              </Step>
            ))}
          </Stepper>
        </div>
        <div className="card-body"></div>
        <React.Fragment>
          <div
            style={{
              margin: "5%",
            }}
          >
            {children}
            
          </div>
        </React.Fragment>
      
      </div>
    </div>
  );
};

export default EasySteps;
