import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  activeCurrentTab: 0,
  statusHireBlue: [
    {
  
}
  ],
  statusHireWhite: [{
    
  }]
};

const selectedHireTab = createSlice({
  name: "selectedHireTab",
  initialState,
  reducers: {
    setCurrentTabHire: (state, action) => {
      state.activeCurrentTab = action.payload;
    },
    setFilterBlueHire: (state, action) => {
      state.statusHireBlue = action.payload;
    },
    setFilterWhiteHire: (state, action) => {
      state.statusHireWhite = action.payload;
    },
  },
});

export const { setCurrentTabHire, setFilterBlueHire, setFilterWhiteHire } =
  selectedHireTab.actions;
export default selectedHireTab.reducer;
