import React, { useState, useEffect } from "react";

const CountdownTimerOtp = ({ initialMinute, again, onTimeChange }) => {
  const initialTime = initialMinute * 60; // dakika cinsinden saniye
  const [time, setTime] = useState(initialTime);

  useEffect(() => {
    let interval;

    // Sayacı başlat
    interval = setInterval(() => {
      setTime((prevTime) => {
        if (prevTime === 0) {
          // Sayaç bittiğinde, tekrar 10 dakikaya başla
          if (again) {
         
            return initialTime;
          } else {
            return 0;
          }
        } else {
          // Sayaç devam ediyor
          return prevTime - 1;
        }
      });
    }, 1000); // 1 saniyede bir güncelle

    return () => clearInterval(interval); 
  }, [initialTime, onTimeChange]);

  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${String(minutes).padStart(2, "0")}:${String(
      remainingSeconds
    ).padStart(2, "0")}`;
  };

  useEffect(() => {
  
      onTimeChange(time);
    
  }, [time]);

  return (
    <div>
      <p style={{ fontSize: "13px", color: "#001542", fontWeight: "500" }}>
        {formatTime(time) === "00:00" ? (
          "Süre bitti"
        ) : (
          <p>Kalan Süre: {formatTime(time)}</p>
        )}
      </p>
    </div>
  );
};

export default CountdownTimerOtp;
