export default function initFillInFormInternDetail(job, setValue) {
  setValue("companyId", {
    value: job.companyId,
    label: job.companyName,
  });
  setValue("companyLocationId", {
    value: job.companyLocationId,
    label: job.companyLocationName,
  });


  setValue("nationalityId", {
    value: job.jobNationality.id,
    label: job.jobNationality.name,
  });

  setValue("gender", {
    value: job.gender.gender,
    label: job.gender.description,
  });
  setValue("internEducationType", {
    value: job.internJobEducation.internEducationType,
    label: job.internJobEducation.description,
  });
  setValue("internEducationSection", {
    value: job.departmentId,
    label: job.departmentName,
  });
  

   setValue(
     "internEducationClass",
     job.internJobClassLevel.map(({ internClassLevel, description }) => ({
       value: internClassLevel,
       label: description,
     }))
   );
}
