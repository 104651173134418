import React, { useEffect, useState } from "react";

import Modal from "react-bootstrap/Modal"; // Varsayılan olarak React Bootstrap Modal bileşeni kullanılıyor
import { Alert, Button } from "react-bootstrap";
import CustomButton from "../../../../../widgets/buttons/CustomButton";

function FindJobSeekerIsPreviewModal({
  show,
  handleClose,
  handleSubmit,
  submitNewVersion,
  isIntern
}) {
  return (
    <>
      <div>
        <MyVerticallyCenteredModal
          show={show}
          onHide={() => handleClose(false)}
          handleSubmit={handleSubmit}
          submitNewVersion={submitNewVersion}
          isIntern={isIntern}
        />
      </div>
    </>
  );
}
function MyVerticallyCenteredModal(props) {
  const { handleSubmit, submitNewVersion, isIntern } = props;

  return (
    <Modal
      {...props}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      dialogClassName="full-screen-modal" // Tam ekran sınıfı ekleyin
    >
      <Modal.Header closeButton style={{ borderBottom: "none" }}>
        <Modal.Title aria-labelledby="contained-modal-title-vcenter" centered>
          <h4
            style={{
              textAlign: "center",
              marginTop: "30px",
              fontWeight: "600",
            }}
          >
            {" "}
            Talebinizi Onaylamak İstediğinizden Emin misiniz?
          </h4>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {isIntern === false && (
          <p
            style={{
              textAlign: "center",
              marginTop: "0px",
              color: "grey",
              fontWeight: "400",
            }}
          >
            <h8>
              Talebinizi yayınladıktan sonra Eşleşme başlatılacağı için{" "}
              <b style={{ color: "#001542" }}> {"Pozisyon"}</b> hariç, bütün
              alanları 30 dakika içerisinde güncelleyebilirsiniz.
            </h8>
          </p>
        )}
      </Modal.Body>
      <Modal.Footer
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          alignContent: "center",
          borderTop: "none",
        }}
      >
        <form onSubmit={handleSubmit(submitNewVersion)}>
         
           <CustomButton title={"Talebimi Yayınla"} width={{
                    xs: "350px",
                    sm: "350px",
                    md: "350px",
                    lg: "350px",
                    xl: "350px",
                  }} />
        </form>
      </Modal.Footer>
    </Modal>
  );
}
export default FindJobSeekerIsPreviewModal;
