import React, { useEffect, useRef, useState } from "react";
import ReactPlayer from "react-player";
import Modal from "react-bootstrap/Modal"; // Varsayılan olarak React Bootstrap Modal bileşeni kullanılıyor
import { Alert, Button } from "react-bootstrap";

import { API_URL } from "../../../../../../../../../services/ApiUrlServices";

function PlayAndSendVideoCvModal({
  show,
  handleClose,
  whiteMatchDetail,
  onApproval,
}) {
  return (
    <>
      <div>
        <MyVerticallyCenteredModal
          show={show}
          onHide={() => {
            handleClose(false);
          }}
          whiteMatchDetail={whiteMatchDetail}
          onApproval={onApproval}
          handleClose={handleClose}
        />
      </div>
    </>
  );
}
function MyVerticallyCenteredModal(props) {
  const { show, whiteMatchDetail, onApproval, handleClose } = props;
  const [sendVideoText, setSendVideoText] = useState(null);
  const [playing, setPlaying] = useState(false);
  const [url, setUrl] = useState(null);

  useEffect(() => {
    if (show) {
      var candidateId = whiteMatchDetail && whiteMatchDetail.candidateId;
      var video = whiteMatchDetail && whiteMatchDetail.videoUrl;
      const url = `${API_URL}/images/candidateVideo/profile/${candidateId}/${video}`;
      setUrl(url);
      setPlaying(false);
    }
  }, [show]);
  return (
    <Modal
      {...props}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      dialogClassName="full-screen-modal"
      style={{
        width: "500px !important",
      }}
    >
      <Modal.Header
        closeButton
        style={{ borderBottom: "none", justifyContent: "center" }}
      >
        <Modal.Title aria-labelledby="contained-modal-title-vcenter" centered>
          <h3 style={{ color: "#001542", fontSize: "20px" }}>Video Cv</h3>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {whiteMatchDetail && whiteMatchDetail.videoUrl && (
          <div
            style={{
              maxWidth: "500px",
              margin: "auto",
              borderRadius: "15px",
              overflow: "hidden",
              margin: "0px",
              width: "100%",
      
            }}
          >
            <ReactPlayer
              url={url && url}
              controls={true}
              width="100%"
   
              playing={playing}
            />
          </div>
        )}

        <li
          style={{
            listStyleType: "none",
            marginBottom: "10px",
            display: "flex",
            alignItems: "center",
            marginTop: "10px",
          }}
        >
          <div
            style={{
              width: "10px",
              height: "10px",
              borderRadius: "50%",
              background: "orange",
              marginRight: "10px",
            }}
          ></div>
          <p
            style={{
              fontSize: "15px",
              margin: "0",
              color: "#001542",
              fontWeight: 500,
            }}
          >
            Ek Video CV İçin Göndereceğin Sorular ;
          </p>
        </li>
        <textarea
          id="myTextarea"
          name="myTextarea"
          value={sendVideoText}
          onChange={(value) => {
            setSendVideoText(value.target.value);
          }}
          style={inputStyle}
          placeholder="Ek Video ile cevaplanması istenen sorularınızı yazınız."
        />

        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
          }}
        >
          <Button
            as="input"
            type="button"
            value="Gönder"
            style={{
              borderRadius: "8px",
              fontSize: "15px",
              padding: "15px",
              width: "100%",
              cursor: "pointer",
              transition: "background-color 0.3s",
              backgroundColor: "#001542",
              border: "none",
              margin: "0px",
            }}
            onClick={(value) => {
              value.preventDefault();
              onApproval(sendVideoText);
              setSendVideoText("");
              handleClose();
            }}
          />
        </div>
      </Modal.Body>
    </Modal>
  );
}
export default PlayAndSendVideoCvModal;
var inputStyle = {
  borderRadius: "8px",
  backgroundColor: "#F5F5F5",
  margin: "10px auto", // Orta hizaya almak için
  width: "100%", // Genişliği %100 yapmak
  maxWidth: "800px", // Maksimum genişlik
  padding: "15px", // Padding artırıldı
  boxSizing: "border-box",
  height: "100px",
  border: "1px solid #ccc", // Çerçeve eklenmiş
};
