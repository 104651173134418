import React from "react";

import "../../css/bitoniscss/custompdfpreview.css";

const CustomPdfPreview = ({ name, value, matchPointStatus }) => {
  return (
    <div className="widgetPc-container">
      <div className="widgetPc-label">{name}: </div>
      <div className="widgetPc-value">{value && value.toString()}</div>
    </div>
  );
};

export default CustomPdfPreview;
