import api from "../utility/Utils";

export const DropListWhiteServices = {
    positionListWhite,
    yearOfExperienceWhite,
    wayOfWorkingWhite,
    nationalityListWhite,
    ageRangeListWhite,
    driverLicenceTypeWhite,
    programListWhite,
    certificatesListWhite,
    competencesListWhite,
    countryListWhite,
    cityListWhite,
    countyListWhite,
    districtListWhite,
    companyListWhite,
    salaryListWhite,
    businessTravelListWhite,
    languageListWhite,
    languageLevelListWhite,
    universityListWhite,
    facultyListWhite,
    facultyDepartmentListWhite,
    whiteNewDemand,
    whiteUpdateDemand,
};

// Beyaz Yaka Bilgiler
function companyListWhite(arg) { //Şirket Bilgileri
    return api.get("/company/" + arg)
        .then((response) => {
            return Promise.resolve(response.data);
        })
        .catch((err) => {
            return Promise.reject(err);
        });
}
function positionListWhite(arg) { // Pozisyon Listesi
    return api.post("/common/GetPositionList", arg)
        .then((response) => {
           
            return Promise.resolve(response.data);
        })
        .catch((err) => {
            return Promise.reject(err);
        });
}
function yearOfExperienceWhite(arg) { //Tecrübe Yılı listesi
    return api.post("/common/GetExperienceRangeTypeList", arg)
        .then((response) => {
            return Promise.resolve(response.data);
        })
        .catch((err) => {
            return Promise.reject(err);
        });
}
function wayOfWorkingWhite(arg) { // Çalışma Şekli
    return api.post("/common/GetWorkTypeList", arg)
        .then((response) => {
            return Promise.resolve(response.data);
        })
        .catch((err) => {
            return Promise.reject(err);
        });
}
function nationalityListWhite(arg) { // Uyruk
    return api.get("/common/GetNationalityList", arg)
        .then((response) => {
            return Promise.resolve(response.data);
        })
        .catch((err) => {
            return Promise.reject(err);
        });
}
function ageRangeListWhite(arg) { // Yaş Aralığı
    return api.get("/common/GetAgeRangeList", arg)
        .then((response) => {
            return Promise.resolve(response.data);
        })
        .catch((err) => {
            return Promise.reject(err);
        });
}
function driverLicenceTypeWhite(arg) { // Sürücü Belgesi Türü
    return api.get("/common/GetDrivingLicenceTypeList", arg)
        .then((response) => {
            return Promise.resolve(response.data);
        })
        .catch((err) => {
            return Promise.reject(err);
        });
}
function programListWhite(positionGroupCode) { // Program Listesi
    const arg = {
        positionGroupCode: positionGroupCode
    };
    return api.post("/common/programs", arg)
        .then((response) => {
            return Promise.resolve(response.data);
        })
        .catch((err) => {
            return Promise.reject(err);
        });
}
function certificatesListWhite(positionGroupCode) { // Sertifika Listesi
    const arg = {
        positionGroupCode: positionGroupCode
    };
    return api.post("/common/certificates", arg)
        .then((response) => {
            return Promise.resolve(response.data);
        })
        .catch((err) => {
            return Promise.reject(err);
        });
}
function competencesListWhite(positionGroupCode) { // Yetkinlik Listesi
    const arg = {
        positionGroupCode: positionGroupCode
    };
    return api.post("/common/competences", arg)
        .then((response) => {
            return Promise.resolve(response.data);
        })
        .catch((err) => {
            return Promise.reject(err);
        });
}
function countryListWhite(arg) { // Ülke Listesi
    return api.get("/common/country", arg)
        .then((response) => {

            return Promise.resolve(response.data);
        })
        .catch((err) => {
            return Promise.reject(err);
        });
}
function cityListWhite(arg) { // Şehir Listesi
    return api.post("/common/city", arg)
        .then((response) => {
            return Promise.resolve(response.data);
        })
        .catch((err) => {
            return Promise.reject(err);
        });
}
function countyListWhite(arg) { // İlçe Listesi
    return api.post("/common/county", arg)
        .then((response) => {
            return Promise.resolve(response.data);
        })
        .catch((err) => {
            return Promise.reject(err);
        });
}
function districtListWhite(arg) { // Semt Listesi
    return api.post("/common/district", arg)
        .then((response) => {
            return Promise.resolve(response.data);
        })
        .catch((err) => {
            return Promise.reject(err);
        });
}
function salaryListWhite(arg) { // Maaş Aralığı Listesi
    return api.get("/common/GetSalarayRangeList", arg)
        .then((response) => {
            return Promise.resolve(response.data);
        })
        .catch((err) => {
            return Promise.reject(err);
        });
}
function businessTravelListWhite(arg) { // İş Seyehat Listesi
    return api.get("/common/GetBusinessTravelChoiceList", arg)
        .then((response) => {
            return Promise.resolve(response.data);
        })
        .catch((err) => {
            return Promise.reject(err);
        });
}
function languageListWhite(arg) { // Yabancı Dil Listesi
    return api.get("/common/GetLanguageList", arg)
        .then((response) => {
            return Promise.resolve(response.data);
        })
        .catch((err) => {
            return Promise.reject(err);
        });
}
function languageLevelListWhite(arg) { // Yabancı Dil Seviye Listesi
    return api.get("/common/GetLanguageLevelList", arg)
        .then((response) => {
            return Promise.resolve(response.data);
        })
        .catch((err) => {
            return Promise.reject(err);
        });
}
function universityListWhite(arg) { // Üniversite Listesi
    return api.get("/common/GetUniversityList", arg)
        .then((response) => {
            return Promise.resolve(response.data);
        })
        .catch((err) => {
            return Promise.reject(err);
        });
}
function facultyListWhite(arg) { // Fakülye Listesi
    return api.post("/common/GetFacultyList", arg)
        .then((response) => {
            // //console.log(response)
            return Promise.resolve(response.data);
        })
        .catch((err) => {
            return Promise.reject(err);
        });
}
function facultyDepartmentListWhite(arg) { // Bölüm Listesi
    return api.post("/common/GetFacultyDepartmentList", arg)
        .then((response) => {
            return Promise.resolve(response.data);
        })
        .catch((err) => {
            return Promise.reject(err);
        });
}
function whiteNewDemand(arg) { // Beyaz Yaka Yeni Talep Post
    //console.log("argWhite", arg)
    return api.post("/job/employee/create", arg)
        .then((response) => {
            console.log("apiden gelen veri ** * * ", JSON.stringify(response));
            
            return Promise.resolve(response);
        })
        .catch((err) => {
            return Promise.reject(err);
        });
}
function whiteUpdateDemand(arg) { // Beyaz Yaka Talep Güncelleme Post
    return api.post("/job/employee/update", arg)
        .then((response) => {
           
            return Promise.resolve(response);
        })
        .catch((err) => {
            return Promise.reject(err);
        });
}