import React, { useState, useEffect, useRef } from "react";
import { Form, InputGroup, Dropdown, Button } from "react-bootstrap";
import { DemandsServices } from "../../../../../../../../services";
import noMatchFound from "../../../../../../../../images/svg/noMatchFound.svg";
import { useSelector } from "react-redux";
import HireItem from "../HireItem";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import PopupLongMenu from "../../../../../../../widgets/PopupLongMenu";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import api from "../../../../../../../../utility/Utils";
import ShowEmployerJobSeekerDocuments from "../Common/ShowEmployerJobSeekerDocuments";
import MatchNotFound from "../../MatchShow/BlueMatchShow/MatchNotFound";
import PaginationPage from "../../../../../../../widgets/PaginationPage";
import LoadingDotsAnimation from "../../../../../../../widgets/LoadingDotsAnimation";
const ShowWhiteHire = ({ getDocument }) => {
  const { activeCurrentTab } = useSelector((state) => state.selectedHireTab);
  useEffect(() => {
    getHireWhite([{ status: 6 }]);
  }, []);
  const [search, setSearch] = useState("");
  const history = useHistory();
  const [candidateId, setCandidateId] = useState(null);

  const [demandHireWhite, setDemandHireWhite] = useState([]);
  function getHireWhite(statusHireWhite) {
    setDemandHireWhite(null)
    DemandsServices.demandMatchWhite(statusHireWhite).then(
      (demandHire) => {
        if (demandHire && demandHire.succeeded === true) {
          setDemandHireWhite([]);
          setDemandHireWhite(demandHire.data.pagedData);
        }
      },
      (error) => {}
    );
  }
  function searchHandleCandidate(value) {
    setSearch(value);
  }

  const [addedDocument, setAddedDocument] = useState(null);
  const [show, setShow] = useState(false);
  function addedDocumentApi() {
    setAddedDocument(null);

    api
      .get("employer/employmentdocs/" + candidateId)
      .then((response) => {
        setAddedDocument([]);
        if (response.succeeded == true) {
          if (response.data.length > 0) {
            setAddedDocument([]);
            response.data.map((value) => {
              setAddedDocument((prev) => [...prev, value]);
            });    setShow(true);
          } else {
            setAddedDocument([]);
            api
              .get("employer/hirechecklist/2")
              .then((response) => {
                if (response.succeeded == true) {
                  if (response.data.length > 0) {
                    setAddedDocument([]);
                    response.data.map((value) => {
                      setAddedDocument((prev) => [...prev, value]);
                    });
                     setShow(true);
                  } else {
                    setAddedDocument(null);
                     setShow(true);
                  }
                } else {
                  setAddedDocument(null);
                   setShow(true);
                }
              })
              .catch((err) => {
                setAddedDocument(false);
                 setShow(true);
              });
               
          }
        } else {
          setAddedDocument(null);
              setShow(true);
        }
      })
      .catch((err) => {
        setAddedDocument(false);
            setShow(true);
      });
  }
  const [openDialogSelectItem, setOpenDialogSelectItem] = useState(null);
  useEffect(() => {
    if (openDialogSelectItem) {
      addedDocumentApi();
    }
  }, [openDialogSelectItem]);
  const [hireItem, setHireItem] = useState(null);

  function handleClose() {
    setShow(false);
  }
  const [page, setPage] = useState(1);
  const pageSize = 10; // Her sayfada gösterilecek eleman sayısı

  const paginatedList =
    demandHireWhite && page === null
      ? demandHireWhite
      : demandHireWhite &&
        demandHireWhite.slice((page - 1) * pageSize, page * pageSize);

  return (
    <div>
      <ToastContainer
        position="top-right"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <div>
        <div className="">
          <InputGroup className="mb-3" style={{}}>
            <Form.Control
              style={{
                borderRadius: "20px",
                backgroundColor: "#F5F5F5",
                marginLeft: "10px",
              }}
              type="search"
              placeholder="İsme göre ara"
              value={search}
              onChange={(e) => searchHandleCandidate(e.target.value)}
            />
          </InputGroup>
        </div>

        {demandHireWhite === null ? (
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
            }}
          >
            <LoadingDotsAnimation />
          </div>
        ) : demandHireWhite && paginatedList && paginatedList.length > 0 ? (
          demandHireWhite && (
            <div
              style={{
                marginBottom: "10%",
                marginTop: "3%",
              }}
            >
              {paginatedList &&
                paginatedList.map((matchData, index) => {
                  return paginatedList &&
                    paginatedList[index].candidateNameSurname
                      .toLowerCase()
                      .includes(search.toLowerCase()) ? (
                    <div
                      style={{
                        position: "relative",
                      }}
                    >
                      <div
                        onClick={() => {
                          const dataMap = new Map();
                          dataMap.set("candidateId", matchData.candidateId);
                          dataMap.set("matchId", matchData.matchId);
                          dataMap.set("status", 6);
                          dataMap.set("cameWithoutMatchingPage", false);
                          history.push({
                            pathname: "/matchShow/whiteMatchDetail",
                            state: { dataMap },
                          });
                        }}
                      >
                        <HireItem
                          candidateNameSurname={matchData.candidateNameSurname}
                          positionName={matchData.positionName}
                          status={matchData.status}
                          candidateId={matchData.candidateId}
                        />
                      </div>
                      <div
                        onClick={() => {}}
                        style={{
                          position: "absolute",
                          top: 0,
                          right: 20,
                          width: 0,
                          height: 0,
                          cursor: "pointer",
                        }}
                      >
                        <div>
                          <PopupLongMenu
                            options={[
                              {
                                title: "İşe Giriş Evraklarını gönder",
                                id: 0,
                              },
                            ]}
                            onChanged={(value) => {
                              var dateTime = new Date(); //burdaki amaç candidateId ye bakarsam eğer aynı itemda dialog açıldığında use effect yenilenmiyor, bu yüzden dialog içinde eski veriler kalıyor.
                              setOpenDialogSelectItem(dateTime);
                              if (value) {
                                setCandidateId(null);
                                setCandidateId(matchData.candidateId);

                                if (
                                  value.onTapStatus == true &&
                                  value.id === 0
                                ) {
                                  setHireItem(matchData);
                                }
                              }
                            }}
                            icon={
                              <i className="fa-solid fa-ellipsis-vertical menu-icon-demand"></i>
                            }
                            iconItem={<i class="fa-solid fa-file-invoice"></i>}
                          />
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div></div>
                  );
                })}
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                {demandHireWhite &&
                  demandHireWhite.length > 0 &&
                  demandHireWhite.length > pageSize && (
                    <PaginationPage
                      items={demandHireWhite}
                      page={page}
                      pageSize={pageSize}
                      onChange={(value) => {
                        if (value) {
                          setPage(value);
                        }
                      }}
                      onTapAllShowButton={() => {
                        setPage(null);
                      }}
                    />
                  )}
              </div>
            </div>
          )
        ) : (
          <MatchNotFound title={"İşe Alınanlar Bulunamadı"} />
        )}
      </div>

      <ShowEmployerJobSeekerDocuments
        hireItem={hireItem}
        positionType={2}
        show={show}
        handleClose={handleClose}
        getDocument={getDocument}
        addedDocument={addedDocument}
        approval={(value) => {
          if (value) {
            if (value === true) {
              handleClose();
            }
          }
        }}
      />
    </div>
  );
};

export default ShowWhiteHire;
