import { UserConstants } from '../constants';
import {getToken, getUser} from '../configs/cookie';

let token = getToken();
let user = getUser();
const defaultState = { isLoading: false, isNewRegister: false, loggedIn: false, token: false, user: (user) ? JSON.parse( user ) : false };
function setState(changes) {
    return { ...defaultState, ...changes };
}

let initialState = token ? setState({loggedIn: true, token: token}) :defaultState;

export function authentication(state = initialState, action) {
    switch (action.type) {
        case UserConstants.REGISTER_REQUEST:
            return setState({isLoading: true});
        case UserConstants.LOGIN_REQUEST:
            return setState({isLoading: true});
        case UserConstants.REGISTER_SUCCESS:
            return setState({isNewRegister: true});
        case UserConstants.LOGIN_SUCCESS:
            return setState({loggedIn: true, user: false, token: action.token});
        case UserConstants.OWN_SUCCESS:
            return setState({loggedIn: true, user: action.user});
        case UserConstants.LOGIN_FAILURE:
            return setState({loggedIn: false, user: false, token: false});
        case UserConstants.LOGOUT:
            return setState({loggedIn: false, user: false, token: false});
        default:
            return state
    }
}

       
