import React, { useState } from "react";
import { Card, Tab, Nav } from "react-bootstrap";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ShowEmployerDemandRequestsVideoMeets from "./ShowEmployerDemandRequestsVideoMeets";
import ShowEmployerDemandSendsVideoMeets from "./ShowEmployerDemandSendsVideoMeets";
import { useDispatch, useSelector } from "react-redux";
import { setDemandConferencesMeetTab } from "../../../../../../../redux/redux-toolkit/demandConferencesMeet";
import BitonisTabBar from "../../../../../../widgets/BitonisTabBar/BitonisTabBar";
import PageInfoTitle from "../../../../../../widgets/PageInfoTitle";
import PrevIconButton from "../../../../../../widgets/buttons/PrevIconButton";

const EmployerDemandConferencesMeet = () => {


  const dispatch = useDispatch();
  const { activeCurrentTab } = useSelector(
    (state) => state.demandConferencesMeet
  );
  const selectedChangeTab = (select) => {
    dispatch(setDemandConferencesMeetTab(select));
  };



  return (
    <div
      id="matchShow"
      style={{
        position: "relative",
      }}
    >
      <ToastContainer
        position="top-right"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />

    
      <PageInfoTitle title={"Video Konferans Talep Ettiğim Adaylar"} />

      <div className="row">
        <div className="col-md-12">
          <Card>
            <Card.Body>
              <BitonisTabBar
                tabLeftTitle={"Video Konferans İstediklerim"}
                tabRightTitle={"Video Konferans Onaylayanlar"}
                tabLeftBody={<ShowEmployerDemandRequestsVideoMeets />}
                tabRightBody={<ShowEmployerDemandSendsVideoMeets />}
                showBorder={false}
                activeTab={activeCurrentTab}
                tabStyle={{
                  fontSize: "0.7rem",
                }}
                onChangeTab={(tab) => {}}
              />
            </Card.Body>
          </Card>
        </div>
      </div>
    </div>
  );
};

export default EmployerDemandConferencesMeet;
