import React, { useState } from "react";
import "../../../../../../../../../css/bitoniscss/videocv.css";
import moment from "moment";

const AdditionalWaitingVideoCvItem = ({ matchData }) => {
  return (
    <div>
      <div className="card-body cardItem" key={matchData.candidateId}>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignContent: "space-between",
            alignItems: "center",
            marginLeft: "0px",
          }}
        >
          <span className="fa-stack fa-lg">
            <i class="fa-solid fa-user"></i>
          </span>
          <div className="candidate-name">{matchData.candidateNameSurname}</div>
        </div>
        <td>
          <div className="position-name">{matchData.positionName}</div>
        </td>
        <div className="item">
          <div className="waitingVideoCv-match">Ek Video Cv Bekleniyor</div>
        </div>
        <div className="triangle-waitingVideoCv-match"></div>{" "}
      </div>
    </div>
  );
};

export default AdditionalWaitingVideoCvItem;
