;
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  activeCurrentTab: 0,
};
const demandConferencesMeet = createSlice({
  name: "demandConferencesMeet",
  initialState,
  reducers: {
    setDemandConferencesMeetTab: (state, action) => {
      state.activeCurrentTab = action.payload;
    },
  },
});

export const { setDemandConferencesMeetTab } = demandConferencesMeet.actions;
export default demandConferencesMeet.reducer;
