import React, { useEffect, useState } from "react";
import { DemandsServices } from "../../../../../../services";
import Select from "react-select";
import { useForm, Controller } from "react-hook-form";
import { customStylesMulti } from "../../../../../customStyleInline";
import { Button } from "react-bootstrap";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { gamificationGivenStarReasonList } from "./GivenStarTypeEnum";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { useDispatch } from "react-redux";
import CustomDesign from "../../../../../widgets/CustomDesign";
import BitonisSingleDropdown from "../../../../../widgets/BitonisSingleDropdown";

const EmployerGivenToBitonis = ({ candidateId,
  givenStarType,
  handleClose,
  activeCurrentTab,
}) => {
  const history = useHistory();
  const dispatch = useDispatch();

  const [givenStarDescriptionList, setGivenStarDescriptionList] = useState([]);
  const [starIndex, setStarIndex] = useState(-1);
  const [selectWhyGivenStar, setSelectWhyGivenStar] = useState(null);

  const handleStarClick = (index) => {
    setStarIndex(index === starIndex ? -1 : index);
  };

  useEffect(() => {
    getGivenStarList();
  }, [activeCurrentTab]);

  function getGivenStarList() {
    setGivenStarDescriptionList([]);
    DemandsServices.getGivenStarList(3).then(
      (givenStarResponse) => {
        if (givenStarResponse && givenStarResponse.succeeded === true) {
          //description
          //id
          if (givenStarResponse) {
            givenStarResponse.data.map((value) => {
              setGivenStarDescriptionList((prev) => [...prev, value]);
            });
          }
        }
      },
      (error) => { }
    );
  }
  const {
    register,
    handleSubmit,
    control,
    watch,
    reset, getValues,
    setValue,
    formState: { errors, isValid },
  } = useForm();

  function employerGiveToStar(reason, rotation, starGiverType) {
    var sendData = {
      giveTo: 3,
      star: starIndex + 1,
      reason: reason,
      rotation: rotation,
      gamificationGivenStarDescriptionId: selectWhyGivenStar,
      starGiverType: starGiverType,
    };
    DemandsServices.sendGivenStar(sendData).then(
      (sendGivenStarResponse) => {
        if (sendGivenStarResponse) {
          if (sendGivenStarResponse.succeeded === true) {
            handleClose();
            toast.success("Değerlendirme Başarılı Şekilde Gönderildi", {
              autoClose: 2000,
              onClose: () => {
                setTimeout(() => {
                }, 100);
              },
            });
          } else {
            toast.info(sendGivenStarResponse.error.message, {
              autoClose: 2000,
              onClose: () => {
                setTimeout(() => { }, 100);
              },
            });
          }
        }
      },
      (error) => {
        toast.info("Beklenmedik Hata Oluştu", {
          autoClose: 2000,
          onClose: () => {
            setTimeout(() => { }, 100);
          },
        });
      }
    );
  }

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
      }}
    >
      <ToastContainer
        position="top-right"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <div
        style={{
          backgroundColor: "#F7F7F7",
          fontSize: "10px",
          margin: "10px",
          borderRadius: "8px",
          display: "flex",
          flexDirection: "row",
          alignContent: "center",
          alignItems: "center",
          padding: "0px 0px 10px 0px",
          width: "90%",
        }}
      >
        <p
          style={{
            padding: "10px 5px 0px 5px",
            color: "#001542",
            margin: "0px",
            fontSize: "12px",
          }}
        >
          {" "}
          <i
            class="fa-regular fa-star"
            style={{
              paddingLeft: "8px",
              paddingRight: "5px",
              fontSize: "15px",
              color: "orange",
            }}
          ></i>
          Bitoniş'i
          <h8
            style={{
              color: "orange",
              fontWeight: "600px",
              marginLeft: "5px",
            }}
          >
            Değerlendir ve Geri Bildirimde Bulun
          </h8>
        </p>
      </div>

      <div
        style={{
          padding: "10px 5px 0px 13px",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignContent: "center",
            alignItems: "center",
          }}
        >
          <div
            style={{
              height: "60px",
              width: "320px",
              display: "flex",
              flexDirection: "row",
              marginTop: "10px"
            }}
          >
            {[...Array(5).keys()].map((index) => (
              <div key={index} style={{ padding: "8px" }}>
                <div onClick={() => handleStarClick(index)}>
                  {index <= starIndex ? (
                    <i
                      class="fa-solid fa-star"
                      style={{ color: "orange", fontSize: "30px" }}
                    ></i>
                  ) : (
                    <i
                      className="fa-regular fa-star"
                      style={{ color: "orange", fontSize: "30px" }}
                    ></i>
                  )}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>


      <CustomDesign
        label={"Bitoniş'i Değerlendir"}
        required={true}
        isColumn={true}
        child={
          <BitonisSingleDropdown
            items={
              givenStarDescriptionList &&
              givenStarDescriptionList.map(({ id, description }) => ({
                value: id,
                label: description,
              }))
            }
            register={register}
            watch={watch}
            companyClassName={"givenStar"}
            control={control}
            setValue={setValue}
            errors={errors}
            errorMessage={"Değerlendirme Boş Geçilemez"}
            placeholder="Değerlendirin"
            onValueChanged={(e) => {
              console.log(e);
              if (e) {
                setSelectWhyGivenStar(e.value);
              }
            }}
            required={true}
            getValues={getValues}
          />
        }
      />
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignContent: "center",
          alignItems: "center",
          marginTop:"15px"
        }}
      >
        <Button
          as="input"
          type="button"
          value="Değerlendir"
          onClick={(val) => {
            val.preventDefault();
            if (starIndex == -1) {
              toast.error("Değerlendirme Boş Geçilemez", {
                autoClose: 5000,
                onClose: () => {
                  setTimeout(() => { }, 1000);
                },
              });
            } else {
              if (
                selectWhyGivenStar === null ||
                selectWhyGivenStar === undefined ||
                selectWhyGivenStar === ""
              ) {
                toast.error("Değerlendirme Detayı Boş Geçilemez", {
                  autoClose: 5000,
                  onClose: () => {
                    setTimeout(() => { }, 1000);
                  },
                });
              } else {
                let reason;
                gamificationGivenStarReasonList.forEach((element) => {
                  if (element.type.toString() === givenStarType.toString()) {
                    reason = element.id;
                  }
                });
                if (reason) {
                  employerGiveToStar(reason, 3, 1);
                }
              }
            }
          }}
          style={{
            borderRadius: "8px",
            fontSize: "15px",
            padding: "15px",
            width: "300px",
            marginTop: "0px",
            cursor: "pointer",
            transition: "background-color 0.3s",
          }}
        />
      </div>
    </div>
  );
};

export default EmployerGivenToBitonis;
