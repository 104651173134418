import React, { useEffect, useState } from "react";

import Modal from "react-bootstrap/Modal"; // Varsayılan olarak React Bootstrap Modal bileşeni kullanılıyor
import { Alert, Button } from "react-bootstrap";
import svgDemand from "../../../../../../../../images/svg/demandSvg3.svg";
function DemandCopyApproved({
  show,
  handleClose,
  approval,
  demandItem,
  isOnIntern,
}) {
  return (
    <>
      <div>
        <MyVerticallyCenteredModal
          show={show}
          onHide={() => handleClose(false)}
          approval={approval}
          demandItem={demandItem}
          isOnIntern={isOnIntern}
        />
      </div>
    </>
  );
}
function MyVerticallyCenteredModal(props) {
  const { show, approval, demandItem, isOnIntern } = props;
  return (
    <Modal
      {...props}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      dialogClassName="full-screen-modal"
    >
      <Modal.Header
        closeButton
        style={{ borderBottom: "none", justifyContent: "center" }}
      >
        <Modal.Title aria-labelledby="contained-modal-title-vcenter" centered>
          <h3 style={{ color: "#001542", fontSize: "20px" }}></h3>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body
        style={{
          margin: "0px",
          padding: "0px",
        }}
      >
        <div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignContent: "center",
              alignItems: "center",
            }}
          >
            <img
              src={svgDemand}
              style={{
                height: "130px",
                textAlign: "center",
              }}
            />
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              alignContent: "center",

              color: "#001542",
              marginTop: "5px",
              textAlign: "center",
            }}
          >
            <p
              style={{
                fontSize: "15px",
                marginRight: "5px",
                fontWeight: "bold",
                marginTop: "0px",
                paddingTop: "0px",
                marginBottom: "0px",
                paddingBottom: "0px",
              }}
            >
              {isOnIntern && isOnIntern == true
                ? demandItem && demandItem.departmentName
                : demandItem && demandItem.positionName}
            </p>
            <p
              style={{
                fontSize: "14px",
                marginRight: "5px",

                marginTop: "0px",
                paddingTop: "0px",
              }}
            >
              Talebinizi Kopyalamak İstediğinizden Emin misiniz?{" "}
            </p>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          alignContent: "center",
          borderTop: "none",
        }}
      >
        <Button
          as="input"
          type="button"
          value="Kopyala"
          onClick={() => {
            approval(true);
          }}
          style={{
            borderRadius: "8px",
            fontSize: "15px",
            padding: "15px",
            width: "300px",
            cursor: "pointer",
            transition: "background-color 0.3s",
            backgroundColor: "#001542",
            border: "none",
          }}
        />
      </Modal.Footer>
    </Modal>
  );
}
export default DemandCopyApproved;
