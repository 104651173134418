import React, { useEffect, useState } from "react";
import { DropListWhiteAction } from "../../../../../../../../action";
import EditEducationModal from "./Modal/EditEducationModal";
import AddEducationModal from "./Modal/AddEducationModal";
import AddEducationAndEduType from "./Modal/AddEducationAndEduType";
import CustomPreview from "../../../../../../../widgets/CustomPreview";
import EditIconWidget from "../../../../../../../widgets/EditIconWidget";
import { Box } from "@mui/material";

const EducationDemandDetail = ({
  register,
  onSubmit,
  handleSubmit,
  inputStyle,
  cardStyle,
  job,
  props,
  isValid,
  control,
  setValue,
  errors,
  watch,
  labelStyle,
  educationListRef,
  facultyDepAndGroupIdsRef,
  reset,
  getValues,
}) => {
  useEffect(() => {
    if (!props.universityListWhite.success) {
      props.dispatch(
        DropListWhiteAction.universityListWhite({
          positionType: 2,
          isActive: true,
        })
      );
    }
    if (!props.facultyListWhite.success) {
      props.dispatch(
        DropListWhiteAction.facultyListWhite({
          positionType: 2,
          isActive: true,
        })
      );
    }
    if (!props.facultyDepartmentListWhite.success) {
      props.dispatch(
        DropListWhiteAction.facultyDepartmentListWhite({
          positionType: 2,
          isActive: true,
        })
      );
    }
  }, []);
  useEffect(() => {
    educationListRef.current = [];
    if (props.job.job.jobPreferredUniversities) {
      const jobPreferredUniversities = props.job.job.jobPreferredUniversities;

      const newEdu = jobPreferredUniversities.map((edu) => {
        return {
          facultyId: edu.facultyId,
          universityId: edu.universityId,
          universityName: edu.universityName,
          facultyDepAndGroupIds: edu.facultyDepartmentAndGroupResponse,
        };
      });

      educationListRef.current = [...educationListRef.current, ...newEdu];
    }
  }, props.job.job.jobPreferredUniversities);
  const [showEdit, setShowEdit] = useState(null);
  const [showAdd, setShowAdd] = useState(null);
  const [showTypeAdd, setShowTypeAdd] = useState(null);
  function handleEditClose() {
    setShowEdit(false);
  }
  function handleAddClose() {
    setShowAdd(false);
  }
  function handleAddTypeClose() {
    setShowTypeAdd(false);
  }
  const [education, setEducation] = useState(null);
  const [visibleEducationType, setVisibleEducationType] = useState(false);

  const [eduType, setEduType] = useState(null);
  const [indexEdu, setIndexEdu] = useState(null);
  useEffect(() => {
    if (props.job.job.jobEducations[0].educationType) {
      if (
        (props.job.job.jobEducations[0].educationType &&
          props.job.job.jobEducations[0].educationType == 4) ||
        (props.job.job.jobEducations[0].educationType &&
          props.job.job.jobEducations[0].educationType === 5) ||
        (props.job.job.jobEducations[0].educationType &&
          props.job.job.jobEducations[0].educationType === 6) ||
        (props.job.job.jobEducations[0].educationType &&
          props.job.job.jobEducations[0].educationType === 7)
      ) {
        setEduType(false);
      } else {
        setEduType(true);
      }
    }
  }, [props.job.job.jobEducations[0].educationType]);

  return (
    <div>
      <>
        <div class="card" style={cardStyle}>
          {props.job.job.jobEducations[0] &&
            props.job.job.jobEducations[0].educationType > 4 && (
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "flex-end",
                  alignItems: "center",
                  marginTop: "0px",
                  paddingTop: "0px",
                  marginBottom: "0px",
                  marginRight: "10px",
                  padding: "0px",
                }}
              >
                <div
                  style={{
                    width: "30px",
                    height: "30px",
                    margin: "0px 5px 0px 0px",
                    padding: "0px",
                  }}
                >
                  <div
                    style={{
                      backgroundColor: "green",
                      borderRadius: "6px",
                      width: "30px",
                      height: "30px",
                      alignItems: "center",
                      display: "flex",
                      justifyContent: "center",
                      transition: "fontSize 0.2s",
                    }}
                    onMouseOver={(e) => {
                      e.currentTarget.style.width = "35px";
                      e.currentTarget.style.height = "35px";
                    }}
                    onMouseOut={(e) => {
                      e.currentTarget.style.width = "30px";
                      e.currentTarget.style.height = "30px";
                    }}
                    onClick={() => {
                      setShowAdd(true);
                    }}
                  >
                    <i
                      class="fa-solid fa-plus"
                      style={{
                        transition: "fontSize 0.2s",
                        color: "white",
                      }}
                    ></i>
                  </div>
                </div>
                <div
                  style={{
                    color: "#001542",
                    fontWeight: "550",
                    marginLeft: "6px",
                  }}
                >
                  Yeni Eğitim Ekle
                </div>
              </div>
            )}

          <div
            class="card-header"
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
              borderBottom: "none",
              marginBottom: "0px",
              paddingBottom: "0px",
            }}
          >
            <h5 class="card-title">Eğitim</h5>
          </div>
          <div class="card-body">
            <div
              style={{
                display: "flex",
                flexDirection: "column",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "end",
                }}
              >


                <Box
                  sx={{
                    position: "relative",


                    display: "flex",
                    alignItems: "center",
                    paddingBottom: "5px",
                    paddingRight: "13px",
                    marginTop:"0px",
                    paddingTop:"0px"

                  }}
                >
                  <EditIconWidget
                    size={20}
                    onTap={() => {
                      setShowTypeAdd(true);
                    }}
                    showContainerEdit={false}
                    showEditText={false}
                  />
                </Box>



              </div>
              <CustomPreview
                name={"Eğitim Durumu"}
                value={props.job.job.jobEducations
                  .map((val) => val.description)
                  .join(", ")}
              />{" "}
            </div>
            {educationListRef.current &&
              educationListRef.current.map((edu, index) => {
                return (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      margin: "0px",
                      padding: "0px",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "end",
                        marginBottom: "0px",
                        marginRight: "10px",
                        marginTop: "10px",
                      }}
                    >


                      <Box
                        sx={{
                          position: "relative",
                          display: "flex",
                          alignItems: "center",
                          paddingBottom: "5px",
                          paddingRight: "3px",


                        }}
                      >
                        <EditIconWidget
                          size={20}
                          onTap={() => {
                            if (edu) {
                              setEducation(edu);
                              setShowEdit(true);
                              setIndexEdu(index);
                            }
                          }}
                          showContainerEdit={false}
                          showEditText={false}
                        />
                      </Box>


                    </div>

                    <CustomPreview
                      name={"Üniversite"}
                      value={edu.universityName}
                    />
                    <CustomPreview
                      name={"Bölüm"}
                      value={
                        edu.facultyDepAndGroupIds[0] &&
                        edu.facultyDepAndGroupIds[0].facultyDepartmentName
                      }
                    />

                    {index !== educationListRef.current.length - 1 && (
                      <hr
                        style={{
                          borderTop: "1px dotted lightgrey",
                          margin: "0px",
                          padding: "0px",
                          marginRight: "15px",
                        }}
                      ></hr>
                    )}
                  </div>
                );
              })}
          </div>
        </div>
        <EditEducationModal
          show={showEdit}
          handleClose={handleEditClose}
          job={job.job}
          onSubmit={onSubmit}
          props={props}
          education={education}
          isValid={isValid}
          register={register}
          handleSubmit={handleSubmit}
          control={control}
          setValue={setValue}
          errors={errors}
          watch={watch}
          labelStyle={labelStyle}
          indexEdu={indexEdu}
          educationListRef={educationListRef}
          facultyDepAndGroupIdsRef={facultyDepAndGroupIdsRef}
          reset={reset}
          getValues={getValues}
        />
        <AddEducationModal
          show={showAdd}
          handleClose={handleAddClose}
          job={job.job}
          onSubmit={onSubmit}
          props={props}
          inputStyle={inputStyle}
          education={education}
          isValid={isValid}
          register={register}
          handleSubmit={handleSubmit}
          control={control}
          setValue={setValue}
          errors={errors}
          watch={watch}
          labelStyle={labelStyle}
          educationListRef={educationListRef}
          facultyDepAndGroupIdsRef={facultyDepAndGroupIdsRef}
          reset={reset}
          getValues={getValues}
        />
        <AddEducationAndEduType
          show={showTypeAdd}
          handleClose={handleAddTypeClose}
          job={job.job}
          onSubmit={onSubmit}
          props={props}
          inputStyle={inputStyle}
          education={education}
          isValid={isValid}
          register={register}
          handleSubmit={handleSubmit}
          control={control}
          setValue={setValue}
          errors={errors}
          watch={watch}
          labelStyle={labelStyle}
          educationListRef={educationListRef}
          visibleEducationType={visibleEducationType}
          facultyDepAndGroupIdsRef={facultyDepAndGroupIdsRef}
          reset={reset}
          getValues={getValues}
        />
      </>
    </div>
  );
};

export default EducationDemandDetail;
