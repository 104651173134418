import api from "../utility/Utils";

export const ProfileDetailServices = {
  companyDetail,
  companyMainDetail,
  companyProfileUpdate,
  companyWidthAddedRoutesGetCompanines,
  companyCheckMainDetail,
  companyInfo,
  couponCheck,
  getCouponList,
  pay,
};







function companyWidthAddedRoutesGetCompanines() {
  //Şirket e Eklenmiş Güzergah Bilgileri
  return api
    .get("/company/preferredlocationlist")
    .then((response) => {
      return Promise.resolve(response.data);
    })
    .catch((err) => {
      return Promise.reject(err);
    });
}


// Mavi Yaka Bilgiler

function companyDetail() { //Şirket Bilgileri
    return api.get("/company")
        .then((response) => {
            return Promise.resolve(response.data);
        })
        .catch((err) => {
            return Promise.reject(err);
        });
}

function companyInfo() {

  return api
    .get("/employer")
    .then((response) => {
      return Promise.resolve(response.data);
    })
    .catch((err) => {
      return Promise.reject(err);
    });
}
function companyMainDetail() { //Firmanın Ana Şirket Bilgileri
    return api.get("/company/main")
      .then((response) => {
            return Promise.resolve(response.data);
        })
        .catch((err) => {
            return Promise.reject(err);
        });
}

function companyCheckMainDetail() {
  //Firmanın Ana Şirket Bilgileri
  return api
    .get("/company/main")
    .then((response) => {
      return Promise.resolve(response);
    })
    .catch((err) => {
      return Promise.reject(err);
    });
}
function companyProfileUpdate(arg) {
  return api
    .post("/company/update", arg)
    .then((response) => {
      return Promise.resolve(response);
    })
    .catch((err) => {
      return Promise.reject(err);
    });
}

function couponCheck(arg) {
  return api
    .post("/payment/coupon-detail", arg)
    .then((response) => {
      return Promise.resolve(response);
    })
    .catch((err) => {
      return Promise.reject(err);
    });
}



function getCouponList() {
  //Firmanın kuponları
  return api
    .get("/payment/coupon-list")
    .then((response) => {
      return Promise.resolve(response);
    })
    .catch((err) => {
      return Promise.reject(err);
    });
}



function pay() {
  //ödeme son hal yen eklendi 3d
  return api
    .get("/")
    .then((response) => {
      return Promise.resolve(response);
    })
    .catch((err) => {
      return Promise.reject(err);
    });
}