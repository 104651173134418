import React, { useEffect, useState } from "react";

import Modal from "react-bootstrap/Modal"; // Varsayılan olarak React Bootstrap Modal bileşeni kullanılıyor
import { Alert, Button } from "react-bootstrap";
import { DemandsServices } from "../../../../../../../../services";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const ShowEmployerJobSeekerDocuments = ({
  show,
  handleClose,
  positionType,
  hireItem,
  getDocument,
  addedDocument,
  approval,
}) => {
  return (
    <>
      <div>
        <MyVerticallyCenteredModal
          show={show}
          onHide={() => handleClose(false)}
          positionType={positionType}
          hireItem={hireItem}
          getDocument={getDocument}
          addedDocument={addedDocument}
          approval={approval}
        />
      </div>
    </>
  );
};

function MyVerticallyCenteredModal(props) {
  const { show, positionType, hireItem, getDocument, addedDocument, approval } =
    props;

  const [selectedItems, setSelectedItems] = useState([]);

  const toggleSelection = (item) => {
    if (selectedItems.some((selectedItem) => selectedItem.id === item.id)) {
      setSelectedItems((prev) =>
        prev.filter((selectedItem) => selectedItem.id !== item.id)
      );
    } else {
      setSelectedItems([...selectedItems, item]);
    }
  };
  useEffect(() => {
    if (show) {
      setSelectedItems([]);
      if (addedDocument == null || addedDocument == false) {
      } else {
        if (addedDocument.length > 0) {
          addedDocument.map((value) => {
            var val = {
              code: value.hiringDocument.code,
              id: value.hiringDocument.id,
              name: value.hiringDocument.name,
            };

            setSelectedItems((prev) => [...prev, val]);
          });
        }
      }
    }
  }, [show]);
  function sendDocumentCandidate(selectedDocumentList, hireItem) {
    var hiringDocumentIds = [];
    selectedDocumentList.map((value) => {
      hiringDocumentIds.push(parseInt(value.id.toString()));
    });
    var sendData = {
      hiringDocumentId: hiringDocumentIds,
      positionType: positionType,
      candidateId: parseInt(hireItem.candidateId.toString()),
      matchId: parseInt(hireItem.matchId.toString()),
    };
    DemandsServices.sendCandidateDocument(sendData).then(
      (resData) => {
        if (resData) {
          if (resData && resData.succeeded === true) {
            toast.success("İşe Giriş Evrakları İş Arayana Gönderildi", {
              autoClose: 2000,
              onClose: () => {
                setTimeout(() => {}, 100);
              },
            });
          } else {
            toast.info(resData.error.message, {
              autoClose: 2000,
              onClose: () => {
                setTimeout(() => {}, 100);
              },
            });
          }
        } else {
          toast.info("Hata oluştu", {
            autoClose: 2000,
            onClose: () => {
              setTimeout(() => {}, 100);
            },
          });
        }
      },
      (error) => {
        toast.info("Hata oluştu", {
          autoClose: 2000,
          onClose: () => {
            setTimeout(() => {}, 100);
          },
        });
      }
    );
  }
  return (
    <Modal
      {...props}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      dialogClassName="full-screen-modal"
    >
      <Modal.Header
        closeButton
        style={{
          borderBottom: "none",
          paddingBottom: "0px",
          marginBottom: "0px",
          textAlign: "center",
        }}
      >
        <Modal.Title
          aria-labelledby="contained-modal-title-vcenter"
          style={{
            width: "100%",
            textAlign: "center",
            margin: "0 auto",
          }}
        >
          İşe Giriş Evraklarını Gönder
        </Modal.Title>
      </Modal.Header>
      <Modal.Body
        style={{
          marginTop: "0px",
          paddingTop: "5px",
        }}
      >
        <div>
          {getDocument &&
            getDocument.map((item, index) => (
              <div key={index} style={{ paddingLeft: 5 }}>
                <button
                  onClick={() => toggleSelection(item)}
                  style={{
                    color: "#001542",
                    padding: 10,
                    outline: "none",
                    border: "none",
                    margin: "5px",
                    borderRadius: "8px",
                    width: "100%",
                    display: "flex",
                    justifyContent: "start",
                  }}
                >
                  <input
                    type="checkbox"
                    checked={selectedItems.some(
                      (selectedItem) => selectedItem.id === item.id
                    )}
                    onChange={() => toggleSelection(item)}
                  />

                  <span
                    style={{
                      fontSize: 13,
                      fontWeight: 600,
                      color: "#001542;",
                      paddingLeft: "20px",
                    }}
                  >
                    {item.name}
                  </span>
                </button>
              </div>
            ))}
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            alignContent: "center",
            borderTop: "none",
            marginTop: "5px",
            paddingTop: "5px",
          }}
        >
          <Button
            as="input"
            type="button"
            value="Gönder"
            onClick={(value) => {
              value.preventDefault();
              sendDocumentCandidate(selectedItems, hireItem);
              approval(true);
            }}
            style={{
              borderRadius: "8px",
              fontSize: "15px",
              padding: "15px",
              width: "300px",
              cursor: "pointer",
              transition: "background-color 0.3s",
              backgroundColor: "#001542",
              border: "none",
            }}
          />
        </div>
      </Modal.Body>
    </Modal>
  );
}
export default ShowEmployerJobSeekerDocuments;
