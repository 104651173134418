import React, { useEffect, useState } from "react";
import { Controller } from "react-hook-form";
import Select from "react-select";
import { connect } from "react-redux";
import { DropListWhiteAction } from "../../../../../../../action";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import addEducation from "../../Function/educationmultiAdd";
import api from "../../../../../../../utility/Utils";
import EducationAddModal from "../Modal/EducationAddModal";
import DeleteEducationModal from "../Modal/DeleteEducationModal";
import UniItem from "../widgets/UniItem";
import BitonisMultiDropdown from "../../../../../../widgets/BitonisMultiDropdown";
import BitonisSingleDropdown from "../../../../../../widgets/BitonisSingleDropdown";
import CustomDesign from "../../../../../../widgets/CustomDesign";

const FindWhiteEducations = (props) => {
  const {
    register,
    handleSubmit,
    control,
    watch,
    reset,
    setValue,
    errors,
    submitNewVersion,
    companyName,
    companyLocationName,
    customStyles,
    customStylesMulti,
    labelStyle,
    university,
    setUniversity,
    section,
    setSection,
    sectionList,
    setSectionList,
    departmentGroupId,
    setDepartmentGroupId,
    educationList,
    setEducationList,
    facultyDepAndGroupIdsRef,
    facultyDepAndGroupIdsRefTemp,
    getValues,
  } = props;
  const [showEduDelete, setShowEduDelete] = useState(null);
  const [indexItem, setIndexItem] = useState(null);
  function eduDeleteHandleClose() {
    setShowEduDelete(false);
  }
  useEffect(() => {
    setValue("universityId", "");
    setValue("facultyDepartmentId", "");
    if (!props.universityListWhite.success) {
      props.dispatch(
        DropListWhiteAction.universityListWhite({
          positionType: 2,
          isActive: true,
        })
      );
    }
    if (!props.facultyListWhite.success) {
      props.dispatch(
        DropListWhiteAction.facultyListWhite({
          positionType: 2,
          isActive: true,
        })
      );
    }
    if (!props.facultyDepartmentListWhite.success) {
      props.dispatch(
        DropListWhiteAction.facultyDepartmentListWhite({
          positionType: 2,
          isActive: true,
        })
      );
    }
  }, []);

  function departmentListGetsNotFaculty() {
    //üniversite id ==233 ise bu api çağrılır.
    setSectionList([]);
    api
      .get("common/FacultyDepartmentGroupList")
      .then((response) => {
        //  alert("gelen bölümler " + JSON.stringify(response));
        setSectionList(response.data);
      })
      .catch((err) => {
        setSectionList([]);
      });
  }

  function facultyDepartmentListGets(universyId) {
    setSectionList([]);
    //üniversite id ==233 değil ise bu api çağrılır.
    let id = {
      universityId: universyId.toString(),
    };
    api
      .post("/common/UniversityFacultyDepartmentList", id)
      .then((response) => {
        //  alert("gelen bölümler " + JSON.stringify(response));
        setSectionList(response.data);
      })
      .catch((err) => {
        setSectionList([]);
      });
  }
  const [showAddEducation, setShowAddEducation] = useState(false);
  function handleEduClose() {
    setShowAddEducation(false);
  }

  const [eduItem, setEduItem] = useState(null);

  function deleteEducation() {
    const getEducationList = [...educationList];
    const indexToRemove = indexItem; // Kaldırmak istediğiniz indeks

    if (indexToRemove >= 0 && indexToRemove < getEducationList.length) {
      const updatedEducationList = getEducationList.reduce(
        (acc, current, index) => {
          if (index !== indexToRemove) {
            acc.push(current);
          }
          return acc;
        },
        []
      );
      setEducationList(updatedEducationList);
      // Silme işlemi tamamlandığında kullanıcıya bir bildirim göstermek için toast veya benzeri bir kütüphane kullanabilirsiniz.
      toast.success("Eğitim başarıyla silindi.");
      setValue("universityId", "");
      setValue("facultyDepartmentId", "");
    } else {
    }
  }

  return (
    <div style={{ marginBottom: "20px" }}>
      <>
        <ToastContainer
          position="top-right"
          autoClose={2000}
          hideProgressBar={false}
          newestOnTop
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
        <div
          style={{
            margin: "0px",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-end",
              alignItems: "center",
              marginBottom: "20px",
              marginRight: "10px",
            }}
          >
            <div
              style={{
                width: "30px",
                height: "30px",
                margin: "0px 5px 0px 0px",
                padding: "0px",
              }}
            >
              <div
                style={{
                  backgroundColor: "green",
                  borderRadius: "6px",
                  width: "30px",
                  height: "30px",
                  alignItems: "center",
                  display: "flex",
                  justifyContent: "center",
                  transition: "fontSize 0.2s",
                }}
                onMouseOver={(e) => {
                  e.currentTarget.style.width = "35px";
                  e.currentTarget.style.height = "35px";
                }}
                onMouseOut={(e) => {
                  e.currentTarget.style.width = "30px";
                  e.currentTarget.style.height = "30px";
                }}
                onClick={() => {
                  //eğitim eğer bir tane ise ekleyip inputlar gizlenir ve
                  //yeni eklenecek eğitim dialog ile yapılmış olur.
                  var facultyDepartmentId = watch("facultyDepartmentId");
                  if (
                    university &&
                    facultyDepAndGroupIdsRefTemp.current &&
                    facultyDepartmentId
                  ) {
                    addEducation(
                      university,
                      setUniversity,
                      section,
                      setSection,
                      departmentGroupId,
                      setDepartmentGroupId,
                      setEducationList,
                      educationList,
                      setValue,
                      facultyDepAndGroupIdsRef,
                      facultyDepAndGroupIdsRefTemp
                    );
                  }

                  setShowAddEducation(true);
                }}
              >
                {" "}
                <i
                  class="fa-solid fa-plus"
                  style={{
                    transition: "fontSize 0.2s",
                    color: "white",
                  }}
                ></i>
              </div>
            </div>
            <div
              style={{
                color: "#001542",
                fontWeight: "550",
                marginLeft: "6px",
              }}
            >
              Yeni Eğitim Ekle
            </div>
          </div>

          <div
            className="grid-container"
            style={{
              marginTop: "7px",
              marginBottom: "20px",
            }}
          >
            {" "}
            {educationList &&
              educationList.map((edu, index) => {
                return (
                  <div className="card .card-group-item">
                    {" "}
                    <UniItem
                      edu={edu}
                      index={index}
                      setShowDelete={() => {
                        setEduItem(edu);
                        setShowEduDelete(true);
                        setIndexItem(index);
                      }}
                    />
                  </div>
                );
              })}
          </div>
          {educationList.toString().length <= 0 ? (
            <div
              className="card"
             
              style={{
                margin: "0px",
                padding: "0px",
                margin: "0px 10px 0px 0px",
                boxShadow: "none",
              }}
            >
              <div className="basic-form" style={{ marginTop: "2px" }}>
                <form onSubmit={handleSubmit(submitNewVersion)}>
                  <CustomDesign
                    label={"Üniversite"}
                    required={true}
                    child={
                      <BitonisSingleDropdown
                        items={
                          props.universityListWhite.success &&
                          props.universityListWhite.universityListWhite.map(
                            ({ id, name }) => ({
                              value: id,
                              label: name,
                            })
                          )
                        }
                        register={register}
                        watch={watch}
                        companyClassName={"universityId"}
                        control={control}
                        setValue={setValue}
                        errors={errors}
                        placeholder="Üniversite Seçin"
                        onValueChanged={(e) => {
                          console.log(e);
                          setValue("facultyDepartmentId", "");

                          setSection(null);
                          setDepartmentGroupId(null);
                          if (e) {
                            setUniversity(e);
                            if (e.value === 233 || e.value == 234) {
                              departmentListGetsNotFaculty();
                            } else {
                              facultyDepartmentListGets(e.value);
                            }
                          } else {
                            setUniversity(null);
                            setSection(null);
                            setDepartmentGroupId(null);
                            setValue("facultyDepartmentId", "");
                          }
                        }}
                        required={false}
                        getValues={getValues}
                        errorMessage={"Üniversite boş geçilemez"}
                      />
                    }
                  />
                  <CustomDesign
                    label={"Bölüm"}
                    required={true}
                    child={
                      <BitonisMultiDropdown
                        items={
                          sectionList &&
                          sectionList.map(
                            ({ id, name, departmentGroupId }) => ({
                              value: id,
                              label: name,
                              departmentGroupId: departmentGroupId,
                            })
                          )
                        }
                        register={register}
                        watch={watch}
                        companyClassName={"facultyDepartmentId"}
                        control={control}
                        setValue={setValue}
                        errors={errors}
                        errorMessage={"Bölüm boş geçilemez"}
                        placeholder="Bölüm Seçin"
                        getValues={getValues}
                        onValueChanged={(e) => {
                          console.log(e);
                          var facultyDepAndGroupIds = [];
                          facultyDepAndGroupIdsRefTemp.current =
                            facultyDepAndGroupIds;

                          if (e) {
                            const selectedValues = e.map(
                              (option) => option.value
                            );
                            if (university && university.value == 233) {
                              if (selectedValues.includes(698)) {
                                const selectedOption = e.find(
                                  (option) => option.value === 698
                                );

                                if (selectedOption) {
                                  [selectedOption].map((val) => {
                                    facultyDepAndGroupIds.push({
                                      facultyDepartmentGroupId: val.value,
                                      facultyDepartmentId: 23069,
                                      facultyDepartmentName: val.label,
                                    });
                                  });
                                } else {
                                }
                              } else {
                                e.map((val) => {
                                  facultyDepAndGroupIds.push({
                                    facultyDepartmentGroupId: val.value,
                                    facultyDepartmentId: 23069,
                                    facultyDepartmentName: val.label,
                                  });
                                });
                              }
                            } else if (university && university.value == 234) {
                              if (selectedValues.includes(698)) {
                                const selectedOption = e.find(
                                  (option) => option.value === 698
                                );
                                if (selectedOption) {
                                  [selectedOption].map((val) => {
                                    facultyDepAndGroupIds.push({
                                      facultyDepartmentGroupId: val.value,
                                      facultyDepartmentId: 25091,
                                      facultyDepartmentName: val.label,
                                    });
                                  });
                                } else {
                                }
                              } else {
                                e.map((val) => {
                                  facultyDepAndGroupIds.push({
                                    facultyDepartmentGroupId: val.value,
                                    facultyDepartmentId: 25091,
                                    facultyDepartmentName: val.label,
                                  });
                                });
                              }
                            } else {
                              if (selectedValues.includes(23069)) {
                                const selectedOption = e.find(
                                  (option) => option.value === 23069
                                );
                                if (selectedOption) {
                                  [selectedOption].map((val) => {
                                    facultyDepAndGroupIds.push({
                                      facultyDepartmentGroupId:
                                        val.departmentGroupId,
                                      facultyDepartmentId: val.value,
                                      facultyDepartmentName: val.label,
                                    });
                                  });
                                } else {
                                }
                              } else {
                                e.map((val) => {
                                  facultyDepAndGroupIds.push({
                                    facultyDepartmentGroupId:
                                      val.departmentGroupId,
                                    facultyDepartmentId: val.value,
                                    facultyDepartmentName: val.label,
                                  });
                                });
                              }
                            }
                            facultyDepAndGroupIdsRefTemp.current =
                              facultyDepAndGroupIds;
                          } else {
                            var facultyDepAndGroupIds = [];
                          }
                        }}
                        required={false}
                        selectedId={
                          (university && university.value == 233) ||
                          (university && university.value == 234)
                            ? 698
                            : 23069
                        }
                      />
                    }
                  />
                </form>
              </div>
            </div>
          ) : (
            <div></div>
          )}
        </div>
        <EducationAddModal
          show={showAddEducation}
          handleClose={handleEduClose}
          university={university}
          setUniversity={setUniversity}
          section={section}
          setSection={setSection}
          sectionList={sectionList}
          setSectionList={setSectionList}
          departmentGroupId={departmentGroupId}
          setDepartmentGroupId={setDepartmentGroupId}
          educationList={educationList}
          setEducationList={setEducationList}
          universityListWhite={props.universityListWhite.universityListWhite}
          facultyDepAndGroupIdsRef={facultyDepAndGroupIdsRef}
          facultyDepAndGroupIdsRefTemp={facultyDepAndGroupIdsRefTemp}
        />{" "}
        <DeleteEducationModal
          show={showEduDelete}
          handleClose={eduDeleteHandleClose}
          approval={() => {
            deleteEducation();
            setShowEduDelete(false);
          }}
        />
      </>
    </div>
  );
};

function mapState(state) {
  let authentication = state.authentication;
  let universityListWhite = state.universityListWhite;
  let facultyListWhite = state.facultyListWhite;
  let facultyDepartmentListWhite = state.facultyDepartmentListWhite;

  return {
    authentication,
    universityListWhite,
    facultyListWhite,
    facultyDepartmentListWhite,
  };
}

export default connect(mapState)(FindWhiteEducations);
