import React, { useEffect, useMemo, useState } from "react";
import { Controller, useForm } from "react-hook-form";

import Modal from "react-bootstrap/Modal"; // Varsayılan olarak React Bootstrap Modal bileşeni kullanılıyor
import Select from "react-select";
import { Alert, Button } from "react-bootstrap";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  DropListAction,
  DropListWhiteAction,
} from "../../../../../../../../../action";
import "../../../../../../../../../css/bitoniscss/modalcss.css";
import BitonisSingleDropdown from "../../../../../../../../widgets/BitonisSingleDropdown";
import CustomDesign from "../../../../../../../../widgets/CustomDesign";
import CustomButton from "../../../../../../../../widgets/buttons/CustomButton";
import { Padding } from "@mui/icons-material";
const EditForeignLanguageModal = ({
  show,
  handleClose,
  job,
  onSubmit,
  props,
  customStyles,
  customStylesMulti,
  inputStyle,
  register,
  handleSubmit,
  control,
  setValue,
  errors,
  watch,
  isValid,
  labelStyle,
  foreignLanguageListRef,
  language,
  index,
  getValues,
}) => {
  useEffect(() => {
    if (show) {
      setValue("languageId", language.languageId);
      setValue("languageLevelId", language.languageLevelId);
    }
  }, [show]);
  return (
    <>
      <div>
        <Modal
          show={show}
          onHide={handleClose}
          backdrop="static"
          keyboard={false}
          aria-labelledby="contained-modal-title-vcenter"
          centered
          className="custom-modal-width"
        >
          {" "}
          <Modal.Header
            closeButton
            style={{ borderBottom: "none", justifyContent: "center" }}
          >
            <Modal.Title
              aria-labelledby="contained-modal-title-vcenter"
              centered
            >
              <h3 style={{ color: "#001542" }}>Yabancı Dil</h3>
            </Modal.Title>
          </Modal.Header>
          <div className="">
            <Modal.Body>
              {" "}
              <form
                onSubmit={(e) => {
                  e.preventDefault();
                  handleSubmit(onSubmit)();
                  if (isValid) {
                    handleClose(); // Close the modal after form submission
                  }
                }}
              >
                <CustomDesign
                  label={"Yabancı Dil"}
                  required={true}
                  isColumn={true}
                  child={
                    <BitonisSingleDropdown
                      items={
                        props.languageListWhite.languageListWhite &&
                        props.languageListWhite.languageListWhite.map(
                          ({ id, name }) => ({
                            value: id,
                            label: name,
                          })
                        )
                      }
                      register={register}
                      watch={watch}
                      companyClassName={"languageId"}
                      control={control}
                      setValue={setValue}
                      errors={errors}
                      defaultValue={language && language.languageId}
                      placeholder="Yabancı Dil Seçin"
                      onValueChanged={(e) => {
                        console.log(e);
                        setValue("languageLevelId", "");
                        if (e) {
                        }
                      }}
                      required={true}
                      getValues={getValues}
                      errorMessage={"Yabancı Dil Boş geçilemez"}
                    />
                  }
                />
                <CustomDesign
                  label={"Yabancı Dil Seviyesi"}
                  required={true}
                  isColumn={true}
                  child={
                    <BitonisSingleDropdown
                      items={
                        props.languageLevelListWhite.languageLevelListWhite &&
                        props.languageLevelListWhite.languageLevelListWhite.map(
                          ({ id, name }) => ({
                            value: id,
                            label: name,
                          })
                        )
                      }
                      register={register}
                      watch={watch}
                      companyClassName={"languageLevelId"}
                      control={control}
                      setValue={setValue}
                      errors={errors}
                      placeholder="Yabancı Dil Seviyesi Seçin"
                      onValueChanged={(e) => {
                        console.log(e);
                        if (e) {
                        }
                      }}
                      required={true}
                      getValues={getValues}
                      errorMessage={"Yabancı Dil Seviyesi Boş geçilemez"}
                      defaultValue={language && language.languageLevelId}
                    />
                  }
                />
              </form>
            </Modal.Body>
            <Modal.Footer
              style={{
                display: "flex",
                flexDirection: "Row",
                alignItems: "center",
                justifyContent: "center",
                alignContent: "center",
                borderTop: "none",
                margin: "0px",
                Padding: "0px",
              }}
            >
              <form
                onSubmit={(e) => {
                  e.preventDefault();
                  handleSubmit(onSubmit)();
                  const languageId = watch("languageId");
                  const languageLevelId = watch("languageLevelId");
                  if (languageId && languageLevelId) {
                    isValid = true;
                  }
                  if (isValid) {
                    if (languageId && languageLevelId) {
                      foreignLanguageListRef.current =
                        foreignLanguageListRef.current.filter(
                          (selectedItem) => selectedItem !== language
                        );
                      handleClose();
                    }
                  }
                }}
              >
                <CustomButton
                  title={"Sil"}
                  backgroundColor={"red"}
                  hoverBackgroundColor={"rgb(231, 32, 32)"}
                  border={"none"}
                  width={{
                    xs: "350px",
                    sm: "350px",
                    md: "350px",
                    lg: "350px",
                    xl: "350px",
                  }}
                />
              </form>
              <form
                onSubmit={(e) => {
                  e.preventDefault();
                  handleSubmit(onSubmit)();
                  const languageId = watch("languageId");
                  const languageLevelId = watch("languageLevelId");

                  if (languageId && languageLevelId) {
                    isValid = true;
                  }
                  if (isValid) {
                    if (languageId && languageLevelId) {
                      foreignLanguageListRef.current[index].languageId =
                        languageId;
                      foreignLanguageListRef.current[index].languageLevelId =
                        languageLevelId;
                    }
                    handleClose(); // Close the modal after form submission
                  } else {
                  }
                }}
              >
                <CustomButton
                  title={"Kaydet"}
                  width={{
                    xs: "350px",
                    sm: "350px",
                    md: "350px",
                    lg: "350px",
                    xl: "350px",
                  }}
                />
              </form>
            </Modal.Footer>
          </div>
        </Modal>
      </div>
    </>
  );
};

export default EditForeignLanguageModal;
