import {
    YearOfExperienceWhiteContants,
    PositionListWhiteContants,
    WayOfWorkingWhiteContants,
    NationalityListWhiteContants,
    AgeRangeWhiteContants,
    DriverLicenseTypeWhiteContants,
    ProgramListWhiteContants,
    CertificatesListWhiteContants,
    CompetencesListWhiteContants,
    CountryListWhiteContants,
    CityListWhiteContants,
    CountyListWhiteContants,
    DistrictListWhiteContants,
    CompanyListWhiteConstants,
    SalaryListWhiteContants,
    BusinessTravelListWhiteContants,
    LanguageListWhiteContants,
    LanguageLevelListWhiteContants,
    UniversityListWhiteContants,
    FacultyListWhiteContants,
    FacultyDepartmantListWhiteContants,
} from "../constants";

const defaultPositionStateWhite = {
    loading: false,
    error: false,
    positionListWhite: false,
    success: false,
};

function setState(changes) {
    return { ...defaultPositionStateWhite, ...changes };
}

export function positionListWhite(state = defaultPositionStateWhite, action) {
    // Pozisyon Listesi
    switch (action.type) {
        case PositionListWhiteContants.GET_ALL_REQUEST:
            return setState({ loading: true });
        case PositionListWhiteContants.GET_ALL_SUCCESS:
            return setState({ positionListWhite: action.positionListWhite, success: true });
        case PositionListWhiteContants.GET_ALL_FAILURE:
            return setState({ error: action.positionListWhite });
        default:
            return state;
    }
}

const defaultStateWhite = {
    loading: false,
    error: false,
    yearOfExperienceWhite: false,
    success: false,
};

export function yearOfExperienceWhite(state = defaultStateWhite, action) {
    // Tecrübe Yılı
    switch (action.type) {
        case YearOfExperienceWhiteContants.GET_ALL_REQUEST:
            return setState({ loading: true });
        case YearOfExperienceWhiteContants.GET_ALL_SUCCESS:
            return setState({
                yearOfExperienceWhite: action.yearOfExperienceWhite,success: true});
        case YearOfExperienceWhiteContants.GET_ALL_FAILURE:
            return setState({ error: action.yearOfExperienceWhite });
        default:
            return state;
    }
}


const defaultStateWayOfWorkingWhite = {
    loading: false,
    error: false,
    wayOfWorkingWhite: false,
    success: false,
};

export function wayOfWorkingWhite(state = defaultStateWayOfWorkingWhite, action) {
    // Çalışma Şekli
    switch (action.type) {
        case WayOfWorkingWhiteContants.GET_ALL_REQUEST:
            return setState({ loading: true });
        case WayOfWorkingWhiteContants.GET_ALL_SUCCESS:
            return setState({ wayOfWorkingWhite: action.wayOfWorkingWhite, success: true });
        case WayOfWorkingWhiteContants.GET_ALL_FAILURE:
            return setState({ error: action.wayOfWorkingWhite });
        default:
            return state;
    }
}
const defaultNationalityListWhite = {
    loading: false,
    error: false,
    nationalityListWhite: false,
    success: false,
};

export function nationalityListWhite(state = defaultNationalityListWhite, action) {
    // Uyruk
    switch (action.type) {
        case NationalityListWhiteContants.GET_ALL_REQUEST:
            return setState({ loading: true });
        case NationalityListWhiteContants.GET_ALL_SUCCESS:
            return setState({
                nationalityListWhite: action.nationalityListWhite,
                success: true,
            });
        case NationalityListWhiteContants.GET_ALL_FAILURE:
            return setState({ error: action.nationalityListWhite });
        default:
            return state;
    }
}
const defaultAgeRangeListWhite = {
    loading: false,
    error: false,
    ageRangeListWhite: false,
    success: false,
};

export function ageRangeListWhite(state = defaultAgeRangeListWhite, action) {
    // Yaş Aralığı
    switch (action.type) {
        case AgeRangeWhiteContants.GET_ALL_REQUEST:
            return setState({ loading: true });
        case AgeRangeWhiteContants.GET_ALL_SUCCESS:
            return setState({ ageRangeListWhite: action.ageRangeListWhite, success: true });
        case AgeRangeWhiteContants.GET_ALL_FAILURE:
            return setState({ error: action.ageRangeListWhite });
        default:
            return state;
    }
}
const defaultDriverLicenseTypeWhite = {
    loading: false,
    error: false,
    driverLicenceTypeWhite: false,
    success: false,
};

export function driverLicenceTypeWhite(state = defaultDriverLicenseTypeWhite, action) {
    // Sürücü Belgesi Türü
    switch (action.type) {
        case DriverLicenseTypeWhiteContants.GET_ALL_REQUEST:
            return setState({ loading: true });
        case DriverLicenseTypeWhiteContants.GET_ALL_SUCCESS:
            return setState({
                driverLicenceTypeWhite: action.driverLicenceTypeWhite,
                success: true,
            });
        case DriverLicenseTypeWhiteContants.GET_ALL_FAILURE:
            return setState({ error: action.driverLicenceTypeWhite });
        default:
            return state;
    }
}
const defaultProgramListWhite = {
    loading: false,
    error: false,
    programListWhite: false,
    success: false,
};

export function programListWhite(state = defaultProgramListWhite, action) {
    // Program Listesi
    switch (action.type) {
        case ProgramListWhiteContants.GET_ALL_REQUEST:
            return setState({ loading: true });
        case ProgramListWhiteContants.GET_ALL_SUCCESS:
            return setState({ programListWhite: action.programListWhite, success: true });
        case ProgramListWhiteContants.GET_ALL_FAILURE:
            return setState({ error: action.programListWhite });
        default:
            return state;
    }
}
const defaultCertificatesListWhite = {
    loading: false,
    error: false,
    certificatesListWhite: false,
    success: false,
};

export function certificatesListWhite(state = defaultCertificatesListWhite, action) {
    // Sertifika
    switch (action.type) {
        case CertificatesListWhiteContants.GET_ALL_REQUEST:
            return setState({ loading: true });
        case CertificatesListWhiteContants.GET_ALL_SUCCESS:
            return setState({
                certificatesListWhite: action.certificatesListWhite,
                success: true,
            });
        case CertificatesListWhiteContants.GET_ALL_FAILURE:
            return setState({ error: action.certificatesListWhite });
        default:
            return state;
    }
}
const defaultCompetencesListWhite = {
    loading: false,
    error: false,
    competencesListWhite: false,
    success: false,
};

export function competencesListWhite(state = defaultCompetencesListWhite, action) {
    // Yeterlilik Listesi
    switch (action.type) {
        case CompetencesListWhiteContants.GET_ALL_REQUEST:
            return setState({ loading: true });
        case CompetencesListWhiteContants.GET_ALL_SUCCESS:
            return setState({
                competencesListWhite: action.competencesListWhite,
                success: true,
            });
        case CompetencesListWhiteContants.GET_ALL_FAILURE:
            return setState({ error: action.competencesListWhite });
        default:
            return state;
    }
}

const defaultCountryListWhite = {
    loading: false,
    error: false,
    countryListWhite: false,
    success: false,
};

export function countryListWhite(state = defaultCountryListWhite, action) {
    // Ülke Listesi
    switch (action.type) {
        case CountryListWhiteContants.GET_ALL_REQUEST:
            return setState({ loading: true });
        case CountryListWhiteContants.GET_ALL_SUCCESS:
            return setState({
                countryListWhite: action.countryListWhite,
                success: true,
            });
        case CountryListWhiteContants.GET_ALL_FAILURE:
            return setState({ error: action.countryListWhite });
        default:
            return state;
    }
}


const defaultCityListWhite = {
    loading: false,
    error: false,
    cityListWhite: false,
    success: false,
};

export function cityListWhite(state = defaultCityListWhite, action) {
    // Ülke Listesi
    switch (action.type) {
        case CityListWhiteContants.GET_ALL_REQUEST:
            return setState({ loading: true });
        case CityListWhiteContants.GET_ALL_SUCCESS:
            return setState({
                cityListWhite: action.cityListWhite,
                success: true,
            });
        case CityListWhiteContants.GET_ALL_FAILURE:
            return setState({ error: action.cityListWhite });
        default:
            return state;
    }
}
const defaultCountyList = {
    loading: false,
    error: false,
    countyListWhite: false,
    success: false,
};

export function countyListWhite(state = defaultCountyList, action) {
    // İlçe Listesi
    switch (action.type) {
        case CountyListWhiteContants.GET_ALL_REQUEST:
            return setState({ loading: true });
        case CountyListWhiteContants.GET_ALL_SUCCESS:
            return setState({
                countyListWhite: action.countyListWhite,
                success: true,
            });
        case CountyListWhiteContants.GET_ALL_FAILURE:
            return setState({ error: action.countyListWhite });
        default:
            return state;
    }
}
const defaultDistrictListWhite = {
    loading: false,
    error: false,
    districtListWhite: false,
    success: false,
};

export function districtListWhite(state = defaultDistrictListWhite, action) {
    // İlçe Listesi
    switch (action.type) {
        case DistrictListWhiteContants.GET_ALL_REQUEST:
            return setState({ loading: true });
        case DistrictListWhiteContants.GET_ALL_SUCCESS:
            return setState({
                districtListWhite: action.districtListWhite,
                success: true,
            });
        case DistrictListWhiteContants.GET_ALL_FAILURE:
            return setState({ error: action.districtListWhite });
        default:
            return state;
    }
}
const defaultCompanyListWhite = {
    loading: false,
    error: false,
    companyListWhite: false,
    success: false,
};

export function companyListWhite(state = defaultCompanyListWhite, action) {
    // Şirket Listesi
    switch (action.type) {
        case CompanyListWhiteConstants.GET_ALL_REQUEST:
            return setState({ loading: true });
        case CompanyListWhiteConstants.GET_ALL_SUCCESS:
            return setState({
                companyListWhite: action.companyListWhite,
                success: true,
            });
        case CompanyListWhiteConstants.GET_ALL_FAILURE:
            return setState({ error: action.companyListWhite });
        default:
            return state;
    }
}

const defaultSalaryListWhite = {
    loading: false,
    error: false,
    salaryListWhite: false,
    success: false,
};

export function salaryListWhite(state = defaultSalaryListWhite, action) {
    // Maaş Listesi
    switch (action.type) {
        case SalaryListWhiteContants.GET_ALL_REQUEST:
            return setState({ loading: true });
        case SalaryListWhiteContants.GET_ALL_SUCCESS:
            return setState({
                salaryListWhite: action.salaryListWhite,
                success: true,
            });
        case SalaryListWhiteContants.GET_ALL_FAILURE:
            return setState({ error: action.salaryListWhite });
        default:
            return state;
    }
}
const defaultBusinessTravelListWhite = {
    loading: false,
    error: false,
    businessTravelListWhite: false,
    success: false,
};

export function businessTravelListWhite(state = defaultBusinessTravelListWhite, action) {
    // İş Seyehat Listesi
    switch (action.type) {
        case BusinessTravelListWhiteContants.GET_ALL_REQUEST:
            return setState({ loading: true });
        case BusinessTravelListWhiteContants.GET_ALL_SUCCESS:
            return setState({
                businessTravelListWhite: action.businessTravelListWhite,
                success: true,
            });
        case BusinessTravelListWhiteContants.GET_ALL_FAILURE:
            return setState({ error: action.businessTravelListWhite });
        default:
            return state;
    }
}
const defaultLanguageListWhiteWhite = {
    loading: false,
    error: false,
    languageListWhite: false,
    success: false,
};

export function languageListWhite(state = defaultLanguageListWhiteWhite, action) {
    // Yabancı Dil Listesi
    switch (action.type) {
        case LanguageListWhiteContants.GET_ALL_REQUEST:
            return setState({ loading: true });
        case LanguageListWhiteContants.GET_ALL_SUCCESS:
            return setState({
                languageListWhite: action.languageListWhite,
                success: true,
            });
        case LanguageListWhiteContants.GET_ALL_FAILURE:
            return setState({ error: action.languageListWhite });
        default:
            return state;
    }
}
const defaultLanguageLevelListWhiteWhite = {
    loading: false,
    error: false,
    languageLevelListWhite: false,
    success: false,
};

export function languageLevelListWhite(state = defaultLanguageLevelListWhiteWhite, action) {
    // Yabancı Dil Seviye Listesi
    switch (action.type) {
        case LanguageLevelListWhiteContants.GET_ALL_REQUEST:
            return setState({ loading: true });
        case LanguageLevelListWhiteContants.GET_ALL_SUCCESS:
            return setState({
                languageLevelListWhite: action.languageLevelListWhite,
                success: true,
            });
        case LanguageLevelListWhiteContants.GET_ALL_FAILURE:
            return setState({ error: action.languageLevelListWhite });
        default:
            return state;
    }
}

const defaultUniversityListWhite = {
    loading: false,
    error: false,
    universityListWhite: false,
    success: false,
};

export function universityListWhite(state = defaultUniversityListWhite, action) {
    // Üniversite Listesi
    switch (action.type) {
        case UniversityListWhiteContants.GET_ALL_REQUEST:
            return setState({ loading: true });
        case UniversityListWhiteContants.GET_ALL_SUCCESS:
            return setState({
                universityListWhite: action.universityListWhite,
                success: true,
            });
        case UniversityListWhiteContants.GET_ALL_FAILURE:
            return setState({ error: action.universityListWhite });
        default:
            return state;
    }
}
const defaultFacultyListWhite = {
    loading: false,
    error: false,
    facultyListWhite: false,
    success: false,
};

export function facultyListWhite(state = defaultFacultyListWhite, action) {
    // Fakülte Listesi
    switch (action.type) {
        case FacultyListWhiteContants.GET_ALL_REQUEST:
            return setState({ loading: true });
        case FacultyListWhiteContants.GET_ALL_SUCCESS:
            return setState({
                facultyListWhite: action.facultyListWhite,
                success: true,
            });
        case FacultyListWhiteContants.GET_ALL_FAILURE:
            return setState({ error: action.facultyListWhite });
        default:
            return state;
    }
}
const defaultFacultyDepartmentListWhite = {
    loading: false,
    error: false,
    facultyDepartmentListWhite: false,
    success: false,
};

export function facultyDepartmentListWhite(state = defaultFacultyDepartmentListWhite, action) {
    // Fakülte Listesi
    switch (action.type) {
        case FacultyDepartmantListWhiteContants.GET_ALL_REQUEST:
            return setState({ loading: true });
        case FacultyDepartmantListWhiteContants.GET_ALL_SUCCESS:
            return setState({
                facultyDepartmentListWhite: action.facultyDepartmentListWhite,
                success: true,
            });
        case FacultyDepartmantListWhiteContants.GET_ALL_FAILURE:
            return setState({ error: action.facultyDepartmentListWhite });
        default:
            return state;
    }
}