import React from "react";
import CustomPdfPreview from "../../../../../../../../widgets/CustomPdfPreview";
import DividerDotted from "../../../../../../../../widgets/DividerDotted";
import { DividerPdf } from "./DividerPdf";

const LanguageWhiteCv = ({ whiteMatchDetail }) => {
  return (
    <div
      className="card"
      style={{
        flex: "6",
        marginTop: "0px",
        display: "flex",
        flexDirection: "column",
        margin: "0px",
        padding: "0px",
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          marginBottom: "10px",
          padding: "0px",
        }}
      >
        <div
          style={{
            border: "2px solid grey",
            borderRadius: "8px",

            height: "40px",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignContent: "center",
          }}
        >
          <p
            style={{
              padding: "10px",
              margin: "0px",
              fontSize: "10px",
              color: "#001542",
              fontWeight: "500",
            }}
          >
            Yetenekler
          </p>
        </div>
        <hr
          style={{
            color: "black",
            marginLeft: "0px",
            paddingLeft: "0px",
            border: "1px solid grey",
            flex: "6",
            margin: "0px",
            padding: "0px",
          }}
        />
        <div
          style={{
            width: "40px",
            height: "40px",
            borderRadius: "50%",
            border: "1.8px solid grey",
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <i
            class="fa-solid fa-star"
            style={{
              color: "orange",
              fontSize: "12px",
            }}
          ></i>
        </div>
      </div>
      <div
        style={{
          backgroundColor: "#f7f7f7",
          borderRadius: "8px",
          marginTop: "0px",
         paddingTop: "10px",

          marginBottom: "30px",
        }}
      >
        <div
          style={{
            margin: "0px",
          }}
        ></div>
        {whiteMatchDetail &&
          whiteMatchDetail.matchLanguageDetailList.map((language, index) => {
            return (
              <div>
                {" "}
                <CustomPdfPreview
                  name={"Yabancı Dil"}
                  value={whiteMatchDetail && language.languageName}
                />
                <CustomPdfPreview
                  name={"Yabancı Dil Seviyesi"}
                  value={whiteMatchDetail && language.languageLevelName}
                />{" "}
                {index !==
                  whiteMatchDetail.matchLanguageDetailList.length - 1 && (
                  <hr style={{ borderTop: "1px dotted orange" }}></hr>
                )}
              </div>
            );
          })}
        <div
          style={{
            paddingBottom: "20px",
          }}
        ></div>
      </div>
    </div>
  );
};

export default LanguageWhiteCv;
