import React from "react";
import CustomPreview from "../../../../../../../../widgets/CustomPreview";

const BlueLastWorkCompany = ({
  cardStyle,
  labelStyle,
  inputStyle,
  blueMatchDetail,
}) => {
  return (
    <div>
      <div class="" style={cardStyle}>
        <div
          class="card-header"
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
            borderBottom: "none",
            marginBottom: "0px",
            paddingBottom: "0px",
            marginTop: "0px",
          }}
        >
          <h5 class="card-title">En Son Çalıştığı İş Yeri</h5>
        </div>

        <div class="card-body">
          <CustomPreview
            name={"En Son Çalıştığınız İş Yeri"}
            value={
              blueMatchDetail &&
              blueMatchDetail.lastWorkCompany &&
              blueMatchDetail.lastWorkCompany.lastCompanyName
            }
          />{" "}
          <CustomPreview
            name={"Pozisyon"}
            value={
              blueMatchDetail &&
              blueMatchDetail.lastWorkCompany &&
              blueMatchDetail.lastWorkCompany.positionName
            }
          />
          <CustomPreview
            name={"Çalışma Süreniz"}
            value={
              blueMatchDetail &&
              blueMatchDetail.lastWorkCompany &&
              blueMatchDetail.lastWorkCompany.yourWorkingTime
            }
          />
          <CustomPreview
            name={"Adres"}
            value={
              blueMatchDetail &&
              blueMatchDetail.lastWorkCompany &&
              blueMatchDetail.lastWorkCompany.address
            }
          />
        </div>
      </div>
    </div>
  );
};

export default BlueLastWorkCompany;
