import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  navIndex: -1,
};

const navBarSlice = createSlice({
  name: "navIndex",
  initialState,
  reducers: {
    setNavIndex: (state, action) => {
      state.navIndex = action.payload;
    },
  },
});

export const { setNavIndex } = navBarSlice.actions;
export default navBarSlice.reducer;
