import React, { useEffect, useState } from "react";

import { Link } from "react-router-dom";
import { connect, useDispatch, useSelector } from "react-redux";
import PerfectScrollbar from "react-perfect-scrollbar";
import LogoutPage from "./Logout";

import profile from "../../../images/profile/logo.png";
import NotificationWidget from "../../components/Dashboard/Application/Employer/Notifications/NotificationWidget";
import { DashboardServices } from "../../../services/DashboardServices";
import {
  setCompanyMainData,
  setCompanyMainDataName,
} from "../../../redux/redux-toolkit/companyMainData";

const Header = (props) => {
  const dispatch = useDispatch();
  const { companyMainData } = useSelector((state) => state.companyMainData);

  let authentication = props.authentication;
  //console.log(authentication)
  const [companyData, setCompanyData] = useState(null);
  useEffect(() => {
    DashboardServices.getHeaderCompanyMain().then((headerResponse) => {
      if (headerResponse && headerResponse.succeeded === true) {
        setCompanyData(headerResponse.data);
        dispatch(setCompanyMainData(headerResponse.data));
        dispatch(
          setCompanyMainDataName(
            headerResponse.data.alias === null ||
              headerResponse.data.alias === undefined ||
              headerResponse.data.alias === ""
              ? headerResponse.data.name
              : headerResponse.data.alias
          )
        );
      }
    });
  }, []);

  return (
    <div className="header">
      <div className="header-content">
        <nav className="navbar navbar-expand">
          <div className="collapse navbar-collapse justify-content-between">
            <ul className="navbar-nav header-right">
              <li
                className={`nav-item dropdown header-profile ${
                  props.toggle === "profile" ? "show" : ""

                }`
             
                
                } style={{
                marginTop:"5px"
              }}
              >
                <Link
                  to={"/profile"}
                  className="nav-link"
                  role="button"
                  data-toggle="dropdown"
                >
                  {/* <img src={profile} width="20" alt="" /> */}

                  <div
                    className="header-info"
                    style={{
                      marginLeft: "0px",
                      padding: "0px",
                    }}
                  >
                    <span className="text-black">
                      {companyData &&
                        (companyData.alias === null ||
                        companyData.alias === undefined ||
                        companyData.alias === ""
                          ? companyData.name
                          : companyData.alias)}
                    </span>
                    <p className="fs-12 mb-0"></p>
                  </div>
                </Link>{" "}
                <NotificationWidget />
                <div
                  className={`dropdown-menu dropdown-menu-right ${
                    props.toggle === "profile" ? "show" : ""
                  }`}
                >
                  <Link to="/app-profile" className="dropdown-item ai-icon">
                    <svg
                      id="icon-user1"
                      xmlns="http://www.w3.org/2000/svg"
                      className="text-primary"
                      width="18"
                      height="18"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    >
                      <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path>
                      <circle cx="12" cy="7" r="4"></circle>
                    </svg>
                    <span className="ml-2">Profil </span>
                  </Link>

                  <LogoutPage />
                </div>
              </li>
            </ul>
          </div>
        </nav>
      </div>
    </div>
  );
};

function mapState(state) {
  let authentication = state.authentication;
  return { authentication };
}

export default connect(mapState)(Header);
