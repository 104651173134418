import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";

import Modal from "react-bootstrap/Modal"; // Varsayılan olarak React Bootstrap Modal bileşeni kullanılıyor
import Select from "react-select";
import { Alert, Button } from "react-bootstrap";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { getValue } from "@syncfusion/ej2-base";

function SmsPacketModal({ show, handleClose, sanitizedUrl }) {
  return (
    <>
      <div>
        <Modal
          show={show}
          onHide={handleClose}
          style={{
            backgroundColor: "rgba(0, 0, 0, 0.2)",
          }}
          backdrop="static"
          keyboard={false}
          aria-labelledby="contained-modal-title-vcenter"
          centered
          dialogClassName="full-screen-modal"
        >
          <Modal.Header closeButton style={{ borderBottom: "none" }}>
            <Modal.Title
              aria-labelledby="contained-modal-title-vcenter"
              style={{
                width: "100%",
                textAlign: "center",
                margin: "0 auto",
              }}
            >
              3D Ödeme
            </Modal.Title>
          </Modal.Header>
          <Modal.Body
            style={{
              marginTop: "0px",
              paddingTop: "0px",
            }}
          >
            {" "}
            <div
              className="card"
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignContent: "center",
                alignItems: "center",
                position: "relative",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignContent: "center",
                  alignItems: "center",
                  width: "500px",
                  height: "500px",
                }}
              >
                <iframe
                  title=""
                  width={"100%"}
                  height={"100%"}
                  src={sanitizedUrl}
                  style={{
                    border: "none",
                    display: "flex",
                  }}
                />
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </div>
    </>
  );
}

export default SmsPacketModal;
