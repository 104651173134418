import React, { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import Select from "react-select";
import { useForm, Controller } from "react-hook-form";
import { customStylesMulti, labelStyle } from "../../../../customStyleInline";
import infoImage from "../../../../../images/svg/info.svg";
import AddProgramsCertificatesCompetencesNotFoundModal from "./AddProgramsCertificatesCompetencesNotFoundModal";
import BitonisMultiDropdown from "../../../../widgets/BitonisMultiDropdown";
import CustomPreview from "../../../../widgets/CustomDesign";

export const CompetenciesTypesEnum = {
  program: "program",
  certificate: "certificate",
  competence: "competence",
};

const ProgramsCertificatesCompetences = ({
  programList,
  certificateList,
  CompetenceList,
  register,
  control,
  watch,
  setValue,
  errors,
  getValues,
  checkProgram,
  checkCertificate,
  checkCompetence,
  setCheckProgram,
  setCheckCertificate,
  setCheckCompetence,
  preview,
}) => {
  const [open, setOpen] = useState(false);
  function handleClose() {
    setOpen(false);
  }
  const [competenciesType, setCompetenciesType] = useState(null);

  return (
    <div
      style={{
        marginBottom: "0px",
        paddingBottom: "0px",
      }}
    >
      {programList&&programList.length <= 0 ? (
        <div></div>
      ) : (
        programList && (
          <CustomPreview
            label={"Programlar"}
            required={false}
            child={
              <BitonisMultiDropdown
                items={
                  programList &&
                  programList.map(({ id, name }) => ({
                    value: id,
                    label: name,
                  }))
                }
                register={register}
                watch={watch}
                companyClassName={"jobPrograms"}
                control={control}
                setValue={setValue}
                errors={errors}
                errorMessage={""}
                placeholder="Program Seçin"
                onValueChanged={(value) => {
                  console.log(value);
                }}
                required={false}
                getValues={getValues}
                requiredSelectedItemsBoolean={true}
                requiredSelectedItemsValues={checkProgram}
                setRequiredSelectedItemsValues={setCheckProgram}
                requiredSelectedItemsText={
                  <div>
                    Adayın eşleşmesi için zorunlu olan şart:
                    <br /> Aşağıda seçtiğiniz tüm programların bilgisine sahip
                    olmasını istiyorsanız,lütfen bu seçeneği işaretleyin.
                  </div>
                }
              />
            }
            trailing={
              preview === false ? (
                <div
                  onClick={() => {
                    setCompetenciesType("program");
                    setOpen(true);
                  }}
                  style={{
                    alignItems: "center",
                    border: "1px solid #fdc303",
                    borderRadius: "4px",

                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                    alignContent: "center",
                    width: "25px",
                    height: "25px",
                    marginLeft: "10px",
                    backgroundColor: "#fdc303",
                    color: "white",
                  }}
                >
                  <i class="fa-solid fa-info"></i>
                </div>
              ) : null
            }
          />
        )
      )}

      {certificateList&&certificateList.length <= 0 ? (
        <></>
      ) : (
        certificateList && (
          <CustomPreview
            label={"Sertifikalar"}
            required={false}
            child={
              <BitonisMultiDropdown
                items={
                  certificateList &&
                  certificateList.map(({ id, name }) => ({
                    value: id,
                    label: name,
                  }))
                }
                register={register}
                watch={watch}
                companyClassName={"jobCertificates"}
                control={control}
                setValue={setValue}
                errors={errors}
                errorMessage={""}
                placeholder="Sertifika Seçin"
                onValueChanged={(value) => {
                  console.log(value);
                }}
                required={false}
                getValues={getValues}
                requiredSelectedItemsBoolean={true}
                requiredSelectedItemsValues={checkCertificate}
                setRequiredSelectedItemsValues={setCheckCertificate}
                requiredSelectedItemsText={
                  <div>
                    Adayın eşleşmesi için zorunlu olan şart:
                    <br /> Aşağıda seçtiğiniz tüm sertifikalara sahip olmasını
                    istiyorsanız,lütfen bu seçeneği işaretleyin.
                  </div>
                }
              />
            }
            trailing={
              preview === false ? (
                <div
                  onClick={() => {
                    setCompetenciesType("certificate");
                    setOpen(true);
                  }}
                  style={{
                    alignItems: "center",
                    border: "1px solid ##fdc303",
                    borderRadius: "4px",
                    backgroundColor: "#fdc303",
                    color: "white",
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                    alignContent: "center",
                    width: "25px",
                    height: "25px",
                    marginLeft: "15px",
                  }}
                >
                  <i class="fa-solid fa-info"></i>
                </div>
              ) : null
            }
          />
        )
      )}

      {CompetenceList&&CompetenceList.length <= 0 ? (
        <div></div>
      ) : (
        CompetenceList && (
          <CustomPreview
            label={"Yetkinlikler"}
            child={
              <BitonisMultiDropdown
                items={
                  CompetenceList &&
                  CompetenceList.map(({ id, name }) => ({
                    value: id,
                    label: name,
                  }))
                }
                register={register}
                watch={watch}
                companyClassName={"jobCompetences"}
                control={control}
                setValue={setValue}
                errors={errors}
                errorMessage={""}
                placeholder="Yetkinlik Seçin"
                onValueChanged={(value) => {
                  console.log(value);
                }}
                required={false}
                getValues={getValues}
                requiredSelectedItemsBoolean={true}
                requiredSelectedItemsValues={checkCompetence}
                setRequiredSelectedItemsValues={setCheckCompetence}
                requiredSelectedItemsText={
                  <div>
                    Adayın eşleşmesi için zorunlu olan şart:
                    <br /> Aşağıda seçtiğiniz tüm yetkinliklere sahip olmasını
                    istiyorsanız,lütfen bu seçeneği işaretleyin.
                  </div>
                }
              />
            }
            trailing={
              preview === false ? (
                <div
                  onClick={() => {
                    setCompetenciesType("competence");
                    setOpen(true);
                  }}
                  style={{
                    alignItems: "center",
                    border: "1px solid #fdc303",
                    borderRadius: "4px",
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                    alignContent: "center",
                    backgroundColor: "#fdc303",
                    color: "white",
                    width: "25px",
                    height: "25px",
                    marginLeft: "15px",
                  }}
                >
                  <i class="fa-solid fa-info"></i>
                </div>
              ) : null
            }
          />
        )
      )}
      <AddProgramsCertificatesCompetencesNotFoundModal
        show={open}
        handleClose={handleClose}
        competenciesType={competenciesType}
      />
    </div>
  );
};

export default ProgramsCertificatesCompetences;

/*

  <img
                src={infoImage}
                style={{
                  width: "15px",
                  height: "15px",
                  color: "white",
                  backgroundColor: "white",
                  textAlign: "center",
                }}
              />

              */
