import React from "react";
import { DemandsServices } from "../../../../../../../../../../services";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { useDispatch } from "react-redux";
import { setCurrentTabNotHire } from "../../../../../../../../../../redux/redux-toolkit/selectedNotHireTab";
import { setDemandMatchOfficeInterViewTab } from "../../../../../../../../../../redux/redux-toolkit/demandMatchOfficeInterView";
import { Box } from "@mui/material";
import CustomButton from "../../../../../../../../../widgets/buttons/CustomButton";

const InterviewMeetingDeclinedByEmployer = ({ matchId, tooltip }) => {
  //Görüşmeyi reddet
  const history = useHistory();
  const dispatch = useDispatch();
  function interviewMeetingDeclinedByEmployerFunction(matchId) {
    DemandsServices.InterviewMeetingDeclinedByEmployerFunction(matchId).then(
      (interviewMeetingDeclinedByEmployerResponse) => {
        if (interviewMeetingDeclinedByEmployerResponse) {
          if (interviewMeetingDeclinedByEmployerResponse.succeeded === true) {
            dispatch(setDemandMatchOfficeInterViewTab(0));

            toast.success(
              interviewMeetingDeclinedByEmployerResponse.result.message,
              {
                autoClose: 2000,
                onClose: () => {
                  setTimeout(() => {
                    history.push("/matchInterviewMeets");
                  }, 100);
                },
              }
            );
          } else {
            toast.info(
              interviewMeetingDeclinedByEmployerResponse.error.message,
              {
                autoClose: 2000,
                onClose: () => {
                  setTimeout(() => {}, 100);
                },
              }
            );
          }
        }
      },
      (error) => {
        toast.info("Beklenmedik Hata Oluştu", {
          autoClose: 2000,
          onClose: () => {
            setTimeout(() => {}, 100);
          },
        });
      }
    );
  }
  return (
    <div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
      
        <Box
            sx={{
              margin: "0px",
            }}
          >
            <CustomButton
              onTap={(value) => {
              value.preventDefault();
              interviewMeetingDeclinedByEmployerFunction(matchId);
            }}
              title={"Görüşmeyi Reddet"}
              width={{
                xs: "380px",
                sm: "380px",
                md: "450px",
                lg: "450px",
                xl: "600px",
              }}
              backgroundColor={"white"}
              color={"#001542"}
              hoverBackgroundColor={"red"}
              
            />
          </Box>
      </div>
    </div>
  );
};

export default InterviewMeetingDeclinedByEmployer;
