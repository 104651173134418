import React from "react";
import CustomPreview from "../../../../../../../../widgets/CustomPreview";
import DividerDotted from "../../../../../../../../widgets/DividerDotted";

const WhiteWantToWorkPositions = ({
  cardStyle,
  labelStyle,
  inputStyle,
  whiteMatchDetail,
  matchPoint,
}) => {
  return (
    <div>
      <div
        class=""
        style={{
          margin: "0px",
          padding: "0px",
        }}
      >
        <div
          class="card-header"
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
            borderBottom: "none",
            marginBottom: "0px",
            paddingBottom: "0px",
          }}
        >
          <h5 class="card-title">Çalışmak İstediği Kriterler</h5>
        </div>
        <div
          class="card-body"
          style={{
            marginTop: "0px",
            paddingTop: "10px",
          }}
        >
          <CustomPreview
            name={"Çalışma Şekli"}
            value={
              whiteMatchDetail &&
              whiteMatchDetail.workTypes &&
              whiteMatchDetail.workTypes
                .map((val) => val.workTypeName)
                .join(", ")
            }
            matchPointStatus={
              matchPoint &&
              matchPoint.isWorkTypeMatched &&
              matchPoint.isWorkTypeMatched
            }
          />
          <CustomPreview
            name={"Maaş"}
            value={whiteMatchDetail && whiteMatchDetail.salaryRange}
            matchPointStatus={
              matchPoint &&
              matchPoint.isSalaryMatched &&
              matchPoint.isSalaryMatched
            }
          />
          <CustomPreview
            name={"Hafta Sonu Çalışma"}
            value={
              whiteMatchDetail &&
              whiteMatchDetail.weekendWork &&
              whiteMatchDetail.weekendWork.name
            }
            matchPointStatus={
              matchPoint &&
              matchPoint.isWeekendWorkMatched &&
              matchPoint.isWeekendWorkMatched
            }
          />
          <CustomPreview
            name={"Sektör"}
            value={
              whiteMatchDetail &&
              whiteMatchDetail.sector &&
              whiteMatchDetail.sector.map((val) => val.sectorName).join(", ")
            }
            matchPointStatus={
              matchPoint &&
              matchPoint.isSectorMatched &&
              matchPoint.isSectorMatched
            }
          />

          <CustomPreview
            name={"İş Seyahati Tercihiniz"}
            value={
              whiteMatchDetail &&
              whiteMatchDetail.businessTravel &&
              whiteMatchDetail.businessTravel
                .map((val) => val.businessTravelChoiceName)
                .join(", ")
            }
            matchPointStatus={
              matchPoint &&
              matchPoint.isTravelRestrictionMatched &&
              matchPoint.isTravelRestrictionMatched
            }
          />
        </div>
      </div>
      <DividerDotted />
    </div>
  );
};

export default WhiteWantToWorkPositions;
