import React, { useEffect, useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { styled } from "@mui/system";
import { Tabs } from "@mui/base/Tabs";
import { TabsList as BaseTabsList } from "@mui/base/TabsList";
import { TabPanel as BaseTabPanel } from "@mui/base/TabPanel";
import { buttonClasses } from "@mui/base/Button";
import { Tab as BaseTab, tabClasses } from "@mui/base/Tab";
import BlueCollarDocuments from "./BlueCollarDocuments";
import WhiteCollarDocuments from "./WhiteCollarDocuments";
import api from "../../../../../../../utility/Utils";
import { Container } from "@material-ui/core";
import { ToastContainer } from "react-toastify";
import BitonisTabBar from "../../../../../../widgets/BitonisTabBar/BitonisTabBar";
import PageInfoTitle from "../../../../../../widgets/PageInfoTitle";
import { Card } from "react-bootstrap";
const RequestEmployerJobSeekerDocuments = () => {
  const [activeTab, setActiveTab] = useState(0);
  const handleTabClick = (index) => {
    setActiveTab(index);
  };

  const [type, setType] = useState(1);

  const [documentSucces, setDocumentSucces] = useState(false);

  return (
    <div>
      {" "}
      <PageInfoTitle title={"İşe Giriş Evrakları"} />
      <div className="card" id="demandShow">
        <ToastContainer
          position="top-right"
          autoClose={2000}
          hideProgressBar={false}
          newestOnTop
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
        <div
          style={{
            marginTop: "10px",
          }}
        ></div>
        <div className="row">
          <div className="col-md-12">
            <Card>
              <Card.Body>
                <BitonisTabBar
                  tabLeftTitle={"Mavi Yaka"}
                  tabRightTitle={"Beyaz Yaka"}
                  tabLeftBody={<BlueCollarDocuments type={1} />}
                  tabRightBody={<WhiteCollarDocuments type={2} />}
                  showBorder={false}
                  activeTab={activeTab}
                  tabStyle={{
                    fontSize: "0.82rem",
                  }}
                  onChangeTab={(val) => {
                    setActiveTab(val);
                  }}
                />
              </Card.Body>
            </Card>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RequestEmployerJobSeekerDocuments;
