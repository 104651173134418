import React from "react";
import { DemandsServices } from "../../../../../../../../../../services";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { useDispatch } from "react-redux";
import { setCurrentTabNotHire } from "../../../../../../../../../../redux/redux-toolkit/selectedNotHireTab";
import { Box } from "@mui/material";
import CustomButton from "../../../../../../../../../widgets/buttons/CustomButton";

const VideoConfDeclinedByEmployer = ({ matchId, tooltip }) => {
  //Görüşmeyi reddet
  const history = useHistory();
  const dispatch = useDispatch();
  function videoConfDeclinedByEmployer(matchId) {
    DemandsServices.VideoConfDeclinedByEmployerFunction(matchId).then(
      (videoConfDeclinedByEmployerResponse) => {
        if (videoConfDeclinedByEmployerResponse) {
          if (videoConfDeclinedByEmployerResponse.succeeded === true) {
            toast.success(videoConfDeclinedByEmployerResponse.result.message, {
              autoClose: 2000,
              onClose: () => {
                setTimeout(() => {}, 100);
              },
            });
          } else {
            toast.info(videoConfDeclinedByEmployerResponse.error.message, {
              autoClose: 2000,
              onClose: () => {
                setTimeout(() => {}, 100);
              },
            });
          }
        }
      },
      (error) => {
        toast.info("Beklenmedik Hata Oluştu", {
          autoClose: 2000,
          onClose: () => {
            setTimeout(() => {}, 100);
          },
        });
      }
    );
  }
  return (
    <div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Box
          sx={{
            margin: "0px",
          }}
        >
          <CustomButton
            onTap={(value) => {
              value.preventDefault();
              videoConfDeclinedByEmployer(matchId);
            }}
            title={"Görüşmeyi Reddet"}
            width={{
              xs: "380px",
              sm: "380px",
              md: "450px",
              lg: "450px",
              xl: "600px",
            }}
            backgroundColor={"white"}
            color={"#001542"}
            hoverBackgroundColor={"red"}
          />
        </Box>
      </div>
    </div>
  );
};

export default VideoConfDeclinedByEmployer;
