export const DashboardConstants = {
    GET_REQUEST: 'DASHBOARD_GET_REQUEST',
    GET_SUCCESS: 'DASHBOARD_GET_SUCCESS',
    GET_CLEAR: 'DASHBOARD_GET_CLEAR',
    GET_FAILURE: 'DASHBOARD_GET_FAILURE',
    GET_ALL_REQUEST: 'DASHBOARD_GET_ALL_REQUEST',
    GET_ALL_SUCCESS: 'DASHBOARD_GET_ALL_SUCCESS',
    GET_ALL_FAILURE: 'DASHBOARD_GET_ALL_FAILURE',
};

export const DashboardMatchConstants = {
    GET_REQUEST: 'DASHBOARDMATCH_GET_REQUEST',
    GET_SUCCESS: 'DASHBOARDMATCH_GET_SUCCESS',
    GET_CLEAR: 'DASHBOARDMATCH_GET_CLEAR',
    GET_FAILURE: 'DASHBOARDMATCH_GET_FAILURE',
    GET_ALL_REQUEST: 'DASHBOARDMATCH_GET_ALL_REQUEST',
    GET_ALL_SUCCESS: 'DASHBOARDMATCH_GET_ALL_SUCCESS',
    GET_ALL_FAILURE: 'DASHBOARDMATCH_GET_ALL_FAILURE',
};
export const LastDashboardConstants = {
    GET_REQUEST: 'LASTDASHBOARDMATCH_GET_REQUEST',
    GET_SUCCESS: 'LASTDASHBOARDMATCH_GET_SUCCESS',
    GET_CLEAR: 'LASTDASHBOARDMATCH_GET_CLEAR',
    GET_FAILURE: 'LASTDASHBOARDMATCH_GET_FAILURE',
    GET_ALL_REQUEST: 'LASTDASHBOARDMATCH_GET_ALL_REQUEST',
    GET_ALL_SUCCESS: 'LASTDASHBOARDMATCH_GET_ALL_SUCCESS',
    GET_ALL_FAILURE: 'LASTDASHBOARDMATCH_GET_ALL_FAILURE',
};

