import Modal from "react-bootstrap/Modal";
import React, { useEffect, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import Select from "react-select";
import { connect, useDispatch } from "react-redux";
import { Button } from "react-bootstrap";
import languageAndLevelAdd from "../../Function/languageAndLevelAdd";
import BitonisSingleDropdown from "../../../../../../widgets/BitonisSingleDropdown";
import CustomDesign from "../../../../../../widgets/CustomDesign";

function LanguageAddModal({
  show,
  handleClose,
  languageListWhite,
  languageLevelListWhite,
  language,
  setLanguage,
  languageLevel,
  setLanguageLevel,
  languageList,
  setLanguageList,
}) {
  function onHide() {
    handleClose(false);
  }

  var props = {
    show,
    onHide,
    languageListWhite,
    languageLevelListWhite,

    languageList,
    setLanguageList,
  };

  return (
    <>
      <div>
        <MyVerticallyCenteredModal props={props} />
      </div>
    </>
  );
}

function MyVerticallyCenteredModal({ props }) {
  const [language, setLanguage] = useState(null);
  const [languageLevel, setLanguageLevel] = useState(null);
  const {
    show,
    onHide,
    languageListWhite,
    languageLevelListWhite,

    languageList,
    setLanguageList,
  } = props;

  const {
    register,
    handleSubmit,
    control,
    setValue,
    getValues,
    watch,
    formState: { errors },
  } = useForm();

  function submitLanguage(form) {
    languageAndLevelAdd(
      language,
      setLanguage,
      languageLevel,
      setLanguageLevel,
      languageList,
      setLanguageList,
      setValue
    );
    onHide();
  }

  useEffect(() => {
    if (show) {
      setLanguage(null);
      setLanguageLevel(null);
      setValue("languageId", "");
      setValue("languageLevelId", "");
    }
  }, [show]);
  return (
    <Modal
      {...props}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      dialogClassName="full-screen-modal" // Tam ekran sınıfı ekleyin
    >
      <Modal.Header
        closeButton
        style={{
          borderBottom: "none",
          textAlign: "center",
        }}
      >
        <Modal.Title
          aria-labelledby="contained-modal-title-vcenter"
          style={{
            width: "100%",
            textAlign: "center",
            margin: "0 auto",
          }}
          centered
        >
          Yeni Yabancı Dil Ekle
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="basic-form">
          <form onSubmit={handleSubmit(submitLanguage)}>
            <CustomDesign
              label={"Yabancı Dil"}
              required={true}
              isColumn={true}
              child={
                <BitonisSingleDropdown
                  items={
                    languageListWhite &&
                    languageListWhite.map(({ id, name }) => ({
                      value: id,
                      label: name,
                    }))
                  }
                  register={register}
                  watch={watch}
                  companyClassName={"languageId"}
                  control={control}
                  setValue={setValue}
                  errors={errors}
                  placeholder="Yabancı Dil Seçin"
                  onValueChanged={(e) => {
                    console.log(e);
                    if (e) {
                      setLanguage(e);
                      setLanguageLevel(null);
                      setValue("languageLevelId", "");
                    }
                  }}
                  required={true}
                  getValues={getValues}
                  errorMessage={"Yabancı Dil Boş geçilemez"}
                />
              }
            />

            {language && (
              <CustomDesign
                label={"Yabancı Dil Seviyesi"}
                required={true}
                isColumn={true}
                child={
                  <BitonisSingleDropdown
                    items={
                      languageLevelListWhite &&
                      languageLevelListWhite.map(({ id, name }) => ({
                        value: id,
                        label: name,
                      }))
                    }
                    register={register}
                    watch={watch}
                    companyClassName={"languageLevelId"}
                    control={control}
                    setValue={setValue}
                    errors={errors}
                    placeholder="Yabancı Dil Seviyesi Seçin"
                    onValueChanged={(e) => {
                      console.log(e);
                      setLanguageLevel(e);
                    }}
                    required={
                      language == undefined || language == null ? false : true
                    }
                    getValues={getValues}
                    errorMessage={"Yabancı Dil Seviyesi boş geçilemez"}
                  />
                }
              />
            )}
          </form>
        </div>
      </Modal.Body>
      <Modal.Footer
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          alignContent: "center",
          borderTop: "none",
        }}
      >
        <form onSubmit={handleSubmit(submitLanguage)}>
          <Button
            as="input"
            type="submit"
            value="Kaydet"
            onClick={handleSubmit(submitLanguage)}
            style={{
              borderRadius: "8px",
              fontSize: "15px",
              padding: "15px",
              width: "300px", // Genişliği istediğiniz değere ayarlayın

              cursor: "pointer",
              transition: "background-color 0.3s",
            }}
          />
        </form>
      </Modal.Footer>
    </Modal>
  );
}
const customStyles = {
  control: (provided) => ({
    ...provided,
    borderRadius: "6px",
    padding: "10px 10px 10px 0px",
    border: "1px solid rgb(226, 226, 226)",
    fontSize: "15px",
    color: "#001542",
  }),
  option: (provided) => ({
    ...provided,
    fontSize: "15px", // Font boyutunu 9px olarak ayarlayın
  }),

  placeholder: (provided) => ({
    ...provided,
    fontSize: "13px", // Placeholder yazı boyutu
    marginLeft: "2px",
  }),
};

const labelStyle = {
  fontSize: "15px",
  marginRight: "0px",
  paddingRight: "0px",
};

export default LanguageAddModal;
