import React, { useEffect, useState } from "react";
import { Controller } from "react-hook-form";
import Select from "react-select";
import { connect } from "react-redux";
import { DropListWhiteAction } from "../../../../../../../action";
import languageAndLevelAdd from "../../Function/languageAndLevelAdd";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import LanguageItem from "../widgets/LanguageItem";
import DeleteLanguageModal from "../Modal/DeleteLanguagModal";
import LanguageAddModal from "../Modal/LanguageAddModal";
import BitonisSingleDropdown from "../../../../../../widgets/BitonisSingleDropdown";
import CustomDesign from "../../../../../../widgets/CustomDesign";

const FindWhiteLanguageAndLevel = (props) => {
  const {
    register,
    handleSubmit,
    control,
    setValue,
    errors,
    submitNewVersion,
    language,
    setLanguage,
    languageLevel,
    setLanguageLevel,
    languageList,
    setLanguageList,
    companyName,
    companyLocationName,
    getValues,
    watch,
  } = props;
  const [showAddLanguage, setShowAddLanguage] = useState(false);
  const [showDelete, setShowDelete] = useState(false);
  function languageDeleteHandleClose() {
    setShowDelete(false);
  }
  function languageHandleClose() {
    setShowAddLanguage(false);
  }

  useEffect(() => {
    if (!props.languageListWhite.success) {
      props.dispatch(
        DropListWhiteAction.languageListWhite({
          positionType: 2,
          isActive: true,
        })
      );
    }
    if (!props.languageLevelListWhite.success) {
      props.dispatch(
        DropListWhiteAction.languageLevelListWhite({
          positionType: 2,
          isActive: true,
        })
      );
    }
  }, []);
  const handleDeleteLanguageAndLevel = (itemToRemove) => {
    // Silinecek dil ve seviyenin kimliğini alın
    const languageIdToDelete = itemToRemove.languageId.value;
    const languageLevelIdToDelete = itemToRemove.languageLevelId.value;

    // Dil ve seviyeyi silmek için bir güncelleme yapın
    const updatedLanguageList = languageList.filter((item) => {
      return (
        item.languageId.value !== languageIdToDelete ||
        item.languageLevelId.value !== languageLevelIdToDelete
      );
    });

    // Güncellenmiş listeyi kullanın
    setLanguageList(updatedLanguageList);

    // Silme işlemi tamamlandığında kullanıcıya bir bildirim göstermek için toast veya benzeri bir kütüphane kullanabilirsiniz.
    toast.success("Dil ve seviye başarıyla silindi.");
  };

  const [langDeleteItem, setLangDeleteItem] = useState(null); //silme işleminde kullanılır

  return (
    <>
      <ToastContainer
        position="top-right"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    
      <div
        style={{
          margin: "0px",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-end",
            alignItems: "center",
            marginBottom: "20px",
            marginRight: "10px",
          }}
        >
          <div
            style={{
              width: "30px",
              height: "30px",
              margin: "0px 5px 0px 0px",
              padding: "0px",
            }}
          >
            <div
              style={{
                backgroundColor: "green",
                borderRadius: "6px",
                width: "30px",
                height: "30px",
                alignItems: "center",
                display: "flex",
                justifyContent: "center",
                transition: "fontSize 0.2s",
              }}
              onMouseOver={(e) => {
                e.currentTarget.style.width = "35px";
                e.currentTarget.style.height = "35px";
              }}
              onMouseOut={(e) => {
                e.currentTarget.style.width = "30px";
                e.currentTarget.style.height = "30px";
              }}
              onClick={() => {
                //dil eğer bir tane ise ekleyip inputlar gizlenir ve
                //yeni eklenecek diller dialog ile yapılmış olur.
                languageAndLevelAdd(
                  language,
                  setLanguage,
                  languageLevel,
                  setLanguageLevel,
                  languageList,
                  setLanguageList,
                  setValue
                );
                setShowAddLanguage(true);
              }}
            >
              {" "}
              <i
                class="fa-solid fa-plus"
                style={{
                  transition: "fontSize 0.2s",
                  color: "white",
                }}
              ></i>
            </div>
          </div>
          <div
            style={{
              color: "#001542",
              fontWeight: "550",
              marginLeft: "6px",
            }}
          >
            Yeni Yabancı Dil Ekle
          </div>
        </div>
        <div
          className="grid-container"
          style={{
            marginTop: "7px",
            marginBottom: "20px",
          }}
        >
          {" "}
          {languageList &&
            languageList.map((language, index) => {
              return (
                <div className="card .card-group-item">
                  {" "}
                  <LanguageItem
                    language={language}
                    index={index}
                    setShowDelete={() => {
                      setLangDeleteItem(language);
                      setShowDelete(true);
                    }}
                  />
                </div>
              );
            })}
        </div>

        {languageList.toString().length <= 0 ? (
          <div
            className="card"
            style={{
              margin: "0px",
              padding: "0px",
              margin: "10px 10px 0px 0px",
            }}
          >
            <div className="basic-form" style={{ marginTop: "2px" }}>
              <form onSubmit={handleSubmit(submitNewVersion)}>
                <CustomDesign
                  label={"Yabancı Dil"}
                  required={true}
                  child={
                    <BitonisSingleDropdown
                      items={
                        props.languageListWhite.languageListWhite &&
                        props.languageListWhite.languageListWhite.map(
                          ({ id, name }) => ({
                            value: id,
                            label: name,
                          })
                        )
                      }
                      register={register}
                      watch={watch}
                      companyClassName={"languageId"}
                      control={control}
                      setValue={setValue}
                      errors={errors}
                      placeholder="Yabancı Dil Seçin"
                      onValueChanged={(e) => {
                        console.log(e);
                        setLanguage(e);
                        setLanguageLevel(null);
                        setValue("languageLevelId", "");
                      }}
                      required={false}
                      getValues={getValues}
                      errorMessage={"Yabancı Dil Boş geçilemez"}
                    />
                  }
                />

                {language && (
                  <CustomDesign
                    label={"Yabancı Dil Seviyesi"}
                    required={true}
                    child={
                      <BitonisSingleDropdown
                        items={
                          props.languageLevelListWhite.languageLevelListWhite &&
                          props.languageLevelListWhite.languageLevelListWhite.map(
                            ({ id, name }) => ({
                              value: id,
                              label: name,
                            })
                          )
                        }
                        register={register}
                        watch={watch}
                        companyClassName={"languageLevelId"}
                        control={control}
                        setValue={setValue}
                        errors={errors}
                        placeholder="Yabancı Dil Seviyesi Seçin"
                        onValueChanged={(e) => {
                          console.log(e);
                          if (e) {
                            setLanguageLevel(e);
                          }
                        }}
                        required={
                          language == undefined || language == null
                            ? false
                            : true
                        }
                        getValues={getValues}
                        errorMessage={" Yabancı Dil Seviyesi boş geçilemez"}
                      />
                    }
                  />
                )}
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    margin: "0px",
                  }}
                ></div>
              </form>
            </div>
          </div>
        ) : (
          <div></div>
        )}
      </div>
      <LanguageAddModal
        show={showAddLanguage}
        handleClose={languageHandleClose}
        language={language}
        setLanguage={setLanguage}
        languageLevel={languageLevel}
        setLanguageLevel={setLanguageLevel}
        languageList={languageList}
        setLanguageList={setLanguageList}
        languageListWhite={props.languageListWhite.languageListWhite} //dil ve dil seviyeb listesi modal yapısına gönderildi
        languageLevelListWhite={
          props.languageLevelListWhite.languageLevelListWhite
        }
      />{" "}
      <DeleteLanguageModal
        show={showDelete}
        handleClose={languageDeleteHandleClose}
        approval={() => {
          handleDeleteLanguageAndLevel(langDeleteItem);
          setShowDelete(false);
        }}
      />
    </>
  );
};

//input stilinde kullanılır.
const customStyles = {
  control: (provided) => ({
    ...provided,
    borderRadius: "6px",
    padding: "10px 10px 10px 0px",
    border: "1px solid rgb(226, 226, 226)",
    fontSize: "15px",
    color: "#001542",
  }),
  option: (provided) => ({
    ...provided,
    fontSize: "15px", // Font boyutunu 9px olarak ayarlayın
  }),

  placeholder: (provided) => ({
    ...provided,
    fontSize: "13px", // Placeholder yazı boyutu
    marginLeft: "2px",
  }),
};
const labelStyle = {
  fontSize: "15px",
  marginRight: "0px",
  paddingRight: "0px",
};
function mapState(state) {
  let authentication = state.authentication;
  let languageListWhite = state.languageListWhite;
  let languageLevelListWhite = state.languageLevelListWhite;

  return {
    authentication,
    languageListWhite,
    languageLevelListWhite,
  };
}

export default connect(mapState)(FindWhiteLanguageAndLevel);
