import React, { useState } from "react";
import { DemandsServices } from "../../../../../../../../../../services";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { useDispatch } from "react-redux";
import {
  setCurrentTab,
  setFilterWhite,
} from "../../../../../../../../../../redux/redux-toolkit/filterMatch";
import CalendarModal from "../CustomDateTimePicker";
import { toast } from "react-toastify";
import CustomDatePicker from "../../../../../../../../../widgets/CustomDatePicker";
import { Box } from "@mui/material";
import CustomButton from "../../../../../../../../../widgets/buttons/CustomButton";

const ReevaluateCandidateMatching = ({ matchId, tooltip, candidateId }) => {
  //İş Yerinde Yeni Görüşme Günü Belirle
  const history = useHistory();
  const dispatch = useDispatch();
  function reevaluateCandidateMatching(matchId, candidateId) {
    DemandsServices.reevaluateCandidateMatching(matchId, candidateId).then(
      (sendMeetingData) => {
        if (sendMeetingData) {
          if (sendMeetingData.succeeded === true) {
            dispatch(setFilterWhite([{ status: 2 }]));
            dispatch(setCurrentTab(1));
            toast.success("Aday Yeniden Değerlendirildi", {
              autoClose: 2000,
              onClose: () => {
                setTimeout(() => {
                  history.push("/matchShow");
                }, 100);
              },
            });
          } else {
            toast.info(sendMeetingData.error.message, {
              autoClose: 2000,
              onClose: () => {
                setTimeout(() => {}, 100);
              },
            });
          }
        }
      },
      (error) => {
        toast.info("Beklenmedik Hata Oluştu", {
          autoClose: 2000,
          onClose: () => {
            setTimeout(() => {}, 100);
          },
        });
      }
    );
  }

  return (
    <div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Box
          sx={{
            margin: "0px",
          }}
        >
          <CustomButton
            onTap={(val) => {
              reevaluateCandidateMatching(matchId, candidateId);
            }}
            title={"Yeniden Değerlendir"}
            width={{
              xs: "380px",
              sm: "380px",
              md: "450px",
              lg: "450px",
              xl: "600px",
            }}
          />
        </Box>
      </div>
    </div>
  );
};

export default ReevaluateCandidateMatching;
