import Modal from "react-bootstrap/Modal";
import React, { useEffect, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import Select from "react-select";
import { connect, useDispatch } from "react-redux";
import { Button } from "react-bootstrap";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Box } from "@mui/material";
import CustomButton from "../../../../../../../../widgets/buttons/CustomButton";

function ShowNotHireCandidateModal({
  show,
  handleClose,
  matchedJobList,
  onChanged,
}) {
  function onHide() {
    handleClose(false);
  }

  var props = {
    show,
    onHide,
    matchedJobList,
    onChanged,
  };

  return (
    <>
      <div>
        <MyVerticallyCenteredModal props={props} />
      </div>
    </>
  );
}

function MyVerticallyCenteredModal({ props }) {
  const { show, onHide, matchedJobList, onChanged } = props;
  const [selectPositionMatch, setSelectPositionMatch] = useState([]);

  useEffect(() => {
    if (show) {
      setSelectPositionMatch([]);
    }
  }, [show]);
  const toggleSelection = (item) => {
    if (
      selectPositionMatch.some(
        (selectedItem) => selectedItem.matchId === item.matchId
      )
    ) {
      setSelectPositionMatch((prev) =>
        prev.filter((selectedItem) => selectedItem.matchId !== item.matchId)
      );
    } else {
      setSelectPositionMatch([...selectPositionMatch, item]);
    }
  };
  return (
    <Modal
      {...props}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      dialogClassName="full-screen-modal"
      style={{
        backgroundColor: "rgba(0, 0, 0, 0.2)",
      }}
      backdrop="static"
    >
      {" "}
      <Modal.Header closeButton style={{ borderBottom: "none" }}>
        <Modal.Title
          aria-labelledby="contained-modal-title-vcenter"
          style={{
            width: "100%",
            textAlign: "center",
            margin: "0 auto",
          }}
        >
          İşe Alma
        </Modal.Title>
      </Modal.Header>
      <Modal.Body
        style={{
          marginTop: "0px",
          paddingTop: "0px",
        }}
      >
        {matchedJobList && (
          <p
            style={{
              color: "#001542",
            }}
          >
            Aday aktif durumundaki{" "}
            <strong
              style={{
                color: "#001542",
                marginRight: "5px",
              }}
            >
              {matchedJobList.length}
            </strong>
            talebiniz ile eşleşti. Hangi pozisyonlar için İşe Almak
            istemiyorsunuz.
          </p>
        )}
        {matchedJobList &&
          matchedJobList.map((item, index) => (
            <div
              key={index}
              onClick={() => {
                toggleSelection(item);
              }}
              style={{
                paddingLeft: 5,
                cursor: "pointer",
                color: "#001542",
                border: "none",
                margin: "0px 0px 10px 0px",
                borderRadius: "8px",
                width: "100%",
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                backgroundColor: "rgb(242, 243, 244)",
                alignContent: "center",
                alignItems: "center",
              }}
            >
              <div style={{ display: "flex", alignItems: "center" }}>
                <input
                  type="checkbox"
                  style={{
                    marginLeft: "10px",
                  }}
                  checked={selectPositionMatch.some(
                    (selectedItem) => selectedItem.matchId === item.matchId
                  )}
                  onChange={() => {
                    toggleSelection(item);
                  }}
                />
                <button
                  onClick={(event) => {
                    event.preventDefault();
                    toggleSelection(item);
                  }}
                  style={{
                    color: "#001542",
                    padding: "10px",
                    outline: "none",
                    border: "none",
                    margin: "10px 0px 10px 0px",
                    borderRadius: "8px",
                    width: "100%",
                    display: "flex",
                    justifyContent: "start",
                    backgroundColor: "transparent", // Buton rengi olmaması için
                  }}
                >
                  <span
                    style={{
                      fontSize: 14,
                      fontWeight: 600,
                      color: "#001542",
                      paddingLeft: "20px",
                    }}
                  >
                    {item.jobPosition}
                  </span>
                </button>{" "}
              </div>
            </div>
          ))}
      </Modal.Body>
      <Modal.Footer
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          alignContent: "center",
          borderTop: "none",
          marginTop: "0px",
          paddingTop: "0px",
        }}
      >
        <Box
          sx={{
            margin: "0px",
          }}
        >
          <CustomButton
            onTap={(e) => {
              e.preventDefault();
              onChanged(selectPositionMatch);
              onHide();
            }}
            title={"İşe Alma"}
            width={{
              xs: "380px",
              sm: "380px",
              md: "450px",
              lg: "450px",
              xl: "450px",
            }}
          />
        </Box>
      </Modal.Footer>
    </Modal>
  );
}

export default ShowNotHireCandidateModal;
