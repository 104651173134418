import React, { useEffect, useRef, useState } from "react";
import {
  useHistory,
  useLocation,
} from "react-router-dom/cjs/react-router-dom.min";
import { connect, useDispatch, useSelector } from "react-redux";
import { DemandsServices } from "../../../../../../../../services";
import "../../../../../../../../css/bitoniscss/matchdetail.css";
import BlueInformation from "./components/BlueInformation";
import BlueWantToWorkPositions from "./components/BlueWantToWorkPositions";
import BlueActionButtons from "./components/BlueActionButtons";
import BlueLastWorkCompany from "./components/BlueLastWorkCompany";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import DividerDotted from "../../../../../../../widgets/DividerDotted";
import {
  createBlueCv,
  setBlueCvMatchDetail,
  setComponentRef,
} from "../../../../../../../../redux/redux-toolkit/matchDetailRedux";
import BlueCandidateCv from "../../Cv/BlueCandidateCv/BlueCandidateCv";
import { useReactToPrint } from "react-to-print";
import PdfWidget from "../../Cv/PdfWidget";
import BlueWantToLocation from "./components/BlueWantToLocation";
import useCurrentUserActionPageLog from "../../../../../../../hooks/useCurrentUserActionPageLog";
import LoadingDotsAnimation from "../../../../../../../widgets/LoadingDotsAnimation";
import PrevIconButton from "../../../../../../../widgets/buttons/PrevIconButton";
import { API_URL } from "../../../../../../../../services/ApiUrlServices";

const BlueMatchDetail = (props) => {
  const location = useLocation();
  const { dataMap } = location.state;
  const candidateId = dataMap.get("candidateId");
  const matchId = dataMap.get("matchId");
  const status = dataMap.get("status");
  const history = useHistory();
  const dispatch = useDispatch();
  const [blueMatchDetail, setBlueMatchDetail] = useState(null);

  const genderList = [
    //kontrol edildi
    {
      id: 0,
      name: "Farketmez",
    },
    {
      id: 1,
      name: "Kadın",
    },
    {
      id: 2,
      name: "Erkek",
    },
  ];

  const maritalStatusList = [
    //kontrol edildi
    {
      id: 0,
      name: "Farketmez",
    },
    {
      id: 1,
      name: "Bekar",
    },
    {
      id: 2,
      name: "Evli",
    },
  ];

  const driverLicense = [
    {
      id: 1,
      name: "Var",
    },
    {
      id: 2,
      name: "Yok",
    },
  ];

  const militaryStatusList = [
    //kontrol edildi
    {
      id: 0,
      name: "Farketmez",
    },
    {
      id: 1,
      name: "Tamamlandı",
    },
    {
      id: 2,
      name: "Tamamlanmadı",
    },
  ];

  const smokingList = [
    {
      id: true,
      name: "Evet",
    },
    {
      id: false,
      name: "Hayır",
    },
  ];
  var eductaionList = [
    {
      id: 0,
      name: "Farketmez",
    },
    {
      id: 1,
      name: "İlkokul",
    },
    {
      id: 2,
      name: "Ortaokul",
    },
    {
      id: 3,
      name: "Lise",
    },
    {
      id: 4,
      name: "Ön Lisans",
    },
    {
      id: 5,
      name: "Lisans",
    },
    {
      id: 6,
      name: "Yüksek Lisans",
    },
    {
      id: 7,
      name: "Doktora",
    },
  ];
  //çalışma süresi
  var haveTotalWorkInexperinced = [
    { name: "0-1 Yıl", id: 3 },
    { name: "1-5 Yıl", id: 4 },
    { name: "5-10 Yıl", id: 5 },
    { name: "10 Yıl ve Üzeri", id: 6 },
  ];
  var disabilityCandidateList = [
    { id: 2, name: "Var" },
    { id: 0, name: "Yok" },
  ];
  function calculateAge(jobSeekerDate) {
    var currentDate = new Date();
    var birthDate = new Date(jobSeekerDate);

    var age = currentDate.getFullYear() - birthDate.getFullYear();

    var currentMonth = currentDate.getMonth() + 1; // Ay indeksi 0'dan başlar, bu yüzden 1 ekliyoruz.
    var birthMonth = birthDate.getMonth() + 1;

    if (
      currentMonth < birthMonth ||
      (currentMonth === birthMonth &&
        currentDate.getDate() < birthDate.getDate())
    ) {
      age--;
    }

    return age;
  }

  const [employerId, setEmployerId] = useState(null);
  const [companyId, setCompanyId] = useState(null);
  function getEmployerHeaderData() {
    DemandsServices.getEmployerHeaderData().then((employerData) => {
      if (employerData && employerData.succeeded === true) {
        setEmployerId(employerData.data.employerId);
        setCompanyId(employerData.data.id);
      }
    });
  }

  useEffect(() => {
    if (candidateId) {
      getEmployerHeaderData();
      setBlueMatchDetail([]);
      DemandsServices.profileEmployerCandidateLabourer(
        candidateId,
        matchId,
        status
      ).then(
        (blueMatchDetail) => {
          if (blueMatchDetail && blueMatchDetail.succeeded === true) {
            var matchCandidateLocations = [];
            const getData = blueMatchDetail.data;
            var gender = genderList.find((val) => val.id === getData.gender);
            var maritalStatus = maritalStatusList.find(
              (val) => val.id === getData.maritalStatus
            );
            var driving = driverLicense.find(
              (val) => val.id === getData.drivingLicence
            );
            var military = null;
            if (getData.militaryStatus == null) {
            } else {
              military = militaryStatusList.find(
                (val) => val.id === getData.militaryStatus
              );
            }

            var education = eductaionList.find(
              (val) => val.id === getData.labourerEducationTypeId
            );
            var smoking = smokingList.find(
              (val) => val.id === getData.isSmoking
            );
            var yourWorkingTime;
            if (getData.labourerCandidateWorkExperience) {
              yourWorkingTime = haveTotalWorkInexperinced.find(
                (val) =>
                  val.id ===
                  getData.labourerCandidateWorkExperience.lastJobWorkingTime
              );
            } else {
              yourWorkingTime = null;
            }

            var age = calculateAge(getData.birthdate.toString());
            var disabilityName = "";
            var disabilityId = parseInt(
              getData && getData.disability.toString()
            );
            disabilityCandidateList.forEach((element) => {
              if (element.id.toString() == getData.disability.toString()) {
                disabilityName = element.name;
              }
            });
            var disabilityDegreeName = "";
            if (
              getData.candidateDegreeOfDisabilities.toString() == "[]" ||
              getData.candidateDegreeOfDisabilities.toString() == "null" ||
              getData.candidateDegreeOfDisabilities.toString() == "" ||
              getData.candidateDegreeOfDisabilities == undefined
            ) {
            } else {
              getData.candidateDegreeOfDisabilities.forEach((val) => {
                disabilityDegreeName =
                  disabilityDegreeName + " " + val.degreeOfDisabilityName;
              });
            }
            var matchRestrictionStatus = getData.matchRestrictionStatus;

            var matchedJobList = getData.matchedJobList;
            if (getData.candidatePreferredLocations) {
              matchCandidateLocations = getData.candidatePreferredLocations;
            } else {
            }

            var data = {
              matchId: getData.matchId,
              aboutNote:getData.aboutNote??"",
              profileImageUrl: getData.profileImageUrl,
              matchedJobList: matchedJobList,
              candidateId: getData.candidateId,
              disabilityName: disabilityName,
              disabilityId: disabilityId,
              disabilityDegreeName: disabilityDegreeName,
              candidateName: getData.name + " " + getData.surname,
              mobilephone: getData.mobilephone,
              birthDate: age,
              nationalityName: getData.nationalityName.toString(),
              genderName: gender.name.toString(),
              genderId: gender.id,
              maritalStatusName: maritalStatus.name.toString(),
              militaryStatusName: military.name,
              isSmokingName: smoking.name,
              matchRestrictionStatus: matchRestrictionStatus,
              matchCandidateLocations: matchCandidateLocations,
              driverLicenseName: driving.name,
              driverLicenseTypeName:
                getData.candidateDrivingLicenceTypes == null ||
                  getData.candidateDrivingLicenceTypes == [] ||
                  getData.candidateDrivingLicenceTypes.toString() == "[]"
                  ? null
                  : getData.candidateDrivingLicenceTypes,
              educationName: education.name,
              totalWorkExperience: getData.labourerExperienceRangeName,
              programs: getData.candidatePrograms,
              certificates: getData.candidateCertificates,
              competencies: getData.candidateCompetences,
              address:
                getData.countryName +
                "/" +
                getData.cityName +
                "/" +
                getData.countyName +
                "/" +
                getData.districtName,
              wantToWorkPositions: {
                positions: getData.candidatePositionPreferences,
                workTypes: getData.workTypes,
                weekendWork: "",
                sector: "",
                businessTravelPreference: "",
              },
              lastWorkCompany:
                getData.labourerCandidateWorkExperience == null ||
                  getData.labourerCandidateWorkExperience.lastJobCompanyName ==
                  null
                  ? null
                  : {
                    lastCompanyName:
                      getData.labourerCandidateWorkExperience &&
                        getData.labourerCandidateWorkExperience
                          .lastJobCompanyName == null
                        ? ""
                        : getData.labourerCandidateWorkExperience
                          .lastJobCompanyName,
                    positionName:
                      getData.labourerCandidateWorkExperience &&
                      getData.labourerCandidateWorkExperience
                        .lastJobPositionName,
                    yourWorkingTime: yourWorkingTime && yourWorkingTime.name,
                    address:
                      getData.labourerCandidateWorkExperience
                        .lastJobCountryName +
                      "/" +
                      getData.labourerCandidateWorkExperience
                        .lastJobCityName +
                      "/" +
                      getData.labourerCandidateWorkExperience
                        .lastJobCountyName,
                  },
            };
            dispatch(setBlueCvMatchDetail(data));
            //   dispatch(setComponentRef(componentRef));
            setBlueMatchDetail(data);
          }
        },
        (error) => { }
      );
    }
  }, [candidateId]);

  useCurrentUserActionPageLog(7, candidateId);




  const [matchPoint, setMatchPoint] = useState(null);
  useEffect(() => {
    setMatchPoint(null);
    if (candidateId) {
      DemandsServices.getBlueMatchPoint(matchId).then((getResponse) => {
        if (getResponse && getResponse.succeeded === true) {
          var getData = getResponse.data;
          if (getData) {
            var point = {
              isPositionMatched: getData.isPositionMatched,
              isPositionGroupMatched: getData.isPositionGroupMatched,
              isLocationMatched: getData.isLocationMatched,
              isNationMatched: getData.isNationMatched,
              isEducationMatched: getData.isEducationMatched,
              isTravelRestrictionMatched: getData.isTravelRestrictionMatched,
              isLanguageMatched: getData.isLanguageMatched,
              isDrivingLicenseTypeMatched: getData.isDrivingLicenseTypeMatched,
              isWorkTypeMatched: getData.isWorkTypeMatched,
              isExperienceMatched: getData.isExperienceMatched,
              isSalaryMatched: getData.isSalaryMatched,
              isWeekendWorkMatched: getData.isWeekendWorkMatched,
              isAgeRangeMatched: getData.isAgeRangeMatched,
              isCompetencesMatched: getData.isCompetencesMatched,
              isProgramsMatched: getData.isProgramsMatched,
              isCertificatesMatched: getData.isCertificatesMatched,
              isMilitaryStatusMatched: getData.isMilitaryStatusMatched,
              isGenderMatched: getData.isGenderMatched,
              isSectorMatched: getData.isSectorMatched,
              isSmokeMatched: getData.isSmokeMatched,
              isMaritalStatusMatched: getData.isMaritalStatusMatched,
            };
            setMatchPoint(point);
          }
        }
      });
    }
  }, [candidateId]);

  const pdfRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => pdfRef.current,
    documentTitle: "mavi-yaka-cv",
    onAfterPrint: () => { },
  });
  const [userImage, setUserImage] = useState(null);

  useEffect(() => {
    if (blueMatchDetail) {
      var candidateId = blueMatchDetail && blueMatchDetail.candidateId;
      var image = blueMatchDetail && blueMatchDetail.profileImageUrl;
      const imageUrl = `${API_URL}/images/candidate/profile/${candidateId}/${image}`;
      setUserImage(imageUrl);
    }
  }, [blueMatchDetail]);
  return (
    <>
      {blueMatchDetail && blueMatchDetail.length <= 0 ? <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
        }}
      >
        <LoadingDotsAnimation />
      </div>

        :
        <div>
          <ToastContainer
            position="top-right"
            autoClose={2000}
            hideProgressBar={false}
            newestOnTop
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
          />
          <div
            id="matchDetailBody"
            style={{
              position: "relative",

            }}
            className="card"
          >
            <BlueInformation
              cardStyle={cardStyle}
              labelStyle={labelStyle}
              inputStyle={inputStyle}
              matchPointStatus={matchPoint}
              blueMatchDetail={blueMatchDetail}
              userImage={userImage}
            />
            <DividerDotted />
            <BlueWantToLocation
              cardStyle={cardStyle}
              labelStyle={labelStyle}
              inputStyle={inputStyle}
              blueMatchDetail={blueMatchDetail}
            />
         
            {blueMatchDetail && blueMatchDetail.lastWorkCompany && (
              <BlueLastWorkCompany
                cardStyle={cardStyle}
                labelStyle={labelStyle}
                inputStyle={inputStyle}
                blueMatchDetail={blueMatchDetail}
              />
            )}
            <DividerDotted />
            <BlueActionButtons
              employerId={employerId}
              companyId={companyId}
              matchId={matchId}
              blueMatchDetail={blueMatchDetail}
              status={status}
            />
            <div
              style={{
                position: "absolute",
                top: "20px",
                right: "20px",
                width: "100px",
              }}
            >
              {blueMatchDetail && <PdfWidget handlePrint={handlePrint} />}
            </div>
            <div
              style={{
                display: "none",
              }}
            >
              <BlueCandidateCv
                blueMatchDetail={blueMatchDetail}
                componentRef={pdfRef}
              />
            </div>

            <PrevIconButton top={15} left={10} />

          </div>
        </div>
      }
    </>
  );
};

function mapState(state) {
  let authentication = state.authentication;
  let labourer = state.labourer;
  let labourerMatch = state.labourerMatch;
  let demands = state.demands;
  let job = state.job;

  let yearOfExperience = state.yearOfExperience;
  let positionList = state.positionList;
  let wayOfWorking = state.wayOfWorking;
  let nationalityList = state.nationalityList;
  let ageRangeList = state.ageRangeList;
  let driverLicenceType = state.driverLicenceType;
  let programList = state.programList;
  let certificatesList = state.certificatesList;
  let competencesList = state.competencesList;
  let countryList = state.countryList;
  let cityList = state.cityList;
  let countyList = state.countyList;
  let districtList = state.districtList;
  let companyList = state.companyList;
  let blueNewDemand = state.blueNewDemand;
  // Beyaz Yaka
  let yearOfExperienceWhite = state.yearOfExperienceWhite;
  let positionListWhite = state.positionListWhite;
  let wayOfWorkingWhite = state.wayOfWorkingWhite;
  let nationalityListWhite = state.nationalityListWhite;
  let ageRangeListWhite = state.ageRangeListWhite;
  let driverLicenceTypeWhite = state.driverLicenceTypeWhite;
  let programListWhite = state.programListWhite;
  let certificatesListWhite = state.certificatesListWhite;
  let competencesListWhite = state.competencesListWhite;
  let countryListWhite = state.countryListWhite;
  let cityListWhite = state.cityListWhite;
  let countyListWhite = state.countyListWhite;
  let districtListWhite = state.districtListWhite;
  let companyListWhite = state.companyListWhite;
  let salaryListWhite = state.salaryListWhite;
  let businessTravelListWhite = state.businessTravelListWhite;
  let languageListWhite = state.languageListWhite;
  let languageLevelListWhite = state.languageLevelListWhite;
  let universityListWhite = state.universityListWhite;
  let facultyListWhite = state.facultyListWhite;
  let facultyDepartmentListWhite = state.facultyDepartmentListWhite;
  let companyLocation = state.companyLocation;
  let companyItemLocation = state.companyItemLocation;
  let countyJobSeekerList = state.countyJobSeekerList;

  return {
    authentication,
    labourer,
    labourerMatch,
    demands,
    job,
    yearOfExperience,
    positionList,
    wayOfWorking,
    nationalityList,
    ageRangeList,
    driverLicenceType,
    programList,
    certificatesList,
    competencesList,
    countryList,
    cityList,
    countyList,
    districtList,
    companyList,
    yearOfExperienceWhite,
    positionListWhite,
    wayOfWorkingWhite,
    nationalityListWhite,
    ageRangeListWhite,
    driverLicenceTypeWhite,
    programListWhite,
    certificatesListWhite,
    competencesListWhite,
    countryListWhite,
    cityListWhite,
    countyListWhite,
    districtListWhite,
    companyListWhite,
    salaryListWhite,
    businessTravelListWhite,
    languageListWhite,
    languageLevelListWhite,
    universityListWhite,
    facultyListWhite,
    facultyDepartmentListWhite,
    blueNewDemand,
    companyLocation,
    companyItemLocation,
    countyJobSeekerList,
  };
}

export default connect(mapState)(BlueMatchDetail);

var inputStyle = {
  borderRadius: "8px",
  height: "55px",
  backgroundColor: "#F5F5F5",
  margin: "0px",
};
var cardStyle = {
  // border: "1.1px dotted orange",
  padding: "10px",
     margin:"0px"
};
var cardParentStyle = {
  border: "1.1px dotted orange",
  padding: "10px",
};
const customStyles = {
  control: (provided) => ({
    ...provided,
    borderRadius: "6px",
    padding: "10px 10px 10px 0px",
    border: "1px solid rgb(226, 226, 226)",
    fontSize: "15px",
    color: "#001542",
  }),
  option: (provided) => ({
    ...provided,
    fontSize: "15px", // Font boyutunu 9px olarak ayarlayın
  }),

  placeholder: (provided) => ({
    ...provided,
    fontSize: "13px", // Placeholder yazı boyutu
    marginLeft: "2px",
  }),
};
const customStylesMulti = {
  multiValue: (provided) => ({
    ...provided,
  }),
  control: (provided) => ({
    ...provided,
    borderRadius: "6px",
    padding: "10px 10px 10px 0px",
    border: "1px solid rgb(226, 226, 226)",
    fontSize: "15px",
    color: "#001542",
  }),
  multiValueLabel: (provided) => ({
    ...provided,
    fontSize: "15px", // MultiValue etiket yazı boyutu
  }),
  multiValueRemove: (provided) => ({
    ...provided,
    fontSize: "15px", // MultiValue kaldırma simgesi yazı boyutu
  }),
  option: (provided) => ({
    ...provided,
    fontSize: "15px", // Font boyutunu 9px olarak ayarlayın
  }),

  placeholder: (provided) => ({
    ...provided,
    fontSize: "13px", // Placeholder yazı boyutu
    marginLeft: "2px",
  }),
};
const labelStyle = {
  fontSize: "15px",
};
