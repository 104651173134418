// dataSlice.js
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  companyMainData: null,
  companyMainDataName: null,
};

const companyMainDataSlice = createSlice({
  name: "companyMainData",
  initialState,
  reducers: {
    setCompanyMainData: (state, action) => {
      state.companyMainData = action.payload;
    },
    setCompanyMainDataName: (state, action) => {
      state.companyMainDataName = action.payload;
    },
  },
});

export const { setCompanyMainData, setCompanyMainDataName } =
  companyMainDataSlice.actions;
export default companyMainDataSlice.reducer;
