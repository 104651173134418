import Modal from "react-bootstrap/Modal";
import React, { useEffect, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import Select from "react-select";
import { connect, useDispatch } from "react-redux";
import { Button } from "react-bootstrap";
import { CompetenciesTypesEnum } from "./ProgramsCertificatesCompetences";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import api from "../../../../../utility/Utils";
import { DemandsServices } from "../../../../../services";

function AddProgramsCertificatesCompetencesNotFoundModal({
  show,
  handleClose,
  competenciesType,
}) {
  function onHide() {
    handleClose(false);
  }

  var props = {
    show,
    onHide,
    competenciesType,
  };

  return (
    <>
      <div>
        <MyVerticallyCenteredModal props={props} />
      </div>
    </>
  );
}

function MyVerticallyCenteredModal({ props }) {
  const { show, onHide, competenciesType } = props;
  const [inputText, setInputText] = useState(null);

  const {
    register,
    handleSubmit,
    control,
    setValue,
    formState: { errors },
  } = useForm();
  useEffect(() => {
    if (show) {
      setInputText("");
    }
  }, [show]);
  function addSkills(form) {
    if (inputText === undefined || inputText === null || inputText === "") {
    } else {
      DemandsServices.addNewPcc(inputText).then(
        (response) => {
          alert("response* * * " + JSON.stringify(response));
          console.log("response* * * " + JSON.stringify(response));
          if (response) {
            if (response.succeeded === true) {
              toast.success(response.result.message, {
                autoClose: 2000,
                onClose: () => {
                  setTimeout(() => {}, 100);
                },
              });
            } else {
              toast.info(response.error.message, {
                autoClose: 2000,
                onClose: () => {
                  setTimeout(() => {}, 100);
                },
              });
            }
          }
        },
        (error) => {}
      );
    }

    onHide();
  }

  return (
    <Modal
      {...props}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      dialogClassName="full-screen-modal"
      style={{
        backgroundColor: "rgba(0, 0, 0, 0.2)",
      }}
      backdrop="static"
    >
      {" "}
      <Modal.Header closeButton style={{ borderBottom: "none" }}>
        <Modal.Title
          aria-labelledby="contained-modal-title-vcenter"
          style={{
            width: "100%",
            textAlign: "center",
            margin: "0 auto",
          }}
        >
          {competenciesType === CompetenciesTypesEnum.program
            ? "Program "
            : competenciesType === CompetenciesTypesEnum.certificate
            ? "Sertifika "
            : "Yetkinlik "}
          Ekle
        </Modal.Title>
      </Modal.Header>
      <Modal.Body
        style={{
          marginTop: "0px",
          paddingTop: "0px",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <div
            style={{
              backgroundColor: "#F7F7F7",
              width: "100%",
              display: "flex",
              flexDirection: "row",
              borderRadius: "8px",
              color: "#001542",
              fontSize: "13px",
            }}
          >
            <i
              class="fa-regular fa-star"
              style={{
                padding: "10px",
                color: "#fdc303",
              }}
            ></i>
            <TopTitle type={competenciesType} />
          </div>
          <TopContent type={competenciesType} />
          <textarea
            type="text"
            name={`inputText`}
            key={`inputText`}
            value={inputText}
            onChange={(val) => {
              setInputText(val.target.value);
            }}
            id="noter-text-area-active"
            className="form-control"
            placeholder="Mesajınız..."
            style={{
              backgroundColor: "rgb(237, 237, 239)",
              border: "1px solid grey",
              fontSize: "14px",
              marginBottom: "0px",
              paddingBottom: "0px",
            }}
          />{" "}
        </div>
      </Modal.Body>
      <Modal.Footer
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          alignContent: "center",
          borderTop: "none",
          marginTop: "0px",
          paddingTop: "0px",
        }}
      >
        {" "}
        <Button
          onClick={addSkills}
          as="input"
          type="submit"
          value="Gönder"
          style={{
            borderRadius: "8px",
            marginTop: "0px",
            fontSize: "15px",
            padding: "15px",
            width: "100%",
            cursor: "pointer",
            transition: "background-color 0.3s",
            backgroundColor: "#001542",
            border: "none",
          }}
        />
      </Modal.Footer>
    </Modal>
  );
}

const TopContent = ({ type }) => {
  return (
    <div>
      <div
        style={{
          color: "#001542",
          fontWeight: "600px",
          fontSize: "14px",
          marginBottom: "5px",
          marginTop: "5px",
        }}
      >
        {type == CompetenciesTypesEnum.program
          ? "Program"
          : type == CompetenciesTypesEnum.certificate
          ? "Sertifika"
          : "Yetkinlik"}
        Bilgisi Eksik mi ?
      </div>
      <div
        style={{
          color: "#001542",
          fontWeight: "600px",
          fontSize: "14px",
          marginBottom: "10px",
        }}
      >
        Bize Ulaşın ve Bilginizi Ekleyelim!
      </div>
    </div>
  );
};

const TopTitle = ({ type }) => {
  if (type == CompetenciesTypesEnum.program) {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
        }}
      >
        <div
          style={{
            padding: "10px",
          }}
        >
          Eğer aradığınız program bilgisini bulamazsanız, lütfen sahip olduğunuz
          program bilgisini mesaj bölümüne yazarak bize iletin. Bitoniş ekibi,
          sizin için ekleyip gerekli bilgileri sağlayacaktır.
        </div>
      </div>
    );
  } else if (type == CompetenciesTypesEnum.certificate) {
    return (
      <div>
        <div
          style={{
            padding: "10px",
          }}
        >
          Eğer aradığınız sertifika bilgisini bulamazsanız, lütfen sahip
          olduğunuz sertifika bilgisini mesaj bölümüne yazarak bize iletin.
          Bitoniş ekibi, sizin için ekleyip gerekli bilgileri sağlayacaktır.
        </div>
      </div>
    );
  } else {
    return (
      <div>
        <div
          style={{
            padding: "10px",
          }}
        >
          Eğer aradığınız yetkinlik bilgisini bulamazsanız, lütfen sahip
          olduğunuz yetkinlik bilgisini mesaj bölümüne yazarak bize iletin.
          Bitoniş ekibi, sizin için ekleyip gerekli bilgileri sağlayacaktır.{" "}
        </div>
      </div>
    );
  }
};
export default AddProgramsCertificatesCompetencesNotFoundModal;
