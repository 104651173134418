import React, { useEffect, useRef, useState } from "react";
import {
  useHistory,
  useLocation,
} from "react-router-dom/cjs/react-router-dom.min";
import { connect, useDispatch, useSelector } from "react-redux";
import { DemandsServices } from "../../../../../../../../services";
import "../../../../../../../../css/bitoniscss/matchdetail.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useReactToPrint } from "react-to-print";
import InternInformation from "./InternInformation";
import DividerDotted from "../../../../../../../widgets/DividerDotted";
import { setInternCvMatchDetail } from "../../../../../../../../redux/redux-toolkit/matchDetailRedux";
import InternWantToLocation from "../Intern/InternWantToLocation";
import InternCandidateCv from "../../Cv/Intern/InternCandidateCv";
import PdfWidget from "../../Cv/PdfWidget";
import InternActionButtons from "./InternActionButtons";
import useCurrentUserActionPageLog from "../../../../../../../hooks/useCurrentUserActionPageLog";
import LoadingDotsAnimation from "../../../../../../../widgets/LoadingDotsAnimation";

const InternMatchDetail = (props) => {
  const location = useLocation();
  const { dataMap } = location.state;
  const candidateId = dataMap.get("candidateId");
  useCurrentUserActionPageLog(9, candidateId); //loglama bilgisini apiye gönderir
  const [internMatchId, setInternMatchId] = useState(null);
  const [internMatchStatus, setInternMatchStatus] = useState(null);
  const matchId = dataMap.get("matchId");
  // const status = dataMap.get("status");
  const jobId = dataMap.get("jobId");
  const positionName = dataMap.get("positionName");
  const history = useHistory();
  const dispatch = useDispatch();
  const [internMatchDetail, setInternMatchDetail] = useState(null);

  const genderList = [
    //kontrol edildi
    {
      id: 0,
      name: "Farketmez",
    },
    {
      id: 1,
      name: "Kadın",
    },
    {
      id: 2,
      name: "Erkek",
    },
  ];

  function calculateAge(jobSeekerDate) {
    var currentDate = new Date();
    var birthDate = new Date(jobSeekerDate);

    var age = currentDate.getFullYear() - birthDate.getFullYear();

    var currentMonth = currentDate.getMonth() + 1; // Ay indeksi 0'dan başlar, bu yüzden 1 ekliyoruz.
    var birthMonth = birthDate.getMonth() + 1;

    if (
      currentMonth < birthMonth ||
      (currentMonth === birthMonth &&
        currentDate.getDate() < birthDate.getDate())
    ) {
      age--;
    }

    return age;
  }

  const [employerId, setEmployerId] = useState(null);
  const [companyId, setCompanyId] = useState(null);
  function getEmployerHeaderData() {
    DemandsServices.getEmployerHeaderData().then((employerData) => {
      if (employerData && employerData.succeeded === true) {
        setEmployerId(employerData.data.employerId);
        setCompanyId(employerData.data.id);
      }
    });
  }
  var educationInternList = [
    {
      id: 3,
      name: "Lise",
    },
    {
      id: 4,
      name: "Ön Lisans",
    },
    {
      id: 5,
      name: "Lisans",
    },
    {
      id: 6,
      name: "Yüksek Lisans",
    },
  ];
  var educationInternClassList = [
    {
      id: 1,
      name: "1. Sınıf",
    },
    {
      id: 2,
      name: "2. Sınıf",
    },
    {
      id: 3,
      name: "3. Sınıf",
    },
    {
      id: 4,
      name: "4. Sınıf",
    },
  ];
  useEffect(() => {
    if (candidateId) {
      getEmployerHeaderData();
      setInternMatchDetail(null);

      DemandsServices.profileEmployerIntern(candidateId, matchId).then(
        (internMatchResponse) => {
          if (internMatchResponse && internMatchResponse.succeeded === true) {
            var matchCandidateLocations = [];
            const getData = internMatchResponse.data;
            var gender = genderList.find((val) => val.id === getData.gender);

            var age = calculateAge(getData.birthdate.toString());

            if (getData.candidatePreferredLocations) {
              matchCandidateLocations = getData.candidatePreferredLocations;
            } else {
            }
            var school = educationInternList.find(
              (element) =>
                element.id == getData.internInformation.internEducationType
            );
            var selectSectionName = null;
            if (getData.internInformation.internEducationType == 3) {
              selectSectionName =
                getData.internInformation.internHighSchoolDepartmentName;
            } else {
              selectSectionName =
                getData.internInformation.internFacultyDepartmentGroupName;
            }

            var schoolClass = educationInternClassList.find(
              (element) =>
                element.id == getData.internInformation.internClassLevel
            );

            setInternMatchId(getData.candidateProfileMatchedJobs[0].matchId);
            setInternMatchStatus(
              getData.candidateProfileMatchedJobs[0].internMatchStatus
            );

            var data = {
              matchId: getData.candidateProfileMatchedJobs[0].matchId,
              candidateId: getData.candidateId,
              candidateName: getData.name + " " + getData.surname,
              mobilephone: getData.mobilephone,
              birthDate: age,
              nationalityName: getData.nationalityName.toString(),
              genderName: gender.name.toString(),
              educationTypeName: school.name,
              sectionName: selectSectionName,
              eduClassName: schoolClass.name,
              matchCandidateLocations: matchCandidateLocations,
            };
            dispatch(setInternCvMatchDetail(data));
            setInternMatchDetail(data);
          }
        },
        (error) => { }
      );
    }
  }, [candidateId]);
  const pdfRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => pdfRef.current,
    documentTitle: "stajyer-cv",
    onAfterPrint: () => { },
  });

  return (
    <>
      {
        internMatchDetail === null ? <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
          }}
        >
          <LoadingDotsAnimation />
        </div>

          :

          <div>
            <ToastContainer
              position="top-right"
              autoClose={2000}
              hideProgressBar={false}
              newestOnTop
              closeOnClick
              rtl={false}
              pauseOnFocusLoss
              draggable
              pauseOnHover
            />
            <div
              id="matchDetailBody"
              style={{
                position: "relative",
              }}
              className="card"
            >
              <InternInformation
                cardStyle={cardStyle}
                labelStyle={labelStyle}
                inputStyle={inputStyle}
                internMatchDetail={internMatchDetail}
              />
              <DividerDotted />
              <InternWantToLocation
                cardStyle={cardStyle}
                labelStyle={labelStyle}
                inputStyle={inputStyle}
                internMatchDetail={internMatchDetail}
              />{" "}
              {internMatchStatus === undefined ||
                internMatchStatus === null ||
                internMatchStatus === "" ? (
                <div></div>
              ) : (
                <div>
                  <InternActionButtons
                    employerId={employerId}
                    companyId={companyId}
                    matchId={internMatchId}
                    internMatchDetail={internMatchDetail}
                    internMatchStatus={internMatchStatus}
                    positionName={positionName}
                    jobId={jobId}
                  />
                </div>
              )}
              <div
                style={{
                  position: "absolute",
                  top: "20px",
                  right: "20px",
                  width: "100px",
                }}
              >
                {internMatchDetail && <PdfWidget handlePrint={handlePrint} />}
              </div>
              <div
                style={{
                  display: "none",
                }}
              >
                <InternCandidateCv
                  internMatchDetail={internMatchDetail}
                  componentRef={pdfRef}
                />
              </div>
            </div>
          </div>}
    </>
  );
};

function mapState(state) {
  let authentication = state.authentication;
  let labourer = state.labourer;
  let labourerMatch = state.labourerMatch;
  let demands = state.demands;
  let job = state.job;

  let yearOfExperience = state.yearOfExperience;
  let positionList = state.positionList;
  let wayOfWorking = state.wayOfWorking;
  let nationalityList = state.nationalityList;
  let ageRangeList = state.ageRangeList;
  let driverLicenceType = state.driverLicenceType;
  let programList = state.programList;
  let certificatesList = state.certificatesList;
  let competencesList = state.competencesList;
  let countryList = state.countryList;
  let cityList = state.cityList;
  let countyList = state.countyList;
  let districtList = state.districtList;
  let companyList = state.companyList;
  let blueNewDemand = state.blueNewDemand;
  // Beyaz Yaka
  let yearOfExperienceWhite = state.yearOfExperienceWhite;
  let positionListWhite = state.positionListWhite;
  let wayOfWorkingWhite = state.wayOfWorkingWhite;
  let nationalityListWhite = state.nationalityListWhite;
  let ageRangeListWhite = state.ageRangeListWhite;
  let driverLicenceTypeWhite = state.driverLicenceTypeWhite;
  let programListWhite = state.programListWhite;
  let certificatesListWhite = state.certificatesListWhite;
  let competencesListWhite = state.competencesListWhite;
  let countryListWhite = state.countryListWhite;
  let cityListWhite = state.cityListWhite;
  let countyListWhite = state.countyListWhite;
  let districtListWhite = state.districtListWhite;
  let companyListWhite = state.companyListWhite;
  let salaryListWhite = state.salaryListWhite;
  let businessTravelListWhite = state.businessTravelListWhite;
  let languageListWhite = state.languageListWhite;
  let languageLevelListWhite = state.languageLevelListWhite;
  let universityListWhite = state.universityListWhite;
  let facultyListWhite = state.facultyListWhite;
  let facultyDepartmentListWhite = state.facultyDepartmentListWhite;
  let companyLocation = state.companyLocation;
  let companyItemLocation = state.companyItemLocation;
  let countyJobSeekerList = state.countyJobSeekerList;

  return {
    authentication,
    labourer,
    labourerMatch,
    demands,
    job,
    yearOfExperience,
    positionList,
    wayOfWorking,
    nationalityList,
    ageRangeList,
    driverLicenceType,
    programList,
    certificatesList,
    competencesList,
    countryList,
    cityList,
    countyList,
    districtList,
    companyList,
    yearOfExperienceWhite,
    positionListWhite,
    wayOfWorkingWhite,
    nationalityListWhite,
    ageRangeListWhite,
    driverLicenceTypeWhite,
    programListWhite,
    certificatesListWhite,
    competencesListWhite,
    countryListWhite,
    cityListWhite,
    countyListWhite,
    districtListWhite,
    companyListWhite,
    salaryListWhite,
    businessTravelListWhite,
    languageListWhite,
    languageLevelListWhite,
    universityListWhite,
    facultyListWhite,
    facultyDepartmentListWhite,
    blueNewDemand,
    companyLocation,
    companyItemLocation,
    countyJobSeekerList,
  };
}

export default connect(mapState)(InternMatchDetail);

var inputStyle = {
  borderRadius: "8px",
  height: "55px",
  backgroundColor: "#F5F5F5",
  margin: "0px",
};
var cardStyle = {
  // border: "1.1px dotted orange",
  padding: "10px",
};
var cardParentStyle = {
  border: "1.1px dotted orange",
  padding: "10px",
};
const customStyles = {
  control: (provided) => ({
    ...provided,
    borderRadius: "6px",
    padding: "10px 10px 10px 0px",
    border: "1px solid rgb(226, 226, 226)",
    fontSize: "15px",
    color: "#001542",
  }),
  option: (provided) => ({
    ...provided,
    fontSize: "15px", // Font boyutunu 9px olarak ayarlayın
  }),

  placeholder: (provided) => ({
    ...provided,
    fontSize: "13px", // Placeholder yazı boyutu
    marginLeft: "2px",
  }),
};
const customStylesMulti = {
  multiValue: (provided) => ({
    ...provided,
  }),
  control: (provided) => ({
    ...provided,
    borderRadius: "6px",
    padding: "10px 10px 10px 0px",
    border: "1px solid rgb(226, 226, 226)",
    fontSize: "15px",
    color: "#001542",
  }),
  multiValueLabel: (provided) => ({
    ...provided,
    fontSize: "15px", // MultiValue etiket yazı boyutu
  }),
  multiValueRemove: (provided) => ({
    ...provided,
    fontSize: "15px", // MultiValue kaldırma simgesi yazı boyutu
  }),
  option: (provided) => ({
    ...provided,
    fontSize: "15px", // Font boyutunu 9px olarak ayarlayın
  }),

  placeholder: (provided) => ({
    ...provided,
    fontSize: "13px", // Placeholder yazı boyutu
    marginLeft: "2px",
  }),
};
const labelStyle = {
  fontSize: "15px",
};
