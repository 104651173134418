import * as React from "react";
import GoogleMapWidget from "../../../widgets/map/GoogleMapWidget";


export default function TestWidget() {
  const initialLatLng = { lat: 39.925533, lng: 32.866287 }; // Örneğin, İstanbul

  const [lat, setLat] = React.useState(initialLatLng.lat);
  const [lng, setLng] = React.useState(initialLatLng.lng);
  return (
    <div>
      <GoogleMapWidget
        initialLatLng={{
          lat: "",
          lng: "",
        }}
        onChangedPositioned={
          (position)=>{
          
            setLat(position.lat);
            setLng(position.lng);
          }
        }
      />
    </div>
  );
}
