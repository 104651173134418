import React, { useState } from "react";
import moment from "moment";
import { Dropdown } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css"; // Bootstrap stillerini ekleyin
import BlueDemandCopy from "../Common/DemandCopy";
import { Button, Menu, MenuItem } from "@mui/material";
import PopupLongMenu from "../../../../../../../widgets/PopupLongMenu";
import { DemandsServices } from "../../../../../../../../services";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { Box, Typography } from "@mui/material";
import { Row } from "react-bootstrap";

function WhiteDemandItem({ demand, onTap }) {
  const history = useHistory();

  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  const getBackgroundColor = () => {
    if (isHovered) {
      return "#F5F5F5";
    }

    // Diğer durumlar için renkleri döndürün
    return "white";
  };

  return (
    <div>
      <Box
        onClick={onTap}
        className="card-body"
        key={demand.candidateId}
        sx={{
          margin: "8px",
          padding: "0px",
          position: "relative",
          display: "flex",
          flexDirection: "row",
          display: "flex",
          flexDirection: "row",
          border: isHovered == true ? "1px solid #D6E5FA" : "1px solid #F7F7F7",

          height: "60px",
          position: "relative",
          borderRadius: "10px",
          alignItems: "center",
          width: "100%",
          margin: "8px",
          justifyContent: "space-between",
          cursor: "pointer",
          backgroundColor: getBackgroundColor(), // Dinamik arka plan rengi
        }}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
   
          <Row
            sx={{
              display: "flex",
              flexDirection: "row",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-start",
                alignContent: "center",
                alignItems: "center",

                marginLeft: {
                  xs: "15px",
                  sm: "20px",
                  md: "20px",
                  lg: "20px",
                  xl: "20px",
                },
              }}
            >
              <span
                className="fa-stack fa-lg"
                style={{
                  marginLeft: "10px",
                }}
              >
                <i class="fa-solid fa-user"></i>
              </span>

              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignContent: "center",
                  alignItems: "flex-start",
                  marginTop: "20px"
                }}
              >
                <Typography
                  sx={{
                    width: {
                      xs: "90px",
                      sm: "120px",
                      md: "150px",
                      lg: "200px",
                      xl: "200px",
                    },
                    marginLeft: {
                      xs: "0px",
                      sm: "0px",
                      md: "0px",
                      lg: "0px",
                      xl: "0px",
                    },

                    fontSize: {
                      xs: "9px",
                      sm: "11px",
                      md: "11px",
                      lg: "12px",
                      xl: "12px",
                    },

                    fontWeight: 600,
                    color: {
                      xs: "#001542",
                      sm: "#001542",
                      md: "#001542",
                      lg: "#001542",
                      xl: "#001542",
                    },
                  }}
                >
                  {demand.positionName}
                </Typography>{" "}
                <Typography
                  sx={{
                    fontSize: {
                      xs: "8px",
                      sm: "9px",
                      md: "10px",
                      lg: "10px",
                      xl: "10px",
                    },

                    fontWeight: 600,
                    color: "grey",
                  }}
                >
                  <p>Eleman Sayısı: {demand.personCount}</p>
                </Typography>
              </Box>
            </Box>
          </Row>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-start",
              alignContent: "flex-start",
              alignItems: "flex-start",
              textAlign: "center",
            }}
          >
            <Typography
              sx={{
                marginTop: "20px",
                textAlign: "center",
                width: {
                  xs: "120px",
                  sm: "100%",
                  md: "100%",
                  lg: "100%",
                  xl: "100%",
                },
                fontSize: {
                  xs: "9px",
                  sm: "11px",
                  md: "11px",
                  lg: "11px",
                  xl: "11px",
                },
                fontWeight: 600,
                color: {
                  xs: "#001542",
                  sm: "#001542",
                  md: "#001542",
                  lg: "#001542",
                  xl: "#001542",
                },
                textAlign: "center",

              }}
            >
              <div>
                {" "}
                Talep Tarihi: {moment(demand.demandDate).format("DD/MM/YYYY")}
                <div
                  style={{
                    fontSize: "9px",
                    fontWeight: 600,
                    color: "#001542",
                  }}
                >
                  {" "}

                  <Typography
                    sx={{

                      textAlign: "center",

                      fontSize: {
                        xs: "9px",
                        sm: "11px",
                        md: "11px",
                        lg: "11px",
                        xl: "11px",
                      },
                      fontWeight: 600,
                      color: {
                        xs: demand.isActive?"green":  "red",
                        sm: demand.isActive?"green":  "red",
                        md: demand.isActive?"green":  "red",
                        lg: demand.isActive?"green":  "red",
                        xl:demand.isActive?"green":  "red",
                      },
                      textAlign: "center",

                    }}
                  >
                    {demand.isActive === true ? <p> Talep Durumu:Aktif</p> : <p> Talep Durumu : Pasif</p>}


                  </Typography>
                </div>
              </div>
            </Typography>
          </Box>


     
        <Box></Box>
        <Box></Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-end",
            alignContent: "flex-end",
            alignItems: "flex-end",
          }}
        >
          <Box
            onClick={(event) => {
              event.stopPropagation(); // Olayın yayılmasını durdur

              if (demand.didDemandExpired === false) {
                const dataMap = new Map();
                dataMap.set("jobId", demand.jobId);
                dataMap.set("positionName", demand.positionName);
                history.push({
                  pathname: "/demands/whiteCurrentDemandMatchShow",
                  state: { dataMap },
                });
              } else {
                toast.error(
                  "Talebinizle eşleşen adayları görme süreniz doldu. Yeni bir talep oluşturarak eşleşme sağlayabilirsiniz.",
                  {
                    autoClose: 5000,
                    onClose: () => {
                      setTimeout(() => { }, 1000);
                    },
                  }
                );
              }
            }}
            sx={{
              height: "63px",
              width: "55px",
              padding: "5px",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              justifyItems: "center",
              alignContent: "center",
              alignItems: "center",
              border: "1px solid grey",
              borderRadius: "0px 0px 0px 0px",
              margin: "0px",
              border: "1px solid #f5f5f5",
              backgroundColor:
                demand && demand.didDemandExpired === true ? "grey" : "teal",
              color: "white",
              cursor: "pointer",
            }}
          >
            <i
              class="fa-solid fa-users"
              style={{
                marginBottom: "2px",
              }}
            ></i>

            <h6
              style={{
                fontSize: "9px",
                margin: 0,
                padding: 0,
                color: "white",
                textAlign: "center",
              }}
            >
              Talebinizle Eşleşenler
            </h6>
          </Box>
          <Box
            onClick={(event) => {
              event.stopPropagation(); // Olayın yayılmasını durdur

              const dataMap = new Map();
              dataMap.set("jobId", demand.jobId);
              dataMap.set("positionName", demand.positionName);

              history.push({
                pathname: "/demands/whiteCurrentDemandMediumMatchShow",
                state: { dataMap },
              });
            }}
            sx={{
              height: "63px",
              width: "55px",
              padding: "5px",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              justifyItems: "center",
              alignContent: "center",
              alignItems: "center",
              border: "1px solid grey",
              borderRadius: "0px 8px 8px 0px",
              margin: "0px",
              border: "1px solid #f5f5f5",
              backgroundColor: "#FFB40D",
              color: "white",
              cursor: "pointer",
            }}
          >
            <i
              class="fa-solid fa-percent"
              style={{
                marginBottom: "2px",
              }}
            ></i>
            <h6
              style={{
                fontSize: "9px",
                margin: 0,
                padding: 0,
                color: "white",
                textAlign: "center",
              }}
            >
              Diğer Eşleşen Adaylar
            </h6>
          </Box>
        </Box>
      </Box>
    </div>
  );
}

export default WhiteDemandItem;

/*

  const history = useHistory();

  return (
    <div
      className="card"
      style={{
        margin: "8px 0px 0px 0px",
        padding: "0px",
        position: "relative",
        display: "flex",
        flexDirection: "row",
      }}
    >
      <div
        onClick={onTap}
        className="whiteItemContainer"
        style={{
          position: "relative",
          height: "65px",
        }}
      >
        <div className="whiteUserIconItem">
          <span
            className="fa-stack fa-lg"
            style={{
              marginRight: "5px",
              marginLeft: "10px",
            }}
          >
            <i class="fa-solid fa-user"></i>
          </span>
          <div
            className="whiteItemPositionClass"
            style={{
              marginBottom: "10px",
            }}
          >
            {demand.positionName}
            <p className="demand-count">Eleman Sayısı: {demand.personCount}</p>
          </div>
        </div>
        <div className="stateDate">
          <div
            style={{
              marginTop: "15px",
            }}
          >
            <div className="whiteItemDate">
              Talep Tarihi: {moment(demand.demandDate).format("DD/MM/YYYY")}
            </div>{" "}
            <div className="itemStatusWhite">
              {demand.isActive === true ? (
                <h6
                  className="itemStatusWhite"
                  style={{
                    color: "green",
                    marginTop: "5px",
                  }}
                >
                  Talep Durumu:Aktif
                </h6>
              ) : (
                <h6
                  className="itemStatusWhite"
                  style={{
                    color: "red",
                    marginTop: "5px",
                  }}
                >
                  Talep Durumu : Pasif
                </h6>
              )}
            </div>
          </div>
        </div>

        <div className="sizedBox"></div>
      </div>
      <div
        onClick={() => {
          if (demand.didDemandExpired === false) {
            const dataMap = new Map();
            dataMap.set("jobId", demand.jobId);
            dataMap.set("positionName", demand.positionName);
            history.push({
              pathname: "/demands/whiteCurrentDemandMatchShow",
              state: { dataMap },
            });
          } else {
            toast.error(
              "Talebinizle eşleşen adayları görme süreniz doldu. Yeni bir talep oluşturarak eşleşme sağlayabilirsiniz.",
              {
                autoClose: 5000,
                onClose: () => {
                  setTimeout(() => {}, 1000);
                },
              }
            );
          }
        }}
        style={{
          height: "65px",
          width: "60px",
          padding: "5px",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          justifyItems: "center",
          alignContent: "center",
          alignItems: "center",
          border: "1px solid grey",
          borderRadius: "0px 0px 0px 0px",
          margin: "0px",
          border: "1px solid #f5f5f5",
          backgroundColor:
            demand && demand.didDemandExpired === true ? "grey" : "teal",
          color: "white",
          cursor: "pointer",
        }}
      >
        <i
          class="fa-solid fa-users"
          style={{
            marginBottom: "2px",
          }}
        ></i>

        <h6
          style={{
            fontSize: "9px",
            margin: 0,
            padding: 0,
            color: "white",
            textAlign: "center",
          }}
        >
          Talebinizle Eşleşenler
        </h6>
      </div>{" "}
      <div
        onClick={() => {
          const dataMap = new Map();
          dataMap.set("jobId", demand.jobId);
          dataMap.set("positionName", demand.positionName);

          history.push({
            pathname: "/demands/whiteCurrentDemandMediumMatchShow",
            state: { dataMap },
          });
        }}
        style={{
          height: "65px",
          width: "60px",
          padding: "5px",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          justifyItems: "center",
          alignContent: "center",
          alignItems: "center",
          border: "1px solid grey",
          borderRadius: "0px 8px 8px 0px",
          margin: "0px",
          border: "1px solid #f5f5f5",
          backgroundColor: "#FFB40D",
          color: "white",
          cursor: "pointer",
        }}
      >
        <i
          class="fa-solid fa-percent"
          style={{
            marginBottom: "2px",
          }}
        ></i>
        <h6
          style={{
            fontSize: "9px",
            margin: 0,
            padding: 0,
            color: "white",
            textAlign: "center",
          }}
        >
          Diğer Eşleşen Adaylar
        </h6>
      </div>
    </div>
  );

  */
