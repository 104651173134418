import React from "react";
import Select from "react-select";
import { useForm, Controller } from "react-hook-form";
import {
  customStylesMulti,
  labelStyle,
} from "../../../../../customStyleInline";
import BitonisMultiDropdown from "../../../../../widgets/BitonisMultiDropdown";
import CustomDesign from "../../../../../widgets/CustomDesign";

const LicenseTypeRequiredUpdate = ({
  licenseIsShow,
  props,
  control,
  register,
  errors,
  checkCLicenseType,
  setCheckLicenseType,
  watch,
  setValue,
  getValues,
}) => {
  return (
    <>
      {licenseIsShow && (
        <CustomDesign
          label={"Sürücü Belgesi Türü"}
          required={true}
          child={
            <BitonisMultiDropdown
              items={
                props.driverLicenceType.driverLicenceType &&
                props.driverLicenceType.driverLicenceType.map(
                  ({ id, name }) => ({
                    value: id,
                    label: name,
                  })
                )
              }
              register={register}
              watch={watch}
              companyClassName={"drivingLicenceTypes"}
              control={control}
              setValue={setValue}
              errors={errors}
              errorMessage={"Sürücü Belgesi Türü Boş geçilemez"}
              placeholder="Sürücü Belgesi Türü"
              getValues={getValues}
              onValueChanged={(value) => {
                console.log(value);
              }}
              required={true}
              requiredSelectedItemsBoolean={true}
              requiredSelectedItemsValues={checkCLicenseType}
              setRequiredSelectedItemsValues={setCheckLicenseType}
              requiredSelectedItemsText={
                <div>
                  Adayın eşleşmesi için gereken şart:
                  <br /> Aşağıda seçtiğiniz tüm sürücü belgelerine sahip
                  olmasını istiyorsanız,lütfen bu seçeneği işaretleyin.
                </div>
              }
            />
          }
        />
      )}
    </>
  );
};

export default LicenseTypeRequiredUpdate;
{
  /*
       <div className="basic-form">
          <div className="form-group row">
            <label className="col-sm-3 col-form-label" style={labelStyle}>
              {" "}
              Sürücü Belgesi Türü{" "}
              <label
                style={{
                  color: "red",
                }}
              >
                *
              </label>
            </label>{" "}
            <div className="col-sm-9"></div>
          </div>
      </div>
      */
}
