import React, { useEffect, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import Select from "react-select";
import { connect, useDispatch } from "react-redux";
import {
  DashboardAction,
  DropListAction,
  DropListWhiteAction,
} from "../../../../../../../action";
import {
  customStyles,
  customStylesMulti,
  labelStyle,
} from "../../../../../../customStyleInline";
import BitonisMultiDropdown from "../../../../../../widgets/BitonisMultiDropdown";
import BitonisSingleDropdown from "../../../../../../widgets/BitonisSingleDropdown";
import CustomDesign from "../../../../../../widgets/CustomDesign";
const FindWhiteFeatures = (props) => {
  const {
    register,
    handleSubmit,
    control,
    watch,
    reset,
    setValue,
    errors,
    submitNewVersion,
    companyName,
    companyLocationName,
    getValues,
  } = props;
  useEffect(() => {
    if (!props.nationalityList.success) {
      props.dispatch(
        DropListAction.nationalityList({
          positionType: 1,
          isActive: true,
        })
      );
    }

    if (!props.driverLicenceType.success) {
      props.dispatch(DropListAction.driverLicenceType());
    }
  }, []);

  // console.log(props)
  const gorumeGunleriList = [
    { id: 0, name: "Farketmez" },
    { id: 1, name: "Pazartesi" },
    { id: 2, name: "Salı" },
    { id: 3, name: "Çarşamba" },
    { id: 4, name: "Perşembe" },
    { id: 5, name: "Cuma" },
    { id: 6, name: "Cumartesi" },
    { id: 7, name: "Pazar" },
  ];

  const [smokingValue, setSmokingValue] = useState("Hayır");

  const educationStatus = [
    //kontrol edildi
    { id: 0, name: "Farketmez" },
    { id: 1, name: "İlkokul" },
    { id: 2, name: "Ortaokul" },
    { id: 3, name: "Lise" },
    { id: 4, name: "Ön Lisans" },
    { id: 5, name: "Lisans" },
    { id: 6, name: "Yüksek Lisans" },
    { id: 7, name: "Doktora" },
  ];
  const genderList = [
    //kontrol edildi
    { id: 0, name: "Farketmez" },
    { id: 1, name: "Kadın" },
    { id: 2, name: "Erkek" },
  ];

  const maritalStatus = [
    //kontrol edildi
    { id: 0, name: "Farketmez" },
    { id: 1, name: "Bekar" },
    { id: 2, name: "Evli" },
  ];

  const driverLicense = [
    //kontrol edildi
    { id: 0, name: "Farketmez" },
    { id: 1, name: "Var" },
  ];

  const militaryStatus = [
    //kontrol edildi
    { id: 0, name: "Farketmez" },
    { id: 1, name: "Tamamlandı" },
    { id: 2, name: "Tamamlanmadı" },
  ];
  const weekendWork = [
    { id: 1, name: "Yok" },
    { id: 2, name: "Tam Gün" },
    { id: 3, name: "Yarım Gün" },
    { id: 4, name: "Dönüşümlü" },
  ];
  const numberOfSearches = [
    //kontrol edildi
    { id: 1, name: "1" },
    { id: 2, name: "2" },
    { id: 3, name: "3" },
    { id: 4, name: "4" },
    { id: 5, name: "5" },
    { id: 6, name: "6" },
    { id: 7, name: "7" },
    { id: 8, name: "8" },
    { id: 9, name: "9" },
    { id: 10, name: "10" },
  ];
  const smoking = [
    //kontrol edildi
    { id: 0, name: "Farketmez" },
    { id: 2, name: "Hayır" },
  ];
  const [licenseIsShow, setLicenseIsShow] = useState(true);
  const [militaryIsShow, setMilitaryIsShow] = useState(true);

  const handleLicenseChange = (selectedLicense) => {
    if (selectedLicense && selectedLicense.value === 0) {
      setValue("drivingLicenceTypes", []);
      setLicenseIsShow(false);
    } else {
      setLicenseIsShow(true);
    }
  };
  const handleMilitaryStatus = (selectedGender) => {
    if (
      selectedGender &&
      (selectedGender.value === 1 || selectedGender.value == 0)
    ) {
      setMilitaryIsShow(false);
    } else {
      setMilitaryIsShow(true);
    }
    if (selectedGender.value == 0 || selectedGender.value == 1) {
      setValue("militaryStatus", "");
    }
  };

  useEffect(() => {
    const gender = watch("gender");
    //steplerden ilerlenip geri geldiğinde veya ilerlediğinde cinsiyete göre askerlik durumu kontrol edildi
    //cinsiyet kadınsa askerlik durumu gizlendi
    if (gender) {
      if (gender && (gender.value === 1 || gender.value == 0)) {
        setMilitaryIsShow(false);
      } else {
        setMilitaryIsShow(true);
      }
      if (gender.value == 0 || gender.value == 1) {
        setValue("militaryStatus", "");
      }
    } else {
      setMilitaryIsShow(false);
    }
    const drivingLicence = watch("drivingLicence");
    if (
      (drivingLicence && drivingLicence.value === 0) ||
      drivingLicence === undefined ||
      drivingLicence === ""
    ) {
      setValue("drivingLicenceTypes", []);
      setLicenseIsShow(false);
    } else {
      setLicenseIsShow(true);
    }
  }, []);

  return (
    <div>
      <div
        className="card"
        style={{
          boxShadow: "none",
        }}
      >
        <div className="basic-form">
          <form onSubmit={handleSubmit(submitNewVersion)}>
            <CustomDesign
              label={"Uyruk"}
              required={true}
              child={
                <BitonisSingleDropdown
                  items={
                    props.nationalityList.nationalityList &&
                    props.nationalityList.nationalityList.map(
                      ({ id, name }) => ({
                        value: id,
                        label: name,
                      })
                    )
                  }
                  register={register}
                  watch={watch}
                  companyClassName={"nationalityId"}
                  control={control}
                  setValue={setValue}
                  errors={errors}
                  placeholder="Uyruk Seçin"
                  onValueChanged={(e) => {
                    console.log(e);
                    if (e) {
                    }
                  }}
                  required={true}
                  getValues={getValues}
                  errorMessage={"Uyruk Boş geçilemez"}
                />
              }
            />
            <CustomDesign
              label={"Cinsiyet"}
              required={true}
              child={
                <BitonisSingleDropdown
                  items={genderList.map(({ id, name }) => ({
                    value: id,
                    label: name,
                  }))}
                  register={register}
                  watch={watch}
                  companyClassName={"gender"}
                  control={control}
                  setValue={setValue}
                  errors={errors}
                  placeholder="Cinsiyet Seçin"
                  onValueChanged={(e) => {
                    console.log(e);
                    if (e) {
                      handleMilitaryStatus(e);
                    }
                  }}
                  required={true}
                  getValues={getValues}
                  errorMessage={"Cinsiyet Boş geçilemez"}
                />
              }
            />
            {militaryIsShow && (
              <CustomDesign
                label={"Askerlik Durumu"}
                required={true}
                child={
                  <BitonisSingleDropdown
                    items={militaryStatus.map(({ id, name }) => ({
                      value: id,
                      label: name,
                    }))}
                    register={register}
                    watch={watch}
                    companyClassName={"militaryStatus"}
                    control={control}
                    setValue={setValue}
                    errors={errors}
                    placeholder="Askerlik Durumu Seçin"
                    onValueChanged={(e) => {
                      console.log(e);
                      if (e) {
                      }
                    }}
                    required={true}
                    getValues={getValues}
                    errorMessage={"Askerlik Durumu Boş geçilemez"}
                  />
                }
              />
            )}
            <CustomDesign
              label={"Yaş Aralığı"}
              required={true}
              child={
                <BitonisMultiDropdown
                  items={
                    props.ageRangeListWhite.ageRangeListWhite &&
                    props.ageRangeListWhite.ageRangeListWhite.map(
                      ({ id, name }) => ({
                        value: id,
                        label: name,
                      })
                    )
                  }
                  register={register}
                  watch={watch}
                  companyClassName={"ageRanges"}
                  control={control}
                  setValue={setValue}
                  errors={errors}
                  errorMessage={"Yaş Aralığı Boş geçilemez"}
                  placeholder="Yaş Aralığı"
                  onValueChanged={(value) => {
                    console.log(value);
                  }}
                  required={true}
                  selectedId={1}
                  getValues={getValues}
                />
              }
            />
            <CustomDesign
              label={"Sigara Kullanımı"}
              required={true}
              child={
                <BitonisSingleDropdown
                  items={smoking.map(({ id, name }) => ({
                    value: id,
                    label: name,
                  }))}
                  register={register}
                  watch={watch}
                  companyClassName={"smokerApply"}
                  control={control}
                  setValue={setValue}
                  errors={errors}
                  placeholder="Sigara Kullanımı Seçin"
                  onValueChanged={(e) => {
                    console.log(e);
                    if (e) {
                    }
                  }}
                  required={true}
                  getValues={getValues}
                  errorMessage={"Sigara Kullanımı Boş geçilemez"}
                />
              }
            />
            <CustomDesign
              label={"Medeni Hal"}
              required={true}
              child={
                <BitonisSingleDropdown
                  items={maritalStatus.map(({ id, name }) => ({
                    value: id,
                    label: name,
                  }))}
                  register={register}
                  watch={watch}
                  companyClassName={"maritalStatus"}
                  control={control}
                  setValue={setValue}
                  errors={errors}
                  placeholder="Medeni Hal Seçin"
                  onValueChanged={(e) => {
                    console.log(e);
                    if (e) {
                    }
                  }}
                  required={true}
                  getValues={getValues}
                  errorMessage={"Medeni Hal Boş geçilemez"}
                />
              }
            />
            <CustomDesign
              label={"Sürücü Belgesi"}
              required={true}
              child={
                <BitonisSingleDropdown
                  items={driverLicense.map(({ id, name }) => ({
                    value: id,
                    label: name,
                  }))}
                  register={register}
                  watch={watch}
                  companyClassName={"drivingLicence"}
                  control={control}
                  setValue={setValue}
                  errors={errors}
                  placeholder="Sürücü Belgesi Seçin"
                  onValueChanged={(e) => {
                    console.log(e);
                    if (e) {
                      handleLicenseChange(e);
                    }
                  }}
                  required={true}
                  getValues={getValues}
                  errorMessage={"Sürücü Belgesi Boş geçilemez"}
                />
              }
            />

            {licenseIsShow && (
              <CustomDesign
                label={"Sürücü Belgesi Türü"}
                required={true}
                child={
                  <BitonisMultiDropdown
                    items={
                      props.driverLicenceType.driverLicenceType &&
                      props.driverLicenceType.driverLicenceType.map(
                        ({ id, name }) => ({
                          value: id,
                          label: name,
                        })
                      )
                    }
                    register={register}
                    watch={watch}
                    companyClassName={"drivingLicenceTypes"}
                    control={control}
                    setValue={setValue}
                    errors={errors}
                    errorMessage={"Sürücü Belgesi Türü Boş geçilemez"}
                    placeholder="Sürücü Belgesi Türü"
                    onValueChanged={(value) => {
                      console.log(value);
                    }}
                    required={true}
                    getValues={getValues}
                  />
                }
              />
            )}
          </form>
        </div>
      </div>
    </div>
  );
};

function mapState(state) {
  let authentication = state.authentication;
  let yearOfExperience = state.yearOfExperience;
  let positionList = state.positionList;
  let wayOfWorking = state.wayOfWorking;
  let nationalityList = state.nationalityList;
  let ageRangeList = state.ageRangeList;
  let driverLicenceType = state.driverLicenceType;
  // Beyaz Yaka
  let yearOfExperienceWhite = state.yearOfExperienceWhite;
  let positionListWhite = state.positionListWhite;
  let wayOfWorkingWhite = state.wayOfWorkingWhite;
  let nationalityListWhite = state.nationalityListWhite;
  let ageRangeListWhite = state.ageRangeListWhite;
  let driverLicenceTypeWhite = state.driverLicenceTypeWhite;
  let salaryListWhite = state.salaryListWhite;
  let businessTravelListWhite = state.businessTravelListWhite;
  return {
    authentication,
    driverLicenceType,
    yearOfExperience,
    positionList,
    wayOfWorking,
    nationalityList,
    ageRangeList,
    yearOfExperienceWhite,
    positionListWhite,
    wayOfWorkingWhite,
    nationalityListWhite,
    ageRangeListWhite,
    driverLicenceTypeWhite,
    salaryListWhite,
    businessTravelListWhite,
  };
}

export default connect(mapState)(FindWhiteFeatures);
