import React, { useState, useEffect, useMemo, useCallback } from "react";
import "../../../css/bitoniscss/activepacket.css";
import logo from "../../../images/packedNotFound.svg";
import { Modal, Button, Row, Col, Form } from "react-bootstrap";
import api from "../../../utility/Utils";
import { connect, useDispatch } from "react-redux";
import { DropListAction } from "../../../action";
import { useHistory } from "react-router-dom";
import { DashboardServices } from "../../../services";
import useIsMobile from "../../hooks/useIsMobile";
import LoadingDotsAnimation from "../../widgets/LoadingDotsAnimation";

const ActivePackets = (props) => {
  const [currentPacket, setCurrentPacket] = useState(null);
 const isMobile = useIsMobile();

  const [getData, setGetData] = useState(false);
  const [remainderJobCount, setRemainderJobCount] = useState(null);
  const history = useHistory();
  useEffect(() => {
    planList().then(
      (plan) => {
        getCurrentPackets(plan);
      },
      (error) => {
        dispatch(failure(error));
      }
    );
    DashboardServices.getRemainderJobCount().then((remainderResponse) => {
      if (remainderResponse && remainderResponse.succeeded == true) {
        if (
          remainderResponse.data.creditLeft &&
          remainderResponse.data.creditLeft.toString() === "0"
        ) {
          setRemainderJobCount(remainderResponse.data.creditLeft.toString());
        } else if (parseInt(remainderResponse.data.creditLeft.toString()) > 0) {
          setRemainderJobCount(remainderResponse.data.creditLeft.toString());
        }
      }
    });
  }, []);

  function planList() {
    return api
      .get("/payment/getplans") //burda paketler
      .then((response) => {
        return Promise.resolve(response.data);
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  }

  async function getCurrentPackets(plan) {
    api
      .get("payment/getCompanyPermission")
      .then((response) => {
        var data = response.data;
        var planList = [];
        if (plan == null || plan == []) {
        } else {
          planList = plan;
          if (data.creditLeft == 0) {
            setCurrentPacket(null);
            setGetData(Boolean(data));
          } else {
            planList.map((item) => {
              if (item.planId == data.currentPlanId) {
                setCurrentPacket(item);
                setGetData(Boolean(data));
              }
            });
          }
        }
      })
      .catch((err) => {});
  }
  if (getData == false) {
    return   <div >
    <div
      style={{
        display: "flex",
              flexDirection: "row",
              justifyContent: "center",
      }}
    >
      <LoadingDotsAnimation fontSize={40} />
    </div>
  </div>
  } else if (currentPacket == null) {
    return (
      <div>
        <div class="activenotfound" style={{}}>
          <img
            src={logo}
            style={{
              height: "120px",
              textAlign: "center",
            }}
          />
          <p
            style={{
              color: "#001542",
              margin: "10px",
              fontSize: "15px",
            }}
          >
            {" "}
            Satın Aldığınız Bir Paket Bulunamadı
          </p>
          <button
            type="submit"
            className="btn btn-primary btn-block"
            id="buttonpacket"
            onClick={(event) => {
              history.push(`/packets`);
            }}
          >
            <p style={{ marginTop: "10px" }}>Paket Satın Al</p>
          </button>
        </div>
      </div>
    );
  }
  return (
    <div>
      <div className="row center-items ">
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
            }}
          >
            <p
              style={{
                fontSize: isMobile ? "12px" : "16px",
                color: "#001542",
                fontWeight: 400,
              }}
            >
              Kalan Talep Hakkı :
            </p>
            <p
              style={{
                fontSize: isMobile ? "12px" : "16px",
                color: "#001542",
                fontWeight: "bold",
                marginLeft: "5px",
              }}
            >
              {" "}
              {remainderJobCount && remainderJobCount}{" "}
            </p>
          </div>
          <Col md={6} className="tab-equal">
            <div className="cardPackets">
              <div className="freetitle">{`Free`} </div>
              <div className="cardHeader ">
                <h4 className="headerTitle">{currentPacket.planName} </h4>
              </div>
              <div className="cardBody">
                <ul
                  className="list-unstyled mt-0 mb-0"
                  style={{
                    fontSize: "14px",
                    color: "#001542",
                    fontWeight: 400,
                  }}
                >
                  {currentPacket == null ? (
                    <div></div>
                  ) : (
                    currentPacket.properties.map((feature, i) => (
                      <li key={i}>
                        {" "}
                        <i
                          className="fa-solid fa-tag"
                          style={{
                            fontSize: "15px",
                            color: "#FDC303",
                            margin: "8px",
                          }}
                        ></i>{" "}
                        {feature.value}
                      </li>
                    ))
                  )}
                </ul>
              </div>
            </div>
          </Col>
        </div>
      </div>
    </div>
  );
};

function mapState(state) {
  let payment = state.payment;
  let companyList = state.companyListMain;
  let planList = state.planList;
  return {
    payment,
    companyList,
    planList,
  };
}
export default connect(mapState)(ActivePackets);
