import { TaxCountyConstants, TaxOfficesConstants } from '../constants';

const defaultState = { loading: false, error: false, taxCounty: false, success: false };

function setState(changes) {
    return { ...defaultState, ...changes };
}

export function taxCounty(state = defaultState, action) {
    switch (action.type) {
        case TaxCountyConstants.GET_ALL_REQUEST:
            return setState({ loading: true });
        case TaxCountyConstants.GET_ALL_SUCCESS:
            return setState({ taxCounty: action.taxCounty, success: true });
        case TaxCountyConstants.GET_ALL_FAILURE:
            return setState({ error: action.taxCounty });
        default:
            return state;
    }
}

const defaultStateTaxOffice = { loading: false, error: false, taxOffices: false, success: false };

export function taxOffices(state = defaultStateTaxOffice, action) {
    switch (action.type) {
        case TaxOfficesConstants.GET_ALL_REQUEST:
            return setState({ loading: true });
        case TaxOfficesConstants.GET_ALL_SUCCESS:
            return setState({ taxOffices: action.taxOffices, success: true });
        case TaxOfficesConstants.GET_ALL_FAILURE:
            return setState({ error: action.taxOffices });
        default:
            return state;
    }
}