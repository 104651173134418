import React from "react";
import SendVideoConferenceDateButton from "./actionButtons/SendVideoConferenceDateButton";
import VideoCvButton from "./actionButtons/VideoCvButton";
import SendMeetingDateAtWorkButton from "./actionButtons/SendMeetingDateAtWorkButton";
import HireButton from "./actionButtons/HireButton";
import RecruitingButton from "./actionButtons/RecruitingButton";
import ConfirmIncomingMeetingDateButton from "./actionButtons/ConfirmIncomingMeetingDateButton";
import SendNewMeetingDateAtWorkButton from "./actionButtons/SendNewMeetingDateAtWorkButton";
import SendNewVideoConferenceDateButton from "./actionButtons/SendNewVideoConferenceDateButton";
import StartVideoConferencesButton from "./actionButtons/StartVideoConferencesButton";
import ConfirmIncomingMeetingDateButtonNewMeeting from "./actionButtons/ConfirmIncomingMeetingDateButtonNewMeeting";
import ReevaluateCandidateMatching from "./actionButtons/ReevaluateCandidateMatching";
import ConfirmIncomingMeetingDateButtonNewVideoConferencesMeeting from "./actionButtons/ConfirmIncomingMeetingDateButtonNewVideoConferencesMeeting";
import InterviewMeetingDeclinedByEmployer from "./actionButtons/InterviewMeetingDeclinedByEmployer";
import ChangeEmployerVideoConferenceMeetingDay from "./actionButtons/ChangeEmployerVideoConferenceMeetingDay";
import VideoConfDeclinedByEmployer from "./actionButtons/VideoConfDeclinedByEmployer";
import EmployerChangeMatchMeetingday from "./actionButtons/EmployerChangeMatchMeetingday";
import BlockButtons from "../../../MatchShow/Common/BlockButtons/BlockButtons";

const WhiteActions = ({
  cardStyle,
  labelStyle,
  inputStyle,
  matchId,
  status,
  whiteMatchDetail,
  title,
  companyId,
  employerId,
  meetingRoom,
  videoConferansId,
  meetingEmployerId,
  meetingMatchId,
  meetingJobId,
  meetingCandidateId,
  meetingMeetingDateTime,
  meetingCandidateNameSurname,
  meetingPositionName,
}) => {
  let statusBody;
  switch (status) {
    case 0:
      //bu tamamlandı **
      statusBody = (
        <div>
          <SendVideoConferenceDateButton
            matchId={matchId}
            tooltip={"Video Konferans Günü Belirle"}
            title={"Video Konferans Günü Belirle"}
            candidateId={whiteMatchDetail && whiteMatchDetail.candidateId}
          />
          <div
            style={{
              margin: "10px",
            }}
          ></div>
          <VideoCvButton
            matchId={matchId}
            tooltip="Video Cv"
            whiteMatchDetail={whiteMatchDetail}
          />
          <div
            style={{
              margin: "10px",
            }}
          ></div>
          <SendMeetingDateAtWorkButton
            matchId={matchId}
            tooltip={"İş Yerinde Görüşme Günü Belirle"}
          />
          <div
            style={{
              margin: "10px",
            }}
          ></div>
          <RecruitingButton matchId={matchId} tooltip={"İşe Alma"} />
        </div>
      );
      break;
    case 1:
      statusBody = (
        //tamamlandı **
        <div>
          <ConfirmIncomingMeetingDateButtonNewMeeting
            matchId={matchId}
            tooltip={"Gelen Görüşme Gününü Onayla"}
          />
          <div
            style={{
              margin: "10px",
            }}
          ></div>{" "}
          <SendVideoConferenceDateButton
            matchId={matchId}
            tooltip={"Video Konferans Günü Belirle"}
            title={"Video Konferans Günü Belirle"}
            candidateId={whiteMatchDetail && whiteMatchDetail.candidateId}
          />
          <div
            style={{
              margin: "10px",
            }}
          ></div>
          <VideoCvButton
            matchId={matchId}
            tooltip="Video Cv"
            whiteMatchDetail={whiteMatchDetail}
          />
          <div
            style={{
              margin: "10px",
            }}
          ></div>
          <SendMeetingDateAtWorkButton
            matchId={matchId}
            tooltip={"İş Yerinde Görüşme Günü Belirle"}
          />
          <div
            style={{
              margin: "10px",
            }}
          ></div>
          <HireButton matchId={matchId} tooltip="İşe Al" />
          <div
            style={{
              margin: "10px",
            }}
          ></div>
          <RecruitingButton matchId={matchId} tooltip={"İşe Alma"} />
        </div>
      );
      break;
    case 2:
      statusBody = (
        //tamamlandı **
        <div>
          <SendVideoConferenceDateButton
            matchId={matchId}
            tooltip={"Video Konferans Günü Belirle"}
            title={"Video Konferans Günü Belirle"}
            candidateId={whiteMatchDetail && whiteMatchDetail.candidateId}
          />
          <div
            style={{
              margin: "10px",
            }}
          ></div>
          <VideoCvButton
            matchId={matchId}
            tooltip="Video Cv"
            whiteMatchDetail={whiteMatchDetail}
          />
          <div
            style={{
              margin: "10px",
            }}
          ></div>
          <EmployerChangeMatchMeetingday
            matchId={matchId}
            tooltip={"İş Yerinde Yeni Görüşme Günü Belirle"}
          />
          <div
            style={{
              margin: "10px",
            }}
          ></div>
          <RecruitingButton matchId={matchId} tooltip={"İşe Alma"} />
        </div>
      );
      break;
    case 3:
      statusBody = (
        //Video Konferans Günü Belirle
        //tamamlandı **
        <div>
          <SendVideoConferenceDateButton
            matchId={matchId}
            tooltip={"Video Konferans Günü Belirle"}
            title={"Video Konferans Günü Belirle"}
            candidateId={whiteMatchDetail && whiteMatchDetail.candidateId}
          />
          <div
            style={{
              margin: "10px",
            }}
          ></div>
          <VideoCvButton
            matchId={matchId}
            tooltip="Video Cv"
            whiteMatchDetail={whiteMatchDetail}
          />
          <div
            style={{
              margin: "10px",
            }}
          ></div>
          <EmployerChangeMatchMeetingday
            matchId={matchId}
            tooltip={"İş Yerinde Yeni Görüşme Günü Belirle"}
          />
          <div
            style={{
              margin: "10px",
            }}
          ></div>
          <HireButton matchId={matchId} tooltip="İşe Al" />{" "}
          <div
            style={{
              margin: "10px",
            }}
          ></div>
          <RecruitingButton matchId={matchId} tooltip={"İşe Alma"} />
        </div>
      );
      break;
    case 4:
      statusBody = (
        //tamamlandı **
        <div>
          <SendVideoConferenceDateButton
            matchId={matchId}
            tooltip={"Video Konferans Günü Belirle"}
            title={"Video Konferans Günü Belirle"}
            candidateId={whiteMatchDetail && whiteMatchDetail.candidateId}
          />{" "}
          <div
            style={{
              margin: "10px",
            }}
          ></div>{" "}
          <VideoCvButton
            matchId={matchId}
            tooltip="Video Cv"
            whiteMatchDetail={whiteMatchDetail}
          />
          <div
            style={{
              margin: "10px",
            }}
          ></div>{" "}
          <SendMeetingDateAtWorkButton
            matchId={matchId}
            tooltip={"İş Yerinde Görüşme Günü Belirle"}
          />
          <div
            style={{
              margin: "10px",
            }}
          ></div>{" "}
          <RecruitingButton matchId={matchId} tooltip={"İşe Alma"} />
        </div>
      );
      break;
    case 5:
      statusBody = <p></p>;
      break;
    case 7:
      statusBody = (
        //tamamlandı **
        <div>
          <ReevaluateCandidateMatching
            matchId={matchId}
            candidateId={whiteMatchDetail && whiteMatchDetail.candidateId}
            tooltip={"Yeniden Değerlendir"}
          />{" "}
        </div>
      );
      break;
    case 8:
      statusBody = (
        //tamamlandı **
        <div>
          <div
            style={{
              margin: "10px",
            }}
          ></div>
          <SendVideoConferenceDateButton
            matchId={matchId}
            tooltip={"Video Konferans İçin Yeni Gün/Saat Belirle"}
            title={"Video Konferans İçin Yeni Gün/Saat Belirle"}
            candidateId={whiteMatchDetail.candidateId}
          />{" "}
          <div
            style={{
              margin: "10px",
            }}
          ></div>
          <VideoCvButton
            matchId={matchId}
            tooltip="Video Cv"
            whiteMatchDetail={whiteMatchDetail}
          />
          <div
            style={{
              margin: "10px",
            }}
          ></div>{" "}
          <SendMeetingDateAtWorkButton
            matchId={matchId}
            tooltip={"İş Yerinde Görüşme Günü Belirle"}
          />{" "}
          <div
            style={{
              margin: "10px",
            }}
          ></div>{" "}
          <RecruitingButton matchId={matchId} tooltip={"İşe Alma"} />
          <div
            style={{
              margin: "10px",
            }}
          ></div>{" "}
        </div>
      );
      break;
    case 9:
      statusBody = (
        //tamamlandı **
        <div>
          <StartVideoConferencesButton
            matchId={matchId}
            title={title}
            tooltip={"Video Konferans Başlat"}
            candidateName={whiteMatchDetail && whiteMatchDetail.candidateName}
            meetingRoom={meetingRoom}
            videoConferansId={videoConferansId}
            meetingEmployerId={meetingEmployerId}
            meetingMatchId={meetingMatchId}
            meetingJobId={meetingJobId}
            meetingCandidateId={meetingCandidateId}
            meetingMeetingDateTime={meetingMeetingDateTime}
            meetingCandidateNameSurname={meetingCandidateNameSurname}
            meetingPositionName={meetingPositionName}
          />
          <div
            style={{
              margin: "10px",
            }}
          ></div>
          <VideoCvButton
            matchId={matchId}
            tooltip="Video Cv"
            whiteMatchDetail={whiteMatchDetail}
          />{" "}
          <div
            style={{
              margin: "10px",
            }}
          ></div>
          <SendMeetingDateAtWorkButton
            matchId={matchId}
            tooltip={"İş Yerinde Görüşme Günü Belirle"}
          />{" "}
          <div
            style={{
              margin: "10px",
            }}
          ></div>{" "}
          <HireButton matchId={matchId} tooltip="İşe Al" />{" "}
          <div
            style={{
              margin: "10px",
            }}
          ></div>{" "}
          <RecruitingButton matchId={matchId} tooltip={"İşe Alma"} />
        </div>
      );
      break;
    case 10:
      statusBody = (
        //tamamlandı **
        <div>
          <SendVideoConferenceDateButton
            matchId={matchId}
            tooltip={"Video Konferans Günü Belirle"}
            title={"Video Konferans Günü Belirle"}
            candidateId={whiteMatchDetail.candidateId}
          />
          <div
            style={{
              margin: "10px",
            }}
          ></div>{" "}
          <VideoCvButton
            matchId={matchId}
            tooltip="Video Cv"
            whiteMatchDetail={whiteMatchDetail}
          />{" "}
          <div
            style={{
              margin: "10px",
            }}
          ></div>{" "}
          <SendMeetingDateAtWorkButton
            matchId={matchId}
            tooltip={"İş Yerinde Görüşme Günü Belirle"}
          />{" "}
          <div
            style={{
              margin: "10px",
            }}
          ></div>{" "}
          <HireButton matchId={matchId} tooltip="İşe Al" />{" "}
          <div
            style={{
              margin: "10px",
            }}
          ></div>{" "}
          <RecruitingButton matchId={matchId} tooltip={"İşe Alma"} />
        </div>
      );
      break;
    case 12:
      statusBody = (
        //tamamlandı **
        <div>
          <SendVideoConferenceDateButton
            matchId={matchId}
            tooltip={"Video Konferans Günü Belirle"}
            title={"Video Konferans Günü Belirle"}
            candidateId={whiteMatchDetail.candidateId}
          />{" "}
          <div
            style={{
              margin: "10px",
            }}
          ></div>
          <VideoCvButton
            matchId={matchId}
            tooltip="Video Cv"
            whiteMatchDetail={whiteMatchDetail}
          />
          <div
            style={{
              margin: "10px",
            }}
          ></div>{" "}
          <SendMeetingDateAtWorkButton
            matchId={matchId}
            tooltip={"İş Yerinde Görüşme Günü Belirle"}
          />
          <div
            style={{
              margin: "10px",
            }}
          ></div>
          <RecruitingButton matchId={matchId} tooltip={"İşe Alma"} />
        </div>
      );
      break;
    case 14:
      statusBody = (
        //tamamlandı **
        <div>
          <ConfirmIncomingMeetingDateButtonNewMeeting
            matchId={matchId}
            tooltip={"Gelen Görüşme Gününü Onayla"}
          />{" "}
          <div
            style={{
              margin: "10px",
            }}
          ></div>
          <SendVideoConferenceDateButton
            matchId={matchId}
            tooltip={"Video Konferans Günü Belirle"}
            title={"Video Konferans Günü Belirle"}
            candidateId={whiteMatchDetail.candidateId}
          />{" "}
          <div
            style={{
              margin: "10px",
            }}
          ></div>
          <VideoCvButton
            matchId={matchId}
            tooltip="Video Cv"
            whiteMatchDetail={whiteMatchDetail}
          />
          <div
            style={{
              margin: "10px",
            }}
          ></div>
          <EmployerChangeMatchMeetingday
            matchId={matchId}
            tooltip={"İş Yerinde Yeni Görüşme Günü Belirle"}
          />
          <div
            style={{
              margin: "10px",
            }}
          ></div>
          <InterviewMeetingDeclinedByEmployer
            matchId={matchId}
            tooltip={"Bu Görüşmeyi reddet"}
          />
          <div
            style={{
              margin: "10px",
            }}
          ></div>
        </div>
      );
      break;
    case 15:
      statusBody = (
        //tamamlandı **
        <div>
          <ConfirmIncomingMeetingDateButtonNewVideoConferencesMeeting
            matchId={matchId}
            tooltip={"Gelen Görüşme Gününü Onayla"}
          />{" "}
          <div
            style={{
              margin: "10px",
            }}
          ></div>{" "}
          <ChangeEmployerVideoConferenceMeetingDay
            matchId={matchId}
            tooltip={"Video Konferans İçin Yeni Gün/Saat Belirle"}
          />{" "}
          <div
            style={{
              margin: "10px",
            }}
          ></div>{" "}
          <VideoCvButton
            matchId={matchId}
            tooltip="Video Cv"
            whiteMatchDetail={whiteMatchDetail}
          />{" "}
          <div
            style={{
              margin: "10px",
            }}
          ></div>{" "}
          <SendMeetingDateAtWorkButton
            matchId={matchId}
            tooltip={"İş Yerinde Görüşme Günü Belirle"}
          />
          <div
            style={{
              margin: "10px",
            }}
          ></div>{" "}
          <VideoConfDeclinedByEmployer
            matchId={matchId}
            tooltip={"Video konferans için reddet butonu"}
          />
        </div>
      );
      break;
    case 16:
      statusBody = (
        //tamamlandı **
        <div>
          <StartVideoConferencesButton
            matchId={matchId}
            title={title}
            tooltip={"Video Konferans Başlat"}
            candidateName={whiteMatchDetail && whiteMatchDetail.candidateName}
            meetingRoom={meetingRoom}
            videoConferansId={videoConferansId}
            meetingEmployerId={meetingEmployerId}
            meetingMatchId={meetingMatchId}
            meetingJobId={meetingJobId}
            meetingCandidateId={meetingCandidateId}
            meetingMeetingDateTime={meetingMeetingDateTime}
            meetingCandidateNameSurname={meetingCandidateNameSurname}
            meetingPositionName={meetingPositionName}
          />
          <div
            style={{
              margin: "10px",
            }}
          ></div>
          <VideoCvButton
            matchId={matchId}
            tooltip="Video Cv"
            whiteMatchDetail={whiteMatchDetail}
          />{" "}
          <div
            style={{
              margin: "10px",
            }}
          ></div>
          <SendMeetingDateAtWorkButton
            matchId={matchId}
            tooltip={"İş Yerinde Görüşme Günü Belirle"}
          />
          <div
            style={{
              margin: "10px",
            }}
          ></div>
          <HireButton matchId={matchId} tooltip="İşe Al" />{" "}
          <div
            style={{
              margin: "10px",
            }}
          ></div>
          <RecruitingButton matchId={matchId} tooltip={"İşe Alma"} />
        </div>
      );
      break;
    case 18:
      statusBody = (
        //tamamlandı **
        <div>
          <SendVideoConferenceDateButton
            matchId={matchId}
            tooltip={"Video Konferans Günü Belirle"}
            title={"Video Konferans Günü Belirle"}
            candidateId={whiteMatchDetail.candidateId}
          />
          <div
            style={{
              margin: "10px",
            }}
          ></div>{" "}
          <VideoCvButton
            matchId={matchId}
            tooltip="Video Cv"
            whiteMatchDetail={whiteMatchDetail}
          />{" "}
          <div
            style={{
              margin: "10px",
            }}
          ></div>{" "}
          <SendMeetingDateAtWorkButton
            matchId={matchId}
            tooltip={"İş Yerinde Görüşme Günü Belirle"}
          />{" "}
          <div
            style={{
              margin: "10px",
            }}
          ></div>{" "}
          <RecruitingButton matchId={matchId} tooltip={"İşe Alma"} />
        </div>
      );
      break;
    case 19:
      statusBody = (
        //tamamlandı **
        <div>
          <ChangeEmployerVideoConferenceMeetingDay
            matchId={matchId}
            tooltip={"Video Konferans Günü Belirle"}
          />{" "}
          <div
            style={{
              margin: "10px",
            }}
          ></div>
          <VideoCvButton
            matchId={matchId}
            tooltip="Video Cv"
            whiteMatchDetail={whiteMatchDetail}
          />
          <div
            style={{
              margin: "10px",
            }}
          ></div>
          <SendMeetingDateAtWorkButton
            matchId={matchId}
            tooltip={"İş Yerinde Görüşme Günü Belirle"}
          />
          <div
            style={{
              margin: "10px",
            }}
          ></div>
          <RecruitingButton matchId={matchId} tooltip={"İşe Alma"} />
        </div>
      );
      break;
    case 20:
      statusBody = (
        //tamamlandı **
        <div>
          <SendVideoConferenceDateButton
            matchId={matchId}
            tooltip={"Video Konferans Günü Belirle"}
            title={"Video Konferans Günü Belirle"}
            candidateId={whiteMatchDetail.candidateId}
          />
          <div
            style={{
              margin: "10px",
            }}
          ></div>{" "}
          <VideoCvButton
            matchId={matchId}
            tooltip="Video Cv"
            whiteMatchDetail={whiteMatchDetail}
          />{" "}
          <div
            style={{
              margin: "10px",
            }}
          ></div>{" "}
          <EmployerChangeMatchMeetingday
            matchId={matchId}
            tooltip={"İş Yerinde Yeni Görüşme Günü Belirle"}
          />
          <div
            style={{
              margin: "10px",
            }}
          ></div>{" "}
          <RecruitingButton matchId={matchId} tooltip={"İşe Alma"} />
        </div>
      );
      break;
    case 21:
      statusBody = (
        //tamamlandı **
        <div>
          <SendVideoConferenceDateButton
            matchId={matchId}
            tooltip={"Video Konferans Günü Belirle"}
            title={"Video Konferans Günü Belirle"}
            candidateId={whiteMatchDetail.candidateId}
          />{" "}
          <div
            style={{
              margin: "10px",
            }}
          ></div>{" "}
          <VideoCvButton
            matchId={matchId}
            tooltip="Video Cv"
            whiteMatchDetail={whiteMatchDetail}
          />{" "}
          <div
            style={{
              margin: "10px",
            }}
          ></div>{" "}
          <SendMeetingDateAtWorkButton
            matchId={matchId}
            tooltip={"İş Yerinde Görüşme Günü Belirle"}
          />{" "}
          <div
            style={{
              margin: "10px",
            }}
          ></div>{" "}
          <RecruitingButton matchId={matchId} tooltip={"İşe Alma"} />
        </div>
      );
      break;
    default:
      statusBody = <h1></h1>;
  }

  return (
    <div
      class=""
      style={{
        padding: "0px",
        margin: "0px",
        paddingBottom: "20px",
      }}
    >
      {statusBody}
      {employerId && (
        <div>
          <div
            style={{
              height: "10px",
              display: "flex",
              justifyContent: "center",
              alignContent: "center",
              alignItems: "center",
            }}
          ></div>
          <BlockButtons
            Detail={whiteMatchDetail}
            companyId={companyId}
            employerId={employerId}
            positionType={2}
            status={status}
          />
        </div>
      )}
      <div
        style={{
          height: "40px",
        }}
      ></div>
    </div>
  );
};

export default WhiteActions;
