import React from "react";

const NotesCv = () => {
  return (
    <div
      style={{
        flex: "6",
        marginTop: "7px",
        display: "flex",
        flexDirection: "column",
        padding: "10px",
        backgroundColor: "#f7f7f7",
        borderRadius: "8px",
        marginTop: "10px",
        paddingBottom: "5px",
      }}
    >
      <p
        style={{
          fontWeight: "500",
          color: "orange",
          marginBottom: "0px",
          paddingBottom: "0px",
        }}
      >
        Notlar
      </p>
      <div>
        <hr
          style={{
            marginTop: "0px",
            marginBottom: "0px",
            padding: "0px",
            paddingTop: "5px",
            border: "none",
            borderTop: "2px solid orange",
            color: " #fff",
            flex: "6",
          }}
        ></hr>
        <ItemCv title={"Çocuk Sayısı"} />{" "}
        <div
          style={{
            paddingBottom: "20px",
          }}
        ></div>
        <DividerPdf />
        <ItemCv title={"Herhangi bir sağlık probleminiz var mı ?"} />{" "}
        <div
          style={{
            paddingBottom: "20px",
          }}
        ></div>
        <DividerPdf />
        <ItemCv title={"Mahkumiyet durumunuz var mı ?"} />{" "}
        <div
          style={{
            paddingBottom: "20px",
          }}
        ></div>
        <DividerPdf />
        <ItemCv
          title={
            "Size ulaşamadığımızda haber verilecek kişinin iletişim bilgileri : "
          }
        />{" "}
        <div
          style={{
            paddingBottom: "20px",
          }}
        ></div>
        <DividerPdf />
        <ItemCv title={"Bu İşyerinde tanıdığınız var mı ?"} />{" "}
        <div
          style={{
            paddingBottom: "20px",
          }}
        ></div>
      
      </div>
    </div>
  );
};
const DividerPdf = () => {
  return (
    <div>
      <div>
        <hr
          style={{
            marginTop: "0px",
            marginBottom: "0px",
            padding: "0px",
            paddingTop: "5px",
            border: "none",
            borderTop: "2px solid rgb(236, 227, 188)",
            color: " #fff",
            flex: "6",
          }}
        ></hr>
      </div>
    </div>
  );
};

const ItemCv = ({ title }) => {
  return (
    <div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
        }}
      >
        <div
          style={{
            width: "10px",
            height: "10px",
            borderRadius: "50%",
            backgroundColor: "orange",
            marginRight: "5px",
          }}
        ></div>
        <p
          style={{
            margin: "0px",
            padding: "0px",
            color: "#001542",
            fontWeight: "500",
            fontSize: "11px",
          }}
        >
          {title}
        </p>
      </div>
    </div>
  );
};

export default NotesCv;
