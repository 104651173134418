import React, { useState } from "react";
import "../../../../../../../css/bitoniscss/demand/tabItem.css";
import { Box, Typography } from "@mui/material";
import { Row } from "react-bootstrap";
function HireItem({ candidateNameSurname, positionName, status, candidateId }) {
  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  const getBackgroundColor = () => {
    if (isHovered) {
      return "white";
    }

    // Diğer durumlar için renkleri döndürün
    return "white";
  };

  const getBoxShadow = () => {
    return isHovered
      ? "0px 7px 8px #dfdfdf;" // Hovered state shadow (elevation effect)
      : "0px"; // Default state shadow
  };



  return (
    <Box
      className="card-body"
      key={candidateId}
      sx={{
        display: "flex",
        flexDirection: "row",
        alignContent: "space-between",
        border: isHovered == true ? "1px solid #D6E5FA" : "1px solid #F7F7F7",
        boxShadow: getBoxShadow(), 
        transition: "box-shadow 0.3s ease-in-out",
        height: "60px",
        position: "relative",
        borderRadius: "10px",
        alignItems: "center",
        width: "100%",
        margin: "8px",
        justifyContent: "space-between",
        cursor: "pointer",
        backgroundColor: getBackgroundColor(), // Dinamik arka plan rengi
      }}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <Row
        sx={{
          display: "flex",
          flexDirection: "row",
        }}
      >
        <Box
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-start",
            alignContent: "center",
            alignItems: "center",
          }}
        >
          <Box
            className="fa-stack fa-lg"
            style={{
              marginLeft: {
                xs: "5px",
                sm: "0px",
                md: "0px",
                lg: "0px",
                xl: "0px",
              },
              color: "#001542"
            }}
          >
            <i class="fa-solid fa-user" style={{
              marginLeft: "10px"
            }}></i>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Typography
              sx={{
                fontSize: {
                  xs: "10px",
                  sm: "12px",
                  md: "12px",
                  lg: "14px",
                  xl: "14px",
                },
                fontWeight: 600,
                color: {
                  xs: "#001542", // 0px ve üzeri ekran genişlikleri için
                  sm: "#001542", // 600px ve üzeri ekran genişlikleri için
                  md: "#001542", // 900px ve üzeri ekran genişlikleri için
                  lg: "#001542", // 1200px ve üzeri ekran genişlikleri için
                  xl: "#001542", // 1536px ve üzeri ekran genişlikleri için
                },
              }}
            >
              {candidateNameSurname}
            </Typography>

          </Box>
        </Box>
      </Row>
      <Box>
        <Typography
          sx={{
            fontSize: {
              xs: "9px", // 0px ve üzeri ekran genişlikleri için
              sm: "11px", // 600px ve üzeri ekran genişlikleri için
              md: "11px", // 900px ve üzeri ekran genişlikleri için
              lg: "13px", // 1200px ve üzeri ekran genişlikleri için
              xl: "12px", // 1536px ve üzeri ekran genişlikleri için,
            },
            textAlign: "center",
            fontWeight: 600,
            color: {
              xs: "#001542", // 0px ve üzeri ekran genişlikleri için
              sm: "#001542", // 600px ve üzeri ekran genişlikleri için
              md: "#001542", // 900px ve üzeri ekran genişlikleri için
              lg: "#001542", // 1200px ve üzeri ekran genişlikleri için
              xl: "#001542", // 1536px ve üzeri ekran genişlikleri için
            },
          }}
        >
          {positionName}
        </Typography>
      </Box>
      <Box>
        <Typography
          sx={{
            fontSize: {
              xs: "9px", // 0px ve üzeri ekran genişlikleri için
              sm: "12px", // 600px ve üzeri ekran genişlikleri için
              md: "12px", // 900px ve üzeri ekran genişlikleri için
              lg: "13px", // 1200px ve üzeri ekran genişlikleri için
              xl: "12px", // 1536px ve üzeri ekran genişlikleri için,
            },
            fontWeight: 600,
            color: {
              xs:
                status === 2 || status == 7 || status == 6
                  ? "teal"
                  : "",
              sm:
                status === 2 || status == 7 || status == 6
                  ? "teal"
                  : "",
              md: status === 2 || status == 7 || status == 6
                ? "teal"
                : "",
              lg: status === 2 || status == 7 || status == 6
                ? "teal"
                : "",
              xl: status === 2 || status == 7 || status == 6
                ? "teal"
                : "",
            },
          }}
        >
          {status === 7 ? (
            <div>  Farklı Pozisyonda İşe Alındı</div>
          ) : status === 2 || status === 6 ? (
            <div>İşe Alındı</div>
          ) : (
            <div></div>
          )}
        </Typography>
      </Box>
      <div
        style={
          status === 2 || status == 7 || status == 6
            ? {
              position: "absolute",
              bottom: 0,
              right: 0,
              width: 0,
              height: 0,
              borderLeft: "30px solid transparent",
              borderTop: "30px solid transparent",
              borderRight: "30px solid teal",
              borderRadius: "0px 0px 8px",
            }
            : {

            }
        }
      ></div>
    </Box>
  );
}

export default HireItem;


//status 7 mavi yakada oluyor


/*


  const [isHovered, setIsHovered] = useState(false);
  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  const getBackgroundColor = () => {
    if (isHovered) {
      return "#F5F5F5";
    }

    // Diğer durumlar için renkleri döndürün
    return "white";
  };
  return (
    <div
      className="card-body"
      key={candidateId}
      style={{
        display: "flex",
        flexDirection: "row",
        alignContent: "space-between",
        border: isHovered == true ? "1px solid #D6E5FA" : "1px solid #F7F7F7",

        height: "60px",
        position: "relative",
        borderRadius: "10px",
        alignItems: "center",
        width: "100%",
        margin: "8px",
        justifyContent: "space-between",
        cursor: "pointer",
        backgroundColor: getBackgroundColor(), // Dinamik arka plan rengi
      }}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignContent: "space-between",
          alignItems: "center",
          marginLeft: "0px",
        }}
      >
        <span className="fa-stack fa-lg">
          <i class="fa-solid fa-user"></i>
        </span>
        <div>
          <div className="item-title">{candidateNameSurname}</div>
        </div>
      </div>
      <div className="position-title">{positionName}</div>

      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "flex-end",
          alignItems: "flex-end",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-end",
            alignContent: "flex-end",
            alignItems: "flex-end",
          }}
        >
      
          {status === 7 ? (
            <div className="hire-status-title">
              Farklı Pozisyonda<br></br>İşe Alındı
              
            </div>
          ) : status === 2 ||status === 6? (
            <div className="hire-status-title">İşe Alındı</div>
          ) : (
            <div></div>
          )}
        </div>
      </div>

      <div
        style={
          status === 2 || status == 7||status==6
            ? {
                position: "absolute",
                bottom: 0,
                right: 0,
                width: 0,
                height: 0,
                borderLeft: "30px solid transparent",
                borderTop: "30px solid transparent",
                borderRight: "30px solid teal",
                borderRadius: "0px 0px 8px",
              }
            : {}
        }
      ></div>
    </div>
  );

  */