import { ProfileAddedRoutesMainConstans, ProfileDetailConstans,ProfileDetailMainConstans, SearchCompanyListConstants } from "../constants";

const defaultProfileState = { loading: false, companyDetail: false, error: false, success: false };

function setState(changes) {
    return { ...defaultProfileState, ...changes };
}
export function companyDetail(state = defaultProfileState, action) { //Şirket Bilgileri
    switch (action.type) {
        case ProfileDetailConstans.GET_ALL_REQUEST:
            return setState({ loading: true });
        case ProfileDetailConstans.GET_ALL_SUCCESS:
            return setState({ companyDetail: action.companyDetail, success: true });
        case ProfileDetailConstans.GET_ALL_FAILURE:
            return setState({ error: action.companyDetail });
        default:
            return state
    }
}
const defaultProfileMainState = { loading: false, companyMainDetail: false, error: false, success: false };


export function companyMainDetail(state = defaultProfileMainState, action) { //Firmanın Ana Şirket Bilgileri
    switch (action.type) {
        case ProfileDetailMainConstans.GET_ALL_REQUEST:
            return setState({ loading: true });
        case ProfileDetailMainConstans.GET_ALL_SUCCESS:
            return setState({ companyMainDetail: action.companyMainDetail, success: true });
        case ProfileDetailMainConstans.GET_ALL_FAILURE:
            return setState({ error: action.companyMainDetail });
        default:
            return state
    }
}
const searchCompanyListState = {
  loading: false,
  searchCompanyList: false,
  error: false,
  success: false,
};

export function searchCompanyList(state = searchCompanyListState, action) {
  //talep yayınlamada eleman aradığın firma kısmında kullanılacak
  switch (action.type) {
    case SearchCompanyListConstants.GET_ALL_REQUEST:
      return setState({ loading: true });
    case SearchCompanyListConstants.GET_ALL_SUCCESS:
      return setState({
        searchCompanyList: action.searchCompanyList,
        success: true,
      });
    case SearchCompanyListConstants.GET_ALL_FAILURE:
      return setState({ error: action.searchCompanyList });
    default:
      return state;
  }
}
const defaultProfileGetRoutesMainState = {
  loading: false,
  companyRoutes: false,
  error: false,
  success: false,
};

export function companyAddedGetRoutes(
  state = defaultProfileGetRoutesMainState,
  action
) {
  //Firmaya eklenmiş güzergahları getirir
  switch (action.type) {
    case ProfileAddedRoutesMainConstans.GET_ALL_REQUEST:
      return setState({ loading: true });
    case ProfileAddedRoutesMainConstans.GET_ALL_SUCCESS:
      return setState({
        companyRoutes: action.companyRoutes,
        success: true,
      });
    case ProfileAddedRoutesMainConstans.GET_ALL_FAILURE:
      return setState({ error: action.companyRoutes });
    default:
      return state;
  }
};