import { DemandsServices } from "../../../../../../../services";
import moment from "moment";
import { exportExcel } from "./exportExcel";
import { ToastContainer, toast } from "react-toastify";
import { Alert } from "react-bootstrap";

function calculateAge(jobSeekerDate) {
  var currentDate = new Date();
  var birthDate = new Date(jobSeekerDate);

  var age = currentDate.getFullYear() - birthDate.getFullYear();

  var currentMonth = currentDate.getMonth() + 1;
  var birthMonth = birthDate.getMonth() + 1;

  if (
    currentMonth < birthMonth ||
    (currentMonth === birthMonth && currentDate.getDate() < birthDate.getDate())
  ) {
    age--;
  }

  return age;
}

var genderList = [
  {
    id: 0,
    name: "Farketmez",
  },
  {
    id: 1,
    name: "Kadın",
  },
  {
    id: 2,
    name: "Erkek",
  },
];

const driverLicense = [
  {
    id: 1,
    name: "Var",
  },
  {
    id: 2,
    name: "Yok",
  },
];

var eductaionList = [
  {
    id: 0,
    name: "Farketmez",
  },
  {
    id: 1,
    name: "İlkokul",
  },
  {
    id: 2,
    name: "Ortaokul",
  },
  {
    id: 3,
    name: "Lise",
  },
  {
    id: 4,
    name: "Ön Lisans",
  },
  {
    id: 5,
    name: "Lisans",
  },
  {
    id: 6,
    name: "Yüksek Lisans",
  },
  {
    id: 7,
    name: "Doktora",
  },
];

function extractHomeAddresses(preferredLocations) {
  var locList = [];

  preferredLocations.forEach((loc) => {
    if (loc.isHomeAddress === true) {
      var locationAdres =
        loc.countryName +
        "/" +
        loc.cityName +
        "/" +
        loc.counties[0].countyName +
        "/" +
        loc.districts[0].districtName;

      locList.push({
        adress: locationAdres,
      });
    }
  });

  return locList;
}

export function getMatchAllBlueCreateExcelData(selectedList) {
  DemandsServices.demandMGetMatchBlueAll(selectedList).then(
    (demandMatchResponse) => {
      if (demandMatchResponse && demandMatchResponse.succeeded === true) {
        if (demandMatchResponse.data && demandMatchResponse.data.length > 0) {
          var senData = [];
          console.log(
            " demandMatchResponse.data* * " +
              JSON.stringify(demandMatchResponse.data)
          );
          demandMatchResponse.data.forEach((blueData) => {
      
            var gender = genderList.find(
              (val) =>
                val.id === blueData.candidateLabourerProfileResponseWeb.gender
            );
            var age = calculateAge(
              blueData.candidateLabourerProfileResponseWeb.birthdate.toString()
            );
            var education = eductaionList.find(
              (val) =>
                val.id ===
                blueData.candidateLabourerProfileResponseWeb
                  .labourerEducationTypeId
            );
            var driving = driverLicense.find(
              (val) =>
                val.id ===
                blueData.candidateLabourerProfileResponseWeb.drivingLicence
            );

            var locList = extractHomeAddresses(
              blueData.candidateLabourerProfileResponseWeb
                .candidatePreferredLocations
            );

            var createData = {
              "Adı Soyadı":
                blueData.candidateLabourerProfileResponseWeb.name +
                " " +
                blueData.candidateLabourerProfileResponseWeb.surname,
              Yaş: age,
              Telefon: blueData.candidateLabourerProfileResponseWeb.mobilephone,
              Cinsiyet: gender.name.toString(),
              Eğitim: education.name,
              "Sürücü Belgesi": driving.name,
              "Sürücü Belgesi Türü":
                blueData.candidateLabourerProfileResponseWeb.candidateDrivingLicenceTypes
                  .map((val) => val.drivingLicenceTypeName)
                  .join(","),
              "Eşleşme Tarihi": moment(blueData.matchCreateDate).format(
                "DD/MM/YYYY"
              ),
              Adres: locList.map((val) => val.adress).join(","),
              Durum: blueData.matchStatusDescription,
            };
        
            senData.push(createData);
          });

          exportExcel(senData);
        } else {
          toast.success("Excel Oluşturulacak bir veri bulunamadı", {
            autoClose: 2000,
            onClose: () => {
              setTimeout(() => {}, 100);
            },
          });
        }
      }
    },
    (error) => {}
  );
}
