import React, { useState } from "react";
import moment from "moment";
import { Dropdown } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css"; // Bootstrap stillerini ekleyin
import BlueDemandCopy from "../Common/DemandCopy";
import { Button, Menu, MenuItem } from "@mui/material";
import PopupLongMenu from "../../../../../../../widgets/PopupLongMenu";
import { DemandsServices } from "../../../../../../../../services";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

function InternDemandItem({ demand, onTap }) {
  const history = useHistory();
  return (
    <div
      className="card"
      style={{
        margin: "8px",
        padding: "0px",
        position: "relative",
        display: "flex",
        flexDirection: "row",
      }}
    >
      <div
        onClick={onTap}
        className="item-container-card-b"
        style={{
          position: "relative",
          height: "65px",
        }}
      >
        <div className="position-and-icon-b">
          <span
            className="fa-stack fa-lg"
            style={{
              marginRight: "5px",
              marginLeft: "10px",
            }}
          >
            <i class="fa-solid fa-user"></i>
          </span>
          <p className="item-position-title-b">{demand.departmentName}</p>
        </div>
        <div style={{}}>
          <div
            style={{
              marginTop: "15px",
            }}
          >
            <div className="item-container-date-b">
              Talep Tarihi: {moment(demand.demandDate).format("DD/MM/YYYY")}
            </div>{" "}
            <div className="itemStatusBlue">
              {demand.isActive === true ? (
                <h6
                  className="item-container-date-b"
                  style={{
                    color: "green",
                    marginTop: "5px",
                  }}
                >
                  Talep Durumu:Aktif
                </h6>
              ) : (
                <h6
                  className="item-container-date-b"
                  style={{
                    color: "red",
                    marginTop: "5px",
                  }}
                >
                  Talep Durumu : Pasif
                </h6>
              )}
            </div>
          </div>
        </div>
        <div className="sizedBox"></div>
      </div>

      <div
        onClick={() => {
          if (demand.didDemandExpired === false) {
            const dataMap = new Map();
            dataMap.set("jobId", demand.jobId);
            dataMap.set("positionName", demand.departmentName);

            history.push({
              pathname: "/demands/internCurrentDemandMatchShow",
              state: { dataMap },
            });
          } else {
            toast.error(
              "Talebinizle eşleşen adayları görme süreniz doldu. Yeni bir talep oluşturarak eşleşme sağlayabilirsiniz.",
              {
                autoClose: 5000,
                onClose: () => {
                  setTimeout(() => {}, 1000);
                },
              }
            );
          }
        }}
        style={{
          height: "65px",
          width: "60px",
          padding: "5px",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          justifyItems: "center",
          alignContent: "center",
          alignItems: "center",
          border: "1px solid grey",
          borderRadius: "0px 8px 8px 0px",
          margin: "0px",
          border: "1px solid #f5f5f5",
          backgroundColor:
            demand && demand.didDemandExpired === true ? "grey" : "#FFB40D",
          color: "white",
          cursor: "pointer",
        }}
      >
        <i
          class="fa-solid fa-users"
          style={{
            marginBottom: "2px",
          }}
        ></i>

        <h6 style={{ fontSize: "9px", margin: 0, padding: 0, color: "white" }}>
          Talebimle Eşleşenler
        </h6>
      </div>
    </div>
  );
}

export default InternDemandItem;
