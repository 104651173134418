import React, { useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import Select from "react-select";
import { useForm, Controller } from "react-hook-form";
import {
  DropListAction,
  DropListWhiteAction,
} from "../../../../../../../action";
import ProgramsCertificatesCompetences from "../../../ProgramsCertificatesCompetences/ProgramsCertificatesCompetences";
import AbilitiesRequiredWidget from "../../Common/AbilitiesRequiredWidget";
const FindWhiteAbilities = (props) => {
  const {
    register,
    handleSubmit,
    control,
    watch,
    reset,
    setValue,
    errors,
    submitNewVersion,
    companyName,
    companyLocationName,
    customStyles,
    customStylesMulti,
    labelStyle,
    getValues 
  } = props;

  return (
    <div>
      <div>
        <div
          className="card"
          style={{
            boxShadow: "none",
          }}
        >
          <div className="basic-form">
            <form onSubmit={handleSubmit(submitNewVersion)}>
              <ProgramsCertificatesCompetences
                programList={props.programList.programList}
                certificateList={props.certificatesList.certificatesList}
                CompetenceList={props.competencesList.competencesList}
                control={control}
                register={register}
                watch={watch}
                setValue={setValue}
                errors={errors}
                getValues={getValues}
                checkCertificate={props.checkCertificate}
                checkCompetence={props.checkCompetence}
                checkProgram={props.checkProgram}
                setCheckCertificate={props.setCheckCertificate}
                setCheckCompetence={props.setCheckCompetence}
                setCheckProgram={props.setCheckProgram}
                preview={false}
              />
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

function mapState(state) {
  let authentication = state.authentication;
  let programList = state.programList;
  let certificatesList = state.certificatesList;
  let competencesList = state.competencesList;
  let programListWhite = state.programListWhite;
  let certificatesListWhite = state.certificatesListWhite;
  let competencesListWhite = state.competencesListWhite;

  return {
    authentication,
    programList,
    certificatesList,
    competencesList,
    programListWhite,
    certificatesListWhite,
    competencesListWhite,
  };
}

export default connect(mapState)(FindWhiteAbilities);
