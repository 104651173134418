import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  notificationCount:0
};

const notificationRedux = createSlice({
  name: "notification",
  initialState,
  reducers: {
    setNotificationCount: (state, action) => {
      state.notificationCount = action.payload;
    },
  
  },
});

export const { setNotificationCount } = notificationRedux.actions;
export default notificationRedux.reducer;
