import React from "react";
import Select from "react-select";
import { useForm, Controller } from "react-hook-form";
import { customStylesMulti } from "../../../../../customStyleInline";
import BitonisMultiDropdown from "../../../../../widgets/BitonisMultiDropdown";
import CustomDesign from "../../../../../widgets/CustomDesign";

const LicenseTypeRequired = ({
  licenseIsShow,
  props,
  control,
  register,
  errors,
  checkCLicenseType,
  setCheckLicenseType,
  setValue,
  watch,
  getValues,

}) => {
  return (
    <div>
      {licenseIsShow && (
        <CustomDesign
          label={" Sürücü Belgesi Türü"}
          required={true}
          child={
            <BitonisMultiDropdown
              items={
                props.driverLicenceType.driverLicenceType &&
                props.driverLicenceType.driverLicenceType.map(
                  ({ id, name }) => ({
                    value: id,
                    label: name,
                  })
                )
              }
              register={register}
              watch={watch}
              companyClassName={"drivingLicenceTypes"}
              control={control}
              setValue={setValue}
              errors={errors}
              errorMessage={"Sürücü Belgesi Türü Boş geçilemez"}
              placeholder="Sürücü Belgesi Türünü Seçin"
              onValueChanged={(value) => {
                console.log(value);
              }}
              required={true}
              getValues={getValues}
              requiredSelectedItemsBoolean={true}
              requiredSelectedItemsValues={checkCLicenseType}
              setRequiredSelectedItemsValues={setCheckLicenseType}
              requiredSelectedItemsText={
                <div>
                  Adayın eşleşmesi için gereken şart:
                  <br /> Aşağıda seçtiğiniz tüm sürücü belgelerine sahip
                  olmasını istiyorsanız,lütfen bu seçeneği işaretleyin.
                </div>
              }
            />
          }
        />
      )}
    </div>
  );
  
  
};

export default LicenseTypeRequired;
