import api from "../utility/Utils";

export const RecruitedService = {
    recruitedList,
    recruitedListWhite,
    unrecruitedList,
    unrecruitedListWhite,
    recruitedDetail,
    recruitedDetailWhite,
};



function recruitedList(arg) { // İşe Alınanlar Mavi yaka
    return api.post("employer/labourer/match", arg)
        .then((response) => {
            return Promise.resolve(response.data);
        })
        .catch((err) => {
            return Promise.reject(err);
        });
}
function recruitedListWhite(arg) { // İşe Alınanlar Beyaz yaka
    return api.post("/employer/match", arg)
        .then((response) => {
            return Promise.resolve(response.data);
        })
        .catch((err) => {
            return Promise.reject(err);
        });
}
function recruitedDetail(arg) { // İşe Alınanlar Mavi Detay
    const data = {
        "isActive": true,
        "candidateId": arg
    }
    return api.post("/employer/profileEmployerCandidateLabourer", data)
        .then((response) => {
            return Promise.resolve(response.data);
        })
        .catch((err) => {
            return Promise.reject(err);
        });
}

function recruitedDetailWhite(arg) { // İşe Alınanlar Beyaz Detay
    const data = {
        "isActive": true,
        "candidateId": arg
    }
    return api.post("/employer/profileEmployerCandidateEmployee", data)
        .then((response) => {
            return Promise.resolve(response.data);
        })
        .catch((err) => {
            return Promise.reject(err);
        });
}

function unrecruitedList(arg) { //İşe alınmayan listesi Mavi Yaka
    return api.post("/employer/labourer/match", arg)
        .then((response) => {
            return Promise.resolve(response.data);
        })
        .catch((err) => {
            return Promise.reject(err);
        });
}
function unrecruitedListWhite(arg) {  //İşe alınmayan listesi Beyaz Yaka
    return api.post("/employer/match", arg)
        .then((response) => {
            return Promise.resolve(response.data);
        })
        .catch((err) => {
            return Promise.reject(err);
        });
}
