import { DropListAction } from "../../../../../../action";
import { DemandsServices } from "../../../../../../services";

export default function sendDemandBlue(
  form,
  props,
  selectedRouteList,
  history,
  checkProgram,
  checkCertificate,
  checkCompetence,
  checkCLicenseType,
  interviewContactValue,
  secondsRef
) {
  const companyId = form.companyId.value;
  const companyLocationId = form.companyLocationId.value;
  const positionId = form.positionId.value;
  const jobExperienceRanges = form.jobExperienceRanges.map(
    (item) => item.value
  ); //tecrübe yılı

  const workTypes = form.workTypes.map((item) => item.value); //çalışma şekli
  var jobInterviewDays = [];
  if (form.jobInterviewDays.length > 0) {
    jobInterviewDays = form.jobInterviewDays.map((item) => item.value);
  } else {
    jobInterviewDays = [];
  }

  const educationTypes = [form.educationTypes.value];
  const gender = form.gender.value;
  var militaryStatus;
  if (form.militaryStatus === undefined) {
    militaryStatus = "";
  } else {
    militaryStatus =
      form.militaryStatus === "" ? "" : form.militaryStatus.value;
  }
  const maritalStatus = form.maritalStatus.value;
  const ageRanges = form.ageRanges.map((item) => item.value);
  const nationalityId = form.nationalityId.value;
  const smokerApply = form.smokerApply.value;
  const drivingLicence = form.drivingLicence.value;
  var drivingLicenceTypes = [];
  if (form.drivingLicenceTypes === undefined) {
    drivingLicenceTypes = [];
  } else {
    drivingLicenceTypes =
      form.drivingLicenceTypes === "" ||
      drivingLicenceTypes === undefined ||
      drivingLicenceTypes === null
        ? []
        : form.drivingLicenceTypes.map((item) => item.value);
  }
  var jobPrograms = [];
  var jobCertificates = [];
  var jobCompetences = [];
  if (
    form.jobPrograms === undefined ||
    form.jobPrograms === null ||
    form.jobPrograms == ""
  ) {
    jobPrograms = [];
  } else {
    jobPrograms =
      form.jobPrograms === ""
        ? []
        : form["jobPrograms"].map((item) => item.value);
  }
  if (
    form.jobCertificates === undefined ||
    form.jobCertificates == null ||
    form.jobCertificates == ""
  ) {
    jobCertificates = [];
  } else {
    jobCertificates =
      form.jobCertificates === ""
        ? []
        : form["jobCertificates"].map((item) => item.value);
  }
  if (
    form.jobCompetences === undefined ||
    form.jobCompetences === null ||
    form.jobCompetences == ""
  ) {
    jobCompetences = [];
  } else {
    jobCompetences =
      form.jobCompetences === ""
        ? []
        : form["jobCompetences"].map((item) => item.value);
  }

  var companyPreferredLocationId = selectedRouteList.map(
    (item) => item.companyPreferredLocationId
  );

  var disabilityId = parseInt(form.disabilityStatus.value.toString());
  var disabilityDegreeListIds = [];
  form.degreeOfDisability == null ||
  form.degreeOfDisability == [] ||
  form.degreeOfDisability === "" ||
  form.degreeOfDisability == undefined ||
  form.degreeOfDisability.toString() == "[]" ||
  form.degreeOfDisability.toString() == "null"
    ? null
    : form.degreeOfDisability.map((val) => {
        disabilityDegreeListIds.push(parseInt(val.value.toString()));
      });

  const sendData = {
    companyId: parseInt(companyId.toString()),
    companyLocationId: parseInt(companyLocationId.toString()),
    positionId: parseInt(positionId.toString()),
    jobExperienceRanges: jobExperienceRanges,
    workTypes: workTypes,
    jobInterviewDays:
      jobInterviewDays == [] ||
      jobInterviewDays.toString() === "[]" ||
      jobInterviewDays.length <= 0
        ? null
        : jobInterviewDays,
    educationTypes: educationTypes,
    gender: gender,
    militaryStatus: gender === 1 || gender === 0 ? null : militaryStatus,
    maritalStatus: maritalStatus,
    ageRanges: ageRanges,
    nationalityId: parseInt(nationalityId.toString()),
    smoking: parseInt(smokerApply.toString()),
    drivingLicence: drivingLicence,
    drivingLicenceTypes: drivingLicenceTypes,
    jobPreferredLocations: null, //myLocationsListPosts,
    jobPrograms: jobPrograms,
    jobCertificates: jobCertificates,
    jobCompetences: jobCompetences,
    isActive: true,
    companyPreferredLocationIds: companyPreferredLocationId,
    isAllDrivingLicensesMust:
      drivingLicenceTypes.toString() == "[]" ? false : checkCLicenseType,

    isAllCertificatesMust:
      jobCertificates.toString() == "[]" ? false : checkCertificate,
    isAllProgramsMust: jobPrograms.toString() == "[]" ? false : checkProgram,
    isAllCompetencesMust:
      jobCompetences.toString() == "[]" ? false : checkCompetence,

    visibilityStatus:
      jobInterviewDays.length > 0
        ? 1
        : interviewContactValue.toString() == "true" ||
          interviewContactValue == true
        ? 2
        : 1,
    disability: disabilityId,
    jobDegreeOfDisabilityIds: disabilityDegreeListIds,
  };
  console.log("giden veri * * " + JSON.stringify(form.jobInterviewDays));
  props.dispatch(
    DropListAction.blueNewDemand(sendData, history, secondsRef)
  );
}
