import React from 'react'
import noNotificationFound from "../../../../../../../images/svg/NotificationNotFound.svg";

const NotificationNotFound = () => {
  return (
    <div>
      <div>
        <div
          style={{
            marginTop: "12%",
          }}
        >
          <div
            class=""
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              alignContent: "center",
              justifyContent: "center",
              marginTop: "10px",
            }}
          >
            <img
              src={noNotificationFound}
              style={{
                height: "80px",
                textAlign: "center",
              }}
            />
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <p
                style={{
                  color: "#001542",
                  marginTop: "10px",
                  marginBottom: "0px",
                  fontSize: "16px",
                  fontWeight: 600,
                  marginBottom: "0px",
                }}
              >
                Bildirim Bulunamadı
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default NotificationNotFound