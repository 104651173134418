import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  whiteSelectedStatus: [
    { status: 0 },
    { status: 1 },
    { status: 3 },
    { status: 9 },
    { status: 5 },
    { status: 12 },
  ],
  activeCurrentTab:0
};

const filterMatch = createSlice({
  name: "selectedTab",
  initialState,
  reducers: {
    setFilterWhite: (state, action) => {
      state.whiteSelectedStatus = action.payload;
    },
    setCurrentTab: (state, action) => {
      state.activeCurrentTab = action.payload;
    },
  },
});

export const { setFilterWhite, setCurrentTab } = filterMatch.actions;
export default filterMatch.reducer;
