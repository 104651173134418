import React from "react";
import "../../../../../../../css/bitoniscss/language.css";

const LanguageItem = ({ language, index, setShowDelete }) => {
  return (
    <div
      className="card"
      key={language}
      style={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        height: "150px",
        margin: "0px",
        padding: "0px",
        border: "3px dotted #F5F5F5",
        borderRadius: "8px",
        backgroundColor: "white",
		  boxShadow: "none",
	
      }}
    >
      <div style={{ margin: "0px", padding: "0px" }}>
        <i
          className="fa-solid fa-earth-americas"
          style={{
            fontSize: "20px",
            marginLeft: "10px",
            color: "orange",
          }}
        ></i>{" "}
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          textAlign: "center",
          marginLeft: "50px",
        }}
      >
        <div style={{ margin: "0 auto", textAlign: "start" }}>
          <h6
            style={{
              color: "grey",
              fontSize: "13px",
              marginBottom: "0px",
            }}
          >
            Yabancı Dil
          </h6>
          <div
            style={{
              marginTop: "0px",
              marginLeft: "auto",
              marginRight: "auto",
              textAlign: "start",
              fontSize: "13px",
              color: "#001542",
              fontWeight: 500,
            }}
          >
            {language.languageId.label}{" "}
            <h6
              style={{
                marginTop: "6px",
                color: "grey",
                fontSize: "13px",
                marginBottom: "0px",
              }}
            >
              Yabancı Dil Seviyesi
            </h6>
            <div
              style={{
                margin: "0px",
                marginLeft: "auto",
                marginRight: "auto",
                textAlign: "start",
                fontSize: "13px",
                color: "#001542",
                fontWeight: 500,
              }}
            >
              {language.languageLevelId.label}
            </div>
          </div>
        </div>
      </div>{" "}
      <div
        style={{
          height: "20px",
          width: "15px",
        }}
        onClick={setShowDelete}
      >
        {" "}
        <i
          class="fa-solid fa-trash"
          style={{
            position: "absolute", // Position the delete icon absolutely
            top: "10px", // Adjust top and right values to position it properly
            right: "10px",
            height: "20px",
            width: "15px",
            color: "#FF3D00",
          }}
          onMouseOver={(e) => {
            e.currentTarget.style.fontSize = "20px";
          }}
          onMouseOut={(e) => {
            e.currentTarget.style.fontSize = "15px";
          }}
        ></i>
      </div>
    </div>
  );
};

export default LanguageItem;
