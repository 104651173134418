import React, { useEffect, useState } from "react";
import EditForeignLanguageModal from "./Modal/EditForeignLanguageModal";
import AddForeignLanguageModal from "./Modal/AddForeignLanguageModal";
import { DropListWhiteAction } from "../../../../../../../../action";
import CustomPreview from "../../../../../../../widgets/CustomPreview";
import EditIconWidget from "../../../../../../../widgets/EditIconWidget";
import { Box } from "@mui/material";

const ForeignLanguageDemandDetail = ({
  register,
  onSubmit,
  handleSubmit,
  inputStyle,
  cardStyle,
  job,
  props,
  customStyles,
  customStylesMulti,
  positionList,
  isValid,
  control,
  setValue,
  errors,
  watch,
  labelStyle,
  getValues,
  foreignLanguageListRef,
}) => {
  useEffect(() => {
    if (!props.languageListWhite.success) {
      props.dispatch(
        DropListWhiteAction.languageListWhite({
          positionType: 2,
          isActive: true,
        })
      );
    }
    if (!props.languageLevelListWhite.success) {
      props.dispatch(
        DropListWhiteAction.languageLevelListWhite({
          positionType: 2,
          isActive: true,
        })
      );
    }
  }, []);
  const [showEdit, setShowEdit] = useState(null);
  function handleClose() {
    setShowEdit(false);
  }
  const [showAdd, setShowAdd] = useState(null);
  function handleAddClose() {
    setShowAdd(false);
  }
  useEffect(() => {
    foreignLanguageListRef.current = [];

    if (props.job.job.languages) {
      const getLanguages = props.job.job.languages;
      const newLanguagesPosition = getLanguages.map((getLanguage) => {
        return {
          languageId: {
            label: getLanguage.language,
            value: getLanguage.languageId,
          },
          languageLevelId: {
            label: getLanguage.languageLevel,
            value: getLanguage.languageLevelId,
          },
        };
      });

      foreignLanguageListRef.current = [
        ...foreignLanguageListRef.current,
        ...newLanguagesPosition,
      ];
    }
  }, [props.job.job.languages]);
  const [language, setLanguage] = useState(null);
  const [index, setIndex] = useState(null);
  return (
    <div>
      <>
        <div class="card" style={cardStyle}>
          {" "}
          {foreignLanguageListRef.current.length <= 0 ? (
            <HeaderDetail />
          ) : (
            <div></div>
          )}
          <div
            style={
              foreignLanguageListRef.current.length > 0
                ? {
                  visibility: "visible",
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "flex-end",
                  alignItems: "center",
                  marginTop: "0px",
                  paddingTop: "0px",
                  marginBottom: "0px",
                  marginRight: "10px",
                  padding: "0px",
                }
                : {
                  visibility: "hidden",
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "flex-end",
                  alignItems: "center",
                  marginTop: "0px",
                  paddingTop: "0px",
                  marginBottom: "0px",
                  marginRight: "10px",
                  padding: "0px",
                }
            }
          >
            <div
              style={{
                width: "30px",
                height: "30px",
                margin: "0px 5px 0px 0px",
                padding: "0px",
                display: "flex",
                flexDirection: "row",
              }}
            >
              <div
                style={{
                  backgroundColor: "green",
                  borderRadius: "6px",
                  width: "30px",
                  height: "30px",
                  alignItems: "center",
                  display: "flex",
                  justifyContent: "center",
                  transition: "fontSize 0.2s",
                }}
                onMouseOver={(e) => {
                  e.currentTarget.style.width = "35px";
                  e.currentTarget.style.height = "35px";
                }}
                onMouseOut={(e) => {
                  e.currentTarget.style.width = "30px";
                  e.currentTarget.style.height = "30px";
                }}
                onClick={() => {
                  setShowAdd(true);
                }}
              >
                {" "}
                <i
                  class="fa-solid fa-plus"
                  style={{
                    transition: "fontSize 0.2s",
                    color: "white",
                  }}
                ></i>
              </div>
            </div>
            <div
              style={{
                color: "#001542",
                fontWeight: "550",
                marginLeft: "6px",
              }}
            >
              Yeni Yabancı Dil Ekle
            </div>
          </div>
          {foreignLanguageListRef.current.length > 0 ? (
            <HeaderDetail />
          ) : (
            <div></div>
          )}
          <div class="card-body">
            {foreignLanguageListRef.current &&
              foreignLanguageListRef.current.map((language, index) => {
                return (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >


                    <Box
                      sx={{
                        position: "absolute",
                        top: {
                          xs: 50,
                          sm: 60,
                          md: 60,
                          lg: 65,
                          xl: 65,
                        },
                        right: {
                          xs: 10,
                          sm: 25,
                          md: 25,
                          lg: 25,
                          xl: 25,
                        },
                        display: "flex",
                        alignItems: "center",
                        paddingRight: "30px"
                      }}
                    >
                      <EditIconWidget
                        size={20}
                        onTap={() => {
                          if (language) {
                            setIndex(index);

                            setLanguage(language);
                            setShowEdit(true);
                          }
                        }}
                        showContainerEdit={false}
                        showEditText={false}
                      />
                    </Box>



                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                      }}
                    >
                      <CustomPreview
                        name={"Yabancı Dil"}
                        value={language.languageId.label}
                      />
                      <CustomPreview
                        name={"Yabancı Dil Seviyesi"}
                        value={language.languageLevelId.label}
                      />
                      {index !== foreignLanguageListRef.current.length - 1 && (
                        <hr
                          style={{
                            borderTop: "1px dotted lightgrey",
                            margin: "0px 20px 0px 0px",
                            padding: "0px",
                          }}
                        ></hr>
                      )}
                    </div>
                  </div>
                );
              })}
            <div
              style={
                foreignLanguageListRef.current.length > 0
                  ? {
                    visibility: "hidden",
                  }
                  : {
                    visibility: "visible",
                    display: "flex",
                    justifyContent: "center",
                    alignContent: "center",
                    alignItems: "center",
                  }
              }
            >
              <button
                type="button"
                class="btn btn-primary"
                style={{
                  width: "70%",
                  padding: "18px",
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  backgroundColor: "#001542",
                  border: "none",
                }}
                onClick={(value) => {
                  setShowAdd(true);
                }}
              >
                Yabancı Dil Ekle
              </button>
            </div>
          </div>
        </div>
        <EditForeignLanguageModal
          show={showEdit}
          handleClose={handleClose}
          job={job.job}
          onSubmit={onSubmit}
          props={props}
          customStyles={customStyles}
          inputStyle={inputStyle}
          customStylesMulti={customStylesMulti}
          language={language}
          isValid={isValid}
          register={register}
          handleSubmit={handleSubmit}
          control={control}
          setValue={setValue}
          errors={errors}
          watch={watch}
          labelStyle={labelStyle}
          foreignLanguageListRef={foreignLanguageListRef}
          index={index}
          getValues={getValues}
        />
        <AddForeignLanguageModal
          show={showAdd}
          handleClose={handleAddClose}
          job={job.job}
          onSubmit={onSubmit}
          props={props}
          customStyles={customStyles}
          inputStyle={inputStyle}
          customStylesMulti={customStylesMulti}
          language={language}
          isValid={isValid}
          register={register}
          handleSubmit={handleSubmit}
          control={control}
          setValue={setValue}
          errors={errors}
          watch={watch}
          labelStyle={labelStyle}
          foreignLanguageListRef={foreignLanguageListRef}
          getValues={getValues}
        />
      </>
    </div>
  );
};

const HeaderDetail = () => {
  return (
    <div>
      <div
        class="card-header"
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          borderBottom: "none",
          padding: "0px",
          marginTop: "0px",
          marginBottom: "0px",
          paddingBottom: "0px",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            borderBottom: "none",
            marginBottom: "0px",
            paddingBottom: "0px",
          }}
        >
          <h5 class="card-title" style={{}}>
            Yabancı dil
          </h5>
        </div>
      </div>
    </div>
  );
};

export default ForeignLanguageDemandDetail;
