import React, { useEffect, useState } from "react";
import SetNameForVideoConference from "../../Modal/SetNameForVideoConference";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { AccountServices } from "../../../../../../../../../../services/AccountServices";
import { Box } from "@mui/material";
import CustomButton from "../../../../../../../../../widgets/buttons/CustomButton";

const StartVideoConferencesButton = ({
  matchId,
  tooltip,
  title,
  candidateName,
  meetingRoom,
  videoConferansId,
  meetingEmployerId,
  meetingMatchId,
  meetingJobId,
  meetingCandidateId,
  meetingMeetingDateTime,
  meetingCandidateNameSurname,
  meetingPositionName,
}) => {
  //video Konferans Başlat
  const history = useHistory();
  const [companyAlias, setCompanyAlias] = useState(null);

  async function getCompanyInfo() {
    AccountServices.getCompanyHeaderInfo().then((response) => {
      if (response && response.succeeded === true) {
        setCompanyAlias(response.data.alias);
      }
    });
  }

  useEffect(() => {
    getCompanyInfo();
  }, []);
  const [show, setShow] = useState(false);
  function handleClose() {
    setShow(false);
  }

  return (
    <div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center",
        }}
        onClick={() => {
          const dataMap = new Map();
          dataMap.set("roomName", meetingRoom);
          dataMap.set("displayName", title);
          dataMap.set("candidateName", candidateName);
          dataMap.set("meetingRoom", meetingRoom);
          dataMap.set("videoConferansId", videoConferansId);
          dataMap.set("meetingEmployerId", meetingEmployerId);
          dataMap.set("meetingMatchId", meetingMatchId);
          dataMap.set("meetingJobId", meetingJobId);
          dataMap.set("meetingCandidateId", meetingCandidateId);
          dataMap.set("meetingMeetingDateTime", meetingMeetingDateTime);
          dataMap.set(
            "meetingCandidateNameSurname",
            meetingCandidateNameSurname
          );
          dataMap.set("meetingPositionName", meetingPositionName);
          dataMap.set("companyAlias", companyAlias);

          history.push({
            pathname: "/videoConference",
            state: { dataMap },
          });
        }}
      >
        <Box
          sx={{
            margin: "0px",
          }}
        >
          <CustomButton
            title={"Video Konferans Başlat"}
            hoverBackgroundColor={"#077bda"}
            backgroundColor={"#068FFF"}
            border={"none"}
            width={{
              xs: "380px",
              sm: "380px",
              md: "450px",
              lg: "450px",
              xl: "600px",
            }}
          />
        </Box>
      </div>
      <SetNameForVideoConference
        show={show}
        title={title}
        handleClose={handleClose}
        approval={(companyName) => {
          if (companyName) {
            var roomText = matchId.toString();
            handleClose();
          }
        }}
      />
    </div>
  );
};

export default StartVideoConferencesButton;
