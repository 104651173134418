import React, { useState } from "react";
import "../../../../../../../../css/bitoniscss/demand/tabItem.css";
import moment from "moment";

function InternTabItem({
  candidateNameSurname,
  positionName,
  status,
  candidateId,
  matchCreateDate,
  internEducationTypeName,
}) {
  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  const getBackgroundColor = () => {
    if (isHovered) {
      return "#F5F5F5";
    }

    // Diğer durumlar için renkleri döndürün
    return "white";
  };
  return (
    <div
      className="card-body"
      key={candidateId}
      style={{
        display: "flex",
        flexDirection: "row",
        alignContent: "space-between",
        border: isHovered == true ? "1px solid #D6E5FA" : "1px solid #F7F7F7",

        height: "60px",
        position: "relative",
        borderRadius: "10px",
        alignItems: "center",
        width: "100%",
        margin: "8px",
        justifyContent: "space-between",
        cursor: "pointer",
        backgroundColor: getBackgroundColor(), // Dinamik arka plan rengi
      }}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignContent: "space-between",
          alignItems: "center",
          marginLeft: "0px",
          paddingLeft: "0px",
        }}
      >
        <span className="fa-stack fa-lg">
          <i class="fa-solid fa-user"></i>
        </span>
        <div>
          <div className="item-title">
            {candidateNameSurname}{" "}
            {matchCreateDate && (
              <p className="matchCreateDate">
                Eşleşme Tarihi:
                {moment(matchCreateDate && matchCreateDate).format(
                  "DD/MM/YYYY"
                )}
              </p>
            )}
          </div>
        </div>
      </div>
      <div className="position-title">
        {internEducationTypeName}/{positionName}
        <div></div>
      </div>

      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "flex-end",
          alignItems: "flex-end",
        }}
      >
        {status === 2 ? (
          <div
            className="onprossess-status-title"
            style={{
              color: "teal",
            }}
          >
            Staja alındı
          </div>
        ) : status === 3 ? (
          <div
            className="onprossess-status-title"
            style={{
              color: "red",
            }}
          >
            Staja Alınmadı
          </div>
        ) : (
          <div
            className="onprossess-status-title"
            style={{
              color: "#5AB2FF",
            }}
          >
            Yeni Eşleşme
          </div>
        )}
      </div>

      <div
        style={
          status === 2
            ? {
                position: "absolute",
                bottom: 0,
                right: 0,
                width: 0,
                height: 0,
                borderLeft: "30px solid transparent",
                borderTop: "30px solid transparent",
                borderRight: "30px solid teal",
                borderRadius: "0px 0px 8px",
              }
            : status == 3
            ? {
                position: "absolute",
                bottom: 0,
                right: 0,
                width: 0,
                height: 0,
                borderLeft: "30px solid transparent",
                borderTop: "30px solid transparent",
                borderRight: "30px solid red",
                borderRadius: "0px 0px 8px",
              }
            : {
                position: "absolute",
                bottom: 0,
                right: 0,
                width: 0,
                height: 0,
                borderLeft: "30px solid transparent",
                borderTop: "30px solid transparent",
                borderRight: "30px solid #5AB2FF",
                borderRadius: "0px 0px 8px",
              }
        }
      ></div>
    </div>
  );
}

export default InternTabItem;
