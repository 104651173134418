import React, { useState } from "react";
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  Popper,
} from "@material-ui/core";
import { MobileDateTimePicker } from "@mui/x-date-pickers";
import { LocalizationProvider } from "@mui/x-date-pickers";
import trLocale from "date-fns/locale/tr";
import format from "date-fns/format";
import parse from "date-fns/parse";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";

function CustomDatePicker({
  isPickerOpen,
  setIsPickerOpen,
  onChange,
  handleClose,
}) {
  const [selectedDate, handleDateChange] = useState(new Date());

  const handleSave = () => {
    console.log("Seçilen Tarih:", selectedDate);
    const timezoneOffset = selectedDate.getTimezoneOffset(); // Zaman dilimi ofsetini al

    const adjustedDate = new Date(
      selectedDate.getTime() - timezoneOffset * 60 * 1000
    );

    onChange(adjustedDate);
    setIsPickerOpen(false);
  };

  const handleDateChangeAndClose = (date) => {
    handleDateChange(date);
  };

  return (
    <Popper open={isPickerOpen} anchorEl={null} placement="bottom">
      <LocalizationProvider
        dateAdapter={AdapterDateFns}
        adapterLocale={trLocale}
        localeText={{
          okButtonLabel:
            format(selectedDate, "MMMM d, yyyy HH:mm", {
              locale: trLocale,
            }) <=
              format(new Date(), "MMMM d, yyyy HH:mm", {
                locale: trLocale,
              })
              ? ""
              : "Gönder",
          cancelButtonLabel: "Vazgeç",
          dateTimePickerToolbarTitle: "Tarih/Saat Seçin",
        }}
      >
        <MobileDateTimePicker
          open={isPickerOpen}
          onClose={handleClose}
          value={selectedDate}
          onAccept={handleSave}
          onChange={handleDateChangeAndClose}
          showTimeInput
          format="YYYY-MM-DD"
          renderTextField={(props) => (
            <TextField
              variant="outlined"
              margin="normal"
              fullWidth
              {...props}
              value={format(selectedDate, "MMMM d, yyyy HH:mm", {
                locale: trLocale,
              })}
            />
          )}
          renderToolbar={(toolbarProps) => <CustomToolbar {...toolbarProps} />}
        />
      </LocalizationProvider>
    </Popper>
  );
}
function CustomToolbar({ children }) {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        padding: "8px", // Örnek bir stil ekledim, ihtiyaca göre değiştirebilirsiniz.
        backgroundColor: "lightgray", // Örnek bir arka plan rengi ekledim, ihtiyaca göre değiştirebilirsiniz.
      }}
    >
      {children}
      <Button
        color="primary"
        onClick={handleSave}
        disabled={isSaveButtonDisabled()}
        style={
          {
            // İstediğiniz buton stilini burada belirleyebilirsiniz.
          }
        }
      >
        Gönder
      </Button>
    </div>
  );
}
export default CustomDatePicker;
