import React from "react";
import InfoWhiteCv from "./InfoWhiteCv";
import InfoBodyWhite from "./InfoBodyWhite";
import WantsToWorkCriterion from "./WantsToWorkCriterion";
import LanguageWhiteCv from "./LanguageWhiteCv";
import EducationWhiteCv from "./EducationWhiteCv";
import WorkExperiencesWhiteCv from "./WorkExperiencesWhiteCv";
import WhiteHobbiesComponent from "./WhiteHobbiesComponent";
import CvWantLocationWhite from "./CvWantLocationWhite";

const WhiteCandidateCvDetail = ({ whiteMatchDetail }) => {
  var body1;

  body1 = (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        margin: "0px",
        padding: "0px",
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          margin: "0px",
          padding: "0px",
        }}
      >
        <InfoBodyWhite whiteMatchDetail={whiteMatchDetail} />
        <div
          style={{
            margin: "10px",
          }}
        ></div>
        <WantsToWorkCriterion whiteMatchDetail={whiteMatchDetail} />
      </div>
      <AbitiliesCtx />
    </div>
  );

  function AbitiliesCtx() {
    //yetenekler boşsa bu kısım çalışır.
    if (
      whiteMatchDetail &&
      whiteMatchDetail.matchLanguageDetailList &&
      whiteMatchDetail.matchLanguageDetailList.length <= 0
    ) {
      return (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            margin: "0px",
            padding: "0px",
          }}
        >
          {whiteMatchDetail &&
            whiteMatchDetail.educationList &&
            whiteMatchDetail.educationList.length > 0 && (
              <EducationWhiteCv whiteMatchDetail={whiteMatchDetail} />
            )}
          <div
            style={{
              margin: "10px",
            }}
          ></div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              flex: "6",
            }}
          >
            {" "}
            {whiteMatchDetail &&
              whiteMatchDetail.experiencesList &&
              whiteMatchDetail.experiencesList.length > 0 && (
                <WorkExperiencesWhiteCv whiteMatchDetail={whiteMatchDetail} />
              )}
            {whiteMatchDetail && whiteMatchDetail.matchHobbies && (
              <WhiteHobbiesComponent whiteMatchDetail={whiteMatchDetail} />
            )}
          </div>
        </div>
      );
    } else {
      return (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              flex: "6",
            }}
          >
            {whiteMatchDetail &&
              whiteMatchDetail.matchLanguageDetailList &&
              whiteMatchDetail.matchLanguageDetailList.length > 0 && (
                <LanguageWhiteCv whiteMatchDetail={whiteMatchDetail} />
              )}{" "}
            {whiteMatchDetail &&
              whiteMatchDetail.experiencesList &&
              whiteMatchDetail.experiencesList.length > 0 && (
                <WorkExperiencesWhiteCv whiteMatchDetail={whiteMatchDetail} />
              )}
          </div>{" "}
          <div
            style={{
              margin: "10px",
            }}
          ></div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              flex: "6",
            }}
          >
            {" "}
            {whiteMatchDetail &&
              whiteMatchDetail.educationList &&
              whiteMatchDetail.educationList.length > 0 && (
                <EducationWhiteCv whiteMatchDetail={whiteMatchDetail} />
              )}
            {whiteMatchDetail && whiteMatchDetail.matchHobbies && (
              <WhiteHobbiesComponent whiteMatchDetail={whiteMatchDetail} />
            )}
          </div>
        </div>
      );
    }
  }
  //whiteMatchDetail && whiteMatchDetail.matchHobbies
  return (
    <div>
      <div className="card">
        <div
          style={{
            width: "100%",

            padding: "10px",
            margin: "0px",
            backgroundColor: "white",
            borderRadius: "8px",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
            }}
          >
            <InfoWhiteCv whiteMatchDetail={whiteMatchDetail} />
            <CvWantLocationWhite whiteMatchDetail={whiteMatchDetail} />
          </div>
          {body1}
        </div>
      </div>
    </div>
  );
};

//WhiteHobbiesComponent
export default WhiteCandidateCvDetail;
