import React, { useState } from "react";
import "../../../../../../../../../css/bitoniscss/confirmvideoconference.css";
import moment from "moment";
import Evaluate from "./Evaluate";
import { GivenStarTypeEnum } from "../../../../GivenStar/GivenStarTypeEnum";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { Box, Typography } from "@mui/material";
import { Row } from "react-bootstrap";
import ShowSendEvaluateModal from "../../../../GivenStar/ShowSendEvaluateModal";
const ConfirmVideoConferenceMeetingDayItem = ({ matchData }) => {
  const history = useHistory();
  const [isHovered, setIsHovered] = useState(false);
  const [showEvaluateModal, setShowEvaluateModal] = useState(null);

  function handleCloseEvaluateModal(e) {
    setShowEvaluateModal(false);
  }

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  const getBackgroundColor = () => {
    if (isHovered) {
      return "white";
    }

    // Diğer durumlar için renkleri döndürün
    return "white";
  };
  const getBoxShadow = () => {
    return isHovered
      ? "0px 7px 8px #dfdfdf;" // Hovered state shadow (elevation effect)
      : "0px"; // Default state shadow
  };

  return (
    <div>
      <Box
        className="card-body"
        key={matchData.candidateId}
        sx={{
          display: "flex",
          flexDirection: "row",
          alignContent: "space-between",
          border: isHovered == true ? "1px solid #D6E5FA" : "1px solid #F7F7F7",

          boxShadow: getBoxShadow(),
          transition: "box-shadow 0.3s ease-in-out",
          height: "60px",
          position: "relative",
          borderRadius: "10px",
          alignItems: "center",
          width: "100%",
          margin: "8px",
          justifyContent: "space-between",
          cursor: "pointer",
          backgroundColor: getBackgroundColor(), // Dinamik arka plan rengi
        }}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        <Row
          sx={{
            display: "flex",
            flexDirection: "row",
          }}
        >
          <Box
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-start",
              alignContent: "center",
              alignItems: "center",
            }}
          >
            <span
              className="fa-stack fa-lg"
              style={{
                marginLeft: "10px",
              }}
            >
              <i class="fa-solid fa-user"></i>
            </span>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                fontSize: {
                  xs: "10px",
                  sm: "12px",
                  md: "12px",
                  lg: "14px",
                  xl: "14px",
                },
                width: {
                  xs: "120px",
                  sm: "200px",
                  md: "200px",
                  lg: "170px",
                  xl: "200px",
                },
                marginRight: {
                  xs: "-200px",
                },
              }}
            >
              <Typography
                sx={{
                  fontSize: {
                    xs: "10px",
                    sm: "12px",
                    md: "12px",
                    lg: "14px",
                    xl: "14px",
                  },
                  fontWeight: 600,
                  color: {
                    xs: "#001542",
                    sm: "#001542",
                    md: "#001542",
                    lg: "#001542",
                    xl: "#001542",
                  },
                }}
              >
                {matchData.candidateNameSurname}
              </Typography>
            </Box>
          </Box>
        </Row>
        <Box
          sx={{
            width: {
              xs: "120px",
              sm: "120px",
              md: "150px",
              lg: "170px",
              xl: "170px",
            },
            marginLeft: {
              xs: "100px",
              sm: "120px",
              md: "120px",
              lg: "120px",
              xl: "120px",
            },
          }}
        >
          <Typography
            sx={{
              fontSize: {
                xs: "9px",
                sm: "11px",
                md: "11px",
                lg: "13px",
                xl: "12px",
              },
              textAlign: "center",
              fontWeight: 600,
              color: "#001542",
              width: {
                xs: "140px",
                sm: "120px",
                md: "150px",
                lg: "350px",
                xl: "350px",
              },
            }}
          >
            {matchData.positionName}
          </Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-end",
            alignContent: "flex-end",
            alignItems: "flex-end",
          }}
        >
          <Typography
            sx={{
              width: {
                xs: "0px",
                sm: "0px",
                md: "0px",
                lg: "250px",
                xl: "250px",
              },
            }}
          ></Typography>
          <Typography
            sx={{
              width: {
                xs: "140px",
                sm: "140px",
                md: "150px",
                lg: "180px",
                xl: "180px",
              },
              fontSize: {
                xs: "9px",
                sm: "9px",
                md: "11px",
                lg: "11px",
                xl: "11px",
              },
              fontWeight: 600,
              color: {
                xs: "#22668D",
                sm: "#22668D",
                md: "#22668D",
                lg: "#22668D",
                xl: "#22668D",
              },
            }}
          >
            <div>
              {" "}
              Video Konferans Günü Onaylandı
              <div
                style={{
                  fontSize: "9px",
                  fontWeight: 600,
                  color: "#001542",
                }}
              >
                Görüşme Günü :
                {moment(matchData.meetingDate).format("DD/MM/YYYY")}
                <br></br>
                Saat :{moment(matchData.meetingDate).format("HH:mm")}
              </div>
            </div>
          </Typography>
        </Box>
        <Evaluate
          matchData={matchData}
          onTap={(e) => {
            e.stopPropagation();
            e.preventDefault();
            setShowEvaluateModal(true);
          }}
        />
      </Box>
      <ShowSendEvaluateModal
        show={showEvaluateModal}
        handleClose={handleCloseEvaluateModal}
        GivenStarTypeEnum={GivenStarTypeEnum.ByEmployerAfterVideoConf}
        candidateId={matchData.candidateId}
      />
    </div>
  );
};

export default ConfirmVideoConferenceMeetingDayItem;
