// AnotherComponent.jsx
import React from "react";
import CustomPreviewMySkillTwopt from "./CustomPreviewMySkillTwopt";
import CustomPreviewMySkillTwoptDate from "./CustomPreviewMySkillTwoptDate";

const AnotherComponent = ({
  context,
  startDate,
  finishDate,
  present,
  leavingSchool,
}) => {
  return (
    <div
      className=""
      style={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        margin: "10px",
      }}
    >
      <CustomPreviewMySkillTwoptDate
        context={context}
        name={"Başlangıç Tarihi"}
        value={startDate === null || startDate === "nul" ? "" : startDate}
      />
      <ShowStatus
        finishDate={finishDate}
        present={present}
        leavingSchool={leavingSchool}
      />
    </div>
  );
};

const ShowStatus = ({ finishDate, present, leavingSchool }) => {
  if (present.toString() === "true") {
    return <CustomPreviewMySkillTwopt value={"Devam Ediyorum"} />;
  } else if (leavingSchool.toString() === "true") {
    return <CustomPreviewMySkillTwopt value={"Terk"} />;
  } else {
    return (
      <CustomPreviewMySkillTwoptDate
        name={"Bitiş Tarihi"}
        value={finishDate.toString() === "null" ? "" : finishDate}
      />
    );
  }
};

export default AnotherComponent;
