import api from "../../../../../../../../../utility/Utils";

export default function departmentListGetsNotFaculty(setSectionList) {
  //üniversite id ==233 ise bu api çağrılır.
  setSectionList([]);
  api
    .get("common/FacultyDepartmentGroupList")
    .then((response) => {
      setSectionList(response.data);
    })
    .catch((err) => {
      setSectionList([]);
    });
}
