import React from "react";

import { DemandsServices } from "../../../../../../../../../services";
import { setCurrentTab } from "../../../../../../../../../redux/redux-toolkit/filterMatch";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { ToastContainer, toast } from "react-toastify";
import { Box } from "@material-ui/core";
import CustomButton from "../../../../../../../../widgets/buttons/CustomButton";

const WhiteMediumActions = ({
  cardStyle,
  labelStyle,
  inputStyle,
  matchId,
  status,
  whiteMatchDetail,
  title,
  companyId,
  employerId,
}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  let statusBody;
  function whiteMatchTransferMedium(matchId) {
    DemandsServices.whiteMatchTransferMedium(matchId).then(
      (sendMeetingData) => {
        if (sendMeetingData) {
          if (sendMeetingData.succeeded === true) {
            dispatch(setCurrentTab(1));
            toast.success("Aday başarılı şekilde değerlendirmeye alındı", {
              autoClose: 2000,
              onClose: () => {
                setTimeout(() => {
                  history.push("/matchShow");
                }, 100);
              },
            });
          } else {
            toast.info(sendMeetingData.error.message, {
              autoClose: 2000,
              onClose: () => {
                setTimeout(() => {}, 100);
              },
            });
          }
        }
      },
      (error) => {
        toast.info("Beklenmedik Hata Oluştu", {
          autoClose: 2000,
          onClose: () => {
            setTimeout(() => {}, 100);
          },
        });
      }
    );
  }
  switch (status) {
    case 0:
      //bu tamamlandı **
      statusBody = (
        <div>
      
           <Box
            sx={{
              margin: "0px",
            }}
          >
            <CustomButton
              onTap={(val) => {
                if (matchId) {
                whiteMatchTransferMedium(matchId);
              }
              }}
              title={"Adayı Değerlendirmeye Al"}
              width={{
                xs: "380px",
                sm: "380px",
                md: "450px",
                lg: "450px",
                xl: "600px",
              }}
            />
          </Box>

        </div>
      );

      break;
    default:
      statusBody = <h1></h1>;
  }

  return (
    <div
      class=""
      style={{
        padding: "0px",
        margin: "0px",
        paddingBottom: "20px",
      }}
    >
      {statusBody}

      <div
        style={{
          height: "40px",
        }}
      ></div>
    </div>
  );
};

export default WhiteMediumActions;
