import * as XLSX from "xlsx";

export function exportExcel(items) {
  // Check for empty data
  if (!items || items.length === 0) {
    console.warn("Warning: No data found. Cannot create Excel file.");
    return;
  }

  // Copy the data
  var listMatchBlueItems = [...items];

  // Create the Excel sheet
  const worksheet = XLSX.utils.json_to_sheet(listMatchBlueItems);

  // Calculate optimal column widths
  const columnWidths = Object.keys(listMatchBlueItems[0]).map((key) => {
    const maxContentLength = listMatchBlueItems.reduce(
      (max, item) => Math.max(max, String(item[key]).length),
      key.length
    );
    return { wch: maxContentLength + 2 }; // Add some padding
  });
  worksheet["!cols"] = columnWidths;

  // Create the Excel workbook and write to file
  const workbook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
  XLSX.writeFile(workbook, "MaviYakaEşleşmeler.xlsx");
}
