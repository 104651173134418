import React, { useEffect, useRef, useState } from "react";
import InternCandidateCvDetail from "./InternCandidateCvDetail";

function InternCandidateCv({ internMatchDetail, componentRef }) {
  return (
    <>
      <div
        ref={componentRef}
        style={{
          width: "100%",
          padding: "10px",
          margin: "0px",
          backgroundColor: "white",
          borderRadius: "8px",
        }}
        className="card"
      >
        <InternCandidateCvDetail internMatchDetail={internMatchDetail} />
      </div>
    </>
  );
}

export default InternCandidateCv;
