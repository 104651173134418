import axios from 'axios';
import {getToken} from '../configs/cookie';
import {baseUrl} from "../configs/api";

const apiSecure = axios.create({
    "baseURL": baseUrl,
});


apiSecure.interceptors.request.use(async config => {
    const token = getToken();
    if (token) {
        config.headers.Authorization = token
        config.headers.withCredentials = false
        
    }
    return config;
});


apiSecure.interceptors.response.use(function (response) {
    return response.data;
}, function (error) {
    return Promise.reject(error);
});

export default apiSecure;

export const isObjEmpty = obj => Object.keys(obj).length === 0
export const kFormatter = num => (num > 999 ? `${(num / 1000).toFixed(1)}k` : num)
export const htmlToString = html => html.replace(/<\/?[^>]+(>|$)/g, '')

const isToday = date => {
  const today = new Date()
  return (
    /* eslint-disable operator-linebreak */
    date.getDate() === today.getDate() &&
    date.getMonth() === today.getMonth() &&
    date.getFullYear() === today.getFullYear()
    /* eslint-enable */
  )
}
export const formatDate = (value, formatting = { month: 'short', day: 'numeric', year: 'numeric' }) => {
  if (!value) return value
  return new Intl.DateTimeFormat('en-US', formatting).format(new Date(value))
}

export const formatDateToMonthShort = (value, toTimeForCurrentDay = true) => {
  const date = new Date(value)
  let formatting = { month: 'short', day: 'numeric' }

  if (toTimeForCurrentDay && isToday(date)) {
    formatting = { hour: 'numeric', minute: 'numeric' }
  }

  return new Intl.DateTimeFormat('en-US', formatting).format(new Date(value))
}

export const isUserLoggedIn = () => localStorage.getItem('userData')
export const getUserData = () => JSON.parse(localStorage.getItem('userData'))
