import React, { useState } from "react";
import { Card, Tab, Nav } from "react-bootstrap";

import "../../../../../../../css/bitoniscss/match.css";
import { useDispatch, useSelector } from "react-redux";

import { ToastContainer, toast } from "react-toastify";
import moment from "moment";
import { setDemandBlockTab } from "../../../../../../../redux/redux-toolkit/demandBlock";
import BlueCandidateBlock from "./BlueCandidateBlock";
import WhiteCandidateBlock from "./WhiteCandidateBlock";
import BitonisTabBar from "../../../../../../widgets/BitonisTabBar/BitonisTabBar";
import PageInfoTitle from "../../../../../../widgets/PageInfoTitle";

const DemandBlock = () => {
  const dispatch = useDispatch();
  const { activeCurrentTab } = useSelector((state) => state.demandBlockTab);
  const selectedChangeTab = (select) => {
    dispatch(setDemandBlockTab(select));
  };
  useState(null);
  return (
    <div
      id="matchShow"
      style={{
        position: "relative",
      }}
    >
      {" "}
      <ToastContainer
        position="top-right"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    
      <PageInfoTitle title={"Engellenen Adaylar"} />

      <div className="row">
        <div className="col-md-12">
          <Card>
            <Card.Body>
              <BitonisTabBar
                tabLeftTitle={"Mavi Yaka"}
                tabRightTitle={"Beyaz Yaka"}
                tabLeftBody={<BlueCandidateBlock />}
                tabRightBody={<WhiteCandidateBlock />}
                showBorder={false}
                activeTab={activeCurrentTab}
                tabStyle={{
                  fontSize: "0.8rem",
                }}
                onChangeTab={(tab) => {}}
              />
            </Card.Body>
          </Card>
        </div>
      </div>
    </div>
  );
};

export default DemandBlock;
