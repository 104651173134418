import React from "react";
import CustomPdfPreview from "../../../../../../../../widgets/CustomPdfPreview";
import DividerDotted from "../../../../../../../../widgets/DividerDotted";
import { DividerPdf } from "./DividerPdf";
import moment from "moment";

const WorkExperiencesWhiteCv = ({ whiteMatchDetail }) => {
  return (
    <div
      className="card"
      style={{
        flex: "6",
        marginTop: "0px",
        display: "flex",
        flexDirection: "column",
        margin: "0px",
        padding: "0px",
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          marginBottom: "10px",
          padding: "0px",
        }}
      >
        <div
          style={{
            border: "2px solid grey",
            borderRadius: "8px",

            height: "40px",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignContent: "center",
          }}
        >
          <p
            style={{
              padding: "10px",
              margin: "0px",
              fontSize: "10px",
              color: "#001542",
              fontWeight: "500",
            }}
          >
            İş Deneyimleri
          </p>
        </div>
        <hr
          style={{
            color: "black",
            marginLeft: "0px",
            paddingLeft: "0px",
            border: "1px solid grey",
            flex: "6",
            margin: "0px",
            padding: "0px",
          }}
        />
        <div
          style={{
            width: "40px",
            height: "40px",
            borderRadius: "50%",
            border: "1.8px solid grey",
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <i
            class="fa-solid fa-briefcase"
            style={{
              color: "orange",
              fontSize: "12px",
            }}
          ></i>
        </div>
      </div>
      <div
        style={{
          backgroundColor: "#f7f7f7",
          borderRadius: "8px",
          marginTop: "0px",
          marginBottom: "30px",
        }}
      >
        <div
          style={{
            margin: "10px",
          }}
        ></div>
        {whiteMatchDetail &&
          whiteMatchDetail.experiencesList.map((experiences, index) => {
            const times =
              experiences.finishDate === null
                ? parseInt(new Date().getMonth() + 1)
                : parseInt(new Date(experiences.finishDate).getMonth() + 1) -
                  parseInt(new Date(experiences.startDate).getMonth() + 1);

            const dates =
              parseInt(
                new Date(
                  experiences.finishDate === null
                    ? new Date()
                    : experiences.finishDate
                ).getFullYear()
              ) - parseInt(new Date(experiences.startDate).getFullYear());

            return (
              <div style={{}}>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignContent: "center",
                    alignItems: "center",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <p
                      style={{
                        paddingLeft: "5px",
                        color: "#001542",
                        fontSize: "9px",
                        fontWeight: "normal",
                        paddingLeft: "10px",
                        paddingBottom: "0px",
                        display: "flex",
                        flexDirection: "column",
                      }}
                    >
                      {dates.toString() + " Yıl"}
                      <div
                        style={{
                          margin: "0px",
                          padding: "0px",
                          width: "20px",
                          height: "1px",
                          borderRadius: "8px",
                          backgroundColor: "grey",
                        }}
                      ></div>
                      <p> {times.toString() + " ay"}</p>
                    </p>
                  </div>
                  <div
                    style={{
                      width: "100px",
                    }}
                  ></div>
                  <div
                    style={{
                      paddingRight: "10px",
                    }}
                  ></div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                    }}
                  >
                    <p
                      style={{
                        color: "grey",
                        fontSize: "9px",
                        fontWeight: "normal",
                        margin: "0px",
                        padding: "0px",
                      }}
                    >
                      Pozisyon
                    </p>
                    <p
                      style={{
                        margin: "0px",
                        fontSize: "9px",
                        color: "#001542",
                        fontWeight: "500",
                      }}
                    >
                      {experiences.positionName}
                    </p>{" "}
                    <p
                      style={{
                        color: "grey",
                        fontSize: "9px",
                        fontWeight: "normal",
                        margin: "0px",
                        padding: "0px",
                      }}
                    >
                      Firma Adı
                    </p>
                    <p
                      style={{
                        margin: "0px",
                        fontSize: "9px",
                        color: "#001542",
                        fontWeight: "500",
                      }}
                    >
                      {experiences.companyName}
                    </p>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                        }}
                      >
                        {" "}
                        <p
                          style={{
                            color: "grey",
                            fontSize: "9px",
                            fontWeight: "normal",
                            margin: "0px",
                            padding: "0px",
                          }}
                        >
                          Başlangıç Tarihi
                        </p>
                        <p
                          style={{
                            margin: "0px",
                            fontSize: "9px",
                            color: "#001542",
                            fontWeight: "500",
                          }}
                        >
                          {moment(
                            whiteMatchDetail && experiences.startDate
                          ).format("MM.YYYY")}
                        </p>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          marginRight: "30px",
                        }}
                      ></div>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                        }}
                      >
                        <ShowStatusFinishExperience
                          finishDate={
                            whiteMatchDetail && experiences.finishDate
                          }
                          present={whiteMatchDetail && experiences.present}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                {index !== whiteMatchDetail.experiencesList.length - 1 && (
                  <hr style={{ borderTop: "1px dotted orange" }}></hr>
                )}
              </div>
            );
          })}
        <div
          style={{
            paddingBottom: "20px",
          }}
        ></div>
      </div>
    </div>
  );
};
const ShowStatusFinishExperience = ({ finishDate, present }) => {
  if (present === true) {
    return (
      <p
        style={{
          margin: "0px",
          fontSize: "9px",
          color: "#001542",
          fontWeight: "500",
        }}
      >
        Hala Çalışıyorum
      </p>
    );
  } else {
    return (
      <div>
        <p
          style={{
            color: "grey",
            fontSize: "9px",
            fontWeight: "normal",
            margin: "0px",
            padding: "0px",
          }}
        >
          Bitiş Tarihi
        </p>
        <p
          style={{
            margin: "0px",
            fontSize: "9px",
            color: "#001542",
            fontWeight: "500",
          }}
        >
          {moment(finishDate && finishDate).format("MM.YYYY")}
        </p>
      </div>
    );
  }
};
export default WorkExperiencesWhiteCv;
