import {getToken} from "./cookie";

//export const baseUrl = "prod-ip";
//export const baseUrl = "http://localhost:3001";
export const baseUrl = "https://testsocket.bitonis.com";




export const apiHeaders = () => {
    const token = getToken();
    if (token) {
        return {Authorization: token };
    }
    return {};
};
