import React from "react";
import CustomPdfPreview from "../../../../../../../../widgets/CustomPdfPreview";
import NotesCv from "./NotesCv";

const LastWorkCompanyCv = ({ blueMatchDetail }) => {
  return (
    <div
      style={{
        flex: "6",
        marginTop: "7px",
        display: "flex",
        flexDirection: "column",
        marginLeft: "15px",
        marginBottom: "30px",
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          marginBottom: "10px",
          padding: "0px",
        }}
      >
        <div
          style={{
            border: "2px solid grey",
            borderRadius: "8px",

            height: "40px",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignContent: "center",
          }}
        >
          <p
            style={{
              padding: "10px",
              margin: "0px",
              fontSize: "10px",
              color: "#001542",
              fontWeight: "500",
            }}
          >
           En Son Çalıştığı İş Yeri
          </p>
        </div>
        <hr
          style={{
            color: "black",
            marginLeft: "0px",
            paddingLeft: "0px",
            border: "1px solid grey",
            flex: "6",
            margin: "0px",
            padding: "0px",
          }}
        />
        <div
          style={{
            width: "40px",
            height: "40px",
            borderRadius: "50%",
            border: "1.8px solid grey",
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <i
            class="fa-solid fa-briefcase"
            style={{
              color: "orange",
              fontSize: "12px",
            }}
          ></i>
        </div>
      </div>
      <div
        style={{
          backgroundColor: "#f7f7f7",
          borderRadius: "8px",
          marginTop: "10px",
          paddingBottom: "5px",
        }}
      >
        <div
          style={{
            margin: "10px",
          }}
        >
          <CustomPdfPreview
            name={"Firma Adı"}
            value={
              blueMatchDetail &&
              blueMatchDetail.lastWorkCompany &&
              blueMatchDetail.lastWorkCompany.lastCompanyName
            }
          />{" "}
          <DividerPdf />
          <CustomPdfPreview
            name={"Pozisyon"}
            value={
              blueMatchDetail &&
              blueMatchDetail.lastWorkCompany &&
              blueMatchDetail.lastWorkCompany.positionName
            }
          />{" "}
          <DividerPdf />
          <CustomPdfPreview
            name={"Çalışma Süreniz"}
            value={
              blueMatchDetail &&
              blueMatchDetail.lastWorkCompany &&
              blueMatchDetail.lastWorkCompany.yourWorkingTime
            }
          />
          <DividerPdf />
          <CustomPdfPreview
            name={"Adres"}
            value={
              blueMatchDetail &&
              blueMatchDetail.lastWorkCompany &&
              blueMatchDetail.lastWorkCompany.address.toString()
            }
          />
        </div>
      </div>
      <NotesCv />
    </div>
  );
};

const DividerPdf = () => {
  return (
    <div>
      <div>
        <hr
          style={{
            marginTop: "0px",
            marginBottom: "0px",
            padding: "0px",
            paddingTop: "5px",
            border: "none",
            borderTop: "2px solid rgb(236, 227, 188)",
            color: " #fff",
            flex: "6",
          }}
        ></hr>
      </div>
    </div>
  );
};
export default LastWorkCompanyCv;
