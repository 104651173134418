import React, { useState } from "react";
import { Card, Tab, Nav } from "react-bootstrap";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ShowEmployerDemandRequestsVideoCv from "./ShowEmployerDemandRequestsVideoCv";
import ShowEmployerDemandSendsVideoCv from "./ShowEmployerDemandSendsVideoCv";
import { useDispatch, useSelector } from "react-redux";
import { setCurrentDemandVideoCvTab } from "../../../../../../../redux/redux-toolkit/demandVideoCvTab";
import BitonisTabBar from "../../../../../../widgets/BitonisTabBar/BitonisTabBar";
import PageInfoTitle from "../../../../../../widgets/PageInfoTitle";
import PrevIconButton from "../../../../../../widgets/buttons/PrevIconButton";

const EmployerDemandVideoCv = () => {
  const dispatch = useDispatch();
  const { activeCurrentTab } = useSelector((state) => state.demandVideoCvTab);
  const selectedChangeTab = (select) => {
    dispatch(setCurrentDemandVideoCvTab(select));
  };

  return (
    <div
      id="matchShow"
      style={{
        position: "relative",
      }}
    >
      <ToastContainer
        position="top-right"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />

  
      <PageInfoTitle title={"Video CV Talep Ettiğim Adaylar"} />

      <div className="row">
        <div className="col-md-12">
          <Card>
            <Card.Body>
              <BitonisTabBar
                tabLeftTitle={"Video CV İstediklerim"}
                tabRightTitle={"Video CV Gönderenler"}
                tabLeftBody={<ShowEmployerDemandRequestsVideoCv />}
                tabRightBody={<ShowEmployerDemandSendsVideoCv />}
                showBorder={false}
                activeTab={activeCurrentTab}
                tabStyle={{
                  fontSize: "0.8rem",
                }}
                onChangeTab={(tab) => {}}
              />
            </Card.Body>
          </Card>
        </div>
      </div>
    </div>
  );
};

export default EmployerDemandVideoCv;
