import React from "react";
import { Box, Typography } from "@mui/material";
import { Image } from "@mui/icons-material";

const DataNotFound = ({title,notFoundImage}) => {
  return (
    <div>
      <div>
        <div
          style={{
            marginTop: "12%",
          }}
        >
          <Box

            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              alignContent: "center",
              justifyContent: "center",
              marginTop: "10px",
            }}
          >
            <Box
              component={"img"}
              sx={{
                height: 150,
                width: 150,
                maxHeight: {
                  xs: 60, 
                  sm: 70,
                  md: 70, 
                  lg: 90, 
                  xl: 90,
                },
                maxWidth: {
                   xs: 60, // 0px ve üzeri ekran genişlikleri için
                  sm: 70, // 600px ve üzeri ekran genişlikleri için
                  md: 70, // 900px ve üzeri ekran genişlikleri için
                  lg: 90, // 1200px ve üzeri ekran genişlikleri için
                  xl: 90, // 1536px ve üzeri ekran genişlikleri için,
                 },
              }}
              alt=""
              src={notFoundImage}
            />
            <Box sx={{
              marginTop: "10px"
            }}>
              <Typography
                sx={{
                  fontSize: {
                    xs: "9px", // 0px ve üzeri ekran genişlikleri için
                    sm: "12px", // 600px ve üzeri ekran genişlikleri için
                    md: "12px", // 900px ve üzeri ekran genişlikleri için
                    lg: "13px", // 1200px ve üzeri ekran genişlikleri için
                    xl: "12px", // 1536px ve üzeri ekran genişlikleri için,
                  },
                  fontWeight: 600,
                  color: {
                    xs: "#001542", // 0px ve üzeri ekran genişlikleri için
                    sm: "#001542", // 600px ve üzeri ekran genişlikleri için
                    md: "#001542", // 900px ve üzeri ekran genişlikleri için
                    lg: "#001542", // 1200px ve üzeri ekran genişlikleri için
                    xl: "#001542" // 1536px ve üzeri ekran genişlikleri için
                  },
                }}
              >

                <div>{title} </div>

              </Typography>
            </Box>
          </Box>
        </div>
      </div>
    </div>
  );
};

export default DataNotFound;
