import React from "react";
import demandNotFound from "../../../../../../../../images/svg/demandNotFound.svg";
import useIsMobile from "../../../../../../../hooks/useIsMobile";

const CouponNotFound = () => {
  const isMobile = useIsMobile();
  return (
  
        <div
          style={{
            margin: "5%",
          }}
        >
          <div
            class=""
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              alignContent: "center",
              justifyContent: "center",
              marginTop: "10px",
            }}
          >
            <img
              src={demandNotFound}
              style={{
                height: "80px",
                textAlign: "center",
              }}
            />
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
             
              <p
                style={{
                  color: "#001542",
                  marginTop: "5px",
                  marginBottom: "0px",
                  fontSize: isMobile ? "12px" : "16px",
                  fontWeight: 500,
                  marginBottom: "30px",
                  width: isMobile ? "360px" : "500px",
                }}
              >
                Hesabınıza tanımlı indirim kuponunuz bulunmamaktadır.
              </p>
            </div>
          </div>
        </div>
     
  );
};

export default CouponNotFound;
