import React, { useState, useCallback, useEffect } from "react";
import {
  GoogleMap,
  LoadScript,
  Marker,
  useJsApiLoader,
} from "@react-google-maps/api";
import { Box, TextField, IconButton, Typography } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import LoadingDotsAnimation from "../LoadingDotsAnimation";
// Varsayılan merkezi konum
const defaultCenter = {
  lat: 39.9334,
  lng: 32.8597,
};

const mapContainerStyle = {
  width: "100%",
  height: "100%",
  padding: "20px",
  borderRadius: "0px 0px 16px 16px",
  overflow: "hidden",
};

const options = {
  disableDefaultUI: true,
  zoomControl: true,
};

const GoogleMapWidget = ({
  initialLatLng,
  onChangedPositioned,
  widthMap,
  heightMap,
}) => {
  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
  });

  const [map, setMap] = useState(null);
  const [markerPosition, setMarkerPosition] = useState(defaultCenter);
  const [searchQuery, setSearchQuery] = useState("");
  const [loading, setLoading] = useState(false);
  const [showTextField, setShowTextField] = useState(false);
  const [messageCount, setMessageCount] = useState(null);

  const onLoad = useCallback((map) => {
    setMap(map);
    setLoading(false);
  }, []);

  const onUnmount = useCallback(() => {
    setMap(null);
  }, []);

  useEffect(() => {
    if (map && initialLatLng && initialLatLng.lat && initialLatLng.lng) {
      // Önceki değeri güncellemeden önce karşılaştırın

      map.panTo(initialLatLng);
      setMarkerPosition(initialLatLng);
      onChangedPositioned(initialLatLng);
    }
  }, [initialLatLng, map, onChangedPositioned]);

  const handleMapClick = (e) => {
    const lat = e.latLng.lat();
    const lng = e.latLng.lng();
    console.log("Tıklanan Konum:", lat, lng); // Konumu kontrol edin
    // alert(`Tıklanan Konum: e: ${JSON.stringify(e)}`); // Bilgiyi gösterin

    const newPosition = { lat, lng };
    setMarkerPosition(newPosition);
    setMarkerPosition(newPosition);

    if (map) {
      map.panTo(newPosition);
      map.panTo(newPosition);
    }
    onChangedPositioned(newPosition);
    onChangedPositioned(newPosition);
    setShowTextField(false);
    setSearchQuery("");
  };

  const handleSearch = (count) => {
    if (!map) return;
    setLoading(true);

    if (searchQuery.trim() === "") {
      setMarkerPosition(defaultCenter);
      map.panTo(defaultCenter);
      onChangedPositioned(defaultCenter);
      setLoading(false);
      return;
    }

    const geocoder = new window.google.maps.Geocoder();
    geocoder.geocode(
      { address: searchQuery.toLowerCase() },
      (results, status) => {
        if (status === "OK" && results[0]) {
          const location = results[0].geometry.location;
          const newPosition = {
            lat: location.lat(),
            lng: location.lng(),
          };
          map.panTo(newPosition);
          setMarkerPosition(newPosition);
          onChangedPositioned(newPosition);
        } else {
          if (count && count === 1) {
            toast.info("Aradığınız lokasyon bulunamadı", {
              autoClose: 2000,
              onClose: () => {
                setTimeout(() => {}, 1000);
              },
            });
          }
        }
        setLoading(false);
      }
    );
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      handleSearch(0);
      handleSearch(1);
    }
  };

  if (!isLoaded)
    return (
      <div>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignContent: "center",
            alignItems: "center",
          }}
        >
          <LoadingDotsAnimation />
        </Box>
      </div>
    );

  return (
    <Box>
      <Box
        sx={{
          backgroundColor: "#F7F7F7",
          marginTop: "20px",
          borderRadius: "16px 16px 0px 0px",
        }}
      >
        <Typography
          sx={{
            fontSize: {
              xs: "13px",
              sm: "13px",
              md: "15px",
              lg: "15px",
              xl: "15px",
            },
            margin: "0px",
            padding: "0px",
            fontWeight: 500,
            color: "#001542",
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-start",
            alignContent: "flex-start",
            alignItems: "flex-start",
            padding: "10px",
          }}
        >
          📌{" "}
          <span
            style={{
              marginLeft: "10px",
            }}
          >
            {" "}
            Harita üzerinde arama yaparken önce ili, sonra firma adını yazınız.
          </span>
        </Typography>
      </Box>
      <Box sx={{ position: "relative", marginTop: "0px", paddingTop: "0px" }}>
        {showTextField && (
          <TextField
            variant="outlined"
            placeholder="Lokasyon Ararken Önce İl sonra Adresinzi yazın. "
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            onKeyDown={handleKeyDown}
            InputProps={{
              sx: {
                border: "none",
                "& .MuiOutlinedInput-notchedOutline": {
                  border: "none",
                },
                width: "100%",
                height: "45px",
                padding: "5px",
                border: "1px solid #d1cfcf",
                fontSize: {
                  xs: "12px",
                  sm: "12px",
                  md: "15px",
                  lg: "15px",
                  xl: "15px",
                },
              },
            }}
            sx={{
              position: "absolute",
              top: 10,
              left: 10,
              backgroundColor: "white",
              borderRadius: "12px 0px 0px 12px",
              width: "calc(100% - 60px)",
              zIndex: 1,
              "& .MuiInputBase-root": {
                borderRadius: "12px 0px 0px 12px",
              },
              "& .MuiOutlinedInput-root": {
                borderRadius: "12px 0px 0px 12px",
              },
            }}
          />
        )}
        <IconButton
          color="primary"
          onClick={() => {
            if (showTextField) {
              if (searchQuery === "") {
              } else {
                handleSearch(0);
                handleSearch(1);
              }
            }
            setShowTextField(!showTextField);
          }}
          disabled={loading}
          sx={{
            position: "absolute",
            top: 10,
            right: 10,
            backgroundColor: "white",
            borderRadius: showTextField == false ? "8px" : "0px 10px 12px 0px",
            height: "45px",
            padding: "8px",
            zIndex: 1,
            "&:hover": {
              backgroundColor: "#f0f0f0",
            },
          }}
        >
          <SearchIcon />
        </IconButton>
        <Box
          sx={{
            width: widthMap ?? "100%",
            height: heightMap ?? "80vh",
            position: "relative",
            marginTop: "0px",
          }}
        >
          {
            <GoogleMap
              mapContainerStyle={mapContainerStyle}
              center={markerPosition}
              zoom={14.4746}
              options={options}
              onLoad={onLoad}
              onUnmount={onUnmount}
              onClick={(e) => {
                handleMapClick(e);
                handleMapClick(e);
              }}
            >
              <Marker position={markerPosition} />
            </GoogleMap>
          }
        </Box>
      </Box>
    </Box>
  );
};

export default GoogleMapWidget;
