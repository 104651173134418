import React from "react";
import { Box, Typography, Tooltip } from "@mui/material";

const CustomLocationNoTitle = ({ name, value }) => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: {
          xs: 'column',
          sm: 'row'
        },
        justifyContent: {
          sm: 'space-between',
          md: 'space-between'
        },
        mb: 0,
        padding:"0px"
      }}
    
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          width: '100%',
          bgcolor: '#f7f7f7',
          borderRadius: '6px',
          p: 2,
          border: '1px solid #f3f3f6',
          mr: 0,
          flexDirection: 'row',
        }}
      >
        <Typography
          sx={{
            flex: 1,
            pl: 0,
            fontWeight: 600,
            color: '#001542',
            fontSize: {
              xs: '10px',
              sm: '11px',
              md: '13px'
            },
          }}
        >
          {value && value.toString()}
        </Typography>
     
      </Box>
    </Box>
  );
};

export default CustomLocationNoTitle;
