import React, { useState } from "react";
import BitonisSingleDropdown from "../BitonisSingleDropdown";
import { labelStyle } from "../../customStyleInline";
import { DemandsServices } from "../../../services";
import BitonisMultiDropdown from "../BitonisMultiDropdown";
import CustomDesign from "../CustomDesign";

const FindInternSelectEducation = (props) => {
  const {
    register,
    control,
    watch,
    reset,
    setValue,
    errors,
    getValues,
    sectionList,
    setSectionList,
    onMultiSection,
  } = props;

  var findEducationInternList = [
    {
      id: 3,
      name: "Lise",
    },
    {
      id: 4,
      name: "Ön Lisans",
    },
    {
      id: 5,
      name: "Lisans",
    },
    {
      id: 6,
      name: "Yüksek Lisans",
    },
  ];
  var educationInternClassList = [
    {
      id: 1,
      name: "1. Sınıf",
    },
    {
      id: 2,
      name: "2. Sınıf",
    },
    {
      id: 3,
      name: "3. Sınıf",
    },
    {
      id: 4,
      name: "4. Sınıf",
    },
  ];
  var internEducationType = getValues("internEducationType");
  function internHighSchoolDepartmentList() {
    setSectionList([]);
    //stajyer için lise bölüm listesini verir
    DemandsServices.internHighSchoolDepartmentList().then((response) => {
      if (response && response.succeeded === true) {
        response.data.map((val) => {
          setSectionList((prev) => [...prev, val]);
        });
      }
    });
  }

  function internFacultyDepartmentGroupList() {
    setSectionList([]);
    //stajyer riçin fakültelerin bölüm listesini verir.
    DemandsServices.internFacultyDepartmentGroupList().then((response) => {
      if (response && response.succeeded === true) {
        response.data.map((val) => {
          setSectionList((prev) => [...prev, val]);
        });
      }
    });
  }
  return (
    <div>
      <CustomDesign
        label={"Eğitim Durumu"}
        required={true}
        child={
          <BitonisSingleDropdown
            items={findEducationInternList.map(({ id, name }) => ({
              value: id,
              label: name,
            }))}
            register={register}
            watch={watch}
            companyClassName={"internEducationType"}
            control={control}
            setValue={setValue}
            errors={errors}
            placeholder="Eğitim Seçin"
            onValueChanged={(e) => {
              console.log(e);
              if (e) {
                setValue("internEducationSection", "");
                setValue("internEducationClass", "");
                if (e.value === 3) {
                  //lise
                  internHighSchoolDepartmentList();
                } else {
                  //lise üstü
                  internFacultyDepartmentGroupList();
                }
              }
            }}
            required={true}
            getValues={getValues}
            errorMessage={"Eğitim Boş geçilemez"}
          />
        }
      />

      {internEducationType &&
        (onMultiSection && onMultiSection == true ? (
          <CustomDesign
            label={"Bölüm"}
            required={true}
            child={
              <BitonisMultiDropdown
                items={
                  sectionList &&
                  sectionList.map(({ id, name }) => ({
                    value: id,
                    label: name,
                  }))
                }
                register={register}
                watch={watch}
                companyClassName={"internEducationSection"}
                control={control}
                setValue={setValue}
                errors={errors}
                errorMessage={"Bölüm Boş geçilemez"}
                placeholder="Bölüm Seçin"
                onValueChanged={(value) => {
                  console.log(value);
                  if (value) {
                    setValue("internEducationClass", "");
                  }
                }}
                required={true}
                getValues={getValues}
                selectedId={
                  internEducationType && internEducationType.value == 3
                    ? 1
                    : 698
                }
              />
            }
          />
        ) : (
          <CustomDesign
            label={"Bölüm"}
            required={true}
            child={
              <BitonisSingleDropdown
                items={
                  sectionList &&
                  sectionList.map(({ id, name }) => ({
                    value: id,
                    label: name,
                  }))
                }
                register={register}
                watch={watch}
                companyClassName={"internEducationSection"}
                control={control}
                setValue={setValue}
                errors={errors}
                placeholder="Bölüm Seçin"
                onValueChanged={(e) => {
                  console.log(e);
                  if (e) {
                    setValue("internEducationClass", "");
                  }
                }}
                required={true}
                getValues={getValues}
                errorMessage={"Bölüm Boş geçilemez"}
              />
            }
          />
        ))}

      {internEducationType && (
        <CustomDesign
          label={"Sınıf"}
          required={true}
          child={
            <BitonisMultiDropdown
              items={
                educationInternClassList &&
                educationInternClassList.map(({ id, name }) => ({
                  value: id,
                  label: name,
                }))
              }
              register={register}
              watch={watch}
              companyClassName={"internEducationClass"}
              control={control}
              setValue={setValue}
              errors={errors}
              errorMessage={"Sınıf Boş geçilemez"}
              placeholder="Sınıf Seçin"
              onValueChanged={(value) => {
                console.log(value);
              }}
              required={true}
              getValues={getValues}
            />
          }
        />
      )}
    </div>
  );
};

export default FindInternSelectEducation;
