import LoadingDotsAnimation from "../LoadingDotsAnimation";

const IsLoadingToShowDataModal = ({ data, showBody ,isFooter}) => {
    return (
      <div>
        {data === null ? (
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
            }}
          >
         { isFooter===undefined||isFooter===null||isFooter===false?  <LoadingDotsAnimation />:<div></div>}
          </div>
        ) : (
          <div>{showBody}</div>
        )}
      </div>
    );
  };
export default IsLoadingToShowDataModal
