import React from "react";

import "../../css/bitoniscss/custompdfpreview.css";

const CustomHobbiesPdfPreview = ({ name, value, matchPointStatus }) => {
  return (
    <div className="widgetPc-container">
   
      <div className="widgetPc-value">{value && value.toString()}</div>
    </div>
  );
};

export default CustomHobbiesPdfPreview;
