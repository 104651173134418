import React, { useEffect, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import api from "../../../../../../../../utility/Utils";
const ChangeInternDemandStatus = ({ props, jobId, defaultJobStatus }) => {
  const [demandStatus, setDemandStatus] = useState(defaultJobStatus);

  useEffect(() => {
    if (props.job.job) {
      setDemandStatus(props.job.job.isActive);
    }
  }, [props.job.job]);

  function changeInternDemandStatusValue(changeStatus) {
    demandChangeStatus(changeStatus).then((res) => {
      if (res.succeeded == true) {
        setDemandStatus(res.data);
        toast.info(res.result.message);
      } else {
        setDemandStatus(defaultJobStatus);
        toast.info(res.error.message);
      }
    });
  }

  function demandChangeStatus(changeStatus) {
    // Talep Pasive Alma
    var sendData = { jobId: props.job.job.id, isActive: changeStatus };
    return api
      .post("/intern/job/updateStatus", sendData)
      .then((response) => {
        return Promise.resolve(response);
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  }

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
      }}
    >
      {demandStatus === true ? (
        <button
          className="btn btn-danger"
          onClick={() => {
            changeInternDemandStatusValue(false);
          }}
          style={{
            borderRadius: "8px",
            width: "50%",
          }}
        >
          Pasife Al
        </button>
      ) : demandStatus === false ? (
        <button
          className="btn btn-primary"
          style={{
            borderRadius: "8px",
            width: "50%",
          }}
          onClick={() => {
            changeInternDemandStatusValue(true);
          }}
        >
          Aktife Al
        </button>
      ) : (
        <div></div>
      )}
    </div>
  );
};

export default ChangeInternDemandStatus;
