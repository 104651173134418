import React, { useEffect, useState } from "react";

import Modal from "react-bootstrap/Modal"; // Varsayılan olarak React Bootstrap Modal bileşeni kullanılıyor
import { Alert, Button } from "react-bootstrap";
import { useForm } from "react-hook-form";

function SetNameForVideoConference({ show, handleClose, approval, title }) {
  return (
    <>
      <div>
        <MyVerticallyCenteredModal
          show={show}
          onHide={() => handleClose(false)}
          approval={approval}
          title={title}
        />
      </div>
    </>
  );
}
function MyVerticallyCenteredModal(props) {
  const { approval, show, title } = props;
  const {
    register,
    handleSubmit,
    control,
    watch,
    reset,
    setValue,
    formState: { errors, isValid },
  } = useForm();
  function setNameFunction(form) {
    approval(form.conferenceShowCompanyName);
  }
  useEffect(() => {
    if (show) {
      setValue("conferenceShowCompanyName", title);
    }
  }, [show]);
  return (
    <Modal
      {...props}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      dialogClassName="full-screen-modal" // Tam ekran sınıfı ekleyin
    >
      <Modal.Header closeButton style={{ borderBottom: "0px" }}>
        <Modal.Title
          aria-labelledby="contained-modal-title-vcenter"
          style={{
            width: "100%",
            textAlign: "center",
            margin: "0 auto",
          }}
        >
          Video Konferansta görünecek isminiz
        </Modal.Title>
      </Modal.Header>
      <Modal.Body
        style={{
          paddingTop: "0px",
          marginTop: "0px",
        }}
      >
        <form onSubmit={handleSubmit(setNameFunction)}>
          <input
            type="text"
            className="form-control"
            id="inputClass"
            name="conferenceShowCompanyName"
            {...register("conferenceShowCompanyName", { required: true })}
            placeholder="Video Konferanst görünecek İsim"
          ></input>
          {errors.conferenceShowCompanyName && (
            <div className="text-danger fs-12">{"Firma İsmi Boş Olamaz"}</div>
          )}
        </form>
      </Modal.Body>
      <Modal.Footer
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          alignContent: "center",
          borderTop: "none",
        }}
      >
        {" "}
        <form onSubmit={handleSubmit(setNameFunction)}>
          <Button
            as="input"
            type="submit"
            value="Katıl"
            style={{
              borderRadius: "8px",
              fontSize: "15px",
              padding: "15px",
              width: "300px",
              cursor: "pointer",
              transition: "background-color 0.3s",
              backgroundColor: "#001542",
              border: "none",
            }}
          />
        </form>
      </Modal.Footer>
    </Modal>
  );
}
export default SetNameForVideoConference;
