import { DemandMatchBlueConstants } from "../constants";

const defaultMatchBlue = {
  loading: false,
  error: false,
  matchBlue: false,
  success: false,
};

function setState(changes) {
  return { ...defaultMatchBlue, ...changes };
}

export function matchBlue(state = defaultMatchBlue, action) {
  switch (action.type) {
    case DemandMatchBlueConstants.GET_ALL_REQUEST:
      return setState({ loading: true });
    case DemandMatchBlueConstants.GET_ALL_SUCCESS:
      return setState({ matchBlue: action.demandMatch, success: true });
    case DemandMatchBlueConstants.GET_ALL_FAILURE:
      return setState({ error: action.demandMatch });
    default:
      return state;
  }
}
