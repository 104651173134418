import { Button } from "@mui/material";
import React from "react";

const StepperNextButton = ({ title, currentStep }) => {
  return (
    <Button
      type="submit"
      sx={{
        borderRadius: 35,
        backgroundColor: "#001542",
        padding: "15px 0px 15px 0px",
        fontSize: "18px",
        borderRadius: "8px",
        marginTop: "0px",
        width: {
          xs: "250px",
          sm: "300px",
          md: "350px",
          lg: "350px",
          xl: "350px",
        },
        marginLeft:
          currentStep == 0
            ? {}
            : {
                xs: "5%",
                sm: "5%",
                md: "5%",
                lg: "5%",
                xl: "5%",
              },
        fontSize: {
          xs: "13px",
          sm: "13px",
          md: "15px",
          lg: "15px",
          xl: "15px",
        },
        "&:hover": {
          backgroundColor: "#011c56", // Hover rengi burdan ayarlanır.
        },
      }}
      variant="contained"
    >
      {title}
    </Button>
  );
};

export default StepperNextButton;
