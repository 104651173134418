import React, { useState } from "react";
import "../../../../../../../../../css/bitoniscss/incomingMeetingDay.css";
import moment from "moment";
import Evaluate from "./Evaluate";
import { GivenStarTypeEnum } from "../../../../GivenStar/GivenStarTypeEnum";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import ShowSendEvaluateModal from "../../../../GivenStar/ShowSendEvaluateModal";

const ConfirmIncomingMeetingDayItem = ({ matchData, title }) => {
  const history = useHistory();
  const [showEvaluateModal, setShowEvaluateModal] = useState(null);

  function handleCloseEvaluateModal(e) {
    setShowEvaluateModal(false);
  }

  return (
    <div>
      <div
        className="card-body cardItem"
        key={matchData.candidateId}
        style={{
          position: "relative",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignContent: "space-between",
            alignItems: "center",
            marginLeft: "0px",
          }}
        >
          <span className="fa-stack fa-lg">
            <i class="fa-solid fa-user"></i>
          </span>

          <div className="candidate-name">{matchData.candidateNameSurname}</div>
        </div>
        <td>
          <div className="position-name">{matchData.positionName}</div>
        </td>
        <div className="item">
          <div className="incomingConfirmMeetingDay-match">{title}</div>
          <div
            style={{
              fontSize: "9px",
              fontWeight: 600,
              color: "#001542",
            }}
          >
            Görüşme Günü :{moment(matchData.meetingDate).format("DD/MM/YYYY")}
            <br></br>
            Saat :{moment(matchData.meetingDate).format("HH:mm")}
          </div>
        </div>
        <Evaluate
          matchData={matchData}
          onTap={(e) => {
            e.stopPropagation();
            e.preventDefault();
            setShowEvaluateModal(true);
          }}
        />
      </div>
      <ShowSendEvaluateModal
        show={showEvaluateModal}
        handleClose={handleCloseEvaluateModal}
        GivenStarTypeEnum={GivenStarTypeEnum.ByEmployerAfterVideoConf}
        candidateId={matchData.candidateId}
      />
    </div>
  );
};

export default ConfirmIncomingMeetingDayItem;
