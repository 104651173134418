import api from "../utility/Utils";
import { format } from "date-fns";

export const DemandsServices = {
  demands,
  analytic,
  create,
  DemandsOwn,
  demandPassive,
  matchingDemands,
  matchingDemandsWhite,
  hireJob,
  interneEmployerMatchApprove,
  demandMatchBlue,
  demandMatchWhite,
  profileEmployerCandidateLabourer,
  profileEmployerIntern,
  sendVideoConferenceDate,
  SendMeetingDate,
  employerChangeMatchMeetingday,
  whiteMatchTransferMedium,
  RecruitingNotHired,
  ConfirmIncomingMeetingDate,
  HireRequest,
  profileGetMatchWhiteSeeker,
  candidateMediumMatchDetailProfileCandidateEmployee,
  getMatchPoint,
  sendRequestVideoCv,
  copyDemandBlue,
  copyDemandIntern,
  demandsBlue,
  demandsIntern,
  demandsWhite,
  copyDemandWhite,
  sendCandidateDocument,
  demandMGetMatchBlueAll,
  demandsBlockBlue,
  employerBlockCandidateUpdate,
  getEmployerHeaderData,
  getDataWhyBlockCandidate,
  demandsBlockWhite,
  blueCurrentDemandToGetaLLMatchShow,
  whiteCurrentDemandToGetaLLMatchShow,
  addNewPcc,
  getEmployerMeetingList,
  getEmployerVideoConfMeetingList,
  ConfirmIncomingNewMeetingDate,
  reevaluateCandidateMatching,
  newApprovedVideoConfMeetingDay,
  InterviewMeetingDeclinedByEmployerFunction,
  changeEmployerVideoConferenceMeetingDay,
  VideoConfDeclinedByEmployerFunction,
  getGivenStarList,
  sendGivenStar,
  callCandidateForInterview,
  employeeCurrendDemandToMediumMatchList,
  videoConfJoined,
  videoConfClosed,
  internHighSchoolDepartmentList,
  internFacultyDepartmentGroupList,
  internCurrentDemandToGetDataMatchShow,
  getBlueMatchPoint,
  currentUserActionPageLog,
};

function internHighSchoolDepartmentList() {
  return api
    .get("/common/internHighSchoolDepartmentListForJob")
    .then((response) => {
      return Promise.resolve(response);
    })
    .catch((err) => {
      return Promise.reject(err);
    });
}

function internFacultyDepartmentGroupList() {
  return api
    .get("/common/FacultyDepartmentGroupList")
    .then((response) => {
      return Promise.resolve(response);
    })
    .catch((err) => {
      return Promise.reject(err);
    });
}

function videoConfJoined(
  meetingJobId,
  meetingEmployerId,
  meetingCandidateId,
  videoConferansId,
  meetingMatchId,
  meetingMeetingDateTime,
  meetingRoom,
  meetingCandidateNameSurname,
  meetingPositionName,
  meetingCompanyAlias
) {
  var sendData = {
    jobId: meetingJobId,
    employerId: meetingEmployerId,
    candidateId: meetingCandidateId,
    videoConferansId: videoConferansId,
    matchId: meetingMatchId,
    meetingDateTime: meetingMeetingDateTime,
    meetingRoom: meetingRoom,
    candidateNameSurname: meetingCandidateNameSurname,
    positionName: meetingPositionName,
    companyAlias: meetingCompanyAlias,
  };
  return api
    .post("/videoconf/participation/joined", sendData)
    .then((response) => {
      return Promise.resolve(response);
    })
    .catch((err) => {
      return Promise.reject(err);
    });
}
function videoConfClosed(videoConferanceParticipantId) {
  return api
    .post("/videoconf/participation/left/" + videoConferanceParticipantId)
    .then((response) => {
      return Promise.resolve(response);
    })
    .catch((err) => {
      return Promise.reject(err);
    });
}

function addNewPcc(arg) {
  var sendData = {
    pcc: arg,
  };
  return api
    .post("/common/addnewpcc", sendData)
    .then((response) => {
      return Promise.resolve(response);
    })
    .catch((err) => {
      return Promise.reject(err);
    });
}

function demandsBlockBlue() {
  var sendData = {
    matchRestrictionStatus: 1,
  };
  return api
    .post("/employer/match/listOfRestrictedLabourer", sendData)
    .then((response) => {
      return Promise.resolve(response);
    })
    .catch((err) => {
      return Promise.reject(err);
    });
}
function demandsBlockWhite() {
  var sendData = {
    matchRestrictionStatus: 1,
  };
  return api
    .post("/employer/match/listOfRestrictedEmployee", sendData)
    .then((response) => {
      return Promise.resolve(response);
    })
    .catch((err) => {
      return Promise.reject(err);
    });
}
function demands(arg) {
  return api
    .post("demand/list", { params: arg })
    .then((response) => {
      return Promise.resolve(response);
    })
    .catch((err) => {
      return Promise.reject(err);
    });
}

function demandsBlue(arg) {
  return api
    .post("/demand/list", arg)
    .then((response) => {
      return Promise.resolve(response);
    })
    .catch((err) => {
      return Promise.reject(err);
    });
}

function currentUserActionPageLog(arg) {
  return api
    .post("/common/actionlog", arg)
    .then((response) => {
      
      return Promise.resolve(response);
    })
    .catch((err) => {
      return Promise.reject(err);
    });
}
function demandsIntern(arg) {
  return api
    .post("/intern/job/list", arg)
    .then((response) => {
      return Promise.resolve(response);
    })
    .catch((err) => {
      return Promise.reject(err);
    });
}
function demandsWhite(arg) {
  return api
    .post("/demand/list", arg)
    .then((response) => {
      return Promise.resolve(response);
    })
    .catch((err) => {
      return Promise.reject(err);
    });
}
function copyDemandBlue(arg) {
  return api
    .post("/job/labourer/copy", arg)
    .then((response) => {
      return Promise.resolve(response);
    })
    .catch((err) => {
      return Promise.reject(err);
    });
}
function copyDemandIntern(arg) {
  return api
    .post("/intern/job/copy", arg)
    .then((response) => {
      return Promise.resolve(response);
    })
    .catch((err) => {
      return Promise.reject(err);
    });
}
function copyDemandWhite(arg) {
  return api
    .post("/job/employee/copy", arg)
    .then((response) => {
      return Promise.resolve(response);
    })
    .catch((err) => {
      return Promise.reject(err);
    });
}
function sendCandidateDocument(arg) {
  return api
    .post("/employer/hirechecklistthecandidate", arg)
    .then((response) => {
      return Promise.resolve(response);
    })
    .catch((err) => {
      return Promise.reject(err);
    });
}
function DemandsOwn(arg) {
  return api
    .get("post/own", { params: arg })
    .then((response) => {
      return Promise.resolve(response);
    })
    .catch((err) => {
      return Promise.reject(err);
    });
}
function getDataWhyBlockCandidate(positionType) {
  return api
    .get("/common/restrictionreasons/" + positionType)
    .then((response) => {
      return Promise.resolve(response);
    })
    .catch((err) => {
      return Promise.reject(err);
    });
}
function analytic(arg) {
  return api
    .get("post/" + arg.type + "/" + arg.value)
    .then((response) => {
      return Promise.resolve(response);
    })
    .catch((err) => {
      return Promise.reject(err);
    });
}

function create(arg) {
  return api
    .post("post/add", arg)
    .then((response) => {
      return Promise.resolve(response);
    })
    .catch((err) => {
      return Promise.reject(err);
    });
}

function demandPassive(arg) {
  // Talep Pasive Alma
  return api
    .post("/job/status", arg)
    .then((response) => {
      return Promise.resolve(response.data);
    })
    .catch((err) => {
      return Promise.reject(err);
    });
}
function matchingDemands(arg) {
  //Taleplerim ile eşleyen Mavi Yaka
  return api
    .post("/employer/labourer/match", arg)
    .then((response) => {
      return Promise.resolve(response.data);
    })
    .catch((err) => {
      return Promise.reject(err);
    });
}
function matchingDemandsWhite(arg) {
  //Taleplerim ile eşleyen Mavi Yaka
  return api
    .post("/employer/match", arg)
    .then((response) => {
      return Promise.resolve(response.data);
    })
    .catch((err) => {
      return Promise.reject(err);
    });
}

function hireJob(arg) {
  //Taleplerim ile eşleyen İşe Al

  return api
    .post("/employer/labourer/match/status", arg)
    .then((response) => {
      return Promise.resolve(response);
    })
    .catch((err) => {
      return Promise.reject(err);
    });
}
function interneEmployerMatchApprove(arg) {
  //stajyer al,alma

  return api
    .post("/employer/intern/match/setStatus", arg)
    .then((response) => {
      return Promise.resolve(response);
    })
    .catch((err) => {
      return Promise.reject(err);
    });
}
function callCandidateForInterview(arg) {
  //görüşmeye çağırmak için kullanılır

  return api
    .post("/employer/labourer/match/callToMeeting", arg)
    .then((response) => {
      return Promise.resolve(response);
    })
    .catch((err) => {
      return Promise.reject(err);
    });
}
function getEmployerHeaderData(arg) {
  //Taleplerim ile eşleyen İşe Al
  return api
    .get("/company/main/header", arg)
    .then((response) => {
      return Promise.resolve(response);
    })
    .catch((err) => {
      return Promise.reject(err);
    });
}
function employerBlockCandidateUpdate(arg) {
  //adayı engelleyip engellememe durumunu günceller
  return api
    .post("/employer/match/restrict", arg)
    .then((response) => {
      return Promise.resolve(response);
    })
    .catch((err) => {
      return Promise.reject(err);
    });
}

function demandMatchBlue(selectedStatus) {
  //Taleplerim ile eşleşenler mavi yaka
  var statusPush = [];
  (selectedStatus && selectedStatus != null) ||
  (selectedStatus && selectedStatus != [])
    ? selectedStatus &&
      selectedStatus.map((value) => statusPush.push(value["status"]))
    : [];
  var sendData = {
    status: statusPush,
    keyword: "",
    pageNumber: 1,
    pageSize: 125,
  };
  return api
    .post("/employer/labourer/match", sendData)
    .then((response) => {
      return Promise.resolve(response);
    })
    .catch((err) => {
      return Promise.reject(err);
    });
}

function blueCurrentDemandToGetaLLMatchShow(selectedStatus, jobId) {
  //ilgili taleb ile eşleşen bütün adayları getirir
  var statusPush = [];
  (selectedStatus && selectedStatus != null) ||
  (selectedStatus && selectedStatus != [])
    ? selectedStatus &&
      selectedStatus.map((value) => statusPush.push(value["status"]))
    : [];
  var sendData = {
    status: statusPush,
    keyword: "",
    pageNumber: 1,
    pageSize: 125,
    jobId: jobId,
  };
  return api
    .post("/employer/labourer/match/grouped", sendData)
    .then((response) => {
      return Promise.resolve(response);
    })
    .catch((err) => {
      return Promise.reject(err);
    });
}

function internCurrentDemandToGetDataMatchShow(selectedStatus, jobId) {
  //ilgili taleb ile eşleşen bütün stajyer adayları getirir
  var statusPush = [];
  (selectedStatus && selectedStatus != null) ||
  (selectedStatus && selectedStatus != [])
    ? selectedStatus &&
      selectedStatus.map((value) => statusPush.push(value["status"]))
    : [];
  var sendData = {
    status: statusPush,
    keyword: "",
    pageNumber: 1,
    pageSize: 125,
    jobId: jobId,
  };
  return api
    .post("/employer/intern/match/grouped", sendData)
    .then((response) => {
      return Promise.resolve(response);
    })
    .catch((err) => {
      return Promise.reject(err);
    });
}
function whiteCurrentDemandToGetaLLMatchShow(selectedStatus, jobId) {
  //ilgili taleb ile eşleşen bütün adayları getirir
  var statusPush = [];
  (selectedStatus && selectedStatus != null) ||
  (selectedStatus && selectedStatus != [])
    ? selectedStatus &&
      selectedStatus.map((value) => statusPush.push(value["status"]))
    : [];
  var sendData = {
    status: statusPush,
    keyword: "",
    pageNumber: 1,
    pageSize: 125,
    jobId: jobId,
  };
  return api
    .post("/employer/match/grouped", sendData)
    .then((response) => {
      return Promise.resolve(response);
    })
    .catch((err) => {
      return Promise.reject(err);
    });
}

function employeeCurrendDemandToMediumMatchList(selectedStatus,jobId) {
  //ilgili taleb ile eşleşen bütün adayları getirir
  var statusPush = [];
  (selectedStatus && selectedStatus != null) ||
  (selectedStatus && selectedStatus != [])
    ? selectedStatus &&
      selectedStatus.map((value) => statusPush.push(value["status"]))
    : [];
  var sendData = {
    pageNumber: 1,
    pageSize: 125,
    keyword: "",
    matchedTransferStatus: statusPush,
    jobId:jobId
  };
  return api
    .post("/employer/match/getMediums", sendData)
    .then((response) => {
      return Promise.resolve(response);
    })
    .catch((err) => {
      return Promise.reject(err);
    });
}
function demandMGetMatchBlueAll(selectedStatus) {
  //Taleplerim ile eşleşenler mavi yakaların hepsini ve bilgilerini getirir
  var statusPush = [];
  (selectedStatus && selectedStatus != null) ||
  (selectedStatus && selectedStatus != [])
    ? selectedStatus &&
      selectedStatus.map((value) => statusPush.push(value["status"]))
    : [];
  var sendData = {
    status: statusPush,
    keyword: "",
    pageNumber: 1,
    pageSize: 125,
  };
  return api
    .post("/employer/labourer/match/web", sendData)
    .then((response) => {
      return Promise.resolve(response);
    })
    .catch((err) => {
      return Promise.reject(err);
    });
}
function demandMatchWhite(selectedStatus) {
  //Taleplerim ile eşleşenler beyaz yaka
  var statusPush = [];
  (selectedStatus && selectedStatus != null) ||
  (selectedStatus && selectedStatus != [])
    ? selectedStatus &&
      selectedStatus.map((value) => statusPush.push(value["status"]))
    : [];
  var sendData = {
    status: statusPush,
    keyword: "",
    pageNumber: 1,
    pageSize: 125,
  };
  return api
    .post("/employer/match", sendData)
    .then((response) => {
      return Promise.resolve(response);
    })
    .catch((err) => {
      return Promise.reject(err);
    });
}
function getEmployerMeetingList(selectedStatus) {
  //iş yerinde görüşme planladığım adaylar

  var sendData = { status: selectedStatus, isActive: true };
  return api
    .post("/interviewMeeting/getEmployerMeetingList", sendData)
    .then((response) => {
      return Promise.resolve(response);
    })
    .catch((err) => {
      return Promise.reject(err);
    });
}
function getEmployerVideoConfMeetingList(selectedStatus) {
  //video konferans planladığım adaylar

  var sendData = {
    employerId: null,
    candidateId: null,
    status: selectedStatus,
    isActive: true,
  };
  return api
    .post("/videoconf/getemployermeetinglist", sendData)
    .then((response) => {
      return Promise.resolve(response);
    })
    .catch((err) => {
      return Promise.reject(err);
    });
}
function profileEmployerCandidateLabourer(candidateId, matchId, matchStatus) {
  //Taleplerim ile eşleşen mavi yaka detay
  var sendData = {
    isActive: true,
    candidateId: parseInt(candidateId.toString()),
    matchId: null,
  };
  var pathUrl = "";
  if (matchStatus == 2) {
    console.log("işe alınanların detayını açar");
    pathUrl = "/employer/hiredProfileCandidateLabourer";
  } else if (matchStatus == 3) {
    console.log("işe alınmayanların detayını açar");
    pathUrl = "employer/notHiredProfileCandidateLabourer";
  } else {
    console.log("işe alınanlar ve alınmayanlar dışındakilerin detayını açar");
    pathUrl = "/employer/profileEmployerCandidateLabourer";
  }
  return api
    .post(pathUrl, sendData)
    .then((response) => {
      return Promise.resolve(response);
    })
    .catch((err) => {
      return Promise.reject(err);
    });
}

function profileEmployerIntern(candidateId, matchId) {
  //Taleplerim ile eşleşen stajyer  detay
  var sendData = {
    isActive: true,
    candidateId: parseInt(candidateId.toString()),
    matchId: matchId,
  };

  var pathUrl = "/employer/intern/match/profile";

  return api
    .post(pathUrl, sendData)
    .then((response) => {
      return Promise.resolve(response);
    })
    .catch((err) => {
      return Promise.reject(err);
    });
}
function profileGetMatchWhiteSeeker(candidateId, matchId) {
  //Taleplerim ile eşleşen beyaz yaka detay
  var sendData = {
    isActive: true,
    candidateId: parseInt(candidateId.toString()),
    matchId: matchId,
  };
  return api
    .post("/employer/profileEmployerCandidateEmployee", sendData)
    .then((response) => {
      return Promise.resolve(response);
    })
    .catch((err) => {
      return Promise.reject(err);
    });
}
function candidateMediumMatchDetailProfileCandidateEmployee(
  candidateId,
  matchId
) {
  //Taleplerim ile eşleşen beyaz yaka medium detay
  var sendData = {
    isActive: true,
    candidateId: parseInt(candidateId.toString()),
    matchId: matchId,
  };
  return api
    .post("/employer/mediumProfileCandidateEmployee", sendData)
    .then((response) => {
      return Promise.resolve(response);
    })
    .catch((err) => {
      return Promise.reject(err);
    });
}
function sendVideoConferenceDate(matchId, date, candidateId) {
  //Video Konferans Günü Belirle
  //bu tamamlandı
  var sendData = {
    candidateId: candidateId,
    meetingDateTime: date,
    matchId: matchId,
  };
  return api
    .post("/videoconf/create", sendData)
    .then((response) => {
      return Promise.resolve(response);
    })
    .catch((err) => {
      return Promise.reject(err);
    });
}
function changeEmployerVideoConferenceMeetingDay(matchId, date) {
  //Video Konferans İçin Yeni Gün/Saat Belirle
  //bu tamamlandı
  var sendData = {
    meetingDay: date,
    matchId: matchId,
  };
  return api
    .post("/videoconf/changemeetingdateByEmployer", sendData)
    .then((response) => {
      return Promise.resolve(response);
    })
    .catch((err) => {
      return Promise.reject(err);
    });
}
function SendMeetingDate(matchId, date) {
  //İş Yerinde Görüşme Günü Belirle ve İş Yerinde Yeni Görüşme Günü Belirle
  //tamamlandı
  var sendData = {
    matchId: matchId,
    meetingDateTime: date,
  };
  return api
    .post("/interviewMeeting/create", sendData)
    .then((response) => {
      return Promise.resolve(response);
    })
    .catch((err) => {
      return Promise.reject(err);
    });
}
function employerChangeMatchMeetingday(matchId, date) {
  //İş Yerinde Görüşme Günü Belirle ve İş Yerinde Yeni Görüşme Günü Belirle
  //tamamlandı
  //yeni api
  var sendData = {
    matchId: matchId,
    meetingDay: date,
  };
  return api
    .post("/interviewMeeting/ChangeMeetingDateByEmployer", sendData)
    .then((response) => {
      return Promise.resolve(response);
    })
    .catch((err) => {
      return Promise.reject(err);
    });
}
function whiteMatchTransferMedium(matchId) {
  //adayı değerlendirmeye al
  var sendData = {
    matchId: matchId,
  };
  return api
    .post("/Employer/match/transferMedium", sendData)
    .then((response) => {
      return Promise.resolve(response);
    })
    .catch((err) => {
      return Promise.reject(err);
    });
}
function reevaluateCandidateMatching(matchId, date, candidateId) {
  //Aday TYenden değrlendirildi
  //tamamlandı
  var sendData = {
    isActive: true,
    candidateId: candidateId,
    matchId: matchId,
  };
  return api
    .post("/employer/match/reevaluate", sendData)
    .then((response) => {
      return Promise.resolve(response);
    })
    .catch((err) => {
      return Promise.reject(err);
    });
}
function RecruitingNotHired(matchId) {
  //İşe alma

  var sendData = { matchId: matchId };
  return api
    .post("/employer/match/nothired", sendData)
    .then((response) => {
      return Promise.resolve(response);
    })
    .catch((err) => {
      return Promise.reject(err);
    });
}
function InterviewMeetingDeclinedByEmployerFunction(matchId) {
  //görüşmeyi reddet

  return api
    .get("/interviewMeeting/declinedByEmployer/" + matchId)
    .then((response) => {
      return Promise.resolve(response);
    })
    .catch((err) => {
      return Promise.reject(err);
    });
}
function VideoConfDeclinedByEmployerFunction(matchId) {
  //video konferans görüşmeyi reddet

  return api
    .get("/videoconf/declinedByEmployer/" + matchId)
    .then((response) => {
      return Promise.resolve(response);
    })
    .catch((err) => {
      return Promise.reject(err);
    });
}
function getGivenStarList(rotation) {
  //değerlendirme listesi getirilir.
  var sendData = { rotation: rotation };
  return api
    .post("/employer/star/descriptions", sendData)
    .then((response) => {
      return Promise.resolve(response);
    })
    .catch((err) => {
      return Promise.reject(err);
    });
}
function sendGivenStar(givenData) {
  //değerlendirme değerini apiye gönderir

  return api
    .post("/employer/star/give", givenData)
    .then((response) => {
      return Promise.resolve(response);
    })
    .catch((err) => {
      return Promise.reject(err);
    });
}

function ConfirmIncomingMeetingDate(matchId) {
  //Gelen Görüşme Gününü Onayla
  //tammalandı
  return api
    .get("/employer/match/approvedbyemployer/" + matchId)
    .then((response) => {
      return Promise.resolve(response);
    })
    .catch((err) => {
      return Promise.reject(err);
    });
}
function ConfirmIncomingNewMeetingDate(matchId) {
  //Gelen Görüşme Gününü Onayla
  return api
    .get("/employer/match/approvedbyemployer/" + matchId)
    .then((response) => {
      return Promise.resolve(response);
    })
    .catch((err) => {
      return Promise.reject(err);
    });
}
function newApprovedVideoConfMeetingDay(matchId) {
  //Gelen video konferans Görüşme Gününü Onayla
  return api
    .get("/videoconf/approveByEmployer/" + matchId)
    .then((response) => {
      return Promise.resolve(response);
    })
    .catch((err) => {
      return Promise.reject(err);
    });
}
function HireRequest(matchId) {
  //İşe al
  var sendData = {
    matchId: matchId,
  };
  return api
    .post("/employer/match/approve", sendData)
    .then((response) => {
      return Promise.resolve(response);
    })
    .catch((err) => {
      return Promise.reject(err);
    });
}

function getMatchPoint(matchId) {
  //yeni eşleşmede eşleşme oranını verir
  var sendData = {
    matchId: matchId,
    employerId: null,
  };
  return api
    .post("/employer/match/points", sendData)
    .then((response) => {
      return Promise.resolve(response);
    })
    .catch((err) => {
      return Promise.reject(err);
    });
}

function getBlueMatchPoint(matchId) {
  return api
    .get("/employer/labourer/match/columnStatus?matchId=", matchId)
    .then((response) => {
     // alert("response* * " + JSON.stringify(response));
      return Promise.resolve(response);
    })
    .catch((err) => {
      return Promise.reject(err);
    });
}
function sendRequestVideoCv(matchId, writeVideoCv) {
  //video cv de istenilenleri iş arayana gönder sendRequestVideoCv
  var sendData = {
    matchId: matchId,
    description: writeVideoCv.toString(),
  };
  return api
    .post("/employer/match/additional-video-cv-awaited", sendData)
    .then((response) => {
      return Promise.resolve(response);
    })
    .catch((err) => {
      return Promise.reject(err);
    });
}
