export const ProfileDetailConstans = {
    GET_REQUEST: 'PROFILE_GET_REQUEST',
    GET_SUCCESS: 'PROFILE_GET_SUCCESS',
    GET_CLEAR: 'PROFILE_GET_CLEAR',
    GET_FAILURE: 'PROFILE_GET_FAILURE',
    GET_ALL_REQUEST: 'PROFILE_GET_ALL_REQUEST',
    GET_ALL_SUCCESS: 'PROFILE_GET_ALL_SUCCESS',
    GET_ALL_FAILURE: 'PROFILE_GET_ALL_FAILURE',
};
export const ProfileDetailMainConstans = {
    GET_REQUEST: 'PROFILEMAIN_GET_REQUEST',
    GET_SUCCESS: 'PROFILEMAIN_GET_SUCCESS',
    GET_CLEAR: 'PROFILEMAIN_GET_CLEAR',
    GET_FAILURE: 'PROFILEMAIN_GET_FAILURE',
    GET_ALL_REQUEST: 'PROFILEMAIN_GET_ALL_REQUEST',
    GET_ALL_SUCCESS: 'PROFILEMAIN_GET_ALL_SUCCESS',
    GET_ALL_FAILURE: 'PROFILEMAIN_GET_ALL_FAILURE',
};


export const ProfileAddedRoutesMainConstans = {
  GET_REQUEST: "PROFILEADDEDrOUTESMAIN_GET_REQUEST",
  GET_SUCCESS: "PROFILEADDEDrOUTESMAIN_GET_SUCCESS",
  GET_CLEAR: "PROFILEADDEDrOUTESMAIN_GET_CLEAR",
  GET_FAILURE: "PROFILEADDEDrOUTESMAIN_GET_FAILURE",
  GET_ALL_REQUEST: "PROFILEADDEDrOUTESMAIN_GET_ALL_REQUEST",
  GET_ALL_SUCCESS: "PROFILEADDEDrOUTESMAIN_GET_ALL_SUCCESS",
  GET_ALL_FAILURE: "PROFILEADDEDrOUTESMAIN_GET_ALL_FAILURE",
};