export var inputStyle = {
  borderRadius: "8px",
  height: "55px",
  backgroundColor: "#F5F5F5",
  margin: "0px",
};
export var cardStyle = {
  border: "1.1px dotted orange",
  padding: "10px",
};
export const customStyles = {
  control: (provided) => ({
    ...provided,
    borderRadius: "6px",
    padding: "10px 10px 10px 0px",
    border: "1px solid rgb(226, 226, 226)",
    fontSize: "15px",
    color: "#001542",
  }),
  option: (provided) => ({
    ...provided,
    fontSize: "15px", // Font boyutunu 9px olarak ayarlayın
  }),

  placeholder: (provided) => ({
    ...provided,
    fontSize: "13px", // Placeholder yazı boyutu
    marginLeft: "2px",
  }),
};
export const customStylesMulti = {
  multiValue: (provided) => ({
    ...provided,
  }),
  control: (provided) => ({
    ...provided,
    borderRadius: "6px",
    padding: "10px 10px 10px 0px",
    border: "1px solid rgb(226, 226, 226)",
    fontSize: "15px",
    color: "#001542",
  }),
  multiValueLabel: (provided) => ({
    ...provided,
    fontSize: "15px", // MultiValue etiket yazı boyutu
  }),
  multiValueRemove: (provided) => ({
    ...provided,
    fontSize: "15px", // MultiValue kaldırma simgesi yazı boyutu
  }),
  option: (provided) => ({
    ...provided,
    fontSize: "15px", // Font boyutunu 9px olarak ayarlayın
  }),

  placeholder: (provided) => ({
    ...provided,
    fontSize: "13px", // Placeholder yazı boyutu
    marginLeft: "2px",
  }),
};
export const customStylesMultiNotPadding = {
  multiValue: (provided) => ({
    ...provided,
  }),
  control: (provided) => ({
    ...provided,
    borderRadius: "6px",
    padding: "10px 0px 10px 0px",
    border: "1px solid rgb(226, 226, 226)",
    fontSize: "15px",
    color: "#001542",
  }),
  multiValueLabel: (provided) => ({
    ...provided,
    fontSize: "15px", // MultiValue etiket yazı boyutu
  }),
  multiValueRemove: (provided) => ({
    ...provided,
    fontSize: "15px", // MultiValue kaldırma simgesi yazı boyutu
  }),
  option: (provided) => ({
    ...provided,
    fontSize: "15px", // Font boyutunu 9px olarak ayarlayın
  }),

  placeholder: (provided) => ({
    ...provided,
    fontSize: "13px", // Placeholder yazı boyutu
    marginLeft: "2px",
  }),
};

export const labelStyle = {
  fontSize: "14px",
  fontWeight:500
};
