export const RecruitedConstants = {
    GET_REQUEST: 'RECRUITED_GET_REQUEST',
    GET_SUCCESS: 'RECRUITED_GET_SUCCESS',
    GET_CLEAR: 'RECRUITED_GET_CLEAR',
    GET_FAILURE: 'RECRUITED_GET_FAILURE',
    GET_ALL_REQUEST: 'RECRUITED_GET_ALL_REQUEST',
    GET_ALL_SUCCESS: 'RECRUITED_GET_ALL_SUCCESS',
    GET_ALL_FAILURE: 'RECRUITED_GET_ALL_FAILURE',
};
export const RecruitedConstantsWhite = {
    GET_REQUEST: 'RECRUITEDWHITE_GET_REQUEST',
    GET_SUCCESS: 'RECRUITEDWHITE_GET_SUCCESS',
    GET_CLEAR: 'RECRUITEDWHITE_GET_CLEAR',
    GET_FAILURE: 'RECRUITEDWHITE_GET_FAILURE',
    GET_ALL_REQUEST: 'RECRUITEDWHITE_GET_ALL_REQUEST',
    GET_ALL_SUCCESS: 'RECRUITEDWHITE_GET_ALL_SUCCESS',
    GET_ALL_FAILURE: 'RECRUITEDWHITE_GET_ALL_FAILURE',
};

export const RecruitedDetailConstants = {
    GET_REQUEST: 'RECRUITEDDETAIL_GET_REQUEST',
    GET_SUCCESS: 'RECRUITEDDETAIL_GET_SUCCESS',
    GET_CLEAR: 'RECRUITEDDETAIL_GET_CLEAR',
    GET_FAILURE: 'RECRUITEDDETAIL_GET_FAILURE',
    GET_ALL_REQUEST: 'RECRUITEDDETAIL_GET_ALL_REQUEST',
    GET_ALL_SUCCESS: 'RECRUITEDDETAIL_GET_ALL_SUCCESS',
    GET_ALL_FAILURE: 'RECRUITEDDETAIL_GET_ALL_FAILURE',
};
export const RecruitedDetailWhiteConstants = {
    GET_REQUEST: 'RECRUITEDDETAILWHITE_GET_REQUEST',
    GET_SUCCESS: 'RECRUITEDDETAILWHITE_GET_SUCCESS',
    GET_CLEAR: 'RECRUITEDDETAILWHITE_GET_CLEAR',
    GET_FAILURE: 'RECRUITEDDETAILWHITE_GET_FAILURE',
    GET_ALL_REQUEST: 'RECRUITEDDETAILWHITE_GET_ALL_REQUEST',
    GET_ALL_SUCCESS: 'RECRUITEDDETAILWHITE_GET_ALL_SUCCESS',
    GET_ALL_FAILURE: 'RECRUITEDDETAILWHITE_GET_ALL_FAILURE',
};

export const UnRecruitedConstants = {
    GET_REQUEST: 'UNRECRUITED_GET_REQUEST',
    GET_SUCCESS: 'UNRECRUITED_GET_SUCCESS',
    GET_CLEAR: 'UNRECRUITED_GET_CLEAR',
    GET_FAILURE: 'UNRECRUITED_GET_FAILURE',
    GET_ALL_REQUEST: 'UNRECRUITED_GET_ALL_REQUEST',
    GET_ALL_SUCCESS: 'UNRECRUITED_GET_ALL_SUCCESS',
    GET_ALL_FAILURE: 'UNRECRUITED_GET_ALL_FAILURE',
};
export const UnRecruitedConstantsWhite = {
    GET_REQUEST: 'UNRECRUITEDWHITE_GET_REQUEST',
    GET_SUCCESS: 'UNRECRUITEDWHITE_GET_SUCCESS',
    GET_CLEAR: 'UNRECRUITEDWHITE_GET_CLEAR',
    GET_FAILURE: 'UNRECRUITEDWHITE_GET_FAILURE',
    GET_ALL_REQUEST: 'UNRECRUITEDWHITE_GET_ALL_REQUEST',
    GET_ALL_SUCCESS: 'UNRECRUITEDWHITE_GET_ALL_SUCCESS',
    GET_ALL_FAILURE: 'UNRECRUITEDWHITE_GET_ALL_FAILURE',
};