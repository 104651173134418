import React, { useEffect, useRef } from "react";
import "../../../../../../../../../css/bitoniscss/progressCircle.css";

const ProgressCircle = ({ value }) => {
  const radius = 20; // Dairenin yarıçapı
  const strokeWidth = 3; // Yolun kalınlığı
  const normalizedRadius = radius - strokeWidth * 2;
  const circumference = normalizedRadius * 2 * Math.PI;
  const strokeDashoffset = circumference - (value / 100) * circumference;

  const circlePathRef = useRef(null);
  const textStyle = {
    animation: "textOpacityAnimation 0.5s ease forwards",
  };

  useEffect(() => {
    circlePathRef.current.style.setProperty("--offset", value / 100);
  }, [value]);

  return (
    <div className="">
      <svg height="100" width="100">
        <circle
          className="progress-circle-trail"
          stroke="#d6d6d6"
          strokeWidth={strokeWidth}
          fill="transparent"
          r={normalizedRadius}
          cx={50}
          cy={50}
        />
        <circle
          className="progress-circle-path"
          stroke="teal"
          strokeWidth={strokeWidth}
          fill="transparent"
          r={normalizedRadius}
          cx={50}
          cy={50}
          style={{
            strokeDasharray: circumference,
            strokeDashoffset: "100%",
            animation: "fillAnimation 1s linear forwards",
          }}
          ref={circlePathRef}
        />
        <text
          className="progress-circle-text"
          style={textStyle}
          x="50%"
          y="50%"
          textAnchor="middle"
          dy=".4em"
        >
          {`${value}%`}
        </text>
      </svg>
    </div>
  );
};

export default ProgressCircle;
