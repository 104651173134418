import React, { useEffect, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import Select from "react-select";
import { connect, useDispatch } from "react-redux";
import {
  DropListAction,
  DropListWhiteAction,
} from "../../../../../../../action";
import { customStyles, labelStyle } from "../../../../../../customStyleInline";
import BitonisSingleDropdown from "../../../../../../widgets/BitonisSingleDropdown";
import CustomDesign from "../../../../../../widgets/CustomDesign";
import SelectCompanyToLocation from "../../../../../../widgets/components/common/SelectCompanyToLocation";

const BlueCompanyLocation = (props) => {
  const {
    register,

    handleSubmit,
    control,
    watch,
    reset,
    setValue,
    errors,
    submitNewVersion,
    getValues,
  } = props;

  {
    /*
      useEffect(() => {
      if (!props.companyList.success) {
        props.dispatch(DropListAction.companyList());
      }
      if (!props.companyLocation.success) {
        props.dispatch(DropListAction.companyLocation());
      }
      props.dispatch(DropListAction.searchCompanyList());
    }, []);
    */
  }

  return (
    <div style={{ margin: "0px", padding: "0px" }}>
      <div
        className="card"
        style={{
          boxShadow: "none",
          margin: "0px",
          padding: "0px",
        }}
      >
        <div className="basic-form">
          <form onSubmit={handleSubmit(submitNewVersion)}>
            <SelectCompanyToLocation
              props={props}
              register={register}
              watch={watch}
              control={control}
              setValue={setValue}
              errors={errors}
              getValues={getValues}
              companyLabel={"Eleman Aradığınız Firma"}
              companyLocationLabel={"İş Görüşme Lokasyonu"}
              companyErrorMessage={"Eleman Aradığınız Firma Boş geçilemez"}
              companyLocationErrorMessage={"İş Görüşme Lokasyonu Boş Geçilemez"}
              companyPlaceholder={"Eleman Aradığınız Firma"}
              companyLocationPlaceholder={"İş Görüşme Lokasyonu"}
      
            />

            {/*

             <CustomDesign
              label={"Eleman Aradığınız Firma"}
              required={true}
              isColumn={true}
              child={
                <BitonisSingleDropdown
                  items={
                    props.searchCompanyList.success &&
                    props.searchCompanyList.searchCompanyList.map((value) => {
                      return {
                        label: value.name,
                        value: value.id,
                      };
                    })
                  }
                  register={register}
                  watch={watch}
                  companyClassName={"companyId"}
                  control={control}
                  setValue={setValue}
                  errors={errors}
                  errorMessage={"Eleman Aradığınız Firma Boş geçilemez"}
                  helperText=""
                  placeholder="Eleman Aradığınız Firma"
                  onValueChanged={(val) => {
                    console.log(val);
                    if (val) {
                      const id = watch("companyLocationId");
                      if (id == undefined) {
                      } else {
                        setValue("companyLocationId", "");
                      }
                      props.dispatch(
                        DropListAction.companyItemLocation(val.value)
                      );
                    }
                  }}
                  required={true}
                  getValues={getValues}
                />
              }
            />

            {props.companyItemLocation.success && (
              <CustomDesign
                label={"İş Görüşme Lokasyonu"}
                required={true}
                isColumn={true}
                child={
                  <BitonisSingleDropdown
                    items={
                      props.companyItemLocation.success &&
                      props.companyItemLocation.companyItemLocation.map(
                        ({ id, name }) => ({
                          value: id,
                          label: name,
                        })
                      )
                    }
                    register={register}
                    watch={watch}
                    companyClassName={"companyLocationId"}
                    control={control}
                    setValue={setValue}
                    errors={errors}
                    errorMessage={"İş Görüşme Lokasyonu Boş Geçilemez"}
                    helperText=""
                    placeholder="İş Görüşme Lokasyonu Seçin"
                    onValueChanged={(e) => {
                      console.log(e);
                      if (e) {
                      }
                    }}
                    required={true}
                    getValues={getValues}
                  />
                }
              />
            )}
          
          */}
          </form>
        </div>
      </div>
    </div>
  );
};
function mapState(state) {
  let authentication = state.authentication;
  let labourer = state.labourer;
  let labourerMatch = state.labourerMatch;
  let demands = state.demands;
  let job = state.job;
  let yearOfExperience = state.yearOfExperience;
  let positionList = state.positionList;
  let wayOfWorking = state.wayOfWorking;
  let nationalityList = state.nationalityList;
  let ageRangeList = state.ageRangeList;
  let driverLicenceType = state.driverLicenceType;
  let programList = state.programList;
  let certificatesList = state.certificatesList;
  let competencesList = state.competencesList;
  let countryList = state.countryList;
  let cityList = state.cityList;
  let countyList = state.countyList;
  let districtList = state.districtList;
  let companyList = state.companyList;
  let companyLocation = state.companyLocation;

  let companyItemLocation = state.companyItemLocation;

  //alert("companyItemLocation * * * * * "+JSON.stringify(companyItemLocation))
  // Beyaz Yaka
  let yearOfExperienceWhite = state.yearOfExperienceWhite;
  let positionListWhite = state.positionListWhite;
  let wayOfWorkingWhite = state.wayOfWorkingWhite;
  let nationalityListWhite = state.nationalityListWhite;
  let ageRangeListWhite = state.ageRangeListWhite;
  let driverLicenceTypeWhite = state.driverLicenceTypeWhite;
  let programListWhite = state.programListWhite;
  let certificatesListWhite = state.certificatesListWhite;
  let competencesListWhite = state.competencesListWhite;
  let countryListWhite = state.countryListWhite;
  let cityListWhite = state.cityListWhite;
  let countyListWhite = state.countyListWhite;
  let districtListWhite = state.districtListWhite;
  let companyListWhite = state.companyListWhite;
  let salaryListWhite = state.salaryListWhite;
  let businessTravelListWhite = state.businessTravelListWhite;
  let languageListWhite = state.languageListWhite;
  let languageLevelListWhite = state.languageLevelListWhite;
  let universityListWhite = state.universityListWhite;
  let facultyListWhite = state.facultyListWhite;
  let facultyDepartmentListWhite = state.facultyDepartmentListWhite;
  let searchCompanyList = state.searchCompanyList;

  return {
    authentication,
    labourer,
    labourerMatch,
    demands,
    job,
    yearOfExperience,
    positionList,
    wayOfWorking,
    nationalityList,
    ageRangeList,
    driverLicenceType,
    programList,
    certificatesList,
    competencesList,
    countryList,
    cityList,
    countyList,
    districtList,
    companyList,
    companyLocation,
    yearOfExperienceWhite,
    positionListWhite,
    wayOfWorkingWhite,
    nationalityListWhite,
    ageRangeListWhite,
    driverLicenceTypeWhite,
    programListWhite,
    certificatesListWhite,
    competencesListWhite,
    countryListWhite,
    cityListWhite,
    countyListWhite,
    districtListWhite,
    companyListWhite,
    salaryListWhite,
    businessTravelListWhite,
    languageListWhite,
    languageLevelListWhite,
    universityListWhite,
    facultyListWhite,
    facultyDepartmentListWhite,
    companyItemLocation,
    searchCompanyList,
  };
}

export default connect(mapState)(BlueCompanyLocation);
