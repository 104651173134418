import React, { useEffect, useRef, useState } from "react";
import Modal from "react-bootstrap/Modal"; // Varsayılan olarak React Bootstrap Modal bileşeni kullanılıyor
import Select from "react-select";
import { Alert, Button } from "react-bootstrap";
import api from "../../../../../../utility/Utils.js";
import { DropListAction } from "../../../../../../action/DropListAction.js";
import { DropListServices } from "../../../../../../services/DropListServices.js";
import { Controller, useForm } from "react-hook-form";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  customStyles,
  customStylesMulti,
  labelStyle,
} from "../../../../../customStyleInline.js";
import BitonisMultiDropdown from "../../../../../widgets/BitonisMultiDropdown.jsx";
import BitonisSingleDropdown from "../../../../../widgets/BitonisSingleDropdown.jsx";
import CustomDesign from "../../../../../widgets/CustomDesign.jsx";
import { Box } from "@mui/material";
import LoadingDotsAnimation from "../../../../../widgets/LoadingDotsAnimation.jsx";
import CustomButton from "../../../../../widgets/buttons/CustomButton.jsx";
import SelectMultiRoute from "../../../../../widgets/components/common/SelectMultiRoute.jsx";
import PageInfoTitle from "../../../../../widgets/PageInfoTitle.jsx";

function DetailEditRouteFindJobSeeker({
  show,
  handleClose,
  currentIndex,
  props,
  companyPreferredLocationId,
  preferredLocationLineId,
  selectedRouteList,
  setSelectedRouteList,
  companyId,
  backState,
  getRouteList,
  setGetRouteList,
  indexSelectGetRoutes,
}) {
  const {
    register,
    handleSubmit,
    control,
    watch,
    reset,
    setValue,
    getValues,
    formState: { errors },
  } = useForm();

  const [lat, setLat] = useState("");
  const [lng, setLng] = useState("");
  const [selectCompanyList, setSelectCompanyList] = useState([]);
  const [semtNewList, setSemtNewList] = useState([]);
  const [showCountiesAndDistricts, setShowCountiesAndDistricts] =
    useState(true);

  const [cityList, setCityList] = useState([]);
  useEffect(() => {
    if (show) {
      setShowCountiesAndDistricts(true);
    }
  }, [show]);
  useEffect(() => {
    if (show) {
      if (!props.countryList.success) {
        props.dispatch(DropListAction.countryList());
      }
      DropListServices.allCityList({
        countryId: 1,
      }).then(
        (cityData) => {
          setCityList([]);
          if (cityData && cityData.length > 0) {
            cityData.map((value) => setCityList((prev) => [...prev, value]));
          }
        },
        (error) => { }
      );
    }
  }, [show]);
  const [deleteModel, setDeleteModel] = useState(null);
  const [detail, setDetail] = useState(null);
  const countyUseRef = useRef([]);
  useEffect(() => {
    setDetail(null);
    if (companyPreferredLocationId) {
      if (show) {
        const updatedList = [...selectedRouteList];
        // setSelectedRouteList([]);
        api
          .get("company/preferredlocation/" + companyPreferredLocationId)
          .then((response) => {
            // alert("response. alert "+JSON.stringify(response))

            if (response.succeeded == true) {
              var data = response.data;
              setDetail(data)
              var model = {
                companyPreferredLocationId: data.companyPreferredLocationId,
                servisRouteName: data.name,
                countryId: {
                  value: data.details[0].countryId,
                  label: data.details[0].countryName,
                },
                cityId: {
                  value: data.details[0].cityId,
                  label: data.details[0].cityName,
                },
                countyId:
                  data.details[0].counties &&
                  data.details[0].counties.map((value) => {
                    return {
                      label: value.name,
                      value: value.id,
                    };
                  }),
                districts:
                  data.details[0].districts &&
                  data.details[0].districts.map((value) => {
                    return {
                      label: value.name,
                      value: value.id,
                    };
                  }),
              };
              setDeleteModel(model);
              setValue("servisRouteName", data.name);

              setValue("countryId", {
                value: data.details[0].countryId,
                label: data.details[0].countryName,
              });
              setValue("cityId", {
                value: data.details[0].cityId,
                label: data.details[0].cityName,
              });
              handleCity({
                value: data.details[0].cityId,
              });

              var countiesList = [];
              var districtList = [];
              data.details[0].counties &&
                data.details[0].counties.map((value) => {
                  countiesList.push({
                    label: value.name,
                    value: value.id,
                  });
                });
              if (countiesList) {
                handleCounty(countiesList);
                setValue("countyId", countiesList);

                console.log("county içi doldu");
              }
              data.details[0].districts &&
                data.details[0].districts.map((value) => {
                  districtList.push({
                    label: value.name,
                    value: value.id,
                  });
                });
              if (districtList) {
                setValue("districts", districtList);
                if (
                  data.details[0].countryName &&
                  data.details[0].cityName &&
                  data.details[0].counties[0] &&
                  data.details[0].districts[0]
                ) {
                  var adres =
                    data.details[0].countryName +
                    "/" +
                    data.details[0].cityName +
                    "/" +
                    data.details[0].counties[0]["name"] +
                    data.details[0].districts[0]["name"];
                  getLatLngApi(adres);
                }
              }
            } else {
              handleClose();
            }
          })
          .catch((err) => {
            handleClose();
          });
      }
    }
  }, [show, companyPreferredLocationId]);

  useEffect(() => {
    if (!props.countryList.success) {
      props.dispatch(DropListAction.countryList());
    }
    if (!props.cityList.success) {
      props.dispatch(
        DropListAction.cityList({
          countryId: 1,
        })
      );
    }
  }, []);
  const handleCity = (selected) => {
    if (selected.value.toString() == "83") {
      setShowCountiesAndDistricts(false);
    } else {
      setShowCountiesAndDistricts(true);
    }
    props.dispatch(DropListAction.allCountyList(selected.value));
  };

  async function getLatLngApi(adress) {
    DropListServices.handleGetLocation(adress).then((location) => {
      // alert("lat " + location.lat + " " + "lng " + location.lng);
      if(location){
        var latitute = location.lat;
        var longtitute = location.lng;
  
        setLat(latitute);
        setLng(longtitute);
      }
   
    });
  }

  const handleCounty = (selected) => {
    setSemtNewList([]);
    if (selected) {
      semtFGetApi(selected);
    }
  };
  function semtFGetApi(selected) {
    for (var i = 0; i < selected.length; i++) {
      DropListServices.districtList(selected[i].value).then(
        (districtList) => {
          if (
            districtList == null ||
            districtList == undefined ||
            districtList == []
          ) {
          } else {
            var semtListPre = [];
            semtListPre = districtList;

            // alert("semtListPre * * * " + JSON.stringify(semtListPre));

            for (var fetSemt in semtListPre) {
              // Aynı ID'ye sahip semt zaten listeye eklenmişse, eklemeyi atla
              if (semtNewList.some((semt) => semt.id == fetSemt.value)) {
                // print("aynı id ye sahip var* * ** * * ");
                continue;
              }
              // Aynı ID'ye sahip  listeye eklenmemişse, ekle
              setSemtNewList((prevList) => [...prevList, semtListPre[fetSemt]]);
            }
          }
        },
        (error) => {
          setSemtNewList([]);
        }
      );
    }
  }

  function editRoute(form) {
    var sendData = {
      companyPreferredLocationId: companyPreferredLocationId,
      name: form["servisRouteName"],
      companyPreferredLocationLineId: preferredLocationLineId,
      companyId: companyId,
      companyPrefLocationReq: {
        countryId: form["countryId"]["value"],
        cityId: form["cityId"]["value"],
        counties:
          form["countyId"] == "" || form["countyId"] == []
            ? []
            : form["countyId"].map((count) =>
              parseInt(count["value"].toString())
            ),
        districts:
          form["districts"] == "" || form["districts"] == undefined || form["districts"] == []
            ? []
            : form["districts"].map((count) =>
              parseInt(count["value"].toString())
            ),
        latitude: lat.toString(),
        longitude: lng.toString(),
      },
    };

    api.put("company/preferredlocation", sendData).then((responseUpdate) => {
      if (responseUpdate.succeeded == true) {
        setGetRouteList([]);

        api
          .get("/company/preferredlocationlist/" + companyId)
          .then((response) => {
            if (response.succeeded == true) {
              setGetRouteList([]);
              var data = response.data;
              var k = [...getRouteList];
              if (data) {
                data.map((value) =>
                  setGetRouteList((prev) => [...prev, value])
                );
                if (data.length > 0) {
                  if (currentIndex == -1) {
                  } else {
                    const updatedItem = {
                      ...selectedRouteList[currentIndex],
                    };
                    updatedItem.name = form["servisRouteName"];
                    updatedItem.companyPreferredLocationId =
                      responseUpdate.data.preferredLocationId;
                    updatedItem.preferredLocationLineId =
                      responseUpdate.data.preferredLocationLineId;
                    const updatedList = [...selectedRouteList];
                    updatedList[currentIndex] = updatedItem;
                    setSelectedRouteList([]);
                    setSelectedRouteList(updatedList);
                  }
                  handleClose(true);
                } else {
                }
              } else {
              }
              toast.success(responseUpdate.result.message, {
                autoClose: 2000,
                onClose: () => {
                  setTimeout(() => {
                    //  window.location.href = window.location.href;
                    backState(true);
                  }, 100);
                },
              });
            } else {
              setGetRouteList([]);
            }
          })
          .catch((err) => {
            setGetRouteList([]);
          });
      } else {
        handleClose(true);
        toast.info(responseUpdate.error.message, {
          autoClose: 2000,
          onClose: () => {
            setTimeout(() => {
              //  window.location.href = window.location.href;
            }, 100);
          },
        });
      }
    });
  }
  function deleteAddedLocationRoutes() {
    var sendData = {
      companyPreferredLocationId: deleteModel.companyPreferredLocationId,
      companyPreferredLocationLineId: preferredLocationLineId,
      name: deleteModel.name,
      companyId: companyId,
      companyPrefLocationReq: {
        countryId: deleteModel.countryId.value,
        cityId: deleteModel.cityId.value,
        counties:
          deleteModel.countyId == []
            ? []
            : deleteModel.countyId.map((val) => val.value),
        districts:
          deleteModel.districts == []
            ? []
            : deleteModel.districts.map((val) => val.value),
        latitude: deleteModel.latitude ?? "",
        longitude: deleteModel.longitude ?? "",
      },
    };
    api
      .delete("company/preferredlocation", { data: sendData })
      .then((response) => {
        if (response.succeeded == true) {
          if (selectedRouteList.length > 0) {
            if (
              selectedRouteList.some(
                (selectedItem) =>
                  selectedItem.companyPreferredLocationId ===
                  deleteModel.companyPreferredLocationId
              )
            ) {
              setSelectedRouteList((prev) =>
                prev.filter(
                  (selectedItem) =>
                    selectedItem.companyPreferredLocationId !==
                    deleteModel.companyPreferredLocationId
                )
              );
            }
          }
          handleClose(true);
          toast.success(response.result.message, {
            autoClose: 2000,
            onClose: () => {
              setTimeout(() => {
                //  window.location.href = window.location.href;
                backState(true);
              }, 100);
            },
          });
        } else {
          handleClose(true);
          backState(false);
          toast.info(response.error.message, {
            autoClose: 2000,
          });
        }
      })
      .catch((err) => {
        backState(false);
        toast.info("Hata Oluştu", {
          autoClose: 2000,
        });
      });
  }

  return (
    <div>
      {
        detail === null && show == true ?
          <Box sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignContent: "center",
            alignItems: "center"

          }} >
            <LoadingDotsAnimation />
          </Box>
          : <div>
            <Modal
              className="fade bd-example-modal-lg"
              size="lg"
              show={show}
              onHide={handleClose}
              backdrop="static"
              keyboard={false}
              aria-labelledby="contained-modal-title-vcenter"
              centered
            >
              <Modal.Header
                closeButton
                style={{
                  borderBottom: "none",
                  marginBottom: "0px",
                  paddingBottom: "0px",
                  display: "flex",
                  justifyContent: "center",
                  alignContent: "center"
                }}
              >
                <PageInfoTitle title={"Güzergah Düzenle"} bottomPadding={"0px"} />

              </Modal.Header>
              <Modal.Body className="grid-example" style={{
                marginTop: "0px",
                paddingTop: "0px"
              }}>
                <form onSubmit={handleSubmit(editRoute)}>
                  <div className="row">
                    <CustomDesign
                      label={"Servis Güzergah Başlığı"}
                      required={true}
                      isColumn={true}
                      columnClassName={"col-xs-12 col-md-12"}
                      child={
                        <>
                          {" "}
                          <input
                            type="text"
                            className="form-control"
                            id="inputClass"
                            rules={{ required: true }}
                            name="servisRouteName"
                            {...register("servisRouteName", { required: true })}
                            placeholder="Servis Güzergah Adı"
                          ></input>
                          {errors.servisRouteName && (
                            <div className="text-danger fs-12">
                              Bu alan Boş Olamaz
                            </div>
                          )}
                        </>
                      }
                    />
                  </div>

                  <SelectMultiRoute
                    props={props}
                    register={register}
                    watch={watch}
                    control={control}
                    setValue={setValue}
                    errors={errors}
                    getValues={getValues}
                    getLatLngApi={getLatLngApi}
                    preview={false}
                    setSemtNewList={setSemtNewList}
                    semtNewList={semtNewList}
                    handleCity={handleCity}
                    handleCounty={handleCounty}
                    showCountiesAndDistricts={showCountiesAndDistricts}
                    setShowCountiesAndDistricts={setShowCountiesAndDistricts}
                    cityList={cityList}
                    setCityList={setCityList}
                  />


                </form>
              </Modal.Body>
              <Modal.Footer style={{
                border: "none", display: "flex",
                justifyContent: "center",
                alignContent: "center",
                alignItems: "center",
              }}>
                <Box
                  sx={{
                    paddingBottom: {
                      xs: "5px",
                      sm: "5px",
                      md: "0px",
                      lg: "0px",
                      xl: "0px",
                    },
                    display: "flex",
                    justifyContent: "center",
                    alignContent: "center",
                    alignItems: "center",


                    flexDirection: {
                      xs: "column",
                      sm: "column",
                      md: "row",
                      lg: "row",
                      xl: "row",
                    },
                  }}
                >
                  <Box
                    sx={{
                      margin: "0px",
                      padding: {
                        xs: "5px",
                        sm: "5px",
                        md: "5px",
                        lg: "5px",
                        xl: "5px",
                      }, width: "100%",
                    }}
                  >
                    <CustomButton
                      title={"Sil"}
                      onTap={deleteAddedLocationRoutes}
                      color={"white"}
                      backgroundColor={"red"}
                      hoverBackgroundColor={"rgb(231, 32, 32)"}
                      border={"none"}
                      width={{
                        xs: "400px",
                        sm: "350px",
                        md: "230px",
                        lg: "350px",
                        xl: "350px",
                      }}
                    />{" "}
                  </Box>
                  <form onSubmit={handleSubmit(editRoute)}>

                    <Box
                      sx={{
                        margin: "0px",
                        padding: {
                          xs: "5px",
                          sm: "5px",
                          md: "5px",
                          lg: "5px",
                          xl: "5px",
                        }, width: "100%",
                      }}
                    >
                      <CustomButton
                        onTap={handleSubmit(editRoute)}
                        title={"Kaydet"}
                        width={{
                          xs: "400px",
                          sm: "350px",
                          md: "230px",
                          lg: "350px",
                          xl: "350px",
                        }}
                      />
                    </Box>
                  </form>
                </Box>



              </Modal.Footer>
            </Modal>
          </div>}
    </div>
  );
}

export default DetailEditRouteFindJobSeeker;
