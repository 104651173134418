const signalR = require("@microsoft/signalr");
import { API_URL } from "../../../../services/ApiUrlServices";

class SignalService {
  constructor() {
    this.hubConnection = null;
  }

  startConnection() {
    const url = `${API_URL}/pay-hub`;
    this.hubConnection = new signalR.HubConnectionBuilder()
      .withUrl(url)
      .withAutomaticReconnect()
      .configureLogging("information") // Ekstra loglama için
      .build();

    // Şimdi hubConnection'ı kullanabilirsiniz
    // Örneğin, bağlantıyı başlatmak için:
    this.hubConnection
      .start()
      .then(() => {
        console.log("SignalR connection started successfully.");
     
        // Bağlantı başlatıldıktan sonra yapılacak işlemler buraya gelebilir
      })
      .catch((err) => {
    
        console.log("Error starting SignalR connection:", err);
      });
  }
  paymentResult(updateStatus) {
      if (this.hubConnection) {
        this.hubConnection.on("Receive", (paymentData) => {
          console.log("Ödeme tamamlandı:", paymentData);
          updateStatus(paymentData);
        });
      }
  }
  registerTransactionId(id) {
    // Hub'a işlem kimliğini (transaction id) gönderme işlemi
      if (this.hubConnection) {
        this.hubConnection
          .invoke("RegisterTransaction", id)
          .then(() => {
            console.log(`Transaction ID ${id} registered successfully.`);
          })
          .catch((err) => {
            console.error(`Error registering transaction ID ${id}:`, err);
          });
      }
  }
  stopConnection() {
    if (this.hubConnection) {
      this.hubConnection
        .stop()
        .then(() => {
          console.log("SignalR connection stopped successfully.");
        })
        .catch((err) => console.log("Error stopping SignalR connection:", err));
    }
  }
}

export default  SignalService;
