import { DashboardConstants, DashboardMatchConstants, LastDashboardConstants } from '../constants';

const defaultState= { loading: false, labourer: false, error: false, success: false};

function setState(changes) {
    return { ...defaultState, ...changes };
}
export function labourer(state = defaultState, action) {
    switch (action.type) {
        case DashboardConstants.GET_ALL_REQUEST:
            return setState({loading: true});
        case DashboardConstants.GET_ALL_SUCCESS:
            return setState({labourer: action.labourer, success: true });
        case DashboardConstants.GET_ALL_FAILURE:
            return setState({ error: action.labourer });
        
        case DashboardConstants.GET_CLEAR:
            return defaultState;
        default:
            return state
    }
}

const defaultStateMatch= { loading: false, labourerMatch: false, error: false, success: false};

export function labourerMatch(state = defaultStateMatch, action) {
    switch (action.type) {
        case DashboardMatchConstants.GET_ALL_REQUEST:
            return setState({loading: true, success: false});
        case DashboardMatchConstants.GET_ALL_SUCCESS:
            return setState({labourerMatch: action.labourerMatch, success: true });
        case DashboardMatchConstants.GET_ALL_FAILURE:
            return setState({ error: action.labourerMatch });
        case DashboardMatchConstants.GET_CLEAR:
            return defaultStateMatch;
        default:
            return state
    }
}
const defaultLastStateMatch = { loading: false, lastLabourer: false, error: false, success: false };

export function lastLabourer(state = defaultLastStateMatch, action) {
    switch (action.type) {
        case LastDashboardConstants.GET_ALL_REQUEST:
            return setState({ loading: true, success: false });
        case LastDashboardConstants.GET_ALL_SUCCESS:
            return setState({ lastLabourer: action.lastLabourer, success: true });
        case LastDashboardConstants.GET_ALL_FAILURE:
            return setState({ error: action.lastLabourer });
        case LastDashboardConstants.GET_CLEAR:
            return defaultStateMatch;
        default:
            return state
    }
}