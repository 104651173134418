import api from "../utility/Utils";
import { setToken, removeToken, setUser } from "../configs/cookie";
import cogoToast from "cogo-toast";

export const UserService = {
  login,
  activation,
  own,
  get,
  lostPassword,
  resetPassword,
  logout,
  updateProfile,
  updateEmail,
  updatePassword,
  updatePermissions,
  register,
  registerDetails,
  loginLastVersion,
  registerLastVersion,
  loginLoginPageVersion,
};

function own(response) {
  setUser(response.data);
  return Promise.resolve(response.data);
}

function login(user_mail, password) {
  let data = { userName: user_mail, password: password };
  return api
    .post("account/login", data)
    .then((response) => {
      if (response.error != null) {
        cogoToast.error(response.error.message);
        return Promise.reject(false);
      } else {
        if (response.data.isEmployer) {
          //console.log(response)
          setToken(response.data.accessToken);
          own(response);
          //window.location.reload();
         
          return Promise.resolve(response);
        } else {
          cogoToast.error("Sadece Şirketler Kullanabilir!");
        }
      }
    })
    .catch((err) => {
      return Promise.reject(err);
    });
}
function loginLastVersion(user_mail, password) {
  let data = { userName: user_mail, password: password };
  return api
    .post("account/login", data)
    .then((response) => {

      if (response) {
        if (response.succeeded === true) {
          if (response.data.isEmployer) {
            //console.log(response)
            setToken(response.data.accessToken);
            own(response);
            //window.location.reload();
            return Promise.resolve(response);
          } else {
            cogoToast.error("Sadece Şirketler Kullanabilir!");
          }
        } else {
          return Promise.resolve(response);
        }
      } else {
         return Promise.resolve(response);
      }
    })
    .catch((err) => {
      return Promise.reject(err);
    });
}
function loginLoginPageVersion(user_mail, password) {
  let data = { userName: user_mail, password: password };
  return api
    .post("account/login", data)
    .then((response) => {
      if (response) {
        if (response.succeeded === true) {
          if (response.data.isEmployer) {
            //console.log(response)
            setToken(response.data.accessToken);
            own(response);
            //window.location.reload();
            return Promise.resolve(response);
          } else {
            cogoToast.error("Sadece Şirketler Kullanabilir!");
          }
        } else {
          alert("response *** " + JSON.stringify(response));
          return Promise.resolve(response);
        }
      }
    })
    .catch((err) => {
      return Promise.reject(err);
    });
}
function activation(code) {
  return api
    .get("user/activation/" + code)
    .then((response) => {
      let data = { refresh: response.token.refresh };
      return api
        .post("token/refresh", data)
        .then((response) => {
          setToken(response.access);
          return Promise.resolve(response.access);
        })
        .catch((err) => {
          return Promise.reject(err);
        });
    })
    .catch((err) => {
      return Promise.reject(err);
    });
}

function get(user, type) {
  return api
    .get("user/" + type + "/" + user)
    .then((response) => {
      return Promise.resolve(response);
    })
    .catch((err) => {
      return Promise.reject(err);
    });
}

function logout() {
  return removeToken();
}
//rabia.isveren@bitonis.com
function register(user, history) {
  return api
    .post("/account/employer/register", user)
    .then((response) => {
      if (response && response.succeeded === true) {
        //alert(JSON.stringify(response));

        cogoToast.success(
          "Doğrulama Kodu E-mail adresinize başarılı bir şekilde gönderilmiştir"
        );
        history.push("/");
        return Promise.resolve(response);
      } else {
        //  alert(JSON.stringify(response.error));
        if (response.error.code == 103) {
          cogoToast.error(response.error.message);
          history.push("/");
          return Promise.reject(false);
        } else if (response.error.code == 203) {
          cogoToast.error(response.error.message);
          history.push("/");
          return Promise.reject(false);
        } else if (response.error.code == 114) {
          cogoToast.error(response.error.message);
          history.push("/");
          return Promise.reject(false);
        } else {
          cogoToast.error(response.error.message);
          history.push("/");
          return Promise.reject(false);
        }
        // Handle the case where 'response' is undefined or 'succeeded' is false
      }
      /*
     var succeeded = response.succeeded;
      if (succeeded == true) {
     //   alert(JSON.stringify(response));
      }
      if (response === 'alreadyhavemail') {
        cogoToast.error('Mail ile daha önce kayıt olunmuş');
        return Promise.reject(false);
      } else if (response === 'alreadyhaveusername') {
        cogoToast.error('Kullanıcı adı ile daha önce kayıt olunmuş');
        return Promise.reject(false);
      } else if (response === 'mailerror') {
        cogoToast.error('Mail adresi yada şifre geçersiz!');
        return Promise.reject(false);
      } else {
        console.log(response)
        cogoToast.success('Kayıt Başarılı, gelen maili kontrol ediniz');
        history.push("/");
        return Promise.resolve(response);
      }*/
    })
    .catch((err) => {
      return Promise.reject(err);
    });
}
function registerLastVersion(user, history) {
  return api
    .post("/account/employer/register", user)
    .then((response) => {
      if (response && response.succeeded === true) {
        return Promise.resolve(response);
      } else {
        return Promise.resolve(response);
      }
    })
    .catch((err) => {
      return Promise.reject(err);
    });
}

function registerDetails(user, history) {
  let data = { userName: user.email, password: user.password };
  // console.log(user)

  return api
    .post("account/login", data)
    .then((response) => {
      if (response) {
        if (response.succeeded === true) {
          if (response.data.isEmployer) {
            setToken(response.data.accessToken);
            own(response);
            
            return api
              .post("/company/create", user)
              .then((responseCompany) => {
               
               // cogoToast.success("Şirket bilgileriniz başarıyla eklenmiştir.");
               
                return Promise.resolve(responseCompany);
              })
              .catch((err) => {
                cogoToast.error("Hata Oluştu " + err.toString());
                return Promise.reject(false);
              });
          } else {
            cogoToast.error("Sadece Şirketler Kullanabilir!");
            return Promise.reject(false);
          }
        } else {
          cogoToast.error(response.error.message);
          return Promise.reject(false);
        }
      } else {
        return Promise.reject(false);
      }
    })
    .catch((err) => {
      return Promise.reject(false);
    });
}

function lostPassword(email) {
  return api
    .post("/account/employer/forgotpassword", { email: email })
    .then((response) => {
      return Promise.resolve(response);
    })
    .catch((err) => {
      return Promise.reject(err);
    });
}

function resetPassword(password, re_password, code) {
  return api
    .post("resetpassword", {
      password: password,
      re_password: re_password,
      code: code,
    })
    .then((response) => {
      return Promise.resolve(response);
    })
    .catch((err) => {
      return Promise.reject(err);
    });
}

function updateProfile(arg) {
  return api
    .patch("user/update/detail", arg)
    .then((response) => {
      setUser(response);
      return Promise.resolve(response);
    })
    .catch((err) => {
      return Promise.reject(err);
    });
}

function updateEmail(arg) {
  return api
    .patch("user/update/account", arg)
    .then((response) => {
      return Promise.resolve(response);
    })
    .catch((err) => {
      return Promise.reject(err);
    });
}

function updatePassword(arg) {
  return api
    .patch("user/update/account", arg)
    .then((response) => {
      return Promise.resolve(response);
    })
    .catch((err) => {
      return Promise.reject(err);
    });
}

function updatePermissions(arg) {
  return api
    .patch("user/update/permissions", arg)
    .then((response) => {
      return Promise.resolve(response);
    })
    .catch((err) => {
      return Promise.reject(err);
    });
}
