import api from "../../../../../../../../../utility/Utils";

export default function facultyDepartmentListGets(universyId, setSectionList) {
  setSectionList([]);
  //üniversite id ==233 değil ise bu api çağrılır.
  let id = {
    universityId: universyId.toString(),
  };
  api
    .post("/common/UniversityFacultyDepartmentList", id)
    .then((response) => {
      //  alert("gelen bölümler " + JSON.stringify(response));
      setSectionList(response.data);
    })
    .catch((err) => {
      setSectionList([]);
    });
}
