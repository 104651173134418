import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  activeCurrentTab: 0,
};
const demandVideoCvTab = createSlice({
  name: "demandVideoCvTab",
  initialState,
  reducers: {
    setCurrentDemandVideoCvTab: (state, action) => {
      state.activeCurrentTab = action.payload;
    },
  },
});

export const { setCurrentDemandVideoCvTab } = demandVideoCvTab.actions;
export default demandVideoCvTab.reducer;
