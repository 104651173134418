import { DropListServices } from "../../../../../../../../services";

export default function getMultiSemt(isSelect, selected, semtNewList, setSemtNewList) {
  if (selected) {
    for (var i = 0; i < selected.length; i++) {
      if (isSelect == true) {
        DropListServices.districtList(selected[i].id).then(
          (districtList) => {
            //    alert("districtList   " + JSON.stringify(districtList));
            if (
              districtList == null ||
              districtList == undefined ||
              districtList == []
            ) {
            } else {
              var semtListPre = [];
              semtListPre = districtList;
              setSemtNewList([]);
              for (var fetSemt in semtListPre) {
                // Aynı ID'ye sahip semt zaten listeye eklenmişse, eklemeyi atla
                if (semtNewList.some((semt) => semt.id == fetSemt.id)) {
                  // print("aynı id ye sahip var* * ** * * ");
                  continue;
                }
                // Aynı ID'ye sahip  listeye eklenmemişse, ekle
                setSemtNewList((prevList) => [
                  ...prevList,
                  semtListPre[fetSemt],
                ]);
              }
            }
          },
          (error) => {
            setSemtNewList([]);
          }
        );
      } else {
        DropListServices.districtList(selected[i].value).then(
          (districtList) => {
            //   alert("districtList   " + JSON.stringify(districtList));
            if (
              districtList == null ||
              districtList == undefined ||
              districtList == []
            ) {
            } else {
              var semtListPre = [];
              semtListPre = districtList;

              // alert("semtListPre * * * " + JSON.stringify(semtListPre));

              for (var fetSemt in semtListPre) {
                // Aynı ID'ye sahip semt zaten listeye eklenmişse, eklemeyi atla
                if (semtNewList.some((semt) => semt.id == fetSemt.id)) {
                  //   print("aynı id ye sahip var* * ** * * ");
                  continue;
                }
                // Aynı ID'ye sahip  listeye eklenmemişse, ekle
                setSemtNewList((prevList) => [
                  ...prevList,
                  semtListPre[fetSemt],
                ]);
              }
            }
          },
          (error) => {
            setSemtNewList([]);
          }
        );
      }
    }
  } else {
    setSemtNewList([]);
  }
}
