import React from "react";
import NewMatchItem from "./NewMatchItem";
import WaitingMatchItem from "./WaitingMatchItem";
import WaitingAtToWorkPlaceMeetingApprovalItem from "./WaitingAtToWorkPlaceMeetingApprovalItem";
import ConfirmAtToWorkPlaceMeetingApprovalItem from "./ConfirmAtToWorkPlaceMeetingApprovalItem";
import AdditionalWaitingVideoCvItem from "./AdditionalWaitingVideoCvItem";
import MeetingRejectedItem from "./MeetingRejectedItem";
import WaitingVideoConferenceMeetingDayItem from "./WaitingVideoConferenceMeetingDayItem";
import ConfirmVideoConferenceMeetingDayItem from "./ConfirmVideoConferenceMeetingDayItem";
import ConfirmIncomingMeetingDayItem from "./ConfirmIncomingMeetingDayItem";
import HireItem from "../../../Hire/HireItem";
import NotHireItem from "../../../NotHire/NotHireItem";
import ConfirmMeetingDayItem from "./confirmMeetingDayItem";
import NewMediumMatchItem from "./NewMediumMatchItem";

const ItemByMediumStatus = ({ matchData, comeCurrentDemand }) => {
  switch (matchData.status) {
    case 0:
      return <NewMediumMatchItem matchData={matchData} />;
    case 1:
      return <NewMediumMatchItem matchData={matchData} />;
   
    default:
      return <div></div>;
  }
};

export default ItemByMediumStatus;
