import React from 'react';
import { styled, keyframes } from '@mui/system';

const waveAnimation = (maxScale, startColor, endColor) => keyframes`
  0% {
    opacity: 1;
    transform: scale(1);
    background-color: ${startColor};
    border-color: ${startColor};
  }
  50% {
    opacity: 0.5;
    transform: scale(${maxScale * 0.75});
    background-color: ${endColor};
    border-color: ${endColor};
  }
  100% {
    opacity: 0;
    transform: scale(${maxScale});
    background-color: ${endColor};
    border-color: ${endColor};
  }
`;

const Wave = styled('div')(({ waveDuration, delay, maxScale, startColor, endColor }) => ({
  position: 'absolute',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  borderRadius: '50%',
  border: `2px solid ${startColor}`,
  animation: `${waveAnimation(maxScale, startColor, endColor)} ${waveDuration}s infinite`,
  animationDelay: `${delay}s`,
  backgroundColor: 'transparent',
  transformOrigin: 'center',
  
}));

const WaveWrapper = styled('div')({
  position: 'relative',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: 'white',  // Circle'ın arka planı beyaz
  borderRadius: '50%',
});

const BitonisAvatarGlow = ({
  children,
  waveCount = 3,
  waveDuration = 2,
  maxRadius = 120,
  initialRadius = 40, // Dalganın başladığı yarıçap
  startColor = 'rgba(255, 0, 0, 0.7)', // Dalganın başlangıç rengi
  endColor = 'rgba(0, 0, 255, 0.7)', // Dalganın bitiş rengi
  animate = true,
}) => {
  const maxScale = maxRadius / initialRadius;

  const waves = [];

  for (let i = 0; i < waveCount; i++) {
    waves.push(
      <Wave
        key={i}
        waveDuration={waveDuration}
        delay={(i * waveDuration) / waveCount}
        maxScale={maxScale}
        startColor={startColor}
        endColor={endColor}
      />
    );
  }

  return (
    <WaveWrapper style={{ width: initialRadius * 2, height: initialRadius * 2 }}>
      {animate && waves}
   <div style={{
    width:"40px",
    height:"40px",
    backgroundColor:"white"
   }}>
   {children} 
   </div>
    </WaveWrapper>
  );
};

export default BitonisAvatarGlow;
