import React, { useState } from "react";
import { DemandsServices } from "../../../../../../../../../services";
import { ToastContainer, toast } from "react-toastify";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { useDispatch } from "react-redux";
import { setCurrentTabNotHire } from "../../../../../../../../../redux/redux-toolkit/selectedNotHireTab";
import { setCurrentTabHire } from "../../../../../../../../../redux/redux-toolkit/selectedHireTab";
import ShowNotHireCandidateModal from "../matchDetailModals/showNotHireCandidateModal";
import ShowHireCandidateModal from "../matchDetailModals/showHireCandidateModal";
import ShowNotCallCandidateToInterviewModal from "../matchDetailModals/ShowNotCallCandidateToInterviewModal";
import { setCurrentTab } from "../../../../../../../../../redux/redux-toolkit/filterMatch";
import BlockButtons from "../../../MatchShow/Common/BlockButtons/BlockButtons";
import CustomButton from "../../../../../../../../widgets/buttons/CustomButton";
import { Box } from "@mui/material";

const BlueActionButtons = ({
  matchId,
  blueMatchDetail,
  companyId,
  employerId,
  status,
}) => {
  const history = useHistory();
  const dispatch = useDispatch();
  var contentActionBody;
  const [showHire, setShowHire] = useState(null);
  const [showNotHire, setShowNotHire] = useState(null);
  const [showCallToInterview, setShowCallToInterview] = useState(null);
  function showCallToInterviewClose() {
    setShowCallToInterview(false);
  }

  function handleHireClose() {
    setShowHire(false);
  }
  function handleNotHireClose() {
    setShowNotHire(false);
  }
  function blueEmployerMatchAcceptance(type, selectedList) {
    var sendDataList = [];
    if (type === 2) {
      blueMatchDetail.matchedJobList
        .filter((element) => element.matchId === selectedList[0].matchId)
        .forEach((e) => {
          var _createData = {
            id: selectedList[0].matchId,
            jobPosition: selectedList[0].jobPosition,
            status: 2,
          };
          sendDataList.push(_createData);
          //sadece seçilenler status 2 yapıldı seçilmeyenlerin statusleri 7 yapılıp apiye gönderildi
        });
      blueMatchDetail.matchedJobList
        .filter((element) => element.matchId !== selectedList[0].matchId)
        .forEach((e) => {
          var createDataSecond = {
            id: e.matchId,
            jobPosition: e.jobPosition,
            status: 7,
          };
          sendDataList.push(createDataSecond);
        });
    } else {
      selectedList.forEach((e) => {
        var createData = {
          id: e.matchId,
          jobPosition: e.jobPosition,
          status: 3,
        };
        sendDataList.push(createData);
      });
    }
    var sendBody = {
      matchedJobsWithStatus: sendDataList,
    };
    //  alert("sendBody* * * " + JSON.stringify(sendBody));
    DemandsServices.hireJob(sendBody).then(
      (hireJob) => {
        if (hireJob && hireJob.succeeded === true) {
          if (type == 2) {
            dispatch(setCurrentTabHire(0));
            toast.success("İlgili Pozisyon İçin İşe Adayı İşe Aldınız", {
              autoClose: 2000,
              onClose: () => {
                setTimeout(() => {
                  history.push("/hire");
                }, 100);
              },
            });
          } else {
            dispatch(setCurrentTabNotHire(0));
            toast.success("İlgili Pozisyon İçin Adayı İşe Almadınız", {
              autoClose: 2000,
              onClose: () => {
                setTimeout(() => {
                  history.push("/notHire");
                }, 100);
              },
            });
          }
        } else {
          toast.success("Hata Oluştu", {
            autoClose: 2000,
            onClose: () => {
              setTimeout(() => {}, 100);
            },
          });
        }
      },
      (error) => {}
    );
  }
  function callCandidateForInterview(positionName, matchId) {
    var sendBody = {
      matchedJobsWithStatus: {
        id: matchId,
        jobPosition: positionName,
        status: 9, //sabit gidecek
      },
      candidateId: blueMatchDetail && blueMatchDetail.candidateId,
    };

    DemandsServices.callCandidateForInterview(sendBody).then(
      (callToInterviewResponse) => {
        if (
          callToInterviewResponse &&
          callToInterviewResponse.succeeded === true
        ) {
          dispatch(setCurrentTab(0));
          toast.success("İşleminiz başarılı şekilde gerçekleşti", {
            autoClose: 2000,
            onClose: () => {
              setTimeout(() => {
                history.push("/matchShow");
              }, 100);
            },
          });
        } else {
          toast.success("Hata Oluştu", {
            autoClose: 2000,
            onClose: () => {
              setTimeout(() => {}, 100);
            },
          });
        }
      },
      (error) => {}
    );
  }

  if (status == 1) {
    contentActionBody = (
      <>
        <CallForInterviewButton
          onTap={() => {
            setShowCallToInterview(true);
          }}
        />
        {blueMatchDetail && blueMatchDetail.matchRestrictionStatus === 1 ? (
          <div></div>
        ) : (
          <HireButton
            onTap={() => {
              setShowHire(true);
            }}
          />
        )}
        {blueMatchDetail && blueMatchDetail.matchRestrictionStatus === 1 ? (
          <div></div>
        ) : (
          <NotHireButton
            onTap={() => {
              setShowNotHire(true);
            }}
          />
        )}
      </>
    );
  } else if (status == 2) {
    contentActionBody = (
      <>
        <CallForInterviewButton
          onTap={() => {
            setShowCallToInterview(true);
          }}
        />
        {blueMatchDetail && blueMatchDetail.matchRestrictionStatus === 1 ? (
          <div></div>
        ) : (
          <NotHireButton
            onTap={() => {
              setShowNotHire(true);
            }}
          />
        )}
      </>
    );
  } else if (status == 3) {
    contentActionBody = (
      <>
        <CallForInterviewButton
          onTap={() => {
            setShowCallToInterview(true);
          }}
        />
        {blueMatchDetail && blueMatchDetail.matchRestrictionStatus === 1 ? (
          <div></div>
        ) : (
          <HireButton
            onTap={() => {
              setShowHire(true);
            }}
          />
        )}
      </>
    );
  } else if (status == 9) {
    contentActionBody = (
      <>
        <CallForInterviewButton
          onTap={() => {
            setShowCallToInterview(true);
          }}
        />
      </>
    );
  }
  return (
    <div>
      <div
        style={{
          height: "20px",
        }}
      ></div>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
          alignItems: "center",
          margin: "0px",
          padding: "0px",
          marginTop: "10px",
        }}
      >
        {contentActionBody}
        {employerId && (
          <BlockButtons
            Detail={blueMatchDetail && blueMatchDetail}
            companyId={companyId}
            employerId={employerId}
            positionType={1}
            status={status}
          />
        )}
        <div
          style={{
            height: "40px",
          }}
        ></div>
      </div>
      <ShowHireCandidateModal
        matchedJobList={blueMatchDetail && blueMatchDetail.matchedJobList}
        show={showHire}
        handleClose={handleHireClose}
        onChanged={(items) => {
          // alert("items  " + JSON.stringify(items));
          if (items == undefined || items == null || items == []) {
          } else {
            blueEmployerMatchAcceptance(2, items);
          }
        }}
      />
      <ShowNotHireCandidateModal
        matchedJobList={blueMatchDetail && blueMatchDetail.matchedJobList}
        show={showNotHire}
        handleClose={handleNotHireClose}
        onChanged={(items) => {
          if (items == undefined || items == null || items == []) {
          } else {
            blueEmployerMatchAcceptance(3, items);
          }
        }}
      />
      <ShowNotCallCandidateToInterviewModal
        matchedJobList={blueMatchDetail && blueMatchDetail.matchedJobList}
        show={showCallToInterview}
        handleClose={showCallToInterviewClose}
        onChanged={(map) => {
          var positionName = map.jobPositionName;
          var matchId = map.jobMatchId;
          if (map == undefined || map == null || map.jobMatchId === null) {
          } else {
            callCandidateForInterview(positionName, matchId);
          }
        }}
      />
    </div>
  );
};

const HireButton = ({ onTap }) => {
  return (
    <Box
      sx={{
        margin: "5px",
      }}
    >
      <CustomButton
        onTap={onTap}
        title={"İşe Al"}
        width={{
          xs: "380px",
          sm: "380px",
          md: "450px",
          lg: "450px",
          xl: "600px",
        }}
      />
    </Box>
  );
};
const NotHireButton = ({ onTap }) => {
  return (
    <Box
      sx={{
        margin: "5px",
      }}
    >
      <CustomButton
        onTap={onTap}
        title={"İşe Alma"}
        color={"#001542"}
        width={{
          xs: "380px",
          sm: "380px",
          md: "450px",
          lg: "450px",
          xl: "600px",
        }}
        backgroundColor={"white"}
        hoverBackgroundColor={"red"}
      />
    </Box>
  );
};
const CallForInterviewButton = ({ onTap }) => {
  return (
    <Box
      sx={{
        margin: "5px",
      }}
    >
      <CustomButton
        onTap={onTap}
        title={"Görüşmeye Çağır"}
        width={{
          xs: "380px",
          sm: "380px",
          md: "450px",
          lg: "450px",
          xl: "600px",
        }}
      />
    </Box>
  );
};
export default BlueActionButtons;
