import React, { useEffect, useMemo, useState } from "react";
import { Controller, useForm } from "react-hook-form";

import Modal from "react-bootstrap/Modal"; // Varsayılan olarak React Bootstrap Modal bileşeni kullanılıyor
import Select from "react-select";
import { Alert, Button } from "react-bootstrap";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import api from "../../../../../../../../../utility/Utils";
import departmentListGetsNotFaculty from "../functions/departmentListGetsNotFaculty";
import facultyDepartmentListGets from "../functions/facultyDepartmentListGets";
import { customStyles } from "../../../../../../../../customStyleInline";
import BitonisMultiDropdown from "../../../../../../../../widgets/BitonisMultiDropdown";
import BitonisSingleDropdown from "../../../../../../../../widgets/BitonisSingleDropdown";
import CustomDesign from "../../../../../../../../widgets/CustomDesign";
import CustomButton from "../../../../../../../../widgets/buttons/CustomButton";
function AddEducationModal({
  show,
  handleClose,
  job,
  onSubmit,
  props,

  inputStyle,
  register,
  handleSubmit,
  control,
  setValue,
  errors,
  watch,
  isValid,
  labelStyle,
  education,
  educationListRef,
  facultyDepAndGroupIdsRef,
  reset,
  getValues,
}) {
  const [universty, setUniversty] = useState(null);
  const [section, setSection] = useState(null);
  const [sectionList, setSectionList] = useState([]);
  const [departmentGroupId, setDepartmentGroupId] = useState(null);
  const [sameUniversityId, setSameUniversityId] = useState(null);
  useEffect(() => {
    if (show) {
      isValid = false;
      setValue("universityId", "");
      setValue("section", "");
      facultyDepAndGroupIdsRef.current = [];
      departmentListGetsNotFaculty(setSectionList);
      setValue("universityId", "");
      setValue("section", "");
    }
  }, [show]);
  var university = watch("universityId");
  return (
    <>
      <div>
        <Modal
          show={show}
          onHide={handleClose}
          backdrop="static"
          keyboard={false}
          aria-labelledby="contained-modal-title-vcenter"
          centered
          className="custom-modal-width"
        >
          {" "}
          <Modal.Header
            closeButton
            style={{ borderBottom: "none", justifyContent: "center" }}
          >
            <Modal.Title
              aria-labelledby="contained-modal-title-vcenter"
              centered
            >
              <h3 style={{ color: "#001542" }}>Eğitim</h3>
            </Modal.Title>
          </Modal.Header>
          <div className="">
            <Modal.Body>
              <form
                onSubmit={(e) => {
                  e.preventDefault();
                  handleSubmit(onSubmit)();
                  if (isValid) {
                    handleClose();
                  }
                }}
              >
                <CustomDesign
                  label={"Üniversite"}
                  required={true}
                  isColumn={true}
                  child={
                    <BitonisSingleDropdown
                      items={
                        props.universityListWhite &&
                        props.universityListWhite.universityListWhite &&
                        props.universityListWhite.universityListWhite.map(
                          ({ id, name }) => ({
                            value: id,
                            label: name,
                          })
                        )
                      }
                      register={register}
                      watch={watch}
                      companyClassName={"universityId"}
                      control={control}
                      setValue={setValue}
                      errors={errors}
                      placeholder="Üniversite Seçin"
                      onValueChanged={(e) => {
                        console.log(e);
                        setValue(`section`, "");
                        setSection(null);
                        if (e) {
                          if (
                            educationListRef.current.some(
                              (addedUniversity) =>
                                addedUniversity.universityId === e.value
                            )
                          ) {
                            setSameUniversityId(true);
                          } else {
                            setSameUniversityId(false);
                          }

                          setDepartmentGroupId(null);
                          if (e.value === 233 || e.value === 234) {
                            departmentListGetsNotFaculty(setSectionList);
                          } else {
                            facultyDepartmentListGets(e.value, setSectionList);
                          }
                          if (university) {
                          }
                        }
                      }}
                      required={true}
                      getValues={getValues}
                      errorMessage={"Üniversite Boş geçilemez"}
                    />
                  }
                />

                {sectionList && (
                  <CustomDesign
                    isColumn={true}
                    label={"Bölüm"}
                    required={true}
                    child={
                      <BitonisMultiDropdown
                        items={
                          sectionList &&
                          sectionList.map(
                            ({ id, name, departmentGroupId }) => ({
                              value: id,
                              label: name,
                              departmentGroupId: departmentGroupId,
                            })
                          )
                        }
                        register={register}
                        watch={watch}
                        companyClassName={"section"}
                        control={control}
                        setValue={setValue}
                        errors={errors}
                        errorMessage={"Bölüm Boş geçilemez"}
                        placeholder="Bölüm"
                        getValues={getValues}
                        onValueChanged={(e) => {
                          console.log(e);
                          setSection(e);
                          var facultyDepAndGroupIds = [];
                          facultyDepAndGroupIdsRef.current =
                            facultyDepAndGroupIds;
                          var university = watch("universityId");

                          if (e) {
                            const selectedValues = e.map(
                              (option) => option.value
                            );

                            if (university.value == 233) {
                              if (selectedValues.includes(698)) {
                                const selectedOption = e.find(
                                  (option) => option.value === 698
                                );

                                if (selectedOption) {
                                  [selectedOption].map((val) => {
                                    facultyDepAndGroupIds.push({
                                      facultyDepartmentGroupId: val.value,
                                      facultyDepartmentId: 23069,
                                      facultyDepartmentName: val.label,
                                    });
                                  });
                                } else {
                                }
                              } else {
                                e.map((val) => {
                                  facultyDepAndGroupIds.push({
                                    facultyDepartmentGroupId: val.value,
                                    facultyDepartmentId: 23069,
                                    facultyDepartmentName: val.label,
                                  });
                                });
                              }
                            } else if (university.value == 234) {
                              if (selectedValues.includes(698)) {
                                const selectedOption = e.find(
                                  (option) => option.value === 698
                                );
                                if (selectedOption) {
                                  [selectedOption].map((val) => {
                                    facultyDepAndGroupIds.push({
                                      facultyDepartmentGroupId: val.value,
                                      facultyDepartmentId: 25091,
                                      facultyDepartmentName: val.label,
                                    });
                                  });
                                } else {
                                }
                              } else {
                                e.map((val) => {
                                  facultyDepAndGroupIds.push({
                                    facultyDepartmentGroupId: val.value,
                                    facultyDepartmentId: 25091,
                                    facultyDepartmentName: val.label,
                                  });
                                });
                              }
                            } else {
                              if (selectedValues.includes(23069)) {
                                const selectedOption = e.find(
                                  (option) => option.value === 23069
                                );
                                if (selectedOption) {
                                  [selectedOption].map((val) => {
                                    facultyDepAndGroupIds.push({
                                      facultyDepartmentGroupId:
                                        val.departmentGroupId,
                                      facultyDepartmentId: val.value,
                                      facultyDepartmentName: val.label,
                                    });
                                  });
                                } else {
                                }
                              } else {
                                e.map((val) => {
                                  facultyDepAndGroupIds.push({
                                    facultyDepartmentGroupId:
                                      val.departmentGroupId,
                                    facultyDepartmentId: val.value,
                                    facultyDepartmentName: val.label,
                                  });
                                });
                              }
                            }
                            facultyDepAndGroupIdsRef.current =
                              facultyDepAndGroupIds;
                          } else {
                            var facultyDepAndGroupIds = [];
                          }
                        }}
                        required={true}
                        selectedId={
                          (university === undefined ||
                          university === null ||
                          university === ""
                            ? null
                            : university && university.value == 233) ||
                          (university && university.value == 234)
                            ? 698
                            : 23069
                        }
                      />
                    }
                  />
                )}
              </form>
            </Modal.Body>
            <Modal.Footer
              style={{
                display: "flex",
                flexDirection: "Row",
                alignItems: "center",
                justifyContent: "center",
                alignContent: "center",
                borderTop: "none",
                margin: "0px",
              }}
            >
              <form
                onSubmit={(e) => {
                  e.preventDefault();
                  if (sameUniversityId == null) {
                    handleSubmit(onSubmit)();
                  }
                  if (sameUniversityId == true) {
                    toast.info("Bu Üniversiteyi Daha Önce Eklediğiniz", {
                      autoClose: 3000,
                      onClose: () => {
                        setTimeout(() => {}, 100);
                      },
                    });
                    handleClose();
                  } else {
                    handleSubmit(onSubmit)();
                    const universty = watch("universityId");
                    const section = watch("section");

                    if (
                      universty &&
                      section &&
                      facultyDepAndGroupIdsRef.current
                    ) {
                      isValid = true;
                    } else {
                      isValid = false;
                    }
                    if (isValid) {
                      var addMap = {
                        universityId: universty.value,
                        universityName: universty.label,
                        facultyDepAndGroupIds: facultyDepAndGroupIdsRef.current,
                      };

                      educationListRef.current = [
                        ...educationListRef.current,
                        addMap,
                      ];

                      handleClose();
                    } else {
                    }
                  }
                }}
              >
                {" "}
                <CustomButton
                  title={"Kaydet"}
                  width={{
                    xs: "350px",
                    sm: "350px",
                    md: "350px",
                    lg: "350px",
                    xl: "350px",
                  }}
                />
              </form>
            </Modal.Footer>
          </div>
        </Modal>
      </div>
    </>
  );
}

export default AddEducationModal;
