import React from "react";
import CustomPdfPreview from "../../../../../../../../widgets/CustomPdfPreview";
import { DividerPdf } from "./DividerPdf";
import CustomHobbiesPdfPreview from "../../../../../../../../widgets/CustomHobbiesPdfPreview";

const WhiteHobbiesComponent = ({ whiteMatchDetail }) => {
  return (
    <div>
      <div
        className="card"
        style={{
          flex: "6",
          marginTop: "7px",
          display: "flex",
          flexDirection: "column",
          margin: "0px",
          padding: "0px",
          marginTop: "0px",
          paddingTop: "10px",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            marginBottom: "10px",
            padding: "0px",
          }}
        >
          <div
            style={{
              border: "2px solid grey",
              borderRadius: "8px",

              height: "40px",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignContent: "center",
            }}
          >
            <p
              style={{
                padding: "10px",
                margin: "0px",
                fontSize: "10px",
                color: "#001542",
                fontWeight: "500",
              }}
            >
              Hobiler
            </p>
          </div>
          <hr
            style={{
              color: "black",
              marginLeft: "0px",
              paddingLeft: "0px",
              border: "1px solid grey",
              flex: "6",
              margin: "0px",
              padding: "0px",
            }}
          />
          <div
            style={{
              width: "40px",
              height: "40px",
              borderRadius: "50%",
              border: "1.8px solid grey",
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <i
              class="fa-solid fa-medal"
              style={{
                color: "orange",
                fontSize: "12px",
              }}
            ></i>
          </div>
        </div>
        <div
          style={{
            backgroundColor: "#f7f7f7",
            borderRadius: "8px",
            marginTop: "10px",
            height: "100px",
            marginBottom: "0px",
            margin: "0px",
            padding: "0px",
          }}
        >
          <div
            style={{
              margin: "10px",
            }}
          >
            {whiteMatchDetail && whiteMatchDetail.matchHobbies && (
              <div>
                <CustomHobbiesPdfPreview
                  name={""}
                  value={whiteMatchDetail && whiteMatchDetail.matchHobbies}
                />
              </div>
            )}
          </div>
        </div>
        <div
          style={{
            height: "50px",
          }}
        ></div>
      </div>
    </div>
  );
};

export default WhiteHobbiesComponent;
