import React from "react";
import "../../css/bitoniscss/customPreviewMySkillTwopt.css";
const CustomPreviewMySkillTwopt = ({ name, value }) => {
  return (
    <div className="custom-preview-container">
      <div className="row-parent-item">
        <div className="custom-preview-label">{name}</div>
        <div className="custom-preview-value">{value}</div>
      </div>
    </div>
  );
};

export default CustomPreviewMySkillTwopt;
