import React, { useState, useEffect, useRef } from "react";
import { Form, InputGroup, Dropdown, Button } from "react-bootstrap";
import { DemandsServices } from "../../../../../../../../services";
import FilterModal from "./Modal/FilterModal";
import noMatchFound from "../../../../../../../../images/svg/noMatchFound.svg";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import MatchNotFound from "./MatchNotFound";
import PaginationPage from "../../../../../../../widgets/PaginationPage";
import LoadingDotsAnimation from "../../../../../../../widgets/LoadingDotsAnimation";
import useCurrentUserActionPageLog from "../../../../../../../hooks/useCurrentUserActionPageLog";
import TabelItem from "../../MatchDetail/blueMatchDetail/components/TabelItem";

const ShowBlueMatchShow = () => {
  useCurrentUserActionPageLog(18, null); //loglama bilgisini apiye gönderir
  var matchSelectedList = [
    { title: "İşe Alındı", status: 2 },
    { title: "İşe Alınmadı", status: 3 },
    { title: "Yeni Eşleşme", status: 1 },
    { title: "Görüşmeye Bekleniyor", status: 9 },
  ];
  const [demandBlueMatchData, setDemandBlueMatchData] = useState(null);
  const [search, setSearch] = useState("");
  const [selectedStatus, setSelectedStatus] = useState([
    { title: "Yeni Eşleşme", status: 1 },
    { title: "Görüşmeye Bekleniyor", status: 9 },
  ]);

  useEffect(() => {
    getMatchBlue(selectedStatus);
  }, []);

  function getMatchBlue(selectedStatus) {
    DemandsServices.demandMatchBlue(selectedStatus).then(
      (demandMatch) => {
        //  alert("demandMatch * *" + JSON.stringify(demandMatch));
        if (demandMatch && demandMatch.succeeded === true) {
          setDemandBlueMatchData([]);
          setDemandBlueMatchData(demandMatch.data.pagedData);
        }
      },
      (error) => {}
    );
  }

  function searchHandleCandidate(value) {
    setSearch(value);
  }

  const [show, setShow] = useState(null);
  function handleClose() {
    setShow(false);
  }
  const history = useHistory();
  const [page, setPage] = useState(1);
  const pageSize = 10; // Her sayfada gösterilecek eleman sayısı

  const paginatedList =
    demandBlueMatchData && page === null
      ? demandBlueMatchData
      : demandBlueMatchData &&
        demandBlueMatchData.slice((page - 1) * pageSize, page * pageSize);

  return (
    <>
      <div>
        <div className="">
          <InputGroup className="mb-3" style={{}}>
            <Form.Control
              style={{
                borderRadius: "25px",
                backgroundColor: "#F5F5F5",
                marginLeft: "10px",
              }}
              type="search"
              placeholder="İsme göre ara"
              value={search}
              onChange={(e) => searchHandleCandidate(e.target.value)}
            />
          </InputGroup>
        </div>

        {demandBlueMatchData === null ? (
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
            }}
          >
            <LoadingDotsAnimation />
          </div>
        ) : demandBlueMatchData && paginatedList && paginatedList.length > 0 ? (
          paginatedList && (
            <div
              style={{
                marginBottom: "10%",
                marginTop: "3%",
              }}
            >
              {paginatedList.map((matchData, index) => {
                return paginatedList[index].candidateNameSurname
                  .toLowerCase()
                  .includes(search.toLowerCase()) ? (
                  <div
                    onClick={() => {
                      const dataMap = new Map();
                      dataMap.set("candidateId", matchData.candidateId);
                      dataMap.set("matchId", matchData.matchId);
                      dataMap.set("status", matchData.status);
                      history.push({
                        pathname: "matchShow/blueMatchDetail",

                        state: { dataMap },
                      });
                    }}
                  >
                    <TabelItem
                      key={matchData.candidateId}
                      candidateNameSurname={matchData.candidateNameSurname}
                      positionName={matchData.positionName}
                      status={matchData.status}
                      candidateId={matchData.candidateId}
                      matchCreateDate={matchData.matchCreateDate}
                    />
                  </div>
                ) : (
                  <div></div>
                );
              })}
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                {demandBlueMatchData &&
                  demandBlueMatchData.length > 0 &&
                  demandBlueMatchData.length > pageSize && (
                    <PaginationPage
                      items={demandBlueMatchData}
                      page={page}
                      pageSize={pageSize}
                      onChange={(value) => {
                        if (value) {
                          setPage(value);
                        }
                      }}
                      onTapAllShowButton={() => {
                        setPage(null);
                      }}
                    />
                  )}
              </div>
            </div>
          )
        ) : (
          <MatchNotFound title={""} newMatch={true} />
        )}
      </div>
      <FilterModal
        show={show}
        handleClose={handleClose}
        approval={(value) => {
          if (value) {
            setSelectedStatus([]);
            value.map((val) => {
              setSelectedStatus((prev) => [...prev, val]);
            });
            getMatchBlue(value);
            setShow(false);
          } else {
            setShow(false);
          }
        }}
        matchSelectedList={matchSelectedList}
        selectedStatus={selectedStatus}
      />
    </>
  );
};

export default ShowBlueMatchShow;
