const RadioButtonWidget = ({
  name,
  id,
  value,
  onChange,
  checked,
  text,
  activeId,
}) => {
  return (
    <div
      key={id}
      style={
        activeId == id
          ? {
              display: "flex",
              flexDirection: "row",
              border: "1px solid #fdc303",
              padding: "10px",
              borderRadius: "6px",
              height: "45px",
              alignContent: "center",
              alignItems: "flex-start",
              justifyItems: "center",
              justifyContent: "flex-start",
            }
          : {
              display: "flex",
              flexDirection: "row",
              height: "45px",
              alignContent: "flex-start",
              alignItems: "flex-start",
              justifyItems: "center",
              justifyContent: "flex-start",
              padding: "10px",
              borderRadius: "6px",
            }
      }
    >
      <input
        className="radio-input"
        type="radio"
        name={name}
        id={id}
        value={value}
        onChange={onChange}
        checked={checked}
        style={{
          marginRight: "10px",
          width: "20px",
          height: "25px",
          accentColor: "#001542",
        }}
      />
      <p
        className="custom-radio"
        style={
          activeId == id
            ? {
                color: "#001542",
                fontWeight: "550",
              }
            : {
                color: "grey",
              }
        }
      >
        {text}
      </p>
    </div>
  );
};
export default RadioButtonWidget;
