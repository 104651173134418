import { ProfileAddedRoutesMainConstans, ProfileDetailConstans, ProfileDetailMainConstans } from "../constants";
import { ProfileDetailServices } from "../services/ProfileDetailServices";
import { toast } from "react-toastify";

export const ProfileDetailAction = {
  companyDetail,
  companyMainDetail,
  companyProfileUpdate,
  companyWidthAddedRoutesGetCompanines,
};




function companyWidthAddedRoutesGetCompanines() {
  //Eklenen Şirket Güzergahlarını getir

  return (dispatch) => {
    dispatch(request());
    ProfileDetailServices.companyWidthAddedRoutesGetCompanines().then(
      (companyRoutes) => {
      //  alert("companyRoutes* ** " + JSON.stringify(companyRoutes));
        dispatch(success(companyRoutes));
      },
      (error) => {
        dispatch(failure(error));
      }
    );
  };

  function request() {
    return {
      type: ProfileAddedRoutesMainConstans.GET_ALL_REQUEST,
    };
  }

  function success(companyRoutes) {
    return {
      type: ProfileAddedRoutesMainConstans.GET_ALL_SUCCESS,
      companyRoutes
    };
  }

  function failure(error) {
    return {
      type: ProfileAddedRoutesMainConstans.GET_ALL_FAILURE,
      error
    };
  }
}

// Şİrket Bilgileri

function companyDetail(arg) {
  //Şirket Bilgileri
  return (dispatch) => {
    dispatch(request());
    ProfileDetailServices.companyDetail(arg).then(
      (companyDetail) => {
        // alert("şirketdetya" + JSON.stringify(companyDetail[0]))
        dispatch(success(companyDetail));
      },
      (error) => {
        dispatch(failure(error));
      }
    );
  };

  function request() {
    return {
      type: ProfileDetailConstans.GET_ALL_REQUEST,
    };
  }

  function success(companyDetail) {
    return {
      type: ProfileDetailConstans.GET_ALL_SUCCESS,
      companyDetail,
    };
  }

  function failure(error) {
    return {
      type: ProfileDetailConstans.GET_ALL_FAILURE,
      error,
    };
  }
}
function companyMainDetail(arg) {
  //Firmanın Ana Şirket Bilgileri
  return (dispatch) => {
    dispatch(request());
    ProfileDetailServices.companyMainDetail(arg).then(
      (companyMainDetail) => {
        dispatch(success(companyMainDetail));
      },
      (error) => {
        dispatch(failure(error));
      }
    );
  };

  function request() {
    return {
      type: ProfileDetailMainConstans.GET_ALL_REQUEST,
    };
  }

  function success(companyMainDetail) {
    return {
      type: ProfileDetailMainConstans.GET_ALL_SUCCESS,
      companyMainDetail,
    };
  }

  function failure(error) {
    return {
      type: ProfileDetailMainConstans.GET_ALL_FAILURE,
      error,
    };
  }
}

function companyProfileUpdate(arg) {
  return (dispatch) => {
    ProfileDetailServices.companyProfileUpdate(arg).then(
      (companyProfileUpdateResponse) => {
        if (companyProfileUpdateResponse.succeeded === true) {
          toast.success("Güncelleme başarılı şekilde gerçekleşti", {
            autoClose: 2000,
            onClose: () => {
              setTimeout(() => {
                window.location.href = window.location.href;
              }, 100);
            },
          });
        } else {
          toast.info(companyProfileUpdateResponse.error.message, {
            autoClose: 2000,
            onClose: () => {
              setTimeout(() => {
                window.location.href = window.location.href;
              }, 100);
            },
          });
        }
      },
        (error) => {
           toast.info("Hata Oluştu", {
            autoClose: 2000,
            onClose: () => {
              setTimeout(() => {
                window.location.href = window.location.href;
              }, 100);
            },
          });
        
      }
    );
  };
}
