import * as React from "react";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Box from "@mui/material/Box";
import { useState } from "react";
import { Button } from "react-bootstrap";
import { StepConnector, Typography } from "@mui/material";
import { makeStyles, withStyles } from "@mui/styles";
import useIsMobile from "../../../../hooks/useIsMobile";

const DottedStepConnector = withStyles((theme) => ({
  line: {
    borderColor: "orange",
    borderTopWidth: 4,
    borderStyle: "dotted",
    borderBottomWidth: 0,
    marginLeft: 8,
    marginRight: 8,
  },
}))(StepConnector);

const BitonisStepper = ({
  stepsHeader,
  children,
  currentStep,
  changePrev,
  companyName,
  companyLocationName,
}) => {
  const isMobile = useIsMobile();

  const itemsPerGroup = 3;
  const startIndex = Math.floor(currentStep / itemsPerGroup) * itemsPerGroup;
  const endIndex = startIndex + itemsPerGroup;

  // Bir önceki 3'lü adımı temizle
  const previousSteps = stepsHeader.slice(0, startIndex);

  return (
    <div>
      <div
        className="card"
        style={{
          backgroundColor: "white",
          boxShadow: "none",
          margin:"0px",
          padding:"0px"
        }}
      >
        <div
          className="card-header"
          style={{
            borderBottom: "none",
          }}
        >
          {" "}
          <i
            className="fa-solid fa-angle-left"
            style={{
              fontSize: "25px",
              marginTop: "0px",
              display: currentStep == 0 ? "none" : "",
              cursor:"pointer"
            }}
            onClick={changePrev}
          ></i>
          <Stepper
            connector={<DottedStepConnector />}
            activeStep={currentStep - startIndex} // Başlangıç noktasından itibaren hesapla
            alternativeLabel
            style={{
              width: "100%",
              marginTop: "20px",
            }}
          >
            {stepsHeader.slice(startIndex, endIndex).map((label, index) => (
              <Step key={label.title}>
                <StepLabel
                  icon={
                    <div
                      style={
                        currentStep === index + startIndex
                          ? {
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              width: "40px",
                              height: "40px",
                              border: "2.7px dotted #fdc303",
                              borderRadius: "50%",
                              marginBottom: "0px",
                              borderColor: "orange",
                              position: "relative",
                              bottom: "5px",
                            }
                          : currentStep > index + startIndex
                          ? {
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              width: "40px",
                              height: "40px",
                              backgroundColor: "orange",
                              color: "white",
                              borderRadius: "50%",
                              marginBottom: "0px",
                              position: "relative",
                              bottom: "5px",
                            }
                          : {
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              width: "40px",
                              height: "40px",
                              border: "2px dotted #001542",
                              borderRadius: "50%",
                              marginBottom: "0px",
                              position: "relative",
                              bottom: "5px",
                            }
                      }
                    >
                      {label.icon}
                    </div>
                  }
                >
                  <Typography
                    sx={{
                      margin: "0px",
                      padding: "0px",
                      fontSize: {
                        xs: "11px",
                        sm: "12px",
                        md: "12px",
                        lg: "14px",
                        xl: "14px",
                      },

                      fontWeight: 600,
                      color:
                        currentStep > index + startIndex
                          ? {
                              color: "#FDC303",
                            }
                          : {
                              color: "grey",
                            },
                    }}
                  >
                    {label.title}
                  </Typography>
                </StepLabel>
              </Step>
            ))}
          </Stepper>
        </div>
        <div
          className=""
          style={{
            marginTop: "20px",
          }}
        ></div>
        {currentStep !== 0 && (
          <div
            id="stepBody"
            style={{
              marginLeft: !isMobile ? "40px" : "20px",
            }}
          >
            <h5
              style={{
                top: "0px",
                color: "#001542",
                fontSize: !isMobile ? "18px" : "14px",
              }}
            >
              {companyName && companyName}{" "}
              <p
                style={{
                  bottom: "0px",

                  color: "#001542",
                  top: "0px",
                  fontSize: !isMobile ? "16px" : "12px",
                  marginTop: "0px",
                  fontWeight: 400,
                }}
              >
                {companyLocationName && companyLocationName}
              </p>
            </h5>
          </div>
        )}
        <React.Fragment>
          <div
            style={{
              margin: "0 5% 0px 5%",
              paddingTop: "0px",
            }}
          >
            {children}
          </div>
        </React.Fragment>
      </div>
    </div>
  );
};

export default BitonisStepper;
