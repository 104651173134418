import React, { useEffect, useState } from "react";
import demandNotFound from "../../../../../../../images/svg/demandNotFound.svg";
import { Form, InputGroup, Dropdown, Button } from "react-bootstrap";
import { DemandsServices } from "../../../../../../../services";
import BlueDemandItem from "./widgets/BlueDemandItem";
import DemandNotFound from "./Common/DemandNotFound";
import FilterBlueModal from "./Modal/FilterBlueModal";
import { ToastContainer, toast } from "react-toastify";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import PopupLongMenu from "../../../../../../widgets/PopupLongMenu";
import DemandCopyApproved from "./Modal/DemandCopyApproved";
import { Pagination, PaginationItem, Typography } from "@mui/material";
import PaginationPage from "../../../../../../widgets/PaginationPage";
import LoadingDotsAnimation from "../../../../../../widgets/LoadingDotsAnimation";
import { useDispatch } from "react-redux";
import { JobConstants } from "../../../../../../../constants";
import "../../../../../../../css/bitoniscss/demand/demandCopy.css";

const BlueAllShow = () => {
  const [blueDemandList, setBlueDemandList] = useState(null);
  const [showDemandCopy, setShowDemandCopy] = useState(null);
  const [demandItem, setDemandItem] = useState(null);
  const dispatch = useDispatch();
  function handleCloseDemandCopy() {
    setShowDemandCopy(false);
  }
  const [show, setShow] = useState(null);
  const [search, setSearch] = useState("");

  var demandFilterList = [
    {
      title: "Aktif",
      id: 0,
    },
    {
      title: "Pasif",
      id: 1,
    },
  ];
  const [selectedFilter, setSelectedFilter] = useState([
    {
      title: "Aktif",
      id: 0,
    },
    {
      title: "Pasif",
      id: 1,
    },
  ]);

  useEffect(() => {
    getAllShowBlue(null);
  }, []);

  function getAllShowBlue(isActive) {
    var sendData = { isActive: isActive, positionType: 1 };
    DemandsServices.demandsBlue(sendData).then(
      (demand) => {
        if (demand && demand.succeeded === true) {
          setBlueDemandList([]);
          demand.data.map((value) => {
            setBlueDemandList((prev) => [...prev, value]);
          });
        }
      },
      (error) => {}
    );
  }
  function searchHandleCandidate(value) {
    setSearch(value);
  }

  function handleClose() {
    setShow(false);
  }
  const history = useHistory();
  function copyDemandItem(job) {
    var sendData = { jobId: parseInt(job.jobId.toString()) };
    DemandsServices.copyDemandBlue(sendData).then(
      (copyDemand) => {
        setShowDemandCopy(false);
        if (copyDemand && copyDemand.succeeded === true) {
          getAllShowBlue(null);
          toast.success("Talep Başarılı bir şekilde kopyalandı", {
            autoClose: 2000,
            onClose: () => {
              setTimeout(() => {}, 100);
            },
          });
        } else {
          toast.info(copyDemand.error.message, {
            autoClose: 2000,
            onClose: () => {
              setTimeout(() => {}, 100);
            },
          });
        }
      },
      (error) => {}
    );
  }
  const [page, setPage] = useState(1);
  const pageSize = 10; // Her sayfada gösterilecek eleman sayısı

  const paginatedList =
    blueDemandList && page === null
      ? blueDemandList
      : blueDemandList &&
        blueDemandList.slice((page - 1) * pageSize, page * pageSize);

  return (
    <>
      <ToastContainer
        position="top-right"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <div>
        <div className="">
          <InputGroup className="mb-3" style={{}}>
            <Form.Control
              style={{
                borderRadius: "20px",
                backgroundColor: "#F5F5F5",
                marginLeft: "10px",
              }}
              type="search"
              placeholder="Pozisyona göre ara"
              value={search}
              onChange={(e) => searchHandleCandidate(e.target.value)}
            />

            <Button
              variant="outline"
              onClick={() => {
                setShow(true);
              }}
              style={{
                borderRadius: "8px",
                border: "1px solid #F5F5F5",
                marginLeft: "30px",
                backgroundColor: "white",
              }}
            >
              <i
                class="fa-solid fa-arrow-down-wide-short"
                style={{
                  color: "#fdc303",
                  fontSize: "20px",
                }}
              ></i>
              <a
                style={{
                  color: "#001542",
                  fontWeight: "600px",
                  margin: "5px",
                }}
              >
                Filtrele
              </a>
            </Button>
          </InputGroup>
        </div>
        {blueDemandList === null ? (
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
            }}
          >
            <LoadingDotsAnimation />
          </div>
        ) : blueDemandList && paginatedList && paginatedList.length > 0 ? (
          <div>
            {paginatedList &&
              paginatedList.map((demand, index) => {
                return paginatedList &&
                  paginatedList[index].positionName
                    .toLowerCase()
                    .includes(search.toLowerCase()) ? (
                  <div
                    style={{
                      position: "relative",
                    }}
                  >
                    <BlueDemandItem
                      positionName={demand.positionName}
                      demand={demand}
                      onTap={() => {
                        const dataMap = new Map();
                        dataMap.set("jobId", demand.jobId);
                        dataMap.set("isActive", Boolean(demand.isActive));
                        dispatch({ type: JobConstants.GET_CLEAR });
                        history.push({
                          pathname: "/demands/BlueDemandPreview",
                          state: { dataMap },
                        });
                      }}
                      onTapMatch={() => {
                        if (demand.didDemandExpired === false) {
                          const dataMap = new Map();
                          dataMap.set("jobId", demand.jobId);
                          dataMap.set("positionName", demand.positionName);

                          history.push({
                            pathname: "/demands/blueCurrentDemandMatchShow",
                            state: { dataMap },
                          });
                        } else {
                          toast.error(
                            "Talebinizle eşleşen adayları görme süreniz doldu. Yeni bir talep oluşturarak eşleşme sağlayabilirsiniz.",
                            {
                              autoClose: 5000,
                              onClose: () => {
                                setTimeout(() => {}, 1000);
                              },
                            }
                          );
                        }
                      }}
                    />

                    <div onClick={() => {}} id="demandCopyBlue">
                      <div>
                        <PopupLongMenu
                          options={[
                            {
                              title: "Talep Kopyala",
                              id: 0,
                            },
                          ]}
                          onChanged={(value) => {
                            if (value) {
                              if (value.onTapStatus == true && value.id === 0) {
                                setDemandItem(demand);
                                setShowDemandCopy(true);
                              }
                            }
                          }}
                          icon={
                            <i className="fa-solid fa-ellipsis-vertical menu-icon-demand"></i>
                          }
                          iconItem={<i className="fa-regular fa-copy"></i>}
                        />
                      </div>
                    </div>
                  </div>
                ) : (
                  <div></div>
                );
              })}
          </div>
        ) : (
          <DemandNotFound />
        )}
        {blueDemandList &&
          blueDemandList.length > 0 &&
          blueDemandList.length > pageSize && (
            <PaginationPage
              items={blueDemandList}
              page={page}
              pageSize={pageSize}
              onChange={(value) => {
                if (value) {
                  setPage(value);
                }
              }}
              onTapAllShowButton={() => {
                setPage(null);
              }}
            />
          )}
      </div>
      <FilterBlueModal
        show={show}
        handleClose={handleClose}
        approval={(value) => {
          if (value) {
            if (value.length == 2) {
              getAllShowBlue(null);
              setSelectedFilter([
                {
                  title: "Aktif",
                  id: 0,
                },
                {
                  title: "Pasif",
                  id: 1,
                },
              ]);
              setShow(false);
            } else {
              if (value[0].id == 0) {
                //aktif
                getAllShowBlue(true);
                setSelectedFilter([
                  {
                    title: "Aktif",
                    id: 0,
                  },
                ]);
                setShow(false);
              } else if (value[0].id == 1) {
                //pasife aldım
                getAllShowBlue(false);
                setSelectedFilter([
                  {
                    title: "Pasif",
                    id: 1,
                  },
                ]);
                setShow(false);
              } else {
                getAllShowBlue(null);
                setSelectedFilter([
                  {
                    title: "Aktif",
                    id: 0,
                  },
                  {
                    title: "Pasif",
                    id: 1,
                  },
                ]);
                setShow(false);
              }
            }
          } else {
            setSelectedFilter([
              {
                title: "Aktif",
                id: 0,
              },
              {
                title: "Pasif",
                id: 1,
              },
            ]);
            setShow(false);
            getAllShowBlue(null);
          }
        }}
        demandFilterList={demandFilterList}
        selectedStatus={selectedFilter}
      />
      <DemandCopyApproved
        handleClose={handleCloseDemandCopy}
        show={showDemandCopy}
        demandItem={demandItem}
        approval={(value) => {
          if (value && value == true) {
            copyDemandItem(demandItem);
          }
        }}
        isOnIntern={false}
      />
    </>
  );
};
/*



      */
export default BlueAllShow;
