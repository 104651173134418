import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { DropListAction } from "../../../../../../action";
import { DropListServices } from "../../../../../../services";
import Modal from "react-bootstrap/Modal"; // Varsayılan olarak React Bootstrap Modal bileşeni kullanılıyor
import Select from "react-select";
import { Alert, Button } from "react-bootstrap";
import { ToastContainer, toast } from "react-toastify";
import api from "../../../../../../utility/Utils.js";
import "react-toastify/dist/ReactToastify.css";
import {
  customStyles,
  customStylesMulti,
  labelStyle,
} from "../../../../../customStyleInline";
import BitonisMultiDropdown from "../../../../../widgets/BitonisMultiDropdown.jsx";
import BitonisSingleDropdown from "../../../../../widgets/BitonisSingleDropdown.jsx";
import CustomDesign from "../../../../../widgets/CustomDesign.jsx";
import PageInfoTitle from "../../../../../widgets/PageInfoTitle.jsx";
import { Box } from "@mui/material";
import CustomButton from "../../../../../widgets/buttons/CustomButton.jsx";
import SelectMultiRoute from "../../../../../widgets/components/common/SelectMultiRoute.jsx";

function AddRoutesModalFindJobSeeker({
  show,
  handleClose,
  props,
  companyId,
  backState,
}) {
  const {
    register,
    handleSubmit,
    control,
    watch,
    reset,
    setValue,
    getValues,
    formState: { errors },
  } = useForm();
  const [lat, setLat] = useState("");
  const [lng, setLng] = useState("");
  const [selectCompanyList, setSelectCompanyList] = useState([]);
  const [semtNewList, setSemtNewList] = useState([]);
  const [showCountiesAndDistricts, setShowCountiesAndDistricts] =
    useState(true);
  const [cityList, setCityList] = useState([]);
  useEffect(() => {
    if (show) {
      setShowCountiesAndDistricts(true);
      setValue("countryId", "");
      setValue("cityId", "");
      setValue("countyId", "");
      setValue("districts", "");
      setSemtNewList([]);
    }
  }, [show]);

  useEffect(() => {
    if (show) {
      if (!props.countryList.success) {
        props.dispatch(DropListAction.countryList());
      }
      DropListServices.allCityList({
        countryId: 1,
      }).then(
        (cityData) => {
          setCityList([]);
          if (cityData && cityData.length > 0) {
            cityData.map((value) => setCityList((prev) => [...prev, value]));
          }
        },
        (error) => { }
      );
    }
  }, [show]);

  const handleCity = (selected) => {

    if (selected.value.toString() == "83") {
      setShowCountiesAndDistricts(false);
    } else {
      setShowCountiesAndDistricts(true);
    }
    props.dispatch(DropListAction.allCountyList(selected.value));
  };

  async function getLatLngApi(adress) {
    DropListServices.handleGetLocation(adress).then((location) => {
      // alert("lat " + location.lat + " " + "lng " + location.lng);
      if(location){
        var latitute = location.lat;
        var longtitute = location.lng;

        setLat(latitute);
        setLng(longtitute);
      }
    
    });
  }
  async function getCompanyActive() {
    setSelectCompanyList([]);
    api
      .get("employer/companies")
      .then((response) => {
        if (response.succeeded == true) {
          //   alert("response * * * " + JSON.stringify(response))
          //  setSelectCompanyList(response.data);
          response.data.map((value) => {
            if (value["isActive"].toString() === "true") {
              setSelectCompanyList((prev) => [...prev, value]);
            }
          });
        } else {
          setSelectCompanyList([]);
        }
      })
      .catch((err) => {
        setSelectCompanyList([]);
      });
  }
  useEffect(() => {
    getCompanyActive();
  }, []);
  const handleCounty = (selected) => {

    setSemtNewList([]);
    if (selected) {
      semtFGetApi(selected);
    }
  };
  function semtFGetApi(selected) {
    for (var i = 0; i < selected.length; i++) {
      DropListServices.districtList(selected[i].value).then(
        (districtList) => {
          if (
            districtList == null ||
            districtList == undefined ||
            districtList == []
          ) {
          } else {
            var semtListPre = [];
            semtListPre = districtList;

            // alert("semtListPre * * * " + JSON.stringify(semtListPre));

            for (var fetSemt in semtListPre) {
              // Aynı ID'ye sahip semt zaten listeye eklenmişse, eklemeyi atla
              if (semtNewList.some((semt) => semt.id == fetSemt.value)) {
                // print("aynı id ye sahip var* * ** * * ");
                continue;
              }
              // Aynı ID'ye sahip  listeye eklenmemişse, ekle
              setSemtNewList((prevList) => [...prevList, semtListPre[fetSemt]]);
            }
          }
        },
        (error) => {
          setSemtNewList([]);
        }
      );
    }
  }

  function addRoutes(form) {
    var sendData = {
      name: form["servisRouteName"],
      companyId: companyId,
      companyPrefLocationReq: {
        countryId: form["countryId"]["value"],
        cityId: form["cityId"]["value"],
        counties:
          form["countyId"] == "" || form["countyId"] == []
            ? []
            : form["countyId"].map((count) =>
              parseInt(count["value"].toString())
            ),
        districts:
          form["districts"] == "" || form["districts"] == undefined
            ? []
            : form["districts"].map((count) =>
              parseInt(count["value"].toString())
            ),
        latitude: lat.toString(),
        longitude: lng.toString(),
      },
    };
    addCompaniesRoute(sendData);
    setValue("countryId", "");
    setValue("cityId", "");
    setValue("countyId", "");
    setValue("districts", "");
    setValue("servisRouteName", "");
  }
  async function addCompaniesRoute(routeData) {
    setSelectCompanyList([]);
    api
      .post("company/preferredLocation", routeData)
      .then((response) => {
        if (response.succeeded == true) {
          handleClose(true);
          toast.success(response.result.message, {
            autoClose: 2000,
            onClose: () => {
              setTimeout(() => {
                backState(true);
              }, 100);
            },
          });
        } else {
          toast.info(response.error.message, {
            autoClose: 2000,
            onClose: () => {
              setTimeout(() => { }, 100);
            },
          });
        }
      })
      .catch((err) => {
        toast.info("Hata Oluştu", {
          autoClose: 2000,
          onClose: () => {
            setTimeout(() => { }, 100);
          },
        });
      });
  }

  return (
    <>
      <div>
        <Modal
          className="fade bd-example-modal-lg"
          size="lg"
          show={show}
          onHide={handleClose}
          backdrop="static"
          keyboard={false}
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header
            closeButton
            style={{
              borderBottom: "none",
              marginBottom: "0px",
              paddingBottom: "0px",
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignContent: "center",
              alignItems: "center",
            }}
          >
            <PageInfoTitle title={"Yeni Güzergah Ekle"} bottomPadding={"0px"} />
          </Modal.Header>
          <Modal.Body
            className="grid-example"
            style={{
              marginTop: "0px",
              paddingTop: "0px",
            }}
          >
            <form onSubmit={handleSubmit(addRoutes)}>
            <div className="row">
        <CustomDesign
          label={"Servis Güzergah Başlığı"}
          required={true}
          isColumn={true}
          columnClassName={"col-xs-12 col-md-12"}
          child={
            <>
              <input
                type="text"
                id="inputdropdownmodal"
                className="form-control"
                rules={{ required: true }}
                name="servisRouteName"
                {...register("servisRouteName", { required: true })}
                placeholder="Güzergah Başlığı"
              ></input>
              {errors.servisRouteName && (
                <div className="text-danger fs-12">Bu alan Boş Olamaz</div>
              )}
            </>
          }
        />
      </div>
              <SelectMultiRoute
                props={props}
                register={register}
                watch={watch}
                control={control}
                setValue={setValue}
                errors={errors}
                getValues={getValues}
                getLatLngApi={getLatLngApi}
                preview={false}
                setSemtNewList={setSemtNewList}
                semtNewList={semtNewList}
                handleCity={handleCity}
                handleCounty={handleCounty}
                showCountiesAndDistricts={showCountiesAndDistricts}
                setShowCountiesAndDistricts={setShowCountiesAndDistricts}
                cityList={cityList}
                setCityList={setCityList}
              />
            </form>
          </Modal.Body>
          <Modal.Footer
            style={{
              borderTop: "none",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              alignContent: "center",
              borderTop: "none",
              marginTop: "0px",
              paddingTop: "0px"
            }}
          >
            <form onSubmit={handleSubmit(addRoutes)}>
              <Box
                sx={{
                  margin: "0px",
                  padding: {
                    xs: "5px",
                    sm: "5px",
                    md: "5px",
                    lg: "5px",
                    xl: "5px",
                  },
                }}
              >
                <CustomButton
                  onTap={handleSubmit(addRoutes)}
                  title={"Kaydet"}
                  width={{
                    xs: "400px",
                    sm: "350px",
                    md: "350px",
                    lg: "350px",
                    xl: "350px",
                  }}
                />
              </Box>
            </form>
          </Modal.Footer>
        </Modal>
      </div>
    </>
  );
}

export default AddRoutesModalFindJobSeeker;

/*


 <div className="row">
                <CustomDesign
                  label={"Servis Güzergah Başlığı"}
                  required={true}
                  isColumn={true}
                  columnClassName={"col-xs-12 col-md-12"}
                  child={
                    <>
                      <input
                        type="text"
                        id="inputdropdownmodal"
                        className="form-control"
                        rules={{ required: true }}
                        name="servisRouteName"
                        {...register("servisRouteName", { required: true })}
                        placeholder="Güzergah Başlığı"
                      ></input>
                      {errors.servisRouteName && (
                        <div className="text-danger fs-12">
                          Bu alan Boş Olamaz
                        </div>
                      )}
                    </>
                  }
                />
              </div>
              <div className="row">
                {props.countryList.countryList && (
                  <CustomDesign
                    label={"Ülke"}
                    required={true}
                    isColumn={true}
                    columnClassName={"col-xs-6 col-md-6"}
                    child={
                      <BitonisSingleDropdown
                        items={
                          props.countryList.countryList &&
                          props.countryList.countryList.map(({ id, name }) => ({
                            value: id,
                            label: name,
                          }))
                        }
                        register={register}
                        watch={watch}
                        companyClassName={"countryId"}
                        control={control}
                        setValue={setValue}
                        errors={errors}
                        placeholder="Ülke Seç"
                        onValueChanged={(e) => {
                          console.log(e);
                          setValue("cityId", "");
                          setValue("countyId", "");
                          setValue("districts", "");
                        }}
                        required={true}
                        getValues={getValues}
                        errorMessage={"Ülke Boş Olamaz"}
                      />
                    }
                  />
                )}

                {cityList && (
                  <CustomDesign
                    label={"İl/Şehir"}
                    required={true}
                    isColumn={true}
                    columnClassName={"col-xs-6 col-md-6"}
                    child={
                      <BitonisSingleDropdown
                        items={
                          cityList &&
                          cityList.map(({ id, name }) => ({
                            value: id,
                            label: name,
                          }))
                        }
                        register={register}
                        watch={watch}
                        companyClassName={"cityId"}
                        control={control}
                        setValue={setValue}
                        errors={errors}
                        placeholder="İl/Şehir Seç"
                        onValueChanged={(e) => {
                          console.log(e);
                          setValue("countyId", "");
                          setValue("districts", "");
                          setSemtNewList([]);
                          if (e) {
                            handleCity(e);
                            setSemtNewList([]);
                            var country = watch("countryId");
                            if (country && e) {
                              var adres = country.label + "/" + e.label;
                              getLatLngApi(adres);
                            }
                          }
                        }}
                        required={true}
                        getValues={getValues}
                        errorMessage={"İl/Şehir Boş Olamaz"}
                      />
                    }
                  />
                )}
              </div>

              {showCountiesAndDistricts && (
                <div className="row">
                  {props.countyList.countyList &&
                    props.countyList.countyList.length > 0 && (
                      <CustomDesign
                        label={"İlçe"}
                        required={true}
                        isColumn={true}
                        columnClassName={"col-xs-6 col-md-6"}
                        child={
                          <BitonisMultiDropdown
                            items={
                              props.countyList.countyList &&
                              props.countyList.countyList.map(
                                ({ id, name }) => ({
                                  value: id,
                                  label: name,
                                })
                              )
                            }
                            register={register}
                            watch={watch}
                            companyClassName={"countyId"}
                            control={control}
                            setValue={setValue}
                            errors={errors}
                            errorMessage={"İlçe Boş Olamaz "}
                            placeholder="İlçe Seç"
                            onValueChanged={(value) => {
                              console.log(value);
                              if (value) {
                                const selectedValues = value.map(
                                  (option) => option.value
                                );
                                if (selectedValues.includes(990)) {
                                  //  alert("value* * * " + JSON.stringify(value));
                                  setValue("districts", "");
                                  setSemtNewList([]);
                                } else {
                                  handleCounty(value);
                                  setValue("districts", "");
                                }
                              }
                            }}
                            required={true}
                            selectedId={990}
                            getValues={getValues}
                          />
                        }
                      />
                    )}
                  {semtNewList == undefined ||
                    (semtNewList == null ||
                    JSON.stringify(semtNewList) == "[]" ? (
                      <div></div>
                    ) : (
                      <CustomDesign
                        label={"Semt/Mahalle"}
                        required={true}
                        isColumn={true}
                        columnClassName={"col-xs-6 col-md-6"}
                        child={
                          <BitonisMultiDropdown
                            items={
                              semtNewList &&
                              semtNewList.map(({ id, name }) => ({
                                value: id,
                                label: name,
                              }))
                            }
                            register={register}
                            watch={watch}
                            companyClassName={"districts"}
                            control={control}
                            setValue={setValue}
                            errors={errors}
                            errorMessage={""}
                            placeholder="Semt/Mahalle Seç"
                            onValueChanged={(value) => {
                              console.log(value);
                              if (value) {
                                var country = watch("countryId");
                                var city = watch("cityId");
                                var county = watch("countyId");
                                if (country && city && county && value) {
                                  var countyLabel = "";
                                  if (county && county.length) {
                                    countyLabel = county[0].label;
                                  }
                                  var distLabel = "";
                                  if (value && value.length) {
                                    distLabel = value[0].label;
                                  }
                                  var adres =
                                    country.label +
                                    "/" +
                                    city.label +
                                    "/" +
                                    countyLabel +
                                    "/" +
                                    distLabel;
                                  getLatLngApi(adres);
                                }
                              }
                            }}
                            required={false}
                            getValues={getValues}
                          />
                        }
                      />
                    ))}
                </div>
              )}


              */