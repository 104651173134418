import React, { useEffect, useState } from "react";
import { DashboardServices } from "../../../../../../../services/DashboardServices";
import NotificationItem from "../Common/NotificationItem";
import LoadingDotsAnimation from "../../../../../../widgets/LoadingDotsAnimation";
import { useDispatch, useSelector } from "react-redux";
import { setNotificationCount } from "../../../../../../../redux/redux-toolkit/notificationRedux";
import NotificationNotFound from "../Common/NotificationNotFound";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { setCurrentTab } from "../../../../../../../redux/redux-toolkit/filterMatch";
import { Link } from "react-router-dom";
import Pagination from "@mui/material/Pagination";
import { setCurrentDemandVideoCvTab } from "../../../../../../../redux/redux-toolkit/demandVideoCvTab";
import { setDemandMatchOfficeInterViewTab } from "../../../../../../../redux/redux-toolkit/demandMatchOfficeInterView";
import { setDemandConferencesMeetTab } from "../../../../../../../redux/redux-toolkit/demandConferencesMeet";
import { setCurrentTabHire } from "../../../../../../../redux/redux-toolkit/selectedHireTab";
import { setCurrentTabNotHire } from "../../../../../../../redux/redux-toolkit/selectedNotHireTab";
import { setCurrentAllTab } from "../../../../../../../redux/redux-toolkit/filterAllShow";

const UnreadNotification = () => {
  const dispatch = useDispatch();

  const history = useHistory();
  const [pageNumber, setPageNumber] = useState(1);
  const [pageSize, setPageSize] = useState(12); // Default pageSize
  const [totalItemsCount, setTotalItemsCount] = useState(0);
  const [UnReadNotificationList, setUnReadNotificationList] = useState([]);
  const { notificationCount } = useSelector((state) => state.notification);
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    fetchData();
  }, [pageNumber]);

  const [paginationSize, setPaginationSize] = useState(5);
  const fetchData = () => {
    setIsLoading(true);
    DashboardServices.getNotification(pageNumber, false).then(
      (notification) => {
        setIsLoading(false);
        if (notification && notification.succeeded === true) {
          const getData = notification.data.pagedData || [];
          setUnReadNotificationList(getData);
        }
      }
    );
  };

  const handleChange = (event, value) => {
    setPageNumber(value);
  };

  if (isLoading) {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
        }}
      >
        <LoadingDotsAnimation />
      </div>
    );
  }

  return (
    <div
      style={{
        marginLeft: "10px",
        marginRight: "10px",
      }}
    >
      {UnReadNotificationList && UnReadNotificationList.length > 0 ? (
        UnReadNotificationList.map((notification, index) => (
          <NotificationItem
            key={notification.eventNotificationId}
            notification={notification}
            isRead={false}
            onTap={() => {
              openDetailPage(notification, index);
            }}
          />
        ))
      ) : (
        <NotificationNotFound />
      )}
      <div
        className="card"
        style={{
          marginTop: "10px",
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          justifyItems: "center",
          alignContent: "center",
          alignItems: "center",
          marginLeft: "10px",
          marginRight: "10px",
          marginTop: "50px",
        }}
      >
        <Pagination
          count={paginationSize}
          variant="outlined"
          color="primary"
          page={pageNumber}
          onChange={handleChange}
        />
      </div>
    </div>
  );

  function openDetailPage(notification, index) {
    const status = notification.notificationType;
    const candidateId = notification.candidateId;
    const matchId = notification.matchId;
    makeRead(notification, index);

    if (status === 11) {
      dispatch(setDemandConferencesMeetTab(1));
      history.push("/matchVideoConferencesMeets");
    } else if (status === 19) {
      dispatch(setDemandConferencesMeetTab(0));
      history.push("/matchVideoConferencesMeets");
    } else if (status === 11) {
      dispatch(setDemandConferencesMeetTab(1));
      history.push("/matchVideoConferencesMeets");
    } else if (status === 15) {
      dispatch(setDemandConferencesMeetTab(0));
      history.push("/matchVideoConferencesMeets");
    } else if (status === 20) {
      dispatch(setDemandConferencesMeetTab(0));
      history.push("/matchVideoConferencesMeets");
    } else if (status === 22) {
      dispatch(setDemandConferencesMeetTab(1));
      history.push("/matchVideoConferencesMeets");
    } else if (status === 12) {
      dispatch(setDemandMatchOfficeInterViewTab(1));
      history.push("/matchInterviewMeets");
    } else if (status === 16) {
      dispatch(setDemandMatchOfficeInterViewTab(0));
      history.push("/matchInterviewMeets");
    } else if (status === 3) {
      dispatch(setDemandMatchOfficeInterViewTab(0));
      history.push("/matchInterviewMeets");
    } else if (status === 21) {
      dispatch(setDemandMatchOfficeInterViewTab(0));
      history.push("/matchInterviewMeets");
    } else if (status === 4) {
      dispatch(setCurrentDemandVideoCvTab(1));
      history.push("/matchVideoRequest");
    } else if (status === 23 || status === 24) {
      //demand all show

      if (notification.positionTypeId === 1) {
        dispatch(setCurrentAllTab(0));
        history.push({
          pathname: "/demands/allShow",
        });
      } else {
        dispatch(setCurrentAllTab(1));
        history.push({
          pathname: "/demands/allShow",
        });
      }
    } else if (status === 1) {
      if (notification.positionTypeId === 1) {
        dispatch(setCurrentTab(0));
        history.push({
          pathname: "/matchShow",
        });
      } else if (notification.positionTypeId == 2) {
        dispatch(setCurrentTab(1));
        history.push({
          pathname: "/matchShow",
        });
      } else if (notification.positionTypeId == 3) {
        history.push({
          pathname: "/demands/internAllShow",
        });
      }
    }
  }

  function makeRead(notification, index) {
    DashboardServices.changeStatusNotification(
      notification.eventNotificationId
    ).then((resNotification) => {
      if (resNotification && resNotification.succeeded === true) {
        const updatedList = [...UnReadNotificationList];
        updatedList.splice(index, 1);
        setUnReadNotificationList(updatedList);
        dispatch(setNotificationCount(updatedList.length));
      }
    });
  }
};

export default UnreadNotification;
