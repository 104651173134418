import React, { useEffect, useMemo, useRef, useState } from "react";
import { Controller, useForm } from "react-hook-form";

import Modal from "react-bootstrap/Modal"; // Varsayılan olarak React Bootstrap Modal bileşeni kullanılıyor
import Select from "react-select";
import { Alert, Button } from "react-bootstrap";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import api from "../../../../../../../../../utility/Utils";
import departmentListGetsNotFaculty from "../functions/departmentListGetsNotFaculty";
import facultyDepartmentListGets from "../functions/facultyDepartmentListGets";
import { customStyles } from "../../../../../../../../customStyleInline";
import BitonisMultiDropdown from "../../../../../../../../widgets/BitonisMultiDropdown";
import BitonisSingleDropdown from "../../../../../../../../widgets/BitonisSingleDropdown";
import CustomDesign from "../../../../../../../../widgets/CustomDesign";
import CustomButton from "../../../../../../../../widgets/buttons/CustomButton";
function AddEducationAndEduType({
  show,
  handleClose,
  job,
  onSubmit,
  props,

  inputStyle,
  register,
  handleSubmit,
  control,
  setValue,
  errors,
  watch,
  isValid,
  labelStyle,
  education,
  educationListRef,
  facultyDepAndGroupIdsRef,
  reset,
  getValues,
}) {
  const [universty, setUniversty] = useState(null);
  const [section, setSection] = useState(null);
  const [sectionList, setSectionList] = useState([]);
  const [departmentGroupId, setDepartmentGroupId] = useState(null);
  const [visibleLisances, setVisibleLisances] = useState(null);
  const educationListRefTemp = useRef([]);
  const educationStatus = [
    //kontrol edildi
    { id: 0, name: "Farketmez" },
    { id: 1, name: "İlkokul" },
    { id: 2, name: "Ortaokul" },
    { id: 3, name: "Lise" },
    { id: 4, name: "Ön Lisans" },
    { id: 5, name: "Lisans" },
    { id: 6, name: "Yüksek Lisans" },
    { id: 7, name: "Doktora" },
  ];
  useEffect(() => {
    if (show) {
      facultyDepAndGroupIdsRef.current = [];
      setValue("universityId", "");
      setValue("section", "");

      var eduType = watch("educationTypes");
      if (
        (eduType && eduType.value === 4) ||
        (props.job.job.jobEducations[0] &&
          props.job.job.jobEducations[0].educationType === 4) ||
        (eduType && eduType.value === 5) ||
        (props.job.job.jobEducations[0] &&
          props.job.job.jobEducations[0].educationType === 5) ||
        (eduType && eduType.value === 6) ||
        (props.job.job.jobEducations[0] &&
          props.job.job.jobEducations[0].educationType === 6) ||
        (eduType && eduType.value === 7) ||
        (props.job.job.jobEducations[0] &&
          props.job.job.jobEducations[0].educationType === 7)
      ) {
        setVisibleLisances(true);
      } else {
        setVisibleLisances(false);
      }
    }
  }, [show]);
  var university = watch("universityId");
  return (
    <>
      <div>
        <Modal
          show={show}
          onHide={handleClose}
          backdrop="static"
          keyboard={false}
          aria-labelledby="contained-modal-title-vcenter"
          centered
          className="custom-modal-width"
        >
          {" "}
          <Modal.Header
            closeButton
            style={{ borderBottom: "none", justifyContent: "center" }}
          >
            <Modal.Title
              aria-labelledby="contained-modal-title-vcenter"
              centered
            >
              <h3 style={{ color: "#001542" }}>Eğitim</h3>
            </Modal.Title>
          </Modal.Header>
          <div className="">
            <Modal.Body>
              <form
                onSubmit={(e) => {
                  e.preventDefault();
                  handleSubmit(onSubmit)();
                  if (isValid) {
                    handleClose();
                  }
                }}
              >
                <CustomDesign
                  label={"Eğitim Durumu"}
                  required={true}
                  isColumn={true}
                  child={
                    <BitonisSingleDropdown
                      items={
                        educationStatus &&
                        educationStatus.map(({ id, name }) => ({
                          value: id,
                          label: name,
                        }))
                      }
                      register={register}
                      watch={watch}
                      companyClassName={"educationTypes"}
                      control={control}
                      setValue={setValue}
                      errors={errors}
                      placeholder="Eğitim Durumu Seçin"
                      onValueChanged={(e) => {
                        console.log(e);
                        if (e) {
                          if (
                            e.value === 4 ||
                            e.value === 5 ||
                            e.value === 6 ||
                            e.value === 7
                          ) {
                            setVisibleLisances(true);
                          } else {
                            educationListRefTemp.current = [];
                            setVisibleLisances(false);
                          }
                        }
                      }}
                      required={true}
                      getValues={getValues}
                      errorMessage={"Eğitim durumu Boş geçilemez"}
                    />
                  }
                />

                {visibleLisances === false ? (
                  <div></div>
                ) : (
                  <div>
                    <CustomDesign
                      label={"Üniversite"}
                      required={true}
                      isColumn={true}
                      child={
                        <BitonisSingleDropdown
                          items={
                            props.universityListWhite &&
                            props.universityListWhite.universityListWhite &&
                            props.universityListWhite.universityListWhite.map(
                              ({ id, name }) => ({
                                value: id,
                                label: name,
                              })
                            )
                          }
                          register={register}
                          watch={watch}
                          companyClassName={"universityId"}
                          control={control}
                          setValue={setValue}
                          errors={errors}
                          placeholder="Üniversite Seçin"
                          onValueChanged={(e) => {
                            console.log(e);
                            if (e) {
                              setValue(`section`, "");
                              setSection(null);
                              setDepartmentGroupId(null);
                              if (e.value === 233 || e.value === 234) {
                                departmentListGetsNotFaculty(setSectionList);
                              } else {
                                facultyDepartmentListGets(
                                  e.value,
                                  setSectionList
                                );
                              }
                            }
                          }}
                          required={true}
                          getValues={getValues}
                          errorMessage={"Üniversite Boş geçilemez"}
                        />
                      }
                    />

                    {sectionList && (
                      <CustomDesign
                        label={"Bölüm"}
                        required={true}
                        isColumn={true}
                        child={
                          <BitonisMultiDropdown
                            items={
                              sectionList &&
                              sectionList.map(
                                ({ id, name, departmentGroupId }) => ({
                                  value: id,
                                  label: name,
                                  departmentGroupId: departmentGroupId,
                                })
                              )
                            }
                            register={register}
                            watch={watch}
                            companyClassName={"section"}
                            control={control}
                            setValue={setValue}
                            errors={errors}
                            errorMessage={"Bölüm Boş geçilemez"}
                            placeholder="Bölüm"
                            getValues={getValues}
                            onValueChanged={(e) => {
                              console.log(e);
                              setSection(e);
                              var facultyDepAndGroupIds = [];
                              facultyDepAndGroupIdsRef.current =
                                facultyDepAndGroupIds;
                              var university = watch("universityId");

                              if (e) {
                                const selectedValues = e.map(
                                  (option) => option.value
                                );

                                if (university.value == 233) {
                                  if (selectedValues.includes(698)) {
                                    const selectedOption = e.find(
                                      (option) => option.value === 698
                                    );

                                    if (selectedOption) {
                                      [selectedOption].map((val) => {
                                        facultyDepAndGroupIds.push({
                                          facultyDepartmentGroupId: val.value,
                                          facultyDepartmentId: 23069,
                                          facultyDepartmentName: val.label,
                                        });
                                      });
                                    } else {
                                    }
                                  } else {
                                    e.map((val) => {
                                      facultyDepAndGroupIds.push({
                                        facultyDepartmentGroupId: val.value,
                                        facultyDepartmentId: 23069,
                                        facultyDepartmentName: val.label,
                                      });
                                    });
                                  }
                                } else if (university.value == 234) {
                                  if (selectedValues.includes(698)) {
                                    const selectedOption = e.find(
                                      (option) => option.value === 698
                                    );
                                    if (selectedOption) {
                                      [selectedOption].map((val) => {
                                        facultyDepAndGroupIds.push({
                                          facultyDepartmentGroupId: val.value,
                                          facultyDepartmentId: 25091,
                                          facultyDepartmentName: val.label,
                                        });
                                      });
                                    } else {
                                    }
                                  } else {
                                    e.map((val) => {
                                      facultyDepAndGroupIds.push({
                                        facultyDepartmentGroupId: val.value,
                                        facultyDepartmentId: 25091,
                                        facultyDepartmentName: val.label,
                                      });
                                    });
                                  }
                                } else {
                                  if (selectedValues.includes(23069)) {
                                    const selectedOption = e.find(
                                      (option) => option.value === 23069
                                    );
                                    if (selectedOption) {
                                      [selectedOption].map((val) => {
                                        facultyDepAndGroupIds.push({
                                          facultyDepartmentGroupId:
                                            val.departmentGroupId,
                                          facultyDepartmentId: val.value,
                                          facultyDepartmentName: val.label,
                                        });
                                      });
                                    } else {
                                    }
                                  } else {
                                    e.map((val) => {
                                      facultyDepAndGroupIds.push({
                                        facultyDepartmentGroupId:
                                          val.departmentGroupId,
                                        facultyDepartmentId: val.value,
                                        facultyDepartmentName: val.label,
                                      });
                                    });
                                  }
                                }
                                facultyDepAndGroupIdsRef.current =
                                  facultyDepAndGroupIds;
                              } else {
                                var facultyDepAndGroupIds = [];
                              }
                            }}
                            required={true}
                            selectedId={
                              (university && university.value == 233) ||
                              (university && university.value == 234)
                                ? 698
                                : 23069
                            }
                          />
                        }
                      />
                    )}
                  </div>
                )}
              </form>
            </Modal.Body>
            <Modal.Footer
              style={{
                display: "flex",
                flexDirection: "Row",
                alignItems: "center",
                justifyContent: "center",
                alignContent: "center",
                borderTop: "none",
                margin: "0px",
              }}
            >
              <form
                onSubmit={(e) => {
                  e.preventDefault();
                  handleSubmit(onSubmit)();
                  const universty = watch("universityId");
                  const section = watch("section");
                  const getEducationTypes = watch("educationTypes");

                  if (getEducationTypes.value < 4) {
                    educationListRef.current = [];
                    facultyDepAndGroupIdsRef.current = [];
                    handleClose();
                  } else {
                    if (
                      universty &&
                      section &&
                      facultyDepAndGroupIdsRef.current
                    ) {
                      isValid = true;
                    }
                    if (isValid) {
                      if (universty && section) {
                        var addMap = {
                          universityId: universty.value,
                          universityName: universty.label,
                          facultyDepAndGroupIds:
                            facultyDepAndGroupIdsRef.current,
                        };

                        educationListRef.current = [
                          ...educationListRef.current,
                          addMap,
                        ];
                      }
                      handleClose();
                    } else {
                    }
                  }
                }}
              >
                  <CustomButton title={"Kaydet"}  width={{
                    xs: "350px",
                    sm: "350px",
                    md: "350px",
                    lg: "350px",
                    xl: "350px",
                  }} />
              </form>
            </Modal.Footer>
          </div>
        </Modal>
      </div>
    </>
  );
}

export default AddEducationAndEduType;
