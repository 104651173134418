import React from "react";
import { DemandsServices } from "../../../../../../../../services";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { ToastContainer, toast } from "react-toastify";

const InternActionButtons = ({
  matchId,
  internMatchDetail,
  companyId,
  employerId,
  internMatchStatus,
  positionName,
  jobId,
}) => {
  const history = useHistory();
  function interneEmployerMatchApprove(internStatus) {
    var sendBody = {
      matchId: matchId,
      internMatchStatus: internStatus,
      description: internStatus.toString(),
    };
    DemandsServices.interneEmployerMatchApprove(sendBody).then(
      (response) => {
        if (response && response.succeeded === true) {
          if (internStatus == 2) {
            toast.success("Adayı Başarılı Bir Şekilde Staja Aldınız", {
              autoClose: 2000,
              onClose: () => {
                setTimeout(() => {
                  const dataMap = new Map();
                  dataMap.set("jobId", jobId);
                  dataMap.set("positionName", positionName);

                  history.push({
                    pathname: "/demands/internCurrentDemandMatchShow",
                    state: { dataMap },
                  });
                }, 100);
              },
            });
          } else {
            toast.success("Adayı Staja Almadınız", {
              autoClose: 2000,
              onClose: () => {
                setTimeout(() => {
                  const dataMap = new Map();
                  dataMap.set("jobId", jobId);
                  dataMap.set("positionName", positionName);

                  history.push({
                    pathname: "/demands/internCurrentDemandMatchShow",
                    state: { dataMap },
                  });
                }, 100);
              },
            });
          }
        } else {
          toast.success("Hata Oluştu", {
            autoClose: 2000,
            onClose: () => {
              setTimeout(() => { }, 100);
            },
          });
        }
      },
      (error) => { }
    );
  }

  var contentActionBody;
  if (internMatchStatus && internMatchStatus === 3) {
    contentActionBody = (
      <>
        <InternHireButton
          onTap={() => {
            interneEmployerMatchApprove(2);
          }}
        />
      </>
    );
  } else if (internMatchStatus && internMatchStatus === 2) {
    contentActionBody = (
      <>
        <NotInternHireButton
          onTap={() => {
            interneEmployerMatchApprove(3);
          }}
        />
      </>
    );
  } else {
    contentActionBody = <div></div>;
  }
  return (
    <div>
      <div
        style={{
          height: "20px",
        }}
      ></div>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
          alignItems: "center",
          margin: "0px",
          padding: "0px",
          marginTop: "0px",
          marginBottom: "20px",
        }}
      >
        {contentActionBody}
      </div>
    </div>
  );
};

const InternHireButton = ({ onTap }) => {
  return (
    <button
      onClick={onTap}
      className="btn btn-primary"
      style={{
        borderRadius: "8px",
        width: "70%",
        margin: "0px 10px 10px 10px",
      }}
    >
      Staja Al
    </button>
  );
};
const NotInternHireButton = ({ onTap }) => {
  return (
    <button
      onClick={onTap}
      className="btn btn-primary"
      style={{
        borderRadius: "8px",
        width: "70%",
        margin: "0px 10px 10px 10px",
        backgroundColor: "white",
        border: "1px solid #001542",
        color: "#001542",
      }}
    >
      Staja Alma
    </button>
  );
};
export default InternActionButtons;
