import React, { useEffect, useState } from "react";
import "../../../../../../../css/bitoniscss/companyWidthAddedRoutes.css";
import { Button } from "react-bootstrap";
import documentImages from "../../../../../../../images/documentNotFound.svg";
import DetailEditDocumentEmployer from "./DetailEditDocumentEmployer";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import api from "../../../../../../../utility/Utils";
import useIsMobile from "../../../../../../hooks/useIsMobile";
import LoadingDotsAnimation from "../../../../../../widgets/LoadingDotsAnimation";
import PageInfoTitle from "../../../../../../widgets/PageInfoTitle";
import EditIconWidget from "../../../../../../widgets/EditIconWidget";
import { Box } from "@mui/material";
import CustomButton from "../../../../../../widgets/buttons/CustomButton";

const BlueCollarDocuments = ({ type,  }) => {
  const isMobile = useIsMobile();
  const [addedDocument, setAddedDocument] = useState(false);
  useEffect(() => {
    if (type) {
      addedDocumentApi();
    }
  }, [type]);
  const [showAdd, setShowAdd] = useState(false);
  const handleCloseAdd = () => {
    setShowAdd(false);
  };
  function addedDocumentApi() {
    api
      .get("employer/hirechecklist/1")

      .then((response) => {
        //  alert("response ** " + JSON.stringify(response));
        if (response.succeeded == true) {
          //  alert("response* * * " + JSON.stringify(response.data));
          if (response.data.length > 0) {
            setAddedDocument([]);
            response.data.map((value) => {
              setAddedDocument((prev) => [...prev, value]);
            });
          } else {
            setAddedDocument(null);
          }
        } else {
          setAddedDocument(null);
        }
      })
      .catch((err) => {
        setAddedDocument(false);
      });
  }

  if (addedDocument == false) {
    return <div >
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
        }}
      >
        <LoadingDotsAnimation fontSize={40} />
      </div>
    </div>
  } else {
    return (
      <>
        <ToastContainer
          position="top-right"
          autoClose={2000}
          hideProgressBar={false}
          newestOnTop
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
        {addedDocument == null ? (
          <div>
            <div
              class=""
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                alignContent: "center",
                justifyContent: "center",
                margin: "10%",
              }}
            >
              <img
                src={documentImages}
                style={{
                  height: isMobile ? "60px" : "100px",
                  textAlign: "center",
                }}
              />
              <p
                style={{
                  color: "#001542",
                  margin: "10px",
                  fontSize: "15px",
                }}
              >
                Eklenmiş Evrak bulunamadı.
              </p>
              <CustomButton
                onTap={
                  (val) => {
                    setShowAdd(true);
                  }
                }
                title={"Yeni Evrak Ekle"}
                width={{
                  xs: "200px",
                  sm: "230px",
                  md: "230px",
                  lg: "250px",
                  xl: "250px",
                }}
                height={40}
              />

            </div>
          </div>
        ) : (
          <div>
            <div className="card" style={{
              marginTop: "0px",
              paddingTop: "0px"
            }}>
              <div
                className="card-header"
                style={{
                  borderBottom: "none", marginTop: "0px",
                  paddingTop: "0px",
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "flex-end",
                  alignContent: "flex-end",
                  alignItems: "flex-end"
                }}
              >



                <Box
                  sx={{

                    display: "flex",
                    alignItems: "center",
                    paddingRight: "0px",
                    marginBottom: "0px",
                    paddingBottom: "0px"
                  }}
                >
                  <EditIconWidget
                    size={20}
                    onTap={() => {
                      setShowAdd(true);
                    }}
                    showContainerEdit={false}
                    showEditText={false}
                  />
                </Box>


              </div>
              <div
                className="card-body"
                style={{
                  marginLeft: "10px",
                  color: "",
                  marginTop: "0px",
                  paddingTop: "0px",
                  backgroundColor: "white"
                }}
              >
                {addedDocument &&
                  addedDocument.map((doc, index) => {
                    return (
                      <div
                        style={{
                          marginTop: index == 0 ? "0px" : "5px",
                          backgroundColor: "#F5F5F5",
                          color: "#001542",
                          borderRadius: "8px",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "flex-start",
                          alignContent: "center",
                          flexDirection: "column",
                          fontWeight: 550,
                          width: "100%"

                        }}
                      >
                        <p
                          style={{
                            paddingLeft: "10px",
                            textAlign: "center",
                            paddingTop: "15px"
                          }}
                        >
                          {doc.hiringDocument.name}
                        </p>
                      </div>
                    );
                  })}
              </div>
            </div>
          </div>
        )}
        <DetailEditDocumentEmployer
          show={showAdd}
          handleClose={handleCloseAdd}
          type={1}
          addedDocument={addedDocument}
        />
      </>
    );
  }
};

export default BlueCollarDocuments;
