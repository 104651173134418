import React from "react";
import NewMatchItem from "./NewMatchItem";
import WaitingMatchItem from "./WaitingMatchItem";
import WaitingAtToWorkPlaceMeetingApprovalItem from "./WaitingAtToWorkPlaceMeetingApprovalItem";
import ConfirmAtToWorkPlaceMeetingApprovalItem from "./ConfirmAtToWorkPlaceMeetingApprovalItem";
import AdditionalWaitingVideoCvItem from "./AdditionalWaitingVideoCvItem";
import MeetingRejectedItem from "./MeetingRejectedItem";
import WaitingVideoConferenceMeetingDayItem from "./WaitingVideoConferenceMeetingDayItem";
import ConfirmVideoConferenceMeetingDayItem from "./ConfirmVideoConferenceMeetingDayItem";
import ConfirmIncomingMeetingDayItem from "./ConfirmIncomingMeetingDayItem";
import HireItem from "../../../Hire/HireItem";
import NotHireItem from "../../../NotHire/NotHireItem";
import ConfirmMeetingDayItem from "./confirmMeetingDayItem";

const ItemByStatus = ({ matchData, comeCurrentDemand }) => {
  switch (matchData.status) {
    case 0:
      return <NewMatchItem matchData={matchData} />;
    case 1:
      return <WaitingMatchItem matchData={matchData} />;
    case 2:
      return <WaitingAtToWorkPlaceMeetingApprovalItem matchData={matchData} />;
    case 3:
    //return <ConfirmAtToWorkPlaceMeetingApprovalItem matchData={matchData} />;
      return (
        <ConfirmMeetingDayItem
          matchData={matchData}
          title={"İşyerinde Görüşme Günü Onaylandı"}
        />
      );
    case 4:
      return <AdditionalWaitingVideoCvItem matchData={matchData} />;
    case 5:
      return (
        <MeetingRejectedItem
          matchData={matchData}
          title={"Görüşme İş Arayan Tarafından Reddedildi"}
        />
      );
    case 6:
      return (
        <div>
          {comeCurrentDemand === true ? (
            <HireItem
              candidateNameSurname={matchData.candidateNameSurname}
              positionName={matchData.positionName}
              status={matchData.status}
              candidateId={matchData.candidateId}
            />
          ) : (
            <div></div>
          )}
        </div>
      );
    case 7:
      return (
        <div>
          {comeCurrentDemand === true ? (
            <NotHireItem
              candidateNameSurname={matchData.candidateNameSurname}
              positionName={matchData.positionName}
              status={matchData.status}
              candidateId={matchData.candidateId}
            />
          ) : (
            <div></div>
          )}
        </div>
      );
    case 8:
      return <WaitingVideoConferenceMeetingDayItem matchData={matchData} />;
    case 9:
      return <ConfirmVideoConferenceMeetingDayItem matchData={matchData} />;
    case 10:
      return (
        <ConfirmMeetingDayItem
          matchData={matchData}
          title={"Görüşme Günü Onaylandı"}
        />
      );
    case 12:
      return <NewMatchItem matchData={matchData} />;
    case 14:
      return (
        <MeetingRejectedItem
          matchData={matchData}
          title={"Görüşme Günü Değiştirildi Onay Bekliyor"}
        />
      );
    case 15:
      return (
        <MeetingRejectedItem
          matchData={matchData}
          title={"Video Konferans Günü Değiştirildi Onay Bekliyor"}
        />
      );
    case 16:
      return (
        <ConfirmIncomingMeetingDayItem
          matchData={matchData}
          title={"Görüşme Günü Onaylandı"}
        />
      );
    case 17:
      return (
        <MeetingRejectedItem
          matchData={matchData}
          title={"İş Arayan Tarafından Görüşme Reddedildi"}
        />
      );
    case 18:
      return (
        <MeetingRejectedItem
          matchData={matchData}
          title={"Görüşme Gününü Reddettiniz"}
        />
      );
    case 19:
      return (
        <MeetingRejectedItem
          matchData={matchData}
          title={"Görüşme Gününü Reddettiniz"}
        />
      );
    case 20:
      return (
        <MeetingRejectedItem
          matchData={matchData}
          title={"Görüşme Sistem Tarafından Reddedildi"}
        />
      );
    case 21:
      return (
        <MeetingRejectedItem
          matchData={matchData}
          title={"Sistem Tarafından Reddedildi"}
        />
      );
    case 17:
      return (
        <MeetingRejectedItem
          matchData={matchData}
          title={"İş Arayan Tarafından Görüşme Reddedildi"}
        />
      );
    default:
      return <div></div>;
  }
};

export default ItemByStatus;
