import { DropListWhiteAction } from "../../../../../../action";

export default function sendDemandWhite(
  form,
  educationList,
  languageList,
  selectedRouteList,
  props,
  history,
  checkProgram,
  checkCertificate,
  checkCompetence,
  secondsRef
) {
  const companyId = form.companyId.value;
  const companyLocationId = form.companyLocationId.value;
  const positionId = form.positionId.value;
  const jobExperienceRanges = form.jobExperienceRanges.map((val) => val.value);
  const workTypes = form.workTypes.map((val) => val.value);
  const educationTypes = form.educationStatus.value;
  const salaryRanges = form.salaryRanges.map((val) => val.value);
  const ageRanges = form.ageRanges.map((val) => val.value);
  const employeeCount = form.employeeCount.value;
  const weekendWork = form.weekendWork.value;
  const businessTravelRestrictions = form.businessTravelRestrictions.map(
    (val) => val.value
  );
  const nationalityId = form.nationalityId.value;
  const gender = form.gender.value;
  var militaryStatus = "";
  if (form.militaryStatus === undefined || form.militaryStatus === "") {
    militaryStatus = "";
  } else {
    militaryStatus =
      form.militaryStatus === "" ? "" : form.militaryStatus.value;
  }
  const smoking = form.smokerApply.value;
  const maritalStatus = form.maritalStatus.value;
  const drivingLicence = form.drivingLicence.value;
  var drivingLicenceTypes = [];
  if (form.drivingLicenceTypes == [] || form.drivingLicenceTypes == undefined) {
    drivingLicenceTypes = [];
  } else {
    drivingLicenceTypes = form.drivingLicenceTypes.map((val) => val.value);
  }
  var jobPreferredUniversities = [];
  var eduPush = [];
  //üniversite ve bölüm fazladan seçilmemiş eklenmişler var
  if (educationList.length > 0) {
    const getEduData = [...educationList];
    if (getEduData.length > 0) {
      const newAddEdu = getEduData.map((data) => {
        //  alert("data else " + JSON.stringify(data));

        var element = {
          universityId: data.universityId,
          facultyId: parseInt(data.universityId) === 234 ? 3244 : 3237,
          facultyDepAndGroupIds: data.facultyDepAndGroupIds.map((edu) => {
            return {
              facultyDepartmentId: edu.facultyDepartmentId,
              facultyDepartmentGroupId: edu.facultyDepartmentGroupId,
            };
          }),
        };
        return element;
      });

      eduPush.push(newAddEdu);
    }
  }
  if (eduPush.length > 0) {
    jobPreferredUniversities = eduPush[0];
  }

  var jobPrograms = [];
  var jobCertificates = [];
  var jobCompetences = [];
  if (
    form.jobPrograms === undefined ||
    form.jobPrograms == "" ||
    form.jobPrograms == null
  ) {
    jobPrograms = [];
  } else {
    jobPrograms =
      form.jobPrograms === ""
        ? []
        : form["jobPrograms"].map((item) => item.value);
  }
  if (
    form.jobCertificates === undefined ||
    form.jobCertificates == "" ||
    form.jobCertificates == null
  ) {
    jobCertificates = [];
  } else {
    jobCertificates =
      form.jobCertificates === ""
        ? []
        : form["jobCertificates"].map((item) => item.value);
  }
  if (
    form.jobCompetences === undefined ||
    form.jobCompetences == "" ||
    form.jobCompetences == null
  ) {
    jobCompetences = [];
  } else {
    jobCompetences =
      form.jobCompetences === ""
        ? []
        : form["jobCompetences"].map((item) => item.value);
  }
  const jobForeignLanguages = [];
  if (languageList.length > 0) {
    languageList.map((val) => {
      jobForeignLanguages.push({
        languageId: val.languageId.value,
        languageLevelId: val.languageLevelId.value,
      });
    });
  }
  var companyPreferredLocationId = selectedRouteList.map(
    (item) => item.companyPreferredLocationId
  );
  var disabilityId = parseInt(form.disabilityStatus.value.toString());
  var disabilityDegreeListIds = [];
  form.degreeOfDisability == null ||
  form.degreeOfDisability == [] ||
  form.degreeOfDisability === "" ||
  form.degreeOfDisability == undefined ||
  form.degreeOfDisability.toString() == "[]" ||
  form.degreeOfDisability.toString() == "null"
    ? null
    : form.degreeOfDisability.map((val) => {
        disabilityDegreeListIds.push(parseInt(val.value.toString()));
      });
  var sendData = {
    companyId: parseInt(companyId.toString()),
    companyLocationId: parseInt(companyLocationId.toString()),
    positionId: positionId,
    jobExperienceRanges: jobExperienceRanges,
    workTypes: workTypes,
    educationTypes: [educationTypes],
    salaryRanges: salaryRanges,
    ageRanges: ageRanges,
    employeeCount: employeeCount,
    weekendWork: [parseInt(weekendWork.toString())],
    businessTravelRestrictions: businessTravelRestrictions,
    languageId: null, //dil
    languageLevelId: null,
    nationalityId: parseInt(nationalityId.toString()),
    gender: gender,
    militaryStatus: gender === 1 || gender === 0 ? null : militaryStatus,
    smoking: parseInt(smoking.toString()),
    maritalStatus: maritalStatus,
    drivingLicence: drivingLicence,
    drivingLicenceTypes: drivingLicenceTypes,
    jobPreferredUniversities: jobPreferredUniversities,
    jobPreferredLocations: null,
    jobPrograms: jobPrograms.toString() == "[]" ? null : jobPrograms,
    jobCertificates:
      jobCertificates.toString() == "[]" ? null : jobCertificates,
    jobCompetences: jobCompetences.toString() == "[]" ? null : jobCompetences,
    jobForeignLanguages: jobForeignLanguages,
    isActive: true,
    companyPreferredLocationIds: companyPreferredLocationId,
    disability: disabilityId,
    jobDegreeOfDisabilityIds: disabilityDegreeListIds,
    isAllCertificatesMust:
      jobCertificates.toString() == "[]" ? false : checkCertificate,
    isAllProgramsMust: jobPrograms.toString() == "[]" ? false : checkProgram,
    isAllCompetencesMust:
      jobCompetences.toString() == "[]" ? false : checkCompetence,
  };

  props.dispatch(
    DropListWhiteAction.whiteNewDemand(sendData, history, secondsRef)
  );
}
