import Modal from "react-bootstrap/Modal"; // Varsayılan olarak React Bootstrap Modal bileşeni kullanılıyor
import React, { useEffect, useMemo, useState } from "react";
import { Card } from "react-bootstrap";
import BitonisTabBar from "../../../../../widgets/BitonisTabBar/BitonisTabBar";
import EmployerGivenToCandidate from "./EmployerGivenToCandidate";
import EmployerGivenToBitonis from "./EmployerGivenToBitonis";

const ShowSendEvaluateModal = ({
  show,
  handleClose,
  GivenStarTypeEnum,
  candidateId,
}) => {
  const [activeCurrentTab, setActiveCurrentTab] = useState(0);

  return (
    <div
      onClick={(e) => {
        //bu kısım kesinlikle olmalı
        e.stopPropagation();
        handleClose();
      }}
    >
      <>
        <div>
          <Modal
            show={show}
            onHide={handleClose}
            backdrop="static" // Modal'ın dışına tıklanarak kapanmasını engeller
            keyboard={false} // Escape tuşuna basarak kapanmasını engeller
            aria-labelledby="contained-modal-title-vcenter"
            centered
          >
            <Modal.Header
              closeButton
              onClick={(e) => {
                e.stopPropagation();
              }}
              style={{ borderBottom: "0px" }}
            ></Modal.Header>

            <Modal.Body
              style={{
                marginTop: "0px",
                paddingTop: "0px",
                margin: "0px",
                padding: "0px",
                marginTop:"15px"
              }}
              onClick={(e) => {
                e.stopPropagation();
              }}
            >
              <BitonisTabBar
              inModal={true}
                tabLeftTitle={"İş Arayanı Değerlendir"}
                tabRightTitle={"Bitoniş'i Değerlendir"}
                tabLeftBody={
                  <EmployerGivenToCandidate
                    candidateId={candidateId}
                    givenStarType={GivenStarTypeEnum}
                    handleClose={(v) => {
                      handleClose();
                    }}
                    activeCurrentTab={activeCurrentTab}

                  />
                }
                tabRightBody={
                  <EmployerGivenToBitonis
                    candidateId={candidateId}
                    givenStarType={GivenStarTypeEnum}
                    handleClose={(v) => {
                      handleClose();
                    }}
                  />
                }
                showBorder={false}
                activeTab={activeCurrentTab}
                tabStyle={{
                  fontSize: "0.72rem",
                }}
                onChangeTab={(tab) => {
                  setActiveCurrentTab(tab)
                }}
                show={show}
              />
            </Modal.Body>
          </Modal>
        </div>
      </>
    </div>
  );
};

export default ShowSendEvaluateModal;
