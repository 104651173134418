// dataSlice.js
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  companyRegisterData: null,
};

const companySlice = createSlice({
  name: "companyData",
  initialState,
  reducers: {
    setCompanyData: (state, action) => {
		  state.companyRegisterData = action.payload;
		  //alert("yazılan data " + JSON.stringify(action.payload));
    },
  },
});

export const { setCompanyData } = companySlice.actions;
export default companySlice.reducer;
