import React, { useEffect } from "react";
import { Pagination, PaginationItem, Typography } from "@mui/material";

const PaginationPage = ({
  items,
  page,
  pageSize,
  onChange,
  onTapAllShowButton,
}) => {
 
  return (
    <div>
      {items && items.length > 0 && (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            margin: "10px",
            padding: "10px",
          }}
        >
          <Pagination
            count={Math.ceil(items && items.length / pageSize)}
            page={page}
            onChange={(event, value) => onChange(value)}
            renderItem={(item) => (
              <div>
                {item.type === "page" && (
                  <PaginationItem
                    {...item}
                    key={item}
                    style={{
                      backgroundColor: item.selected ? "#001542" : "#e0e0e0",
                      color: item.selected ? "white" : "#001542",
                      width: "35px", //45px yapılıp borderRadius kaldırılırsa circle olmaz isteğe bağlı dikdörtgende olabilir
                      height: "35px",
                      borderRadius: "50%",
                    }}
                  />
                )}
                {item.type === "previous" && (
                  <PaginationItem
                    {...item}
                    style={{
                      display: page === null ? "none" : "flex",
                    }}
                  />
                )}
                {item.type === "next" && (
                  <PaginationItem
                    {...item}
                    style={{
                      display: page === null ? "none" : "flex",
                    }}
                  />
                )}
              </div>
            )}
          />
          {
            /*
            <button
            style={{
              border: "none",
              borderRadius: "8px",
              padding: "8px",
              height: "35px",
              fontSize: "11px",
              fontWeight: "500",
              marginLeft: "5px",
              backgroundColor:
                page === null
                  ? page === null
                    ? "#001542"
                    : "#e0e0e0"
                  : "#e0e0e0",
              color:
                page === null
                  ? page === null
                    ? "white"
                    : "#001542"
                  : "#001542",
            }}
            onClick={onTapAllShowButton}
          >
            Tümünü Getir
          </button>
          */
          }
        </div>
      )}
    </div>
  );
};

export default PaginationPage;
