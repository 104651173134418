import { DropListAction } from "../../../../../../action";
import { DemandsServices, DropListServices } from "../../../../../../services";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
export default function sendDemandIntern(
  form,
  props,
  selectedRouteList,
  history,
  secondsRef
) {
  const companyId = form.companyId.value;
  const companyLocationId = form.companyLocationId.value;

  const gender = form.gender.value;

  const nationalityId = form.nationalityId.value;

  var companyPreferredLocationId = selectedRouteList.map(
    (item) => item.companyPreferredLocationId
  );

  const sendData = {
    companyId: parseInt(companyId.toString()),
    companyLocationId: parseInt(companyLocationId.toString()),
    gender: gender,
    nationalityId: parseInt(nationalityId.toString()),
    isActive: true,
    internEducationType: form.internEducationType.value,
    internHighSchoolDepartmentId:
      form.internEducationType.value == 3
        ? form.internEducationSection &&
          form.internEducationSection.map((e) => e.value)
        : null,
    facultyDepartmentGroupId:
      form.internEducationType.value == 3
        ? null
        : form.internEducationSection &&
          form.internEducationSection.map((e) => e.value),
    internClassLevel:
      form.internEducationClass &&
      form.internEducationClass.map((e) => e.value),
    internJobPreferredLocations: null,
    companyPreferredLocationIds: companyPreferredLocationId,
  };

  DropListServices.internNewDemand(sendData).then(
    (internNewDemand) => {
      if (internNewDemand.succeeded === true) {
        var sendData = {
          actionTypeId: 6,
          duration: secondsRef.current,
          actionRelatedId: null,
        };
        DemandsServices.currentUserActionPageLog(sendData).then(
          (pageLogRes) => {
            if (pageLogRes && pageLogRes.succeeded === true) {
            }
          },
          (error) => {}
        );
        toast.success("Stajyer Talebiniz Başarıyla Gerçekleşti", {
          autoClose: 2000,
          onClose: () => {
            history.push("/demands/internAllShow");
            setTimeout(() => {
              window.location.href = window.location.href;
            }, 100);
          },
        });
      } else {
        toast(internNewDemand.error.message);
      }
    },
    (error) => {}
  );
}
