import React from "react";
import "../../../../../../../css/bitoniscss/demand/pdfwidget.css";
const PdfWidget = ({ handlePrint }) => {
  return (
    <div className="" onClick={handlePrint}>
      <div className="pdfI-container">
        <i class="fa-solid fa-file-pdf pdfI-icon"></i>
        <p className="pdfI-title">Pdf Oluştur</p>
      </div>
    </div>
  );
};

export default PdfWidget;
