import React, { useEffect, useRef, useState } from "react";
import BlueCandidateCvDetail from "./BlueCandidateCvDetail";

function BlueCandidateCv({ blueMatchDetail, componentRef }) {
  return (
    <>
      <div
        ref={componentRef}
        style={{
          width: "100%",
          padding: "10px",
          margin: "0px",
          backgroundColor: "white",
          borderRadius: "8px",
        }}
        className="card"
      >
        {}
        <BlueCandidateCvDetail blueMatchDetail={blueMatchDetail} />
      </div>
    </>
  );
};

export default BlueCandidateCv;
