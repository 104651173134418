import React, { useEffect, useState } from "react";
import { Form, InputGroup, Dropdown, Button } from "react-bootstrap";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { useDispatch, useSelector } from "react-redux";
import { DemandsServices } from "../../../../../../../services";
import MatchNotFound from "../MatchShow/BlueMatchShow/MatchNotFound";
import PaginationPage from "../../../../../../widgets/PaginationPage";
import ItemByStatus from "../MatchShow/WhiteMatchShow/widgets/ItemByStatus";
import LoadingDotsAnimation from "../../../../../../widgets/LoadingDotsAnimation";

const ShowEmployerDemandSendsVideoCv = () => {
  var matchSelectedList = [
    { title: "İş Arayan Ek Video CV'sini Gönderdi", status: 12 },
  ];
  const [search, setSearch] = useState("");
  const dispatch = useDispatch();

  const [demandWhiteMatchData, setDemandWhiteMatchData] = useState(null);

  // const { whiteSelectedStatus } = useSelector((state) => state.selectedTab);
  useEffect(() => {
    getMatchWhite();
  }, []);
  function getMatchWhite() {
    DemandsServices.demandMatchWhite(matchSelectedList).then(
      (demandMatch) => {
        if (demandMatch && demandMatch.succeeded === true) {
          setDemandWhiteMatchData([]);
          setDemandWhiteMatchData(demandMatch.data.pagedData);
        }
      },
      (error) => {}
    );
  }
  function searchHandleCandidate(value) {
    setSearch(value);
  }

  const [show, setShow] = useState(null);
  function handleClose() {
    setShow(false);
  }
  const history = useHistory();
  const [page, setPage] = useState(1);
  const pageSize = 10; // Her sayfada gösterilecek eleman sayısı

  const paginatedList =
    demandWhiteMatchData && page === null
      ? demandWhiteMatchData
      : demandWhiteMatchData &&
        demandWhiteMatchData.slice((page - 1) * pageSize, page * pageSize);

  return (
    <>
      <div>
        <div className="">
          <InputGroup className="mb-3" style={{}}>
            <Form.Control
              style={{
                borderRadius: "20px",
                backgroundColor: "#F5F5F5",
                marginLeft: "10px",
              }}
              type="search"
              placeholder="İsme göre ara"
              value={search}
              onChange={(e) => searchHandleCandidate(e.target.value)}
            />
          </InputGroup>
        </div>

        {demandWhiteMatchData === null ? (
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
            }}
          >
            <LoadingDotsAnimation/>
          </div>
        ) : demandWhiteMatchData &&
          paginatedList &&
          paginatedList.length > 0 ? (
          paginatedList && (
            <div
              style={{
                marginBottom: "10%",
                marginTop: "3%",
              }}
            >
              {paginatedList.map((matchData, index) => {
                return paginatedList[index].positionName
                  .toLowerCase()
                  .includes(search.toLowerCase()) ? (
                  <div
                    onClick={(e) => {

e.stopPropagation();
                      const dataMap = new Map();
                      dataMap.set("candidateId", matchData.candidateId);
                      dataMap.set("matchId", matchData.matchId);
                      dataMap.set("status", matchData.status);
                      dataMap.set("cameWithoutMatchingPage", false);
                      history.push({
                        pathname: "matchShow/whiteMatchDetail",
                        state: { dataMap },
                      });
                    }}
                  >
                    <ItemByStatus
                      matchData={matchData}
                      key={matchData}
                      comeCurrentDemand={false}
                    />
                  </div>
                ) : (
                  <div></div>
                );
              })}{" "}
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                {demandWhiteMatchData &&
                  demandWhiteMatchData.length > 0 &&
                  demandWhiteMatchData.length > pageSize && (
                    <PaginationPage
                      items={demandWhiteMatchData}
                      page={page}
                      pageSize={pageSize}
                      onChange={(value) => {
                        if (value) {
                          setPage(value);
                        }
                      }}
                      onTapAllShowButton={() => {
                        setPage(null);
                      }}
                    />
                  )}
              </div>
            </div>
          )
        ) : (
          <MatchNotFound title={""} newMatch={true} />
        )}
      </div>
    </>
  );
};

export default ShowEmployerDemandSendsVideoCv;
