import { RecruitedConstants, UnRecruitedConstants, UnRecruitedConstantsWhite, RecruitedConstantsWhite, RecruitedDetailConstants, RecruitedDetailWhiteConstants } from '../constants';

const defaultRecruitedState = { loading: false, recruitedList: false, error: false, success: false };

function setState(changes) {
    return { ...defaultRecruitedState, ...changes };
}
export function recruitedList(state = defaultRecruitedState, action) { //İşe alınan listesi Mavi Yaka
    switch (action.type) {
        case RecruitedConstants.GET_ALL_REQUEST:
            return setState({ loading: true });
        case RecruitedConstants.GET_ALL_SUCCESS:
            return setState({ recruitedList: action.recruitedList, success: true });
        case RecruitedConstants.GET_ALL_FAILURE:
            return setState({ error: action.recruitedList });
        default:
            return state
    }
}

const defaultRecruitedStateWhite = { loading: false, recruitedListWhite: false, error: false, success: false };

export function recruitedListWhite(state = defaultRecruitedStateWhite, action) { //İşe alınan listesi Beyaz Yaka
    switch (action.type) {
        case RecruitedConstantsWhite.GET_ALL_REQUEST:
            return setState({ loading: true });
        case RecruitedConstantsWhite.GET_ALL_SUCCESS:
            return setState({ recruitedListWhite: action.recruitedListWhite, success: true });
        case RecruitedConstantsWhite.GET_ALL_FAILURE:
            return setState({ error: action.recruitedListWhite });
        default:
            return state
    }
}

const defaultRecruitedDetailsState = { loading: false, recruitedDetail: false, error: false, success: false };

export function recruitedDetail(state = defaultRecruitedDetailsState, action) { //İşe alınan detay Mavi Yaka
    switch (action.type) {
        case RecruitedDetailConstants.GET_ALL_REQUEST:
            return setState({ loading: true });
        case RecruitedDetailConstants.GET_ALL_SUCCESS:
            return setState({ recruitedDetail: action.recruitedDetail, success: true });
        case RecruitedDetailConstants.GET_ALL_FAILURE:
            return setState({ error: action.recruitedDetail });
        case RecruitedDetailConstants.GET_CLEAR:
            return defaultRecruitedDetailsState;
        default:
            return state
    }
}
const defaultRecruitedWhiteDetailsState = { loading: false, recruitedDetailWhite: false, error: false, success: false };

export function recruitedDetailWhite(state = defaultRecruitedWhiteDetailsState, action) { //İşe alınan detay Beyaz Yaka
    switch (action.type) {
        case RecruitedDetailWhiteConstants.GET_ALL_REQUEST:
            return setState({ loading: true });
        case RecruitedDetailWhiteConstants.GET_ALL_SUCCESS:
            return setState({ recruitedDetailWhite: action.recruitedDetailWhite, success: true });
        case RecruitedDetailWhiteConstants.GET_ALL_FAILURE:
            return setState({ error: action.recruitedDetailWhite });
        case RecruitedDetailWhiteConstants.GET_CLEAR:
            return defaultRecruitedWhiteDetailsState;
        default:
            return state
    }
}


const defaultUnRecruitedState = { loading: false, unrecruitedList: false, error: false, success: false };

export function unrecruitedList(state = defaultUnRecruitedState, action) { //İşe alınmayan listesi Mavi Yaka
    switch (action.type) {
        case UnRecruitedConstants.GET_ALL_REQUEST:
            return setState({ loading: true });
        case UnRecruitedConstants.GET_ALL_SUCCESS:
            return setState({ unrecruitedList: action.unrecruitedList, success: true });
        case UnRecruitedConstants.GET_ALL_FAILURE:
            return setState({ error: action.unrecruitedList });
        default:
            return state
    }
}
const defaultUnRecruitedStateWhite = { loading: false, unrecruitedListWhite: false, error: false, success: false };

export function unrecruitedListWhite(state = defaultUnRecruitedStateWhite, action) { //İşe alınmayan listesi Beyaz Yaka
    switch (action.type) {
        case UnRecruitedConstantsWhite.GET_ALL_REQUEST:
            return setState({ loading: true });
        case UnRecruitedConstantsWhite.GET_ALL_SUCCESS:
            return setState({ unrecruitedListWhite: action.unrecruitedListWhite, success: true });
        case UnRecruitedConstantsWhite.GET_ALL_FAILURE:
            return setState({ error: action.unrecruitedListWhite });
        default:
            return state
    }
}
