import React, { Fragment, useState } from "react";
import SideBar from "./SideBar";
import NavHader from "./NavHader";
import Header from "./Header";
import { UserAction } from "../../../action";
import { connect, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

const BitonisNav = ({
  title,
  onClick: ClickToAddEvent,
  onClick2,
  onClick3,
}) => {
  const [toggle, setToggle] = useState("");
  const onClick = (name) => setToggle(toggle === name ? "" : name);
  let history = useHistory();
  let dispatch = useDispatch();

  const logout = () => {
    dispatch(UserAction.logout());
    history.push("/login");
  };
  return (
    <Fragment>
      <NavHader />
      <SideBar
        onClick={() => onClick2()}
        onClick3={() => onClick3()}
        logout={() => logout()}
      />
      <Header
        onNote={() => {}}
        onNotification={() => onClick("notification")}
        onProfile={() => onClick("profile")}
        toggle={toggle}
        title={title}
        onBox={() => onClick("box")}
        onClick={() => ClickToAddEvent()}
      />
    </Fragment>
  );
};

function mapState(state) {
  let authentication = state.authentication;
  return { authentication };
}

export default connect(mapState)(BitonisNav);
