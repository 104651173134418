import { JaaSMeeting } from "@jitsi/react-sdk";
import React, { useEffect, useRef, useState } from "react";
import { getToken } from "../configs/cookie";
import {
  useHistory,
  useLocation,
} from "react-router-dom/cjs/react-router-dom.min";
import { MEET_URL } from "../services/ApiUrlServices";
import { ToastContainer, toast } from "react-toastify";
import LoadingDotsAnimation from "./widgets/LoadingDotsAnimation";
import { DemandsServices } from "../services/DemandsServices";

const JoInVideoConferencePage = () => {
  const [error, setError] = useState(null);
  const history = useHistory();
  const token = getToken();
  const location = useLocation();
  const { dataMap } = location.state;

  const roomName = dataMap.get("roomName");
  const displayName = dataMap.get("displayName");

  const candidateName = dataMap.get("candidateName");

  //- - - - - - -- - - - - - --
  const meetingRoom = dataMap.get("meetingRoom");
  const videoConferansId = dataMap.get("videoConferansId");
  const meetingEmployerId = dataMap.get("meetingEmployerId");
  const meetingMatchId = dataMap.get("meetingMatchId");
  const meetingJobId = dataMap.get("meetingJobId");
  const meetingCandidateId = dataMap.get("meetingCandidateId");
  const meetingMeetingDateTime = dataMap.get("meetingMeetingDateTime");
  const meetingCandidateNameSurname = dataMap.get(
    "meetingCandidateNameSurname"
  );
  const meetingPositionName = dataMap.get("meetingPositionName");
  const companyAlias = dataMap.get("companyAlias");
  //- - - - - - -- - - - - - --

  const handleMeetingError = (error) => {
    // Uyarı: Bant genişliği hatası veya başka bir hata durumunda kullanıcıya bilgi göster.
    setError(`Hata oluştu: ${error.message}`);
    toast.info(error.message);
  };
  const currentInVideoConferenceRef = useRef(null);

  const [videoConferanceParticipantId, setVideoConferanceParticipantId] =
    useState(null);
  const [currentVideoConfEmployerId, setCurrentVideoConfEmployerId] =
    useState(null);
  function videoConfJoined() {
    DemandsServices.videoConfJoined(
      meetingJobId,
      meetingEmployerId,
      meetingCandidateId,
      videoConferansId,
      meetingMatchId,
      meetingMeetingDateTime,
      meetingRoom,
      meetingCandidateNameSurname,
      meetingPositionName,
      companyAlias
    ).then((response) => {
      if (response && response.succeeded) {
      }
    });
  }

  function videoConfClosed(current) {
    DemandsServices.videoConfClosed(videoConferansId).then((response) => {
      if (response && response.succeeded) {
        if (current) {
          history.push("/");
        }
      }
    });
  }
  useEffect(() => {
    return closePage;
  }, []);

  function closePage() {
    videoConfClosed(false);
  }

  return (
    <div>
      <ToastContainer
        position="top-right"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <div className="card">
        <div
          style={{
            borderBottom: "none",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              paddingTop: "10px",
            }}
          >
            <p
              style={{
                fontSize: "17px",
                color: "#001542",
                fontWeight: "500",
                marginBottom: "0px",
                paddingBottom: "5px",
              }}
            >
              <strong>{candidateName}</strong> ile Video konferansınız
              başlatılmıştır
            </p>
          </div>
          <JaaSMeeting
            domain={process.env.REACT_APP_SECRET_MEET_API}
            //   domain={MEET_URL}
            roomName={meetingRoom}
            jwt={token}
            configOverwrite={{
              disableThirdPartyRequests: true,
              disableLocalVideoFlip: true,
              backgroundAlpha: 0.5,
            }}
            lang="tr"
            interfaceConfigOverwrite={{
              VIDEO_LAYOUT_FIT: "nocrop",
              MOBILE_APP_PROMO: false,
              TILE_VIEW_MAX_COLUMNS: 4,
            }}
            spinner={() => (
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  alignContent: "center",
                  alignItems: "0px",
                }}
              >
                <LoadingDotsAnimation />
              </div>
            )}
            onApiReady={(externalApi) => {
              /*externalApi.executeCommand("overwriteConfig", {
                toolbarButtons: ["camera", "microphone", "hangup"],
              });*/
              const participantEmployer = externalApi.on(
                "videoConferenceJoined",
                function (event) {
                  setCurrentVideoConfEmployerId(event.id);
                  currentInVideoConferenceRef.current = true;
                  videoConfJoined();
                }
              );
              const participantJoinedCandidate = externalApi.on(
                "participantJoined",
                function (event) {
                  videoConfJoined();
                  externalApi.getRoomsInfo().then((rooms) => { });
                }
              );
              const participantLeft = externalApi.on(
                "participantLeft",
                function (event) {
                  videoConfClosed(false);
                }
              );
            }}
            onError={handleMeetingError}
            invitees={(val) => { }}
            userInfo={{
              displayName: displayName,
              isSingleUser: true,
            }}
            onReadyToClose={() => {
              videoConfClosed(true);
            }}
            getIFrameRef={(iframeRef) => {
              iframeRef.style.height = "550px";
              iframeRef.style.width = window.innerHeight;
              iframeRef.style.borderRadius = "8px";
              (iframeRef.style.padding = "0px"),
                (iframeRef.style.margin = "0px");
              iframeRef.style.clipPath = "inset(0 0 0 0 round 0px 0px 8px 8px)";
            }}
          />{" "}
        </div>
      </div>
    </div>
  );
};

export default JoInVideoConferencePage;
