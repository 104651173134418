import React from "react";
import logo from "../../../../../../../../../images/logo/logo.png";
import "../../../../../../../../../css/bitoniscss/demand/bluecvdetail.css";

const InterInfoCv = ({ internMatchDetail }) => {
  return (
    <div
      style={{
        flex: "6",
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          margin: "0px",
          padding: "0px",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            alignContent: "center",
            margin: "0px",
            padding: "0px",
          }}
        >
          <div className="">
            <div className="logo-image">
              <img src={logo} className="logoStyle" />
            </div>
          </div>
          <p
            style={{
              color: "#001542",
              fontWeight: "500",
              marginLeft: "5px",
            }}
          >
            {internMatchDetail && internMatchDetail.candidateName}
            <p
              style={{
                margin: "0px",
                padding: "0px",
                fontSize: "11px",
                color: "grey",
                fontWeight: "420",
                margin: "0px",
                padding: "0px",
                textAlign: "center",
              }}
            >
              {internMatchDetail && internMatchDetail.mobilephone}
            </p>
          </p>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            marginLeft: "20px",
            justifyContent: "center",
            alignItems: "center",
            margin: "0px",
            padding: "0px",
          }}
        >
          {" "}
          <hr
            style={{
              width: "400px",
              color: "rgb(242, 240, 240)",
              margin: "0px",
              padding: "0px",
              marginBottom:"10px"
            }}
          />
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                margin: "0px",
                padding: "0px",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <p
                style={{
                  margin: "0px",
                  padding: "0px",
                  fontSize: "12px",
                  color: "#001542",
                  fontWeight: "500",
                }}
              >
                Eğitim
              </p>
              <p
                style={{
                  margin: "0px",
                  padding: "0px",
                  fontSize: "11px",
                  fontWeight: "420",
                }}
              >
                {internMatchDetail && internMatchDetail.educationTypeName}
              </p>
            </div>
            <div
              style={{
                width: "50px",
              }}
            ></div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                margin: "0px",
                padding: "0px",
                justifyContent: "center",
                alignItems: "center",
                width: "200px",
                overflow:"hidden"
              }}
            >
              <p
                style={{
                  margin: "0px",
                  padding: "0px",
                  fontSize: "12px",
                  color: "#001542",
                  fontWeight: "500",
                }}
              >
                Bölüm
              </p>
              <p
                style={{
                  margin: "0px",
                  padding: "0px",
                  fontSize: "11px",
                  fontWeight: "420",
                }}
              >
                {internMatchDetail && internMatchDetail.sectionName}
              </p>
            </div>
            <div
              style={{
                width: "50px",
              }}
            />

            <div
              style={{
                display: "flex",
                flexDirection: "column",
                margin: "0px",
                padding: "0px",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <p
                style={{
                  margin: "0px",
                  padding: "0px",
                  fontSize: "12px",
                  color: "#001542",
                  fontWeight: "500",
                }}
              >
                Sınıf
              </p>
              <p
                style={{
                  margin: "0px",
                  padding: "0px",
                  fontSize: "11px",
                  fontWeight: "420",
                }}
              >
                {internMatchDetail && internMatchDetail.eduClassName}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default InterInfoCv;
