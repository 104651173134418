import React, { useEffect, useState } from "react";
import { DashboardServices } from "../../../../../../services/DashboardServices";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { useDispatch, useSelector } from "react-redux";
import { setNotificationCount } from "../../../../../../redux/redux-toolkit/notificationRedux";
import "../../../../../../css/bitoniscss/notificationItem.css";
const NotificationWidget = () => {
  const dispatch = useDispatch();
  const { notificationCount } = useSelector((state) => state.notification);

  useEffect(() => {
    getNotification();
  }, []);

  function getNotification() {
    DashboardServices.getNotificationUnReadCount().then((notification) => {
      if (notification && notification.succeeded == true) {
        var getData = notification.data;
        var notificationUnReadCount =
          getData.isEmpty || getData == [] ? null : getData;
        dispatch(setNotificationCount(notificationUnReadCount));
      } else {
        dispatch(setNotificationCount(null));
      }
    });
  }
  const history = useHistory();
  return (
    <div
      onClick={() => {
        history.push("/notification");
      }}
    >
      <div
        style={{
          margin: "0px",
          padding: "0px",
          position: "relative",
          width: "50px",
          height: "50px",
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center",
          cursor: "pointer",
        }}
      >
        <i
          className="fa-solid fa-bell"
          style={{
            fontSize: "25px",
            color: "orange",
          }}
        ></i>

        {notificationCount === null ||
        notificationCount === 0 ||
        notificationCount === undefined ||
        notificationCount === "" ? (
          <div></div>
        ) : (
          <div
            style={{
              position: "absolute",
              right: "5px",
              backgroundColor: "red",
              borderRadius: "50%",
              top: "10px",
              right: "8px",
              padding: "0px",
              cursor: "pointer",
            }}
          >
            <div
              style={{
                display: "flex",

                justifyContent: "center",
                padding: "0px",
                width: "18px",
                height: "18px",
              }}
            >
              <p
                style={{
                  fontSize: "9px",
                  color: "white",
                  padding: "1px",
                }}
              >
                {notificationCount}
              </p>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default NotificationWidget;
