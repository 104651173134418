export const JobConstants = {
    GET_REQUEST: 'JOB_GET_REQUEST',
    GET_SUCCESS: 'JOB_GET_SUCCESS',
    GET_CLEAR: 'JOB_GET_CLEAR',
    GET_FAILURE: 'JOB_GET_FAILURE',
    GET_ALL_REQUEST: 'JOB_GET_ALL_REQUEST',
    GET_ALL_SUCCESS: 'JOB_GET_ALL_SUCCESS',
    GET_ALL_FAILURE: 'JOB_GET_ALL_FAILURE',
};

export const JobInternConstant= {
  GET_REQUEST: "JOB_GET_REQUEST",
  GET_SUCCESS: "JOB_GET_SUCCESS",
  GET_CLEAR: "JOB_GET_CLEAR",
  GET_FAILURE: "JOB_GET_FAILURE",
  GET_ALL_REQUEST: "JOB_GET_ALL_REQUEST",
  GET_ALL_SUCCESS: "JOB_GET_ALL_SUCCESS",
  GET_ALL_FAILURE: "JOB_GET_ALL_FAILURE",
};
