import React from "react";
import CustomPdfPreview from "../../../../../../../../widgets/CustomPdfPreview";
import DividerDotted from "../../../../../../../../widgets/DividerDotted";
import { DividerPdf } from "./DividerPdf";

const InfoBodyWhite = ({ whiteMatchDetail }) => {
  return (
    <div
      className="card"
      style={{
        flex: "6",
        marginTop: "7px",
        display: "flex",
        flexDirection: "column",
        margin: "0px",
        padding: "0px",
        marginTop: "0px",
        paddingTop: "10px",
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          marginBottom: "10px",
          padding: "0px",
        }}
      >
        <div
          style={{
            border: "2px solid grey",
            borderRadius: "8px",

            height: "40px",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignContent: "center",
          }}
        >
          <p
            style={{
              padding: "10px",
              margin: "0px",
              fontSize: "10px",
              color: "#001542",
              fontWeight: "500",
            }}
          >
            Kişisel Bilgileri
          </p>
        </div>
        <hr
          style={{
            color: "black",
            marginLeft: "0px",
            paddingLeft: "0px",
            border: "1px solid grey",
            flex: "6",
            margin: "0px",
            padding: "0px",
          }}
        />
        <div
          style={{
            width: "40px",
            height: "40px",
            borderRadius: "50%",
            border: "1.8px solid grey",
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <i
            class="fa-solid fa-user"
            style={{
              color: "orange",
              fontSize: "12px",
            }}
          ></i>
        </div>
      </div>
      <div
        style={{
          backgroundColor: "#f7f7f7",
          borderRadius: "8px",
          marginTop: "10px",
          height: "330px",
          marginBottom: "0px",
          margin: "0px",
          padding: "0px",
        }}
      >
        <div
          style={{
            margin: "10px",
          }}
        >
          <CustomPdfPreview
            name={"Uyruk"}
            value={whiteMatchDetail && whiteMatchDetail.nationalityName}
          />
          <DividerPdf />
          <CustomPdfPreview
            name={"Cinsiyet"}
            value={whiteMatchDetail && whiteMatchDetail.genderName}
          />
          {whiteMatchDetail && whiteMatchDetail.genderId == 1 ? (
            <div></div>
          ) : (
            <div>
              <DividerPdf />
              <CustomPdfPreview
                name={"Askerlik durumu"}
                value={whiteMatchDetail && whiteMatchDetail.militaryStatusName}
              />
            </div>
          )}
          {whiteMatchDetail && whiteMatchDetail.genderId == 1 ? (
            <DividerPdf />
          ) : (
            <div>
              {" "}
              <DividerPdf />
            </div>
          )}
          <CustomPdfPreview
            name={"Medeni Hal"}
            value={whiteMatchDetail && whiteMatchDetail.maritalStatusName}
          />{" "}
          <DividerPdf />
          <CustomPdfPreview
            name={"Sigara kullanımı"}
            value={whiteMatchDetail && whiteMatchDetail.isSmokingName}
          />
          <DividerPdf />
          <CustomPdfPreview
            name={"Sürücü Belgesi"}
            value={whiteMatchDetail && whiteMatchDetail.driverLicenseName}
          />{" "}
          {whiteMatchDetail &&
            whiteMatchDetail.driverLicenseTypeName &&
            whiteMatchDetail.driverLicenseTypeName.length > 0 && (
              <div>
                {" "}
                <DividerPdf />
                <CustomPdfPreview
                  name={"Sürücü Belgesi Türü"}
                  value={whiteMatchDetail.driverLicenseTypeName
                    .map((val) => val.drivingLicenceTypeName)
                    .join(", ")}
                />
              </div>
            )}{" "}
          <DividerPdf />
          <CustomPdfPreview
            name={"Engellilik Durumu"}
            value={whiteMatchDetail && whiteMatchDetail.disabilityName}
          />{" "}
          {(whiteMatchDetail && whiteMatchDetail.disabilityId == 1) ||
          (whiteMatchDetail && whiteMatchDetail.disabilityId == 0) ? (
            <div></div>
          ) : (
            <div>
              {" "}
              <DividerPdf />
              <CustomPdfPreview
                name={"Engellilik Seviyesi"}
                value={
                  whiteMatchDetail && whiteMatchDetail.disabilityDegreeName
                }
              />
            </div>
          )}
          {whiteMatchDetail &&
            whiteMatchDetail.programs &&
            whiteMatchDetail.programs.length > 0 && (
              <div>
                <DividerPdf />
                <CustomPdfPreview
                  name={"Programlar"}
                  value={
                    whiteMatchDetail &&
                    whiteMatchDetail.programs
                      .map((val) => val.programName)
                      .join(",")
                  }

                />
              </div>

            )}{" "}
          {whiteMatchDetail &&
            whiteMatchDetail.certificates &&
            whiteMatchDetail.certificates.length > 0 && (
              <div>
                <DividerPdf />
                <CustomPdfPreview
                  name={"Sertifikalar"}
                  value={
                    whiteMatchDetail &&
                    whiteMatchDetail.certificates
                      .map((val) => val.certificateName)
                      .join(",")
                  }

                />
              </div>
            )}
          {whiteMatchDetail &&
            whiteMatchDetail.competencies &&
            whiteMatchDetail.competencies.length > 0 && (
              <div>
                <DividerPdf />
                <CustomPdfPreview
                  name={"Yetkinlikler"}
                  value={
                    whiteMatchDetail &&
                    whiteMatchDetail.competencies
                      .map((val) => val.competenceName)
                      .join(",")
                  }


                />
              </div>

            )}
      
        </div>
      </div>
      <div
        style={{
          height: "50px",
        }}
      ></div>
    </div>
  );
};

export default InfoBodyWhite;
