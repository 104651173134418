import { DemandsConstants,DemandsMatching,DemandsMatchingWhite } from '../constants';

const defaultState= { loading: false, error: false, demands: false, success: false};

function setState(changes) {
    return { ...defaultState, ...changes };
}

export function demands(state = defaultState, action) {
    switch (action.type) {
        case DemandsConstants.GET_ALL_REQUEST:
            return setState({loading: true});
        case DemandsConstants.GET_ALL_SUCCESS:
            return setState({demands: action.demands, success: true });
        case DemandsConstants.GET_ALL_FAILURE:
            return setState({error: action.demands});
        default:
            return state
    }
}

const defaultStateMatch = { loading: false, matchingDemands: false, error: false, success: false };

export function matchingDemands(state = defaultStateMatch, action) {
    switch (action.type) {
        case DemandsMatching.GET_ALL_REQUEST:
            return setState({ loading: true, success: false });
        case DemandsMatching.GET_ALL_SUCCESS:
            return setState({ matchingDemands: action.matchingDemands, success: true });
        case DemandsMatching.GET_ALL_FAILURE:
            return setState({ error: action.matchingDemands });
        case DemandsMatching.GET_CLEAR:
            return defaultStateMatch;
        default:
            return state
    }
}
const defaultStateMatchWhite = { loading: false, matchingDemandsWhite: false, error: false, success: false };

export function matchingDemandsWhite(state = defaultStateMatchWhite, action) {
    switch (action.type) {
        case DemandsMatchingWhite.GET_ALL_REQUEST:
            return setState({ loading: true, success: false });
        case DemandsMatchingWhite.GET_ALL_SUCCESS:
            return setState({ matchingDemandsWhite: action.matchingDemandsWhite, success: true });
        case DemandsMatchingWhite.GET_ALL_FAILURE:
            return setState({ error: action.matchingDemandsWhite });
        case DemandsMatchingWhite.GET_CLEAR:
            return defaultStateMatch;
        default:
            return state
    }
}