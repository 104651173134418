export const DemandsConstants = {
    GET_REQUEST: 'DEMANDS_GET_REQUEST',
    GET_SUCCESS: 'DEMANDS_GET_SUCCESS',
    GET_CLEAR: 'DEMANDS_GET_CLEAR',
    GET_FAILURE: 'DEMANDS_GET_FAILURE',
    GET_ALL_REQUEST: 'DEMANDS_GET_ALL_REQUEST',
    GET_ALL_SUCCESS: 'DEMANDS_GET_ALL_SUCCESS',
    GET_ALL_FAILURE: 'DEMANDS_GET_ALL_FAILURE',
};
export const DemandsPassive = {
    GET_REQUEST: 'DEMANDSPASSIVE_GET_REQUEST',
    GET_SUCCESS: 'DEMANDSPASSIVE_GET_SUCCESS',
    GET_CLEAR: 'DEMANDSPASSIVE_GET_CLEAR',
    GET_FAILURE: 'DEMANDSPASSIVE_GET_FAILURE',
    GET_ALL_REQUEST: 'DEMANDSPASSIVE_GET_ALL_REQUEST',
    GET_ALL_SUCCESS: 'DEMANDSPASSIVE_GET_ALL_SUCCESS',
    GET_ALL_FAILURE: 'DEMANDSPASSIVE_GET_ALL_FAILURE',
};
export const DemandsMatching = {
    GET_REQUEST: 'DEMANDSMATCHINE_GET_REQUEST',
    GET_SUCCESS: 'DEMANDSMATCHINE_GET_SUCCESS',
    GET_CLEAR: 'DEMANDSMATCHINE_GET_CLEAR',
    GET_FAILURE: 'DEMANDSMATCHINE_GET_FAILURE',
    GET_ALL_REQUEST: 'DEMANDSMATCHINE_GET_ALL_REQUEST',
    GET_ALL_SUCCESS: 'DEMANDSMATCHINE_GET_ALL_SUCCESS',
    GET_ALL_FAILURE: 'DEMANDSMATCHINE_GET_ALL_FAILURE',
};
export const DemandsMatchingWhite = {
    GET_REQUEST: 'DEMANDSMATCHINEWHITE_GET_REQUEST',
    GET_SUCCESS: 'DEMANDSMATCHINEWHITEE_GET_SUCCESS',
    GET_CLEAR: 'DEMANDSMATCHINEWHITE_GET_CLEAR',
    GET_FAILURE: 'DEMANDSMATCHINEWHITE_GET_FAILURE',
    GET_ALL_REQUEST: 'DEMANDSMATCHINEWHITEE_GET_ALL_REQUEST',
    GET_ALL_SUCCESS: 'DEMANDSMATCHINEWHITEE_GET_ALL_SUCCESS',
    GET_ALL_FAILURE: 'DEMANDSMATCHINEWHITE_GET_ALL_FAILURE',
};
