import React from "react";

import "../../../../../../../../css/bitoniscss/demand/bluecvdetail.css";
import InfoCv from "./children/InfoCv";
import InfoBody from "./children/InfoBody";
import { useSelector } from "react-redux";
import LastWorkCompanyCv from "./children/LastWorkCompanyCv";
import NotesCv from "./children/NotesCv";
import CvWantLocation from "./children/CvWantLocation"
const BlueCandidateCvDetail = ({ blueMatchDetail }) => {
  console.log("blueMatchDetail* * " + JSON.stringify(blueMatchDetail));
  var body1;
  if (blueMatchDetail && blueMatchDetail.lastWorkCompany) {
    body1 = (
      <div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
          }}
        >
          <InfoBody blueMatchDetail={blueMatchDetail} />
          <LastWorkCompanyCv blueMatchDetail={blueMatchDetail} />
        </div>
      </div>
    );
  } else {
    body1 = (
      <div
        style={{
          display: "flex",
          flexDirection: "row",
        }}
      >
        <InfoBody blueMatchDetail={blueMatchDetail} />
        <div
          style={{
            margin: "10px",
          }}
        ></div>
        <NotesCv />
      </div>
    );
  }
  return (
    <div className="card">
      <div
        style={{
          width: "100%",

          padding: "10px",
          margin: "0px",
          backgroundColor: "white",
          borderRadius: "8px",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
          }}
        >
          <InfoCv blueMatchDetail={blueMatchDetail} />
          <CvWantLocation blueMatchDetail={blueMatchDetail} />
        </div>
        {body1}
      </div>
    </div>
  );
};

export default BlueCandidateCvDetail;
