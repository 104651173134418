import { useState, useEffect, useRef } from "react";
import { DemandsServices } from "../../services";
import { useHistory } from "react-router-dom";

const useCurrentUserActionPageLog = (actionTypeId, actionRelatedId) => {
  //loglama bilgisini apiye gönderir
  const [seconds, setSeconds] = useState(0);
  const secondsRef = useRef(0);

  useEffect(() => {
    return userLog;
  }, []);

  function userLog() {
    const sendData = {
      actionTypeId: actionTypeId,
      duration: secondsRef.current,
      actionRelatedId: actionRelatedId || null,
    };
  //  alert("sendData** " + JSON.stringify(sendData));
    DemandsServices.currentUserActionPageLog(sendData)
      .then((pageLogRes) => {
        if (pageLogRes && pageLogRes.succeeded === true) {
      //    alert("sendData** " + JSON.stringify(pageLogRes));
          console.log("Loglama başarılı oldu");
          localStorage.setItem("beforeunload", "başarılı");
        }
      })
      .catch((error) => {
        console.error("Loglama hatası:", error);
      });
   
  }

  useEffect(() => {
    const interval = setInterval(() => {
      setSeconds((prev) => {
        secondsRef.current = prev + 1;
        return prev + 1;
      });
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  return { seconds };
};

export default useCurrentUserActionPageLog;
