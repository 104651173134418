import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import CustomPreview from "../../../../../../../../widgets/CustomPreview";
import CustomPreviewMySkillTwopt from "../../../../../../../../widgets/CustomPreviewMySkillTwopt";
import AnotherComponent from "../../../../../../../../widgets/AnotherComponent";
import DividerDotted from "../../../../../../../../widgets/DividerDotted";

const WhiteEducation = ({
  cardStyle,
  labelStyle,
  inputStyle,
  whiteMatchDetail,
}) => {
  return (
    <div style={{}}>
      {whiteMatchDetail &&
      whiteMatchDetail.educationList &&
      whiteMatchDetail.educationList.length > 0 ? (
        <div
          className="card"
          style={{
            margin: "0px 5% 0px 5%",
            padding: "0px",
            border: "0.5px solid #F7F7F7",
            backgroundColor: "F7F7F7",
          }}
        >
          <div
            className="card-header"
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
              borderBottom: "none",
              marginBottom: "0px",
              paddingBottom: "0px",
              border: "none",
            }}
          >
            <h5
              className="card-title"
              style={{
                fontSize: "17px",
              }}
            >
              Eğitim Bilgileri
            </h5>
          </div>
          <div
            className="card-body"
            style={{
              marginTop: "0px",
              paddingTop: "10px",
            }}
          >
            {whiteMatchDetail &&
              whiteMatchDetail.educationList &&
              whiteMatchDetail.educationList.map((edu, index) => {
                if (
                  edu.educationTypeId === 1 ||
                  edu.educationTypeId === 2 ||
                  edu.educationTypeId === 3
                ) {
                  return (
                    <div>
                      <CustomPreview
                        key={edu+index}
                        name={"Eğitim Durumu"}
                        value={edu && edu.educationName}
                      />
                      {index !== whiteMatchDetail.educationList.length - 1 && (
                        <hr style={{ borderTop: "1px dotted lightgrey" }}></hr>
                      )}
                    </div>
                  );
                } else {
                  return (
                    <div>
                      <CustomPreview
                        name={"Eğitim Durumu"}
                        value={edu && edu.educationName}
                        matchPointStatus={false}
                        key={edu + index}
                      />
                      <div className="row">
                        <div className="col-md-12">
                          <AnotherComponent
                            key={edu + index}
                            startDate={edu && edu.educationStartDate}
                            finishDate={edu && edu.educationFinishDate}
                            present={edu && edu.present}
                            leavingSchool={edu && edu.leavingSchool}
                          />
                        </div>
                      </div>
                      <CustomPreview
                        name={"Üniversite"}
                        key={"Üniversite"+edu+index}
                        value={edu && edu.universityName}
                      />
                      <CustomPreview
                        name={"Bölüm"}
                        key={"Bölüm" +edu+ index}
                        value={edu && edu.sectionName}
                      />
                      {index !== whiteMatchDetail.educationList.length - 1 && (
                        <hr style={{ borderTop: "1px dotted lightgrey" }}></hr>
                      )}
                    </div>
                  );
                }
              })}
          </div>
        </div>
      ) : (
        <div></div>
      )}
    </div>
  );
};

export default WhiteEducation;
