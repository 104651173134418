import React from "react";
import CustomPdfPreview from "../../../../../../../../widgets/CustomPdfPreview";
import DividerDotted from "../../../../../../../../widgets/DividerDotted";

const InternInfoBody = ({ internMatchDetail }) => {
  return (
    <div
      className="card"
      style={{
        flex: "6",
        marginTop: "7px",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          marginBottom: "10px",
          padding: "0px",
        }}
      >
        <div
          style={{
            border: "2px solid grey",
            borderRadius: "8px",

            height: "40px",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignContent: "center",
          }}
        >
          <p
            style={{
              padding: "10px",
              margin: "0px",
              fontSize: "10px",
              color: "#001542",
              fontWeight: "500",
            }}
          >
            Kişisel Bilgileri
          </p>
        </div>
        <hr
          style={{
            color: "black",
            marginLeft: "0px",
            paddingLeft: "0px",
            border: "1px solid grey",
            flex: "6",
            margin: "0px",
            padding: "0px",
          }}
        />
        <div
          style={{
            width: "40px",
            height: "40px",
            borderRadius: "50%",
            border: "1.8px solid grey",
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <i
            class="fa-solid fa-user"
            style={{
              color: "orange",
              fontSize: "12px",
            }}
          ></i>
        </div>
      </div>
      <div
        style={{
          backgroundColor: "#f7f7f7",
          borderRadius: "8px",
          marginTop: "10px",
          height: "500px",
          marginBottom: "30px",
        }}
      >
        <div
          style={{
            margin: "10px",
          }}
        >
          <CustomPdfPreview
            name={"Uyruk"}
            value={internMatchDetail && internMatchDetail.nationalityName}
          />{" "}
          <DividerPdf />
          <CustomPdfPreview
            name={"Cinsiyet"}
            value={internMatchDetail && internMatchDetail.genderName}
          />{" "}
          <DividerPdf />
          <CustomPdfPreview
            name={"Yaş"}
            value={internMatchDetail && internMatchDetail.birthDate}
          />
        </div>
      </div>
    </div>
  );
};

const DividerPdf = () => {
  return (
    <div>
      <div>
        <hr
          style={{
            marginTop: "0px",
            marginBottom: "0px",
            padding: "0px",
            paddingTop: "5px",
            border: "none",
            borderTop: "2px solid rgb(236, 227, 188)",
            color: " #fff",
            flex: "6",
          }}
        ></hr>
      </div>
    </div>
  );
};

export default InternInfoBody;
