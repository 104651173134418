import React, { useState } from "react";
import "../../../../../../../../../css/bitoniscss/matchıtem.css";
import ProgressCircle from "./ProgressCircle";
import { Box, Typography } from "@mui/material";
import { Row } from "react-bootstrap";
const NewMatchItem = ({ matchData }) => {
  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  const getBackgroundColor = () => {
    if (isHovered) {
      return "white";
    }

    // Diğer durumlar için renkleri döndürün
    return "white";
  };

  const getBoxShadow = () => {
    return isHovered
      ? "0px 7px 8px #dfdfdf;" // Hovered state shadow (elevation effect)
      : "0px"; // Default state shadow
  };




  return (
    <Box
      className="card-body"
      key={matchData.candidateId}
      sx={{
        display: "flex",
        flexDirection: "row",
        alignContent: "space-between",
        border: isHovered == true ? "1px solid #D6E5FA" : "1px solid #F7F7F7",
        boxShadow: getBoxShadow(), 
        transition: "box-shadow 0.3s ease-in-out",
        height: "60px",
        position: "relative",
        borderRadius: "10px",
        alignItems: "center",
        width: "100%",
        margin: "8px",
        justifyContent: "space-between",
        cursor: "pointer",
        backgroundColor: getBackgroundColor(), // Dinamik arka plan rengi
      }}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <Row
        sx={{
          display: "flex",
          flexDirection: "row",
        }}
      >
        <Box
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-start",
            alignContent: "center",
            alignItems: "center",
          }}
        >
          <span
            className="fa-stack fa-lg"
            style={{
              marginLeft: "10px",
            }}
          >
            <i class="fa-solid fa-user"></i>
          </span>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Typography
              sx={{
                fontSize: {
                  xs: "10px",
                  sm: "12px",
                  md: "12px",
                  lg: "14px",
                  xl: "14px",
                },
                width: {
                  xs: "120px",
                  sm: "200px",
                  md: "200px",
                  lg: "170px",
                  xl: "200px",
                },
                marginRight: {
                  xs: "-200px",
                },
                fontWeight: 600,
                color: {
                  xs: "#001542", // 0px ve üzeri ekran genişlikleri için
                  sm: "#001542", // 600px ve üzeri ekran genişlikleri için
                  md: "#001542", // 900px ve üzeri ekran genişlikleri için
                  lg: "#001542", // 1200px ve üzeri ekran genişlikleri için
                  xl: "#001542", // 1536px ve üzeri ekran genişlikleri için
                },
              }}
            >
              {matchData.candidateNameSurname}{" "}
            </Typography>
          </Box>
        </Box>
      </Row>
      <Box>
        <Box
          sx={
            matchData.status === 12
              ? {
                  width: {
                    xs: "120px", // 0px ve üzeri ekran genişlikleri için
                    sm: "120px", // 600px ve üzeri ekran genişlikleri için
                    md: "150px", // 900px ve üzeri ekran genişlikleri için
                    lg: "170px", // 1200px ve üzeri ekran genişlikleri için
                    xl: "170px", // 1536px ve üzeri ekran genişlikleri için,
                  },
                  marginLeft: {
                    xs: "100px", // 0px ve üzeri ekran genişlikleri için
                    sm: "120px", // 600px ve üzeri ekran genişlikleri için
                    md: "120px", // 900px ve üzeri ekran genişlikleri için
                    lg: "120px", // 1200px ve üzeri ekran genişlikleri için
                    xl: "120px", // 1536px ve üzeri ekran genişlikleri için,
                  },
                }
              : {}
          }
        >
          <Typography
            sx={{
              fontSize: {
                xs: "9px", // 0px ve üzeri ekran genişlikleri için
                sm: "11px", // 600px ve üzeri ekran genişlikleri için
                md: "11px", // 900px ve üzeri ekran genişlikleri için
                lg: "13px", // 1200px ve üzeri ekran genişlikleri için
                xl: "12px", // 1536px ve üzeri ekran genişlikleri için,
              },
              textAlign: "center",
              fontWeight: 600,
              color: {
                xs: "#001542", // 0px ve üzeri ekran genişlikleri için
                sm: "#001542", // 600px ve üzeri ekran genişlikleri için
                md: "#001542", // 900px ve üzeri ekran genişlikleri için
                lg: "#001542", // 1200px ve üzeri ekran genişlikleri için
                xl: "#001542", // 1536px ve üzeri ekran genişlikleri için
              },
            }}
          >
            {matchData.positionName}
          </Typography>
        </Box>
      </Box>
      <Box>
        <Box
          sx={
            matchData.status === 12
              ? {
                  width: {
                    xs: "120px", // 0px ve üzeri ekran genişlikleri için
                    sm: "120px", // 600px ve üzeri ekran genişlikleri için
                    md: "150px", // 900px ve üzeri ekran genişlikleri için
                    lg: "250px", // 1200px ve üzeri ekran genişlikleri için
                    xl: "250px", // 1536px ve üzeri ekran genişlikleri için,
                  },
                }
              : {}
          }
        >
          <Typography
            sx={{
              position: matchData.status === 12 ? "relative" : "absolute",
              right: matchData.status === 12 ? -10 : 55,
              top: matchData.status === 12 ? 0 : 20,
              fontSize: {
                xs: "9px", // 0px ve üzeri ekran genişlikleri için
                sm: "12px", // 600px ve üzeri ekran genişlikleri için
                md: "12px", // 900px ve üzeri ekran genişlikleri için
                lg: "12px", // 1200px ve üzeri ekran genişlikleri için
                xl: "12px", // 1536px ve üzeri ekran genişlikleri için,
              },
              fontWeight: 600,
              color: {
                xs: matchData.status == 12 ? "teal" : "teal", // 0px ve üzeri ekran genişlikleri için
                sm: matchData.status == 12 ? "teal" : "teal", // 600px ve üzeri ekran genişlikleri için
                md: matchData.status == 12 ? "teal" : "teal", // 900px ve üzeri ekran genişlikleri için
                lg: matchData.status == 12 ? "teal" : "teal", // 1200px ve üzeri ekran genişlikleri için
                xl: matchData.status == 12 ? "teal" : "teal", // 1536px ve üzeri ekran genişlikleri için
              },
            }}
          >
            {matchData.status === 12
              ? "İş Arayan Ek Video CV'sini Gönderdi"
              : "Yeni Eşleşme"}
          </Typography>
        </Box>
      </Box>
      <div
        style={
          matchData.matchPoint === null || matchData.status === 12
            ? {
                position: "absolute",
                bottom: 0,
                right: 0,
                width: 0,
                height: 0,
                borderLeft: "30px solid transparent",
                borderTop: "30px solid transparent",
                borderRight: "30px solid teal",
                borderRadius: "0px 0px 8px",
              }
            : {
                position: "absolute",
                bottom: 0,
                right: 0,
                width: 0,
                height: 0,
                borderLeft: "30px solid transparent",
                borderTop: "30px solid transparent",
                borderRight: "30px solid teal",
                borderRadius: "0px 0px 8px",
              }
        }
      >
        {matchData.matchPoint === null || matchData.status === 12 ? (
          <div></div>
        ) : (
          <div
            style={{
              position: "absolute",
              right: -50,
              top: -85,
            }}
          >
            <ProgressCircle value={matchData.matchPoint} />
          </div>
        )}
      </div>
    </Box>
  );
};

export default NewMatchItem;

/*
 <div
      style={{
        position: "relative",
      }}
    >
      <table
        style={{
          width: "100%",
        }}
      >
        <tr>
          <div className="card-body cardItem" key={matchData.candidateId}>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignContent: "space-between",
                alignItems: "center",
                marginLeft: "0px",
              }}
            >
         
              <td
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  alignContent: "center",
                  alignItems: "center",
                }}
              >
                <span className="fa-stack fa-lg">
                  <i class="fa-solid fa-user"></i>
                </span>
                <div className="candidate-name">
                  {matchData.candidateNameSurname}
                </div>
              </td>
            </div>
            <td>
              <div className="position-name">{matchData.positionName}</div>
            </td>
            <td>
              <div className="new-match ">
                {matchData.status === 12
                  ? "İş Arayan Ek Video CV'sini Gönderdi"
                  : "Yeni Eşleşme"}{" "}
              </div>{" "}
              <div className="triangle-new-match "></div>{" "}
              {matchData.matchPoint === null || matchData.status === 12 ? (
                <div></div>
              ) : (
                <div
                  style={{
                    position: "absolute",
                    right: -32,
                    top: -28,
                  }}
                >
                  <ProgressCircle value={matchData.matchPoint} />
                </div>
              )}
            </td>
          </div>
        </tr>
      </table>
    </div>
    */
