import React, { useEffect, useMemo } from "react";
import { Link } from "react-router-dom";
import moment from "moment";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useDispatch, useSelector } from "react-redux";
import { fetchLastDemandDemandTotal } from "../../../../redux/redux-toolkit/lastDemandCountSlice";
import demandNotFound from "../../../../images/svg/demandNotFound.svg";

const JobSlide = ({ lastDemandList }) => {
  const items = () => {
    if (lastDemandList && lastDemandList.length <= 0) {
      return (
        <div
          style={{
            marginTop: "12%",
          }}
        >
          <div
            class=""
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              alignContent: "center",
              justifyContent: "center",
              marginTop: "10px",
              justifyItems: "center",
            }}
          >
            <img
              src={demandNotFound}
              style={{
                height: "80px",
                textAlign: "center",
              }}
            />
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <p
                style={{
                  color: "#001542",
                  marginTop: "10px",
                  marginBottom: "0px",
                  fontSize: "16px",
                  fontWeight: 600,
                  marginBottom: "0px",
                }}
              >
                Yayınlanmış Talep Bulunamadı
              </p>{" "}
          
          
            </div>
          </div>
        </div>
      );
    }
    return (
      lastDemandList &&
      lastDemandList.map((data) => {
        return (
          <div className="items" key={data.jobId}>
            <div className="card shadow">
              <div className="card-body">
                <div className="media mb-2">
                  <div className="media-body">
                    <p className="mb-1">Toplam {data.personCount} Kişi</p>
                    <h4 className="fs-20 text-black">{data.positionName}</h4>
                  </div>
                  {
                    <i
                      className="fa fa-user-tag"
                      style={{
                        fontSize: "30px",
                        color: "#001542",
                      }}
                    ></i>
                  }
                </div>
                <span className="text-primary font-w500 d-block mb-3">
                  Belirtilmemiş
                </span>
                <p className="fs-14">-</p>
                <div className="d-flex align-items-center mt-4">
                  <Link className="btn btn-primary light btn-rounded mr-auto cursor-p-none">
                    {moment(data.demandDate).format("DD.MM.YYYY")}
                  </Link>
                  <span className="text-black font-w500 pl-3"></span>
                </div>
              </div>
            </div>
          </div>
        );
      })
    );
  };
  return <Slider {...settings}>{items()}</Slider>;
};
const settings = {
  arrows: false,
  slidesToShow: 2,
  slidesToScroll: 1,
  infinite: false,
  speed: 1000,
  autoplay: true,
  autoplaySpeed: 1000,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 2,
      },
    },
    {
      breakpoint: 700,
      settings: {
        slidesToShow: 1,
      },
    },
    {
      breakpoint: 500,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
};

export default JobSlide;
