import React, { useEffect, useMemo, useState } from "react";
import { Controller, useForm } from "react-hook-form";

import Modal from "react-bootstrap/Modal"; // Varsayılan olarak React Bootstrap Modal bileşeni kullanılıyor
import Select from "react-select";
import { Alert, Button } from "react-bootstrap";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  DropListAction,
  DropListWhiteAction,
} from "../../../../../../../../../action";
import "../../../../../../../../../css/bitoniscss/modalcss.css";
import AbilitiesRequiredWidget from "../../../../../FindJobSeeker/Common/AbilitiesRequiredWidget";
import BitonisMultiDropdown from "../../../../../../../../widgets/BitonisMultiDropdown";
import ProgramsCertificatesCompetences from "../../../../../ProgramsCertificatesCompetences/ProgramsCertificatesCompetences";
import BitonisSingleDropdown from "../../../../../../../../widgets/BitonisSingleDropdown";
import CustomDesign from "../../../../../../../../widgets/CustomDesign";
import CustomButton from "../../../../../../../../widgets/buttons/CustomButton";
const EditWhitePositionModal = ({
  show,
  handleClose,
  job,
  onSubmit,
  props,
  customStyles,
  customStylesMulti,
  inputStyle,
  militaryIsShow,
  setMilitaryIsShow,
  licenseIsShow,
  setLicenseIsShow,
  register,
  handleSubmit,
  control,
  setValue,
  errors,
  watch,
  isValid,
  labelStyle,
  checkProgram,
  checkCertificate,
  checkCompetence,
  setCheckProgram,
  setCheckCertificate,
  setCheckCompetence,
  getValues,
}) => {
  const handleMilitaryStatus = (selectedGender) => {
    if (
      selectedGender &&
      (selectedGender.value === 1 || selectedGender.value == 0)
    ) {
      setMilitaryIsShow(false);
      setValue("militaryStatus", "");
    } else {
      setMilitaryIsShow(true);
    }
  };

  const genderList = [
    //kontrol edildi
    { id: 0, name: "Farketmez" },
    { id: 1, name: "Kadın" },
    { id: 2, name: "Erkek" },
  ];

  const maritalStatus = [
    //kontrol edildi
    { id: 0, name: "Farketmez" },
    { id: 1, name: "Bekar" },
    { id: 2, name: "Evli" },
  ];

  const driverLicense = [
    //kontrol edildi
    { id: 0, name: "Farketmez" },
    { id: 1, name: "Var" },
  ];

  const militaryStatus = [
    //kontrol edildi
    { id: 0, name: "Farketmez" },
    { id: 1, name: "Tamamlandı" },
    { id: 2, name: "Tamamlanmadı" },
  ];
  const weekendWork = [
    //kontrol edildi
    { id: 1, name: "Yok" },
    { id: 2, name: "Tam Gün" },
    { id: 3, name: "Yarım Gün" },
    { id: 4, name: "Dönüşümlü" },
  ];
  const numberOfSearches = [
    //kontrol edildi
    { id: 1, name: "1" },
    { id: 2, name: "2" },
    { id: 3, name: "3" },
    { id: 4, name: "4" },
    { id: 5, name: "5" },
    { id: 6, name: "6" },
    { id: 7, name: "7" },
    { id: 8, name: "8" },
    { id: 9, name: "9" },
    { id: 10, name: "10" },
  ];
  const smoking = [
    //kontrol edildi
    { id: 0, name: "Farketmez" },
    { id: 2, name: "Hayır" },
  ];

  const handleLicenseChange = (selectedLicense) => {
    if (
      (selectedLicense && selectedLicense.value === 0) ||
      selectedLicense.value == 2
    ) {
      setValue("drivingLicenceTypes", []);
      setLicenseIsShow(false);
    } else {
      setLicenseIsShow(true);
    }
  };

  var travelpreferencesNewList = [
    //iş arayan beyaz yaka üyelikte iş seyahati tercihinde kullanıldı
    {
      id: 1,
      name: "Farketmez",
    },
    {
      id: 2,
      name: "Yok",
    },
    {
      id: 3,
      name: "Şehir İçi",
    },
    {
      id: 4,
      name: "Şehir Dışı",
    },
    {
      id: 5,
      name: "Yurt Dışı",
    },
  ];
  useEffect(() => {
    if (!props.salaryListWhite.success) {
      props.dispatch(
        DropListWhiteAction.salaryListWhite({
          positionType: 2,
          isActive: true,
        })
      );
    }

    if (!props.positionList.success) {
      props.dispatch(
        DropListAction.positionList({
          positionType: 1,
          isActive: true,
        })
      );
    }
    if (!props.positionListWhite.success) {
      props.dispatch(
        DropListWhiteAction.positionListWhite({
          positionType: 2,
          isActive: true,
        })
      );
    }
    if (!props.yearOfExperience.success) {
      props.dispatch(
        DropListAction.yearOfExperience({
          positionType: 1,
          isActive: true,
        })
      );
    }
    if (!props.yearOfExperienceWhite.success) {
      props.dispatch(
        DropListWhiteAction.yearOfExperienceWhite({
          positionType: 2,
          isActive: true,
        })
      );
    }
    if (!props.wayOfWorking.success) {
      props.dispatch(
        DropListAction.wayOfWorking({
          positionType: 1,
          isActive: true,
        })
      );
    }
    if (!props.wayOfWorkingWhite.success) {
      props.dispatch(
        DropListWhiteAction.wayOfWorkingWhite({
          positionType: 2,
          isActive: true,
        })
      );
    }
    if (!props.nationalityList.success) {
      props.dispatch(
        DropListAction.nationalityList({
          positionType: 1,
          isActive: true,
        })
      );
    }
    if (!props.ageRangeList.success) {
      props.dispatch(
        DropListAction.ageRangeList({
          positionType: 1,
          isActive: true,
        })
      );
    }
    if (!props.ageRangeListWhite.success) {
      props.dispatch(
        DropListWhiteAction.ageRangeListWhite({
          positionType: 2,
          isActive: true,
        })
      );
    }
    if (!props.driverLicenceType.success) {
      props.dispatch(
        DropListAction.driverLicenceType({
          positionType: 1,
          isActive: true,
        })
      );
    }

    if (!props.businessTravelListWhite.success) {
      props.dispatch(
        DropListWhiteAction.businessTravelListWhite({
          positionType: 2,
          isActive: true,
        })
      );
    }
  }, []);
  useEffect(() => {
    if (show) {
      const gender = watch("gender");
      if (gender && (gender.value === 1 || gender.value == 0)) {
        setMilitaryIsShow(false);
        setValue("militaryStatus", "");
      } else {
        setMilitaryIsShow(true);
      }
      props.dispatch(
        DropListAction.getProfileFillPcc([props.job.job.positionGroupCode])
      );

      if (!props.disabilityOfDegreeList.success) {
        props.dispatch(DropListAction.getDisabilityOfDegreeList());
      }
    }
  }, [show]);
  var disabilityEmployerList = [
    { id: 1, name: "Farketmez" },
    { id: 2, name: "Evet" },
    { id: 0, name: "Hayır" },
  ];

  const disabilityStatus = watch("disabilityStatus");

  return (
    <div>
      <>
        <div>
          <Modal
            show={show}
            onHide={handleClose}
            keyboard={false}
            aria-labelledby="contained-modal-title-vcenter"
            centered
            style={{
              backgroundColor: "rgba(0, 0, 0, 0.2)",
              width: "100%", // Genişlik ayarı
            }}
            backdrop="static"
            size="lg"
          >
            <div className="">
              <Modal.Header
                closeButton
                style={{ borderBottom: "none", justifyContent: "center" }}
              >
                <Modal.Title
                  aria-labelledby="contained-modal-title-vcenter"
                  centered
                >
                  <h3 style={{ color: "#001542" }}>Pozisyon</h3>
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <form
                  onSubmit={(e) => {
                    e.preventDefault();
                    handleSubmit(onSubmit)();
                    if (isValid) {
                      handleClose(); // Close the modal after form submission
                    }
                  }}
                >
                  <CustomDesign
                    label={"Pozisyon"}
                    required={true}
                    child={
                      <input
                        type="text"
                        className="form-control"
                        placeholder=""
                        value={props.job.job.positionName}
                        style={{
                          backgroundColor: "#F5F5F5",

                          borderRadius: "8px",
                          height: "55px",

                          border: "1px solid #dcdcdd",
                          margin: "0px",
                          fontWeight: 500,
                          fontSize: "14px",
                        }}
                        disabled={true}
                        onChange={(e) => {}}
                      />
                    }
                  />
                  <CustomDesign
                    label={" Tecrübe Yılı"}
                    required={true}
                    child={
                      <BitonisMultiDropdown
                        items={
                          props.yearOfExperience.yearOfExperience &&
                          props.yearOfExperience.yearOfExperience.map(
                            ({ id, name }) => ({
                              value: id,
                              label: name,
                            })
                          )
                        }
                        register={register}
                        watch={watch}
                        companyClassName={"jobExperienceRanges"}
                        control={control}
                        setValue={setValue}
                        errors={errors}
                        errorMessage={"Tecrübe Yılı Boş geçilemez"}
                        placeholder="Tecrübe Yılı"
                        getValues={getValues}
                        onValueChanged={(value) => {
                          console.log(value);
                        }}
                        required={true}
                        selectedId={1}
                      />
                    }
                  />
                  <CustomDesign
                    label={"Çalışma Şekli"}
                    required={true}
                    child={
                      <BitonisMultiDropdown
                        items={
                          props.wayOfWorkingWhite.wayOfWorkingWhite &&
                          props.wayOfWorkingWhite.wayOfWorkingWhite.map(
                            ({ id, name }) => ({
                              value: id,
                              label: name,
                            })
                          )
                        }
                        register={register}
                        watch={watch}
                        companyClassName={"workTypes"}
                        control={control}
                        setValue={setValue}
                        errors={errors}
                        errorMessage={"Çalışma Şekli Boş geçilemez"}
                        placeholder="Çalışma Şekli"
                        getValues={getValues}
                        onValueChanged={(value) => {
                          console.log(value);
                        }}
                        required={true}
                      />
                    }
                  />
                  <CustomDesign
                    label={"Aradığınız Pozisyon İçin Eleman Sayısı"}
                    required={true}
                    child={
                      <BitonisSingleDropdown
                        items={
                          numberOfSearches &&
                          numberOfSearches.map(({ id, name }) => ({
                            value: id,
                            label: name,
                          }))
                        }
                        register={register}
                        watch={watch}
                        companyClassName={"personCount"}
                        control={control}
                        setValue={setValue}
                        errors={errors}
                        placeholder="Aradığınız Pozisyon İçin Eleman Sayısını Seçin"
                        onValueChanged={(e) => {
                          console.log(e);
                          if (e) {
                          }
                        }}
                        required={true}
                        getValues={getValues}
                        errorMessage={
                          "Aradığınız Pozisyon İçin Eleman Sayısı Boş geçilemez"
                        }
                      />
                    }
                  />
                  <CustomDesign
                    label={"Uyruk"}
                    required={true}
                    child={
                      <BitonisSingleDropdown
                        items={
                          props.nationalityList.nationalityList &&
                          props.nationalityList.nationalityList.map(
                            ({ id, name }) => ({
                              value: id,
                              label: name,
                            })
                          )
                        }
                        register={register}
                        watch={watch}
                        companyClassName={"nationalityId"}
                        control={control}
                        setValue={setValue}
                        errors={errors}
                        placeholder="Uyruk Seçin"
                        onValueChanged={(e) => {
                          console.log(e);
                          if (e) {
                          }
                        }}
                        required={true}
                        getValues={getValues}
                        errorMessage={"Uyruk Boş geçilemez"}
                      />
                    }
                  />
                  <CustomDesign
                    label={"Cinsiyet"}
                    required={true}
                    child={
                      <BitonisSingleDropdown
                        items={
                          genderList &&
                          genderList.map(({ id, name }) => ({
                            value: id,
                            label: name,
                          }))
                        }
                        register={register}
                        watch={watch}
                        companyClassName={"gender"}
                        control={control}
                        setValue={setValue}
                        errors={errors}
                        placeholder="Cinsiyet Seçin"
                        onValueChanged={(e) => {
                          console.log(e);
                          if (e) {
                            handleMilitaryStatus(e);
                          }
                        }}
                        required={true}
                        getValues={getValues}
                        errorMessage={"Cinsiyet Boş geçilemez"}
                      />
                    }
                  />
                  {militaryIsShow && (
                    <CustomDesign
                      label={"Askerlik Durumu"}
                      required={true}
                      child={
                        <BitonisSingleDropdown
                          items={
                            militaryStatus &&
                            militaryStatus.map(({ id, name }) => ({
                              value: id,
                              label: name,
                            }))
                          }
                          register={register}
                          watch={watch}
                          companyClassName={"militaryStatus"}
                          control={control}
                          setValue={setValue}
                          errors={errors}
                          placeholder="Askerlik Durumu Seçin"
                          onValueChanged={(e) => {
                            console.log(e);
                            if (e) {
                            }
                          }}
                          required={true}
                          getValues={getValues}
                          errorMessage={"Askerlik Durumu Boş geçilemez"}
                        />
                      }
                    />
                  )}
                  <CustomDesign
                    label={"Medeni Hal"}
                    required={true}
                    child={
                      <BitonisSingleDropdown
                        items={
                          maritalStatus &&
                          maritalStatus.map(({ id, name }) => ({
                            value: id,
                            label: name,
                          }))
                        }
                        register={register}
                        watch={watch}
                        companyClassName={"maritalStatus"}
                        control={control}
                        setValue={setValue}
                        errors={errors}
                        placeholder="Medeni Hal Seçin"
                        onValueChanged={(e) => {
                          console.log(e);
                          if (e) {
                          }
                        }}
                        required={true}
                        getValues={getValues}
                        errorMessage={"Medeni Hal Boş geçilemez"}
                        defaultValue={{
                          value: props.job.job.jobMaritalStatus.maritalStatus,
                          label: props.job.job.jobMaritalStatus.description,
                        }}
                      />
                    }
                  />
                  <CustomDesign
                    label={"Hafta Sonu Çalışma"}
                    required={true}
                    child={
                      <BitonisSingleDropdown
                        items={
                          weekendWork &&
                          weekendWork.map(({ id, name }) => ({
                            value: id,
                            label: name,
                          }))
                        }
                        register={register}
                        watch={watch}
                        companyClassName={"jobWeekendWorks"}
                        control={control}
                        setValue={setValue}
                        errors={errors}
                        placeholder="Hafta Sonu Çalışma Seçi"
                        onValueChanged={(e) => {
                          console.log(e);
                          if (e) {
                          }
                        }}
                        required={true}
                        getValues={getValues}
                        errorMessage={"Hafta Sonu Çalışma Boş geçilemez"}
                      />
                    }
                  />
                  <CustomDesign
                    label={"İş Seyahati Tercihiniz"}
                    required={true}
                    child={
                      <BitonisMultiDropdown
                        items={
                          travelpreferencesNewList &&
                          travelpreferencesNewList.map(({ id, name }) => ({
                            value: id,
                            label: name,
                          }))
                        }
                        register={register}
                        watch={watch}
                        companyClassName={"jobBusinessTravelRestrictions"}
                        control={control}
                        setValue={setValue}
                        errors={errors}
                        errorMessage={"İş Seyehati Tercihiniz Boş geçilemez"}
                        placeholder="İş Seyahati Tercihi"
                        getValues={getValues}
                        onValueChanged={(value) => {
                          console.log(value);
                        }}
                        required={true}
                        selectedId={1}
                      />
                    }
                  />
                  <CustomDesign
                    label={"Maaş Beklentiniz"}
                    required={true}
                    child={
                      <BitonisMultiDropdown
                        items={
                          props.salaryListWhite.salaryListWhite &&
                          props.salaryListWhite.salaryListWhite.map(
                            ({ id, name }) => ({
                              value: id,
                              label: name,
                            })
                          )
                        }
                        register={register}
                        watch={watch}
                        companyClassName={"salaryRanges"}
                        control={control}
                        setValue={setValue}
                        errors={errors}
                        errorMessage={
                          "Tercih Edilen Maaş Aralığı Boş geçilemez"
                        }
                        placeholder="Tercih Edilen Maaş Aralığı"
                        getValues={getValues}
                        onValueChanged={(value) => {
                          console.log(value);
                        }}
                        required={true}
                        selectedId={0}
                      />
                    }
                  />
                  <CustomDesign
                    label={"Yaş Aralığı"}
                    required={true}
                    child={
                      <BitonisMultiDropdown
                        items={
                          props.ageRangeList.ageRangeList &&
                          props.ageRangeList.ageRangeList.map(
                            ({ id, name }) => ({
                              value: id,
                              label: name,
                            })
                          )
                        }
                        register={register}
                        watch={watch}
                        companyClassName={"ageRanges"}
                        control={control}
                        setValue={setValue}
                        errors={errors}
                        errorMessage={"Yaş Aralığı Boş geçilemez"}
                        placeholder="Yaş Aralığı"
                        getValues={getValues}
                        onValueChanged={(value) => {
                          console.log(value);
                        }}
                        required={true}
                        selectedId={1}
                      />
                    }
                  />
                  <CustomDesign
                    label={"Sigara Kullanımı"}
                    required={true}
                    child={
                      <BitonisSingleDropdown
                        items={
                          smoking &&
                          smoking.map(({ id, name }) => ({
                            value: id,
                            label: name,
                          }))
                        }
                        register={register}
                        watch={watch}
                        companyClassName={"smokerApply"}
                        control={control}
                        setValue={setValue}
                        errors={errors}
                        placeholder="Sigara Kullanımını Seçin"
                        onValueChanged={(e) => {
                          console.log(e);
                          if (e) {
                          }
                        }}
                        required={true}
                        getValues={getValues}
                        errorMessage={"Sigara Kullanımı Boş geçilemez"}
                        defaultValue={{
                          value: props.job.job.jobSmoking.smoking,
                          label: props.job.job.jobSmoking.description,
                        }}
                      />
                    }
                  />
                  <CustomDesign
                    label={"Sürücü Belgesi"}
                    required={true}
                    child={
                      <BitonisSingleDropdown
                        items={
                          driverLicense &&
                          driverLicense.map(({ id, name }) => ({
                            value: id,
                            label: name,
                          }))
                        }
                        register={register}
                        watch={watch}
                        companyClassName={"drivingLicence"}
                        control={control}
                        setValue={setValue}
                        errors={errors}
                        placeholder="Sürücü Belgesi Seçin"
                        onValueChanged={(e) => {
                          console.log(e);
                          if (e) {
                            handleLicenseChange(e);
                          }
                        }}
                        required={true}
                        getValues={getValues}
                        errorMessage={"Sürücü Belgesi Boş geçilemez"}
                        defaultValue={{
                          value: props.job.job.drivingLicence.drivingLicence,
                          label: props.job.job.drivingLicence.description,
                        }}
                      />
                    }
                  />
                  {licenseIsShow && (
                    <CustomDesign
                      label={"Sürücü Belgesi Türü"}
                      required={true}
                      child={
                        <BitonisMultiDropdown
                          items={
                            props.driverLicenceType.driverLicenceType &&
                            props.driverLicenceType.driverLicenceType.map(
                              ({ id, name }) => ({
                                value: id,
                                label: name,
                              })
                            )
                          }
                          register={register}
                          watch={watch}
                          companyClassName={"drivingLicenceTypes"}
                          control={control}
                          setValue={setValue}
                          errors={errors}
                          errorMessage={"Sürücü Belgesi Türü Boş geçilemez"}
                          placeholder="Sürücü Belgesi Türü"
                          getValues={getValues}
                          onValueChanged={(value) => {
                            console.log(value);
                          }}
                          required={true}
                        />
                      }
                    />
                  )}{" "}
                  <CustomDesign
                    label={"Engellilik Durumu"}
                    required={true}
                    child={
                      <BitonisSingleDropdown
                        items={
                          disabilityEmployerList &&
                          disabilityEmployerList.map(({ id, name }) => ({
                            value: id,
                            label: name,
                          }))
                        }
                        register={register}
                        watch={watch}
                        companyClassName={"disabilityStatus"}
                        control={control}
                        setValue={setValue}
                        errors={errors}
                        placeholder="Engellilik Durumu Seçin"
                        onValueChanged={(e) => {
                          console.log(e);
                          if (e) {
                            if (
                              e.value.toString() == "0" ||
                              e.value.toString() == "1"
                            ) {
                              setValue("degreeOfDisability", "");
                            }
                          }
                        }}
                        required={true}
                        getValues={getValues}
                        errorMessage={"Engellilik Durumu Boş geçilemez"}
                      />
                    }
                  />
                  {(disabilityStatus && disabilityStatus.value === 0) ||
                  (disabilityStatus && disabilityStatus.value === "") ||
                  (disabilityStatus && disabilityStatus.value === null) ||
                  disabilityStatus === "" ||
                  disabilityStatus === undefined ||
                  (disabilityStatus && disabilityStatus.value === 1) ? (
                    <div></div>
                  ) : (
                    <CustomDesign
                      label={"Engellilik Durum Seviyesi"}
                      required={true}
                      child={
                        <BitonisMultiDropdown
                          items={
                            props.disabilityOfDegreeList
                              .disabilityOfDegreeList &&
                            props.disabilityOfDegreeList.disabilityOfDegreeList.map(
                              ({ id, name }) => ({
                                value: id,
                                label: name,
                              })
                            )
                          }
                          register={register}
                          watch={watch}
                          companyClassName={"degreeOfDisability"}
                          control={control}
                          setValue={setValue}
                          errors={errors}
                          errorMessage={
                            "Engellilik Durum Seviyesi Boş geçilemez"
                          }
                          placeholder="Engellilik Durum Seviyesi"
                          getValues={getValues}
                          onValueChanged={(value) => {
                            console.log(value);
                          }}
                          required={true}
                        />
                      }
                    />
                  )}
                  <ProgramsCertificatesCompetences
                    programList={
                      props.programList.success && props.programList.programList
                    }
                    certificateList={
                      props.certificatesList.success &&
                      props.certificatesList.certificatesList
                    }
                    CompetenceList={
                      props.competencesList.success &&
                      props.competencesList.competencesList
                    }
                    control={control}
                    register={register}
                    watch={watch}
                    setValue={setValue}
                    errors={errors}
                    getValues={getValues}
                    checkCertificate={checkCertificate}
                    checkCompetence={checkCompetence}
                    checkProgram={checkProgram}
                    setCheckCertificate={setCheckCertificate}
                    setCheckCompetence={setCheckCompetence}
                    setCheckProgram={setCheckProgram}
                    preview={true}
                  />
                </form>
              </Modal.Body>
              <Modal.Footer
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  alignContent: "center",
                  borderTop: "none",
                  margin: "0px",
                  padding: "0px 0px 15px 0px",
                }}
              >
                <form
                  onSubmit={(e) => {
                    e.preventDefault();
                    handleSubmit(onSubmit)();
                    if (isValid) {
                      handleClose();
                    }
                  }}
                >
                   <CustomButton title={"Kaydet"} inSmallModal={false}/>
                </form>
              </Modal.Footer>
            </div>
          </Modal>
        </div>
      </>
    </div>
  );
};

export default EditWhitePositionModal;
