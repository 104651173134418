import React, { useEffect, useState } from "react";
import { DemandsServices } from "../../../../../../../../../services";
import { ToastContainer, toast } from "react-toastify";
import BlockCandidateModal from "./BlockDialog/BlockCandidateModal";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { useDispatch } from "react-redux";
import { setCurrentTabNotHire } from "../../../../../../../../../redux/redux-toolkit/selectedNotHireTab";
import { Box } from "@mui/material";
import CustomButton from "../../../../../../../../widgets/buttons/CustomButton";

const BlockButtons = ({
  Detail,
  positionType,
  companyId,
  employerId,
  status,
}) => {
  const [show, setShow] = useState(false);
  const history = useHistory();
  const dispatch = useDispatch();
  function handleClose() {
    setShow(false);
  }
  function updateBlock() {
    var positionName = "";
    if (positionType === 1) {
      let data = Detail.matchedJobList;
      let positionNames = data.map((value) => value.jobPosition).join(",");

      // Eğer positionNames boşsa bu durumun kontrolünü yapabilirsiniz
      positionName = positionNames === "" ? "" : positionNames;
    } else {
    }
    var sendData = {
      candidateId: Detail.candidateId,
      companyId: companyId,
      employerId: employerId,
      positionName:
        positionType == 2 ? Detail.pozitionMatchDetailCandidate : positionName,
      labourerMatchId:
        positionType == 2 ? null : Detail.matchedJobList[0].matchId,
      employeeMatchId: positionType == 1 ? null : Detail.matchId,
      matchRestrictionStatus: 2, //engelle ,,2 engeli kaldır
      restrictionReasonId: null,
    };
    DemandsServices.employerBlockCandidateUpdate(sendData).then((response) => {
      if (response.succeeded == true) {
        if (positionType == 1) {
          dispatch(setCurrentTabNotHire(0));
        } else {
          dispatch(setCurrentTabNotHire(1));
        }

        toast.success("İşleminiz başarılı şekilde gerçekleşti", {
          autoClose: 2000,
          onClose: () => {
            setTimeout(() => {
              history.push("/notHire");
            }, 100);
          },
        });
      } else {
        toast.info(response.error.message, {
          autoClose: 2000,
          onClose: () => {
            setTimeout(() => {}, 100);
          },
        });
      }
    });
  }
  var bodyButton;
  const Block = () => {
    return (
      <Box
        sx={{
          margin: "0px",
        }}
      >
        <CustomButton
          onTap={(val) => {
            val.preventDefault();
            setShow(true);
          }}
          title={"Engelle"}
          border={"none"}
          backgroundColor={"red"}
          hoverBackgroundColor={"rgb(231, 32, 32)"}
          width={{
            xs: "380px",
            sm: "380px",
            md: "450px",
            lg: "450px",
            xl: "600px",
          }}
        />
      </Box>
    );
  };
  if (Detail && Detail.matchRestrictionStatus === 0) {
    //engelleme tanımlanmadı
    if (status == 15 || status == 14) {
      return <div></div>;
    } else {
      return (
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignContent: "center",
            alignItems: "center",
          }}
        >
          <Block />
          <BlockCandidateModal
            show={show}
            handleClose={handleClose}
            onApproved={(value) => {}}
            positionType={positionType}
            Detail={Detail}
            companyId={companyId}
            employerId={employerId}
          />
        </div>
      );
    }
  } else if (Detail && Detail.matchRestrictionStatus === 1) {
    //Engeli Kaldır

    return (
      <div
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignContent: "center",
          alignItems: "center",
        }}
      >
        <Box
          sx={{
            margin: "0px",
          }}
        >
          <CustomButton
            onTap={(val) => {
              val.preventDefault();
              updateBlock();
            }}
            title={"Engeli Kaldır"}
            border={"none"}
            backgroundColor={"red"}
            hoverBackgroundColor={"rgb(231, 32, 32)"}
            width={{
              xs: "380px",
              sm: "380px",
              md: "450px",
              lg: "450px",
              xl: "600px",
            }}
          />
        </Box>
      </div>
    );
  } else if (Detail && Detail.matchRestrictionStatus === 2) {
    if (status == 15 || status == 14) {
      return <div></div>;
    } else {
      return (
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignContent: "center",
            alignItems: "center",
          }}
        >
          <Block />
          <BlockCandidateModal
            show={show}
            handleClose={handleClose}
            onApproved={(value) => {}}
            positionType={positionType}
            Detail={Detail}
            companyId={companyId}
            employerId={employerId}
          />
        </div>
      );
    }

    //Engelle
  } else {
    return <div></div>;
  }
};

export default BlockButtons;
