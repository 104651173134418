import { ToastContainer, toast } from "react-toastify";

export default function languageAndLevelAdd(
  language,
  setLanguage,
  languageLevel,
  setLanguageLevel,
  languageList,
  setLanguageList,
  setValue
) {
  if (language && languageLevel) {
    const updatedNewValue = {
      languageId: language,
      languageLevelId: languageLevel,
    };

    const isValueInList = languageList.some(
      (item) => item.languageId.value === updatedNewValue.languageId.value
    );

    if (!isValueInList) {
      let newLanguageData = [...languageList, updatedNewValue];
      setLanguageList(newLanguageData);
      setValue("languageId", "");
      setValue("languageLevelId", "");
      setLanguage(null);
      setLanguageLevel(null);
        toast.info("Yabancı Dil başarılı şekilde eklendi", {
          autoClose: 3000,
          onClose: () => {
            setTimeout(() => {}, 100);
          },
        });
    } else {
       toast.error("Bu Dil'i Daha Önce Eklediniz", {
         autoClose: 3000,
         onClose: () => {
           setTimeout(() => {}, 100);
         },
       });
      setValue("languageId", "");
      setValue("languageLevelId", "");
      setLanguage(null);
      setLanguageLevel(null);
    }
  } else {
    setValue("languageId", "");
    setValue("languageLevelId", "");
    setLanguage(null);
    setLanguageLevel(null);
  }
}
