import React from "react";
import CustomPdfPreview from "../../../../../../../../widgets/CustomPdfPreview";
import DividerDotted from "../../../../../../../../widgets/DividerDotted";

const InfoBody = ({ blueMatchDetail }) => {
  return (
    <div
      className="card"
      style={{
        flex: "6",
        marginTop: "7px",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          marginBottom: "10px",
          padding: "0px",
        }}
      >
        <div
          style={{
            border: "2px solid grey",
            borderRadius: "8px",

            height: "40px",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignContent: "center",
          }}
        >
          <p
            style={{
              padding: "10px",
              margin: "0px",
              fontSize: "10px",
              color: "#001542",
              fontWeight: "500",
            }}
          >
            Kişisel Bilgileri
          </p>
        </div>
        <hr
          style={{
            color: "black",
            marginLeft: "0px",
            paddingLeft: "0px",
            border: "1px solid grey",
            flex: "6",
            margin: "0px",
            padding: "0px",
          }}
        />
        <div
          style={{
            width: "40px",
            height: "40px",
            borderRadius: "50%",
            border: "1.8px solid grey",
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <i
            class="fa-solid fa-user"
            style={{
              color: "orange",
              fontSize: "12px",
            }}
          ></i>
        </div>
      </div>
      <div
        style={{
          backgroundColor: "#f7f7f7",
          borderRadius: "8px",
          marginTop: "10px",
          height: "500px",
          marginBottom: "30px",
        }}
      >
        <div
          style={{
            margin: "10px",
          }}
        >
          <CustomPdfPreview
            name={"Uyruk"}
            value={blueMatchDetail && blueMatchDetail.nationalityName}
          />{" "}
          <DividerPdf />
          <CustomPdfPreview
            name={"Cinsiyet"}
            value={blueMatchDetail && blueMatchDetail.genderName}
          />
          {blueMatchDetail && blueMatchDetail.genderId === 1 ? (
            <div></div>
          ) : (
            <CustomPdfPreview
              name={"Askerlik durumu"}
              value={blueMatchDetail && blueMatchDetail.militaryStatusName}
            />
          )}{" "}
          {blueMatchDetail && blueMatchDetail.genderId === 1 ? (
            <DividerPdf />
          ) : (
            <div></div>
          )}
          <CustomPdfPreview
            name={"Medeni Hal"}
            value={blueMatchDetail && blueMatchDetail.maritalStatusName}
          />{" "}
          <DividerPdf />
          <CustomPdfPreview
            name={"Sigara kullanımı"}
            value={blueMatchDetail && blueMatchDetail.isSmokingName}
          />
          <DividerPdf />
          <CustomPdfPreview
            name={"Sürücü Belgesi"}
            value={blueMatchDetail && blueMatchDetail.driverLicenseName}
          />{" "}
          {blueMatchDetail &&
            blueMatchDetail.driverLicenseTypeName &&
            blueMatchDetail.driverLicenseTypeName.length > 0 && (
              <div>
                {" "}
                <DividerPdf />
                <CustomPdfPreview
                  name={"Sürücü Belgesi Türü"}
                  value={blueMatchDetail.driverLicenseTypeName
                    .map((val) => val.drivingLicenceTypeName)
                    .join(", ")}
                />
              </div>
            )}{" "}
          {blueMatchDetail &&
            blueMatchDetail.driverLicenseTypeName &&
            blueMatchDetail.driverLicenseTypeName.length > 0 && <div></div>}
          <DividerPdf />
          <CustomPdfPreview
            name={"Eğitim Durumu"}
            value={blueMatchDetail && blueMatchDetail.educationName}
          />
          <DividerPdf />
          <CustomPdfPreview
            name={"Toplam İş Tecrübesi"}
            value={blueMatchDetail && blueMatchDetail.totalWorkExperience}
          />{" "}
          <DividerPdf />
          <CustomPdfPreview
            name={"Çalışma Şekli"}
            value={
              blueMatchDetail &&
              blueMatchDetail.wantToWorkPositions &&
              blueMatchDetail.wantToWorkPositions.workTypes
                .map((val) => val.workTypeName)
                .join(", ")
            }
          />
          <DividerPdf />
          <CustomPdfPreview
            name={"Engellilik Durumu"}
            value={blueMatchDetail && blueMatchDetail.disabilityName}
          />
          {(blueMatchDetail && blueMatchDetail.disabilityId == 1) ||
            (blueMatchDetail && blueMatchDetail.disabilityId == 0) ? (
            <div></div>
          ) : (
            <div>
              <DividerPdf />
              <CustomPdfPreview
                name={"Engellilik Seviyesi"}
                value={blueMatchDetail && blueMatchDetail.disabilityDegreeName}
              />
            </div>
          )}
          {blueMatchDetail &&
            blueMatchDetail.programs &&
            blueMatchDetail.programs.length > 0 && (
              <div>
                <DividerPdf />
                <CustomPdfPreview
                  name={"Programlar"}
                  value={
                    blueMatchDetail &&
                    blueMatchDetail.programs
                      .map((val) => val.programName)
                      .join(",")
                  }

                />
              </div>

            )}{" "}
          {blueMatchDetail &&
            blueMatchDetail.certificates &&
            blueMatchDetail.certificates.length > 0 && (
              <div>
                <DividerPdf />
                <CustomPdfPreview
                  name={"Sertifikalar"}
                  value={
                    blueMatchDetail &&
                    blueMatchDetail.certificates
                      .map((val) => val.certificateName)
                      .join(",")
                  }

                />
              </div>
            )}
          {blueMatchDetail &&
            blueMatchDetail.competencies &&
            blueMatchDetail.competencies.length > 0 && (
              <div>
                <DividerPdf />
                <CustomPdfPreview
                  name={"Yetkinlikler"}
                  value={
                    blueMatchDetail &&
                    blueMatchDetail.competencies
                      .map((val) => val.competenceName)
                      .join(",")
                  }


                />
              </div>

            )}
        </div>
      </div>
    </div>
  );
};

const DividerPdf = () => {
  return (
    <div>
      <div>
        <hr
          style={{
            marginTop: "0px",
            marginBottom: "0px",
            padding: "0px",
            paddingTop: "5px",
            border: "none",
            borderTop: "2px solid rgb(236, 227, 188)",
            color: " #fff",
            flex: "6",
          }}
        ></hr>
      </div>
    </div>
  );
};

export default InfoBody;
