export const TaxCountyConstants = {
    GET_REQUEST: 'TAXCOUNTY_GET_REQUEST',
    GET_SUCCESS: 'TAXCOUNTY_GET_SUCCESS',
    GET_CLEAR: 'TAXCOUNTY_GET_CLEAR',
    GET_FAILURE: 'TAXCOUNTY_GET_FAILURE',
    GET_ALL_REQUEST: 'TAXCOUNTY_GET_ALL_REQUEST',
    GET_ALL_SUCCESS: 'TAXCOUNTY_GET_ALL_SUCCESS',
    GET_ALL_FAILURE: 'TAXCOUNTY_GET_ALL_FAILURE',
};
export const TaxOfficesConstants = {
    GET_REQUEST: 'TAXOFFICES_GET_REQUEST',
    GET_SUCCESS: 'TAXOFFICES_GET_SUCCESS',
    GET_CLEAR: 'TAXOFFICES_GET_CLEAR',
    GET_FAILURE: 'TAXOFFICES_GET_FAILURE',
    GET_ALL_REQUEST: 'TAXOFFICES_GET_ALL_REQUEST',
    GET_ALL_SUCCESS: 'TAXOFFICES_GET_ALL_SUCCESS',
    GET_ALL_FAILURE: 'TAXOFFICES_GET_ALL_FAILURE',
};