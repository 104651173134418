import api from "../utility/Utils";

export const TaxInfoServices = {
    taxCounty,
    taxOffices,
};

function taxCounty(arg) { // Vergi Dairesi Şehir Listesi
    return api.post("/common/taxoffices/all", arg)
        .then((response) => {
            return Promise.resolve(response.data);
        })
        .catch((err) => {
           
            return Promise.reject(err);
        });
}
function taxOffices(cityCode) {
    const arg = {
      pageNumber: 1,
      pageSize: 500,
      keyword: "",
      cityCode: cityCode.toString(),
    };
    
    return api.post("/common/taxoffices/", arg)
        .then((response) => {
            return Promise.resolve(response.data);
        })
        .catch((err) => {
            return Promise.reject(err);
        });
}