import React from "react";
import CustomPreview from "../../../../../../../../widgets/CustomPreview";

const WhiteAbilities = ({
  cardStyle,
  labelStyle,
  inputStyle,
  whiteMatchDetail,
  matchPoint,
}) => {
  return (
    <div>
      {whiteMatchDetail &&
      whiteMatchDetail.matchLanguageDetailList &&
      whiteMatchDetail.matchLanguageDetailList.length > 0 ? (
        <div
          class="card"
          style={{
            margin: "0px 5% 5px 5%",
            padding: "0px",
            border: "0.5px solid #F7F7F7",
            backgroundColor: "F7F7F7",
          }}
        >
          <div
            class="card-header"
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
              borderBottom: "none",
              marginBottom: "0px",
              paddingBottom: "0px",
            }}
          >
            <h5
              class="card-title"
              style={{
                fontSize: "17px",
              }}
            >
              Yetenekleri
            </h5>
          </div>
          <div
            class="card-body"
            style={{
              marginTop: "0px",
              paddingTop: "10px",
            }}
          >
            {whiteMatchDetail &&
              whiteMatchDetail.matchLanguageDetailList &&
              whiteMatchDetail.matchLanguageDetailList.map(
                (language, index) => {
                  return (
                    <div key={"language"+index}>
                      <CustomPreview
                        name={"Yabancı Dil"}
                        value={language && language.languageName}
                        matchPointStatus={
                          matchPoint &&
                          matchPoint.isLanguageMatched &&
                          matchPoint.isLanguageMatched
                        }
                      />{" "}
                      <CustomPreview
                        name={"Yabancı Dil Seviyesi"}
                        value={language && language.languageLevelName}
                      />{" "}
                      {index !==
                        whiteMatchDetail.matchLanguageDetailList.length - 1 && (
                        <hr style={{ borderTop: "1px dotted lightgrey" }}></hr>
                      )}
                    </div>
                  );
                }
              )}
          </div>
        </div>
      ) : (
        <div></div>
      )}
    </div>
  );
};

export default WhiteAbilities;
