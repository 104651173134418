import React from "react";
import noMatchFound from "../../../../../../../images/svg/noMatchFound.svg";
import { Box, Typography } from "@mui/material";
import { Image } from "@mui/icons-material";
import DataNotFound from "../MatchShow/WhiteMatchShow/widgets/DataNotFound";

const BlockNotFound = () => {
  return (
    <div>
      <DataNotFound title={"Engellenen Aday Bulunamadı"} notFoundImage={noMatchFound} />
    </div>
  );
};

export default BlockNotFound;
