import React, { useState } from "react";
import { DashboardServices } from "../../../../../../services/DashboardServices";
import { styled } from "@mui/system";
import Box from "@mui/material/Box";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import ReadNotification from "./ReadNotification/ReadNotification";
import UnreadNotification from "./UnreadNotification/UnreadNotification";
import "../../../../../../css/bitoniscss/notificationItem.css";

const CenteredTabs = styled(Box)({
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  marginTop: "16px",
  border: "1px dotted transparent",
  borderRadius: "8px",
});

const CustomTab = styled(Tab)(({ theme }) => ({
  borderBottom: "2px solid transparent",
  color: "grey",
  textTransform: "capitalize",
  "&.Mui-selected": {
    color: "orange",
    borderBottom: "2px solid black",
  },
}));

const NotificationPage = () => {
  const [currentIndex, setCurrentIndex] = useState(0);

  function handleCurrentIndex(event, newValue) {
    setCurrentIndex(newValue);
  }

  return (
    <div
      className="parent-n-card"
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
        alignItems: "flex-start",
      }}
    >
      <div className="card notification-card">
        <div
          className="card-header"
          style={{
            justifyContent: "center",
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            border: "none",
          }}
        >
          <CenteredTabs>
            <Tabs
              value={currentIndex}
              onChange={handleCurrentIndex}
              aria-label="nav tabs example"
              TabIndicatorProps={{ style: { backgroundColor: "transparent" } }}
            >
              <CustomTab label="Okunmamış" style={{}} />
              <div
                style={{
                  margin: "0px 20px 0px 20px",
                }}
              ></div>
              <CustomTab label="Okunmuş" />
            </Tabs>
          </CenteredTabs>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <div className="getNotification">Gelen Bildirimler :</div>
          {currentIndex === 0 && (
            <div>
              <UnreadNotification />
            </div>
          )}
          {currentIndex === 2 && (
            <div>
              <ReadNotification />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default NotificationPage;
