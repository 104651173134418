import { TaxCountyConstants, TaxOfficesConstants } from "../constants";
import { TaxInfoServices } from "../services";
import cogoToast from 'cogo-toast';

export const TaxInfoAction = {
    taxCounty,
    clearRecruitetDetail,
    taxOffices,
};



function taxCounty(arg) { // Vergi Dairesi Şehir Listesi
    return (dispatch) => {
        dispatch(request());
        TaxInfoServices.taxCounty(arg).then(
            (taxCounty) => {
                dispatch(success(taxCounty));
            },
            (error) => {
                dispatch(failure(error));
            }
        );
    };

    function request() {
        return {
            type: TaxCountyConstants.GET_ALL_REQUEST
        };
    }

    function success(taxCounty) {
        return {
            type: TaxCountyConstants.GET_ALL_SUCCESS, taxCounty
        };
    }

    function failure(error) {
        return {
            type: TaxCountyConstants.GET_ALL_FAILURE, error
        };
    }
}

function taxOffices(arg) { // Vergi Dairesi Listesi
    return (dispatch) => {
        dispatch(request());
        TaxInfoServices.taxOffices(arg).then(
            (taxOffices) => {
                dispatch(success(taxOffices));
            },
            (error) => {
                dispatch(failure(error));
            }
        );
    };

    function request() {
        return {
            type: TaxOfficesConstants.GET_ALL_REQUEST
        };
    }

    function success(taxOffices) {
        return {
            type: TaxOfficesConstants.GET_ALL_SUCCESS, taxOffices
        };
    }

    function failure(error) {
        return {
            type: TaxOfficesConstants.GET_ALL_FAILURE, error
        };
    }
}
function clearRecruitetDetail() {
    return (dispatch) => {
        return dispatch({ type: RecruitedDetailConstants.GET_CLEAR });
    };
}

