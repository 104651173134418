import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  activeCurrentTab: 0,
};

const demandBlock = createSlice({
  name: "demandBlockTab",
  initialState,
  reducers: {
    setDemandBlockTab: (state, action) => {
      state.activeCurrentTab = action.payload;
    },
  
  },
});

export const { setDemandBlockTab } = demandBlock.actions;
export default demandBlock.reducer;
