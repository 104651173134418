import React from "react";

const InternCvWantLocation = ({ internMatchDetail }) => {
  return (
    <div
      style={{
        flex: "6",
        marginLeft: "10px",
        marginRight: "0px",
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            marginBottom: "10px",
            padding: "0px",
          }}
        >
          <div
            style={{
              border: "2px solid grey",
              borderRadius: "8px",

              height: "40px",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignContent: "center",
            }}
          >
            <p
              style={{
                padding: "10px",
                margin: "0px",
                fontSize: "10px",
                color: "#001542",
                fontWeight: "500",
              }}
            >
              Staj Aradığım Lokasyonlar
            </p>
          </div>
          <hr
            style={{
              color: "black",
              marginLeft: "0px",
              paddingLeft: "0px",
              border: "1px solid grey",
              flex: "6",
              margin: "0px",
              padding: "0px",
            }}
          />
          <div
            style={{
              width: "40px",
              height: "40px",
              borderRadius: "50%",
              border: "1.8px solid grey",
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <i
              class="fa-solid fa-location-dot"
              style={{
                color: "orange",
                fontSize: "12px",
              }}
            ></i>
          </div>
        </div>
        <div
          style={{
            marginTop: "0px",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <div
            style={{
              marginTop: "0px",
              paddingTop: "0px",
              height: "180px",
              backgroundColor: "#f5f5f5",
              borderRadius: "8px",
              display: "flex",
              flexDirection: "column",
              justifyContent: "flex-start",
              alignItems: "flex-start",
              margin: "0px",
              padding: "0px",
            }}
          >
            <p
              style={{
                margin: "10px",
              }}
            >
              {internMatchDetail &&
                internMatchDetail.matchCandidateLocations.map(
                  (location, index) => {
                    return (
                      <div key={index}>
                        {index !== 0 && <DividerPdf />}{" "}
                        {/* İlk itemden önce divider */}
                        <p
                          style={{
                            fontSize: "9px",
                            color: "#001542",
                            fontWeight: "500",
                            marginLeft: "5px",
                            width: "100%",
                          }}
                        >
                          {location.countryName + "/" + location.cityName}
                        </p>
                      </div>
                    );
                  }
                )}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};
const DividerPdf = () => {
  return (
    <div>
      <div>
        <hr
          style={{
            marginTop: "0px",
            marginBottom: "0px",
            padding: "0px",
            paddingTop: "5px",
            border: "none",
            borderTop: "2px solid rgb(236, 227, 188)",
            color: " #fff",
            flex: "6",
            width: "500px",
          }}
        ></hr>
      </div>
    </div>
  );
};
export default InternCvWantLocation;
