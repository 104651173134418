import {
  DashboardConstants,
  DashboardMatchConstants,
  JobConstants,
  JobInternConstant,
  LastDashboardConstants,
} from "../constants";
import { DashboardServices } from "../services";
import cogoToast from "cogo-toast";
import { ToastContainer, toast } from "react-toastify";

export const DashboardAction = {
  lastLabourer,
  labourer,
  addlabourer,
  create,
  clearDashboard,
  clearModal,
  clearJob,
  labourerMatch,
  jobDetail,
  payment,
  jobInternDetail,
};

function jobDetail(arg,) {
  return (dispatch) => {
    dispatch(request());
    DashboardServices.jobDetail(arg).then(
      (jobDetail) => {
        dispatch(success(jobDetail));
      },
      (error) => {
        dispatch(failure(error));
      }
    );
  };

  function request() {
    return { type: JobConstants.GET_ALL_REQUEST };
  }

  function success(jobDetail) {
    return { type: JobConstants.GET_ALL_SUCCESS, jobDetail };
  }

  function failure(error) {
    return { type: JobConstants.GET_ALL_FAILURE, error };
  }
}

function jobInternDetail(arg) {
 
  return (dispatch) => {
    dispatch(request());
    DashboardServices.jobInternDetail(arg).then(
      (jobDetail) => {
        dispatch(success(jobDetail));
      },
      (error) => {
        dispatch(failure(error));
      }
    );
  };

  function request() {
    return { type: JobConstants.GET_ALL_REQUEST };
  }

  function success(jobDetail) {
    return { type: JobConstants.GET_ALL_SUCCESS, jobDetail };
  }

  function failure(error) {
    return { type: JobConstants.GET_ALL_FAILURE, error };
  }
}

function lastLabourer(arg, handle = false) {
  if (handle) {
    return DashboardServices.lastLabourer(arg);
  }
  return (dispatch) => {
    dispatch(request());
    DashboardServices.lastLabourer(arg).then(
      (lastLabourer) => {
        dispatch(success(lastLabourer));
      },
      (error) => {
        dispatch(failure(error));
      }
    );
  };

  function request() {
    return { type: LastDashboardConstants.GET_ALL_REQUEST };
  }

  function success(lastLabourer) {
    return { type: LastDashboardConstants.GET_ALL_SUCCESS, lastLabourer };
  }

  function failure(error) {
    return { type: LastDashboardConstants.GET_ALL_FAILURE, error };
  }
}
function labourer(arg, handle = false) {
  if (handle) {
    return DashboardServices.labourer(arg);
  }
  return (dispatch) => {
    dispatch(request());
    DashboardServices.labourer(arg).then(
      (labourer) => {
        dispatch(success(labourer));
      },
      (error) => {
        dispatch(failure(error));
      }
    );
  };

  function request() {
    return { type: DashboardConstants.GET_ALL_REQUEST };
  }

  function success(labourer) {
    return { type: DashboardConstants.GET_ALL_SUCCESS, labourer };
  }

  function failure(error) {
    return { type: DashboardConstants.GET_ALL_FAILURE, error };
  }
}

function labourerMatch(arg, handle = false) {
  if (handle) {
    return DashboardServices.labourerMatch(arg);
  }
  return (dispatch) => {
    dispatch(request());
    DashboardServices.labourerMatch(arg).then(
      (labourerMatch) => {
        dispatch(success(labourerMatch));
      },
      (error) => {
        dispatch(failure(error));
      }
    );
  };

  function request() {
    return { type: DashboardMatchConstants.GET_ALL_REQUEST };
  }

  function success(labourerMatch) {
    return { type: DashboardMatchConstants.GET_ALL_SUCCESS, labourerMatch };
  }

  function failure(error) {
    return { type: DashboardMatchConstants.GET_ALL_FAILURE, error };
  }
}

function addlabourer(labourer) {
  return { type: DashboardMatchConstants.GET_ALL_SUCCESS, labourer };
}

function payment(arg, handle = false) {
  DashboardServices.payment(arg).then(
    (payment) => {
      if (payment) {
        if (payment.succeeded === true) {
          toast.success(payment.result.message);
          
        } else {
          toast.info(payment.error.message);
        }
      } else {
          toast.info("Hata Oluştu * * ");
      }
    },
    (error) => {
      console.log(error);
    }
  );
}

function create(data) {
  if (data.parameters) {
    data.parameters = Object.assign({}, data.parameters);
  }
  return (dispatch) => {
    dispatch(request());
    DashboardServices.create(data).then(
      (labourer) => {
        dispatch(success(labourer));
      },
      (error) => {
        //console.log("hatası : " + error);
        dispatch(failure(error));
      }
    );
  };

  function request() {
    return { type: DashboardConstants.GET_REQUEST };
  }

  function success(labourer) {
    return { type: DashboardConstants.GET_SUCCESS, labourer };
  }

  function failure(error) {
    return { type: DashboardConstants.GET_FAILURE, error };
  }
}

function clearDashboard() {
  return (dispatch) => {
    return dispatch({ type: DashboardConstants.GET_CLEAR });
  };
}
function clearModal() {
  return (dispatch) => {
    return dispatch({ type: DashboardMatchConstants.GET_CLEAR });
  };
}
function clearJob() {
  return (dispatch) => {
    return dispatch({ type: JobConstants.GET_CLEAR });
  };
}

function devicename(data) {
  return (dispatch) => {
    //console.log("device", data)
    dispatch(request());
    DashboardServices.devicename(data).then(
      (labourer) => {
        dispatch(success(labourer));
      },
      (error) => {
        //console.log("hatası : " + error);
        dispatch(failure(error));
      }
    );
  };

  function request() {
    return { type: DashboardConstants.GET_REQUEST };
  }

  function success(labourer) {
    return { type: DashboardConstants.GET_SUCCESS, labourer };
  }

  function failure(error) {
    return { type: DashboardConstants.GET_FAILURE, error };
  }
}

function loaddevice(data) {
  return (dispatch) => {
    //console.log("device", data)
    dispatch(request());
    DashboardServices.loaddevice(data).then(
      (labourer) => {
        cogoToast.success("Kaydedildi!");
        dispatch(success(labourer));
      },
      (error) => {
        cogoToast.error("hatası : " + error);
        dispatch(failure(error));
      }
    );
  };

  function request() {
    return { type: DashboardConstants.GET_REQUEST };
  }

  function success(labourer) {
    return { type: DashboardConstants.GET_SUCCESS, labourer };
  }

  function failure(error) {
    return { type: DashboardConstants.GET_FAILURE, error };
  }
}

function liveget(arg = {}, dispatch = true) {
  if (!dispatch) {
    return labourerervice.liveget(arg);
  }
  return (dispatch) => {
    dispatch(request());
    DashboardServices.liveget(arg).then(
      (device) => {
        dispatch(success(device));
      },
      (error) => {
        dispatch(failure(error));
      }
    );
  };

  function request() {
    return { type: DashboardConstants.GET_ALL_REQUEST };
  }

  function success(labourer) {
    return { type: DashboardConstants.GET_ALL_SUCCESS, labourer };
  }

  function failure(error) {
    return { type: DashboardConstants.GET_ALL_FAILURE, error };
  }
}
