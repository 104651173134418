import React, { useState } from "react";
import PlayAndSendVideoCvModal from "../PlayAndSendVideoCvModal";
import { DemandsServices } from "../../../../../../../../../../services";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { setCurrentDemandVideoCvTab } from "../../../../../../../../../../redux/redux-toolkit/demandVideoCvTab";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { Box } from "@mui/material";
import CustomButton from "../../../../../../../../../widgets/buttons/CustomButton";

const VideoCvButton = ({ matchId, tooltip, whiteMatchDetail }) => {
  const [show, setShow] = useState(false);
  const dispatch = useDispatch();
  const [writeVideoCv, setWriteVideoCv] = useState("");
  const history = useHistory();
  function handleClose() {
    setShow(false);
  }

  function sendVNewVideoConference(matchId, writeVideoCv) {
    DemandsServices.sendRequestVideoCv(matchId, writeVideoCv).then(
      (videoCv) => {
        if (videoCv) {
          if (videoCv.succeeded === true) {
            //bu yönlendirme tamamlandı
            dispatch(setCurrentDemandVideoCvTab(0));
            toast.success("Ek Video CV Talebiniz Gönderildi", {
              autoClose: 2000,
              onClose: () => {
                setTimeout(() => {
                  //  window.location.href = window.location.href;
                  history.push("/matchVideoRequest");
                }, 100);
              },
            });
          } else {
            toast.info(videoCv.error.message, {
              autoClose: 2000,
              onClose: () => {
                setTimeout(() => {
                  //  window.location.href = window.location.href;
                }, 100);
              },
            });
          }
        }
      },
      (error) => {
        toast.info("Beklenmedik Hata Oluştu", {
          autoClose: 2000,
          onClose: () => {
            setTimeout(() => {
              //   window.location.href = window.location.href;
            }, 100);
          },
        });
      }
    );
  }

  return (
    <>
      <PlayAndSendVideoCvModal
        show={show}
        handleClose={handleClose}
        whiteMatchDetail={whiteMatchDetail}
        onApproval={(value) => {
          if (value) {
            sendVNewVideoConference(matchId, value);
          }
        }}
      />
      <div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Box
            sx={{
              margin: "0px",
            }}
          >
            <CustomButton
              onTap={(val) => {
                val.preventDefault();
                if (whiteMatchDetail) {
                  setShow(true);
                }
              }}
              title={"Video Cv"}
              width={{
                xs: "380px",
                sm: "380px",
                md: "450px",
                lg: "450px",
                xl: "600px",
              }}
            />
          </Box>
        </div>
      </div>
    </>
  );
};

export default VideoCvButton;
