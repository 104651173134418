import { useState } from "react";
import { toast } from "react-toastify";
import { DemandsServices } from "../../../../../../../../../../services";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { useDispatch } from "react-redux";

import CustomDatePicker from "../../../../../../../../../widgets/CustomDatePicker";
import { setDemandConferencesMeetTab } from "../../../../../../../../../../redux/redux-toolkit/demandConferencesMeet";
import { Box } from "@mui/material";
import CustomButton from "../../../../../../../../../widgets/buttons/CustomButton";

const ChangeEmployerVideoConferenceMeetingDay = ({
  matchId,
  tooltip,
}) => {
  const history = useHistory();
  const dispatch = useDispatch();
  //Video Konferans İçin Yeni Gün/Saat Belirle
  const [show, setShow] = useState(false);
  function handleCloseDate() {
    setShow(false);
  }
  function sendVideoConference(matchId, date) {
    DemandsServices.changeEmployerVideoConferenceMeetingDay(
      matchId,
      date,
    ).then(
      (videoConferencesData) => {
        if (videoConferencesData) {
          if (videoConferencesData.succeeded === true) {
            dispatch(setDemandConferencesMeetTab(0));
            toast.success("Video Konferans Gün ve Saati Gönderildi", {
              autoClose: 2000,
              onClose: () => {
                setTimeout(() => {
                  history.push("/matchVideoConferencesMeets");
                  //  window.location.href = window.location.href;
                }, 100);
              },
            });
          } else {
            toast.info(videoConferencesData.error.message, {
              autoClose: 2000,
              onClose: () => {
                setTimeout(() => {
                  //  window.location.href = window.location.href;
                }, 100);
              },
            });
          }
        }
      },
      (error) => {
        toast.info("Beklenmedik Hata Oluştu", {
          autoClose: 2000,
          onClose: () => {
            setTimeout(() => {
              // window.location.href = window.location.href;
            }, 100);
          },
        });
      }
    );
  }

  const [isPickerOpen, setIsPickerOpen] = useState(false);

  const handleOpen = () => {
    setIsPickerOpen(true);
  };

  const handleClose = () => {
    setIsPickerOpen(false);
  };
  return (
    <div>
      <CustomDatePicker
        isPickerOpen={isPickerOpen}
        setIsPickerOpen={setIsPickerOpen}
        handleClose={handleClose}
        handleOpen={handleOpen}
        onChange={(date) => {
          console.log("tarih " + date);
          if (date) {
            sendVideoConference(matchId, date);
          }
        }}
      />

      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
      
        <Box
            sx={{
              margin: "0px",
            }}
          >
            <CustomButton
              onTap={(val) => {
                setIsPickerOpen(true);
              }}
              title={"Video Konferans İçin Yeni Gün/Saat Belirle"}
              width={{
                xs: "380px",
                sm: "380px",
                md: "450px",
                lg: "450px",
                xl: "600px",
              }}
              backgroundColor={"#068FFF"}
              color={"white"}
              hoverBackgroundColor={"#056bbe"}
              border={"none"}
            />
          </Box>
      </div>
    </div>
  );
};

export default ChangeEmployerVideoConferenceMeetingDay;
