import React, { useState } from "react";
import { Card, Tab, Nav } from "react-bootstrap";
import "../../../../../../../css/bitoniscss/demandAllShow.css";
import BlueAllShow from "./BlueAllShow";
import WhiteAllShow from "./WhiteAllShow";
import { useDispatch, useSelector } from "react-redux";
import { setCurrentAllTab } from "../../../../../../../redux/redux-toolkit/filterAllShow";
import BitonisTabBar from "../../../../../../widgets/BitonisTabBar/BitonisTabBar";
import PageInfoTitle from "../../../../../../widgets/PageInfoTitle";
const DemandAllShow = () => {

  const dispatch = useDispatch();

  const { activeCurrentTab } = useSelector((state) => state.selectedAllTab);
  const selectedChangeTab = (select) => {
    dispatch(setCurrentAllTab(select));
  };

  return (
    <div id="demandShow">
        <PageInfoTitle title={"Taleplerim"} />
     

      <div className="row" style={{
        marginTop:"0px",
        paddingTop:"0px"
      }}>
        <div className="col-md-12">
          <Card>
            <Card.Body>
              <BitonisTabBar
                tabLeftTitle={"Mavi Yaka"}
                tabRightTitle={"Beyaz Yaka"}
                tabLeftBody={<BlueAllShow />}
                tabRightBody={<WhiteAllShow />}
                showBorder={false}
                activeTab={activeCurrentTab}
                tabStyle={{
                  fontSize: "0.8rem",
                }}
                onChangeTab={(tab) => { }}
              />
            </Card.Body>
          </Card>
        </div>
      </div>
    </div>
  );
};

export default DemandAllShow;
