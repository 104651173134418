import React, { useEffect, useState } from "react";
import "../../../../../../../css/bitoniscss/locationcompany.css";
import {
  useHistory,
  useLocation,
} from "react-router-dom/cjs/react-router-dom.min";
import { useForm } from "react-hook-form";
import { Alert, Button, Col, Row } from "react-bootstrap";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { connect, useDispatch } from "react-redux";
import api from "../../../../../../../utility/Utils";
import EmployerGroupCompaniesAddLocation from "./EmployerGroupCompaniesAddLocation";
import LoadingDotsAnimation from "../../../../../../widgets/LoadingDotsAnimation";
import useIsMobile from "../../../../../../hooks/useIsMobile";
import EditIconWidget from "../../../../../../widgets/EditIconWidget";
import EmployerCompaniesAddedLocationEdit from "./EmployerCompaniesAddedLocationEdit";
import PrevIconButton from "../../../../../../widgets/buttons/PrevIconButton";

const EmployerGroupLocations = (props) => {
  const isMobile = useIsMobile();

  const history = useHistory();
  const {
    register,
    handleSubmit,
    control,
    watch,
    reset,
    setValue,
    formState: { errors },
  } = useForm();
  const [locations, setLocations] = useState(null);
  const [editLocationIndex, setEditLocationIndex] = useState(-1);
  const [editShow, setEditShow] = useState([]);
  const [adress, setAdress] = useState("");
  const location = useLocation();
  const { dataMap } = location.state;
  const companyParentId = dataMap.get("companyId");
  const [showLocation, setShowLocation] = useState(false);
  const [mainLocationName, setMainLocationName] = useState("");
  const [companyLocationId, setCompanyLocationId] = useState(null);
  const [companyNameForLocation, setCompanyNameForLocation] = useState(null);
  const handleCloseAdd = () => {
    setShowLocation(false);
  };
  const handleCloseEdit = () => {
    setEditShow(false);
  };
  const [editLocationCompanies, setEditLocation] = useState(false);
  const handleCloseEditLocation = () => {
    setEditLocation(false);
  };
  function getCompanyLocations(id) {
    //Lokasyonalrı getir
    return api
      .get("/company/location/listAddress/" + id.toString())
      .then((response) => {
        return Promise.resolve(response);
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  }

  function getCompanyIdToHeader(id) {
    //Lokasyonalrı getir
    return api
      .get("/company/groupHeader/" + id.toString())
      .then((response) => {
        return Promise.resolve(response);
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  }


  useEffect(() => {
    if (companyParentId) {
      getCompanyLocations(companyParentId).then(
        (locationRequest) => {
        
          setLocations([]);
          if (locationRequest.succeeded === true) {
            locationRequest.data.map((loc) => {
              setLocations((prev) => [...prev, loc]);
            });
          } else {
          }
        },
        (error) => {
          toast.info("Hata Oluştu", {
            autoClose: 2000,
            onClose: () => {
              setTimeout(() => {
                window.location.href = window.location.href;
              }, 100);
            },
          });
        }
      );
      getCompanyIdToHeader(companyParentId).then(
        (companyIdToHeaderResponse) => {
          if (companyIdToHeaderResponse.succeeded === true) {
            setCompanyNameForLocation(companyIdToHeaderResponse.data.name)
          } else {
          }
        },
        (error) => {

        }
      );
    }
  }, [companyParentId]);

  return (
    <div>
      <ToastContainer
        position="top-right"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      {
        <div className="card">
          <div
            style={{
              marginLeft: "25px",
              marginBottom: "0px",
              paddingBottom: "0px",
              display: "flex",
              flexDirection: "row",
              height:"100%"
            }}
          >
          </div>

          <form>
            <div
              className="card"
              style={{
                height: locations && locations.length <= 4 ? "500px" : "",
                position: "relative",       height:"100%"
              }}
            >
              <PrevIconButton top={10} left={5} />

              {
                <p
                  style={{
                    color: "#001542",
                    fontWeight: "600",
                    padding: "0px",
                    margin: "0px",
                    textAlign: "center",
                    marginTop: "10px",
                    fontSize: isMobile ? "15px" : "18px",
                  }}
                >
                  Lokasyonlarım
                </p>
              }

              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  alignContent: "flex-end",
                  alignItems: "flex-end",
                  flexDirection: "row",
                  marginTop: isMobile ? "30px" : "10px",
                  textAlign: "center",
                  position: "absolute",
                  right: "10px",
                  top: "0px",
                  marginRight: "25px",
                  fontSize: isMobile ? "9px" : "13px",
                }}
              >
                <div
                  style={{
                    width: isMobile ? "25px" : "30px",
                    height: isMobile ? "25px" : "30px",
                    marginTop: "10px",
                    padding: "0px",
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                    alignContent: "center",
                    alignItems: "center",
                    cursor: "pointer",
                  }}
                >
                  <div
                    style={{
                      backgroundColor: "green",
                      borderRadius: "6px",
                      width: isMobile ? "20px" : "30px",
                      height: isMobile ? "20px" : "30px",
                      alignItems: "center",
                      display: "flex",
                      justifyContent: "center",
                      transition: "fontSize 0.2s",
                    }}
                    onMouseOver={(e) => {
                      e.currentTarget.style.width = "35px";
                      e.currentTarget.style.height = "35px";
                    }}
                    onMouseOut={(e) => {
                      e.currentTarget.style.width = "30px";
                      e.currentTarget.style.height = "30px";
                    }}
                    onClick={() => {
                      setShowLocation(true);
                    }}
                  >
                    <i
                      class="fa-solid fa-plus"
                      style={{
                        transition: "fontSize 0.2s",
                        color: "white",
                      }}
                    ></i>
                  </div>
                </div>

                <div
                  style={{
                    color: "#001542",
                    fontWeight: "550",
                    marginLeft: "6px",
                    cursor: "pointer",
                    marginTop: "0px",
                    fontSize: isMobile ? "11px" : "12px",
                    marginBottom: "5px",
                  }}
                  onClick={() => {
                    setShowLocation(true);
                  }}
                >
                  Lokasyon Ekle
                </div>
              </div>
              {locations === null && (
                <div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "center",
                    }}
                  >
                    <LoadingDotsAnimation />
                  </div>
                </div>
              )}
              {locations && locations.length <= 0 && (
                <div>
                  <div
                    style={{
                      marginTop: isMobile ? "40%" : "20%",
                    }}
                  >
                    <div
                      class=""
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        alignContent: "center",
                        justifyContent: "center",
                        marginTop: "10px",
                      }}
                    >
                      <i
                        class="fa-solid fa-building"
                        style={{
                          fontSize: isMobile ? "70px" : "70px",
                        }}
                      ></i>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <p
                          style={{
                            color: "#001542",
                            marginTop: "10px",
                            marginBottom: "0px",
                            fontSize: "16px",
                            fontWeight: 600,
                            marginBottom: "0px",
                          }}
                        >
                          Lokasyon Bulunamadı
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              )}

              <Row
                style={{
                  margin: "10px",
                  padding: "5px",
                  marginTop: "7%",
                }}
              >
                {locations &&
                  locations.map((item, index) => (
                    <CompanyLocationItem
                      item={item}
                      index={index}
                      key={index}
                      length={locations && locations && locations.length}
                      setMainLocationName={setMainLocationName}
                      setEditLocationIndex={setEditLocationIndex}
                      setEditLocation={setEditLocation}
                      setCompanyLocationId={setCompanyLocationId}
                    />
                  ))}
              </Row>
              <EmployerGroupCompaniesAddLocation
                show={showLocation}
                handleClose={handleCloseAdd}
                props={props}
                companyId={companyParentId}
                companyNameForLocation={companyNameForLocation}
              />
              <EmployerCompaniesAddedLocationEdit
                show={editLocationCompanies}
                handleClose={handleCloseEditLocation}
                props={props}
                companyLocationId={companyLocationId}
                locationName={mainLocationName}
                companyId={companyParentId}
                companyNameForLocation={companyNameForLocation}
           
              />
            </div>
          </form>
        </div>
      }


    </div>
  );
};
const CompanyLocationItem = ({
  item,
  index,
  length,
  setMainLocationName,
  setEditLocationIndex,
  setEditLocation,
  setCompanyLocationId,
}) => {
  const isMobile = useIsMobile();
  const history = useHistory();
  return (
    <Col
      xs={12}
      sm={length == 1 ? 12 : 6}
      md={length == 1 ? 12 : 6}
      lg={length == 1 ? 12 : 6}
      xl={length == 1 ? 12 : 6}
      style={{
        marginBottom: "10px",
        cursor: "pointer",
      }}
      onClick={() => { }}
    >
      <div
        style={{
          display: "flex",
          alignItems: "flex-start",
          backgroundColor: " #f7f7f7",
          borderRadius: "6px",
          justifyContent: "flex-start",
          border: "1px solid #f3f3f6",
          padding: "20px",

          position: "relative",
          flexDirection: "column",
          paddingBottom: "20px",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            margin: "0px",
            padding: "0px",
            height: "40px",
          }}
        >
          <p
            style={{
              color: "#001542",
              fontWeight: "bold",
              fontSize: isMobile ? "11px" : "13px",
            }}
          >
            Lokasyon Adı :
          </p>
          <p
            style={{
              color: "#001542",
              fontWeight: 500,
              fontSize: isMobile ? "11px" : "13px",
              marginLeft: "5px",
            }}
          >
            {item.locationName}
          </p>
        </div>
        <div
          style={{
            width: "100%",
            border: "0.1px solid rgb(216, 216, 216)",
            padding: "0px",
            margin: "0px",
          }}
        />
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            marginTop: "10px",
            padding: "0px",
          }}
        >
          <p
            style={{
              color: "#001542",
              fontWeight: "bold",
              fontSize: isMobile ? "11px" : "13px",
            }}
          >
            Şirket Adresi :
          </p>

          <p
            style={{
              color: "#001542",
              fontWeight: 500,
              fontSize: isMobile ? "11px" : "13px",
              marginLeft: "5px",
            }}
          >
            {item.companyAddress}
          </p>
        </div>
        <div
          onClick={() => {
            setMainLocationName(item.locationName);
            setEditLocationIndex(index);
            setEditLocation(true);
            setCompanyLocationId(item.companyLocationId);
          }}
          style={{
            position: "absolute",
            top: "6%",
            right: "1.5%",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignContent: "center",
              alignItems: "center",
              padding: "10px"
            }}
          >
            <EditIconWidget size={14} showEditText={false} />
          </div>
        </div>
      </div>
    </Col>
  );
};
function mapState(state) {
  let authentication = state.authentication;
  let companyDetail = state.companyDetail;
  let countryList = state.countryList;
  let cityList = state.cityList;
  let countyList = state.countyList;
  let districtList = state.districtList;
  let companyMainDetail = state.companyMainDetail;
  let sectorList = state.sectorList;
  let taxOffices = state.taxOffices;

  return {
    authentication,
    companyDetail,
    countryList,
    cityList,
    countyList,
    districtList,
    companyMainDetail,
    sectorList,
    taxOffices,
  };
}

export default connect(mapState)(EmployerGroupLocations);
