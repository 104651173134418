import { toast } from "react-toastify";
import { DropListWhiteServices } from "../../../../../../../../services";

export default function updateWhiteRequestDetailsToAPI(
  form,
  jobParentId,
  preferredLocationList,
  foreignLanguageList,
  educationList,
  history,
  dispatch,
  checkProgram,
  checkCertificate,
  checkCompetence
) {
  const jobId = jobParentId;
  const companyId = form.companyId.value;
  const companyLocationId = form.companyLocationId.value;
  const positionId = form.positionId.value;
  const workTypes = form.workTypes.map((val) => parseInt(val.value.toString()));
  const jobExperienceRanges = form.jobExperienceRanges.map((val) =>
    parseInt(val.value.toString())
  );
  const educationTypes = parseInt(form.educationTypes.value.toString());
  const salaryRanges = form.salaryRanges.map((val) =>
    parseInt(val.value.toString())
  );
  const ageRanges = form.ageRanges.map((val) => parseInt(val.value.toString()));
  const employeeCount = parseInt(form.personCount.value.toString());
  const weekendWork = form.jobWeekendWorks.value;
  const businessTravelRestrictions = form.jobBusinessTravelRestrictions.map(
    (val) => parseInt(val.value.toString())
  );
  const languageId = null;
  const languageLevelId = null;
  const nationalityId = parseInt(form.nationalityId.value.toString());
  const gender = parseInt(form.gender.value.toString());
  const militaryStatus =
    form.militaryStatus.value == null ||
    form.militaryStatus == null ||
    form.militaryStatus.value.toString() == "null"
      ? null
      : parseInt(form.militaryStatus.value.toString());
  const smoking = parseInt(form.smokerApply.value.toString());
  const maritalStatus = parseInt(form.maritalStatus.value.toString());
  const drivingLicence = parseInt(form.drivingLicence.value.toString());
  const drivingLicenceTypes =
    form.drivingLicenceTypes == []
      ? []
      : form.drivingLicenceTypes.map((val) => parseInt(val.value.toString()));
  var jobPrograms = [];
  if (form.jobPrograms == []) {
    jobPrograms = [];
  } else {
    jobPrograms = form.jobPrograms.map((val) => val.value);
  }
  var jobCertificates = [];
  if (form.jobCertificates == []) {
    jobCertificates = [];
  } else {
    jobCertificates = form.jobCertificates.map((val) => val.value);
  }
  var jobCompetences = [];
  if (form.jobCompetences == []) {
    jobCompetences = [];
  } else {
    jobCompetences = form.jobCompetences.map((val) => val.value);
  }
  var jobPreferredLocations = [];

  preferredLocationList.map((data) => {
    let element = {};
    element["countryId"] = data.countryId.value;
    element["cityId"] = data.cityId.value;
    element["counties"] =
      data.countyId == [] || data.countyId == undefined || data.countyId == null
        ? []
        : data.countyId.map(({ value }) => value);
    element["districts"] =
      data.districts == [] ||
      data.districts == undefined ||
      data.districts == null
        ? []
        : data.districts.map(({ value }) => value);
    if (
      data &&
      data.lat !== undefined &&
      data.lat !== null &&
      data.lat !== ""
    ) {
      element["latitude"] = data.lat.toString();
    } else {
      element["latitude"] = "";
    }
    if (
      data &&
      data.lng !== undefined &&
      data.lng !== null &&
      data.lng !== ""
    ) {
      element["longitude"] = data.lng.toString();
    } else {
      element["longitude"] = "";
    }

    jobPreferredLocations.push(element);
  });
  let eduPush = [];
  // -- - - - - - - - - - - - -
  if (educationList.length > 0) {
    var newEduList = educationList.map((p) => {
      var _mapEdu = {
        universityId: p.universityId,
        facultyId: parseInt(p.universityId) == 234 ? 3244 : 3237,
        facultyDepAndGroupIds: p.facultyDepAndGroupIds.map((edu) => {
          return {
            facultyDepartmentId: edu.facultyDepartmentId,
            facultyDepartmentGroupId: edu.facultyDepartmentGroupId,
          };
        }),
      };
      return _mapEdu;
    });
    eduPush.push(newEduList);
    eduPush = eduPush[0];
  }

  //- - - - - - - - - -- - - -

  let languagePush = [];
  if (foreignLanguageList.length > 0) {
    const newAddLanguage = foreignLanguageList.map((data) => {
      //  alert("data else " + JSON.stringify(data));
      let element = {
        languageId: data.languageId.value,
        languageLevelId: data.languageLevelId.value,
      };
      return element;
    });

    languagePush.push(newAddLanguage);
    languagePush = languagePush[0];
  }
  var disabilityId = parseInt(form.disabilityStatus.value.toString());
  var disabilityDegreeListIds = [];
  form.degreeOfDisability == null ||
  form.degreeOfDisability == [] ||
  form.degreeOfDisability === "" ||
  form.degreeOfDisability == undefined ||
  form.degreeOfDisability.toString() == "[]" ||
  form.degreeOfDisability.toString() == "null"
    ? null
    : form.degreeOfDisability.map((val) => {
        disabilityDegreeListIds.push(parseInt(val.value.toString()));
      });
  var sendData = {
    jobId: jobId,
    companyId: companyId,
    companyLocationId: companyLocationId,
    positionId: positionId,
    workTypes: workTypes,
    educationTypes: [educationTypes],
    salaryRanges: salaryRanges,
    ageRanges: ageRanges,
    employeeCount: employeeCount,
    weekendWork: [weekendWork],
    businessTravelRestrictions: businessTravelRestrictions,
    languageId: null,
    languageLevelId: null,
    nationalityId: nationalityId,
    gender: gender,
    militaryStatus: gender == 0 || gender == 1 ? null : militaryStatus,
    smoking: smoking,
    maritalStatus: maritalStatus,
    drivingLicence: drivingLicence,
    drivingLicenceTypes: drivingLicenceTypes,
    jobPreferredLocations: jobPreferredLocations,
    jobPreferredUniversities: eduPush,
    jobExperienceRanges: jobExperienceRanges,
    jobPrograms: jobPrograms,
    jobCertificates: jobCertificates,
    jobCompetences: jobCompetences,
    jobForeignLanguages: languagePush,
    isActive: true,
    disability: disabilityId,
    jobDegreeOfDisabilityIds: disabilityDegreeListIds,
    isAllCertificatesMust:
      jobCertificates.toString() == "[]" ? false : checkCertificate,
    isAllProgramsMust: jobPrograms.toString() == "[]" ? false : checkProgram,
    isAllCompetencesMust:
      jobCompetences.toString() == "[]" ? false : checkCompetence,
  };
  console.log("sendData* * *" + JSON.stringify(sendData));

  DropListWhiteServices.whiteUpdateDemand(sendData).then(
    (whiteUpdateDemand) => {
      if (whiteUpdateDemand) {
        if (whiteUpdateDemand.succeeded === true) {
          toast.success(whiteUpdateDemand.result.message, {
            autoClose: 2000,
            onClose: () => {
              setTimeout(() => {
                window.location.href = window.location.href;
              }, 1000);
            },
          });
        } else {
          toast.info(whiteUpdateDemand.error.message, {
            autoClose: 2000,
            onClose: () => {
              setTimeout(() => {
                window.location.href = window.location.href;
              }, 1000);
            },
          });
        }
      } else {
        toast.info("Hata Oluştu", {
          autoClose: 1000,
          onClose: () => {
            setTimeout(() => {
              window.location.href = window.location.href;
            }, 1000);
          },
        });
      }
    },
    (error) => {
    
    }
  );
}
