export const CompanyListConstants = {
    GET_REQUEST: 'COMPANY_GET_REQUEST',
    GET_SUCCESS: 'COMPANY_GET_SUCCESS',
    GET_CLEAR: 'COMPANY_GET_CLEAR',
    GET_FAILURE: 'COMPANY_GET_FAILURE',
    GET_ALL_REQUEST: 'COMPANY_GET_ALL_REQUEST',
    GET_ALL_SUCCESS: 'COMPANY_GET_ALL_SUCCESS',
    GET_ALL_FAILURE: 'COMPANY_GET_ALL_FAILURE',
};export const SearchCompanyListConstants = {
  GET_REQUEST: "Search_COMPANY_GET_REQUEST",
  GET_SUCCESS: "Search_COMPANY_GET_SUCCESS",
  GET_CLEAR: "Search_COMPANY_GET_CLEAR",
  GET_FAILURE: "Search_COMPANY_GET_FAILURE",
  GET_ALL_REQUEST: "Search_COMPANY_GET_ALL_REQUEST",
  GET_ALL_SUCCESS: "Search_COMPANY_GET_ALL_SUCCESS",
  GET_ALL_FAILURE: "Search_COMPANY_GET_ALL_FAILURE",
};
export const CompanyLocationConstants = {
    GET_REQUEST: 'COMPANYLOCATION_GET_REQUEST',
    GET_SUCCESS: 'COMPANYLOCATION_GET_SUCCESS',
    GET_CLEAR: 'COMPANYLOCATION_GET_CLEAR',
    GET_FAILURE: 'COMPANYLOCATION_GET_FAILURE',
    GET_ALL_REQUEST: 'COMPANYLOCATION_GET_ALL_REQUEST',
    GET_ALL_SUCCESS: 'COMPANYLOCATION_GET_ALL_SUCCESS',
    GET_ALL_FAILURE: 'COMPANYLOCATION_GET_ALL_FAILURE',
};
export const CompanyMListConstants = {
  GET_REQUEST: "COMPANY_M_GET_REQUEST",
  GET_SUCCESS: "COMPANY_M_GET_SUCCESS",
  GET_CLEAR: "COMPANY_M_GET_CLEAR",
  GET_FAILURE: "COMPANY_M_GET_FAILURE",
  GET_ALL_REQUEST: "COMPANY_M_GET_ALL_REQUEST",
  GET_ALL_SUCCESS: "COMPANY_M_GET_ALL_SUCCESS",
  GET_ALL_FAILURE: "COMPANY_M_GET_ALL_FAILURE",
};

export const PlanListConstants = {
  GET_REQUEST: "COMPANY_P_GET_REQUEST",
  GET_SUCCESS: "COMPANY_P_GET_SUCCESS",
  GET_CLEAR: "COMPANY_P_GET_CLEAR",
  GET_FAILURE: "COMPANY_P_GET_FAILURE",
  GET_ALL_REQUEST: "COMPANY_P_GET_ALL_REQUEST",
  GET_ALL_SUCCESS: "COMPANY_P_GET_ALL_SUCCESS",
  GET_ALL_FAILURE: "COMPANY_P_GET_ALL_FAILURE",
};


export const DropListConstants = {
    GET_REQUEST: 'DROPLIST_GET_REQUEST',
    GET_SUCCESS: 'DROPLIST_GET_SUCCESS',
    GET_CLEAR: 'DROPLIST_GET_CLEAR',
    GET_FAILURE: 'DROPLIST_GET_FAILURE',
    GET_ALL_REQUEST: 'DROPLIST_GET_ALL_REQUEST',
    GET_ALL_SUCCESS: 'DROPLIST_GET_ALL_SUCCESS',
    GET_ALL_FAILURE: 'DROPLIST_GET_ALL_FAILURE',
};

export const PositionListContants = {
    GET_REQUEST: 'POSITIONLIST_GET_REQUEST',
    GET_SUCCESS: 'POSITIONLIST_GET_SUCCESS',
    GET_CLEAR: 'POSITIONLIST_GET_CLEAR',
    GET_FAILURE: 'POSITIONLIST_GET_FAILURE',
    GET_ALL_REQUEST: 'POSITIONLIST_GET_ALL_REQUEST',
    GET_ALL_SUCCESS: 'POSITIONLIST_GET_ALL_SUCCESS',
    GET_ALL_FAILURE: 'POSITIONLIST_GET_ALL_FAILURE',
};

export const YearOfExperienceContants = {
    GET_REQUEST: 'YEAROFEXPERIENCE_GET_REQUEST',
    GET_SUCCESS: 'YEAROFEXPERIENCE_GET_SUCCESS',
    GET_CLEAR: 'YEAROFEXPERIENCE_GET_CLEAR',
    GET_FAILURE: 'YEAROFEXPERIENCE_GET_FAILURE',
    GET_ALL_REQUEST: 'YEAROFEXPERIENCET_GET_ALL_REQUEST',
    GET_ALL_SUCCESS: 'YEAROFEXPERIENCE_GET_ALL_SUCCESS',
    GET_ALL_FAILURE: 'YEAROFEXPERIENCE_GET_ALL_FAILURE',
}
export const DisabilityOfDegreeList = {
  GET_REQUEST: "DisabilityOfDegreeList_GET_REQUEST",
  GET_SUCCESS: "DisabilityOfDegreeList_GET_SUCCESS",
  GET_CLEAR: "DisabilityOfDegreeList_GET_CLEAR",
  GET_FAILURE: "DisabilityOfDegreeList_GET_FAILURE",
  GET_ALL_REQUEST: "DisabilityOfDegreeList_GET_ALL_REQUEST",
  GET_ALL_SUCCESS: "DisabilityOfDegreeList_GET_ALL_SUCCESS",
  GET_ALL_FAILURE: "DisabilityOfDegreeList_GET_ALL_FAILURE",
};
;
export const WayOfWorkingContants = {
    GET_REQUEST: 'WAYOFWORKING_GET_REQUEST',
    GET_SUCCESS: 'WAYOFWORKING_GET_SUCCESS',
    GET_CLEAR: 'WAYOFWORKING_GET_CLEAR',
    GET_FAILURE: 'WAYOFWORKING_GET_FAILURE',
    GET_ALL_REQUEST: 'WAYOFWORKING_GET_ALL_REQUEST',
    GET_ALL_SUCCESS: 'WAYOFWORKING_GET_ALL_SUCCESS',
    GET_ALL_FAILURE: 'WAYOFWORKING_GET_ALL_FAILURE',
}
export const NationalityListContants = {
    GET_REQUEST: 'NATIONALITYLIST_GET_REQUEST',
    GET_SUCCESS: 'NATIONALITYLIST_GET_SUCCESS',
    GET_CLEAR: 'NATIONALITYLIST_CLEAR',
    GET_FAILURE: 'NATIONALITYLIST_GET_FAILURE',
    GET_ALL_REQUEST: 'NATIONALITYLIST_GET_ALL_REQUEST',
    GET_ALL_SUCCESS: 'NATIONALITYLIST_GET_ALL_SUCCESS',
    GET_ALL_FAILURE: 'NATIONALITYLIST_GET_ALL_FAILURE',
}
export const AgeRangeContants = {
    GET_REQUEST: 'AGERANGE_GET_REQUEST',
    GET_SUCCESS: 'AGERANGE_GET_SUCCESS',
    GET_CLEAR: 'AGERANGE_CLEAR',
    GET_FAILURE: 'AGERANGE_GET_FAILURE',
    GET_ALL_REQUEST: 'AGERANGE_GET_ALL_REQUEST',
    GET_ALL_SUCCESS: 'AGERANGE_GET_ALL_SUCCESS',
    GET_ALL_FAILURE: 'AGERANGE_GET_ALL_FAILURE',
}
export const DriverLicenseTypeContants = {
    GET_REQUEST: 'DRIVERLICENSETYPE_GET_REQUEST',
    GET_SUCCESS: 'DRIVERLICENSETYPE_GET_SUCCESS',
    GET_CLEAR: 'DRIVERLICENSETYPE_CLEAR',
    GET_FAILURE: 'DRIVERLICENSETYPE_GET_FAILURE',
    GET_ALL_REQUEST: 'DRIVERLICENSETYPE_GET_ALL_REQUEST',
    GET_ALL_SUCCESS: 'DRIVERLICENSETYPE_GET_ALL_SUCCESS',
    GET_ALL_FAILURE: 'DRIVERLICENSETYPE_GET_ALL_FAILURE',
}
export const ProgramListContants = {
    GET_REQUEST: 'PROGRAMLIST_GET_REQUEST',
    GET_SUCCESS: 'PROGRAMLIST_GET_SUCCESS',
    GET_CLEAR: 'PROGRAMLIST_CLEAR',
    GET_FAILURE: 'PROGRAMLIST_GET_FAILURE',
    GET_ALL_REQUEST: 'PROGRAMLIST_GET_ALL_REQUEST',
    GET_ALL_SUCCESS: 'PROGRAMLISTPE_GET_ALL_SUCCESS',
    GET_ALL_FAILURE: 'PROGRAMLIST_GET_ALL_FAILURE',
}
export const CertificatesListContants = {
    GET_REQUEST: 'CERTIFICATESLIST_GET_REQUEST',
    GET_SUCCESS: 'CERTIFICATESLIST_GET_SUCCESS',
    GET_CLEAR: 'CERTIFICATESLIST_CLEAR',
    GET_FAILURE: 'CERTIFICATESLIST_GET_FAILURE',
    GET_ALL_REQUEST: 'CERTIFICATESLIST_GET_ALL_REQUEST',
    GET_ALL_SUCCESS: 'CERTIFICATESLIST_GET_ALL_SUCCESS',
    GET_ALL_FAILURE: 'CERTIFICATESLIST_GET_ALL_FAILURE',
}
export const CompetencesListContants = {
    GET_REQUEST: 'COMPETENCELIST_GET_REQUEST',
    GET_SUCCESS: 'COMPETENCELIST_GET_SUCCESS',
    GET_CLEAR: 'COMPETENCELISTT_CLEAR',
    GET_FAILURE: 'COMPETENCELIST_GET_FAILURE',
    GET_ALL_REQUEST: 'COMPETENCELIST_GET_ALL_REQUEST',
    GET_ALL_SUCCESS: 'COMPETENCELIST_GET_ALL_SUCCESS',
    GET_ALL_FAILURE: 'COMPETENCELIST_GET_ALL_FAILURE',
}
export const CountryListContants = {
    GET_REQUEST: 'COUNTRYLIST_GET_REQUEST',
    GET_SUCCESS: 'COUNTRYLIST_GET_SUCCESS',
    GET_CLEAR: 'COUNTRYLIST_CLEAR',
    GET_FAILURE: 'COUNTRYLIST_GET_FAILURE',
    GET_ALL_REQUEST: 'COUNTRYLIST_GET_ALL_REQUEST',
    GET_ALL_SUCCESS: 'COUNTRYLIST_GET_ALL_SUCCESS',
    GET_ALL_FAILURE: 'COUNTRYLIST_GET_ALL_FAILURE',
}
export const CityListContants = {
    GET_REQUEST: 'CITYLIST_GET_REQUEST',
    GET_SUCCESS: 'CITYLISTT_GET_SUCCESS',
    GET_CLEAR: 'CITYLIST_CLEAR',
    GET_FAILURE: 'CITYLIST_GET_FAILURE',
    GET_ALL_REQUEST: 'CITYLIST_GET_ALL_REQUEST',
    GET_ALL_SUCCESS: 'CITYLISTT_GET_ALL_SUCCESS',
    GET_ALL_FAILURE: 'CITYLIST_GET_ALL_FAILURE',
}
export const CountyListContants = {
    GET_REQUEST: 'COUNTRY_GET_REQUEST',
    GET_SUCCESS: 'COUNTRY_GET_SUCCESS',
    GET_CLEAR: 'COUNTRY_CLEAR',
    GET_FAILURE: 'COUNTRY_GET_FAILURE',
    GET_ALL_REQUEST: 'COUNTRY_GET_ALL_REQUEST',
    GET_ALL_SUCCESS: 'COUNTRY_GET_ALL_SUCCESS',
    GET_ALL_FAILURE: 'CCOUNTRY_GET_ALL_FAILURE',
}
export const CountyJobSeekerListContants = {
    GET_REQUEST: 'COUNTRY_GET_JOBSEEKER_REQUEST',
    GET_SUCCESS: 'COUNTRY_GET_JOBSEEKER_SUCCESS',
    GET_CLEAR: 'COUNTRY_JOBSEEKER_CLEAR',
    GET_FAILURE: 'COUNTRY_GET_JOBSEEKER_FAILURE',
    GET_ALL_REQUEST: 'COUNTRY_GET_ALL_JOBSEEKER_REQUEST',
    GET_ALL_SUCCESS: 'COUNTRY_GET_ALL_JOBSEEKER_SUCCESS',
    GET_ALL_FAILURE: 'CCOUNTRY_GET_ALL_JOBSEEKER_FAILURE',
}
export const DistrictListContants = {
    GET_REQUEST: 'DISTRICTLIST_GET_REQUEST',
    GET_SUCCESS: 'DISTRICTLIST_GET_SUCCESS',
    GET_CLEAR: 'DISTRICTLIST_CLEAR',
    GET_FAILURE: 'DISTRICTLIST_GET_FAILURE',
    GET_ALL_REQUEST: 'DISTRICTLIST_GET_ALL_REQUEST',
    GET_ALL_SUCCESS: 'DISTRICTLIST_GET_ALL_SUCCESS',
    GET_ALL_FAILURE: 'DISTRICTLIST_GET_ALL_FAILURE',
}
export const NewCountyAddContants = {
    GET_REQUEST: 'NEWCOUNTRY_GET_REQUEST',
    GET_SUCCESS: 'NEWCOUNTRY_GET_SUCCESS',
    GET_CLEAR: 'NEWCOUNTRY_CLEAR',
    GET_FAILURE: 'NEWCOUNTRY_GET_FAILURE',
    GET_ALL_REQUEST: 'NEWCOUNTRY_GET_ALL_REQUEST',
    GET_ALL_SUCCESS: 'NEWCOUNTRY_GET_ALL_SUCCESS',
    GET_ALL_FAILURE: 'NEWCCOUNTRY_GET_ALL_FAILURE',
}
export const NewDistrictAddContants = {
    GET_REQUEST: 'NEWDISTRICTLIST_GET_REQUEST',
    GET_SUCCESS: 'NEWDISTRICTLIST_GET_SUCCESS',
    GET_CLEAR: 'NEWDISTRICTLIST_CLEAR',
    GET_FAILURE: 'NEWDISTRICTLIST_GET_FAILURE',
    GET_ALL_REQUEST: 'NEWDISTRICTLIST_GET_ALL_REQUEST',
    GET_ALL_SUCCESS: 'NEWDISTRICTLIST_GET_ALL_SUCCESS',
    GET_ALL_FAILURE: 'NEWDISTRICTLIST_GET_ALL_FAILURE',
}
export const SectorListContants = {
    GET_REQUEST: 'SECTORLIST_GET_REQUEST',
    GET_SUCCESS: 'SECTORLIST_GET_SUCCESS',
    GET_CLEAR: 'SECTORLIST_CLEAR',
    GET_FAILURE: 'SECTORLIST_GET_FAILURE',
    GET_ALL_REQUEST: 'SECTORLIST_GET_ALL_REQUEST',
    GET_ALL_SUCCESS: 'SECTORLIST_GET_ALL_SUCCESS',
    GET_ALL_FAILURE: 'SECTORLIST_GET_ALL_FAILURE',
}
export const DepartmentsListContants = {
    GET_REQUEST: 'DEPARTMENTSLIST_GET_REQUEST',
    GET_SUCCESS: 'DEPARTMENTSLIST_GET_SUCCESS',
    GET_CLEAR: 'DEPARTMENTSLIST_CLEAR',
    GET_FAILURE: 'DEPARTMENTSLIST_GET_FAILURE',
    GET_ALL_REQUEST: 'DEPARTMENTSLIST_GET_ALL_REQUEST',
    GET_ALL_SUCCESS: 'DEPARTMENTSLIST_GET_ALL_SUCCESS',
    GET_ALL_FAILURE: 'DEPARTMENTSLIST_GET_ALL_FAILURE',
}
export const BlueNewDemandContants  = {
    GET_REQUEST: 'BLUENEWDEPARTMENTS_GET_REQUEST',
    GET_SUCCESS: 'BLUENEWDEPARTMENTS_GET_SUCCESS',
    GET_CLEAR: 'BLUENEWDEPARTMENTS_CLEAR',
    GET_FAILURE: 'BLUENEWDEPARTMENTS_GET_FAILURE',
    GET_ALL_REQUEST: 'BLUENEWDEPARTMENTS_GET_ALL_REQUEST',
    GET_ALL_SUCCESS: 'BLUENEWDEPARTMENTS_GET_ALL_SUCCESS',
    GET_ALL_FAILURE: 'BLUENEWDEPARTMENTS_GET_ALL_FAILURE',
}

export const CompanyItemLocationConstants = {
  GET_REQUEST: "COMPANYITEMLOCATION_GET_REQUEST",
  GET_SUCCESS: "COMPANYITEMLOCATION_GET_SUCCESS",
  GET_CLEAR: "COMPANYITEMLOCATION_GET_CLEAR",
  GET_FAILURE: "COMPANYITEMLOCATION_GET_FAILURE",
  GET_ALL_REQUEST: "COMPANYITEMLOCATION_GET_ALL_REQUEST",
  GET_ALL_SUCCESS: "COMPANYITEMLOCATION_GET_ALL_SUCCESS",
  GET_ALL_FAILURE: "COMPANYITEMLOCATION_GET_ALL_FAILURE",
};