import React from "react";
import CustomPreview from "../../../../../../../../widgets/CustomPreview";
import DividerDotted from "../../../../../../../../widgets/DividerDotted";
import CustomPreviewNoTitle from "../../../../../../../../widgets/CustomPreviewNoTitle";

const WhiteWantToLocation = ({
  cardStyle,
  labelStyle,
  inputStyle,
  whiteMatchDetail,
  matchPoint,
}) => {
  
  return (
    <div
      class=""
      style={{
        margin: "0px",
        padding: "0px",
      }}
    >
      <div
        class="card-header"
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center",
          borderBottom: "none",
          marginBottom: "0px",
          paddingBottom: "0px",
        }}
      >
        {" "}
        <h5 class="card-title">Çalışmak İstediği Lokasyonlar</h5>
      </div>

      <div
        class="card-body"
        style={{
          marginTop: "0px",
          paddingTop: "10px",
        }}
      >
        {whiteMatchDetail &&
          whiteMatchDetail.matchCandidateLocations.map((location) => {
            return (
              <CustomPreviewNoTitle
                name={""}
                value={location.countryName + "/" + location.cityName}
              />
            );
          })}
      </div>
      <DividerDotted />
    </div>
  );
};

export default WhiteWantToLocation;
