import React, { Fragment, useEffect, useState, useMemo } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { DashboardAction } from "../../../../action";
import JobSlide from "./JobSlide";
import { fetchLastDemandDemandTotal } from "../../../../redux/redux-toolkit/lastDemandCountSlice";
import { DashboardServices } from "../../../../services";
import CarouselSlider from "./CarouselSlider";
import LastDemands from "./LastDemands";
import TestWidget from "./TestWidget";
import packageJsonPath from "../../../../../package.json";
import { setNavIndex } from "../../../../redux/redux-toolkit/sidebarIndex";

const Home = (props) => {
  const dispatch = useDispatch();
  const [demandActiveCount, setDemandActiveCount] = useState(0);
  const [remainderJobCount, setRemainderJobCount] = useState(null);
  const [lastDemandList, setLastDemandList] = useState([]);
  const { navIndex } = useSelector((state) => state.navIndex);
  dispatch(setNavIndex(-1));
  useEffect(() => {

    DashboardServices.employerActiveDemandsCount().then((countResponse) => {
      if (countResponse && countResponse.succeeded == true) {
        setDemandActiveCount(countResponse.data.activeJobCount);
      }
    });
    DashboardServices.getRemainderJobCount().then((remainderResponse) => {
      if (remainderResponse && remainderResponse.succeeded == true) {
        if (
          remainderResponse.data.creditLeft &&
          remainderResponse.data.creditLeft.toString() === "0"
        ) {
          setRemainderJobCount(remainderResponse.data.creditLeft.toString());
        } else if (parseInt(remainderResponse.data.creditLeft.toString()) > 0) {
          setRemainderJobCount(remainderResponse.data.creditLeft.toString());
        }
      }
    });

    if (!props.lastLabourer.success) {
      props.dispatch(
        DashboardAction.lastLabourer({
          positionType: 2,
        })
      );
    }
    if (!props.labourer.success) {
      props.dispatch(
        DashboardAction.labourer({
          positionType: 1,
        })
      );
    }
  }, [props.lastLabourer.success]);
  useEffect(() => {
    DashboardServices.lastDemands({
      isActive: true,
      positionType: null,
    }).then((lastDemandData) => {
      if (lastDemandData && lastDemandData.succeeded == true) {
        var data = lastDemandData.data;
        var getData = [];
        data.map((value) => getData.push(value));
        if (data) {
          getData.map((val) =>
            setLastDemandList((prev) => [...prev, { ...val }])
          );
        }
      }
    });
  }, []);
 
  return (
    <>
      <div className="row">
     
        <div className="col-xl-3 col-xxl-3 col-sm-6">
          <div className="card bg-primary">
            <a href="/demands/allShow">
              <div className="card-body">
                <div className="media align-items-center ">
                  <span className="p-3  border border-white rounded">
                    <svg
                      width="36"
                      height="36"
                      viewBox="0 0 36 36"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M30.25 5.75H28.5V2.25C28.5 1.78587 28.3156 1.34075 27.9874 1.01256C27.6593 0.684374 27.2141 0.5 26.75 0.5C26.2859 0.5 25.8407 0.684374 25.5126 1.01256C25.1844 1.34075 25 1.78587 25 2.25V5.75H11V2.25C11 1.78587 10.8156 1.34075 10.4874 1.01256C10.1592 0.684374 9.71413 0.5 9.25 0.5C8.78587 0.5 8.34075 0.684374 8.01256 1.01256C7.68437 1.34075 7.5 1.78587 7.5 2.25V5.75H5.75C4.35761 5.75 3.02226 6.30312 2.03769 7.28769C1.05312 8.27226 0.5 9.60761 0.5 11V12.75H35.5V11C35.5 9.60761 34.9469 8.27226 33.9623 7.28769C32.9777 6.30312 31.6424 5.75 30.25 5.75Z"
                        fill="white"
                      />
                      <path
                        d="M0.5 30.25C0.5 31.6424 1.05312 32.9777 2.03769 33.9623C3.02226 34.9469 4.35761 35.5 5.75 35.5H30.25C31.6424 35.5 32.9777 34.9469 33.9623 33.9623C34.9469 32.9777 35.5 31.6424 35.5 30.25V16.25H0.5V30.25Z"
                        fill="white"
                      />
                    </svg>
                  </span>
                  <div className="media-body text-right">
                    <p className="fs-16 text-white mb-2">
                      Aktif <br /> Talebim
                    </p>
                    <span className="fs-48 text-white font-w600">
                      {
                        <p>
                          {demandActiveCount === 0 ? 0 : demandActiveCount}{" "}
                        </p>
                      }
                    </span>
                  </div>
                </div>
              </div>
            </a>
          </div>
        </div>
        <div className="col-xl-3 col-xxl-3 col-sm-6">
          <div className="card bg-info">
            <a href="/matchShow">
              <div className="card-body">
                <div className="media align-items-center">
                  <span className="p-3  border border-white rounded">
                    <svg
                      width="36"
                      height="36"
                      viewBox="0 0 42 42"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M38.4998 10.4995H35.0002V38.4999H38.4998C40.4245 38.4999 42 36.9238 42 34.9992V13.9992C42 12.075 40.4245 10.4995 38.4998 10.4995Z"
                        fill="white"
                      />
                      <path
                        d="M27.9998 10.4995V6.9998C27.9998 5.07515 26.4243 3.49963 24.5001 3.49963H17.4998C15.5756 3.49963 14.0001 5.07515 14.0001 6.9998V10.4995H10.5V38.4998H31.5V10.4995H27.9998ZM24.5001 10.4995H17.4998V6.99929H24.5001V10.4995Z"
                        fill="white"
                      />
                      <path
                        d="M3.50017 10.4995C1.57551 10.4995 0 12.075 0 13.9997V34.9997C0 36.9243 1.57551 38.5004 3.50017 38.5004H6.99983V10.4995H3.50017Z"
                        fill="white"
                      />
                    </svg>
                  </span>
                  <div className="media-body text-right">
                    <p className="fs-16 text-white mb-2">
                      Talebim İle Eşleşenler
                    </p>
                    <span className="fs-48 text-white font-w600">
                      {<p></p>}
                    </span>
                  </div>
                </div>
              </div>
            </a>
          </div>
        </div>
        <div className="col-xl-3 col-xxl-3 col-sm-6">
          <div className="card bg-success">
            <a href="#">
              <div className="card-body">
                <div className="media align-items-center">
                  <span className="p-3  border border-white rounded">
                    <svg
                      width="36"
                      height="36"
                      viewBox="0 0 42 42"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M15.1811 22.0083C15.065 21.9063 14.7968 21.6695 14.7015 21.5799C12.3755 19.3941 10.8517 15.9712 10.8517 12.1138C10.8517 5.37813 15.4868 0.0410156 21.001 0.0410156C26.5152 0.0410156 31.1503 5.37813 31.1503 12.1138C31.1503 15.9679 29.6292 19.3884 27.3094 21.5778C27.2118 21.6699 26.9384 21.9116 26.8238 22.0125L26.8139 22.1799C26.8789 23.1847 27.554 24.0553 28.5232 24.3626C35.7277 26.641 40.9507 32.0853 41.8276 38.538C41.9483 39.3988 41.6902 40.2696 41.1198 40.9254C40.5495 41.5813 39.723 41.9579 38.8541 41.9579C32.4956 41.9591 9.50672 41.9591 3.14818 41.9591C2.2787 41.9591 1.4518 41.5824 0.881242 40.9263C0.31068 40.2701 0.0523763 39.3989 0.172318 38.5437C1.05145 32.0851 6.27444 26.641 13.4777 24.3628C14.4504 24.0544 15.1263 23.1802 15.1885 22.1722L15.1811 22.0083Z"
                        fill="white"
                      />
                    </svg>
                  </span>
                  <div className="media-body text-right">
                    <p className="fs-16 text-white mb-2 text-right">
                      Talep Hakkınız Kaldı
                    </p>
                    <span className="fs-48 text-white font-w600">
                      {<p> {remainderJobCount && remainderJobCount} </p>}
                    </span>
                  </div>
                </div>
              </div>
            </a>
          </div>
        </div>
        <div className="col-xl-3 col-xxl-3 col-sm-6">
          <div className="card bg-secondary">
            <a href="/packets">
              <div className="card-body">
                <div className="media align-items-center">
                  <span className="p-3  border border-white rounded">
                    <svg
                      width="36"
                      height="36"
                      viewBox="0 0 42 42"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M40.614 9.36994C40.443 8.22658 39.8679 7.18234 38.9932 6.4265C38.1184 5.67067 37.0018 5.25328 35.8457 5.25H6.1543C4.99822 5.25328 3.88159 5.67067 3.00681 6.4265C2.13203 7.18234 1.55701 8.22658 1.38599 9.36994L21 22.0618L40.614 9.36994Z"
                        fill="white"
                      />
                      <path
                        d="M21.7127 24.7274C21.5003 24.8647 21.2529 24.9378 21 24.9378C20.7471 24.9378 20.4997 24.8647 20.2873 24.7274L1.3125 12.4503V31.9081C1.31389 33.1918 1.82445 34.4225 2.73217 35.3302C3.63988 36.238 4.87061 36.7485 6.15431 36.7499H35.8457C37.1294 36.7485 38.3601 36.238 39.2678 35.3302C40.1755 34.4225 40.6861 33.1918 40.6875 31.9081V12.449L21.7127 24.7274Z"
                        fill="white"
                      />
                    </svg>
                  </span>
                  <a className="media-body text-right" href="/packets">
                    <p className="fs-16 text-white mb-2">
                      Paket <br /> Bilgilerim
                    </p>
                    <span className="fs-48 text-white font-w600"></span>
                  </a>
                </div>
              </div>
            </a>
          </div>
        </div>
      </div>{" "}
      {lastDemandList && lastDemandList.length > 0 && (
        <div className="col-md-12">
          <h4 className="fs-20 text-black mb-sm-4 mt-sm-0 mt-2  mb-2">
            <p style={{ color: "#fdc303" }}> Son Taleplerim</p>
          </h4>
          <JobSlide lastDemandList={lastDemandList && lastDemandList} />
        </div>
      )}
    </>
  );
};
//      <p className="hItemTitle-p">Son Taleplerim</p>
function mapState(state) {
  let authentication = state.authentication;
  let labourer = state.labourer;
  let lastLabourer = state.lastLabourer;
  return { authentication, lastLabourer, labourer };
}

export default connect(mapState)(Home);
/*
 <TestWidget
        lastDemandList={lastDemandList}
        demandActiveCount={demandActiveCount}
      />
      */
/*-
  <div className="col-md-12">
          <h4 className="fs-20 text-black mb-sm-4 mt-sm-0 mt-2  mb-2">
            <p style={{ color: "#fdc303" }}> Son Taleplerim</p>
          </h4>
          <JobSlide />
        </div>
    */

/*


         <div className="row">
        <div className="col-xl-3 col-xxl-3 col-sm-6">
          <div className="card bg-primary">
            <a href="/demands/allShow">
              <div className="card-body">
                <div className="media align-items-center ">
                  <span className="p-3  border border-white rounded">
                    <svg
                      width="36"
                      height="36"
                      viewBox="0 0 36 36"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M30.25 5.75H28.5V2.25C28.5 1.78587 28.3156 1.34075 27.9874 1.01256C27.6593 0.684374 27.2141 0.5 26.75 0.5C26.2859 0.5 25.8407 0.684374 25.5126 1.01256C25.1844 1.34075 25 1.78587 25 2.25V5.75H11V2.25C11 1.78587 10.8156 1.34075 10.4874 1.01256C10.1592 0.684374 9.71413 0.5 9.25 0.5C8.78587 0.5 8.34075 0.684374 8.01256 1.01256C7.68437 1.34075 7.5 1.78587 7.5 2.25V5.75H5.75C4.35761 5.75 3.02226 6.30312 2.03769 7.28769C1.05312 8.27226 0.5 9.60761 0.5 11V12.75H35.5V11C35.5 9.60761 34.9469 8.27226 33.9623 7.28769C32.9777 6.30312 31.6424 5.75 30.25 5.75Z"
                        fill="white"
                      />
                      <path
                        d="M0.5 30.25C0.5 31.6424 1.05312 32.9777 2.03769 33.9623C3.02226 34.9469 4.35761 35.5 5.75 35.5H30.25C31.6424 35.5 32.9777 34.9469 33.9623 33.9623C34.9469 32.9777 35.5 31.6424 35.5 30.25V16.25H0.5V30.25Z"
                        fill="white"
                      />
                    </svg>
                  </span>
                  <div className="media-body text-right">
                    <p className="fs-16 text-white mb-2">
                      Aktif <br /> Talebim
                    </p>
                    <span className="fs-48 text-white font-w600">
                      {
                        <p>
                          {JSON.stringify(lastDemand.lastDemands.length ?? "")}{" "}
                        </p>
                      }
                    </span>
                  </div>
                </div>
              </div>
            </a>
          </div>
        </div>
        <div className="col-xl-3 col-xxl-3 col-sm-6">
          <div className="card bg-info">
            <a href="#">
              <div className="card-body">
                <div className="media align-items-center">
                  <span className="p-3  border border-white rounded">
                    <svg
                      width="36"
                      height="36"
                      viewBox="0 0 42 42"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M38.4998 10.4995H35.0002V38.4999H38.4998C40.4245 38.4999 42 36.9238 42 34.9992V13.9992C42 12.075 40.4245 10.4995 38.4998 10.4995Z"
                        fill="white"
                      />
                      <path
                        d="M27.9998 10.4995V6.9998C27.9998 5.07515 26.4243 3.49963 24.5001 3.49963H17.4998C15.5756 3.49963 14.0001 5.07515 14.0001 6.9998V10.4995H10.5V38.4998H31.5V10.4995H27.9998ZM24.5001 10.4995H17.4998V6.99929H24.5001V10.4995Z"
                        fill="white"
                      />
                      <path
                        d="M3.50017 10.4995C1.57551 10.4995 0 12.075 0 13.9997V34.9997C0 36.9243 1.57551 38.5004 3.50017 38.5004H6.99983V10.4995H3.50017Z"
                        fill="white"
                      />
                    </svg>
                  </span>
                  <div className="media-body text-right">
                    <p className="fs-16 text-white mb-2">
                      Bekleyen <br /> Özgeçmiş
                    </p>
                    <span className="fs-48 text-white font-w600">
                      {
                        <p>
                          {JSON.stringify(lastDemand.lastDemands.length ?? "")}{" "}
                        </p>
                      }
                    </span>
                  </div>
                </div>
              </div>
            </a>
          </div>
        </div>
        <div className="col-xl-3 col-xxl-3 col-sm-6">
          <div className="card bg-success">
            <a href="#">
              <div className="card-body">
                <div className="media align-items-center">
                  <span className="p-3  border border-white rounded">
                    <svg
                      width="36"
                      height="36"
                      viewBox="0 0 42 42"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M15.1811 22.0083C15.065 21.9063 14.7968 21.6695 14.7015 21.5799C12.3755 19.3941 10.8517 15.9712 10.8517 12.1138C10.8517 5.37813 15.4868 0.0410156 21.001 0.0410156C26.5152 0.0410156 31.1503 5.37813 31.1503 12.1138C31.1503 15.9679 29.6292 19.3884 27.3094 21.5778C27.2118 21.6699 26.9384 21.9116 26.8238 22.0125L26.8139 22.1799C26.8789 23.1847 27.554 24.0553 28.5232 24.3626C35.7277 26.641 40.9507 32.0853 41.8276 38.538C41.9483 39.3988 41.6902 40.2696 41.1198 40.9254C40.5495 41.5813 39.723 41.9579 38.8541 41.9579C32.4956 41.9591 9.50672 41.9591 3.14818 41.9591C2.2787 41.9591 1.4518 41.5824 0.881242 40.9263C0.31068 40.2701 0.0523763 39.3989 0.172318 38.5437C1.05145 32.0851 6.27444 26.641 13.4777 24.3628C14.4504 24.0544 15.1263 23.1802 15.1885 22.1722L15.1811 22.0083Z"
                        fill="white"
                      />
                    </svg>
                  </span>
                  <div className="media-body text-right">
                    <p className="fs-16 text-white mb-2 text-right">
                      Talep Hakkınız Kaldı
                    </p>
                    <span className="fs-48 text-white font-w600">
                      {
                        <p>
                          {JSON.stringify(lastDemand.lastDemands.length ?? "")}{" "}
                        </p>
                      }
                    </span>
                  </div>
                </div>
              </div>
            </a>
          </div>
        </div>
        <div className="col-xl-3 col-xxl-3 col-sm-6">
          <div className="card bg-secondary">
            <a href="/packets">
              <div className="card-body">
                <div className="media align-items-center">
                  <span className="p-3  border border-white rounded">
                    <svg
                      width="36"
                      height="36"
                      viewBox="0 0 42 42"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M40.614 9.36994C40.443 8.22658 39.8679 7.18234 38.9932 6.4265C38.1184 5.67067 37.0018 5.25328 35.8457 5.25H6.1543C4.99822 5.25328 3.88159 5.67067 3.00681 6.4265C2.13203 7.18234 1.55701 8.22658 1.38599 9.36994L21 22.0618L40.614 9.36994Z"
                        fill="white"
                      />
                      <path
                        d="M21.7127 24.7274C21.5003 24.8647 21.2529 24.9378 21 24.9378C20.7471 24.9378 20.4997 24.8647 20.2873 24.7274L1.3125 12.4503V31.9081C1.31389 33.1918 1.82445 34.4225 2.73217 35.3302C3.63988 36.238 4.87061 36.7485 6.15431 36.7499H35.8457C37.1294 36.7485 38.3601 36.238 39.2678 35.3302C40.1755 34.4225 40.6861 33.1918 40.6875 31.9081V12.449L21.7127 24.7274Z"
                        fill="white"
                      />
                    </svg>
                  </span>
                  <div className="media-body text-right">
                    <p className="fs-16 text-white mb-2">
                      Paket <br /> Bilgilerim
                    </p>
                    <span className="fs-48 text-white font-w600">
                      {
                        <p>
                          {JSON.stringify(lastDemand.lastDemands.length ?? "")}{" "}
                        </p>
                      }
                    </span>
                  </div>
                </div>
              </div>
            </a>
          </div>
        </div>
   
      </div>

      */
