import React, { useEffect } from "react";
import CustomDesign from "../../CustomDesign";
import BitonisSingleDropdown from "../../BitonisSingleDropdown";
import { DropListAction } from "../../../../action";
import GoogleMapWidget from "../../map/GoogleMapWidget";

const SelectSingleRoute = ({
  props,
  register,
  watch,
  control,
  setValue,
  errors,
  getValues,
  getLatLngApi,
  preview,
  employerCompanyName,
}) => {
  useEffect(() => {
    if (!props.countryList.success) {
      props.dispatch(DropListAction.countryList());
    }

    props.dispatch(
      DropListAction.cityList({
        countryId: 1,
      })
    );
  }, []);
  useEffect(() => {
    if (preview) {
    } else {
      setValue("countryId", "");
      setValue("cityId", "");
      setValue("countyId", "");
      setValue("districts", "");
    }
  }, [preview]);

  const handleCity = (selected) => {
    props.dispatch(DropListAction.countyList(selected.value));
  };
  const handleDistry = (selected) => {
    props.dispatch(DropListAction.districtList(selected.value));
  };

  return (
    <div>
      <div className="row">
        <CustomDesign
          label={"Ülke"}
          required={true}
          isColumn={true}
          columnClassName={"col-xs-6 col-md-6"}
          child={
            <BitonisSingleDropdown
              items={
                props.countryList.countryList &&
                props.countryList.countryList.map(({ id, name }) => ({
                  value: id,
                  label: name,
                }))
              }
              register={register}
              watch={watch}
              companyClassName={"countryId"}
              control={control}
              setValue={setValue}
              errors={errors}
              placeholder="Ülke Seç"
              onValueChanged={(e) => {
                console.log(e);
                setValue("cityId", "");
                setValue("countyId", "");
                setValue("districts", "");
                if (e) {
                }
              }}
              required={true}
              getValues={getValues}
              errorMessage={"Ülke Boş Olamaz"}
            />
          }
        />
        <CustomDesign
          label={"İl/Şehir"}
          required={true}
          isColumn={true}
          columnClassName={"col-xs-6 col-md-6"}
          child={
            <BitonisSingleDropdown
              items={
                props.cityList.cityList &&
                props.cityList.cityList.map(({ id, name }) => ({
                  value: id,
                  label: name,
                }))
              }
              register={register}
              watch={watch}
              companyClassName={"cityId"}
              control={control}
              setValue={setValue}
              errors={errors}
              placeholder="İl/Şehir Seç"
              onValueChanged={(e) => {
                console.log(e);
                setValue("countyId", "");
                setValue("districts", "");
                if (e) {
                  handleCity(e);
                  var country = watch("countryId");
                    if (country  && e) {
                      var createLatLngLastVersion = e.label + ","+
                
                      "+" + employerCompanyName;
                      getLatLngApi(createLatLngLastVersion);
                    
                  }
                }
              }}
              required={true}
              getValues={getValues}
              errorMessage={"İl/Şehir Boş Olamaz"}
            />
          }
        />
      </div>

      <div className="row">
        {props.countyList.countyList && (
          <CustomDesign
            label={"İlçe"}
            required={true}
            isColumn={true}
            columnClassName={"col-xs-6 col-md-6"}
            child={
              <BitonisSingleDropdown
                items={
                  props.countyList.countyList &&
                  props.countyList.countyList.map(({ id, name }) => ({
                    value: id,
                    label: name,
                  }))
                }
                register={register}
                watch={watch}
                companyClassName={"countyId"}
                control={control}
                setValue={setValue}
                errors={errors}
                placeholder="İlçe Seç"
                onValueChanged={(e) => {
                  console.log(e);
                  if (e) {
                    handleDistry(e);
                    setValue("districts", "");
            
                    var country = watch("countryId");
                    var city = watch("cityId");
                    if (country && city && e) {
                      var createLatLngLastVersion = city.label + ","+
                      "+" + e.label + ","+
                
                      "+" + employerCompanyName;
                      getLatLngApi(createLatLngLastVersion);
                    
                  }
                  }
                }}
                required={true}
                getValues={getValues}
                errorMessage={"İlçe Boş Olamaz"}
              />
            }
          />
        )}
        {props.districtList.districtList && (
          <CustomDesign
            label={"Semt/Mahalle"}
            required={true}
            isColumn={true}
            columnClassName={"col-xs-6 col-md-6"}
            child={
              <BitonisSingleDropdown
                items={
                  props.districtList.districtList &&
                  props.districtList.districtList.map(({ id, name }) => ({
                    value: id,
                    label: name,
                  }))
                }
                register={register}
                watch={watch}
                companyClassName={"districts"}
                control={control}
                setValue={setValue}
                errors={errors}
                placeholder="Semt/Mahalle Seç"
                onValueChanged={(e) => {
                  console.log(e);
                  if (e) {
                    var country = watch("countryId");
                    var city = watch("cityId");
                    var county = watch("countyId");
                    if (country && city && county) {
                      var createLatLngLastVersion = city.label + ","+
                      "+" + county.label + ","+
                      "+" + e.label + ","+
                      "+" + employerCompanyName;
                      getLatLngApi(createLatLngLastVersion);
                    }
                  }
                }}
                required={true}
                getValues={getValues}
                errorMessage={"Semt Boş Olamaz"}
              />
            }
          />
        )}

      </div>
      <div>
     
    </div>
    </div>
  );
};

export default SelectSingleRoute;
