import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../utility/Utils";

export const fetchLastDemandDemandTotal = createAsyncThunk(
  "demand/lists",
  async (arg) => {
    try {
      const response = await api.post("demand/list", arg);
      return response.data;
    } catch (err) {
      throw err;
    }
  }
);

const lastDemandCountSlice = createSlice({
  name: "lastDemand",
  initialState: {
    error: null,
    lastDemands: [],
    status: null,
  },

  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchLastDemandDemandTotal.pending, (state) => {
      state.status = "loading";
    });
    builder.addCase(fetchLastDemandDemandTotal.fulfilled, (state, action) => {
      state.lastDemands = [];
      state.lastDemands = action.payload;
      state.status = Boolean(action.payload);
    });
    builder.addCase(fetchLastDemandDemandTotal.rejected, (state, action) => {
      state.status = "failed";
      state.error = action.error.message;
    });
  },
});


export default lastDemandCountSlice.reducer;
