import React, { useState } from "react";
import { Card, Tab, Nav } from "react-bootstrap";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ShowEmployerDemandRequestsInterviewMeets from "./ShowEmployerDemandRequestsInterviewMeets";
import ShowEmployerDemandSendsInterviewMeets from "./ShowEmployerDemandSendsInterviewMeets";
import { useDispatch, useSelector } from "react-redux";
import { setDemandMatchOfficeInterViewTab } from "../../../../../../../redux/redux-toolkit/demandMatchOfficeInterView";
import BitonisTabBar from "../../../../../../widgets/BitonisTabBar/BitonisTabBar";
import PageInfoTitle from "../../../../../../widgets/PageInfoTitle";
import PrevIconButton from "../../../../../../widgets/buttons/PrevIconButton";

const EmployerDemandMatchOfficeInterView = () => {
  const dispatch = useDispatch();
  const { activeCurrentTab } = useSelector(
    (state) => state.demandMatchOfficeInterView
  );
  const selectedChangeTab = (select) => {
    dispatch(setDemandMatchOfficeInterViewTab(select));
  };

  return (
    <div
      id="matchShow"
      style={{
        position: "relative",
      }}
    >
      <ToastContainer
        position="top-right"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />

      <PageInfoTitle title={"İşyerinde Görüşme Planladığım Adaylar"} />

      <div className="row">
        <div className="col-md-12">
          <Card>
            <Card.Body>
              <BitonisTabBar
                tabLeftTitle={"İş Görüşmesi Gönderdiklerim"}
                tabRightTitle={"İş Görüşmesi Onaylayanlar"}
                tabLeftBody={<ShowEmployerDemandRequestsInterviewMeets />}
                tabRightBody={<ShowEmployerDemandSendsInterviewMeets />}
                showBorder={false}
                activeTab={activeCurrentTab}
                tabStyle={{
                  fontSize: "0.72rem",
                }}
                onChangeTab={(tab) => {}}
              />
            </Card.Body>
          </Card>
        </div>
      </div>
    </div>
  );
};

export default EmployerDemandMatchOfficeInterView;
