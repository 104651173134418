import React, { useEffect, useState } from "react";
import "./CustomDesign.css";
import useIsMobile from "../hooks/useIsMobile";
import { Typography } from "@mui/material";

const CustomDesign= ({
  required,
  label,
  child,
  trailing,
  isColumn, //label ile select row mu yoksa column mu olmasına karar verir bunun için columnClassName e gerek yoktur ancak itemlar row yapılacaksa columnClassName gerek vardır örneğin col-xs-6 col-md-6 belirtmek yeterlidir
  columnClassName, //bu row itemlerda  uzunluğu belirler örneğin col-xs-6 col-md-6
}) => {
  const isMobile = useIsMobile();

  if (isColumn && isColumn === true) {
    return (
      <div className={columnClassName}>
        <CustomBody
          isColumn={isColumn}
          isMobile={isMobile}
          trailing={trailing}
          label={label}
          required={required}
          child={child}
        />
      </div>
    );
  } else {
    return (
      <CustomBody
        isColumn={isColumn}
        isMobile={isMobile}
        trailing={trailing}
        label={label}
        required={required}
        child={child}
      />
    );
  }
};

function CustomBody({ isColumn, isMobile, trailing, label, required, child }) {
  return (
    <div
      className={
        isColumn && isColumn ? "columnDropdownContainer" : "dropdownContainer"
      }
    >
      <div
        className={
          isColumn && isColumn ? "columnDropdownLabel" : "dropdownLabel"
        }
        style={
          trailing && isMobile === true
            ? {
              marginRight: "0px",
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-start",
              alignContent: "flex-start",
              alignItems: "center",
            }
            : {
              marginRight: "0px",
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-start",
              alignContent: "flex-start",
              alignItems: "center",
            }
        }
      >

        <Typography
          sx={{
            padding: isColumn && isColumn ? "5px" : "0px",
            fontSize: {
              xs: "11px",
              sm: "12px",
              md: "12px",
              lg: "13px",
              xl: "13px",
            },
            fontWeight: 600,
            color: {
              xs: "#001542",
              sm: "#001542",
              md: "#001542",
              lg: "#001542",
              xl: "#001542"
            },
          }}
        >

          {label}
          {required && <span style={{ color: "red" }}>*</span>}

        </Typography>

        {trailing && (
          <div
            style={{
              margin: isMobile ? "-10px" : "0px",
              padding: "0px",
              width: isMobile ? "60px" : "10px",
            }}
          >
            {trailing && trailing}
          </div>
        )}
      </div>
      <div
        className={
          isColumn && isColumn === true
            ? "columnChildContainer"
            : "childContainer"
        }
        style={
          trailing && isMobile === true
            ? {
              marginLeft: "10px",
            }
            : {
              marginLeft: "0px",
            }
        }
      >
        <div className={"dropdownChild"}>{child && child} </div>
      </div>
    </div>
  );
}

export default CustomDesign;
