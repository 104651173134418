import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  //bu kısım cv için yazıldı.
  matchCvBlueDetail: null,
  matchCvWhiteDetail: null,
  matchCvInternDetail: null,
  componentRef: null,
};

const matchDetailRedux = createSlice({
  name: "matchDetail",
  initialState,
  reducers: {
    setBlueCvMatchDetail: (state, action) => {
      state.matchCvBlueDetail = action.payload;
    },
    setWhiteCvMatchDetail: (state, action) => {
      state.matchCvWhiteDetail = action.payload;
    },
    setInternCvMatchDetail: (state, action) => {
      state.matchCvWhiteDetail = action.payload;
    },
    setComponentRef: (state, action) => {
      state.componentRef = action.payload;
    },
  },
});

export const {
  setBlueCvMatchDetail,
  setWhiteCvMatchDetail,
  setInternCvMatchDetail,setComponentRef,
} = matchDetailRedux.actions;
export default matchDetailRedux.reducer;

/*

import { useRef } from "react";
import { useReactToPrint } from "react-to-print";

function createCv() {
	
  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: "mavi-yaka-cv",
    onAfterPrint: () => alert("print succes"),
  });
}

*/
