import React, { useEffect, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import Select from "react-select";
import { connect, useDispatch } from "react-redux";
import {
  DropListAction,
  DropListWhiteAction,
} from "../../../../../../../action";
import {
  customStyles,
  customStylesMulti,
  labelStyle,
} from "../../../../../../customStyleInline";
import LicenseTypeRequired from "../../Common/LicenseTypeRequired";
import BitonisMultiDropdown from "../../../../../../widgets/BitonisMultiDropdown";
import BitonisSingleDropdown from "../../../../../../widgets/BitonisSingleDropdown";
import CustomDesign from "../../../../../../widgets/CustomDesign";

const FindBlueFeatures = (props) => {
  const {
    register,
    handleSubmit,
    control,
    watch,
    reset,
    setValue,
    errors,
    submitNewVersion,
    companyName,
    companyLocationName,
    checkCLicenseType,
    setCheckLicenseType,
    getValues,
  } = props;
  const [licenseIsShow, setLicenseIsShow] = useState(false);
  const [militaryIsShow, setMilitaryIsShow] = useState(true);

  useEffect(() => {
    if (!props.yearOfExperience.success) {
      props.dispatch(
        DropListAction.yearOfExperience({
          positionType: 1,
          isActive: true,
        })
      );
    }
    if (!props.yearOfExperienceWhite.success) {
      props.dispatch(
        DropListWhiteAction.yearOfExperienceWhite({
          positionType: 2,
          isActive: true,
        })
      );
    }
    if (!props.wayOfWorking.success) {
      props.dispatch(
        DropListAction.wayOfWorking({
          positionType: 1,
          isActive: true,
        })
      );
    }
    if (!props.wayOfWorkingWhite.success) {
      props.dispatch(
        DropListWhiteAction.wayOfWorkingWhite({
          positionType: 2,
          isActive: true,
        })
      );
    }
    if (!props.nationalityList.success) {
      props.dispatch(
        DropListAction.nationalityList({
          positionType: 1,
          isActive: true,
        })
      );
    }
    if (!props.ageRangeList.success) {
      props.dispatch(
        DropListAction.ageRangeList({
          positionType: 1,
          isActive: true,
        })
      );
    }
    if (!props.ageRangeListWhite.success) {
      props.dispatch(
        DropListWhiteAction.ageRangeListWhite({
          positionType: 2,
          isActive: true,
        })
      );
    }
    if (!props.driverLicenceType.success) {
      props.dispatch(
        DropListAction.driverLicenceType({
          positionType: 1,
          isActive: true,
        })
      );
    }
  }, []);

  const handleLicenseChange = (selectedLicense) => {
    if (selectedLicense && selectedLicense.value === 0) {
      setValue("drivingLicenceTypes", []);
      setLicenseIsShow(false);
    } else {
      setLicenseIsShow(true);
    }
  };

  const handleMilitaryStatus = (selectedGender) => {
    if (
      selectedGender &&
      (selectedGender.value === 1 || selectedGender.value == 0)
    ) {
      setMilitaryIsShow(false);
    } else {
      setMilitaryIsShow(true);
    }
    if (selectedGender.value == 0 || selectedGender.value == 1) {
      setValue("militaryStatus", "");
    }
  };

  useEffect(() => {
    const gender = watch("gender");
    //steplerden ilerlenip geri geldiğinde veya ilerlediğinde cinsiyete göre askerlik durumu kontrol edildi
    //cinsiyet kadınsa askerlik durumu gizlendi
    if (gender) {
      if (gender && (gender.value === 1 || gender.value == 0)) {
        setMilitaryIsShow(false);
      } else {
        setMilitaryIsShow(true);
      }
      if (gender.value == 0 || gender.value == 1) {
        setValue("militaryStatus", "");
      }
    } else {
      setMilitaryIsShow(false);
    }
    const drivingLicence = watch("drivingLicence");

    if (
      (drivingLicence && drivingLicence.value === 0) ||
      drivingLicence === undefined ||
      drivingLicence === null ||
      drivingLicence === ""
    ) {
      setValue("drivingLicenceTypes", []);
      setLicenseIsShow(false);
    } else {
      setLicenseIsShow(true);
    }
  }, []);

  const genderList = [
    //kontrol edildi
    {
      id: 0,
      name: "Farketmez",
    },
    {
      id: 1,
      name: "Kadın",
    },
    {
      id: 2,
      name: "Erkek",
    },
  ];

  const maritalStatus = [
    //kontrol edildi
    {
      id: 0,
      name: "Farketmez",
    },
    {
      id: 1,
      name: "Bekar",
    },
    {
      id: 2,
      name: "Evli",
    },
  ];

  const driverLicense = [
    //kontrol edildi
    {
      id: 0,
      name: "Farketmez",
    },
    {
      id: 1,
      name: "Var",
    },
  ];

  const militaryStatus = [
    //kontrol edildi
    {
      id: 0,
      name: "Farketmez",
    },
    {
      id: 1,
      name: "Tamamlandı",
    },
    {
      id: 2,
      name: "Tamamlanmadı",
    },
  ];
  const weekendWork = [
    { id: 0, name: "Yok" },
    { id: 1, name: "Tam Gün" },
    { id: 3, name: "Yarım Gün" },
    { id: 4, name: "Dönüşümlü" },
  ];
  const smoking = [
    //kontrol edildi
    {
      id: 0,
      name: "Farketmez",
    },
    { id: 2, name: "Hayır" },
  ];

  return (
    <div>
      <div
        className="card"
        style={{
          boxShadow: "none",
          margin: "0px",
          padding:"0px"
        }}
      >
        <div className="basic-form">
          <form onSubmit={handleSubmit(submitNewVersion)}>
            <CustomDesign
              label={"Uyruk"}
              required={true}
              child={
                <BitonisSingleDropdown
                  items={
                    props.nationalityList.nationalityList &&
                    props.nationalityList.nationalityList.map(
                      ({ id, name }) => ({
                        value: id,
                        label: name,
                      })
                    )
                  }
                  register={register}
                  watch={watch}
                  companyClassName={"nationalityId"}
                  control={control}
                  setValue={setValue}
                  errors={errors}
                  errorMessage={"Uyruk Boş geçilemez"}
                  placeholder="Uyruk Seçin"
                  onValueChanged={(e) => {
                    console.log(e);
                    if (e) {
                    }
                  }}
                  required={true}
                  getValues={getValues}
                />
              }
            />
            <CustomDesign
              label={"Cinsiyet"}
              required={true}
              child={
                <BitonisSingleDropdown
                  items={genderList.map(({ id, name }) => ({
                    value: id,
                    label: name,
                  }))}
                  register={register}
                  watch={watch}
                  companyClassName={"gender"}
                  control={control}
                  setValue={setValue}
                  errors={errors}
                  errorMessage={"Cinsiyet Boş geçilemez"}
                  placeholder="Cinsiyet Seçin"
                  onValueChanged={(e) => {
                    console.log(e);
                    if (e) {
                      handleMilitaryStatus(e);
                    }
                  }}
                  required={true}
                  getValues={getValues}
                />
              }
            />

            {militaryIsShow && (
              <CustomDesign
                label={" Askerlik Durumu"}
                required={true}
                child={
                  <BitonisSingleDropdown
                    items={militaryStatus.map(({ id, name }) => ({
                      value: id,
                      label: name,
                    }))}
                    register={register}
                    watch={watch}
                    companyClassName={"militaryStatus"}
                    control={control}
                    setValue={setValue}
                    errors={errors}
                    errorMessage={"Askerlik Durumu Boş geçilemez"}
                    placeholder="Askerlik Durumu Seçin"
                    onValueChanged={(e) => {
                      console.log(e);
                      if (e) {
                      }
                    }}
                    required={true}
                    getValues={getValues}
                  />
                }
              />
            )}
            <CustomDesign
              label={"Medeni Hal"}
              required={true}
              child={
                <BitonisSingleDropdown
                  items={maritalStatus.map(({ id, name }) => ({
                    value: id,
                    label: name,
                  }))}
                  register={register}
                  watch={watch}
                  companyClassName={"maritalStatus"}
                  control={control}
                  setValue={setValue}
                  errors={errors}
                  errorMessage={"Medeni Hal Boş geçilemez"}
                  placeholder="Medeni Hal Seçin"
                  onValueChanged={(e) => {
                    console.log(e);
                    if (e) {
                    }
                  }}
                  required={true}
                  getValues={getValues}
                />
              }
            />

            {props.ageRangeList.ageRangeList && (
              <CustomDesign
                label={"Yaş Aralığı"}
                required={true}
                child={
                  <BitonisMultiDropdown
                    items={
                      props.ageRangeList.ageRangeList &&
                      props.ageRangeList.ageRangeList.map(({ id, name }) => ({
                        value: id,
                        label: name,
                      }))
                    }
                    register={register}
                    watch={watch}
                    companyClassName={"ageRanges"}
                    control={control}
                    setValue={setValue}
                    errors={errors}
                    errorMessage={"Yaş Aralığı Boş geçilemez"}
                    placeholder="Yaş Aralığını Seçin"
                    onValueChanged={(value) => {
                      console.log(value);
                    }}
                    required={true}
                    selectedId={1}
                    getValues={getValues}
                  />
                }
              />
            )}

            <CustomDesign
              label={"Sigara Kullanımı"}
              required={true}
              child={
                <BitonisSingleDropdown
                  items={smoking.map(({ id, name }) => ({
                    value: id,
                    label: name,
                  }))}
                  register={register}
                  watch={watch}
                  companyClassName={"smokerApply"}
                  control={control}
                  setValue={setValue}
                  errors={errors}
                  errorMessage={"Sigara Kullanımı Boş geçilemez"}
                  placeholder="Sigara Kullanım Durumunu Seçin"
                  onValueChanged={(e) => {
                    console.log(e);
                    if (e) {
                    }
                  }}
                  required={true}
                  getValues={getValues}
                />
              }
            />
            <CustomDesign
              label={"Sürücü Belgesi"}
              required={true}
              child={
                <BitonisSingleDropdown
                  items={driverLicense.map(({ id, name }) => ({
                    value: id,
                    label: name,
                  }))}
                  register={register}
                  watch={watch}
                  companyClassName={"drivingLicence"}
                  control={control}
                  setValue={setValue}
                  errors={errors}
                  errorMessage={"Sürücü Belgesi Boş geçilemez"}
                  placeholder="Sürücü Belgesi Seçin"
                  onValueChanged={(e) => {
                    console.log(e);
                    if (e) {
                      handleLicenseChange(e);
                    }
                  }}
                  required={true}
                  getValues={getValues}
                />
              }
            />

            {licenseIsShow && (
              <LicenseTypeRequired
                licenseIsShow={licenseIsShow}
                props={props}
                control={control}
                register={register}
                errors={errors}
                checkCLicenseType={checkCLicenseType}
                setCheckLicenseType={setCheckLicenseType}
                watch={watch}
                setValue={setValue}
                companyClassName={"drivingLicenceTypes"}
                getValues={getValues}
              />
            )}
          </form>
        </div>
      </div>
    </div>
  );
};

function mapState(state) {
  let authentication = state.authentication;
  let labourer = state.labourer;
  let labourerMatch = state.labourerMatch;
  let demands = state.demands;
  let job = state.job;
  let yearOfExperience = state.yearOfExperience;
  let positionList = state.positionList;
  let wayOfWorking = state.wayOfWorking;
  let nationalityList = state.nationalityList;
  let ageRangeList = state.ageRangeList;
  let driverLicenceType = state.driverLicenceType;
  let programList = state.programList;
  let certificatesList = state.certificatesList;
  let competencesList = state.competencesList;
  let countryList = state.countryList;
  let cityList = state.cityList;
  let countyList = state.countyList;
  let districtList = state.districtList;
  let companyList = state.companyList;
  let companyLocation = state.companyLocation;

  let companyItemLocation = state.companyItemLocation;

  //alert("companyItemLocation * * * * * "+JSON.stringify(companyItemLocation))
  // Beyaz Yaka
  let yearOfExperienceWhite = state.yearOfExperienceWhite;
  let positionListWhite = state.positionListWhite;
  let wayOfWorkingWhite = state.wayOfWorkingWhite;
  let nationalityListWhite = state.nationalityListWhite;
  let ageRangeListWhite = state.ageRangeListWhite;
  let driverLicenceTypeWhite = state.driverLicenceTypeWhite;
  let programListWhite = state.programListWhite;
  let certificatesListWhite = state.certificatesListWhite;
  let competencesListWhite = state.competencesListWhite;
  let countryListWhite = state.countryListWhite;
  let cityListWhite = state.cityListWhite;
  let countyListWhite = state.countyListWhite;
  let districtListWhite = state.districtListWhite;
  let companyListWhite = state.companyListWhite;
  let salaryListWhite = state.salaryListWhite;
  let businessTravelListWhite = state.businessTravelListWhite;
  let languageListWhite = state.languageListWhite;
  let languageLevelListWhite = state.languageLevelListWhite;
  let universityListWhite = state.universityListWhite;
  let facultyListWhite = state.facultyListWhite;
  let facultyDepartmentListWhite = state.facultyDepartmentListWhite;

  return {
    authentication,
    labourer,
    labourerMatch,
    demands,
    job,
    yearOfExperience,
    positionList,
    wayOfWorking,
    nationalityList,
    ageRangeList,
    driverLicenceType,
    programList,
    certificatesList,
    competencesList,
    countryList,
    cityList,
    countyList,
    districtList,
    companyList,
    companyLocation,
    yearOfExperienceWhite,
    positionListWhite,
    wayOfWorkingWhite,
    nationalityListWhite,
    ageRangeListWhite,
    driverLicenceTypeWhite,
    programListWhite,
    certificatesListWhite,
    competencesListWhite,
    countryListWhite,
    cityListWhite,
    countyListWhite,
    districtListWhite,
    companyListWhite,
    salaryListWhite,
    businessTravelListWhite,
    languageListWhite,
    languageLevelListWhite,
    universityListWhite,
    facultyListWhite,
    facultyDepartmentListWhite,
    companyItemLocation,
  };
}

export default connect(mapState)(FindBlueFeatures);

/*
 <div>
      <div className="card">
        <div className="basic-form">
          <h3 style={{ top: "0px", color: "#001542" }}>{companyName}</h3>
          <p style={{ bottom: "20px", color: "#001542", top: "0px" }}>
            {companyLocationName}
          </p>
          <br></br>
          <form onSubmit={handleSubmit(submitNewVersion)}>
            <CustomDesign
              label={"Uyruk"}
              required={true}
              child={
                <BitonisSingleDropdown
                  items={
                    props.nationalityList.nationalityList &&
                    props.nationalityList.nationalityList.map(
                      ({ id, name }) => ({
                        value: id,
                        label: name,
                      })
                    )
                  }
                  register={register}
                  watch={watch}
                  companyClassName={"nationalityId"}
                  control={control}
                  setValue={setValue}
                  errors={errors}
                  errorMessage={"Uyruk Boş geçilemez"}
                  placeholder="Uyruk Seçin"
                  onValueChanged={(e) => {
                    console.log(e);
                    if (e) {
                    }
                  }}
                  required={true}
                  getValues={getValues}
                />
              }
            />
            <div className="form-group row">
              <label className="col-sm col-form-label" style={labelStyle}>
                Uyruk{" "}
                <label
                  style={{
                    color: "red",
                  }}
                >
                  {" "}
                  *
                </label>
              </label>
              <div className="col-sm-9">
                <BitonisSingleDropdown
                  items={
                    props.nationalityList.nationalityList &&
                    props.nationalityList.nationalityList.map(
                      ({ id, name }) => ({
                        value: id,
                        label: name,
                      })
                    )
                  }
                  register={register}
                  watch={watch}
                  companyClassName={"nationalityId"}
                  control={control}
                  setValue={setValue}
                  errors={errors}
                  errorMessage={"Uyruk Boş geçilemez"}
                  placeholder="Uyruk Seçin"
                  onValueChanged={(e) => {
                    console.log(e);
                    if (e) {
                    }
                  }}
                  required={true}
                  getValues={getValues}
                />
                {/*<Controller
                  name="nationalityId"
                  key={"nationalityId"}
                  control={control}
                  {...register("nationalityId", { required: true })}
                  render={({ field }) => (
                    <Select
                      {...field}
                      placeholder="Uyruk Seçin"
                      styles={customStyles}
                      options={
                        props.nationalityList.nationalityList &&
                        props.nationalityList.nationalityList.map(
                          ({ id, name }) => ({ value: id, label: name })
                        )
                      }
                      onChange={(e) => {
                        field.onChange(e);
                      }}
                    />
                  )}
              
              </div>
            </div>
            <div className="form-group row">
              <label className="col-sm col-form-label" style={labelStyle}>
                Cinsiyet{" "}
                <label
                  style={{
                    color: "red",
                  }}
                >
                  {" "}
                  *
                </label>
              </label>
              <div className="col-sm-9">
                <BitonisSingleDropdown
                  items={genderList.map(({ id, name }) => ({
                    value: id,
                    label: name,
                  }))}
                  register={register}
                  watch={watch}
                  companyClassName={"gender"}
                  control={control}
                  setValue={setValue}
                  errors={errors}
                  errorMessage={"Cinsiyet Boş geçilemez"}
                  placeholder="Cinsiyet Seçin"
                  onValueChanged={(e) => {
                    console.log(e);
                    if (e) {
                      handleMilitaryStatus(e);
                    }
                  }}
                  required={true}
                  getValues={getValues}
                />
              
              </div>
            </div>
            {militaryIsShow && (
              <div className="form-group row">
                <label className="col-sm col-form-label" style={labelStyle}>
                  Askerlik Durumu{" "}
                  <label
                    style={{
                      color: "red",
                    }}
                  >
                    {" "}
                    *
                  </label>
                </label>
                <div className="col-sm-9">
                  <BitonisSingleDropdown
                    items={militaryStatus.map(({ id, name }) => ({
                      value: id,
                      label: name,
                    }))}
                    register={register}
                    watch={watch}
                    companyClassName={"militaryStatus"}
                    control={control}
                    setValue={setValue}
                    errors={errors}
                    errorMessage={"Askerlik Durumu Boş geçilemez"}
                    placeholder="Askerlik Durumu Seçin"
                    onValueChanged={(e) => {
                      console.log(e);
                      if (e) {
                      }
                    }}
                    required={true}
                    getValues={getValues}
                  />
               
                </div>
              </div>
            )}
            <div className="form-group row">
              <label className="col-sm col-form-label" style={labelStyle}>
                Medeni Hal{" "}
                <label
                  style={{
                    color: "red",
                  }}
                >
                  {" "}
                  *
                </label>
              </label>
              <div className="col-sm-9">
                <BitonisSingleDropdown
                  items={maritalStatus.map(({ id, name }) => ({
                    value: id,
                    label: name,
                  }))}
                  register={register}
                  watch={watch}
                  companyClassName={"maritalStatus"}
                  control={control}
                  setValue={setValue}
                  errors={errors}
                  errorMessage={"Medeni Hal Boş geçilemez"}
                  placeholder="Medeni Hal Seçin"
                  onValueChanged={(e) => {
                    console.log(e);
                    if (e) {
                    }
                  }}
                  required={true}
                  getValues={getValues}
                />
           
              </div>
            </div>
            <div className="form-group row">
              <label className="col-sm col-form-label" style={labelStyle}>
                Yaş Aralığı{" "}
                <label
                  style={{
                    color: "red",
                  }}
                >
                  {" "}
                  *
                </label>
              </label>
              <div className="col-sm-9">
                {
                  props.ageRangeList.ageRangeList && (
                    <BitonisMultiDropdown
                      items={
                        props.ageRangeList.ageRangeList &&
                        props.ageRangeList.ageRangeList.map(({ id, name }) => ({
                          value: id,
                          label: name,
                        }))
                      }
                      register={register}
                      watch={watch}
                      companyClassName={"ageRanges"}
                      control={control}
                      setValue={setValue}
                      errors={errors}
                      errorMessage={"Yaş Aralığı Boş geçilemez"}
                      placeholder="Yaş Aralığını Seçin"
                      onValueChanged={(value) => {
                        console.log(value);
                      }}
                      required={true}
                      selectedId={1}
                      getValues={getValues}
                    />
                  )
               
              </div>
            </div>

            <div className="form-group row">
              <label className="col-sm col-form-label" style={labelStyle}>
                Sigara Kullanımı{" "}
                <label
                  style={{
                    color: "red",
                  }}
                >
                  {" "}
                  *
                </label>
              </label>
              <div className="col-sm-9">
                <BitonisSingleDropdown
                  items={smoking.map(({ id, name }) => ({
                    value: id,
                    label: name,
                  }))}
                  register={register}
                  watch={watch}
                  companyClassName={"smokerApply"}
                  control={control}
                  setValue={setValue}
                  errors={errors}
                  errorMessage={"Sigara Kullanımı Boş geçilemez"}
                  placeholder="Sigara Kullanım Durumunu Seçin"
                  onValueChanged={(e) => {
                    console.log(e);
                    if (e) {
                    }
                  }}
                  required={true}
                  getValues={getValues}
                />
              
              </div>
            </div>
            <div className="form-group row">
              <label className="col-sm col-form-label" style={labelStyle}>
                Sürücü Belgesi{" "}
                <label
                  style={{
                    color: "red",
                  }}
                >
                  {" "}
                  *
                </label>
              </label>
              <div className="col-sm-9">
                <BitonisSingleDropdown
                  items={driverLicense.map(({ id, name }) => ({
                    value: id,
                    label: name,
                  }))}
                  register={register}
                  watch={watch}
                  companyClassName={"drivingLicence"}
                  control={control}
                  setValue={setValue}
                  errors={errors}
                  errorMessage={"Sürücü Belgesi Boş geçilemez"}
                  placeholder="Sürücü Belgesi Seçin"
                  onValueChanged={(e) => {
                    console.log(e);
                    if (e) {
                      handleLicenseChange(e);
                    }
                  }}
                  required={true}
                  getValues={getValues}
                />
               
               
              </div>
            </div>

            {licenseIsShow && (
              <LicenseTypeRequired
                licenseIsShow={licenseIsShow}
                props={props}
                control={control}
                register={register}
                errors={errors}
                checkCLicenseType={checkCLicenseType}
                setCheckLicenseType={setCheckLicenseType}
                watch={watch}
                setValue={setValue}
                companyClassName={"drivingLicenceTypes"}
                getValues={getValues}
              />
            )}
          </form>
        </div>
      </div>
    </div >
      */
