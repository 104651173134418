import React from "react";

import "../../css/bitoniscss/customPreviewNoTitle.css";

const CustomPreviewNoTitle = ({ name, value }) => {
  return (
    <div className="widget-container">
      <div className="widget-value-containerNotTitle">
        <div className="widget-value">{value && value.toString()}</div>
      </div>
    </div>
  );
};

export default CustomPreviewNoTitle;
