import { UserConstants } from '../constants';

export function users(state = {}, action) {
    switch (action.type) {
        case UserConstants.GET_ALL_REQUEST:
            return {
                loading: true
            };
        case UserConstants.GET_ALL_SUCCESS:
            return {
                items: action.users
            };
        case UserConstants.GET_ALL_FAILURE:
            return {
                error: action.error
            };
        case UserConstants.DELETE_REQUEST:
            // add 'deleting:true' property to user being deleted
            return {
                ...state,
                items: state.items.map(user =>
                    user.id === action.id
                        ? { ...user, deleting: true }
                        : user
                )
            };
        case UserConstants.DELETE_SUCCESS:
            // remove deleted user from state
            return {
                items: state.items.filter(user => user.id !== action.id)
            };
        case UserConstants.DELETE_FAILURE:
            // remove 'deleting:true' property and add 'deleteError:[error]' property to user
            return {
                ...state,
                items: state.items.map(user => {
                    if (user.id === action.id) {
                        // make copy of user without 'deleting:true' property
                        const { deleting, ...userCopy } = user;
                        // return copy of user with 'deleteError:[error]' property
                        return { ...userCopy, deleteError: action.error };
                    }

                    return user;
                })
            };
        default:
            return state
    }
}

const defaultState = { loading: false, user: false, error: false};

function setState(changes) {
    return { ...defaultState, ...changes };
}

export function user(state = defaultState, action) {
    switch (action.type) {
        case UserConstants.GET_REQUEST:
            return setState({loading: true});
        case UserConstants.RESET_REQUEST:
            return setState({loading: true});
        case UserConstants.LOST_REQUEST:
            return setState({loading: true});
        case UserConstants.LOST_SUCCESS:
            return defaultState;
        case UserConstants.LOST_FAILURE:
            return defaultState;
        case UserConstants.RESET_FAILURE:
            return defaultState;
        case UserConstants.USER_SUCCESS:
            return setState({user: action.user, loading: false});
        case UserConstants.GET_FAILURE:
            return setState({error: action.error});
        default:
            return state
    }
}



const defaultOwnState = { loading: false, success: false};

function setOwnState(changes) {
    return { ...defaultOwnState, ...changes };
}

export function own(state = defaultOwnState, action) {
    switch (action.type) {
        case UserConstants.GET_REQUEST:
            return setState({loading: true});
        case UserConstants.OWN_SUCCESS:
            return setState({loading: false, success: true});
        case UserConstants.GET_SUCCESS:
            return setState({loading: false, success: true});
        case UserConstants.GET_FAILURE:
            return setState({loading: false, success: false});
        default:
            return state
    }
}
