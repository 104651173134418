import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  activeCurrentTab: 0,
  statusNotHireBlue: [{}],
  statusNotHireWhite: [{}],
};

const selectedNotHireTab = createSlice({
  name: "selectedNotHireTab",
  initialState,
  reducers: {
    setCurrentTabNotHire: (state, action) => {
      state.activeCurrentTab = action.payload;
    },
    setFilterBlueNotHire: (state, action) => {
      state.statusNotHireBlue = action.payload;
    },
    setFilterWhiteNotHire: (state, action) => {
      state.statusNotHireWhite = action.payload;
    },
  },
});

export const {
  setCurrentTabNotHire,
  setFilterBlueNotHire,
  setFilterWhiteNotHire,
} = selectedNotHireTab.actions;
export default selectedNotHireTab.reducer;
