import React from "react";
import logo from "../../../../../../../../../images/logo/logo.png";
import "../../../../../../../../../css/bitoniscss/demand/bluecvdetail.css";

const InfoWhiteCv = ({ whiteMatchDetail }) => {
  return (
    <div
      style={{
        flex: "6",
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          margin: "0px",
          padding: "0px",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            alignContent: "center",
            textAlign: "center",
            margin: "0px",
            padding: "0px",
          }}
        >
          <div className="">
            <div className="logo-image">
              <img src={logo} className="logoStyle" />
            </div>
          </div>
          <p
            style={{
              color: "#001542",
              fontWeight: "500",
              marginLeft: "5px",
              fontSize: "18px",
            }}
          >
            {whiteMatchDetail && whiteMatchDetail.candidateName}
            <p
              style={{
                margin: "0px",
                padding: "0px",
                fontSize: "11px",
                color: "grey",
                fontWeight: "420",
                margin: "0px",
                padding: "0px",
              }}
            >
              {whiteMatchDetail && whiteMatchDetail.mobilephone}
            </p>
          </p>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            marginLeft: "20px",
            justifyContent: "center",
            alignItems: "center",
            margin: "0px",
            padding: "0px",
            margin: "0px",
            padding: "0px",
          }}
        >
          <p
            style={{
              margin: "0px",
              padding: "0px",
              fontSize: "12px",
              color: "#001542",
              fontWeight: "500",
              marginTop: "0px",
              padding: "0px",
            }}
          >
            {whiteMatchDetail && whiteMatchDetail.pozitionMatchDetailCandidate}
          </p>

          <hr
            style={{
              width: "180px",
              color: "rgb(242, 240, 240)",
              margin: "0px",
              padding: "0px",
            }}
          />
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                margin: "0px",
                padding: "0px",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <p
                style={{
                  margin: "0px",
                  padding: "0px",
                  fontSize: "12px",
                  color: "#001542",
                  fontWeight: "500",
                }}
              >
                Yaş
              </p>
              <p
                style={{
                  margin: "0px",
                  padding: "0px",
                  fontSize: "11px",
                  fontWeight: "420",
                }}
              >
                {whiteMatchDetail && whiteMatchDetail.birthDate}
              </p>
            </div>
            <div
              style={{
                width: "100px",
              }}
            ></div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                margin: "0px",
                padding: "0px",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <p
                style={{
                  margin: "0px",
                  padding: "0px",
                  fontSize: "12px",
                  color: "#001542",
                  fontWeight: "500",
                }}
              >
                Toplam Deneyim
              </p>
              <p
                style={{
                  margin: "0px",
                  padding: "0px",
                  fontSize: "11px",
                  fontWeight: "420",
                }}
              >
                {whiteMatchDetail && whiteMatchDetail.yearofexperienceWhiteMatch&&
                whiteMatchDetail.yearofexperienceWhiteMatch === 0
                  ? "Tecrübesiz"
                  : whiteMatchDetail.yearofexperienceWhiteMatch}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default InfoWhiteCv;
