import React, { useState, useEffect, useMemo, useRef } from "react";
import { useParams } from "react-router-dom";
import { Link } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import ReactMultiSelectCheckboxes from "react-multiselect-checkboxes";
import "react-toastify/dist/ReactToastify.css";
import Select from "react-select";
import { useForm, Controller } from "react-hook-form";
import {
  useHistory,
  useLocation,
} from "react-router-dom/cjs/react-router-dom.min";
import { connect, useDispatch, useSelector } from "react-redux";
import "../../../../../../../../css/bitoniscss/blueDemandcss.css";
import {
  DashboardAction,
  DemandsAction,
  DropListAction,
} from "../../../../../../../../action";
import CompanyLocation from "../../DemandDetail/Common/CompanyLocation";
import initFillInFormWhiteDemandDetail from "../../DemandDetail/functions/initFillInFormWhiteDemandDetail";
import PositionWhiteDetail from "./PositionWhiteDetail";
import updateWhiteRequestDetailsToAPI from "../../DemandDetail/Services/updateWhiteRequestDetailsToAPI";
import ForeignLanguageDemandDetail from "./ForeignLanguageDemandDetail";
import EducationDemandDetail from "./EducationDemandDetail";
import PreferredLocationDetail from "../Common/PreferredLocationDetail";
import ChangeDemandStatus from "../BlueDemandDetail/ChangeDemandStatus";
import {
  customStyles,
  customStylesMulti,
  labelStyle,
} from "../../../../../../../customStyleInline";
import LoadingDotsAnimation from "../../../../../../../widgets/LoadingDotsAnimation";

const WhiteDemandPreview = (props) => {
  const location = useLocation(); // useLocation hook'u ile locationdaki gelen veriler alınır
  const dispatch = useDispatch();
  const { dataMap } = location.state;
  const jobParentId = dataMap.get("jobId");
  const [showLock, setShowLoc] = useState(false);
  const handleClose = () => setShow(!showLock);
  const [jobStatus, setJobStatus] = useState(null);
  const [jobs, setJobs] = useState(false);
  const [checkProgram, setCheckProgram] = useState(false);
  const [checkCertificate, setCheckCertificate] = useState(false);
  const [checkCompetence, setCheckCompetence] = useState(false);

  const {
    register,
    handleSubmit,
    control,
    watch,
    reset,
    setValue,
    getValues,
    formState: { errors, isValid },
  } = useForm();

  useEffect(() => {
    if (jobParentId) {
      props.dispatch(DashboardAction.clearJob());
      props.dispatch(DropListAction.clearCompanyList());
      props.dispatch(DashboardAction.jobDetail(jobParentId));
    }

  }, [jobParentId]);

  useEffect(() => {
    if (!props.countryList.success) {
      props.dispatch(DropListAction.countryList({}));
    }

    if (!props.companyList.success) {
      props.dispatch(DropListAction.companyList({}));
    }
    if (!props.companyLocation.success) {
      props.dispatch(DropListAction.companyLocation({}));
    }
    props.dispatch(DropListAction.searchCompanyList());
  }, []);

  const formRef = useRef({});
  const preferredLocationRef = useRef([]);
  const foreignLanguageListRef = useRef([]);
  const educationListRef = useRef([]);
  const facultyDepAndGroupIdsRef = useRef([]);

  useEffect(() => {
    if (props.job.job) {
      setJobStatus(props.job.job.isActive);
      initFillInFormWhiteDemandDetail(
        props.job.job,
        setValue,
        setCheckProgram,
        setCheckCertificate,
        setCheckCompetence
      ); //detay verileri geldiğinde formlara eklenmiş veriler doldurulur
    }
  }, [props.job.job]);
  useEffect(() => { }, []);
  const onSubmit = (form) => {
    formRef.current = { ...formRef.current, form };
    updateWhiteRequestDetailsToAPI(
      form,
      jobParentId,
      preferredLocationRef.current,
      foreignLanguageListRef.current,
      educationListRef.current,
      history,
      dispatch,
      checkProgram,
      checkCertificate,
      checkCompetence
    );
  };
  useEffect(() => { }, []);

  return (
    <div>
      {props.job.job == false ? <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
        }}
      >
        <LoadingDotsAnimation />
      </div>

        :
        <div id="demandBody">
          <ToastContainer
            position="top-right"
            autoClose={2000}
            hideProgressBar={false}
            newestOnTop
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
          />
          <h4 className="card-title">
            {" "}
            {props.job.job.companyName}
            <sub className="location">{props.job.job.companyLocationName}</sub>
          </h4>{" "}
          <div
            style={{
              padding: "10px",
            }}
          ></div>
          <CompanyLocation
            companyList={props.companyList}
            job={props.job}
            onSubmit={onSubmit}
            inputStyle={inputStyle}
            cardStyle={cardStyle}
            props={props}
            customStyles={customStyles}
            register={register}
            handleSubmit={handleSubmit}
            control={control}
            setValue={setValue}
            errors={errors}
            watch={watch}
            isValid={isValid}
            labelStyle={labelStyle}
            getValues={getValues}
            isIntern={false}
          />
          <PositionWhiteDetail
            register={register}
            onSubmit={onSubmit}
            inputStyle={inputStyle}
            cardStyle={cardStyle}
            props={props}
            customStyles={customStyles}
            job={props.job}
            positionList={props.positionList}
            customStylesMulti={customStylesMulti}
            setValue={setValue}
            isValid={isValid}
            handleSubmit={handleSubmit}
            control={control}
            errors={errors}
            watch={watch}
            labelStyle={labelStyle}
            checkProgram={checkProgram}
            checkCertificate={checkCertificate}
            checkCompetence={checkCompetence}
            setCheckProgram={setCheckProgram}
            setCheckCertificate={setCheckCertificate}
            setCheckCompetence={setCheckCompetence}
            getValues={getValues}
          />
          <ForeignLanguageDemandDetail
            register={register}
            onSubmit={onSubmit}
            inputStyle={inputStyle}
            cardStyle={cardStyle}
            props={props}
            customStyles={customStyles}
            job={props.job}
            positionList={props.positionList}
            customStylesMulti={customStylesMulti}
            setValue={setValue}
            isValid={isValid}
            handleSubmit={handleSubmit}
            control={control}
            errors={errors}
            watch={watch}
            foreignLanguageListRef={foreignLanguageListRef}
            labelStyle={labelStyle}
            getValues={getValues}
          />
          <EducationDemandDetail
            register={register}
            onSubmit={onSubmit}
            inputStyle={inputStyle}
            cardStyle={cardStyle}
            props={props}
            job={props.job}
            positionList={props.positionList}
            setValue={setValue}
            isValid={isValid}
            handleSubmit={handleSubmit}
            control={control}
            errors={errors}
            watch={watch}
            labelStyle={labelStyle}
            educationListRef={educationListRef}
            facultyDepAndGroupIdsRef={facultyDepAndGroupIdsRef}
            reset={reset}
            getValues={getValues}
          />
          <PreferredLocationDetail
            register={register}
            onSubmit={onSubmit}
            inputStyle={inputStyle}
            cardStyle={cardStyle}
            props={props}
            customStyles={customStyles}
            job={props.job}
            positionList={props.positionList}
            customStylesMulti={customStylesMulti}
            setValue={setValue}
            isValid={isValid}
            handleSubmit={handleSubmit}
            control={control}
            errors={errors}
            watch={watch}
            getValues={getValues}
            preferredLocationRef={preferredLocationRef}
            isOnIntern={false}
          />
          <ChangeDemandStatus jobId={jobParentId} defaultJobStatus={jobStatus} />
        </div>
      }
    </div>
  );
};

function mapState(state) {
  let authentication = state.authentication;
  let labourer = state.labourer;
  let labourerMatch = state.labourerMatch;
  let demands = state.demands;
  let job = state.job;
  let yearOfExperience = state.yearOfExperience;
  let positionList = state.positionList;
  let wayOfWorking = state.wayOfWorking;
  let nationalityList = state.nationalityList;
  let ageRangeList = state.ageRangeList;
  let driverLicenceType = state.driverLicenceType;
  let programList = state.programList;
  let certificatesList = state.certificatesList;
  let competencesList = state.competencesList;
  let countryList = state.countryList;
  let cityList = state.cityList;
  let countyList = state.countyList;
  let districtList = state.districtList;
  let companyList = state.companyList;
  let blueNewDemand = state.blueNewDemand;
  // Beyaz Yaka
  let yearOfExperienceWhite = state.yearOfExperienceWhite;
  let positionListWhite = state.positionListWhite;
  let wayOfWorkingWhite = state.wayOfWorkingWhite;
  let nationalityListWhite = state.nationalityListWhite;
  let ageRangeListWhite = state.ageRangeListWhite;
  let driverLicenceTypeWhite = state.driverLicenceTypeWhite;
  let programListWhite = state.programListWhite;
  let certificatesListWhite = state.certificatesListWhite;
  let competencesListWhite = state.competencesListWhite;
  let countryListWhite = state.countryListWhite;
  let cityListWhite = state.cityListWhite;
  let countyListWhite = state.countyListWhite;
  let districtListWhite = state.districtListWhite;
  let companyListWhite = state.companyListWhite;
  let salaryListWhite = state.salaryListWhite;
  let businessTravelListWhite = state.businessTravelListWhite;
  let languageListWhite = state.languageListWhite;
  let languageLevelListWhite = state.languageLevelListWhite;
  let universityListWhite = state.universityListWhite;
  let facultyListWhite = state.facultyListWhite;
  let facultyDepartmentListWhite = state.facultyDepartmentListWhite;
  let companyLocation = state.companyLocation;
  let companyItemLocation = state.companyItemLocation;
  let countyJobSeekerList = state.countyJobSeekerList;
  let searchCompanyList = state.searchCompanyList;
  let disabilityOfDegreeList = state.disabilityOfDegreeList;

  return {
    authentication,
    searchCompanyList,
    disabilityOfDegreeList,
    labourer,
    labourerMatch,
    demands,
    job,
    yearOfExperience,
    positionList,
    wayOfWorking,
    nationalityList,
    ageRangeList,
    driverLicenceType,
    programList,
    certificatesList,
    competencesList,
    countryList,
    cityList,
    countyList,
    districtList,
    companyList,
    yearOfExperienceWhite,
    positionListWhite,
    wayOfWorkingWhite,
    nationalityListWhite,
    ageRangeListWhite,
    driverLicenceTypeWhite,
    programListWhite,
    certificatesListWhite,
    competencesListWhite,
    countryListWhite,
    cityListWhite,
    countyListWhite,
    districtListWhite,
    companyListWhite,
    salaryListWhite,
    businessTravelListWhite,
    languageListWhite,
    languageLevelListWhite,
    universityListWhite,
    facultyListWhite,
    facultyDepartmentListWhite,
    blueNewDemand,
    companyLocation,
    companyItemLocation,
    countyJobSeekerList,
  };
}

export default connect(mapState)(WhiteDemandPreview);

var inputStyle = {
  borderRadius: "8px",
  height: "55px",
  backgroundColor: "#F5F5F5",
  margin: "0px",
  fontSize: "14px",
};
var cardStyle = {
  border: "1.1px dotted orange",
  padding: "10px",
};
