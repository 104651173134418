// src/features/dataSlice.js

import { createSlice } from "@reduxjs/toolkit";
import api from "../../utility/Utils";
function gGroupCompanies() { //Şirket grup şirketleri
    return api
      .get("/company")
      .then((response) => {
        return Promise.resolve(response.data);
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  }
export const getGroupCompanies = () => async (dispatch) => {
  try {
    dispatch(fetchGetCompaniesDataStart());
    gGroupCompanies().then(
      (groupCompany) => {
       // alert("groupCompany* * * * " + JSON.stringify(groupCompany));
        dispatch(fetchGetCompaniesDataSuccess(groupCompany));
      },
      (error) => {
        dispatch(fetchGetCompaniesDataFailure());
      }
    );
  } catch (error) {
    dispatch(fetchGetCompaniesDataFailure());
  }
};
const getGroupCompany_slicer = createSlice({
  name: "getGroupCompanies",
  initialState: {
    isLoading: false,
    success: false,
    getCompanies: null,
  },
  reducers: {
    fetchGetCompaniesDataStart(state) {
      state.isLoading = true;
      state.success = false;
    },
    fetchGetCompaniesDataSuccess(state, action) {
      state.isLoading = false;
      state.success = true;
      state.getCompanies = action.payload;
   //  alert("state.getCompanies * * * " + JSON.stringify(state.getCompanies));
    },
    fetchGetCompaniesDataFailure(state) {
      state.isLoading = false;
      state.success = false;
      state.getCompanies = null;
    },
  },
});

export const {
  fetchGetCompaniesDataStart,
  fetchGetCompaniesDataSuccess,
  fetchGetCompaniesDataFailure,
} = getGroupCompany_slicer.actions;

export default getGroupCompany_slicer.reducer;

