export default function initFillInFormWhiteDemandDetail(
  job,
  setValue,
  setCheckProgram,
  setCheckCertificate,
  setCheckCompetence
) {
  //forma bütün değerler tanımlandı
  setValue("positionId", ""),
    setValue("companyId", ""),
    setValue("companyLocationId", ""),
    setValue("jobExperienceRanges", ""),
    setValue("jobBusinessTravelRestrictions", ""),
    setValue("jobWeekendWorks", ""),
    setValue("workTypes", ""),
    setValue("jobSalaryRanges", ""),
    setValue("personCount", ""),
    setValue("educationTypes", ""),
    setValue("nationalityId", ""),
    setValue("gender", ""),
    setValue("militaryStatus", ""),
    setValue("maritalStatus", ""),
    setValue("ageRanges", ""),
    setValue("smokerApply", ""),
    setValue("drivingLicence", ""),
    setValue("drivingLicenceTypes", ""),
    setValue("jobPrograms", ""),
    setValue("jobCertificates", ""),
    setValue("jobCompetences", ""),
    setCheckProgram(job.isAllProgramsMust);
  setCheckCertificate(job.isAllCertificatesMust);
  setCheckCompetence(job.isAllCompetencesMust);
  setValue("positionId", {
    value: job.positionId,
    label: job.positionName,
  });
  setValue("companyId", {
    value: job.companyId,
    label: job.companyName,
  });
  setValue("disabilityStatus", {
    value: job.jobDisability.disability,
    label: job.jobDisability.description,
  });
  setValue(
    "degreeOfDisability",
    job.jobDisability.jobDegreeOfDisabilities == undefined ||
      job.jobDisability.jobDegreeOfDisabilities == null ||
      job.jobDisability.jobDegreeOfDisabilities == [] ||
      job.jobDisability.jobDegreeOfDisabilities == ""
      ? []
      : job.jobDisability.jobDegreeOfDisabilities.map(
          ({ id, description }) => ({
            value: id,
            label: description,
          })
        )
  );
  setValue("companyLocationId", {
    value: job.companyLocationId,
    label: job.companyLocationName,
  });
  setValue(
    "jobExperienceRanges",
    job.experienceRanges.map(({ id, name }) => ({
      value: id,
      label: name,
    }))
  );
  setValue(
    "jobBusinessTravelRestrictions",
    job.jobBusinessTravelRestrictions.map(
      ({ businessTravelRestriction, description }) => ({
        value: businessTravelRestriction,
        label: description,
      })
    )
  );

  setValue("jobWeekendWorks", {
    value: job.jobWeekendWorks[0] && job.jobWeekendWorks[0].id,
    label: job.jobWeekendWorks[0] && job.jobWeekendWorks[0].description,
  });

  setValue(
    "workTypes",
    job.workTypes.map(({ id, name }) => ({
      value: id,
      label: name,
    }))
  );
  setValue(
    "salaryRanges",
    job.jobSalaryRanges.map(({ id, name }) => ({
      value: id,
      label: name,
    }))
  );
  setValue("personCount", {
    value: job.personCount,
    label: job.personCount,
  });
  setValue("educationTypes", {
    value: job.jobEducations[0].educationType,
    label: job.jobEducations[0].description,
  });
  setValue("nationalityId", {
    value: job.jobNationality.id,
    label: job.jobNationality.name,
  });
  setValue("gender", {
    value: job.gender.gender,
    label: job.gender.description,
  });
  setValue(
    "militaryStatus",
    job.jobMilitaryStatus == null
      ? null
      : {
          value: job.jobMilitaryStatus.militaryStatus,
          label: job.jobMilitaryStatus.description,
        }
  );
  setValue("maritalStatus", {
    value: job.jobMaritalStatus.maritalStatus,
    label: job.jobMaritalStatus.description,
  });
  setValue(
    "ageRanges",
    job.jobAgeRange.map(({ id, name }) => ({
      value: id,
      label: name,
    }))
  );
  setValue("smokerApply", {
    value: job.jobSmoking.smoking,
    label: job.jobSmoking.description,
  });
  setValue("drivingLicence", {
    value: job.drivingLicence.drivingLicence,
    label: job.drivingLicence.description,
  });
  setValue(
    "drivingLicenceTypes",
    job.drivingLicence.jobDrivingLicenceTypes == []
      ? []
      : job.drivingLicence.jobDrivingLicenceTypes.map(
          ({ id, description }) => ({
            value: id,
            label: description,
          })
        )
  );
  setValue(
    "jobPrograms",
    job.jobPrograms == []
      ? []
      : job.jobPrograms.map(({ id, name }) => ({
          value: id,
          label: name,
        }))
  );
  setValue(
    "jobCertificates",
    job.jobCertificates == []
      ? []
      : job.jobCertificates.map(({ id, name }) => ({
          value: id,
          label: name,
        }))
  );
  setValue(
    "jobCompetences",
    job.jobCompetence == []
      ? []
      : job.jobCompetence.map(({ id, name }) => ({
          value: id,
          label: name,
        }))
  );
}
