import React, { Fragment, useEffect, useMemo, useState } from "react";
import { Alert, Button, Col, Container, Dropdown, Row } from "react-bootstrap";
import { useForm, Controller } from "react-hook-form";
import "react-toastify/dist/ReactToastify.css";
import Select from "react-select";
import { ToastContainer, toast } from "react-toastify";
import Modal from "react-bootstrap/Modal";
import { customStyles } from "../../../../../customStyleInline";
import { ProfileDetailAction, TaxInfoAction } from "../../../../../../action";
import { connect, useDispatch } from "react-redux";
import BitonisSingleDropdown from "../../../../../widgets/BitonisSingleDropdown";
import CustomDesign from "../../../../../widgets/CustomDesign";
import CustomButton from "../../../../../widgets/buttons/CustomButton";

const EditProfileCompanyMainDetail = ({
  show,
  handleClose,
  props,
  companyMainDetailData,
}) => {
  const {
    register,
    handleSubmit,
    control,
    watch,
    reset,
    setValue,
    getValues,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    if (show) {
      reset();
      setValue("companyName", "");
      setValue("brandCompanyName", "");
      setValue("sector", "");
      setValue("taxCity", "");
      setValue("taxOfficeId", "");
      setValue("phoneNumber", "");
      setValue(
        "phoneNumber",
        companyMainDetailData && companyMainDetailData.phoneNumber
      );
      setValue(
        "companyName",
        companyMainDetailData && companyMainDetailData.name
      );
      setValue(
        "brandCompanyName",
        companyMainDetailData && companyMainDetailData.alias
      );
      setValue("sector", {
        label: companyMainDetailData && companyMainDetailData.sectors[0].name,
        value: companyMainDetailData && companyMainDetailData.sectors[0].id,
      });
      setValue("taxCity", {
        label:
          companyMainDetailData && companyMainDetailData.taxOffice.cityName,
        value: companyMainDetailData && companyMainDetailData.taxOffice.cityId,
      });
      setValue("taxOfficeId", {
        label: companyMainDetailData && companyMainDetailData.taxOffice.name,
        value: companyMainDetailData && companyMainDetailData.taxOffice.id,
      });
      props.dispatch(
        TaxInfoAction.taxOffices(
          companyMainDetailData && companyMainDetailData.taxOffice.cityCode
        )
      );
    }
  }, [show]);

  function editCompanyDetail(formData) {
    var sendData = {
      id: companyMainDetailData.id,
      name: formData.companyName,
      alias:
        formData.brandCompanyName == null ||
        formData.brandCompanyName.toString() == "null" ||
        formData.brandCompanyName.toString().isEmpty ||
        formData.brandCompanyName == ""
          ? formData.companyName
          : formData.brandCompanyName.toString(),
      taxNumber: companyMainDetailData.taxNumber,
      taxOfficeId: formData.taxOfficeId.value,
      sectorIds: [formData.sector.value],
      phoneNumber: formData.phoneNumber.toString(),
    };
    handleClose();
    props.dispatch(ProfileDetailAction.companyProfileUpdate(sendData));
  }
  const handleTaxCounty = (e) => {
    const selectedValue = e;

    const selectedCity = props.cityList.cityList.find(
      (item) => item.code.toString() === selectedValue.code.toString()
    );
    const cityCode = selectedCity ? selectedCity.code : null;
    props.dispatch(TaxInfoAction.taxOffices(cityCode));
  };

  return (
    <Modal
      className="fade bd-example-modal-lg"
      size="lg"
      show={show}
      onHide={handleClose}
      backdrop="static"
      keyboard={false}
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header
        closeButton
        style={{
          borderBottom: "none",
          justifyContent: "center",
          marginBottom: "0px",
          paddingBottom: "0px",
        }}
      >
        <Modal.Title aria-labelledby="contained-modal-title-vcenter" centered>
          <h4 style={{ color: "#001542", fontWeight: "600" }}>
            {" "}
            Şirket Bilgilerimi Güncelle
          </h4>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body
        className="grid-example"
        style={{
          marginBottom: "0px",
          paddingBottom: "0px",
        }}
      >
        <div className="container">
          <form onSubmit={handleSubmit(editCompanyDetail)}>
            <div className="row">
              <CustomDesign
                label={"Firma Adı"}
                required={true}
                isColumn={true}
                columnClassName={"col-xs-6 col-md-6"}
                child={
                  <div>
                    <input
                      type="text"
                      className="form-control"
                      id="inputClassActive"
                      name="companyName"
                      {...register("companyName", { required: true })}
                      placeholder="Firma Adı"
                    ></input>
                    {errors.companyName && (
                      <div className="text-danger fs-12">
                        {"Firma Adı Boş Olamaz "}
                      </div>
                    )}
                  </div>
                }
              />

              <CustomDesign
                label={"Şirket Marka Adı"}
                required={false}
                isColumn={true}
                columnClassName={"col-xs-6 col-md-6"}
                child={
                  <input
                    type="text"
                    className="form-control"
                    id="inputClassActive"
                    name="brandCompanyName"
                    {...register("brandCompanyName", { required: false })}
                    placeholder="Firma Marka Adı"
                  ></input>
                }
              />

              <CustomDesign
                label={"Sektör"}
                required={true}
                isColumn={true}
                columnClassName={"col-xs-6 col-md-6"}
                child={
                  <BitonisSingleDropdown
                    items={
                      props.sectorList.sectorList &&
                      props.sectorList.sectorList.map(({ id, name }) => ({
                        value: id,
                        label: name,
                      }))
                    }
                    register={register}
                    watch={watch}
                    companyClassName={"sector"}
                    control={control}
                    setValue={setValue}
                    errors={errors}
                    placeholder="Sektör Seç"
                    onValueChanged={(e) => {
                      console.log(e);
                      if (e) {
                      }
                    }}
                    required={true}
                    getValues={getValues}
                    errorMessage={"Sektör No Boş Olamaz"}
                  />
                }
              />

              <CustomDesign
                label={"Telefon No"}
                required={true}
                isColumn={true}
                columnClassName={"col-xs-6 col-md-6"}
                child={
                  <div>
                    <input
                      type="text"
                      className="form-control"
                      id="inputClassActive"
                      name="phoneNumber"
                      maxLength={11}
                      {...register("phoneNumber", { required: true })}
                      placeholder="Telefon No"
                    ></input>{" "}
                    {errors.phoneNumber && (
                      <div className="text-danger fs-12">
                        {"Telefon No Boş Olamaz "}
                      </div>
                    )}
                  </div>
                }
              />

              <CustomDesign
                label={"Vergi Dairesi İli"}
                required={true}
                isColumn={true}
                columnClassName={"col-xs-6 col-md-6"}
                child={
                  <BitonisSingleDropdown
                    items={
                      props.cityList.cityList &&
                      props.cityList.cityList.map(({ id, name, code }) => ({
                        value: id,
                        label: name,
                        code: code,
                      }))
                    }
                    register={register}
                    watch={watch}
                    companyClassName={"taxCity"}
                    control={control}
                    setValue={setValue}
                    errors={errors}
                    placeholder="Vergi Dairesi İli Seç"
                    onValueChanged={(e) => {
                      console.log(e);
                      setValue("taxOfficeId", "");
                      if (e) {
                        handleTaxCounty(e);
                      }
                    }}
                    required={true}
                    getValues={getValues}
                    errorMessage={"Vergi Dairesi İli Boş Olamaz"}
                  />
                }
              />

              <CustomDesign
                label={"Vergi Dairesi"}
                required={true}
                isColumn={true}
                columnClassName={"col-xs-6 col-md-6"}
                child={
                  <BitonisSingleDropdown
                    items={
                      props.taxOffices.taxOffices &&
                      props.taxOffices.taxOffices.pagedData.map(
                        ({ id, name }) => ({
                          value: id,
                          label: name,
                        })
                      )
                    }
                    register={register}
                    watch={watch}
                    companyClassName={"taxOfficeId"}
                    control={control}
                    setValue={setValue}
                    errors={errors}
                    placeholder="Vergi Dairesi Seçin"
                    onValueChanged={(e) => {
                      console.log(e);
                      if (e) {
                      }
                    }}
                    required={true}
                    getValues={getValues}
                    errorMessage={"Vergi Dairesi Boş Olamaz"}
                  />
                }
              />
            </div>
          </form>
        </div>
      </Modal.Body>
      <Modal.Footer
        style={{
          display: "flex",
          justifyContent: "center",
          borderTop: "none",
        }}
      >
        <div
          className="col-xs-6 col-md-6"
          style={{
            paddingBottom: "0px",
            marginBottom: "10px",
          }}
        >
          <form onSubmit={handleSubmit(editCompanyDetail)}>
            <CustomButton
              title={"Kaydet"}
              width={{
                xs: "400px",
                sm: "400px",
                md: "400px",
                lg: "400px",
                xl: "400px",
              }}
            />
          </form>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default EditProfileCompanyMainDetail;
