import React, { useState, useEffect, useMemo, useRef } from "react";
import { useParams } from "react-router-dom";
import { Link } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import ReactMultiSelectCheckboxes from "react-multiselect-checkboxes";
import "react-toastify/dist/ReactToastify.css";
import Select from "react-select";

import { useForm, Controller } from "react-hook-form";
import {
  useHistory,
  useLocation,
} from "react-router-dom/cjs/react-router-dom.min";
import { connect, useDispatch, useSelector } from "react-redux";
import {
  Card,
  Tab,
  Nav,
  Dropdown,
  Modal,
  Button,
  Badge,
  Alert,
} from "react-bootstrap";
import "../../../../../../../../css/bitoniscss/blueDemandcss.css";
import "../../../../../../../../css/bitoniscss/demandDetail.css";
import {
  DashboardAction,
  DemandsAction,
  DropListAction,
  DropListWhiteAction,
} from "../../../../../../../../action";
import PositionDetail from "./PositionDetail";
import ChangeDemandStatus from "./ChangeDemandStatus";
import initFillInFormFDemandDetail from "../functions/initFillInFormFDemandDetail";
import updateRequestDetailsToAPI from "../Services/updateRequestDetailsToAPI.js";
import PreferredLocationDetail from "../Common/PreferredLocationDetail";
import CompanyLocation from "../Common/CompanyLocation";
import {
  customStyles,
  customStylesMulti,
  inputStyle,
} from "../../../../../../../customStyleInline";
import LoadingDotsAnimation from "../../../../../../../widgets/LoadingDotsAnimation.jsx";
import useIsMobile from "../../../../../../../hooks/useIsMobile.jsx";

const BlueDemandPreview = (props) => {
  const dispatch = useDispatch();

  const {
    register,
    handleSubmit,
    control,
    watch,
    reset,
    getValues,
    setValue,
    formState: { errors, isValid },
  } = useForm();
  const location = useLocation(); // useLocation hook'u ile location alınır

  const { dataMap } = location.state;

  const jobParentId = dataMap.get("jobId");

  const history = useHistory();
  const [jobStatus, setJobStatus] = useState(null);



  useEffect(() => {
    //if (!props.job.success) {//yenileme olmuyor bu şekilde
    if (jobParentId) {
      props.dispatch(DashboardAction.clearJob());
      props.dispatch(DropListAction.clearCompanyList());
      props.dispatch(DashboardAction.jobDetail(jobParentId));

    }
  }, [jobParentId]);
  //[props.job.success]);

  useEffect(() => {
    if (!props.countryList.success) {
      props.dispatch(
        DropListAction.countryList({
          positionType: 1,
          isActive: true,
        })
      );
    }
    if (!props.cityList.success) {
      props.dispatch(
        DropListAction.cityList({
          countryId: 1,
        })
      );
    }

    if (!props.positionList.success) {
      props.dispatch(
        DropListAction.positionList({
          positionType: 1,
          isActive: true,
        })
      );
    }
    if (!props.positionListWhite.success) {
      props.dispatch(
        DropListWhiteAction.positionListWhite({
          positionType: 2,
          isActive: true,
        })
      );
    }
    if (!props.yearOfExperience.success) {
      props.dispatch(
        DropListAction.yearOfExperience({
          positionType: 1,
          isActive: true,
        })
      );
    }
    if (!props.yearOfExperienceWhite.success) {
      props.dispatch(
        DropListWhiteAction.yearOfExperienceWhite({
          positionType: 2,
          isActive: true,
        })
      );
    }
    if (!props.wayOfWorking.success) {
      props.dispatch(
        DropListAction.wayOfWorking({
          positionType: 1,
          isActive: true,
        })
      );
    }
    if (!props.wayOfWorkingWhite.success) {
      props.dispatch(
        DropListWhiteAction.wayOfWorkingWhite({
          positionType: 2,
          isActive: true,
        })
      );
    }
    if (!props.nationalityList.success) {
      props.dispatch(
        DropListAction.nationalityList({
          positionType: 1,
          isActive: true,
        })
      );
    }
    if (!props.ageRangeList.success) {
      props.dispatch(
        DropListAction.ageRangeList({
          positionType: 1,
          isActive: true,
        })
      );
    }
    if (!props.ageRangeListWhite.success) {
      props.dispatch(
        DropListWhiteAction.ageRangeListWhite({
          positionType: 2,
          isActive: true,
        })
      );
    }
    if (!props.driverLicenceType.success) {
      props.dispatch(
        DropListAction.driverLicenceType({
          positionType: 1,
          isActive: true,
        })
      );
    }
    if (!props.salaryListWhite.success) {
      props.dispatch(
        DropListWhiteAction.salaryListWhite({
          positionType: 2,
          isActive: true,
        })
      );
    }
    if (!props.businessTravelListWhite.success) {
      props.dispatch(
        DropListWhiteAction.businessTravelListWhite({
          positionType: 2,
          isActive: true,
        })
      );
    }

    if (!props.companyList.success) {
      props.dispatch(DropListAction.companyList({}));
    }
    if (!props.companyLocation.success) {
      props.dispatch(DropListAction.companyLocation({}));
    }
    props.dispatch(DropListAction.searchCompanyList());
  }, []);
  const [checkProgram, setCheckProgram] = useState(false);
  const [checkCertificate, setCheckCertificate] = useState(false);
  const [checkCompetence, setCheckCompetence] = useState(false);
  const [checkLicenseType, setCheckLicenseType] = useState(false);
  const [interviewContactValue, setInterviewContactValue] = useState(false);

  useEffect(() => {
    if (props.job.job) {
      setJobStatus(props.job.job.isActive)
      initFillInFormFDemandDetail(
        props.job.job,
        setValue,
        setCheckProgram,
        setCheckCertificate,
        setCheckCompetence,
        setCheckLicenseType,
        interviewContactValue,
        setInterviewContactValue
      ); //detay verileri geldiğinde formlara eklenmiş veriler doldurulur
    }
  }, [props.job.job]);

  const formRef = useRef({});
  const preferredLocationRef = useRef([]);

  useEffect(() => { }, []);
  const onSubmit = (form) => {
    formRef.current = { ...formRef.current, form };
    updateRequestDetailsToAPI(
      form,
      jobParentId,
      preferredLocationRef.current,
      history,
      dispatch,
      checkProgram,
      checkCertificate,
      checkCompetence,
      checkLicenseType,
      interviewContactValue,
      props.job
    );
  };


  return (
    <div>
      {
        props.job.job == false ? <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
          }}
        >
          <LoadingDotsAnimation />
        </div> : <div id="demandBody">
          <ToastContainer
            position="top-right"
            autoClose={2000}
            hideProgressBar={false}
            newestOnTop
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
          />
          <h4 className="card-title">
            {" "}
            {props.job.job.companyName}
            <sub className="location">{props.job.job.companyLocationName}</sub>
          </h4>{" "}
          <div
            style={{
              padding: "10px",
            }}
          ></div>
          <CompanyLocation
            companyList={props.companyList}
            job={props.job}
            onSubmit={onSubmit}
            inputStyle={inputStyle}
            cardStyle={cardStyle}
            props={props}
            customStyles={customStyles}
            register={register}
            handleSubmit={handleSubmit}
            control={control}
            setValue={setValue}
            errors={errors}
            watch={watch}
            isValid={isValid}
            getValues={getValues}
            isIntern={false}
          />
          <PositionDetail
            register={register}
            onSubmit={onSubmit}
            inputStyle={inputStyle}
            cardStyle={cardStyle}
            props={props}
            customStyles={customStyles}
            job={props.job}
            positionList={props.positionList}
            customStylesMulti={customStylesMulti}
            setValue={setValue}
            isValid={isValid}
            handleSubmit={handleSubmit}
            control={control}
            errors={errors}
            watch={watch}
            checkProgram={checkProgram}
            checkCertificate={checkCertificate}
            checkCompetence={checkCompetence}
            setCheckProgram={setCheckProgram}
            setCheckCertificate={setCheckCertificate}
            setCheckCompetence={setCheckCompetence}
            checkLicenseType={checkLicenseType}
            setCheckLicenseType={setCheckLicenseType}
            getValues={getValues}
            interviewContactValue={interviewContactValue}
            setInterviewContactValue={setInterviewContactValue}
          />
          <PreferredLocationDetail
            register={register}
            onSubmit={onSubmit}
            inputStyle={inputStyle}
            cardStyle={cardStyle}
            props={props}
            customStyles={customStyles}
            job={props.job}
            positionList={props.positionList}
            customStylesMulti={customStylesMulti}
            setValue={setValue}
            isValid={isValid}
            handleSubmit={handleSubmit}
            control={control}
            errors={errors}
            watch={watch}
            preferredLocationRef={preferredLocationRef}
            getValues={getValues}
            isOnIntern={false}

          />
          <ChangeDemandStatus jobId={jobParentId} defaultJobStatus={jobStatus} />
        </div>
      }
    </div>
  );
};

function mapState(state) {
  let authentication = state.authentication;
  let labourer = state.labourer;
  let labourerMatch = state.labourerMatch;
  let demands = state.demands;
  let job = state.job;

  let yearOfExperience = state.yearOfExperience;
  let positionList = state.positionList;
  let wayOfWorking = state.wayOfWorking;
  let nationalityList = state.nationalityList;
  let ageRangeList = state.ageRangeList;
  let driverLicenceType = state.driverLicenceType;
  let programList = state.programList;
  let certificatesList = state.certificatesList;
  let competencesList = state.competencesList;
  let countryList = state.countryList;
  let cityList = state.cityList;
  let countyList = state.countyList;
  let districtList = state.districtList;
  let companyList = state.companyList;
  let blueNewDemand = state.blueNewDemand;
  // Beyaz Yaka
  let yearOfExperienceWhite = state.yearOfExperienceWhite;
  let positionListWhite = state.positionListWhite;
  let wayOfWorkingWhite = state.wayOfWorkingWhite;
  let nationalityListWhite = state.nationalityListWhite;
  let ageRangeListWhite = state.ageRangeListWhite;
  let driverLicenceTypeWhite = state.driverLicenceTypeWhite;
  let programListWhite = state.programListWhite;
  let certificatesListWhite = state.certificatesListWhite;
  let competencesListWhite = state.competencesListWhite;
  let countryListWhite = state.countryListWhite;
  let cityListWhite = state.cityListWhite;
  let countyListWhite = state.countyListWhite;
  let districtListWhite = state.districtListWhite;
  let companyListWhite = state.companyListWhite;
  let salaryListWhite = state.salaryListWhite;
  let businessTravelListWhite = state.businessTravelListWhite;
  let languageListWhite = state.languageListWhite;
  let languageLevelListWhite = state.languageLevelListWhite;
  let universityListWhite = state.universityListWhite;
  let facultyListWhite = state.facultyListWhite;
  let facultyDepartmentListWhite = state.facultyDepartmentListWhite;
  let companyLocation = state.companyLocation;
  let companyItemLocation = state.companyItemLocation;
  let countyJobSeekerList = state.countyJobSeekerList;
  let searchCompanyList = state.searchCompanyList;
  let disabilityOfDegreeList = state.disabilityOfDegreeList;

  return {
    authentication,
    searchCompanyList,
    labourer,
    labourerMatch,
    demands,
    job,
    yearOfExperience,
    positionList,
    wayOfWorking,
    nationalityList,
    ageRangeList,
    driverLicenceType,
    programList,
    certificatesList,
    competencesList,
    countryList,
    cityList,
    countyList,
    districtList,
    companyList,
    yearOfExperienceWhite,
    positionListWhite,
    wayOfWorkingWhite,
    nationalityListWhite,
    ageRangeListWhite,
    driverLicenceTypeWhite,
    programListWhite,
    certificatesListWhite,
    competencesListWhite,
    countryListWhite,
    cityListWhite,
    countyListWhite,
    districtListWhite,
    companyListWhite,
    salaryListWhite,
    businessTravelListWhite,
    languageListWhite,
    languageLevelListWhite,
    universityListWhite,
    facultyListWhite,
    facultyDepartmentListWhite,
    blueNewDemand,
    companyLocation,
    companyItemLocation,
    countyJobSeekerList,
    disabilityOfDegreeList,
  };
}

export default connect(mapState)(BlueDemandPreview);

var cardStyle = {
  border: "1.1px dotted orange",
  padding: "10px",
};
