import React, { useEffect, useMemo, useState } from "react";
import CustomPreview from "../../../../../../../widgets/CustomPreview";
import EditInternDemandFeatureModal from "./Modal/EditInternDemandFeatureModal";
import { DemandsServices } from "../../../../../../../../services";
import EditIconWidget from "../../../../../../../widgets/EditIconWidget";
import { Box } from "@mui/material";
import PageInfoTitle from "../../../../../../../widgets/PageInfoTitle";

const InternDemandFeatureDetail = ({
  register,
  onSubmit,
  handleSubmit,
  inputStyle,
  cardStyle,
  job,
  props,
  customStyles,
  customStylesMulti,
  isValid,
  control,
  setValue,
  errors,
  watch,reset,

  getValues,
}) => {
  const [showEdit, setShowEdit] = useState(null);
  function handleClose() {
    setShowEdit(false);
  }
 

  return (
    <>
      <div class="card" style={cardStyle}>
        <div
          class="card-header"
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
            borderBottom: "none",
            marginBottom: "0px",
            paddingBottom: "0px",
            marginTop: "0px",
            paddingTop: "10px",
          }}
        >
          <PageInfoTitle title={"Aradığım Özellikler"}/>
          <Box
            sx={{
              position: "absolute",
              top: {
                xs: 30,
                sm: 45,
                md: 50,
                lg: 50,
                xl: 50,
              },
              right: {
                xs: 0,
                sm: 20,
                md: 15,
                lg: 15,
                xl: 15,
              },
              display: "flex",
              alignItems: "center",
              paddingRight: "30px"
            }}
          >
            <EditIconWidget
              size={20}
              onTap={() => {
                setShowEdit(true);
              }}
              showContainerEdit={false}
              showEditText={false}
            />
          </Box>
        </div>
        <div class="card-body">
          <CustomPreview
            name={"Uyruk"}
            value={
              props &&
              job.job &&
              job.job.jobNationality.name &&
              job.job.jobNationality.name
            }
          />
          <CustomPreview
            name={"Cinsiyet"}
            value={props && job.job && job.job && job.job.gender.description}
          />
          <CustomPreview
            name={"Eğitim Durumu"}
            value={
              props &&
              job.job &&
              job.job.internJobEducation.description &&
              job.job.internJobEducation.description
            }
          />
          <CustomPreview
            name={"Bölüm"}
            value={props && job.job && job.job.departmentName}
          />
          <CustomPreview
            name={"Sınıf"}
            value={
              props &&
              job.job &&
              job.job.internJobClassLevel &&
              job.job.internJobClassLevel
                .map((val) => val.description)
                .join(",")
            }
          />

          <EditInternDemandFeatureModal
            show={showEdit}
            handleClose={handleClose}
            approval={() => {
              setShowEdit(false);
            }}
            job={props && job.job && job.job}
            onSubmit={onSubmit}
            props={props}
            isValid={isValid}
            register={register}
            handleSubmit={handleSubmit}
            control={control}
            setValue={setValue}
            errors={errors}
            watch={watch}
            getValues={getValues}
            reset={reset}
          />
        </div>
      </div>
    </>
  );
};

export default InternDemandFeatureDetail;
