import React, { useMemo, useState } from "react";
import EditWhitePositionModal from "./Modal/EditWhitePositionModal";
import CustomPreview from "../../../../../../../widgets/CustomPreview";
import { Box } from "@mui/material";
import EditIconWidget from "../../../../../../../widgets/EditIconWidget";

const PositionWhiteDetail = ({
  register,
  onSubmit,
  handleSubmit,
  inputStyle,
  cardStyle,
  job,
  props,
  customStyles,
  customStylesMulti,
  positionList,
  isValid,
  control,
  setValue,
  errors,
  watch,
  labelStyle,
  checkProgram,
  checkCertificate,
  checkCompetence,
  setCheckProgram,
  setCheckCertificate,
  setCheckCompetence,
  getValues,
}) => {
  const [showEdit, setShowEdit] = useState(null);
  function handleClose() {
    setShowEdit(false);
  }
  const [militaryIsShow, setMilitaryIsShow] = useState(true);
  const [licenseIsShow, setLicenseIsShow] = useState(true);
  useMemo(() => {
    if (
      props.job.job.gender &&
      (props.job.job.gender.gender === 1 || props.job.job.gender.gender == 0)
    ) {
      setMilitaryIsShow(false);
      setValue("militaryStatus", "");
    } else {
      setMilitaryIsShow(true);
    }
  }, [props.job.job.gender]);
  useMemo(() => {
    if (
      (props.job.job.drivingLicence &&
        props.job.job.drivingLicence.drivingLicence === 0) ||
      props.job.job.drivingLicence.drivingLicence === 2
    ) {
      setValue("drivingLicenceTypes", []);
      setLicenseIsShow(false);
    } else {
      setLicenseIsShow(true);
    }
  }, [props.job.job.drivingLicence]);
  return (
    <div>
      <>
        <div class="card" style={cardStyle}>
          <div
            class="card-header"
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
              borderBottom: "none",
              marginBottom: "0px",
              paddingBottom: "0px",
              marginTop: "0px",
              paddingTop: "10px",
            }}
          >
            <h5 class="card-title">Pozisyon</h5>
            <Box
            sx={{
              position: "absolute",
              top: {
                xs: 20,
                sm: 35,
                md: 35,
                lg: 35,
                xl: 35,
              },
              right: {
                xs: 0,
                sm: 20,
                md: 15,
                lg: 15,
                xl: 15,
              },
              display: "flex",
              alignItems: "center",
              paddingRight: "30px"
            }}
          >
            <EditIconWidget
              size={20}
              onTap={() => {
                setShowEdit(true);
              }}
              showContainerEdit={false}
              showEditText={false}
            />
          </Box>
          </div>
          <div class="card-body">
            <CustomPreview name={"Pozisyon"} value={job.job.positionName} />
            <CustomPreview
              name={"Tecrübe Yılı"}
              value={job.job.experienceRanges.map((val) => val.name).join(", ")}
            />{" "}
            <CustomPreview
              name={"Çalışma Şekli"}
              value={job.job.workTypes.map((val) => val.name).join(", ")}
            />
            <CustomPreview
              name={"Maaş Beklentisi"}
              value={job.job.jobSalaryRanges.map((val) => val.name).join(", ")}
            />{" "}
            <CustomPreview name={"Uyruk"} value={job.job.jobNationality.name} />
            <CustomPreview
              name={"Cinsiyet"}
              value={job.job.gender.description}
            />
            {militaryIsShow && (
              <CustomPreview
                name={"Askerlik Durumu"}
                value={job.job.jobMilitaryStatus.description}
              />
            )}
            <CustomPreview
              name={"Medeni Hal"}
              value={job.job.jobMaritalStatus.description}
            />{" "}
            <CustomPreview
              name={"Hafta Sonu Çalışma"}
              value={job.job.jobWeekendWorks
                .map((val) => val.description)
                .join(",")}
            />
            <CustomPreview
              name={"İş Seyahati Tercihiniz"}
              value={job.job.jobBusinessTravelRestrictions
                .map((val) => val.description)
                .join(",")}
            />
            <CustomPreview
              name={"Yaş Aralığı"}
              value={job.job.jobAgeRange.map((val) => val.name).join(",")}
            />
            <CustomPreview
              name={"Aradığınız Pozisyon İçin Eleman Sayısı"}
              value={job.job.personCount}
            />
            <CustomPreview
              name={"Sigara Kullanımı"}
              value={job.job.jobSmoking.description}
            />
            <CustomPreview
              name={"Sürücü Belgesi"}
              value={job.job.drivingLicence.description}
            />
            {licenseIsShow && (
              <div>
                {job.job.drivingLicence.drivingLicence === 2 ||
                  (job.job.drivingLicence.drivingLicence === 0 ? (
                    <div></div>
                  ) : (
                    <CustomPreview
                      name={"Sürücü Belgesi Türü"}
                      value={job.job.drivingLicence.jobDrivingLicenceTypes
                        .map((val) => val.description)
                        .join(",")}
                    />
                  ))}
              </div>
            )}
            <CustomPreview
              name={"Engellilik Durumu"}
              value={job.job.jobDisability.description}
            />
            {(job.job.jobDisability && job.job.jobDisability.disability == 0) ||
              (job.job.jobDisability &&
              job.job.jobDisability.disability == 1 ? (
                <div></div>
              ) : (
                <CustomPreview
                  name={"Engellilik Seviyesi"}
                  value={
                    job.job.jobDisability &&
                    job.job.jobDisability.jobDegreeOfDisabilities
                      .map((val) => val.description)
                      .join(",")
                  }
                />
              ))}
            <CustomPreview
              name={"Programlar"}
              value={
                job.job.jobPrograms &&
                job.job.jobPrograms.map((val) => val.name).join(",")
              }
            />
            <CustomPreview
              name={"Sertifikalar"}
              value={
                job.job.jobCertificates &&
                job.job.jobCertificates.map((val) => val.name).join(",")
              }
            />
            <CustomPreview
              name={"Yetkinlikler"}
              value={
                job.job.jobCompetence &&
                job.job.jobCompetence.map((val) => val.name).join(",")
              }
            />
          </div>
        </div>
        <EditWhitePositionModal
          show={showEdit}
          handleClose={handleClose}
          approval={() => {
            setShowEdit(false);
          }}
          job={job.job}
          onSubmit={onSubmit}
          props={props}
          customStyles={customStyles}
          inputStyle={inputStyle}
          customStylesMulti={customStylesMulti}
          militaryIsShow={militaryIsShow}
          setMilitaryIsShow={setMilitaryIsShow}
          licenseIsShow={licenseIsShow}
          setLicenseIsShow={setLicenseIsShow}
          isValid={isValid}
          register={register}
          handleSubmit={handleSubmit}
          control={control}
          setValue={setValue}
          errors={errors}
          watch={watch}
          labelStyle={labelStyle}
          checkProgram={checkProgram}
          checkCertificate={checkCertificate}
          checkCompetence={checkCompetence}
          setCheckProgram={setCheckProgram}
          setCheckCertificate={setCheckCertificate}
          setCheckCompetence={setCheckCompetence}
          getValues={getValues}
        />
      </>
    </div>
  );
};

export default PositionWhiteDetail;
