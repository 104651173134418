export const DividerPdf = () => {
  return (
    <div>
      <div>
        <hr
          style={{
            marginTop: "0px",
            marginBottom: "0px",
            padding: "0px",
            paddingTop: "5px",
            border: "none",
            borderTop: "2px solid rgb(236, 227, 188)",
            color: " #fff",
            flex: "6",
          }}
        ></hr>
      </div>
    </div>
  );
};
