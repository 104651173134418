import React, { useEffect } from "react";
import CustomDesign from "../../CustomDesign";
import BitonisSingleDropdown from "../../BitonisSingleDropdown";
import { DropListAction } from "../../../../action";

const SelectCompanyToLocation = ({
  props,
  register,
  watch,
  control,
  setValue,
  errors,
  getValues,
  companyLabel,
  companyLocationLabel,
  companyErrorMessage,
  companyLocationErrorMessage,
  companyPlaceholder,
  companyLocationPlaceholder,
}) => {
  useEffect(() => {
    if (!props.companyList.success) {
      props.dispatch(DropListAction.companyList());
    }
    if (!props.companyLocation.success) {
      props.dispatch(DropListAction.companyLocation());
    }
    props.dispatch(DropListAction.searchCompanyList());
  }, []);

  return (
    <div>
      <CustomDesign
        label={companyLabel}
        required={true}
        isColumn={true}
        child={
          <BitonisSingleDropdown
            items={
              props.searchCompanyList.success &&
              props.searchCompanyList.searchCompanyList.map((value) => {
                return {
                  label: value.name,
                  value: value.id,
                };
              })
            }
            register={register}
            watch={watch}
            companyClassName={"companyId"}
            control={control}
            setValue={setValue}
            errors={errors}
            errorMessage={companyErrorMessage}
            helperText=""
            placeholder={companyPlaceholder}
            onValueChanged={(val) => {
              console.log(val);
              if (val) {
                const id = watch("companyLocationId");
                if (id == undefined) {
                } else {
                  setValue("companyLocationId", "");
                }
                props.dispatch(DropListAction.companyItemLocation(val.value));
              }
            }}
            required={true}
            getValues={getValues}
          />
        }
      />

      {props.companyItemLocation.success && (
        <CustomDesign
          label={companyLocationLabel}
          required={true}
          isColumn={true}
          child={
            <BitonisSingleDropdown
              items={
                props.companyItemLocation.success &&
                props.companyItemLocation.companyItemLocation.map(
                  ({ id, name }) => ({
                    value: id,
                    label: name,
                  })
                )
              }
              register={register}
              watch={watch}
              companyClassName={"companyLocationId"}
              control={control}
              setValue={setValue}
              errors={errors}
              errorMessage={companyLocationErrorMessage}
              helperText=""
              placeholder={companyLocationPlaceholder}
              onValueChanged={(e) => {
                console.log(e);
                if (e) {
                }
              }}
              required={true}
              getValues={getValues}
            />
          }
        />
      )}
    </div>
  );
};

export default SelectCompanyToLocation;
