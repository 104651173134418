import React from "react";

import "../../css/bitoniscss/custompreview.css";

const CustomPreview = ({
  name,
  value,
  matchPointStatus,
  isOnColumn,
  isOnColumnClassName,
  trailing,
}) => {
  const isMatchPointStatusTrue = matchPointStatus === true;

  return (
    <div
      className={
        isOnColumnClassName === undefined ||
        isOnColumnClassName === null ||
        isOnColumnClassName === ""
          ? ""
          : isOnColumnClassName
      }
    >
      <div
        className={
          isOnColumn && isOnColumn === true
            ? "widgetColumnContainer"
            : "widget-container"
        }
      >
        {" "}
        <div
          className={
            isOnColumn && isOnColumn === true
              ? "widgetColumnLabel"
              : "widget-label"
          }
        >
          {name}
        </div>
        <div
          className={
            isOnColumn && isOnColumn === true
              ? "widgetColumnValueContainer"
              : "widget-value-container"
          }
        >
          <div className="widget-value">{value && value}</div>

          {isMatchPointStatusTrue && (
            <div className="matchIContainer">
              <i class="fa-regular fa-circle-check check-icon"></i>
            </div>
          )}
          {trailing && <div className="matchIContainer">{trailing}</div>}
        </div>
      </div>
    </div>
  );
};

export default CustomPreview;
