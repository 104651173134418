import {
  YearOfExperienceContants,
  PositionListContants,
  WayOfWorkingContants,
  NationalityListContants,
  AgeRangeContants,
  DriverLicenseTypeContants,
  ProgramListContants,
  CertificatesListContants,
  CompetencesListContants,
  CountryListContants,
  CityListContants,
  CountyListContants,
  DistrictListContants,
  CompanyListConstants,
  SectorListContants,
  DepartmentsListContants,
  CompanyMListConstants,
  PlanListConstants,
  CompanyLocationConstants,
  CompanyItemLocationConstants,
  CountyJobSeekerListContants,
  DisabilityOfDegreeList,
} from "../constants";

const defaultPositionState = {
  loading: false,
  error: false,
  positionList: false,
  success: false,
};

function setState(changes) {
  return { ...defaultPositionState, ...changes };
}

export function positionList(state = defaultPositionState, action) {
  // Pozisyon Listesi
  switch (action.type) {
    case PositionListContants.GET_ALL_REQUEST:
      return setState({ loading: true });
    case PositionListContants.GET_ALL_SUCCESS:
      return setState({ positionList: action.positionList, success: true });
    case PositionListContants.GET_ALL_FAILURE:
      return setState({ error: action.positionList });
    default:
      return state;
  }
}

const defaultState = {
  loading: false,
  error: false,
  yearOfExperience: false,
  success: false,
};

export function yearOfExperience(state = defaultState, action) {
  // Tecrübe Yılı
  switch (action.type) {
    case YearOfExperienceContants.GET_ALL_REQUEST:
      return setState({ loading: true });
    case YearOfExperienceContants.GET_ALL_SUCCESS:
      return setState({
        yearOfExperience: action.yearOfExperience,
        success: true,
      });
    case YearOfExperienceContants.GET_ALL_FAILURE:
      return setState({ error: action.yearOfExperience });
    default:
      return state;
  }
}
const defaultDisabilityOfDegreeListState = {
  loading: false,
  error: false,
  disabilityOfDegreeList: false,
  success: false,
};

export function disabilityOfDegreeList(
  state = defaultDisabilityOfDegreeListState,
  action
) {
  // engellilik seviyesi
  switch (action.type) {
    case DisabilityOfDegreeList.GET_ALL_REQUEST:
      return setState({ loading: true });
    case DisabilityOfDegreeList.GET_ALL_SUCCESS:
      return setState({
        disabilityOfDegreeList: action.disabilityOfDegreeList,
        success: true,
      });
    case DisabilityOfDegreeList.GET_ALL_FAILURE:
      return setState({ error: action.disabilityOfDegreeList });
    default:
      return state;
  }
}

const defaultStateWayOfWorking = {
  loading: false,
  error: false,
  wayOfWorking: false,
  success: false,
};

export function wayOfWorking(state = defaultStateWayOfWorking, action) {
  // Çalışma Şekli
  switch (action.type) {
    case WayOfWorkingContants.GET_ALL_REQUEST:
      return setState({ loading: true });
    case WayOfWorkingContants.GET_ALL_SUCCESS:
      return setState({ wayOfWorking: action.wayOfWorking, success: true });
    case WayOfWorkingContants.GET_ALL_FAILURE:
      return setState({ error: action.wayOfWorking });
    default:
      return state;
  }
}
const defaultNationalityList = {
  loading: false,
  error: false,
  nationalityList: false,
  success: false,
};

export function nationalityList(state = defaultNationalityList, action) {
  // Uyruk
  switch (action.type) {
    case NationalityListContants.GET_ALL_REQUEST:
      return setState({ loading: true });
    case NationalityListContants.GET_ALL_SUCCESS:
      return setState({
        nationalityList: action.nationalityList,
        success: true,
      });
    case NationalityListContants.GET_ALL_FAILURE:
      return setState({ error: action.nationalityList });
    default:
      return state;
  }
}
const defaultAgeRangeList = {
  loading: false,
  error: false,
  ageRangeList: false,
  success: false,
};

export function ageRangeList(state = defaultAgeRangeList, action) {
  // Yaş Aralığı
  switch (action.type) {
    case AgeRangeContants.GET_ALL_REQUEST:
      return setState({ loading: true });
    case AgeRangeContants.GET_ALL_SUCCESS:
      return setState({ ageRangeList: action.ageRangeList, success: true });
    case AgeRangeContants.GET_ALL_FAILURE:
      return setState({ error: action.ageRangeList });
    default:
      return state;
  }
}
const defaultDriverLicenseType = {
  loading: false,
  error: false,
  driverLicenceType: false,
  success: false,
};

export function driverLicenceType(state = defaultDriverLicenseType, action) {
  // Sürücü Belgesi Türü
  switch (action.type) {
    case DriverLicenseTypeContants.GET_ALL_REQUEST:
      return setState({ loading: true });
    case DriverLicenseTypeContants.GET_ALL_SUCCESS:
      return setState({
        driverLicenceType: action.driverLicenceType,
        success: true,
      });
    case DriverLicenseTypeContants.GET_ALL_FAILURE:
      return setState({ error: action.driverLicenceType });
    default:
      return state;
  }
}
const defaultProgramList = {
  loading: false,
  error: false,
  programList: false,
  success: false,
};

export function programList(state = defaultProgramList, action) {
  // Program Listesi
  switch (action.type) {
    case ProgramListContants.GET_ALL_REQUEST:
      return setState({ loading: true });
    case ProgramListContants.GET_ALL_SUCCESS:
      return setState({ programList: action.programList, success: true });
    case ProgramListContants.GET_ALL_FAILURE:
      return setState({ error: action.programList });
    default:
      return state;
  }
}
const defaultCertificatesList = {
  loading: false,
  error: false,
  certificatesList: false,
  success: false,
};

export function certificatesList(state = defaultCertificatesList, action) {
  // Sertifika
  switch (action.type) {
    case CertificatesListContants.GET_ALL_REQUEST:
      return setState({ loading: true });
    case CertificatesListContants.GET_ALL_SUCCESS:
      return setState({
        certificatesList: action.certificatesList,
        success: true,
      });
    case CertificatesListContants.GET_ALL_FAILURE:
      return setState({ error: action.certificatesList });
    default:
      return state;
  }
}
const defaultCompetencesList = {
  loading: false,
  error: false,
  competencesList: false,
  success: false,
};

export function competencesList(state = defaultCompetencesList, action) {
  // Yeterlilik Listesi
  switch (action.type) {
    case CompetencesListContants.GET_ALL_REQUEST:
      return setState({ loading: true });
    case CompetencesListContants.GET_ALL_SUCCESS:
      return setState({
        competencesList: action.competencesList,
        success: true,
      });
    case CompetencesListContants.GET_ALL_FAILURE:
      return setState({ error: action.competencesList });
    default:
      return state;
  }
}

const defaultCountryList = {
  loading: false,
  error: false,
  countryList: false,
  success: false,
};

export function countryList(state = defaultCountryList, action) {
  // Ülke Listesi
  switch (action.type) {
    case CountryListContants.GET_ALL_REQUEST:
      return setState({ loading: true });
    case CountryListContants.GET_ALL_SUCCESS:
      return setState({
        countryList: action.countryList,
        success: true,
      });
    case CountryListContants.GET_ALL_FAILURE:
      return setState({ error: action.countryList });
    default:
      return state;
  }
}

const defaultCityList = {
  loading: false,
  error: false,
  cityList: false,
  success: false,
};

export function cityList(state = defaultCityList, action) {
  // Ülke Listesi
  switch (action.type) {
    case CityListContants.GET_ALL_REQUEST:
      return setState({ loading: true });
    case CityListContants.GET_ALL_SUCCESS:
      return setState({
        cityList: action.cityList,
        success: true,
      });
    case CityListContants.GET_ALL_FAILURE:
      return setState({ error: action.cityList });
    default:
      return state;
  }
}
const defaultCountyList = {
  loading: false,
  error: false,
  countyList: false,
  success: false,
};

export function countyList(state = defaultCountyList, action) {
  // İlçe Listesi
  switch (action.type) {
    case CountyListContants.GET_ALL_REQUEST:
      return setState({ loading: true });
    case CountyListContants.GET_ALL_SUCCESS:
      return setState({
        countyList: action.countyList,
        success: true,
      });
    case CountyListContants.GET_ALL_FAILURE:
      return setState({ error: action.countyList, success: false });
    default:
      return state;
  }
}

const defaultJobCountyList = {
  loading: false,
  error: false,
  countyJobSeekerList: false,
  success: false,
};

export function countyJobSeekerList(state = defaultJobCountyList, action) {
  //ilçede farketmez burdan gelecek
  // İlçe Listesi
  switch (action.type) {
    case CountyJobSeekerListContants.GET_ALL_REQUEST:
      return setState({ loading: true });
    case CountyJobSeekerListContants.GET_ALL_SUCCESS:
      return setState({
        countyJobSeekerList: action.countyJobSeekerList,
        success: true,
      });
    case CountyJobSeekerListContants.GET_ALL_FAILURE:
      return setState({ error: action.countyJobSeekerList });
    default:
      return state;
  }
}

const defaultDistrictList = {
  loading: false,
  error: false,
  districtList: false,
  success: false,
};

export function districtList(state = defaultDistrictList, action) {
  // İlçe Listesi
  switch (action.type) {
    case DistrictListContants.GET_ALL_REQUEST:
      return setState({ loading: true });
    case DistrictListContants.GET_ALL_SUCCESS:
      return setState({
        districtList: action.districtList,
        success: true,
      });
    case DistrictListContants.GET_ALL_FAILURE:
      return setState({ error: action.districtList });
    default:
      return state;
  }
}

const defaultCompanyList = {
  loading: false,
  error: false,
  companyList: false,
  success: false,
};

export function companyList(state = defaultCompanyList, action) {
  // Şirket Listesi
  switch (action.type) {
    case CompanyListConstants.GET_ALL_REQUEST:
      return setState({ loading: true });
    case CompanyListConstants.GET_ALL_SUCCESS:
      return setState({
        companyList: action.companyList,
        success: true,
      });
    case CompanyListConstants.GET_ALL_FAILURE:
      return setState({ error: action.companyList });
    default:
      return state;
  }
}

const defaultCompanyLocation = {
  loading: false,
  error: false,
  companyLocation: false,
  success: false,
};

export function companyLocation(state = defaultCompanyLocation, action) {
  // Şirket Listesi

  switch (action.type) {
    case CompanyLocationConstants.GET_ALL_REQUEST:
      return setState({ loading: true });
    case CompanyLocationConstants.GET_ALL_SUCCESS:
      return setState({
        companyLocation: action.companyLocation,
        success: true,
      });
    case CompanyLocationConstants.GET_ALL_FAILURE:
      return setState({ error: action.companyLocation });
    default:
      return state;
  }
}

//------------------------------------------------------  -- - - -- -

const defaultCompanyItemLocation = {
  loading: false,
  error: false,
  companyItemLocation: false,
  success: false,
};

export function companyItemLocation(
  state = defaultCompanyItemLocation,
  action
) {
  // Şirket Listesi
  switch (action.type) {
    case CompanyItemLocationConstants.GET_ALL_REQUEST:
      return setState({ loading: true });
    case CompanyItemLocationConstants.GET_ALL_SUCCESS:
      return setState({
        companyItemLocation: action.companyItemLocation,
        success: true,
      });
    case CompanyItemLocationConstants.GET_ALL_FAILURE:
      return setState({ error: action.companyItemLocation });
    default:
      return state;
  }
}

//--------------------------    ---------------------------------------

export function companyListMain(state = defaultCompanyList, action) {
  // Şirket Listesi
  switch (action.type) {
    case CompanyMListConstants.GET_ALL_REQUEST:
      return setState({ loading: true });
    case CompanyMListConstants.GET_ALL_SUCCESS:
      return setState({
        companyList: action.companyList,
        success: true,
      });
    case CompanyMListConstants.GET_ALL_FAILURE:
      return setState({ error: action.companyList });
    default:
      return state;
  }
}

const defaultPlanListt = {
  loading: false,
  error: false,
  planList: false,
  success: false,
};

export function planList(state = defaultPlanListt, action) {
  // Şirket plan listesi

  switch (action.type) {
    case PlanListConstants.GET_ALL_REQUEST:
      return setState({ loading: true });
    case PlanListConstants.GET_ALL_SUCCESS:
      return setState({
        planList: action.planList,
        success: true,
      });
    case PlanListConstants.GET_ALL_FAILURE:
      return setState({ error: action.planList });
    default:
      return state;
  }
}

const defaultSectorList = {
  loading: false,
  error: false,
  sectorList: false,
  success: false,
};

export function sectorList(state = defaultSectorList, action) {
  // Sektör Listesi
  switch (action.type) {
    case SectorListContants.GET_ALL_REQUEST:
      return setState({ loading: true });
    case SectorListContants.GET_ALL_SUCCESS:
      return setState({
        sectorList: action.sectorList,
        success: true,
      });
    case SectorListContants.GET_ALL_FAILURE:
      return setState({ error: action.sectorList });
    default:
      return state;
  }
}
const defaultDepartmentListList = {
  loading: false,
  error: false,
  departmentList: false,
  success: false,
};

export function departmentList(state = defaultDepartmentListList, action) {
  // Departman Listesi
  switch (action.type) {
    case DepartmentsListContants.GET_ALL_REQUEST:
      return setState({ loading: true });
    case DepartmentsListContants.GET_ALL_SUCCESS:
      return setState({
        departmentList: action.departmentList,
        success: true,
      });
    case DepartmentsListContants.GET_ALL_FAILURE:
      return setState({ error: action.departmentList });
    default:
      return state;
  }
}
