import api from "../utility/Utils";

export const FieldService = {
  fields,
  field,
  create,
  del,
  fieldsOwn,
  fieldname,
  loadField,
  getConvexHull
};

function getConvexHull(arg) {
  return api.post("getconvexhull", arg).then((response) => {
      return response;
    })
    .catch((err) => {
      return err;
    });
}

function fields(arg) {
  return api
    .get("ActionsByFilter", { params: arg })
    .then((response) => {
      return Promise.resolve(response);
    })
    .catch((err) => {
      return Promise.reject(err);
    });
}

function fieldsOwn(arg) {
  return api
    .get("post/own", { params: arg })
    .then((response) => {
      return Promise.resolve(response);
    })
    .catch((err) => {
      return Promise.reject(err);
    });
}

function field(arg) {
  return api
    .get("post/" + arg.type + "/" + arg.value)
    .then((response) => {
      return Promise.resolve(response);
    })
    .catch((err) => {
      return Promise.reject(err);
    });
}

function create(arg) {
  return api
    .post("post/add", arg)
    .then((response) => {
      return Promise.resolve(response);
    })
    .catch((err) => {
      return Promise.reject(err);
    });
}

function del(arg) {
  return api.post("deleteaction", arg)
    .then((response) => {
      window.location.reload();
      return Promise.resolve(response);
    })
    .catch((err) => {
      return Promise.reject(err);
    });
}

function fieldname(arg) {
  return api
    .post("setfieldname", arg)
    .then((response) => {
      return Promise.resolve(response);
    })
    .catch((err) => {
      return Promise.reject(err);
    });
}

function loadField(arg) {
  return api.post("loadField", arg)
    .then((response) => {
      return Promise.resolve(response);
    })
    .catch((err) => {
      return Promise.reject(err);
    });
}
