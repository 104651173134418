import { createSlice } from "@reduxjs/toolkit";

const initialState = {

  activeCurrentTab: 0,
};

const filterAllShow = createSlice({
  name: "selectedAllTab",
  initialState,
  reducers: {
    
    setCurrentAllTab: (state, action) => {
      state.activeCurrentTab = action.payload;
    },
  },
});

export const { setCurrentAllTab } = filterAllShow.actions;
export default filterAllShow.reducer;
