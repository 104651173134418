import React from "react";
import { DemandsServices } from "../../../../../../../../../../services";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { useDispatch } from "react-redux";
import {
  setCurrentTab,
  setFilterWhite,
} from "../../../../../../../../../../redux/redux-toolkit/filterMatch";
import { setDemandMatchOfficeInterViewTab } from "../../../../../../../../../../redux/redux-toolkit/demandMatchOfficeInterView";
import { Box } from "@mui/material";
import CustomButton from "../../../../../../../../../widgets/buttons/CustomButton";

const ConfirmIncomingMeetingDateButtonNewMeeting = ({ matchId, tooltip }) => {
  //Gelen Görüşme Gününü Onayla
  const history = useHistory();
  const dispatch = useDispatch();
  function ConfirmIncomingMeetingDate(matchId) {
    DemandsServices.ConfirmIncomingNewMeetingDate(matchId).then(
      (confirmIncomingMeeting) => {
        if (confirmIncomingMeeting) {
          if (confirmIncomingMeeting.succeeded === true) {
            //yönlendirme tamamlandı
            dispatch(setDemandMatchOfficeInterViewTab(1));
            toast.success("Gelen Görüşme Günü Onaylandı", {
              autoClose: 2000,
              onClose: () => {
                setTimeout(() => {
                  history.push("/matchInterviewMeets");
                }, 100);
              },
            });
          } else {
            toast.info(confirmIncomingMeeting.error.message, {
              autoClose: 2000,
              onClose: () => {
                setTimeout(() => {}, 100);
              },
            });
          }
        }
      },
      (error) => {
        toast.info("Beklenmedik Hata Oluştu", {
          autoClose: 2000,
          onClose: () => {
            setTimeout(() => {}, 100);
          },
        });
      }
    );
  }

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Box
        sx={{
          margin: "0px",
        }}
      >
        <CustomButton
          onTap={(val) => {
            ConfirmIncomingMeetingDate(matchId);
          }}
          title={"Gelen Görüşme Gününü Onayla"}
          color={"white"}
          border={"none"}
          backgroundColor={"#2E8A99"}
          hoverBackgroundColor={"teal"}
          width={{
            xs: "380px",
            sm: "380px",
            md: "450px",
            lg: "450px",
            xl: "600px",
          }}
          hoverColor={"teal"}
        />
      </Box>
    </div>
  );
};

export default ConfirmIncomingMeetingDateButtonNewMeeting;
