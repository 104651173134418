import React, { useEffect, useMemo, useState } from "react";
import { connect, useDispatch } from "react-redux";
import {
  DropListAction,
  ProfileDetailAction,
} from "../../../../../../../action";

import { Button, Col, Row } from "react-bootstrap";
import "../../../../../../../css/bitoniscss/companyWidthAddedRoutes.css";
import routesImages from "../../../../../../../images/updateImage.svg";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import AddRoutesModal from "./AddRoutesModal";
import { useHistory } from "react-router-dom";
import RouteItem from "./RouteItem";
import { customStyles, inputStyle } from "../../../../../../customStyleInline";
import RouteItemWidget from "./RouteItemWidget";
import useIsMobile from "../../../../../../hooks/useIsMobile";
import { Box, Link } from "@mui/material";
import LoadingDotsAnimation from "../../../../../../widgets/LoadingDotsAnimation";
import PrevIconButton from "../../../../../../widgets/buttons/PrevIconButton";
import CustomButton from "../../../../../../widgets/buttons/CustomButton";
//Eklenmiş şirketlerin güzergahlarını getiren yapı
const CompanyWidthAddedRoutes = (props) => {
  let history = useHistory();
  const isMobile = useIsMobile();
  const [showAdd, setShowAdd] = useState(false);
  const handleCloseAdd = () => {
    setShowAdd(false);
  };
  useEffect(() => {
    if (!props.companyRoutes?.success) {
      props.dispatch(
        ProfileDetailAction.companyWidthAddedRoutesGetCompanines()
      );
    }
  }, [props.companyRoutes?.success]);
  if (!props.companyRoutes) {
    return <div >
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
        }}
      >
        <LoadingDotsAnimation fontSize={40} />
      </div>
    </div>
  }
  return (
    <div id="stepBody">
      <ToastContainer
        position="top-right"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      {props.companyRoutes.length <= 0 ? (
        <div style={{}}>
          <div
            class=""
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              alignContent: "center",
              justifyContent: "center",
              margin: "10%",
            }}
          >
            <img
              src={routesImages}
              style={{
                height: "90px",
                textAlign: "center",
              }}
            />
            <p
              style={{
                color: "#001542",
                margin: "10px",
                fontSize: "17px",
                fontWeight: "500",
              }}
            >
              Servis güzergahı eklenmiş şirket bulunamadı
            </p>
            <p
              style={{
                color: "grey",
                marginTop: "2px",
                fontSize: "13px",
                textAlign: "center",
              }}
            >
              Şirketinize ait servis güzergahlarını veya eleman aradığınız
              lokasyonları giriş yaparak <br></br> Talep oluştururken otomatik
              olarak seçebilirsiniz.
            </p>

            <Box
              sx={{
                margin: "0px",
                padding: {
                  xs: "5px",
                  sm: "5px",
                  md: "5px",
                  lg: "5px",
                  xl: "5px",
                },
              }}
            >
              <CustomButton onTap={
                () => {
                  setShowAdd(true);
                }
              }
                height={50}
                title={"Şirket Servis Güzergahı Ekle"}
                width={{
                  xs: "200px",
                  sm: "200px",
                  md: "300px",
                  lg: "300px",
                  xl: "350px",
                }}
              />
            </Box>
          </div>
        </div>
      ) : (
        <>
          <div
            style={{
              marginLeft: "25px",
              marginBottom: "0px",
              paddingBottom: "0px",
              display: "flex",
              flexDirection: "row",
            }}
          ></div>

          <form>
            <div
              className="card"
              style={{
                height:
                  props.companyRoutes && props.companyRoutes.length <= 4
                    ? "500px"
                    : "",
                position: "relative",
              }}
            >  <PrevIconButton top={10} left={5} />
              <p
                style={{
                  color: "#001542",
                  fontWeight: "600",
                  padding: "0px",
                  margin: "0px",
                  textAlign: "center",
                  marginTop: "10px",
                }}
              >
                Şirket Servis Güzergahları
              </p>
              {props.companyRoutes && props.companyRoutes.length > 0 && (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    alignContent: "flex-end",
                    alignItems: "flex-end",
                    flexDirection: "row",
                    marginTop: isMobile ? "30px" : "10px",
                    textAlign: "center",
                    position: "absolute",
                    right: "10px",
                    top: "0px",
                    marginRight: "25px",
                    fontSize: isMobile ? "9px" : "13px",
                  }}
                >
                  <div
                    style={{
                      width: isMobile ? "25px" : "30px",
                      height: isMobile ? "25px" : "30px",
                      marginTop: "10px",
                      padding: "0px",
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "center",
                      alignContent: "center",
                      alignItems: "center",
                      cursor: "pointer",
                    }}
                  >
                    <div
                      style={{
                        backgroundColor: "green",
                        borderRadius: "6px",
                        width: isMobile ? "20px" : "30px",
                        height: isMobile ? "20px" : "30px",
                        alignItems: "center",
                        display: "flex",
                        justifyContent: "center",
                        transition: "fontSize 0.2s",
                      }}
                      onMouseOver={(e) => {
                        e.currentTarget.style.width = "35px";
                        e.currentTarget.style.height = "35px";
                      }}
                      onMouseOut={(e) => {
                        e.currentTarget.style.width = "30px";
                        e.currentTarget.style.height = "30px";
                      }}
                      onClick={() => {
                        setShowAdd(true);
                      }}
                    >
                      <i
                        class="fa-solid fa-plus"
                        style={{
                          transition: "fontSize 0.2s",
                          color: "white",
                        }}
                      ></i>
                    </div>
                  </div>
                  <div
                    style={{
                      color: "#001542",
                      fontWeight: "550",
                      marginLeft: "6px",
                      cursor: "pointer",
                      marginTop: "0px",
                      fontSize: isMobile ? "11px" : "12px",
                      marginBottom: "5px",
                    }}
                    onClick={() => {
                      setShowAdd(true);
                    }}
                  >
                    Şirket Servis Güzergahı Ekle
                  </div>

                </div>
              )}
              <Row
                style={{
                  margin: "10px",
                  padding: "5px",
                  marginTop: "7%",
                }}
              >
                {props.companyRoutes &&
                  props.companyRoutes.map((item, index) => (
                    <CompanyAddedRoutesItem
                      item={item}
                      index={index}
                      key={index}
                      length={props.companyRoutes && props.companyRoutes.length}
                    />
                  ))}
              </Row>
            </div>
          </form>
        </>
      )}
      <AddRoutesModal
        customStyles={customStyles}
        inputStyle={inputStyle}
        show={showAdd}
        handleClose={handleCloseAdd}
        props={props}
      />
    </div>
  );
};
const CompanyAddedRoutesItem = ({ item, index, length }) => {
  const isMobile = useIsMobile();
  const history = useHistory();
  return (
    <Col
      xs={12}
      sm={length == 1 ? 12 : 6}
      md={length == 1 ? 12 : 6}
      lg={length == 1 ? 12 : 6}
      xl={length == 1 ? 12 : 6}
      style={{
        marginBottom: "10px",
        cursor: "pointer",
      }}
      onClick={() => {
        var companyId = item.companyId;
        var companyName = item.companyPreferredLocationHeader.companyName;
        const dataMap = new Map();
        dataMap.set("companyInfo", {
          companyId: companyId,
          companyName: companyName,
        });
        history.push({
          pathname: "/companyServiceRoutePage",
          state: { dataMap },
        });
      }}
    >
      <div
        style={{
          display: "flex",
          alignItems: "flex-start",
          backgroundColor: " #f7f7f7",
          borderRadius: "6px",
          justifyContent: "flex-start",
          border: "1px solid #f3f3f6",
          padding: "20px",
          height: isMobile ? "70px" : "100px",
          position: "relative",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
          }}
        >
          <p
            style={{
              color: "#001542",
              fontWeight: "bold",
              fontSize: isMobile ? "11px" : "13px",
            }}
          >
            Firma Adı :
          </p>
          <div style={{}}>
            <p
              style={{
                color: "#001542",
                fontWeight: 500,
                fontSize: isMobile ? "11px" : "13px",
                marginLeft: "5px",
                width: 170,
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
              }}
            >
              {item.companyPreferredLocationHeader.companyName}
            </p>
          </div>
        </div>

        <div
          style={{
            position: "absolute",
            top: "15px",
            right: "7%",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignContent: "center",
                alignItems: "center",
              }}
            >
              <i
                class="fa-solid fa-route"
                style={{
                  fontSize: isMobile ? "13px" : "14px",
                  color: "orange",
                }}
              ></i>
              <p
                style={{
                  margin: "0px",
                  padding: "0px",
                  marginLeft: "5px",
                  color: "#001542",
                  fontSize: isMobile ? "12px" : "14px",
                  fontWeight: 410,
                }}
              >
                ({item.companyPreferredLocationCount})
              </p>
            </div>
            <p
              style={{
                margin: "0px",
                padding: "0px",
                marginLeft: "5px",
                color: "#001542",
                fontSize: isMobile ? "10px" : "11px",
                fontWeight: 410,
                textAlign: "center",
                marginBottom: "5px",
              }}
            ></p>
          </div>
        </div>
        <div
          style={{
            position: "absolute",
            bottom: "0px",
            right: "5px",
            bottom: "5px",
          }}
        >
          <Button
            style={{
              height: isMobile ? "20px" : "25px",
              backgroundColor: "#001542",
              display: "flex",
              justifyContent: "center",
              alignContent: "center",
              alignItems: "center",
              fontSize: isMobile ? "8px" : "11px",
              color: "white",
              borderRadius: "0px",
            }}
          >
            Detayı Gör
          </Button>
        </div>
      </div>
    </Col>
  );
};
function mapState(state) {
  let authentication = state.authentication;
  let companyRoutes = state.companyAddedGetRoutes.companyRoutes;
  let countryList = state.countryList;
  let cityList = state.cityList;
  let countyList = state.countyList;
  let districtList = state.districtList;
  return {
    authentication,
    companyRoutes,
    countryList,
    cityList,
    countyList,
    districtList,
  };
}

export default connect(mapState)(CompanyWidthAddedRoutes);
