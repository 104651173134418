import React from "react";

import "../../css/bitoniscss/custompreview.css";

const CustomPreviewShowPoint = ({ name, value, matchPointStatus }) => {
  const isMatchPointStatusTrue = matchPointStatus === true;

  return (
    <div className="widget-container">
      {" "}
      <div className="widget-label">{name}</div>
      <div className="widget-value-container">
        <div className="widget-value">{value && value.toString()}</div>

        {isMatchPointStatusTrue && (
          <div
            className="matchIContainer"
            style={{
              color: "red",
            }}
          >
            <i
              class="fa-regular fa-circle-check check-icon"
              style={{
                color: "red",
              }}
            ></i>
          </div>
        )}
      </div>
    </div>
  );
};

export default CustomPreviewShowPoint;
