import React, { useEffect } from "react";
import logo from "../../images/btnlogowhite.png";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { useState } from "react";
import { UserAction } from "../../action";
import classes from "../../css/bitoniscss/reset.module.css";
import { ToastContainer, toast } from "react-toastify";
import { useForm, Controller } from "react-hook-form";
import {
  useHistory,
  useLocation,
} from "react-router-dom/cjs/react-router-dom.min";
import { useParams } from "react-router-dom";

import { setToken } from "../../configs/cookie";
import Select from "react-select";
import { customStyles, labelStyle } from "../customStyleInline";
import api from "../../utility/Utils";
import { RadioGroup } from "@material-ui/core";
import "../../css/bitoniscss/Home/cardsettings.css";

const EmployerUnSubscriptionPageOnLoginIn = (props) => {
  const params = new URLSearchParams(window.location.search);
  const value = params.get("value");
  const [selectUnSubscription, setSelectUnSubscription] = useState(null);
  var whyUnSubscriptionList = [
    { name: "Cok fazla E-Posta alıyorum", id: 1 },
    { name: "Gönderilen icerikler ilgimi çekmiyor.", id: 2 },
    { name: "Şirketim tarafından onaylanmadı", id: 3 },
  ];
  const [selectIndex, setSelectIndex] = useState(null);
  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm();
  var history = useHistory();
  function unSubscriptionSend(arg) {
    return api
      .post("/account/employer/unsubscription", arg)
      .then((response) => {
        console.log("response* * ** " + JSON.stringify(response));
        return Promise.resolve(response);
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  }
  function onSubmit() {
    if (
      selectUnSubscription === null ||
      selectUnSubscription === undefined ||
      selectUnSubscription === ""
    ) {
      toast.info("Lütfen Abonelikten Çıkma Nedeninizi Seçin", {
        autoClose: 2000,
        onClose: () => {
          setTimeout(() => {}, 100);
        },
      });
    } else {
      var sendData = {
        guid: value.toString(),
        unsubReason: selectUnSubscription,
      };
      unSubscriptionSend(sendData).then((unSubscriptionResponse) => {
        if (unSubscriptionResponse) {
          if (unSubscriptionResponse.succeeded === true) {
            toast.error(
              "Abonelikten Çıkma İşleminiz Başarılı Şekilde Gerçekleşti",
              {
                autoClose: 2000,
                onClose: () => {
                  setTimeout(() => {
                    history.push("/");
                  }, 100);
                },
              }
            );
          } else {
            toast.info(unSubscriptionResponse.error.message, {
              autoClose: 2000,
              onClose: () => {
                setTimeout(() => {}, 100);
              },
            });
          }
        } else {
          toast.info("Hata Oluştu", {
            autoClose: 2000,
            onClose: () => {
              setTimeout(() => {
                //  window.location.href = window.location.href;
              }, 100);
            },
          });
        }
      });
    }
  }

  return (
    <>
      {" "}
      <ToastContainer
        position="top-right"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <div
        id="settingBody"
        style={{
          marginBottom: "0px",
          paddingBottom: "0px",
        }}
      >
        {" "}
        <div
          style={{
            marginBottom: "0px",
            paddingBottom: "0px",
          }}
        >
          <p
            style={{
              color: "#001542",
              fontWeight: "600",
            }}
          ></p>
        </div>
        <div
          className="card"
          style={{
            marginTop: "0px",
            paddingTop: "0px",
          }}
        >
          <div
            className="card-header"
            style={{
              borderBottom: "none",
              marginLeft: "18px",
              paddingLeft: "18px",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
              }}
            >
              <p></p>
            </div>
          </div>
          <div
            className="card-body"
            style={{
              marginTop: "0px",
              paddingTop: "0px",
            }}
          >
            <div className="form-group">
              <label className="mb-1">
                <strong className="labelClass">
                  Abonelikten Çıkma Sebebi
                  <label
                    style={{
                      color: "red",
                    }}
                  >
                    {" "}
                    *
                  </label>
                </strong>
              </label>

              {whyUnSubscriptionList.map((value, index) => {
                return (
                  <div style={{}}>
                    <div
                      onClick={() => {
                        setSelectUnSubscription(value.id);
                      }}
                      style={
                        selectUnSubscription &&
                        selectUnSubscription === value.id
                          ? {
                              display: "flex",
                              border: "1px solid #F6F5F5",
                              padding: "10px",
                              borderRadius: "8px",
                              height: "50px",
                              margin: "10px",
                              backgroundColor: "#F3F8FF",
                            }
                          : {
                              display: "flex",
                              border: "1px solid #F6F5F5",
                              padding: "10px",
                              borderRadius: "8px",
                              height: "50px",
                              margin: "10px",
                            }
                      }
                    >
                      <RadioButtonWithTitle
                        title={value.name}
                        onChange={() => {
                          setSelectUnSubscription(value.id);
                        }}
                        checked={
                          selectUnSubscription &&
                          selectUnSubscription === value.id
                        }
                      />
                    </div>
                  </div>
                );
              })}
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "end",
                  alignContent: "end",
                  alignItems: "end",
                  marginRight: "15px",
                }}
              ></div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                {" "}
                <button
                  className="btn btn-primary"
                  style={{
                    width: "50%",
                  }}
                  type="button"
                  onClick={(val) => {
                    val.preventDefault();
                    onSubmit();
                  }}
                >
                  Gönder
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

function mapState(state) {
  let authentication = state.authentication;
  return { authentication };
}

export default connect(mapState)(EmployerUnSubscriptionPageOnLoginIn);
function RadioButtonWithTitle({ title, checked, onChange }) {
  return (
    <div className="form-check">
      <input
        className="form-check-input"
        type="radio"
        checked={checked}
        onChange={onChange}
        style={{
          width: "20px",
          height: "20px",
        }}
      />
      <label
        className="form-check-label"
        style={{
          fontSize: "13px",
          color: "#001542",
          fontWeight: 450,
          margin: "5px",
        }}
      >
        {title}
      </label>
    </div>
  );
}
