import React, { useState, useEffect, useRef } from "react";
import { Form, InputGroup, Dropdown, Button } from "react-bootstrap";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import NotHireItem from "../NotHire/NotHireItem";
import PaginationPage from "../../../../../../widgets/PaginationPage";
import BlockNotFound from "./BlockNotFound";
import BlockItem from "./BlockItem";
import { DemandsServices } from "../../../../../../../services";
import LoadingDotsAnimation from "../../../../../../widgets/LoadingDotsAnimation";

const ShowBlueMatchShow = () => {
  const [demandBlockBlueCandidates, setDemandBlockBlueCandidates] =
    useState(null);
  const [search, setSearch] = useState("");

  useEffect(() => {
    getDemandBlueBlockData(1);
  }, []);

  function getDemandBlueBlockData() {
    DemandsServices.demandsBlockBlue().then((response) => {
      if (response.succeeded == true) {
        setDemandBlockBlueCandidates([]);

        if (response.data <= 0) {
        } else {
          response.data.map((value) => {
            setDemandBlockBlueCandidates((prev) => [...prev, value]);
          });
        }
      } else {
      }
    });
  }

  function searchHandleCandidate(value) {
    setSearch(value);
  }

  const [show, setShow] = useState(null);
  function handleClose() {
    setShow(false);
  }
  const history = useHistory();
  const [page, setPage] = useState(1);
  const pageSize = 10;

  const paginatedList =
    demandBlockBlueCandidates && page === null
      ? demandBlockBlueCandidates
      : demandBlockBlueCandidates &&
        demandBlockBlueCandidates.slice((page - 1) * pageSize, page * pageSize);

  if (demandBlockBlueCandidates == null) {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
        }}
      >
        <LoadingDotsAnimation />
      </div>
    );
  }
  return (
    <>
      <div>
        <div className="">
          <InputGroup className="mb-3" style={{}}>
            <Form.Control
              style={{
                borderRadius: "25px",
                backgroundColor: "#F5F5F5",
                marginLeft: "10px",
              }}
              type="search"
              placeholder="İsme göre ara"
              value={search}
              onChange={(e) => searchHandleCandidate(e.target.value)}
            />
          </InputGroup>
        </div>

        {demandBlockBlueCandidates === null ? (
          <div >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
            }}
          >
            <LoadingDotsAnimation fontSize={40} />
          </div>
        </div>
        ) : demandBlockBlueCandidates &&
          paginatedList &&
          paginatedList.length > 0 ? (
          paginatedList && (
            <div
              style={{
                marginBottom: "10%",
                marginTop: "3%",
              }}
            >
              {paginatedList.map((blockData, index) => {
                return paginatedList[index].candidateNameSurname
                  .toLowerCase()
                  .includes(search.toLowerCase()) ? (
                  <div
                    onClick={() => {
                      const dataMap = new Map();
                      dataMap.set("candidateId", blockData.candidateId);
                      dataMap.set("matchId", blockData.matchId);
                      dataMap.set("status", blockData.status);
                      history.push({
                        pathname: "matchShow/blueMatchDetail",
                        state: { dataMap },
                      });
                    }}
                  >
                    <BlockItem
                      key={blockData.candidateId}
                      candidateNameSurname={blockData.candidateNameSurname}
                      positionName={blockData.positionName}
                      status={blockData.status}
                      candidateId={blockData.candidateId}
                      matchCreateDate={blockData.matchCreateDate}
                    />
                  </div>
                ) : (
                  <div></div>
                );
              })}
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                {demandBlockBlueCandidates &&
                  demandBlockBlueCandidates.length > 0 &&
                  demandBlockBlueCandidates.length > pageSize && (
                    <PaginationPage
                      items={demandBlockBlueCandidates}
                      page={page}
                      pageSize={pageSize}
                      onChange={(value) => {
                        if (value) {
                          setPage(value);
                        }
                      }}
                      onTapAllShowButton={() => {
                        setPage(null);
                      }}
                    />
                  )}
              </div>
            </div>
          )
        ) : (
          <BlockNotFound title={""} />
        )}
      </div>
    </>
  );
};

export default ShowBlueMatchShow;
