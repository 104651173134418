
import { JobConstants, JobInternConstant } from "../constants";

const defaultState = {
  loading: false,
  error: false,
  job: false,
  success: false,
};

function setState(changes) {
  return { ...defaultState, ...changes };
}

export function internJob(state = defaultState, action) {
  switch (action.type) {
    case JobConstants.GET_ALL_REQUEST:
      return setState({ loading: true });
    case JobConstants.GET_ALL_SUCCESS:
      return setState({ job: action.jobDetail, success: true });
    case JobConstants.GET_ALL_FAILURE:
      return setState({ error: action.job });
    case JobConstants.GET_CLEAR:
      return defaultState;
    default:
      return state;
  }
}
