import React, { useEffect, useMemo, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import Modal from "react-bootstrap/Modal"; // Varsayılan olarak React Bootstrap Modal bileşeni kullanılıyor
import Select from "react-select";
import { Alert, Button, Form } from "react-bootstrap";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import "../../css/bitoniscss/modalcss.css";

import { useDispatch, useSelector } from "react-redux";
import {
  customStyles,
  customStylesMulti,
  customStylesMultiNotPadding,
  labelStyle,
} from "../customStyleInline";
import { AccountServices } from "../../services/AccountServices";
import BitonisSingleDropdown from "../widgets/BitonisSingleDropdown";
import CustomDesign from "../widgets/CustomDesign";

function EmployerToBitonisContact({
  show,
  handleClose,
  contactUsUserUserName,
}) {
  const {
    register,
    isValid,
    handleSubmit,
    control,
    watch,
    reset,
    setValue,
    getValues,
    formState: { errors },
  } = useForm();
  const dispatch = useDispatch();
  const [whyList, setWhyList] = useState([]);

  useEffect(() => {
    if (show) {
      setValue(
        "emailCtx1",
        contactUsUserUserName === undefined ||
          contactUsUserUserName === null ||
          contactUsUserUserName === ""
          ? ""
          : contactUsUserUserName
      );
      setValue("whyContact", "");
      setValue("explanation", "");
      setWhyList([]);
      AccountServices.userReasonToContactUs(2).then(
        (userReasonToContactUsResponse) => {
          if (
            userReasonToContactUsResponse &&
            userReasonToContactUsResponse.succeeded === true
          ) {
            userReasonToContactUsResponse.data.map((val) => {
              setWhyList((prev) => [...prev, val]);
            });
          } else {
          }
        },
        (error) => {}
      );
    }
  }, [show]);

  function onSubmit(form) {
    handleClose();
    var sendBody = {
      email: form.emailCtx1,
      reasonId: form.whyContact.value,
      userName: contactUsUserUserName,
      companyName:
        form.companyName === undefined || form.companyName === null
          ? ""
          : form.companyName,
      description:
        form.whyContact.value == 5 ? form.explanation : form.whyContact.label,
      accountType: 2,
    };
    AccountServices.notifyContactUs(sendBody).then(
      (userReasonToContactUsResponse) => {
        if (
          userReasonToContactUsResponse &&
          userReasonToContactUsResponse.succeeded === true
        ) {
          toast.success(userReasonToContactUsResponse.result.message, {
            autoClose: 2000,
            onClose: () => {
              setTimeout(() => {}, 100);
            },
          });
        } else {
          toast.success("İletişim bilgisi gönderilirken bir hata oluştu.", {
            autoClose: 2000,
            onClose: () => {
              setTimeout(() => {}, 100);
            },
          });
        }
      },
      (error) => {
        toast.success("İletişim bilgisi gönderilirken bir hata oluştu.", {
          autoClose: 2000,
          onClose: () => {
            setTimeout(() => {}, 100);
          },
        });
      }
    );
  }

  return (
    <>
      <div>
        <Modal
          show={show}
          onHide={handleClose}
          style={{
            backgroundColor: "rgba(0, 0, 0, 0.2)",
          }}
          backdrop="static"
          keyboard={false}
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          {" "}
          <Modal.Header closeButton style={{ borderBottom: "0px" }}>
            <Modal.Title
              aria-labelledby="contained-modal-title-vcenter"
              style={{
                width: "100%",
                textAlign: "center",
                margin: "0 auto",
              }}
            >
              İletişime Geç
            </Modal.Title>
          </Modal.Header>
          <div className="">
            <Modal.Body
              style={{
                marginTop: "0px",
                paddingTop: "0px",
              }}
            >
              <div
                style={{
                  backgroundColor: "#F7F7F7",
                  borderRadius: "8px",
                }}
              >
                <p
                  style={{
                    fontSize: "13px",
                    color: "#001542",
                    fontWeight: 400,
                    margin: "20px 10px 20px 10px",
                    paddingTop: "5px",
                  }}
                >
                  {" "}
                  <strong
                    style={{
                      fontWeight: "bold",
                      marginRight: "5px",
                      color: "#001542",
                    }}
                  >
                    Değerli Müşterimiz,{" "}
                  </strong>{" "}
                  Üyelik işlemlerinizde hata mı tespit ettiniz? Endişelenmeyin,
                  doğru bilgilerle size yardımcı olmak için buradayız. Lütfen
                  Bitoniş ekibiyle iletişime geçin
                  <p
                    style={{
                      fontSize: "13px",
                      color: "#001542",
                      fontWeight: 550,
                      paddingBottom: "5px",
                    }}
                  >
                    {" "}
                    0850 733 34 35
                  </p>
                </p>
              </div>

              <CustomDesign
                label={"E-Posta Adresi"}
                required={true}
                isColumn={true}
                columnClassName={"col-xs-12 col-md-12"}
                child={
                  <div>
                    <input
                      type="email"
                      className="form-control"
                      name="emailCtx1"
                      key={"emailCtx1"}
                      style={{
                        border: "1px solid rgb(203, 200, 200)",
                        padding: "10px",
                        marginLeft: "0px",
                        backgroundColor: "#f8f8f9",
                        borderRadius: "6px",
                        width: "100%",
                        color: "#001542",
                        fontWeight: "500",
                        fontSize: "15px",
                      }}
                      {...register("emailCtx1", { required: true })}
                      placeholder="E-Posta Adresiniz"
                    />
                    {errors.emailCtx1 && (
                      <div className="text-danger fs-12">
                        Email Boş Bırakılamaz
                      </div>
                    )}
                  </div>
                }
              />
              <CustomDesign
                label={"Firma Adı"}
                required={false}
                isColumn={true}
                columnClassName={"col-xs-12 col-md-12"}
                child={
                  <div>
                    <input
                      type="text"
                      className="form-control"
                      name="companyName"
                      key={"companyName"}
                      style={{
                        border: "1px solid rgb(203, 200, 200)",
                        padding: "10px",
                        marginLeft: "0px",
                        backgroundColor: "#f8f8f9",
                        borderRadius: "6px",
                        width: "100%",
                        color: "#001542",
                        fontWeight: "500",
                        fontSize: "15px",
                      }}
                      {...register("companyName", { required: false })}
                      placeholder="Firma adı girin."
                    />
                    {errors.companyName && (
                      <div className="text-danger fs-12">
                        Firma Adı Boş Bırakılamaz
                      </div>
                    )}
                  </div>
                }
              />

              <CustomDesign
                label={"Sebep"}
                required={true}
                isColumn={true}
                columnClassName={"col-xs-12 col-md-12"}
                child={
                  <div>
                    <BitonisSingleDropdown
             
                      items={
                        whyList &&
                        whyList.map(({ id, name }) => ({
                          value: id,
                          label: name,
                        }))
                      }
                      register={register}
                      watch={watch}
                      companyClassName={"whyContact"}
                      control={control}
                      setValue={setValue}
                      errors={errors}
                      placeholder="Sebep Seçiniz"
                      onValueChanged={(e) => {
                        console.log(e);
                        if (e) {
                        }
                      }}
                      required={true}
                      getValues={getValues}
                      errorMessage={"Sebep Boş Geçilemez"}
                    />
                  </div>
                }
              />

              {watch("whyContact") && watch("whyContact").value === 5 ? (
                <div className="form-group col-md-12">
                  <label className="mb-1">
                    <strong className="labelClass">
                      Açıklama
                      <label
                        style={{
                          color: "red",
                        }}
                      >*
                      </label>
                    </strong>
                  </label>
                  <textarea
                    className="form-control textAreaId"
                    name="explanation"
                    key={"explanation"}
                    {...register("explanation", { required: true })}
                    id={"inputClass"}
                    rows="4"
                    style={{
                      fontSize: "13px",
                      color: "#001542",
                      opacity: "1px",
                      fontWeight: "400",
                    }}
                    placeholder="Açıklama"
                  />
                  {errors.explanation && (
                    <div className="text-danger fs-12">
                      Açıklama Boş Bırakılamaz
                    </div>
                  )}
                </div>
              ) : (
                <div></div>
              )}
            <form onSubmit={handleSubmit(onSubmit)}></form>
            </Modal.Body>
            <Modal.Footer
              style={{
                display: "flex",
                flexDirection: "Row",
                alignItems: "center",
                justifyContent: "center",
                alignContent: "center",
                borderTop: "none",
                margin: "0px",
              }}
            >
              <form onSubmit={handleSubmit(onSubmit)}>
                <Button
                  as="input"
                  type="submit"
                  value="Talebimi Gönder"
                  style={{
                    borderRadius: "8px",
                    fontSize: "15px",
                    padding: "15px",
                    width: "300px",
                    marginTop: "0px",
                    cursor: "pointer",
                    transition: "background-color 0.3s",
                  }}
                />
              </form>
            </Modal.Footer>
          </div>
        </Modal>
      </div>
    </>
  );
}

export default EmployerToBitonisContact;
