export const CompanyListWhiteConstants = {
    GET_REQUEST: 'COMPANYWHITEWHITE_GET_REQUEST',
    GET_SUCCESS: 'COMPANYWHITE_GET_SUCCESS',
    GET_CLEAR: 'COMPANYWHITE_GET_CLEAR',
    GET_FAILURE: 'COMPANYWHITE_GET_FAILURE',
    GET_ALL_REQUEST: 'COMPANYWHITE_GET_ALL_REQUEST',
    GET_ALL_SUCCESS: 'COMPANYWHITE_GET_ALL_SUCCESS',
    GET_ALL_FAILURE: 'COMPANYWHITE_GET_ALL_FAILURE',
};
export const DropListWhiteConstants = {
    GET_REQUEST: 'DROPLISTWHITEWHITE_GET_REQUEST',
    GET_SUCCESS: 'DROPLISTWHITE_GET_SUCCESS',
    GET_CLEAR: 'DROPLISTWHITE_GET_CLEAR',
    GET_FAILURE: 'DROPLISTWHITE_GET_FAILURE',
    GET_ALL_REQUEST: 'DROPLISTWHITE_GET_ALL_REQUEST',
    GET_ALL_SUCCESS: 'DROPLISTWHITE_GET_ALL_SUCCESS',
    GET_ALL_FAILURE: 'DROPLISTWHITE_GET_ALL_FAILURE',
};

export const PositionListWhiteContants = {
    GET_REQUEST: 'POSITIONLISTWHITE_GET_REQUEST',
    GET_SUCCESS: 'POSITIONLISTWHITE_GET_SUCCESS',
    GET_CLEAR: 'POSITIONLISTWHITE_GET_CLEAR',
    GET_FAILURE: 'POSITIONLISTWHITE_GET_FAILURE',
    GET_ALL_REQUEST: 'POSITIONLISTWHITE_GET_ALL_REQUEST',
    GET_ALL_SUCCESS: 'POSITIONLISTWHITE_GET_ALL_SUCCESS',
    GET_ALL_FAILURE: 'POSITIONLISTWHITE_GET_ALL_FAILURE',
};

export const YearOfExperienceWhiteContants = {
    GET_REQUEST: 'YEAROFEXPERIENCEWHITE_GET_REQUEST',
    GET_SUCCESS: 'YEAROFEXPERIENCEWHITE_GET_SUCCESS',
    GET_CLEAR: 'YEAROFEXPERIENCEWHITE_GET_CLEAR',
    GET_FAILURE: 'YEAROFEXPERIENCEWHITE_GET_FAILURE',
    GET_ALL_REQUEST: 'YEAROFEXPERIENCEWHITET_GET_ALL_REQUEST',
    GET_ALL_SUCCESS: 'YEAROFEXPERIENCEWHITE_GET_ALL_SUCCESS',
    GET_ALL_FAILURE: 'YEAROFEXPERIENCEWHITE_GET_ALL_FAILURE',
}
export const WayOfWorkingWhiteContants = {
    GET_REQUEST: 'WAYOFWORKINGWHITE_GET_REQUEST',
    GET_SUCCESS: 'WAYOFWORKINGWHITE_GET_SUCCESS',
    GET_CLEAR: 'WAYOFWORKINGWHITE_GET_CLEAR',
    GET_FAILURE: 'WAYOFWORKINGWHITE_GET_FAILURE',
    GET_ALL_REQUEST: 'WAYOFWORKINGWHITE_GET_ALL_REQUEST',
    GET_ALL_SUCCESS: 'WAYOFWORKINGWHITE_GET_ALL_SUCCESS',
    GET_ALL_FAILURE: 'WAYOFWORKINGWHITE_GET_ALL_FAILURE',
}
export const NationalityListWhiteContants = {
    GET_REQUEST: 'NATIONALITYLISTWHITE_GET_REQUEST',
    GET_SUCCESS: 'NATIONALITYLISTWHITE_GET_SUCCESS',
    GET_CLEAR: 'NATIONALITYLISTWHITE_CLEAR',
    GET_FAILURE: 'NATIONALITYLISTWHITE_GET_FAILURE',
    GET_ALL_REQUEST: 'NATIONALITYLISTWHITE_GET_ALL_REQUEST',
    GET_ALL_SUCCESS: 'NATIONALITYLISTWHITE_GET_ALL_SUCCESS',
    GET_ALL_FAILURE: 'NATIONALITYLISTWHITE_GET_ALL_FAILURE',
}
export const AgeRangeWhiteContants = {
    GET_REQUEST: 'AGERANGEWHITE_GET_REQUEST',
    GET_SUCCESS: 'AGERANGEHITE_GET_SUCCESS',
    GET_CLEAR: 'AGERANGEWHITE_CLEAR',
    GET_FAILURE: 'AGERANGEWHITE_GET_FAILURE',
    GET_ALL_REQUEST: 'AGERANGEWHITE_GET_ALL_REQUEST',
    GET_ALL_SUCCESS: 'AGERANGEWHITE_GET_ALL_SUCCESS',
    GET_ALL_FAILURE: 'AGERANGEWHITE_GET_ALL_FAILURE',
}
export const DriverLicenseTypeWhiteContants = {
    GET_REQUEST: 'DRIVERLICENSETYPEWHITE_GET_REQUEST',
    GET_SUCCESS: 'DRIVERLICENSETYPEWHITE_GET_SUCCESS',
    GET_CLEAR: 'DRIVERLICENSETYPEWHITE_CLEAR',
    GET_FAILURE: 'DRIVERLICENSETYPEWHITE_GET_FAILURE',
    GET_ALL_REQUEST: 'DRIVERLICENSETYPEWHITE_GET_ALL_REQUEST',
    GET_ALL_SUCCESS: 'DRIVERLICENSETYPEWHITE_GET_ALL_SUCCESS',
    GET_ALL_FAILURE: 'DRIVERLICENSETYPEWHITE_GET_ALL_FAILURE',
}
export const ProgramListWhiteContants = {
    GET_REQUEST: 'PROGRAMLISTWHITE_GET_REQUEST',
    GET_SUCCESS: 'PROGRAMLISTWHITE_GET_SUCCESS',
    GET_CLEAR: 'PROGRAMLISTWHITE_CLEAR',
    GET_FAILURE: 'PROGRAMLISTWHITE_GET_FAILURE',
    GET_ALL_REQUEST: 'PROGRAMLISTWHITE_GET_ALL_REQUEST',
    GET_ALL_SUCCESS: 'PROGRAMLISTWHITEPE_GET_ALL_SUCCESS',
    GET_ALL_FAILURE: 'PROGRAMLISTWHITE_GET_ALL_FAILURE',
}
export const CertificatesListWhiteContants = {
    GET_REQUEST: 'CERTIFICATESLISTWHITE_GET_REQUEST',
    GET_SUCCESS: 'CERTIFICATESLISTWHITE_GET_SUCCESS',
    GET_CLEAR: 'CERTIFICATESLISTWHITE_CLEAR',
    GET_FAILURE: 'CERTIFICATESLISTWHITE_GET_FAILURE',
    GET_ALL_REQUEST: 'CERTIFICATESLISTWHITE_GET_ALL_REQUEST',
    GET_ALL_SUCCESS: 'CERTIFICATESLISTWHITE_GET_ALL_SUCCESS',
    GET_ALL_FAILURE: 'CERTIFICATESLISTWHITE_GET_ALL_FAILURE',
}
export const CompetencesListWhiteContants = {
    GET_REQUEST: 'COMPETENCELISTWHITE_GET_REQUEST',
    GET_SUCCESS: 'COMPETENCELISTWHITE_GET_SUCCESS',
    GET_CLEAR: 'COMPETENCELISTWHITET_CLEAR',
    GET_FAILURE: 'COMPETENCELISTWHITE_GET_FAILURE',
    GET_ALL_REQUEST: 'COMPETENCELISTWHITE_GET_ALL_REQUEST',
    GET_ALL_SUCCESS: 'COMPETENCELISTWHITE_GET_ALL_SUCCESS',
    GET_ALL_FAILURE: 'COMPETENCELISTWHITE_GET_ALL_FAILURE',
}
export const CountryListWhiteContants = {
    GET_REQUEST: 'COUNTRYWHITELISTWHITE_GET_REQUEST',
    GET_SUCCESS: 'COUNTRYWHITELISTWHITE_GET_SUCCESS',
    GET_CLEAR: 'COUNTRYWHITELISTWHITE_CLEAR',
    GET_FAILURE: 'COUNTRYWHITELISTWHITE_GET_FAILURE',
    GET_ALL_REQUEST: 'COUNTRYWHITELISTWHITE_GET_ALL_REQUEST',
    GET_ALL_SUCCESS: 'COUNTRYWHITELISTWHITE_GET_ALL_SUCCESS',
    GET_ALL_FAILURE: 'COUNTRYWHITELISTWHITE_GET_ALL_FAILURE',
}
export const CityListWhiteContants = {
    GET_REQUEST: 'CITYLISTWHITE_GET_REQUEST',
    GET_SUCCESS: 'CITYLISTWHITET_GET_SUCCESS',
    GET_CLEAR: 'CITYLISTWHITE_CLEAR',
    GET_FAILURE: 'CITYLISTWHITE_GET_FAILURE',
    GET_ALL_REQUEST: 'CITYLISTWHITE_GET_ALL_REQUEST',
    GET_ALL_SUCCESS: 'CITYLISTWHITET_GET_ALL_SUCCESS',
    GET_ALL_FAILURE: 'CITYLISTWHITE_GET_ALL_FAILURE',
}
export const CountyListWhiteContants = {
    GET_REQUEST: 'COUNTRYWHITE_GET_REQUEST',
    GET_SUCCESS: 'COUNTRYWHITE_GET_SUCCESS',
    GET_CLEAR: 'COUNTRYWHITE_CLEAR',
    GET_FAILURE: 'COUNTRYWHITE_GET_FAILURE',
    GET_ALL_REQUEST: 'COUNTRYWHITE_GET_ALL_REQUEST',
    GET_ALL_SUCCESS: 'COUNTRYWHITE_GET_ALL_SUCCESS',
    GET_ALL_FAILURE: 'CCOUNTRYWHITE_GET_ALL_FAILURE',
}
export const DistrictListWhiteContants = {
    GET_REQUEST: 'DISTRICTLISTWHITE_GET_REQUEST',
    GET_SUCCESS: 'DISTRICTLISTWHITE_GET_SUCCESS',
    GET_CLEAR: 'DISTRICTLISTWHITE_CLEAR',
    GET_FAILURE: 'DISTRICTLISTWHITE_GET_FAILURE',
    GET_ALL_REQUEST: 'DISTRICTLISTWHITE_GET_ALL_REQUEST',
    GET_ALL_SUCCESS: 'DISTRICTLISTWHITE_GET_ALL_SUCCESS',
    GET_ALL_FAILURE: 'DISTRICTLISTWHITE_GET_ALL_FAILURE',
}
export const SalaryListWhiteContants = {
    GET_REQUEST: 'SALARYLISTWHITE_GET_REQUEST',
    GET_SUCCESS: 'SALARYLISTWHITE_GET_SUCCESS',
    GET_CLEAR: 'SALARYLISTWHITE_CLEAR',
    GET_FAILURE: 'SALARYLISTWHITE_GET_FAILURE',
    GET_ALL_REQUEST: 'SALARYLISTWHITE_GET_ALL_REQUEST',
    GET_ALL_SUCCESS: 'SALARYLISTWHITE_GET_ALL_SUCCESS',
    GET_ALL_FAILURE: 'SALARYLISTWHITE_GET_ALL_FAILURE',
}
export const BusinessTravelListWhiteContants = {
    GET_REQUEST: 'BUSINESSTRAVELLISTWHITE_GET_REQUEST',
    GET_SUCCESS: 'BUSINESSTRAVELLISTWHITE_GET_SUCCESS',
    GET_CLEAR: 'BUSINESSTRAVELLISTWHITE_CLEAR',
    GET_FAILURE: 'BUSINESSTRAVELLISTWHITE_GET_FAILURE',
    GET_ALL_REQUEST: 'BUSINESSTRAVELLISTWHITE_GET_ALL_REQUEST',
    GET_ALL_SUCCESS: 'BUSINESSTRAVELLISTWHITE_GET_ALL_SUCCESS',
    GET_ALL_FAILURE: 'BUSINESSTRAVELLISTWHITE_GET_ALL_FAILURE',
}
export const LanguageListWhiteContants = {
    GET_REQUEST: 'LANGUAGELISTWHITE_GET_REQUEST',
    GET_SUCCESS: 'LANGUAGELISTWHITE_GET_SUCCESS',
    GET_CLEAR: 'LANGUAGELISTWHITE_CLEAR',
    GET_FAILURE: 'LANGUAGELISTWHITE_GET_FAILURE',
    GET_ALL_REQUEST: 'LANGUAGELISTWHITE_GET_ALL_REQUEST',
    GET_ALL_SUCCESS: 'LANGUAGELISTWHITE_GET_ALL_SUCCESS',
    GET_ALL_FAILURE: 'LANGUAGELISTWHITE_GET_ALL_FAILURE',
}
export const LanguageLevelListWhiteContants = {
    GET_REQUEST: 'LANGUAGELEVELLISTWHITE_GET_REQUEST',
    GET_SUCCESS: 'LANGUAGELEVELLISTWHITE_GET_SUCCESS',
    GET_CLEAR: 'LANGUAGELEVELLISTWHITE_CLEAR',
    GET_FAILURE: 'LANGUAGELEVELLISTWHITE_GET_FAILURE',
    GET_ALL_REQUEST: 'LANGUAGELEVELLISTWHITE_GET_ALL_REQUEST',
    GET_ALL_SUCCESS: 'LANGUAGELEVELLISTWHITE_GET_ALL_SUCCESS',
    GET_ALL_FAILURE: 'LANGUAGELEVELLISTWHITE_GET_ALL_FAILURE',
}
export const UniversityListWhiteContants = {
    GET_REQUEST: 'UNIVERSITYLISTWHITE_GET_REQUEST',
    GET_SUCCESS: 'UNIVERSITYLISTWHITE_GET_SUCCESS',
    GET_CLEAR: 'UNIVERSITYLISTWHITE_CLEAR',
    GET_FAILURE: 'UNIVERSITYLISTWHITE_GET_FAILURE',
    GET_ALL_REQUEST: 'UNIVERSITYLISTWHITE_GET_ALL_REQUEST',
    GET_ALL_SUCCESS: 'UNIVERSITYLISTWHITE_GET_ALL_SUCCESS',
    GET_ALL_FAILURE: 'UNIVERSITYLISTWHITE_GET_ALL_FAILURE',
}
export const FacultyListWhiteContants = {
    GET_REQUEST: 'FACULTYLISTWHITE_GET_REQUEST',
    GET_SUCCESS: 'FACULTYLISTWHITE_GET_SUCCESS',
    GET_CLEAR: 'FACULTYLISTWHITE_CLEAR',
    GET_FAILURE: 'FACULTYLISTWHITE_GET_FAILURE',
    GET_ALL_REQUEST: 'FACULTYLISTWHITE_GET_ALL_REQUEST',
    GET_ALL_SUCCESS: 'FACULTYLISTWHITE_GET_ALL_SUCCESS',
    GET_ALL_FAILURE: 'FACULTYLISTWHITE_GET_ALL_FAILURE',
}
export const FacultyDepartmantListWhiteContants = {
    GET_REQUEST: 'FACULTYDEPARTMANTLISTWHITE_GET_REQUEST',
    GET_SUCCESS: 'FACULTYDEPARTMANTLISTWHITE_GET_SUCCESS',
    GET_CLEAR: 'FACULTYDEPARTMANTLISTWHITE_CLEAR',
    GET_FAILURE: 'FACULTYDEPARTMANTLISTWHITE_GET_FAILURE',
    GET_ALL_REQUEST: 'FACULTYDEPARTMANTLISTWHITEE_GET_ALL_REQUEST',
    GET_ALL_SUCCESS: 'FACULTYDEPARTMANTLISTWHITEE_GET_ALL_SUCCESS',
    GET_ALL_FAILURE: 'FACULTYLISTWHITE_GET_ALL_FAILURE',
}