import React, { useState, useEffect, useRef } from "react";
import { Form, InputGroup, Dropdown, Button } from "react-bootstrap";
import { DemandsServices } from "../../../../../../../../services";
import noMatchFound from "../../../../../../../../images/svg/noMatchFound.svg";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import NotHireItem from "../NotHireItem";
import MatchNotFound from "../../MatchShow/BlueMatchShow/MatchNotFound";
import PaginationPage from "../../../../../../../widgets/PaginationPage";
import LoadingDotsAnimation from "../../../../../../../widgets/LoadingDotsAnimation";

const ShowNotHireWhite = () => {
  const { activeCurrentTab } = useSelector((state) => state.selectedHireTab);
  useEffect(() => {
    getNotHireWhite([{ status: 7 }]);
  }, []);
  const [search, setSearch] = useState("");
  const history = useHistory();

  const [demandNotHireWhite, setDemandNotHireWhite] = useState(null);
  function getNotHireWhite(statusNotHireWhite) {
   
    DemandsServices.demandMatchWhite(statusNotHireWhite).then(
      (demandNotHire) => {
        if (demandNotHire && demandNotHire.succeeded === true) {
           setDemandNotHireWhite([]);
          setDemandNotHireWhite(demandNotHire.data.pagedData);
        }
      },
      (error) => {}
    );
    //-------------------------
  }
  function searchHandleCandidate(value) {
    setSearch(value);
  }
  const [page, setPage] = useState(1);
  const pageSize = 5; // Her sayfada gösterilecek eleman sayısı

  const paginatedList =
    demandNotHireWhite && page === null
      ? demandNotHireWhite
      : demandNotHireWhite &&
        demandNotHireWhite.slice((page - 1) * pageSize, page * pageSize);

  return (
    <div>
      <div>
        <div className="">
          <InputGroup className="mb-3" style={{}}>
            <Form.Control
              style={{
                borderRadius: "20px",
                backgroundColor: "#F5F5F5",
                marginLeft: "10px",
              }}
              type="search"
              placeholder="İsme göre ara"
              value={search}
              onChange={(e) => searchHandleCandidate(e.target.value)}
            />
          </InputGroup>
        </div>

        {demandNotHireWhite === null ? (
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
            }}
          >
            <LoadingDotsAnimation/>
          </div>
        ) : demandNotHireWhite && paginatedList && paginatedList.length > 0 ? (
          demandNotHireWhite && (
            <div
              style={{
                marginBottom: "10%",
                marginTop: "3%",
              }}
            >
              {paginatedList &&
                paginatedList.map((matchData, index) => {
                  return paginatedList &&
                    paginatedList[index].candidateNameSurname
                      .toLowerCase()
                      .includes(search.toLowerCase()) ? (
                    <div
                      onClick={() => {
                        const dataMap = new Map();
                        dataMap.set("candidateId", matchData.candidateId);
                        dataMap.set("matchId", matchData.matchId);
                        dataMap.set("status", 7);
                        dataMap.set("cameWithoutMatchingPage", false);
                        history.push({
                          pathname: "/matchShow/whiteMatchDetail",
                          state: { dataMap },
                        });
                      }}
                    >
                      <NotHireItem
                        candidateNameSurname={matchData.candidateNameSurname}
                        positionName={matchData.positionName}
                        status={matchData.status}
                        candidateId={matchData.candidateId}
                      />
                    </div>
                  ) : (
                    <div></div>
                  );
                })}{" "}
              {demandNotHireWhite &&
                demandNotHireWhite.length > 0 &&
                demandNotHireWhite.length > pageSize && (
                  <PaginationPage
                    items={demandNotHireWhite}
                    page={page}
                    pageSize={pageSize}
                    onChange={(value) => {
                      if (value) {
                        setPage(value);
                      }
                    }}
                    onTapAllShowButton={() => {
                      setPage(null);
                    }}
                  />
                )}
            </div>
          )
        ) : (
          <MatchNotFound title={"İşe Alınmayanlar Bulunamadı"} />
        )}
      </div>
    </div>
  );
};

export default ShowNotHireWhite;
