import React, { useEffect, useState } from "react";
import demandNotFound from "../../../../../../../images/svg/demandNotFound.svg";
import { Form, InputGroup, Dropdown, Button, Card } from "react-bootstrap";
import { DemandsServices } from "../../../../../../../services";
import DemandNotFound from "./Common/DemandNotFound";
import FilterBlueModal from "./Modal/FilterBlueModal";
import { ToastContainer, toast } from "react-toastify";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import PopupLongMenu from "../../../../../../widgets/PopupLongMenu";
import DemandCopyApproved from "./Modal/DemandCopyApproved";
import { Pagination, PaginationItem, Typography } from "@mui/material";
import PaginationPage from "../../../../../../widgets/PaginationPage";
import FilterInternModal from "./Modal/FilterInternModal";
import InternDemandItem from "./widgets/InternDemandItem";
import LoadingDotsAnimation from "../../../../../../widgets/LoadingDotsAnimation";
import { JobConstants } from "../../../../../../../constants";
import { useDispatch } from "react-redux";
import BlueDemandItem from "./widgets/BlueDemandItem";
import PageInfoTitle from "../../../../../../widgets/PageInfoTitle";
import PrevIconButton from "../../../../../../widgets/buttons/PrevIconButton";

const DemandInternAllShow = () => {
  const dispatch = useDispatch();
  const [internDemandList, setInternDemandList] = useState(null);
  const [showDemandCopy, setShowDemandCopy] = useState(null);
  const [demandItem, setDemandItem] = useState(null);
  function handleCloseDemandCopy() {
    setShowDemandCopy(false);
  }
  const [show, setShow] = useState(null);
  const [search, setSearch] = useState("");

  var demandFilterList = [
    {
      title: "Aktif",
      id: 0,
    },
    {
      title: "Pasif",
      id: 1,
    },
  ];
  const [selectedFilter, setSelectedFilter] = useState([
    {
      title: "Aktif",
      id: 0,
    },
    {
      title: "Pasif",
      id: 1,
    },
  ]);

  useEffect(() => {
    getAllShowIntern(null);
  }, []);

  function getAllShowIntern(isActive) {
    var sendData = { isActive: isActive, positionType: 3 };
    DemandsServices.demandsIntern(sendData).then(
      (demand) => {
        if (demand && demand.succeeded === true) {
          setInternDemandList([]);
          demand.data.map((value) => {
            setInternDemandList((prev) => [...prev, value]);
          });
        }
      },
      (error) => {}
    );
  }
  function searchHandleCandidate(value) {
    setSearch(value);
  }

  function handleClose() {
    setShow(false);
  }
  const history = useHistory();
  function copyDemandItem(job) {
    var sendData = { jobId: parseInt(job.jobId.toString()) };
    DemandsServices.copyDemandIntern(sendData).then(
      (copyDemand) => {
        setShowDemandCopy(false);
        if (copyDemand && copyDemand.succeeded === true) {
          getAllShowIntern(null);
        } else {
          toast.info(copyDemand.error.message, {
            autoClose: 2000,
            onClose: () => {
              setTimeout(() => {}, 100);
            },
          });
        }
      },
      (error) => {}
    );
  }
  const [page, setPage] = useState(1);
  const pageSize = 10; // Her sayfada gösterilecek eleman sayısı

  const paginatedList =
    internDemandList && page === null
      ? internDemandList
      : internDemandList &&
        internDemandList.slice((page - 1) * pageSize, page * pageSize);

  return (
    <div id="demandShow">
   
      <PageInfoTitle title={"Stajyer Taleplerim"} />

      <ToastContainer
        position="top-right"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />

      <div className="row">
        <div className="col-md-12">
          <Card>
          <PrevIconButton top={5} left={-10} />
            <Card.Body>
              <div>
                <div className="">
                

                  <InputGroup className="mb-3" style={{}}>
                  
                    <Form.Control
                      style={{
                        borderRadius: "20px",
                        backgroundColor: "#F5F5F5",
                        marginLeft: "10px",
                      }}
                      type="search"
                      placeholder="Bölüme göre ara"
                      value={search}
                      onChange={(e) => searchHandleCandidate(e.target.value)}
                    />

                    <Button
                      variant="outline"
                      onClick={() => {
                        setShow(true);
                      }}
                      style={{
                        borderRadius: "8px",
                        border: "1px solid #F5F5F5",
                        marginLeft: "30px",
                        backgroundColor: "white",
                      }}
                    >
                      <i
                        class="fa-solid fa-arrow-down-wide-short"
                        style={{
                          color: "#fdc303",
                          fontSize: "20px",
                        }}
                      ></i>
                      <a
                        style={{
                          color: "#001542",
                          fontWeight: "600px",
                          margin: "5px",
                        }}
                      >
                        Filtrele
                      </a>
                    </Button>
                  </InputGroup>
                </div>

                {internDemandList === null ? (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "center",
                    }}
                  >
                    <LoadingDotsAnimation />
                  </div>
                ) : internDemandList &&
                  paginatedList &&
                  paginatedList.length > 0 ? (
                  <div>
                    {paginatedList &&
                      paginatedList.map((demand, index) => {
                        return paginatedList &&
                          paginatedList[index].departmentName
                            .toLowerCase()
                            .includes(search.toLowerCase()) ? (
                          <div
                            style={{
                              position: "relative",
                            }}
                          >
                            <BlueDemandItem
                              demand={demand}
                              positionName={demand.departmentName}
                              onTap={() => {
                                dispatch({ type: JobConstants.GET_CLEAR });

                                const dataMap = new Map();
                                dataMap.set("jobId", demand.jobId);
                                dataMap.set(
                                  "isActive",
                                  Boolean(demand.isActive)
                                );
                                history.push({
                                  pathname: "/demands/InternDemandPreview",
                                  state: { dataMap },
                                });
                              }}
                              onTapMatch={() => {
                                if (demand.didDemandExpired === false) {
                                  const dataMap = new Map();
                                  dataMap.set("jobId", demand.jobId);
                                  dataMap.set(
                                    "positionName",
                                    demand.departmentName
                                  );

                                  history.push({
                                    pathname:
                                      "/demands/internCurrentDemandMatchShow",
                                    state: { dataMap },
                                  });
                                } else {
                                  toast.error(
                                    "Talebinizle eşleşen adayları görme süreniz doldu. Yeni bir talep oluşturarak eşleşme sağlayabilirsiniz.",
                                    {
                                      autoClose: 5000,
                                      onClose: () => {
                                        setTimeout(() => {}, 1000);
                                      },
                                    }
                                  );
                                }
                              }}
                            />

                            <div
                              onClick={() => {}}
                              style={{
                                position: "absolute",
                                top: 5,
                                right: 100,
                                width: 0,
                                height: 0,
                                cursor: "pointer",
                              }}
                            >
                              <div>
                                <PopupLongMenu
                                  options={[
                                    {
                                      title: "Talep Kopyala",
                                      id: 0,
                                    },
                                  ]}
                                  onChanged={(value) => {
                                    if (value) {
                                      if (
                                        value.onTapStatus == true &&
                                        value.id === 0
                                      ) {
                                        setDemandItem(demand);
                                        setShowDemandCopy(true);
                                      }
                                    }
                                  }}
                                  icon={
                                    <i className="fa-solid fa-ellipsis-vertical menu-icon-demand"></i>
                                  }
                                  iconItem={
                                    <i className="fa-regular fa-copy"></i>
                                  }
                                />
                              </div>
                            </div>
                          </div>
                        ) : (
                          <div></div>
                        );
                      })}
                  </div>
                ) : (
                  <DemandNotFound />
                )}
                {internDemandList &&
                  internDemandList.length > 0 &&
                  internDemandList.length > pageSize && (
                    <PaginationPage
                      items={internDemandList}
                      page={page}
                      pageSize={pageSize}
                      onChange={(value) => {
                        if (value) {
                          setPage(value);
                        }
                      }}
                      onTapAllShowButton={() => {
                        setPage(null);
                      }}
                    />
                  )}
              </div>
              <FilterInternModal
                show={show}
                handleClose={handleClose}
                approval={(value) => {
                  if (value) {
                    if (value.length == 2) {
                      getAllShowIntern(null);
                      setSelectedFilter([
                        {
                          title: "Aktif",
                          id: 0,
                        },
                        {
                          title: "Pasif",
                          id: 1,
                        },
                      ]);
                      setShow(false);
                    } else {
                      if (value[0].id == 0) {
                        //aktif
                        getAllShowIntern(true);
                        setSelectedFilter([
                          {
                            title: "Aktif",
                            id: 0,
                          },
                        ]);
                        setShow(false);
                      } else if (value[0].id == 1) {
                        //pasife aldım
                        getAllShowIntern(false);
                        setSelectedFilter([
                          {
                            title: "Pasif",
                            id: 1,
                          },
                        ]);
                        setShow(false);
                      } else {
                        getAllShowIntern(null);
                        setSelectedFilter([
                          {
                            title: "Aktif",
                            id: 0,
                          },
                          {
                            title: "Pasif",
                            id: 1,
                          },
                        ]);
                        setShow(false);
                      }
                    }
                  } else {
                    setSelectedFilter([
                      {
                        title: "Aktif",
                        id: 0,
                      },
                      {
                        title: "Pasif",
                        id: 1,
                      },
                    ]);
                    setShow(false);
                    getAllShowIntern(null);
                  }
                }}
                demandFilterList={demandFilterList}
                selectedStatus={selectedFilter}
              />
              <DemandCopyApproved
                handleClose={handleCloseDemandCopy}
                show={showDemandCopy}
                demandItem={demandItem}
                approval={(value) => {
                  if (value && value == true) {
                    copyDemandItem(demandItem);
                  }
                }}
                isOnIntern={true}
              />
            </Card.Body>
          </Card>
        </div>
      </div>
    </div>
  );
};

export default DemandInternAllShow;
