//*** Date:07-05-2024/ Code Writer Developer HÜSEYİN TELLİ ***
import { useEffect, useRef, useState } from "react";
import {
  TextField,
  Autocomplete,
  Checkbox,
  InputAdornment,
  Typography,
} from "@mui/material";
import { Controller } from "react-hook-form";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;
import "./BitonisDropdownStyle.css";
import { customStylesMulti } from "../customStyleInline";
import useIsMobile from "../hooks/useIsMobile";
const BitonisSingleDropdown = ({
  items,
  onValueChanged,
  errors,
  errorMessage,
  register,
  companyClassName,
  control,
  watch,//bunları dışarıdan almamın nedeni yapılacak kontrolleri doğru bir şekilde ilgili valueye göre yapmam gerekiyor
  setValue,
  required,
  placeholder,
  getValues,
  defaultValue,
}) => {
  const isMobile = useIsMobile();
  var initialValue = watch(companyClassName);

  useEffect(() => {
    // if (watch(companyClassName)) {
    //     const selectedItemsArray = (watch(companyClassName) as any[]).map((item) => items.find((i) => i.id === item.id));
    //     const list = selectedItemsArray.filter(item => Boolean(item));
    //     setSelected(list as any[]);
    // }
  }, [companyClassName, items, watch]);

  //-----------------------------------------------
  function changeSelected(_, e) {
    return e;
  }

  return (
    <div style={{}}>
      <div
        style={{
          border: "1px solid #dcdcdd",
          borderRadius: "5px",
          marginBottom: "5px",
          backgroundColor: "white",
        }}
      >
        {items && (
          <Controller
            name={companyClassName}
            control={control}
            defaultValue={
              defaultValue === null ||
              defaultValue === undefined ||
              defaultValue === ""
                ? ""
                : defaultValue
            }
            rules={{
              required: Boolean(required),
            }}
            render={({ field }) => {
              return (
                <Autocomplete
                  isOptionEqualToValue={(a, b) => a.value === b.value}
                  options={items}
                  onChange={(event, value) => {
                    const newValue = changeSelected(event, value);
                    field.onChange(newValue);
                    onValueChanged(newValue);
                  }}
                  sx={{
                    "& .MuiAutocomplete-clearIndicator": {
                      display: "none",
                    },
                    "& .MuiOutlinedInput-notchedOutline": {
                      border: "none",
                    },
                    "&:hover .MuiOutlinedInput-notchedOutline": {
                      border: "none",
                    },
                    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                      border: "none",
                    },
                    display: "flex",
                    alignItems: "center",
                    width: "100%",
                    backgroundColor: "#fcfcfc", //"#f7f7f7",
                    borderRadius: "6px",
                    alignItems: "center",
                    justifyContent: "center",
                    flexDirection: "row",
                    border: "1px solid #f3f3f6",
                    marginRight: "15px",
                  }}
                  value={field.value || null}
                  getOptionLabel={(option) => option && option.label}
                  noOptionsText="Bulunamadı"
                  blurOnSelect={true}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      placeholder={placeholder}
                      className="custom-placeholder-text"
                      sx={
                        ({
                          "& input::placeholder": {
                            color: "black",
                            fontWeight: 500,
                          },
                          "& .MuiAutocomplete-tag": {
                            color: "#001542",
                            borderRadius: "5px",
                            fontWeight: 500,
                            whiteSpace: "nowrap",
                            display: "inline-flex",
                          },
                        },
                        {
                          input: {
                            cursor: "pointer",
                            fontSize: "14px",
                            fontWeight: 410,
                            color: "#001542",
                            fontSize: isMobile ? "12px" : "14px",
                            "::placeholder": {
                              fontSize: isMobile ? "11px" : "13px",
                              fontWeight: 500,
                              color: "#001542",
                            },
                            caretColor: "red",
                            
                          },
                        })
                      }
                    />
                  )}
          
                  renderOption={(props, option, e) => {
                    var disable = false;

                    return (
                      <>
                        <li
                          {...props}
                          style={{
                            opacity: disable ? 0.4 : 1,
                            pointerEvents: disable ? "none" : "all",

                            fontSize: isMobile ? "11px" : "13px",
                            fontWeight: 405,
                            padding: "10px",
                            color: "#001542",
                            display: "flex",
                            flexDirection: "row",

                            justifyContent: "space-between",
                          }}
                        >
                          {option.label}
                          <div>
                            {e.selected && (
                              <i
                                class="fa-solid fa-check"
                                style={{
                                  marginLeft: "40px",
                                }}
                              ></i>
                            )}
                          </div>
                        </li>
                      </>
                    );
                  }}
                  loading
                />
              );
            }}
          />
        )}
      </div>{" "}
      {required && errors[companyClassName] && (
        <div className="text-danger fs-12 mb-2">{errorMessage}</div>
      )}
    </div>
  );
};

export default BitonisSingleDropdown;
{
  /*
     PaperComponent={({ children }) => (
      <ul
        style={{
          margin: 0,
          padding: 0,
          listStyle: "none",
          backgroundColor: "white",
          borderRadius: 4, // Optional: add border radius
          border: "1px solid white",
          marginTop: "5px",
          boxShadow: "0 3px 10px rgb(0 0 0 / 0.5)",
        }}
      >
        {children}
      </ul>
    )}
    */
 }