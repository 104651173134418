import React from "react";

import "../../css/bitoniscss/custompreview.css";

const CustomPreviewHobbies = ({ name, value, matchPointStatus }) => {
  const isMatchPointStatusTrue = matchPointStatus === true;

  return (
    <div className="widget-container">
      {" "}
      <div className="widget-value-containerHobbies">
        <div className="widget-value">{value && value.toString()}</div>

        {isMatchPointStatusTrue && (
          <div className="matchIContainer">
            <i class="fa-regular fa-circle-check check-icon"></i>
          </div>
        )}
      </div>
    </div>
  );
};

export default CustomPreviewHobbies;
