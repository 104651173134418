import { lazy, Suspense, useEffect, useMemo, useState } from "react";
import { connect, useDispatch } from "react-redux";
import Index from "./jsx/index";
import { Route, Switch, withRouter } from "react-router-dom";
import "./css/style.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { ProfileDetailServices } from "./services";
import { useHistory } from "react-router-dom";
import { UserAction } from "./action";
import { getToken, removeToken, removeUser } from "./configs/cookie";
import CacheBuster from "react-cache-buster";
import { version } from "../package.json";
import LoadingDotsAnimation from "./jsx/widgets/LoadingDotsAnimation";
import { AccountServices } from "./services/AccountServices";
import { UserConstants } from "./constants";

const SignUp = lazy(() => import("./jsx/pages/Registration"));
const RegisterDetails = lazy(() => import("./jsx/pages/RegistrationDetails"));

const CompanyEmailOtp = lazy(() => import("./jsx/pages/CompanyEmailOtp"));
const ForgotPassword = lazy(() => import("./jsx/pages/ForgotPassword"));
const ResetPassword = lazy(() => import("./jsx/pages/ResetPassword"));
const EmployerUnSubscriptionPage = lazy(() =>
  import("./jsx/pages/EmployerUnSubscriptionPage")
);

const Login = lazy(() => {
  return new Promise((resolve) => {
    setTimeout(() => resolve(import("./jsx/pages/Login")), 500);
  });
});

function App(props) {

  if (window.top !== window.self) {
    //bu kontrol sitenin iframe içinden açılmasını engeller
    return "blocked";
  } else {
  }



  const isProduction = process.env.NODE_ENV === "production";

  let authentication = props.authentication;
  const [hasCompany, setHasCompany] = useState(null);
  const dispatch = useDispatch();
  let history = useHistory();
  const [validToken, setValidToken] = useState(null);
  useEffect(() => {
    AccountServices.invalideToken()
      .then((res) => {
        if (res) {
          if (res.succeeded == true) {
            setValidToken(true);
          } else {
            if (res.error.code === 154) {
              setValidToken(false);
              dispatch({ type: UserConstants.LOGOUT });
              removeUser();
              removeToken();
              toast.info(
                "Oturum Süreniz dolmuştur,Lütfen tekrar giriş yapınız",
                {
                  autoClose: 2000,
                  onClose: () => {
                    setTimeout(() => {
                    }, 100);
                  },
                }
              );

            }
          }
        }
      })

      .catch((e) => {});

    if (authentication.loggedIn) {
      ProfileDetailServices.companyCheckMainDetail()
        .then((res) => {
          if (res) {
            if (res.data === undefined) {
              setHasCompany(null);
            } else {
              if (res.succeeded == true) {
                if (res.data == null) {
                  setHasCompany(false);
                } else {
                  setHasCompany(true);
                }
              } else {
                if (res.data == null) {
                  setHasCompany(false);
                } else {
                  setHasCompany(true);
                }
              }
            }
          }
        })
        .catch((e) => {
          console.error(e);
          setHasCompany(null);
        });
    } else if (hasCompany) {
      setHasCompany(null);
    }
  }, [authentication.loggedIn]);
  let path = window.location.pathname;
  useEffect(() => {
    if (authentication.loggedIn && hasCompany === false) {
      history.push("/register-details");
    } else {
      // history.push(path);
    }
  }, [hasCompany]);

  let routes = (
    <Switch>
      <Route path="/page-register" component={SignUp} />
      <Route path="/register-details" component={RegisterDetails} />
      <Route path="/page-forgot-password" component={ForgotPassword} />
      <Route path="/page-email-otp" component={CompanyEmailOtp} />
      <Route
        path="/account/employer/reset-password"
        component={ResetPassword}
      />
      <Route path="/unsubscription" component={EmployerUnSubscriptionPage} />
      <Route path="/" component={Login} />
    </Switch>
  );
  if (authentication.loggedIn && hasCompany == true) {
    return <Index />;
  } else {
    return (
      <div
        className="vh-100"
        style={{
          opacity: authentication.loggedIn && hasCompany === null ? "0" : "1",
          transition: "all .4s",
          backgroundClolor: "#001542",
        }}
      >
        <ToastContainer />
        <Suspense
          fallback={
            <div id="preloader">
              <div className="sk-three-bounce">
                <div className="sk-child sk-bounce1"></div>
                <div className="sk-child sk-bounce2"></div>
                <div className="sk-child sk-bounce3"></div>
              </div>
            </div>
          }
        >
          {routes}
        </Suspense>
      </div>
    );
  }
}

function mapState(state) {
  let authentication = state.authentication;
  return { authentication };
}

export default connect(mapState)(App);
