import React from 'react';
import { Box, CircularProgress, Paper } from '@mui/material';
import { styled } from '@mui/system';
import logo from '../../images/svg/logosimple.svg';

// Daire şeklinde bir konteyner
const CircularContainer = styled(Paper)({
  position: 'relative',
  width: 65,
  height: 65,
  borderRadius: '50%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.5)', // Elevation
  overflow: 'hidden',
});

// Yükleme göstergesinin stil ayarları
const StyledCircularProgress = styled(CircularProgress)({
  position: 'absolute',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  margin: 'auto',
  color: '#001542',
  animation: 'progress 4s linear infinite', // Döndürme animasyonunu yavaşlat
  '@keyframes progress': {
    '0%': { transform: 'rotate(0deg)' },
    '100%': { transform: 'rotate(360deg)' },
  },
});

// Resmin stil ayarları
const AnimatedImage = styled('img')({
  width: 25,
  height: 25,
  animation: 'scale 4s infinite', // Ölçekleme animasyonunu yavaşlat
  '@keyframes scale': {
    '0%': { transform: 'scale(1)' },
    '50%': { transform: 'scale(1.2)' },
    '100%': { transform: 'scale(1)' },
  },
});
// Yükleme ekranı için stil ayarları
const Overlay = styled('div')({
  position: 'fixed',
  top: 0,
  left: 0,
  width: '100vw',
  height: '100vh',
  backgroundColor: 'rgba(0, 0, 0, 0.1)', // Arka planı karart
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  zIndex: 9999, // Ekranın en üstünde görünmesi için
});
const LoadingDotsAnimation = () => {
  return (<Overlay>
    <Box sx={{
      position: 'fixed',
      top: '50%',
      left: {
        xs: "50%",
        sm: "50%",
        md: "50%",
        lg: "60%",
        xl: "60%",
      }
      ,
      transform: 'translate(-50%, -50%)',
      zIndex: 9999, // Ekranın en üstünde görünmesi için
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    }}>
      <CircularContainer elevation={4}>
        <StyledCircularProgress
          size={55}
          thickness={2}
        />
        <AnimatedImage src={logo} alt="Logo" />
      </CircularContainer>
    </Box></Overlay>
  );
};

export default LoadingDotsAnimation;
