// PopupLongMenu.jsx
import React from "react";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import "../../css/bitoniscss/demand/longMenu.css";
const ITEM_HEIGHT = 48;

export default function PopupLongMenu({ options, onChanged, icon, iconItem }) {
  //options da item id ve id olmak zorunda
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div className="long-menu-container">
      <IconButton
        className="long-menu-icon-button"
        aria-label="more"
        id="long-button"
        aria-controls={open ? "long-menu" : undefined}
        aria-expanded={open ? "true" : undefined}
        aria-haspopup="true"
        onClick={handleClick}
        style={{
          color:"orange"
        }}
      >
        {icon}
      </IconButton>
      <Menu
        className="long-menu"
        id="long-menu"
        MenuListProps={{
          "aria-labelledby": "long-button",
        }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            maxHeight: ITEM_HEIGHT * 4.5,
            width: "20ch",
          },
        }}
      >
        {options.map((option) => (
          <MenuItem
            key={option}
            selected={option === "Pyxis"}
            onClick={() => {
              handleClose();
              onChanged({
                onTapStatus: true, //tıkladığını anlamk için kullandım
                id: option.id, //eğer başka componentlerde birden fazla item olduğunda itema göre işlemler yapmak için kullandım
              });
            }}
            className="long-menu-item"
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
              }}
            >
              {iconItem}
              <p className="option-title"> {option.title}</p>
            </div>
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
}
